import { FailedModalConfig } from "../manageCashDrawerTypes";
import { Header } from "./Header";

export const FailedPopUp = ({ label, primaryButton, secondaryButton }: FailedModalConfig) => {
  const successClassNames =
    "brandRedBtnMcd text-white active:brandRedBtnMcd font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150";
  const cancelClassNames =
    "restNoBtnMcd align-center border-t border-r border-l border-b border-black border-solid bg-transparent-500 text-black active:bg-transparent-600 font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 hover";

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-xl popupmodel">
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4	">
            <div className="relative p-4 flex-auto fontSize text-center">
              <i className="fas fa-times paidCrossColor text-5xl bg-white"></i>
              <Header>{label}</Header>
              {/*footer*/}
              <div className="justify-start p-4  rounded-b">
                {secondaryButton ? (
                  <button
                    className={cancelClassNames}
                    type="button"
                    value="No"
                    onClick={secondaryButton.onClick}
                    data-cy="popupNo">
                    {secondaryButton.label}
                  </button>
                ) : null}
                <button
                  className={successClassNames}
                  type="button"
                  value="Yes"
                  onClick={primaryButton.onClick}
                  data-cy="popupYes">
                  {primaryButton.label}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
