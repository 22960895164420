import { BranchCashOverview } from "@bbo/api/generator";
import { useContext } from "react";
import { StockCardData } from "../StockBranchOverview/StockCardData/StockCardData";
import { useTableList } from "./useTableList";
import { CashManagementBranchOverviewContext } from "./BranchOverviewProvider";

interface SafeItemProps {
  data: BranchCashOverview;
  isStockData?: boolean;
}

const SafeItem = ({ data, isStockData }: SafeItemProps) => {
  const { setAccountingLocation } = useContext(CashManagementBranchOverviewContext);
  const { orderedTable } = useTableList(data);

  if (!data) return null;

  return (
    <div
      className="accounting-card widthAccounting inline-block mr-8"
      onClick={() => setAccountingLocation(data)}>
      <div className="main Safe w-full bg-white cursor-pointer block mr-8 inline-block cursor-pointer">
        {isStockData ? (
          <StockCardData
            currentVariance={data.currentVariance}
            lastBalancedDateTime={data.lastDeclarationTimestamp}
            toDateVariance={data.toDateVariance}
          />
        ) : (
          <table className="w-full border-none">
            <tbody>{orderedTable}</tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default SafeItem;
