/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, UseMutationOptions, MutationFunction } from "@tanstack/react-query";
import type {
  CreatePouchResponseResponse,
  BboPouchManagementBadRequestResponse,
  UnauthorizedResponse,
  BboPouchManagementForbiddenResponse,
  BboPouchManagementFailureResponseResponse,
  CreatePouchBodyBody,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Suspend Pouch in Preparation for a pouchID
 */
export const usePouchPreparationSuspendHook = () => {
  const pouchPreparationSuspend = useCustomInstance<CreatePouchResponseResponse>();

  return (pouchID: string, createPouchBodyBody: CreatePouchBodyBody) => {
    return pouchPreparationSuspend({
      url: `/bm-pouch-management/v1/preparation/suspend/${pouchID}`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: createPouchBodyBody,
    });
  };
};

export type PouchPreparationSuspendMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof usePouchPreparationSuspendHook>>
>;
export type PouchPreparationSuspendMutationBody = CreatePouchBodyBody;
export type PouchPreparationSuspendMutationError = ErrorType<
  | BboPouchManagementBadRequestResponse
  | UnauthorizedResponse
  | BboPouchManagementForbiddenResponse
  | BboPouchManagementFailureResponseResponse
>;

export const usePouchPreparationSuspend = <
  TError = ErrorType<
    | BboPouchManagementBadRequestResponse
    | UnauthorizedResponse
    | BboPouchManagementForbiddenResponse
    | BboPouchManagementFailureResponseResponse
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof usePouchPreparationSuspendHook>>,
    TError,
    { pouchID: string; data: CreatePouchBodyBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const pouchPreparationSuspend = usePouchPreparationSuspendHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof usePouchPreparationSuspendHook>>,
    { pouchID: string; data: CreatePouchBodyBody }
  > = (props) => {
    const { pouchID, data } = props || {};

    return pouchPreparationSuspend(pouchID, data);
  };

  return useMutation<
    AsyncReturnType<typeof pouchPreparationSuspend>,
    TError,
    { pouchID: string; data: CreatePouchBodyBody },
    TContext
  >(mutationFn, mutationOptions);
};
