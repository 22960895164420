import { PouchDetailsItems, PouchStatus, PouchSubType, PouchType } from "@bbo/api/generator";
import { AdditionalItems, TransferPayloadParams } from "@bbo/types/TransferTypes";
import {
  denominationLadderForPouchSubTypes,
  denominationLookup,
  ItemInfo,
  PouchDetailsItemsInfo,
} from "@bbo/utils";
import { getUnixTimeStamp } from "@bbo/utils/dateTimeFormatter";
import { TRANSACTION_ITEM_ID, TRANSACTION_MODE } from "@bbo/utils/transferPayload";
import { getUUID } from "@bbo/utils/util";

// Converts the PouchDetailsItems interface into the PouchDetailsItemsInfo interface, adding
// the missing values using the denominationLookup map
export const constructPouchDetailsItemInfo = (
  pouchDetailsItems: PouchDetailsItems,
): PouchDetailsItemsInfo =>
  Object.keys(pouchDetailsItems).reduce((acc, curr) => {
    if (!denominationLookup[curr]) {
      throw new Error(`No denominationLookup found for itemID: ${curr}`);
    }

    acc[curr] = {
      ...denominationLookup[curr],
      ...pouchDetailsItems[curr],
    };

    return acc;
  }, {} as PouchDetailsItemsInfo);

// Convert from PouchDetailsItemsInfo back into PouchDetailsItems.
// Used when suspending a pouch
export const convertToPouchDetailsItems = (
  pouchDetailsItemInfo: PouchDetailsItemsInfo,
): PouchDetailsItems =>
  Object.keys(pouchDetailsItemInfo).reduce((acc, curr) => {
    acc[curr] = {
      itemID: pouchDetailsItemInfo[curr].itemID,
      currency: pouchDetailsItemInfo[curr].currency,
      denomination: pouchDetailsItemInfo[curr].denomination,
      itemQuantity: pouchDetailsItemInfo[curr].itemQuantity,
      itemValue: pouchDetailsItemInfo[curr].itemValue,
      materialType: pouchDetailsItemInfo[curr].materialType,
    };

    return acc;
  }, {} as PouchDetailsItems);

export const getValueInPence = ({ currency, denomination, itemQuantity }: ItemInfo) =>
  denomination * itemQuantity * (currency === "GBP" ? 100 : 1); // If in pounds, convert to pence

// Converts into an array of AdditionalItems which is included in the TransferPayloadParams interface
// and sent to the transaction API
export const convertToAdditionalItems = (
  pouchDetailsItemsInfo: PouchDetailsItemsInfo,
  status: string,
): AdditionalItems[] => {
  const additionalItems = pouchDetailsItemsInfo
    ? Object.values(pouchDetailsItemsInfo)
        .filter((itemInfo) => !!itemInfo.itemValue) // Filter out the 0 values
        .map((itemInfo) => ({
          itemID: itemInfo.itemID,
          valueInPence: (status === "cancelled" ? -1 : 1) * Number(itemInfo?.itemValue),
          quantity: (status === "cancelled" ? -1 : 1) * Number(itemInfo?.itemQuantity),
          tokens: {
            denomination: itemInfo.denomination,
            currencyCode: itemInfo.currency,
            materialType: itemInfo.materialType,
          },
        }))
    : [];

  // If there are no additionalItems then return an array containing this one item. Not sure why
  // Functionality copied across from old preparedPouchParams function from transferPayload.ts
  if (!additionalItems?.length) {
    return [
      {
        itemID: "655",
        valueInPence: 0,
        quantity: -1,
        tokens: {
          denomination: 10,
          currencyCode: "GBP",
          materialType: "Notes",
        },
      },
    ];
  }

  // Success
  return additionalItems;
};

interface ConvertToTransferPayloadParams {
  pouchDetailsItemsInfo: PouchDetailsItemsInfo;
  status: PouchStatus;
  pouchBarcode: string;
  fadCode: string;
  pouchType: PouchType;
  pouchSubType: PouchSubType;
  smartID: string;
  userName: string;
  assignedBranchName: string;
}

export const calculateTotalValueInPence = (pouchDetailsItemsInfo: PouchDetailsItemsInfo) =>
  Object.values(pouchDetailsItemsInfo).reduce((acc, curr) => {
    const currentValue = curr.itemValue;
    if (isNaN(currentValue)) {
      return acc;
    }
    return acc + currentValue;
  }, 0);

// Converts into an array of AdditionalItems which is included in the TransferPayloadParams interface
// and sent to the transaction API
export const convertToTransferPayloadParams = ({
  pouchDetailsItemsInfo,
  status,
  pouchBarcode,
  fadCode,
  pouchType,
  smartID,
  userName,
  assignedBranchName,
  pouchSubType,
}: ConvertToTransferPayloadParams): TransferPayloadParams => {
  const items = convertToAdditionalItems(pouchDetailsItemsInfo, status);
  const totalValueInPence = items.reduce((acc, curr) => acc + curr.valueInPence, 0);
  return {
    id: getUUID(),
    transactionStartTime: getUnixTimeStamp(new Date()),
    itemID: TRANSACTION_ITEM_ID.POUCH_PREPARATION,
    quantity: status === "cancelled" ? 1 : -1, // For preparing and cancelling should be -1
    valueInPence: -totalValueInPence,
    additionalItems: items,
    transactionMode:
      status === "cancelled"
        ? TRANSACTION_MODE.POUCH_PREPARATION_CANCEL
        : TRANSACTION_MODE.POUCH_PREPARATION,
    stockunitIdentifier: "S01", // TODO: Copying over from preparedPouchParams function in transferPayload.ts, there is no way to currently get stockunitIdentifier, this needs raising
    accountReferenceID: pouchBarcode,
    fadCode,
    tokens: {
      pouchType,
      pouchBarcode,
      smartID,
      userName,
      assignedBranchName,
      pouchSubType,
      operationType: status,
    },
  };
};

export const penceToPounds = (pence: number) => ((pence ?? 0) / 100).toFixed(2);

export const calculateItemValue = (itemInfo: ItemInfo) => {
  const scale = (itemInfo.currency === "GBP" ? 100 : 1) / 100; // divide by 100 since displayValues total is in pounds
  switch (itemInfo.itemType) {
    case "fullBag":
    case "partBag":
      return itemInfo.maxQuantityInBag * itemInfo.itemQuantity * itemInfo.denomination * scale;
    default:
      return itemInfo.itemQuantity * itemInfo.denomination * scale;
  }
};

export const constructDenominationLadderForPouchSubType = (subType: PouchSubType) => {
  const { rows } = denominationLadderForPouchSubTypes[subType];
  const itemIDs = Object.values(rows).flat(1);
  return itemIDs.reduce((acc, curr) => {
    acc[curr] = denominationLookup[curr];
    return acc;
  }, {} as PouchDetailsItemsInfo);
};

export const getItemType = (item: ItemInfo): string => {
  switch (item.itemType) {
    case "fullBag":
      return "Full Bag";
    case "partBag":
      return "Part Bag";
    case "unusableCoin":
    case "unusableNote":
      return "Unusable";
    default:
      return "";
  }
};

export const getMaterialTypeText = (item: ItemInfo): string => {
  switch (item.materialType) {
    case "COIN":
      return "Coins";
    case "NOTE":
      return "Notes";
    default:
      return "";
  }
};

export const formatDisplayCurrency = (itemInfo: ItemInfo) => {
  const valueWithCurrency =
    itemInfo.currency === "GBP"
      ? `£${itemInfo.denomination.toFixed(2)}`
      : `${itemInfo.denomination}p`;
  const valueWithCurrencyAndMaterialType = `${valueWithCurrency} ${getMaterialTypeText(itemInfo)}`;

  return ["unusableCoin", "unusableNote"].includes(itemInfo.itemType)
    ? `${getItemType(itemInfo)} ${valueWithCurrencyAndMaterialType}`
    : `${valueWithCurrencyAndMaterialType} ${getItemType(itemInfo)}`;
};
