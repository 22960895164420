/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, UseMutationOptions, MutationFunction } from "@tanstack/react-query";
import type {
  VarianceReportResponseResponse,
  BboDeclarationBadRequestResponse,
  UnauthorizedResponse,
  ForbiddenResponse,
  BboDeclarationFailureResponseResponse,
  BboDeclarationVarianceReport,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary This endpoint is used to get Variance report of branch
 */
export const useGetVarianceReportHook = () => {
  const getVarianceReport = useCustomInstance<VarianceReportResponseResponse>();

  return (bboDeclarationVarianceReport: BboDeclarationVarianceReport) => {
    return getVarianceReport({
      url: `/bm-declaration-service/v1/variance-report`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: bboDeclarationVarianceReport,
    });
  };
};

export type GetVarianceReportMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetVarianceReportHook>>
>;
export type GetVarianceReportMutationBody = BboDeclarationVarianceReport;
export type GetVarianceReportMutationError = ErrorType<
  | BboDeclarationBadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | BboDeclarationFailureResponseResponse
>;

export const useGetVarianceReport = <
  TError = ErrorType<
    | BboDeclarationBadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | BboDeclarationFailureResponseResponse
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof useGetVarianceReportHook>>,
    TError,
    { data: BboDeclarationVarianceReport },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const getVarianceReport = useGetVarianceReportHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof useGetVarianceReportHook>>,
    { data: BboDeclarationVarianceReport }
  > = (props) => {
    const { data } = props || {};

    return getVarianceReport(data);
  };

  return useMutation<
    AsyncReturnType<typeof getVarianceReport>,
    TError,
    { data: BboDeclarationVarianceReport },
    TContext
  >(mutationFn, mutationOptions);
};
