import { Dispatch, SetStateAction, useMemo } from "react";

// Pagination Management Hook
export const usePaginationControl = <TData>(
  filteredAllData: TData[],
  currentPage: number,
  setCurrentPage: Dispatch<SetStateAction<number>>,
  itemsPerPage: number,
) => {
  // This needs to be memoized otherwise paginatedData triggers an infinite re-render
  const paginatedData = useMemo(
    () => filteredAllData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage),
    [currentPage, filteredAllData, itemsPerPage],
  );

  const hasNext = filteredAllData.length > itemsPerPage * (currentPage + 1);
  const hasPrev = currentPage > 0;

  const getNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const getPrev = () => {
    setCurrentPage((prev) => prev - 1);
  };

  return {
    paginatedData,
    hasNext,
    hasPrev,
    getNext,
    getPrev,
  };
};
