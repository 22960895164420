import ProgressBar from "@bbo/components/ProgressBar";
import { BP_TP_STATUS } from "@bbo/constants";
import { EachDetailViewCommodityProps } from "@bbo/interfaces/BpTp";
import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";
const { COMPLETED, RECOUNT, COMPLETE_BALANCE } = BP_TP_STATUS;

const EachDetailViewCommodity = ({
  detailViewCommodityProps,
  detailViewProps,
}: EachDetailViewCommodityProps) => {
  const {
    commodityTypeName,
    commodityAction,
    commodityRecount,
    commodityProgressColor,
    commodityVarianceValue,
  } = detailViewCommodityProps;
  const { fromHistory, fromDiscrepancyAccount, completeBalance, completedBalance, recountBalance } =
    detailViewProps;
  return (
    <>
      <tr className="h-20">
        <td className="sideTitle">{commodityTypeName}</td>
        <td>
          {<ProgressBar bgcolor={commodityProgressColor} progress="100" reason={commodityAction} />}
        </td>
        <td className={`valueDot ${currencyStyle(commodityVarianceValue)}`}>
          {amountSignedPound(commodityVarianceValue)}
        </td>
        {fromHistory || fromDiscrepancyAccount ? (
          <td className="linkBtnHighLevel" onClick={completedBalance}>
            {COMPLETED}
          </td>
        ) : !fromHistory && !fromDiscrepancyAccount && commodityRecount ? (
          <td className="linkBtnHighLevel" onClick={completeBalance}>
            {RECOUNT}
          </td>
        ) : (
          <td className="linkBtnHighLevel" onClick={completeBalance}>
            {COMPLETE_BALANCE}
          </td>
        )}
      </tr>
    </>
  );
};

export default EachDetailViewCommodity;
