import {
  BranchDetails,
  EntityDataPouchStatus,
  StockCountList,
  TaskData,
  VarianceFormFields,
  VarianceReport,
} from "@bbo/api/generator";
import { CASH_DETAILS } from "@bbo/features/AdhocBalance/ladderConstant";
import { ALL } from "@bbo/features/DiscrepancyAccount/constant";
import { createGlobalState } from "react-hooks-global-state";

const authUserState = {
  isLoggedIn: false,
  idToken: "",
  accessToken: "",
  refreshToken: "",
  isPOLauncher: false,
};

interface AdhocDataState {
  variance: number | null;
  currentTDV: number | null;
  balance: number | null;
  denomination: { denomination: string; index: number; looseCoin: number; value: number }[];
}

const adhocDataState: AdhocDataState = {
  variance: null,
  currentTDV: null,
  balance: null,
  denomination: [],
};

const cashDetailsFprAdhocBalanceState = CASH_DETAILS;

const userState = {
  UserAttributes: [],
  attributes: {
    sub: null,
    email_verified: null,
    name: null,
    preferred_username: null,
  },
  id: null,
  username: null,
};

const branchDetails: BranchDetails = {
  branch_address: "",
  branch_name: "",
  branch_type: "",
  fadcode: "",
  is_royal_mails_branch: false,
};

const isUserLoggedIn = {
  IsLoggedIn: true,
};

const adhocBalanceState: EntityDataPouchStatus = {
  entityData: [],
  isSuspendedPouch: false,
  preparedPouches: [],
  suspendedBy: "",
  suspendedPouches: [],
};
const isReadonlyMode = {
  isReadonlyMode: false,
};
// Isbalancecheck
const Isbalancecheckstate = {
  Isbalancecheck: false,
};
const totalCash = {
  totalCash: 0,
};

// Too many any types to figure out this type. Needs to wait until network requests have been moved over to React Query
const PopupData = {
  head: "",
  data: {
    sequenceID: 0,
    completionDateTime: 0,
    entity: "",
    entityID: "",
    entityName: "",
    fadcode: "",
    level: "",
    role: "",
    run_date_time: 0,
    status: "",
    task: "",
    taskID: "",
    taskType: "",
    trigger: "",
    user: "",
    balance: "",
    dateTime: null,
    systemValue: null,
    associatedEntityID: null,
    associatedEntityType: null,
    associatedEntityName: null,
    associatedCounterTerminalID: null,
    associatedCounterTerminal: null,
    lastVariance: null,
    toDateVariance: null,
    dueVariance: null,
    lastBalancedDateTime: null,
    actionList: null,
    transferAmt: null,
    transferAmtDate: null,
    itemDetails: {
      currentVariance: null,
      toDateVariance: null,
      lastBalancedDateTime: null,
      transferAmt: null,
      transferAmtDate: null,
      balance: null,
      systemValue: null,
      lastTransferAmount: null,
    },
    ItemDetails: {
      currentVariance: null,
      toDateVariance: null,
      lastBalancedDateTime: null,
      transferAmt: null,
      transferAmtDate: null,
      balance: null,
      systemValue: null,
      lastTransferAmount: null,
    },
  },
  index: null,
};
const BranchVariancePopup = {
  BranchVariancePopup: false,
};
const MoveVariancePopup = {
  BranchVariancePopup: false,
};
const moveDiscrepancyButton = {
  moveDiscrepancyButton: false,
};
const cashMovementModal = {
  cashMovementModal: false,
};
const BarCodeFormEnable = {
  barCodeFormEnable: true,
};
const BookingFormEnable = {
  bookingFormEnable: true,
};
const CountTypeTitleShow = {
  countTypeTitleShow: true,
};
const requiredDetailsdisableBtn = {
  requiredDetailsdisableBtn: true,
};
const requiredDetailsShow = {
  requiredDetailsShow: true,
};

interface DateCashBalance {
  from: Date | null;
  todate: Date | null;
}

interface transactionLogParamDate {
  date: Date | null;
}

const dateCashbalance: DateCashBalance = {
  from: null,
  todate: null,
};

const transactionLogParamDate: transactionLogParamDate = {
  date: null,
};

const transactionLogParam = {
  to: null,
  from: null,
  fromMin: null,
  toMin: null,
};

const taskSelected: TaskData | null = null;

const CashMovementHistory = [
  {
    dateTime: 0,
    sourceEntityType: "",
    sourceEntityID: "",
    sourceEntityName: "",
    destinationEntityType: "",
    destinationEntityID: "",
    destinationEntityName: "",
    transferAmount: 0,
    branchID: "",
    smartID: "",
    userName: "",
    operationType: "",
    transactionID: "",
    transactionType: "",
    transferAt: "",
    entityType: "",
  },
];

const counterTerminalAccess = {
  isCtAccess: false,
  navfromCT: "",
};
const valueOfVariance = {
  Variance: "",
  branchReferenceNumber: "",
  pouchNumber: "",
  sessionID: "",
};

const RouteState = {
  RouteState: null,
};

const showSettleDispute = {
  showSettleDisputeButton: false,
};
const RecordVarianceData: VarianceReport[] = [
  {
    id: "",
    varianceType: "",
    requiredDetails: [],
  },
];

const requiredDetails: VarianceFormFields[] = [{ id: "", value: "", errorMessage: "", title: "" }];

const selectValue: any = {
  data: null,
  setShowSelect: null,
};
const KnownVarianceAPI: any = {
  Error: null,
  Success: null,
};

const DetailedViewModel: any = {
  params: null,
  showModel: true,
};

const apiChange = {
  apiChange: null,
};

const cashBalanceFiltersState: any = {
  entityName: [],
  smartID: [],
  status: [],
  userName: [],
};

const SUMMARY_TAB = ALL;
const CURRENT_PAGE = 1;
const CURRENT_ROWS = [];
const FEATURE_MANAGE_CASH_DRAWER = true;

interface IFilter {
  filter: string;
  value: unknown;
  field: string;
  from: string;
  to: string;
  items: unknown[];
  date: string;
}

const ACTIVE_FILTERS: IFilter[] = [];
const MAX_LIMIT_COUNTER = 0;
const CATEGORIES = { categories: [] };

const COUNTTYPE: StockCountList = { id: null, name: null };
const SuspendedPouches = [];

const isPreparedActive = {
  isPreparedActive: false,
};

const isSuspenedActive = {
  isSuspenedActive: false,
};

const isActivitiesActive = {
  isActivitiesActive: false,
};

export const initialState = {
  COUNTTYPE,
  categories: CATEGORIES,
  activeFilters: ACTIVE_FILTERS,
  summaryTab: SUMMARY_TAB,
  curentPage: CURRENT_PAGE,
  currentRows: CURRENT_ROWS,
  cashBalanceFilters: cashBalanceFiltersState,
  adhocbalance: adhocBalanceState, // Multiple network requests are using this state object with incorrect types, needs refactoring
  adhocData: adhocDataState,
  totalCash: totalCash,
  taskSlected: taskSelected,
  userName: userState, // Not being set anywhere?
  isUserLoggedIn: isUserLoggedIn,
  cashMovementModal: cashMovementModal,
  SuspendedPouches: SuspendedPouches,
  PopupData: PopupData, // Too many any types to figure out this type. Needs to wait until network requests have been moved over to React Query
  Isbalancecheck: Isbalancecheckstate,
  cashMovementHistory: CashMovementHistory,
  counterTerminalAccess: counterTerminalAccess,
  dateCashbalance: dateCashbalance,
  routeState: RouteState,
  BranchVariancePopup: BranchVariancePopup,
  MoveVariancePopup: MoveVariancePopup,
  apiChange: apiChange,
  RecordVarianceData: RecordVarianceData,
  KnownVarianceAPI: KnownVarianceAPI,
  FEATURE_MANAGE_CASH_DRAWER: FEATURE_MANAGE_CASH_DRAWER,
  DetailedViewModel: DetailedViewModel,
  showSettleDispute: showSettleDispute,
  selectValue: selectValue,
  authUser: authUserState,
  maxLimitCounter: MAX_LIMIT_COUNTER,
  moveDiscrepancyButton: moveDiscrepancyButton,
  isReadonlyMode: isReadonlyMode,
  BarCodeFormEnable: BarCodeFormEnable,
  BookingFormEnable: BookingFormEnable,
  CountTypeTitleShow: CountTypeTitleShow,
  requiredDetailsdisableBtn: requiredDetailsdisableBtn,
  requiredDetailsShow: requiredDetailsShow,
  valueOfVariance: valueOfVariance,
  requiredDetails: requiredDetails,
  transactionLogParam: transactionLogParam,
  transactionLogParamDate: transactionLogParamDate,
  isPreparedActive: isPreparedActive,
  isSuspenedActive: isSuspenedActive,
  isActivitiesActive: isActivitiesActive,
};
export const { getGlobalState, setGlobalState, useGlobalState } = createGlobalState(initialState);
