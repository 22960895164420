import { BP_TP_PERIOD_NAMES, BP_TP_PERIOD_TYPES } from "@bbo/constants";
import CurrentBpTp from "@bbo/features/TpBp/CurrentBpTp";

const CurrentBp = () => {
  const periodName = BP_TP_PERIOD_NAMES.BALANCE_PERIOD;
  const periodType = BP_TP_PERIOD_TYPES.BP;

  return <CurrentBpTp periodName={periodName} periodType={periodType} />;
};

export default CurrentBp;
