import { BranchCashOverview } from "@bbo/api/generator";
import { SCREENS } from "@bbo/constants/screenEnums";
import { useAppSelector } from "@bbo/lib";
import { deepLink, isDeviceMobile } from "@bbo/utils/util";
import { Dispatch, SetStateAction } from "react";
import { useNavigate } from "react-router-dom";
import { Action } from "./BranchOverviewProvider";
import {
  Operation,
  actionButtonStyle,
  checkOperationDisabled,
  getOperationUrl,
} from "./branchOverviewHelpers";

interface ActionButtonsProps {
  actionList: Action[];
  data: BranchCashOverview;
  setPouchPopup: Dispatch<
    SetStateAction<{
      isPopupPouch: boolean;
      message: string;
    }>
  >;
}

export const ActionButtons = ({ actionList, data, setPouchPopup }: ActionButtonsProps) => {
  const isMobile = isDeviceMobile();
  const navigate = useNavigate();
  const { authUserData } = useAppSelector((rootState) => rootState.auth);
  const { branch_type } = useAppSelector((root) => root.branchDetails);

  const apiCounterCall = async (operationType: Operation) => {
    navigate(
      getOperationUrl[operationType](data.accountingLocationID, data.accountingLocationType),
      {
        state: { from: SCREENS.BRANCH_OVERVIEW },
      },
    );
  };
  const renderPouchAction = (item: Action, index: number) => {
    const baseClass = "branchOverviewButton font-bold";

    if (isMobile) {
      const refreshToken = localStorage.getItem(
        `CognitoIdentityServiceProvider.3bug83m4vi0j030qikm703k87n.${authUserData.username}.refreshToken`,
      );

      const CT_DEEP_LINK =
        item.id === "pouch_despatch_1"
          ? deepLink.pouchDispatch(refreshToken)
          : deepLink.pouchAcceptance(refreshToken);

      return (
        <a key={index} className={baseClass} href={CT_DEEP_LINK}>
          {item.name}
        </a>
      );
    }

    const handleClick = () => {
      if (location.pathname !== "/branchOverviewForex") {
        setPouchPopup({
          isPopupPouch: true,
          message: `${item.name} is only available via the Counter Terminal App.`,
        });
      }
    };

    return (
      <button key={index} className={baseClass} onClick={handleClick}>
        {item.name}
      </button>
    );
  };

  const renderButton = (item: Action, index: number) => (
    <button
      key={index}
      className={actionButtonStyle(index, item.operation, actionList, branch_type)}
      disabled={checkOperationDisabled(item.operation, branch_type)}
      onClick={() => {
        if (
          location.pathname !== "/branchOverviewForex" ||
          item.name === "Currency Count Summary" ||
          item.name === "Currency Count"
        ) {
          apiCounterCall(item.operation);
        }
      }}>
      {item.name}
    </button>
  );

  const isPouchAction = (item: Action) =>
    item.id === "pouch_acc_1" || item.id === "pouch_despatch_1";

  return (
    <>
      {actionList?.map((item, index) =>
        isPouchAction(item) ? renderPouchAction(item, index) : renderButton(item, index),
      )}
    </>
  );
};
