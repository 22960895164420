/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  GetDiscrepancyTotalResponseResponse,
  BboDeclarationBadRequestResponse,
  UnauthorizedResponse,
  ForbiddenResponse,
  BboDeclarationFailureResponseResponse,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Get current total discrepancy for a branch
 */
export const useGetDiscrepancyTotalHook = () => {
  const getDiscrepancyTotal = useCustomInstance<GetDiscrepancyTotalResponseResponse>();

  return () => {
    return getDiscrepancyTotal({
      url: `/bm-declaration-service/v1/discrepancy/total`,
      method: "get",
    });
  };
};

export const getGetDiscrepancyTotalQueryKey = () => [
  `/bm-declaration-service/v1/discrepancy/total`,
];

export type GetDiscrepancyTotalQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetDiscrepancyTotalHook>>
>;
export type GetDiscrepancyTotalQueryError = ErrorType<
  | BboDeclarationBadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | BboDeclarationFailureResponseResponse
>;

export const useGetDiscrepancyTotal = <
  TData = AsyncReturnType<ReturnType<typeof useGetDiscrepancyTotalHook>>,
  TError = ErrorType<
    | BboDeclarationBadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | BboDeclarationFailureResponseResponse
  >,
>(options?: {
  query?: UseQueryOptions<
    AsyncReturnType<ReturnType<typeof useGetDiscrepancyTotalHook>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetDiscrepancyTotalQueryKey();

  const getDiscrepancyTotal = useGetDiscrepancyTotalHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetDiscrepancyTotalHook>>
  > = () => getDiscrepancyTotal();

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetDiscrepancyTotalHook>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};
