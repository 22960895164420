/**
 * Pouch preparation constants
 */

import { ladderRowCombinedItem } from "@bbo/types";
import { TRANSACTION_NOTE_COIN_ITEM_ID } from "@bbo/utils/transferPayload";

/* eslint-disable prettier/prettier */
export const LIMIT_EXCEEDS = 26000;

export const fullBagCoinIds = [
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_POUND_FULL_ID,
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_POUND_FULL_ID,
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_50_PENCE_FULL_ID,
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_20_PENCE_FULL_ID,
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_10_PENCE_FULL_ID,
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_5_PENCE_FULL_ID,
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_PENCE_FULL_ID,
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_PENCE_FULL_ID,
];

export const loosePartialCoinIds = [
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_POUND_PARTIAL_ID,
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_POUND_PARTIAL_ID,
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_50_PENCE_PARTIAL_ID,
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_20_PENCE_PARTIAL_ID,
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_10_PENCE_PARTIAL_ID,
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_5_PENCE_PARTIAL_ID,
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_PENCE_PARTIAL_ID,
  TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_PENCE_PARTIAL_ID,
];

export const partialToFullCoinIds = {
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_POUND_PARTIAL_ID]:
    TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_POUND_FULL_ID,
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_POUND_PARTIAL_ID]:
    TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_POUND_FULL_ID,
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_50_PENCE_PARTIAL_ID]:
    TRANSACTION_NOTE_COIN_ITEM_ID.BAG_50_PENCE_FULL_ID,
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_20_PENCE_PARTIAL_ID]:
    TRANSACTION_NOTE_COIN_ITEM_ID.BAG_20_PENCE_FULL_ID,
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_10_PENCE_PARTIAL_ID]:
    TRANSACTION_NOTE_COIN_ITEM_ID.BAG_10_PENCE_FULL_ID,
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_5_PENCE_PARTIAL_ID]:
    TRANSACTION_NOTE_COIN_ITEM_ID.BAG_5_PENCE_FULL_ID,
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_PENCE_PARTIAL_ID]:
    TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_PENCE_FULL_ID,
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_PENCE_PARTIAL_ID]:
    TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_PENCE_FULL_ID,
};

export const fullBagCoinIDsToName = {
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_POUND_FULL_ID]: "£2",
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_POUND_FULL_ID]: "£1",
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_50_PENCE_FULL_ID]: "50p",
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_20_PENCE_FULL_ID]: "20p",
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_10_PENCE_FULL_ID]: "10p",
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_5_PENCE_FULL_ID]: "5p",
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_PENCE_FULL_ID]: "2p",
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_PENCE_FULL_ID]: "1p",
};

export const partBagCoinIDsToName = {
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_POUND_PARTIAL_ID]: "£2",
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_POUND_PARTIAL_ID]: "£1",
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_50_PENCE_PARTIAL_ID]: "50p",
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_20_PENCE_PARTIAL_ID]: "20p",
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_10_PENCE_PARTIAL_ID]: "10p",
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_5_PENCE_PARTIAL_ID]: "5p",
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_PENCE_PARTIAL_ID]: "2p",
  [TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_PENCE_PARTIAL_ID]: "1p",
};

export const initialCoinValues: ladderRowCombinedItem[] = [
  {
    initialCoinValues: true,
    fullBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_POUND_FULL_ID,
    nameAsString: "£2",
    fullBagIndividualValueInPence: 200,
    fullBagValueInPence: 50000,
    fullBagQuantity: 0,
    fullBagCalculatedValue: 0,
    partBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_POUND_PARTIAL_ID,
    partBagIndividualValue: 200,
    partBagCalculatedValue: 0,
    partBagQuantity: 0,
    combinedValueInPence: 0,
    cashType: "Coins",
    currency: "£",
    btnDisable: null,
    alertCss: "normalCss",
    qAlertCss: "normalCss",
    errorMsg: "",
    qErrorMsg: "",
    symbol: "£",
  },
  {
    fullBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_POUND_FULL_ID,
    nameAsString: "£1",
    fullBagIndividualValueInPence: 100,
    fullBagValueInPence: 50000,
    fullBagQuantity: 0,
    fullBagCalculatedValue: 0,
    partBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_POUND_PARTIAL_ID,
    partBagIndividualValue: 100,
    partBagCalculatedValue: 0,
    partBagQuantity: 0,
    combinedValueInPence: 0,
    cashType: "Coins",
    currency: "£",
    btnDisable: null,
    alertCss: "normalCss",
    qAlertCss: "normalCss",
    errorMsg: "",
    qErrorMsg: "",
    symbol: "£",
  },
  {
    fullBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_50_PENCE_FULL_ID,
    nameAsString: "50p",
    fullBagIndividualValueInPence: 50,
    fullBagValueInPence: 50000,
    fullBagQuantity: 0,
    fullBagCalculatedValue: 0,
    partBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_50_PENCE_PARTIAL_ID,
    partBagIndividualValue: 50,
    partBagCalculatedValue: 0,
    partBagQuantity: 0,
    combinedValueInPence: 0,
    cashType: "Coins",
    currency: "£",
    btnDisable: null,
    alertCss: "normalCss",
    qAlertCss: "normalCss",
    errorMsg: "",
    qErrorMsg: "",
    symbol: "p",
  },
  {
    fullBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_20_PENCE_FULL_ID,
    nameAsString: "20p",
    fullBagIndividualValueInPence: 20,
    fullBagValueInPence: 25000,
    fullBagQuantity: 0,
    fullBagCalculatedValue: 0,
    partBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_20_PENCE_PARTIAL_ID,
    partBagIndividualValue: 20,
    partBagCalculatedValue: 0,
    partBagQuantity: 0,
    combinedValueInPence: 0,
    cashType: "Coins",
    currency: "£",
    btnDisable: null,
    alertCss: "normalCss",
    qAlertCss: "normalCss",
    errorMsg: "",
    qErrorMsg: "",
    symbol: "p",
  },
  {
    fullBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_10_PENCE_FULL_ID,
    nameAsString: "10p",
    fullBagIndividualValueInPence: 10,
    fullBagValueInPence: 10000,
    fullBagQuantity: 0,
    fullBagCalculatedValue: 0,
    partBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_10_PENCE_PARTIAL_ID,
    partBagIndividualValue: 10,
    partBagCalculatedValue: 0,
    partBagQuantity: 0,
    combinedValueInPence: 0,
    cashType: "Coins",
    currency: "£",
    btnDisable: null,
    alertCss: "normalCss",
    qAlertCss: "normalCss",
    errorMsg: "",
    qErrorMsg: "",
    symbol: "p",
  },
  {
    fullBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_5_PENCE_FULL_ID,
    nameAsString: "5p",
    fullBagIndividualValueInPence: 5,
    fullBagValueInPence: 10000,
    fullBagQuantity: 0,
    fullBagCalculatedValue: 0,
    partBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_5_PENCE_PARTIAL_ID,
    partBagIndividualValue: 5,
    partBagCalculatedValue: 0,
    partBagQuantity: 0,
    combinedValueInPence: 0,
    cashType: "Coins",
    currency: "£",
    btnDisable: null,
    alertCss: "normalCss",
    qAlertCss: "normalCss",
    errorMsg: "",
    qErrorMsg: "",
    symbol: "p",
  },
  {
    fullBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_PENCE_FULL_ID,
    nameAsString: "2p",
    fullBagIndividualValueInPence: 2,
    fullBagValueInPence: 2000,
    fullBagQuantity: 0,
    fullBagCalculatedValue: 0,
    partBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_PENCE_PARTIAL_ID,
    partBagIndividualValue: 2,
    partBagCalculatedValue: 0,
    partBagQuantity: 0,
    combinedValueInPence: 0,
    cashType: "Coins",
    currency: "£",
    btnDisable: null,
    alertCss: "normalCss",
    qAlertCss: "normalCss",
    errorMsg: "",
    qErrorMsg: "",
    symbol: "p",
  },
  {
    fullBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_PENCE_FULL_ID,
    nameAsString: "1p",
    fullBagIndividualValueInPence: 1,
    fullBagValueInPence: 2000,
    fullBagQuantity: 0,
    fullBagCalculatedValue: 0,
    partBagID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_PENCE_PARTIAL_ID,
    partBagIndividualValue: 1,
    partBagCalculatedValue: 0,
    partBagQuantity: 0,
    combinedValueInPence: 0,
    cashType: "Coins",
    currency: "£",
    btnDisable: null,
    alertCss: "normalCss",
    qAlertCss: "normalCss",
    errorMsg: "",
    qErrorMsg: "",
    symbol: "p",
  },
];

export const NOTES_DETAIL = [
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_50_POUND_ID,
    Cash: 50.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_20_POUND_ID,
    Cash: 20.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_10_POUND_ID,
    Cash: 10.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.NOTE_5_POUND_ID,
    Cash: 5.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Notes",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    errorMsg: "",
    QerrorMsg: "",
  },
];

export const COINS_DETAIL = [
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_POUND_PARTIAL_ID,
    Cash: 2.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_POUND_PARTIAL_ID,
    Cash: 1.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_50_PENCE_PARTIAL_ID,
    Cash: 50,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_20_PENCE_PARTIAL_ID,
    Cash: 20,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_10_PENCE_PARTIAL_ID,
    Cash: 10,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_5_PENCE_PARTIAL_ID,
    Cash: 5,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_PENCE_PARTIAL_ID,
    Cash: 2,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_PENCE_PARTIAL_ID,
    Cash: 1,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
];

export const COINS_DETAIL_PART_BAG = [
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_POUND_PARTIAL_ID,
    Cash: 2.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_POUND_PARTIAL_ID,
    Cash: 1.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_50_PENCE_PARTIAL_ID,
    Cash: 50,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_20_PENCE_PARTIAL_ID,
    Cash: 20,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_10_PENCE_PARTIAL_ID,
    Cash: 10,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_5_PENCE_PARTIAL_ID,
    Cash: 5,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_PENCE_PARTIAL_ID,
    Cash: 2,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_PENCE_PARTIAL_ID,
    Cash: 1,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
];

export const COINS_FULL_BAG_DETAIL = [
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_POUND_FULL_ID,
    Cash: 2.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_POUND_FULL_ID,
    Cash: 1.0,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "£",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_50_PENCE_FULL_ID,
    Cash: 50,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_20_PENCE_FULL_ID,
    Cash: 20,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_10_PENCE_FULL_ID,
    Cash: 10,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_5_PENCE_FULL_ID,
    Cash: 5,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_2_PENCE_FULL_ID,
    Cash: 2,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
  {
    itemID: TRANSACTION_NOTE_COIN_ITEM_ID.BAG_1_PENCE_FULL_ID,
    Cash: 1,
    Quantity: null,
    value: "£0.00",
    cashType: "Coins",
    currency: "p",
    btnDisable: true,
    alertCss: "normalCss",
    QalertCss: "normalCss",
    looseCoin: null,
    errorMsg: "",
    QerrorMsg: "",
  },
];
