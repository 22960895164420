import { BranchCashOverview } from "@bbo/api/generator";
import { useState } from "react";
import CashDrawerItem from "./CashDrawerItem";

type Props = {
  items: BranchCashOverview[];
  showAll?: boolean;
  isStockData?: boolean;
};

const CashDrawerList = ({ items, isStockData, showAll }: Props) => {
  const [count, setCount] = useState(15);

  const containerStyle = showAll
    ? "grid grid-cols-1 lg:grid-cols-4 gap-7"
    : "float-left h-40 overflow-x-scroll whitespace-nowrap";

  const LoadMore = () => {
    if (showAll && count !== items?.length && count < items?.length) {
      const handleClick = () => setCount(items?.length);

      return (
        <div
          className="main morebtn justify-center flex-col cursor-pointer items-center flex block w-full grid-cols-4 gap-5"
          onClick={handleClick}>
          <p> More</p>
          <div>
            <i className="fas fa-arrow-down mt-4"></i>
          </div>
        </div>
      );
    }
    return null;
  };

  const renderedItems = items
    ?.filter((item, index) => (showAll && index < count) || (!showAll && !item?.counterID))
    .map((item, index) => (
      <CashDrawerItem key={index} data={item} index={index} isStockData={isStockData} />
    ));

  return (
    <>
      <h2 className="account-group mb-1">Cash Drawers {showAll}</h2>
      <div className={`w-full accounting-card ${containerStyle}`}>
        {renderedItems}
        <LoadMore />
      </div>
    </>
  );
};

export default CashDrawerList;
