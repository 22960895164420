import { useGetPouchList } from "@bbo/api/generator";
import { CashDetailContext } from "@bbo/context";
import { PreparedPouch } from "@bbo/features/AdhocBalance/preparedPouch";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { useGlobalState } from "@bbo/lib";
import AdhocCashBalancing from "@bbo/screens/AdhocCashBalancing";
import { useEffect, useMemo, useState } from "react";
import { useAdhocCashBalancingState } from "./CashBalanceHistoryUtil";
import { useAccountingLocationApiCall } from "./CashBalanceHooks/useAccountingLocationApiCall";
import { useParams } from "react-router-dom";

const SafeCashBalancing = () => {
  const { accountingLocationID } = useParams();
  const { setTitle } = useTitle();
  const [, setIsReadonlyMode] = useGlobalState("isReadonlyMode");
  const [param, setparam] = useState({
    fromDate: Math.floor((Date.now() - 42 * 24 * 60 * 60 * 1000) / 1000),
    toDate: Math.floor(Date.now() / 1000),
  });
  useEffect(() => {
    setTitle("Cash Drawer Cash Balancing");
    setIsReadonlyMode({ isReadonlyMode: false });
  }, [setTitle, setIsReadonlyMode]);

  const { data: accountingLocationCashDeclaration } = useAccountingLocationApiCall();

  useEffect(() => {
    setparam({
      fromDate: accountingLocationCashDeclaration?.timestamp,
      toDate: Math.floor(Date.now() / 1000),
    });
  }, [accountingLocationCashDeclaration]);

  const { cashDetail, setCashDetail, activitiesBetweenBalancesTransactions } =
    useAdhocCashBalancingState(param);

  const { data: pouchListUnfiltered } = useGetPouchList({
    fromDate: accountingLocationCashDeclaration?.lastDeclarationTimestamp,
    toDate: Math.floor(Date.now() / 1000),
  });

  const pouchListFiltered = useMemo(() => {
    return {
      suspendedPouches: pouchListUnfiltered?.data?.filter((pouch) => pouch.status === "suspended"),
      preparedPouches: pouchListUnfiltered?.data?.filter((pouch) => pouch.status === "prepared"),
    };
  }, [pouchListUnfiltered?.data]);

  return (
    <CashDetailContext.Provider
      value={{
        cashDetail,
        setCashDetail,
        accountingLocationID,
      }}>
      <AdhocCashBalancing
        pouchData={{
          title: "Pouches in Safe",
          content: <PreparedPouch pouchList={pouchListFiltered} />,
        }}
        activitiesBetweenBalancesTransactions={activitiesBetweenBalancesTransactions}
      />
    </CashDetailContext.Provider>
  );
};

export default SafeCashBalancing;
