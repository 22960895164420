import { AxiosResponse } from "axios";
import { useAppDispatch } from "../hooks";
import { logoutFederatedUser } from "../reduxStore/auth.slice";
import { expiredTokenCheck } from "./expiredToken";

export const useResponse401Interceptor = () => {
  const dispatch = useAppDispatch();

  const response404Interceptor = [
    // onFulfilled
    (response: AxiosResponse) => {
      return response;
    },
    // onRejected
    (error: any) => {
      if (error?.response?.status === 401 || expiredTokenCheck(error)) {
        dispatch(logoutFederatedUser());
      }
      return Promise.reject(error);
    },
  ];

  return {
    response404Interceptor,
  };
};
