import "@bbo/components/layout/index.css";
import Loader from "@bbo/components/Loader";
import { useContext } from "react";
import { AccountingOverview } from "./AccountingOverview";
import { ActionList } from "./ActionList";
import { CashManagementBranchOverviewContext } from "./BranchOverviewProvider";
import HeaderTabs from "./HeaderTabs";

export const BranchOverview = () => {
  const { isLoading, accountingLocation, setAccountingLocation } = useContext(
    CashManagementBranchOverviewContext,
  );

  return (
    <>
      {isLoading && <Loader />}
      <HeaderTabs />
      <AccountingOverview />
      {accountingLocation ? (
        <ActionList data={accountingLocation} onClose={() => setAccountingLocation(null)} />
      ) : null}
    </>
  );
};
