import {
  BboDeclarationCashCommodity,
  CashCommodityResponseResponse,
  SystemCashListResponseResponse,
} from "@bbo/api/generator";
import { BP_TP_STATUS, BP_TP_VIEW_HEADERS } from "@bbo/constants";
import { GREENCODE } from "@bbo/features/constants";
import {
  AllAccountsInterface,
  BpTpButtonVisibilityStatus,
  BpTpDeclaration,
  BranchViewCashInterface,
  BranchViewForexInterface,
  BranchViewObsoleteStockInterface,
  BranchViewStockInterface,
  DetailViewCashInterface,
  DetailViewForexInterface,
  DetailViewObsoleteInterface,
  DetailViewStockInterface,
} from "@bbo/interfaces/BpTp";

const { COMPLETED, COMPLETE_BALANCE } = BP_TP_STATUS;
const { COMMODITY, STATUS, ACTION, PROGRESS, VARIANCE } = BP_TP_VIEW_HEADERS;
export const initialEachCashCommodity: DetailViewCashInterface = {
  cashRecount: true,
  cashAction: COMPLETED,
  cashProgressColor: GREENCODE,
};
export const initialEachForexCommodity: DetailViewForexInterface = {
  forexRecount: true,
  forexAction: COMPLETED,
  forexProgressColor: GREENCODE,
};
export const initialEachStockCommodity: DetailViewStockInterface = {
  stockAction: COMPLETED,
  stockProgressColor: GREENCODE,
  stockRecount: true,
};
export const initialEachObsoleteCommodity: DetailViewObsoleteInterface = {
  obsoleteRecount: true,
  obsoleteAction: COMPLETED,
  obsoleteProgressColor: GREENCODE,
};

export const initialCashCommodity: BranchViewCashInterface = {
  cashData: [],
  cashPercentage: 100,
  cashAction: COMPLETED,
  cashProgressColor: GREENCODE,
};
export const cashReducer = (
  previous: BranchViewCashInterface,
  update: Partial<BranchViewCashInterface>,
) => ({
  ...previous,
  ...update,
});
export const initialForexCommodity: BranchViewForexInterface = {
  forexData: [],
  forexPercentage: 100,
  forexAction: COMPLETED,
  forexProgressColor: GREENCODE,
};
export const forexReducer = (
  previous: BranchViewForexInterface,
  update: Partial<BranchViewForexInterface>,
) => ({
  ...previous,
  ...update,
});
export const initialStockCommodity: BranchViewStockInterface = {
  stockData: [],
  stockPercentage: 100,
  stockAction: COMPLETED,
  stockProgressColor: GREENCODE,
};
export const stockReducer = (
  previous: BranchViewStockInterface,
  update: Partial<BranchViewStockInterface>,
) => ({
  ...previous,
  ...update,
});
export const initialObsoleteCommodity: BranchViewObsoleteStockInterface = {
  obsoleteData: [],
  obsoletePercentage: 100,
  obsoleteAction: COMPLETED,
  obsoleteProgressColor: GREENCODE,
};
export const obsoleteReducer = (
  previous: BranchViewObsoleteStockInterface,
  update: Partial<BranchViewObsoleteStockInterface>,
) => ({
  ...previous,
  ...update,
});
// TODO if all commodity functionalities are implemented then assign false to all commodities
export const initialAllAccountsStatus: AllAccountsInterface = {
  cash: false,
  forex: true,
  stock: true,
  obsolete: true,
};
export const accountsReducer = (
  previous: AllAccountsInterface,
  update: Partial<AllAccountsInterface>,
) => ({
  ...previous,
  ...update,
});
export const defaultAllAccountsStatus = { cash: true, forex: true, stock: true, obsolete: true };
export const emptyTransactionProps = {
  transactionPostBody: null,
  postBpTpTransaction: null,
  isBpTpTransactionSuccess: null,
  isBpTpTransactionError: null,
};
export const emptyPostBpTPProps = {
  postCreateBp: null,
  isCreateBpSuccess: null,
  postCreateTp: null,
  isCreateTpSuccess: null,
  createTpResponse: null,
  createBpResponse: null,
  isCreateBpError: null,
  isCreateTpError: null,
};
export const completeBpTpButtonStatus = [
  BpTpButtonVisibilityStatus.COMMIT_BUTTON_VISIBILITY_STATUS_SHOW,
  BpTpButtonVisibilityStatus.COMMIT_BUTTON_VISIBILITY_STATUS_DISABLE,
];
export const branchViewHeaderLists = [
  { headerName: COMMODITY, css: { width: "w-1/5" } },
  { headerName: PROGRESS, css: { width: "w-4/6" } },
  { headerName: VARIANCE, css: { width: "width-15" } },
];
export const detailViewHeaderLists = [
  { headerName: COMMODITY, css: { width: "w-1/5" } },
  { headerName: STATUS, css: { width: "w-3/6" } },
  { headerName: VARIANCE, css: { width: "width-15" } },
  { headerName: ACTION, css: { width: "width-15" } },
];
const initial = {
  varianceValue: 0,
  progress: 0,
  accountingLocations: {},
};

const sortLocationNumbers = (locationName: string) => {
  const locationNumber = Number(locationName.split(" ").pop());
  return isNaN(locationNumber) ? -1 : locationNumber;
};
export const listAllAccountingLocationNames = (locations: BpTpDeclaration[]) => {
  return locations?.map(({ accountingLocationName }) => {
    return accountingLocationName;
  });
};
export const sortHistoryCashCommodityData = (locations: BpTpDeclaration[]) => {
  return listAllAccountingLocationNames(locations)?.sort(
    (a, b) => sortLocationNumbers(a) - sortLocationNumbers(b),
  );
};

export const sortCashCommodityData = (cashCommodityData: BboDeclarationCashCommodity[]) => {
  return cashCommodityData?.sort(
    (a, b) =>
      sortLocationNumbers(a.accountingLocationName) - sortLocationNumbers(b.accountingLocationName),
  );
};

export const dataFormation = (
  cashDeclarationsData: CashCommodityResponseResponse,
  systemCashData: SystemCashListResponseResponse,
) => {
  const sanitisedCashDeclarations = cashDeclarationsData?.data?.filter((item) => {
    const match =
      systemCashData &&
      systemCashData?.find(
        (bpItem: { accountingLocationID: string }) =>
          bpItem.accountingLocationID === item.accountingLocationID,
      );
    return !(match && match.updatedBy.transactionID === "DEFAULT");
  });

  const cashDeclarations: BboDeclarationCashCommodity[] =
    sortCashCommodityData(sanitisedCashDeclarations);

  return cashDeclarations?.reduce((initial, cashDeclaration) => {
    const {
      declarationCompleted,
      declarationID,
      accountingLocationName,
      toDateVariance,
      accountingLocationID,
    } = cashDeclaration;
    return {
      ...initial,
      varianceValue: cashDeclarationsData.totalVarianceValue,
      progress: cashDeclarationsData.progress,
      accountingLocations: {
        ...initial.accountingLocations,
        [accountingLocationID]: {
          declarationCompleted,
          accountingLocationName,
          accountingLocationID: accountingLocationID,
          varianceValue: toDateVariance,
          balancingDeclID: declarationID,
        },
      },
    };
  }, initial);
};
