import { BranchCashOverview } from "@bbo/api/generator";
import CounterItem from "./CounterItem";

type Props = {
  items: BranchCashOverview[];
  isStockData?: boolean;
};

const CounterList = ({ items, isStockData }: Props) => {
  return (
    <>
      <h2 className={"account-group mb-1 "}>Counters</h2>
      <div className="w-full accounting-card float-left h-52 overflow-x-scroll whitespace-nowrap">
        {items?.map((item, index) => (
          <CounterItem
            key={item.accountingLocationID}
            data={item}
            index={index}
            isStockData={isStockData}
          />
        ))}
      </div>
    </>
  );
};

export default CounterList;
