import { BranchCashOverview, CashDrawerData, ForexDrawerData } from "@bbo/api/generator";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { StockCardData } from "../StockBranchOverview/StockCardData/StockCardData";
import { ActionList } from "./ActionList";
import { useTableList } from "./useTableList";
import { CashManagementBranchOverviewContext } from "./BranchOverviewProvider";

type Props = {
  data: BranchCashOverview;
  index: number;
  isStockData?: boolean;
};

const CashDrawerItem = ({ data, isStockData }: Props) => {
  const { setAccountingLocation } = useContext(CashManagementBranchOverviewContext);
  const { orderedTable } = useTableList(data);

  return (
    <div
      className="main cursor-pointer widthAccounting mr-8 inline-block"
      onClick={() => setAccountingLocation(data)}>
      <div className="heading">
        <span>{data?.accountingLocationName}</span>
        {data?.counterID ? (
          <>
            <i className="fas fa-link mx-2"></i>
            <span>{data.counterName}</span>
          </>
        ) : null}
      </div>
      <hr className="border-gray-200 border-bottom-none" />
      <div className="third-heading">
        {isStockData ? (
          <StockCardData
            currentVariance={data.currentVariance}
            lastBalancedDateTime={data.lastDeclarationTimestamp}
            toDateVariance={data.toDateVariance}
          />
        ) : (
          <table className="w-full">
            <tbody className="counterPad">{orderedTable}</tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CashDrawerItem;
