import { Filter, Filters } from "@bbo/components/DynamoTable/dynamoTableTypes";
import { filterSwitch, getGeneratedValues } from "./useDynamoPagination/filterHelpers";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { DYNAMO_ITEMS_PER_PAGE } from "@bbo/components/DynamoTable/dynamoTableHelpers";

const itemsPerPage = DYNAMO_ITEMS_PER_PAGE;

interface UseCustomPaginatorProps<T> {
  data: T[];
  initialFilters: Filters;
  filters: Filters;
  setFilters: Dispatch<SetStateAction<Filters>>;
}

export const useCustomPaginator = <T>({
  data,
  initialFilters,
  filters,
  setFilters,
}: UseCustomPaginatorProps<T>) => {
  const [currentPage, setCurrentPage] = useState(0);

  const transformData = <TData>(allData: TData[], filters: Filters): TData[] => {
    if (!allData?.length) return allData;

    return allData.map((item) =>
      Object.keys(item).reduce((acc, itemKey) => {
        acc[itemKey] = filters?.[itemKey]?.transform
          ? filters[itemKey].transform(item[itemKey])
          : item[itemKey];
        return acc;
      }, {} as typeof item),
    );
  };

  const filterData = <TData>(allData: TData[], filters: Filters): TData[] => {
    if (!allData?.length || !filters) return allData;
    const { timestamp, ...otherFilters } = filters;
    let filteredData = allData;
    console.log({ allData, filters });
    for (const key in otherFilters) {
      filteredData = filterSwitch(key, otherFilters[key] as Filter<string>, filteredData);
    }

    return filteredData;
  };

  useEffect(() => {
    setFilters((prev) => getGeneratedValues<unknown>(transformData(data, prev), prev));
  }, [data, setFilters]);

  useEffect(() => {
    setCurrentPage(0);
  }, [filters]);

  const transformedData = transformData(data, filters);
  const filteredData = filterData(transformedData, filters);

  const paginatedData = !filteredData?.length
    ? []
    : filteredData.slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage);

  const hasNext = filteredData?.length >= (currentPage + 1) * itemsPerPage;
  const hasPrev = currentPage > 0;

  const getNext = () => {
    setCurrentPage((prev) => prev + 1);
  };

  const getPrev = () => {
    setCurrentPage((prev) => prev - 1);
  };

  const resetFilters = () => {
    setFilters(initialFilters);
  };

  return {
    paginatedData,
    getNext,
    getPrev,
    hasNext,
    hasPrev,
    filters,
    setFilters,
    resetFilters,
  };
};
