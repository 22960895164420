import { PouchDetails, PouchStatus } from "@bbo/api/generator";
import {
  Pouch_Acceptance_History_Details,
  Pouch_Despatch_History_Details,
  Pouch_Preparation_History_Details,
} from "@bbo/constants";
import { featureFlags } from "@bbo/constants/FeatureConstants";
import {
  COIN_MAPPING,
  CURRENCY_ITEM_ID,
  FULL_COIN_BAGS,
  PARTIAL_COIN_BAGS,
} from "@bbo/constants/currencyItemID";
import { amountUnSignedPound } from "@bbo/utils/currencyFormatter";
import { getOnlyDate, getOnlyTime } from "@bbo/utils/dateTimeFormatter";
import React, { useState } from "react";
import UserAvatar from "../../assets/images/downarrow.png";
import EditHistoryDetails from "./EditHistoryDetails";

interface PouchPopupModalProps {
  showModal: boolean;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  data?: PouchDetails;
  onPrintConfirmation?: () => void;
  onPrintSummary?: () => void;
}

const getTitle = (status: PouchStatus) => {
  switch (status) {
    case "accepted":
      return Pouch_Acceptance_History_Details;
    case "despatched":
      return Pouch_Despatch_History_Details;
    default:
      return Pouch_Preparation_History_Details;
  }
};

const PouchPopupModel = ({
  data,
  showModal,
  setShowModal,
  onPrintConfirmation,
  onPrintSummary,
}: PouchPopupModalProps) => {
  const [isActive, setActive] = useState(false);
  const [viewActive, setviewActive] = useState(false);

  const toggleTable = () => {
    setActive(!isActive);
  };

  const getCombinedItemID = (key: string) =>
    PARTIAL_COIN_BAGS[key] || FULL_COIN_BAGS[key]
      ? COIN_MAPPING[(PARTIAL_COIN_BAGS[key] || FULL_COIN_BAGS[key]).split(" ")[0]]
      : key;

  const displayData = () =>
    data &&
    data.items &&
    Object.values(data.items).reduce((acc, item) => {
      const hasPartialBag = Object.keys(data?.items).find((itemID) => itemID in PARTIAL_COIN_BAGS);
      const isPartialBag = item?.itemID in PARTIAL_COIN_BAGS;
      const itemID = hasPartialBag ? getCombinedItemID(item?.itemID).toString() : item?.itemID;

      console.log("acc[itemID]", acc[itemID]);
      acc[itemID] = acc[itemID]
        ? {
            ...acc[itemID],
            itemValue: acc[itemID].itemValue + item.itemValue,
            fullBagItemQuantity: isPartialBag ? acc[itemID].fullBagItemQuantity : item.itemQuantity,
            partBagItemQuantity: isPartialBag ? item.itemQuantity : acc[itemID].partBagItemQuantity,
          }
        : {
            itemID,
            itemValue: item.itemValue,
            fullBagItemQuantity: item.itemQuantity,
            partBagItemQuantity: 0,
          };

      return acc;
    }, {});

  console.log({ displayData: displayData(), dataItems: data?.items });

  if (!showModal) return null;

  const title = getTitle(data?.status);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-2 mx-auto max-w-xl">
          <div className="border-0 rounded-lg shadow-lg relative height-39 overflow-y-auto flex flex-col w-full bg-white outline-none focus:outline-none p-4	">
            <div className="flex items-center justify-between">
              <h3 className="text-lg font-bold text-color">{title}</h3>
              <button
                className="ml-auto border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                onClick={() => setShowModal(false)}>
                <span className="text-black opacity-1 w-6 text-3xl block outline-none focus:outline-none">
                  ×
                </span>
              </button>
            </div>
            <div className="flex items-center justify-center p-4  rounded-b">
              <table className="w-full movementTable">
                <tbody className="bg-white divide-y divide-gray-200">
                  <tr>
                    <td>
                      <b>Pouch Content Details</b>
                    </td>
                    <td>
                      <div className="flex justify-between">
                        <button className="ml-2 flex colorBlue items-center" onClick={toggleTable}>
                          Break Down
                          <img
                            className={`h-4 w-4 ml-2 ${
                              isActive
                                ? "transform rotate-180 transition duration-150 ease-in-out"
                                : ""
                            }`}
                            src={UserAvatar}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                  {isActive ? (
                    <tr>
                      <td colSpan={2}>
                        <table className="breakdownTable">
                          <thead>
                            <tr>
                              <th>
                                <b>Denomination</b>
                              </th>
                              <th></th>
                              {["useable_coins_full_bag", "useable_coins_part_bag"].includes(
                                data?.pouchSubType,
                              ) ? (
                                data?.pouchSubType === "useable_coins_part_bag" ? (
                                  <>
                                    <th>
                                      <b>No. of bags</b>
                                    </th>
                                    <th>
                                      <b>Loose Coin</b>
                                    </th>
                                  </>
                                ) : (
                                  <>
                                    <th>
                                      <b>No. of bags</b>
                                    </th>
                                    <th></th>
                                  </>
                                )
                              ) : (
                                <th>
                                  <b>Quantity</b>
                                </th>
                              )}
                              <th></th>
                              <th>
                                <b>Value</b>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {displayData &&
                              Object.values(displayData() || {}).map((item, index) => {
                                return [
                                  "useable_coins_full_bag",
                                  "useable_coins_part_bag",
                                ].includes(data?.pouchSubType) ? (
                                  <tr key={index}>
                                    <td>{CURRENCY_ITEM_ID[item?.itemID]}</td>
                                    <td>X</td>
                                    <td>{item?.fullBagItemQuantity}</td>
                                    <td>{item?.partBagItemQuantity}</td>
                                    <td>=</td>
                                    <td>{amountUnSignedPound(item?.itemValue)}</td>
                                  </tr>
                                ) : (
                                  <tr key={index}>
                                    <td>{CURRENCY_ITEM_ID[item?.itemID]}</td>
                                    <td>X</td>
                                    <td>{item?.fullBagItemQuantity}</td>
                                    <td>=</td>
                                    <td>{amountUnSignedPound(item?.itemValue)}</td>
                                  </tr>
                                );
                              })}
                          </tbody>
                        </table>
                      </td>
                    </tr>
                  ) : null}
                  <tr>
                    <td>
                      <b>Pouch ID</b>
                    </td>
                    <td>{data?.pouchID}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Pouch Type</b>
                    </td>
                    <td className="capitalize">{data?.pouchType}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Prepared by User Name</b>
                    </td>
                    <td>{data?.updatedBy?.userName}</td>
                  </tr>
                  <tr>
                    <td>
                      <b> Prepared by Smart ID</b>
                    </td>
                    <td>{data?.updatedBy?.smartID}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Date</b>
                    </td>
                    <td>{data ? getOnlyDate(data.updatedBy?.transactionTimestamp) : ""}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Time</b>
                    </td>
                    <td>{data ? getOnlyTime(data.updatedBy?.transactionTimestamp) : ""}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Value</b>
                    </td>
                    <td>{data ? amountUnSignedPound(data.totalValue) : ""}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Status</b>
                    </td>
                    <td className="capitalize">{data?.status}</td>
                  </tr>
                  {featureFlags.shouldShowEditHistoryDetails && <EditHistoryDetails data={data} />}
                </tbody>
              </table>
            </div>
            <div className="flex justify-center pt-2">
              <button className="accounting-button mr-1" onClick={onPrintSummary}>
                Print Summary
              </button>
              <button className="accounting-button ml-1" onClick={onPrintConfirmation}>
                Print Confirmation
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default PouchPopupModel;
