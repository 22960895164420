import { BagTypeKeys, bagTypes } from "./pouchItemsInfo";

export const checkBarcodeBagType = (barcode: string) => {
  // The 2nd and 3rd characters of the barcode represent the bag type
  const bagType = barcode.substring(1, 3);
  return bagTypes[bagType as BagTypeKeys];
};

export const checkBarcodeValid = (barcode: string) => {
  // First barcode digit represents the carrier - 3 is POL
  if (barcode.length !== 12 || barcode[0] !== "3") return false;

  const calculateChecksum = (evenSum: number, oddSum: number) => (evenSum * 3 + oddSum) % 10;

  let evenIndexSum = 0;
  let oddIndexSum = 0;

  for (let i = 0; i < barcode.length - 1; i++) {
    const digit = parseInt(barcode[i], 10);
    if (i % 2 === 0) {
      evenIndexSum += digit;
    } else {
      oddIndexSum += digit;
    }
  }

  const checksum = calculateChecksum(evenIndexSum, oddIndexSum);
  const correction = checksum >= 5 ? 10 - checksum : checksum;
  const lastDigit = parseInt(barcode[barcode.length - 1], 10);

  return correction === lastDigit;
};

// Calculate the check digit for a given barcode
export const calculateCheckDigit = (barcode: string): number => {
  let oddSum = 0;
  let evenSum = 0;

  // Calculate the sum of digits in odd and even positions
  for (let i = 0; i < barcode.length; i++) {
    const digit = parseInt(barcode[i], 10);
    if (i % 2 === 0) {
      oddSum += digit;
    } else {
      evenSum += digit;
    }
  }

  // Calculate the total sum following the given calculation rules
  const total = oddSum * 3 + evenSum;

  // Round up the total to the next multiple of 10
  const roundedUp = Math.ceil(total / 10) * 10;

  // Calculate the check digit by finding the difference between the rounded up total and the actual total
  return roundedUp - total;
};

// Checks if the given barcode is a valid POL barcode
export const isValidPOLBarcode = (barcode: string): boolean => {
  // Check if the barcode length is 12
  if (barcode.length !== 12) {
    return false;
  }

  // Check if the barcode contains only digits
  if (!/^\d{12}$/.test(barcode)) {
    return false;
  }

  // Check if the first barcode digit represents the carrier - 3 is POL
  if (barcode[0] !== "3") {
    return false;
  }

  // Extract the check digit from the barcode
  const checkDigit = parseInt(barcode[11], 10);

  // Calculate the check digit using the first 11 digits of the barcode
  const calculatedCheckDigit = calculateCheckDigit(barcode.substring(0, 11));

  // Check if the given check digit matches the calculated check digit
  if (checkDigit !== calculatedCheckDigit) {
    return false;
  }

  // Extract the value of 'c' from the barcode
  const c = parseInt(barcode[2], 10);

  // Check if the value of 'c' is within the valid range (0-9)
  if (c < 0 || c > 9) {
    return false;
  }

  return true;
};
