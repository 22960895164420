/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { Menu } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.
 * @summary Returns all menus by roles
 */
export const useGetMenuListHook = () => {
  const getMenuList = useCustomInstance<Menu[]>();

  return () => {
    return getMenuList({ url: `/menu-management/menu`, method: "get" });
  };
};

export const getGetMenuListQueryKey = () => [`/menu-management/menu`];

export type GetMenuListQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetMenuListHook>>
>;
export type GetMenuListQueryError = ErrorType<void>;

export const useGetMenuList = <
  TData = AsyncReturnType<ReturnType<typeof useGetMenuListHook>>,
  TError = ErrorType<void>,
>(options?: {
  query?: UseQueryOptions<AsyncReturnType<ReturnType<typeof useGetMenuListHook>>, TError, TData>;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetMenuListQueryKey();

  const getMenuList = useGetMenuListHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetMenuListHook>>> = () =>
    getMenuList();

  const query = useQuery<AsyncReturnType<ReturnType<typeof useGetMenuListHook>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};
