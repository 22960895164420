export enum SCREENS {
  TASK_MANAGEMENT = "/taskManagement",
  BRANCH_SELECTION = "/branchSelection",
  PAID_IN = "/paidIn",
  PAID_OUT = "/paidOut",
  TRANSFER_IN = "/transferIn",
  HOME = "/home",
  TRANSFER_OUT = "/transferOut",
  BRANCH_OVERVIEW = "/branchOverview",
  CASH_BALANCE = "/cashBalance",
  POUCH_PREPARATION = "/pouchPreperation",
  MANAGE_CASH_DRAWER = "/manageCashDrawer",
  RECORD_KNOWN_VARIANCE = "/RecordKnownVariance",
  CASH_BALANCES_HISTORY = "/cashBalancesHistory",
  BALANCE_PERIOD = "/balancePeriod",
  TRADING_PERIOD = "/tradingPeriod",
  CASH_MOVEMENT_HISTORY = "/cashMovementHistory",
  DISCREPANCY_ACCOUNT = "/discrepancyAccount",
  MAIL_DESPATCH = "/mailDespatch",
  POUCH_PREPARATION_HISTORY = "/pouchPreparationHistory",
  STOCK_CHECK = "/stockCheck",
  BALANCE_TRADING_PERIOD_HISTORY = "/BalanceTradingPeriodHistory",
  STOCK_COUNT = "/stockCount",
  BRANCH_OVERVIEW_STOCK = "/branchOverviewStock",
  STOCK_COUNT_REVIEW = "/StockCountReview",
  BRANCH_OVERVIEW_FOREX = "/branchOverviewForex",
  CURRENCY_COUNT_SUMMARY = "/currencyCountSummary",
  TRANSACTION_LOG = "/TransactionLog",
  CURRENCY_COUNT = "/currencyCount",
  CURRENCY_COUNT_DENOMINATION_LADDER = "/currencyCountDenominationLadder",
  MANUAL_BOOK_IN_STOCK = "/manualBookInStock",
  CURRENCY_COUNT_REVIEW = "/currencyCountReview",
  SALES_SUMMARY = "/salesSummary",
  TEST = "/test",
  LOGIN = "/login",
}

export enum CASH_BALANCE_SCREENS {
  CASH_DRAWER = "CashDrawer",
  SAFE = "Safe",
  TP_BP_WITH_DECLARATION_ID = "TpBp",
  TP_BP_HISTORY = "TpBpHistory",
  TP_BP_WITHOUT_DECLARATION_ID = "TpBpWithoutDeclarationId",
}
