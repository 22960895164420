import { AXIOS_INSTANCE } from "@bbo/api/generator/mutator/useCustomInstance";
import { useAppDispatch, useAppSelector } from "@bbo/lib";
import { setAuthState } from "@bbo/lib/reduxStore/auth.slice";
import { getValueFromStorage, setValueInStorage } from "@bbo/utils/util";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { components } from "react-select";
import BackOffice from "../../assets/images/back-office.svg";
import UserAvatar from "../../assets/images/post-office-logo-all.png";
import { BranchSelectionDropDown } from "./BranchSelectionDropDown";

type OptionItem = {
  label: string;
  value: string;
};

const BranchSelection = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [selectedValue, setSelectedValue] = useState<OptionItem>(null);
  const [branchValidation, setBranchValidation] = useState(0);
  const authUserData = useAppSelector((root) => root.auth.authUserData);
  const { userName } = useAppSelector((root) => root.userName);

  useEffect(() => {
    // Prevent branch selection on application refresh if already logged In
    const mFadcode = getValueFromStorage("fadcode");
    const sysRoleName = getValueFromStorage("sysRoleName");
    if (mFadcode != null) {
      AXIOS_INSTANCE.defaults.headers.common["fadcode"] = mFadcode;
      dispatch(
        setAuthState({
          isLoggedIn: true,
          loading: false,
          authUserData: {
            ...authUserData,
            username: userName,
            role: sysRoleName,
            fadCode: mFadcode,
          },
        }),
      );
      navigate(`/taskManagement`);
    }
    // Should only run once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []); // Initially check and prevent already selected branch

  const updateBranch = (params: OptionItem) => {
    const labels = params.label.split("-");
    if (labels && labels.length > 1) {
      AXIOS_INSTANCE.defaults.headers.common["fadcode"] = params.value;
      setValueInStorage("fadcode", params.value);
      setValueInStorage("sysRoleName", labels[1].trim());
      dispatch(
        setAuthState({
          isLoggedIn: true,
          loading: false,
          authUserData: { ...authUserData, role: labels[1].trim(), fadCode: params.value },
        }),
      );
      navigate(`/taskManagement`);
    }
  };

  const options = authUserData?.sysRole?.map((data) => {
    return {
      value: data.fadCode,
      label: `${data.fadCode} - ${data.role}`,
    };
  });

  const groupedOptions = [
    {
      label: "",
      options: options,
    },
  ]; // filter options

  const handleChange = (params) => {
    setSelectedValue(params);
    setBranchValidation(0);
  };

  const navigateTaskManagement = () => {
    if (!selectedValue) {
      setBranchValidation(1);
    } else {
      updateBranch(selectedValue);
    }
  };
  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div className="arrowSelect"></div>
        </components.DropdownIndicator>
      )
    );
  };

  return (
    <div className="grid lg:grid-cols-2 gap-24">
      <div className="relative" id="left-view-container">
        <div className="left-branchselection h-full w-full flex justify-center absolute"></div>
        <div className="left-branchBackground h-full w-full flex justify-center absolute"></div>
        <div className="relative">
          <div className="flex mt-8 mb-8 pl-8">
            <img className="w-24 h-16 ml-3" src={UserAvatar} alt="User" />
          </div>
          <div className="flex mt-8 mb-8 pl-8 justify-center">
            <img className="w-48 h-48 ml-3" src={BackOffice} alt="User" />
          </div>
          <div className="float-left ml-16 w-full">
            <p className="w-full float-left  mb-5 branch-office-heading">Back Office</p>
            <p className="w-full float-left branch-wish-heading">
              Select the branch you wish to <br />
              access.
            </p>
          </div>
        </div>
      </div>
      <div
        className="right-branchselection h-full w-full flex  items-center"
        id="right-view-container">
        <div className="float-left w-10/12">
          <p className="w-full float-left branch-selection mb-4">Select Branch</p>
          <p className="w-full float-left branch-acess mb-8">
            Please select the branch you wish to access.
          </p>
          <p className="w-full float-left branch-list-of-branch  mb-4">List of Branches</p>
          {branchValidation === 1 ? (
            <p className="branchValidation">Please select the branch</p>
          ) : (
            ""
          )}
          <BranchSelectionDropDown
            selectedValue={selectedValue}
            groupedOptions={groupedOptions}
            handleChange={handleChange}
            DropdownIndicator={DropdownIndicator}
          />
          <button
            type="button"
            className="accounting-button w-full "
            onClick={navigateTaskManagement}>
            Next
          </button>
        </div>
      </div>
    </div>
  );
};

export default BranchSelection;
