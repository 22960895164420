import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type userDetails = { userName: string; smartID: string };
const initialState = { userName: "", smartID: "" };

export const userName = createSlice({
  name: "userName",
  initialState,
  reducers: {
    setUserName: (state, action: PayloadAction<userDetails>) => {
      state.userName = action.payload.userName;
      state.smartID = action.payload.smartID;
    },
  },
});

export const { setUserName } = userName.actions;

export default userName.reducer;
