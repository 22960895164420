import { CashDetail } from "@bbo/context/cashDetailContext";
import { CASH_DETAILS } from "@bbo/features/AdhocBalance/ladderConstant";
import { useTransactionApi } from "@bbo/lib/hooks/useTransactionApi";
import { useState } from "react";

export const useAdhocCashBalancingState = ({
  fromDate: lastDeclarationTimestamp,
  toDate: timestamp,
}) => {
  const [cashDetail, setCashDetail] = useState<CashDetail[]>([...CASH_DETAILS]);
  const [TpRecount, setTpRecount] = useState("Incomplete");

  const {
    status: recordLoadedStatus,
    cashMovementHistoryData: activitiesBetweenBalancesTransactions,
  } = useTransactionApi({
    fromDate: lastDeclarationTimestamp,
    toDate: timestamp,
  });

  return {
    cashDetail,
    setCashDetail,
    recordLoadedStatus,
    activitiesBetweenBalancesTransactions,
    TpRecount,
    setTpRecount,
  };
};
