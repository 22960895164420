import { useCallback } from "react";
import {
  DropdownValue,
  Filter,
  MultiDropdownFilter as BaseMultiDropdownFilter,
} from "../../dynamoTableTypes";
import { MultiDropdown } from "../MultiDropdown";

interface MultiDropdownFilterProps {
  fieldName: string;
  filter: BaseMultiDropdownFilter;
  onFilterChange: (filterKey: string, filter: Filter) => void;
}

export const MultiDropdownFilter = ({
  fieldName,
  filter,
  onFilterChange,
}: MultiDropdownFilterProps) => {
  const { values, placeholder } = filter;

  const onChange = useCallback(
    (newValues: DropdownValue[]) => {
      onFilterChange(fieldName, {
        ...filter,
        values: newValues,
      });
    },
    [fieldName, filter, onFilterChange],
  );

  return <MultiDropdown placeholder={placeholder} values={values} onChange={onChange} />;
};
