/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  BranchCashOverviewResponseResponse,
  BboCashManagementBadRequestResponse,
  UnauthorisedResponse,
  BboCashManagementForbiddenResponse,
  NotFoundResponse,
  FailureResponseResponse,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get cumulative cash details across all accounting location with association details as              well. Endpoint will get declared cash details (current variance and to date variance) for            all accounting locations. It will respond will data segregated based on counter                     association with cash drawers.
 * @summary Get cumulative cash details across all accounting location with association details as well.
 */
export const useGetCashBranchOverviewDetailsHook = () => {
  const getCashBranchOverviewDetails = useCustomInstance<BranchCashOverviewResponseResponse>();

  return () => {
    return getCashBranchOverviewDetails({
      url: `/BboCashManagementService/v1/branch-overview`,
      method: "get",
    });
  };
};

export const getGetCashBranchOverviewDetailsQueryKey = () => [
  `/BboCashManagementService/v1/branch-overview`,
];

export type GetCashBranchOverviewDetailsQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetCashBranchOverviewDetailsHook>>
>;
export type GetCashBranchOverviewDetailsQueryError = ErrorType<
  | BboCashManagementBadRequestResponse
  | UnauthorisedResponse
  | BboCashManagementForbiddenResponse
  | NotFoundResponse
  | FailureResponseResponse
>;

export const useGetCashBranchOverviewDetails = <
  TData = AsyncReturnType<ReturnType<typeof useGetCashBranchOverviewDetailsHook>>,
  TError = ErrorType<
    | BboCashManagementBadRequestResponse
    | UnauthorisedResponse
    | BboCashManagementForbiddenResponse
    | NotFoundResponse
    | FailureResponseResponse
  >,
>(options?: {
  query?: UseQueryOptions<
    AsyncReturnType<ReturnType<typeof useGetCashBranchOverviewDetailsHook>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetCashBranchOverviewDetailsQueryKey();

  const getCashBranchOverviewDetails = useGetCashBranchOverviewDetailsHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetCashBranchOverviewDetailsHook>>
  > = () => getCashBranchOverviewDetails();

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetCashBranchOverviewDetailsHook>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};
