import { useGlobalState } from "@bbo/lib/state";
import Select, {
  components,
  CSSObjectWithLabel,
  DropdownIndicatorProps,
  GroupBase,
  PropsValue,
} from "react-select";
import "./index.css";

/**
 * Alert/Popup view with
 * @param props
 * @returns
 */

interface iProps {
  onSelect?: (params: { value: string }) => void;
  value: string;
  isDisabled: boolean;
}

interface MOptions {
  label: string;
  value: string;
}

export const VarianceType = (props: iProps) => {
  const [RecordVarianceDataList] = useGlobalState("RecordVarianceData");

  const DropdownIndicator = (
    props: JSX.IntrinsicAttributes & DropdownIndicatorProps<unknown, boolean, GroupBase<unknown>>,
  ) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div className="arrowSelect"></div>
        </components.DropdownIndicator>
      )
    );
  };
  const customStyles = {
    control: (base: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...base,
      border: "1px solid #444",
      borderRadius: "4px",
      width: "80%",
      margin: "22px 0px",
      "&:hover": {
        border: "1px solid #444",
      },
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      padding: "0px",
      height: "72px",
    }),
    menu: (provided: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...provided,
      width: "80%",
    }),
    placeholder: (styles: CSSObjectWithLabel): CSSObjectWithLabel => ({
      ...styles,
      fontStyle: "normal",
      fontWeight: "400",
      fontSize: "24px",
      lineHeight: "34px",
      color: "#444",
    }),
  }; // filter options styling
  const onChange = (param: { label: string; value: string }) => {
    props.onSelect({
      value: param.value,
    });
  };

  let mOptions: PropsValue<MOptions> = [];
  if (RecordVarianceDataList && RecordVarianceDataList.length > 0) {
    mOptions = RecordVarianceDataList?.map((city) => {
      return { value: city.id, label: city.varianceType };
    });
  }

  return (
    <>
      <h2 className="mainHeadingRecord mb-1"> Variance Type</h2>
      <div className="VarianceType">
        <Select
          // isDisabled={props.isDisabled}
          options={mOptions}
          styles={customStyles}
          placeholder="Please select an option"
          onChange={(e: { label: string; value: string }) => {
            onChange(e);
          }}
          value={props.value}
          components={{ IndicatorSeparator: () => null, DropdownIndicator }}
          closeMenuOnSelect={true}
        />
      </div>
    </>
  );
};
