import "./index.css";

export interface IPopupAction {
  name: string;
  handleAction: () => void;
  style: string;
}

interface iPopupData {
  titleHeader?: string;
  title?: string;
  actions?: IPopupAction[];
  body?: any;
  onClose?: () => void;
  hideCrossBtn?: boolean;
  testId?: string;
}

const ActionButtons = ({ name, handleAction, style }: IPopupAction) => {
  return (
    <>
      <button
        className={`${style}`}
        onClick={() => {
          handleAction();
        }}>
        {name}
      </button>
    </>
  );
};

export const Popup = ({
  titleHeader,
  title,
  body,
  actions,
  onClose,
  hideCrossBtn,
  testId,
}: iPopupData) => {
  return (
    <div
      data-testid={testId}
      className="justify-center bg-opacity-75 transition-opacity bg-gray-500 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-auto max-w-5xl">
        <div className="p-8 relative flex flex-col w-full cursor-pointer  bg-white outline-none focus:outline-none">
          <>
            <div className="flex items-start justify-between">
              <h3 className="popupTitle font-bold text-color">{titleHeader}</h3>
              <h3 className="popupTitle text-color ml-2">{title}</h3>
              {!hideCrossBtn && (
                <button
                  className="ml-auto border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                  onClick={onClose}>
                  <span className="text-black opacity-1 w-6 text-3xl block outline-none focus:outline-none">
                    ×
                  </span>
                </button>
              )}
            </div>
            {titleHeader && <hr className="border-gray-200 border-bottom-none my-1.5" />}
            {body}
            <div className="flex justify-center mt-3">
              {actions?.length &&
                actions.map((action, index) => {
                  return (
                    <ActionButtons
                      key={index}
                      name={action?.name}
                      style={action?.style}
                      handleAction={action.handleAction}
                    />
                  );
                })}
            </div>
          </>
        </div>
      </div>
    </div>
  );
};
