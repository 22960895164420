import Loader from "@bbo/components/Loader";
import { Tab } from "@bbo/components/Tab/Tab";
import { CASH_DRAWERS, COUNTERS, SAFE } from "@bbo/constants";
import CashDrawerList from "@bbo/features/BranchOverview/CashDrawerList";
import CounterList from "@bbo/features/BranchOverview/CounterList";
import { StockAccountingOverview } from "../StockAccountingOverview";
import { CashManagementBranchOverviewContext } from "@bbo/features/BranchOverview/BranchOverviewProvider";
import { useStockBranchOverview } from "./useStockBranchOverview";
import { SafeList } from "@bbo/features/BranchOverview/SafeList";

export const StockBranchOverviewTab = () => {
  const contextValue = useStockBranchOverview();
  const { isLoading, accountingLocationGroups } = contextValue;

  if (isLoading) {
    return <Loader data-testid="loader" />;
  }

  return (
    <CashManagementBranchOverviewContext.Provider value={contextValue}>
      <Tab id={"StockBranchOverviewTab"}>
        <Tab.TitleList>
          <Tab.Title data-testid="tab-0">Branch Overview</Tab.Title>
          <Tab.Title data-testid="tab-1">{COUNTERS}</Tab.Title>
          <Tab.Title data-testid="tab-2">{SAFE}</Tab.Title>
          <Tab.Title data-testid="tab-3">{CASH_DRAWERS}</Tab.Title>
        </Tab.TitleList>
        <Tab.Panel className="w-full bg-white">
          <Tab.PanelContent>
            <Tab.PanelData>
              <div className="p-8">
                <StockAccountingOverview stockData={accountingLocationGroups} isStockData={true} />
              </div>
            </Tab.PanelData>
            <Tab.PanelData>
              <div className="p-8">
                <CounterList
                  items={accountingLocationGroups.counterTerminal}
                  data-testid="counter-id"
                  isStockData={true}
                />
              </div>
            </Tab.PanelData>
            <Tab.PanelData>
              <div className="p-8">
                <SafeList items={accountingLocationGroups.safe} isStockData={true} />
                <CashDrawerList
                  items={accountingLocationGroups.cashDrawer}
                  showAll={false}
                  isStockData={true}
                />
              </div>
            </Tab.PanelData>
            <Tab.PanelData>
              <div className="p-8">
                <CashDrawerList
                  items={accountingLocationGroups.cashDrawer}
                  showAll={true}
                  isStockData={true}
                />
              </div>
            </Tab.PanelData>
          </Tab.PanelContent>
        </Tab.Panel>
      </Tab>
    </CashManagementBranchOverviewContext.Provider>
  );
};
