import { useGlobalState } from "@bbo/lib";
import { useEffect, useRef, useState } from "react";
import crossIcon from "../../assets/images/crossIcon.svg";

export default function SearchForm({ onChange }) {
  const data: any = useGlobalState("categories");
  const [showOptions, setShowOptions] = useState(false);
  const [matchList, setMatchList] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [showClear, setShowClear] = useState(false);
  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setShowOptions(false);
      setMatchList([]);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  }, []);

  const select = (option: string) => {
    onChange(option);
    setShowOptions(false);
    setSearchValue(option);
    setMatchList([]);
  };
  const clear = () => {
    setSearchValue("");
    setShowClear(false);
  };

  const handleChange = (input: string): void => {
    const keyword = input?.trim()?.toLowerCase();
    setMatchList([]);
    setSearchValue(input);
    setShowClear(!!keyword);
    if (keyword && data[0] && data[0]?.categories?.length) {
      let result = [];
      data[0].categories.forEach((item) => {
        for (const key in item) {
          const itemList = item[key]?.items;
          if (itemList && itemList?.length) {
            const match = itemList.filter((product) => {
              const itemName = product?.productName.toLowerCase();
              const barcode = product?.barcode.toLowerCase();
              return itemName.includes(keyword) || barcode.includes(keyword);
            });
            result = [...result, ...match];
          }
        }
      });
      setMatchList(result);
      setShowOptions(true);
    }
    // onChange(input);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onChange(searchValue);
  };

  return (
    <div className="relative">
      <form>
        <div className="wrapper">
          <input
            className="movementInput mt-8 lg:w-5/6 mx-10"
            spellCheck="false"
            placeholder="Enter Barcode / Product Name"
            type="text"
            value={searchValue}
            onChange={(e) => handleChange(e.target.value)}
            // onFocus={() => setShowOptions(true)}
            autoFocus
          />
          {showClear && (
            <span
              className="absolute top-12 right-25 inset-y-0 right-28 mr-1 h-5 cursor-pointer"
              onClick={clear}>
              <img src={crossIcon} alt="clear" width="20px" />
            </span>
          )}
          <div className="ul-dropdown-items lg:w-5/6 mx-10">
            {showOptions && (
              <ul className="absolute bg-white lg:w-5/6  select-none" ref={ref}>
                {matchList.length ? (
                  matchList.map((option) => {
                    return (
                      <li
                        className="p-4 border hover:bg-gray-100 cursor-pointer"
                        key={option.productName}
                        onClick={() => select(option.productName)}>
                        {option.productName}
                      </li>
                    );
                  })
                ) : (
                  <li className="p-4 border text-gray-500">Item not found</li>
                )}
              </ul>
            )}
          </div>
        </div>
        <div className="flex w-full justify-center mt-8">
          <button className="brandWhiteBtn mr-4 lg:w-1/4 font-bold">Cancel</button>
          <button className="accounting-button ml-3 lg:w-1/4" onClick={(e) => handleSubmit(e)}>
            Search
          </button>
        </div>
      </form>
    </div>
  );
}
