import React from "react";

/**
 * Replace name
 * @param nametring
 * @returns
 */
export const replacename = (nametring: string) => {
  if (nametring !== null) return nametring.replace(/[^a-zA-Z ]/g, " ");
};

/**
 * Get nested key value from object
 * @param param
 * @param key
 * @returns
 */
export const keyPathValue = (param: any, key: string) => {
  key = key.replace(/\[(\w+)\]/g, ".$1");
  key = key.replace(/^\./, "");
  const keyLevels = key.split(".");
  for (let i = 0; i < keyLevels.length; ++i) {
    const k = keyLevels[i];
    if (k in param) {
      param = param[k];
    } else {
      return;
    }
  }
  return param;
};

/**
 * Check device is mobile or desktop
 * @returns boolean true if device is mobile otherwise false
 */
export const isDeviceMobile = (): boolean => {
  if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    return true;
  }

  return false;
};
export const isDeviceMobileElectron = (): boolean => {
  if (
    /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Electron/i.test(
      navigator.userAgent,
    )
  ) {
    return true;
  }

  return false;
};

/**
 * Deep link navigation
 */
export const deepLink = {
  pouchDispatch: (refreshToken: unknown): string => `spm://app/pouchdispatch/${refreshToken}`,
  pouchAcceptance: (refreshToken): string => `spm://app/pouchacceptance/${refreshToken}`,
  home: (refreshToken): string => `spm://app/home/${refreshToken}`,
};

export const getValueFromStorage = <T>(key: string): T | null => {
  return JSON.parse(localStorage.getItem(key));
};

export const setValueInStorage = (key: string, value: any) => {
  localStorage.setItem(key, JSON.stringify(value));
};

export const typedMemo: <T>(c: T) => T = React.memo;

/**
 * Generate unique UUID string ID
 * @returns string ID in format "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxxxx"
 */
export const getUUID = (): string => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, (c) => {
    const randNum = (Math.random() * 16) | 0;
    const v = c === "x" ? randNum : (randNum & 0x3) | 0x8;
    return v.toString(16);
  });
};

export const formattedQuantity = (params: number): number => {
  if (params < 0) {
    return -1 * params;
  } else return params;
};
