import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";

export const useStyles = makeStyles(() =>
  createStyles({
    cellText: {
      padding: "14px 20px 14px 20px !important",
      width: "41px",
      height: "24px",
      "font-family": "Nunito Sans !important",
      "font-style": "normal",
      "font-weight": "400",
      "font-size": "16px",
      "line-height": "24px",
      color: "#222222",
      flex: "none",
      order: 1,
      "flex-grow": "0",
    },
    colorRed: {
      color: "#B20101",
      "text-align": "right",
    },

    colorGreen: {
      color: "#008000",
      "text-align": "right",
    },
    link: {
      color: "#2B60DE",
      cursor: "pointer",
    },
  }),
);
