import { useCallback } from "react";
import { Column, Filter } from "../dynamoTableTypes";
import { FreeTextFilter } from "./FreeTextFilter/FreeTextFilter";
import { FromToFilter } from "./FromToFilter";
import { MultiDropdownFilter } from "./MultiDropdownFilter/MultiDropdownFilter";
import { SingleDropdownFilter } from "./SingleDropdownFilter/SingleDropdownFilter";

interface DynamoTableFilterProps {
  fieldName: Column["fieldName"];
  filter: Filter;
  onFilterChange: (filterKey: string, filter: Filter) => void;
}

export const DynamoTableFilter = ({
  fieldName,
  filter,
  onFilterChange,
}: DynamoTableFilterProps) => {
  const renderFilter = useCallback(() => {
    if (!filter) return null;

    const { variant } = filter;

    switch (variant) {
      case "fromTo":
        return (
          <FromToFilter fieldName={fieldName} filter={filter} onFilterChange={onFilterChange} />
        );
      case "freeText":
        return (
          <FreeTextFilter fieldName={fieldName} filter={filter} onFilterChange={onFilterChange} />
        );
      case "singleDropdown":
        return (
          <SingleDropdownFilter
            fieldName={fieldName}
            filter={filter}
            onFilterChange={onFilterChange}
          />
        );
      case "multiDropdown":
        return (
          <MultiDropdownFilter
            fieldName={fieldName}
            filter={filter}
            onFilterChange={onFilterChange}
          />
        );
      default:
        return null;
    }
  }, [fieldName, filter, onFilterChange]);

  return <td className="px-[20px] py-[14px]">{renderFilter()}</td>;
};
