import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type CounterTerminalAccessSlice = {
  isCtAccess: boolean;
  navfromCT: string;
};

const initialState: CounterTerminalAccessSlice = {
  isCtAccess: false,
  navfromCT: "",
};

export const slice = createSlice({
  name: "counterTerminalAccess",
  initialState,
  reducers: {
    setCounterTerminalAccess: (
      state: CounterTerminalAccessSlice,
      action: PayloadAction<CounterTerminalAccessSlice>,
    ) => {
      state.isCtAccess = action.payload.isCtAccess;
      state.navfromCT = action.payload.navfromCT;
    },
  },
});

export const { setCounterTerminalAccess } = slice.actions;

export default slice.reducer;
