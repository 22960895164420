import CellNode from "./cellNode";
import { Column, TableCellCallbackParams, TableRowCallbackParams } from "./types";

/**
 * Table row component
 * @param props
 * @returns RowNode JSX Element
 */

//Component props model
interface RowNodeProps<T> {
  columns: Column[];
  data: T;
  rowIndex: number;
  rowClass?: string;
  cellClass?: string;
  tdClass?: string;
  onRowClicked?: (params: TableRowCallbackParams<T>) => void;
  onCellClicked?: (params: TableCellCallbackParams<T>) => void;
  onRowSelection?: (params: TableRowCallbackParams<T>) => void;
  onCellChange?: (params: TableCellCallbackParams<T>) => void;
  isSelected?: boolean;
  isStockReview?: boolean;
  min?: string;
}

const RowNode = <T,>(props: RowNodeProps<T>) => {
  const onRowClicked = (params) => {
    if (props.onRowClicked) {
      props.onRowClicked(params);
    }
  };

  const renderCell = (params) => {
    if (params.columns && params.columns.length > 0) {
      return params.columns.map((col, columnIndex) => (
        <CellNode
          key={columnIndex}
          column={col}
          data={props.data}
          rowIndex={props.rowIndex}
          columnIndex={columnIndex}
          cellClass={props.cellClass}
          tdClass={col?.tdClass}
          onCellClicked={props.onCellClicked}
          onRowSelection={props.onRowSelection}
          onCellChange={props.onCellChange}
          isSelected={props.isSelected}
          isStockReview={props.isStockReview}
          min={props.min}
        />
      ));
    }

    return null;
  };

  const mParams = {
    columns: props.columns,
    data: props.data,
    rowIndex: props.rowIndex,
  };

  if (props.onRowClicked) {
    return (
      <tr key={props.rowIndex} onClick={() => onRowClicked(mParams)}>
        {renderCell(mParams)}
      </tr>
    );
  }

  return <tr key={props.rowIndex}>{renderCell(mParams)}</tr>;
};

export default RowNode;
