import { Transaction, Transactions } from "@bbo/api/generatorNoFadcode";
import { TRANSACTION_TABLE, TRANSACTION_TITLE } from "@bbo/constants/TransactionViewConstants";
import {
  getStockUnitIdentifierName,
  TRANSACTION_ITEM_ID,
  TRANSACTION_MODE,
} from "./transferPayload";

const FindOperationType = (data: Transaction) => {
  switch (data.TransactionMode) {
    case TRANSACTION_MODE.TRANSFER_IN:
      return TRANSACTION_TITLE.TransferIn;
    case TRANSACTION_MODE.TRANSFER_OUT:
      return TRANSACTION_TITLE.TransferOut;
    case TRANSACTION_MODE.PAID_IN:
      if (data.itemID === TRANSACTION_ITEM_ID.CASH) {
        return TRANSACTION_TITLE.PaidIn;
      } else if (TRANSACTION_ITEM_ID.PAID_OUT.includes(data.itemID)) {
        return TRANSACTION_TITLE.PaidOut;
      } else return TRANSACTION_TITLE.NA;
    case TRANSACTION_MODE.POUCH_ACCEPTANCE:
      return TRANSACTION_TITLE.PouchAcceptance;
    case TRANSACTION_MODE.POUCH_PREPARATION:
      return TRANSACTION_TITLE.PouchPreparation;
    case TRANSACTION_MODE.POUCH_DESPATCH:
      return TRANSACTION_TITLE.PouchDespatch;
    case TRANSACTION_MODE.POUCH_PREPARATION_CANCEL:
      return TRANSACTION_TITLE.PouchPreparationCancelPouch;
    default:
      return TRANSACTION_TITLE.NA;
  }
};
const FindDestinationEntityName = (data: Transaction) => {
  if (data.TransactionMode === TRANSACTION_MODE.PAID_IN) {
    if (data.itemID === TRANSACTION_ITEM_ID.CASH) {
      return getStockUnitIdentifierName(data.Tokens.stockunitidentifier);
    }
    if (TRANSACTION_ITEM_ID.PAID_OUT.includes(data.itemID)) {
      return TRANSACTION_TITLE.OutOfBranch;
    }
  }
  if (data.TransactionMode === TRANSACTION_MODE.POUCH_ACCEPTANCE) {
    return TRANSACTION_TITLE.Safe;
  }
  if (data.TransactionMode === TRANSACTION_MODE.POUCH_DESPATCH) {
    return TRANSACTION_TITLE.CashStockCentre;
  }
  if (
    data.TransactionMode === TRANSACTION_MODE.POUCH_PREPARATION ||
    data.TransactionMode === TRANSACTION_MODE.POUCH_PREPARATION_CANCEL
  ) {
    return TRANSACTION_TITLE.NA;
  }
  return getStockUnitIdentifierName(data.Tokens.stockunitidentifierdest);
};
const FindSourceEntityName = (data: Transaction) => {
  if (data.TransactionMode === TRANSACTION_MODE.PAID_IN) {
    if (data.itemID === TRANSACTION_ITEM_ID.CASH) {
      return TRANSACTION_TITLE.OutOfBranch;
    }
    if (TRANSACTION_ITEM_ID.PAID_OUT.includes(data.itemID)) {
      return getStockUnitIdentifierName(data.Tokens.stockunitidentifier);
    }
  }
  if (data.TransactionMode === TRANSACTION_MODE.POUCH_ACCEPTANCE) {
    return TRANSACTION_TITLE.CashStockCentre;
  }
  if (data.TransactionMode === TRANSACTION_MODE.POUCH_DESPATCH) {
    return TRANSACTION_TITLE.Safe;
  }
  if (
    data.TransactionMode === TRANSACTION_MODE.POUCH_PREPARATION ||
    data.TransactionMode === TRANSACTION_MODE.POUCH_PREPARATION_CANCEL
  ) {
    return TRANSACTION_TITLE.Safe;
  }
  return getStockUnitIdentifierName(data.Tokens.stockunitidentifier);
};
const FindTransferAt = (data: Transaction) => {
  if (
    data.TransactionMode === TRANSACTION_MODE.POUCH_ACCEPTANCE ||
    data.TransactionMode === TRANSACTION_MODE.POUCH_DESPATCH
  ) {
    return TRANSACTION_TITLE.CounterTerminal;
  }
  if (
    data.TransactionMode === TRANSACTION_MODE.POUCH_PREPARATION ||
    data.TransactionMode === TRANSACTION_MODE.POUCH_PREPARATION_CANCEL
  ) {
    return TRANSACTION_TITLE.BackOffice;
  }
  return data.Tokens.transferat;
};
const TransactionIdCheck = (ID) => {
  const lastLetter = ID.length;
  const letter = ID.split("");
  if (letter[lastLetter - 1] === "1") return true;
  else return false;
};
export const constructCashMovementData = (transactions: Transactions) => {
  const cashMovementData = transactions?.Transactions?.filter((operationType) => {
    if (
      (operationType.TransactionMode === TRANSACTION_MODE.PAID_IN &&
        operationType.itemID === TRANSACTION_ITEM_ID.CASH &&
        TransactionIdCheck(operationType.TransactionID)) ||
      (operationType.TransactionMode === TRANSACTION_MODE.PAID_OUT &&
        TRANSACTION_ITEM_ID.PAID_OUT.includes(operationType.itemID) &&
        operationType.quantity < 0) ||
      (operationType.TransactionMode === TRANSACTION_MODE.TRANSFER_OUT &&
        operationType.itemID === TRANSACTION_ITEM_ID.CASH &&
        operationType.quantity < 0) ||
      (operationType.TransactionMode === TRANSACTION_MODE.TRANSFER_IN &&
        operationType.itemID === TRANSACTION_ITEM_ID.CASH &&
        operationType.quantity < 0) ||
      (operationType.TransactionMode === TRANSACTION_MODE.POUCH_ACCEPTANCE &&
        operationType.itemID === TRANSACTION_ITEM_ID.POUCH_ACCEPTANCE) ||
      (operationType.TransactionMode === TRANSACTION_MODE.POUCH_PREPARATION &&
        operationType.itemID === TRANSACTION_ITEM_ID.POUCH_PREPARATION) ||
      (operationType.TransactionMode === TRANSACTION_MODE.POUCH_PREPARATION_CANCEL &&
        operationType.itemID === TRANSACTION_ITEM_ID.POUCH_PREPARATION) ||
      (operationType.TransactionMode === TRANSACTION_MODE.POUCH_DESPATCH &&
        operationType.itemID === TRANSACTION_ITEM_ID.POUCH_DESPATCH)
    )
      return operationType;
  })
    .sort((a, b) => {
      return b.TransactionStartTime - a.TransactionStartTime;
    })
    .map((list) => {
      return {
        [TRANSACTION_TABLE.transferAmount]: list?.valueInPence,
        [TRANSACTION_TABLE.destinationEntityName]: FindDestinationEntityName(list) || "",
        [TRANSACTION_TABLE.sourceEntityName]: FindSourceEntityName(list) || "",
        [TRANSACTION_TABLE.transferAt]: FindTransferAt(list) || "",
        [TRANSACTION_TABLE.dateTime]: list?.TransactionEndTime,
        [TRANSACTION_TABLE.operationType]: FindOperationType(list),
        [TRANSACTION_TABLE.smartID]: list?.entryUpdatedBy?.split("_")[1],
        [TRANSACTION_TABLE.userName]: list?.Tokens.username || "",
        [TRANSACTION_TABLE.fadcode]: list?.fadCode,
        [TRANSACTION_TABLE.nodeID]: list?.nodeID,
        ["token"]: list,
      };
    });
  return cashMovementData;
};
