import { CurrencyCountList } from "@bbo/api/generator";
import { ICurrencyData } from "./CurrencyCountScreen";

export const sortByCurrencyName = (a, b) => {
  if (a.name > b.name) {
    return 1;
  } else if (b.name > a.name) {
    return -1;
  } else {
    return 0;
  }
};

export const fnJoinAllCurrencies = (
  topSellers: ICurrencyData[],
  onHandCurrencies: ICurrencyData[],
  fullRange: ICurrencyData[],
  onHandCount: number,
  fullRangeCount: number,
): ICurrencyData[] => {
  let slicedData: ICurrencyData[] = [];
  let tempCurrencies: ICurrencyData[] = [];
  if (topSellers?.length > 0) {
    tempCurrencies = [...topSellers, { ordercode: "", name: "" }];
  }
  if (onHandCurrencies?.length > 0) {
    slicedData = onHandCurrencies.slice(0, onHandCount);
    if (onHandCount !== onHandCurrencies?.length && onHandCount < onHandCurrencies?.length) {
      tempCurrencies = [
        ...tempCurrencies,
        ...slicedData,
        { name: "more", ordercode: "Hand", balance: 4 },
      ];
    } else {
      if (slicedData?.length % 3 === 0) {
        tempCurrencies = [...tempCurrencies, ...slicedData];
      } else if (slicedData.length % 3 === 2) {
        tempCurrencies = [
          ...tempCurrencies,
          ...slicedData,
          { name: "", ordercode: "", balance: 4 },
        ];
      } else if (slicedData.length % 3 === 1) {
        tempCurrencies = [
          ...tempCurrencies,
          ...slicedData,
          { name: "", ordercode: "", balance: 4 },
          { name: "", ordercode: "", balance: 4 },
        ];
      }
    }
  }
  if (fullRange?.length > 0) {
    slicedData = [];
    slicedData = fullRange.slice(0, fullRangeCount);
    if (fullRangeCount !== fullRange?.length && fullRangeCount < fullRange?.length) {
      tempCurrencies = [
        ...tempCurrencies,
        ...slicedData,
        { name: "more", ordercode: "full", balance: 0 },
      ];
    } else {
      tempCurrencies = [...tempCurrencies, ...slicedData];
    }
  }
  return tempCurrencies;
};

export const getTopCurrencies = (selected: CurrencyCountList) => {
  const currencies = selected?.currencys?.filter(
    (currency) => currency.ordercode === "EUR" || currency.ordercode === "USD",
  );
  return currencies;
};

export const getOnHandCurrencies = (selected: CurrencyCountList) => {
  const currencies = selected?.currencys?.filter(
    (currency) =>
      currency?.balance !== 0 && currency.ordercode !== "EUR" && currency.ordercode !== "USD",
  );
  return currencies?.sort(sortByCurrencyName);
};

export const getFullRangeCurrencies = (selected: CurrencyCountList) => {
  const currencies = selected?.currencys?.filter(
    (currency) =>
      currency?.balance === 0 && currency.ordercode !== "EUR" && currency.ordercode !== "USD",
  );
  return currencies?.sort(sortByCurrencyName);
};
