import Accordion from "@bbo/components/Accordion";
import Popup from "@bbo/components/layout/Popup";
import CashMovementsModal from "@bbo/components/layout/cashMovement-Popup";
import { CashDetailContext } from "@bbo/context";
import { BalanceCheck } from "@bbo/features/AdhocBalance/BalanceCheck";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { useAppSelector } from "@bbo/lib";
import { CashMovementHistoryData } from "@bbo/lib/hooks/useTransactionApi";
import { useGlobalState } from "@bbo/lib/state";
import { AdhocCashBalancingProps } from "@bbo/screens/AdhocCashBalancing";
import { useAdhocCashBalancingState } from "@bbo/screens/CashBalanceHistoryUtil";
import "@bbo/screens/Index.css";
import { amountInPence } from "@bbo/utils/currencyFormatter";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import UserAvatar from "../../assets/images/downarrow.png";
import { Ladder } from "./ladder";
import { SuspendPopup } from "./suspendPopUp";
import { useAdhocBalance } from "./useAdhocBalance";
import UserActivitiesTable from "./userActivitiesTable";

interface TpBpType {
  operationType: string;
  date: string;
}

const Dashboard = ({ pouchData = {} }: AdhocCashBalancingProps | Record<string, never>) => {
  const { cashDetail, setCashDetail } = useContext(CashDetailContext);
  const navigate = useNavigate();
  const location = useLocation();
  const { accountingLocationID } = useParams();
  const [adhocbalance] = useGlobalState("adhocbalance");
  const [, setVariance] = useGlobalState("adhocData");
  const [, setTotal] = useGlobalState("totalCash");
  const { title: historicalBpTpTitle } = useAppSelector((root) => root.historicalBpTp);
  const [showSuspendPopUp, setShowSuspendPopUp] = useState<boolean>(false);
  const [quantityInputEnabled, setQuantityInputEnabled] = useState<boolean>(true);
  const [valueInputEnabled, setValueInputEnabled] = useState<boolean>(true);
  const unUseNote = "Unusable Notes";
  const unUseCoin = "Unusable Coins";
  const [Isbalancecheck, setIsbalancecheck] = useGlobalState("Isbalancecheck");
  const [ResetPopup, setResetPopup] = useState<boolean>(false);
  const [Submitted, setSubmitted] = useState<boolean>(true);
  const [, setfocus] = useState<number>(1);
  const [modalData, setModalData] = useState({ showModal: false });
  const [routeState] = useGlobalState("routeState");
  const selectedTask = useAppSelector((root) => root.selectedTask);
  const { setTitle, setIsBackButtonActive } = useTitle();
  const { statusRes, setStatusRes, handleSaveVariance, toDateVariance } = useAdhocBalance();
  const [accountingLocation, setAccountingLocation] = useState("");
  const [cashMovementModalData, setCashMovementModalData] = useState<CashMovementHistoryData>();

  const [param, setparam] = useState({
    fromDate: Math.floor((Date.now() - 42 * 24 * 60 * 60 * 1000) / 1000),
    toDate: Math.floor(Date.now() / 1000),
  });

  useEffect(() => {
    if (adhocbalance.isSuspendedPouch) {
      //should this be a global state?
      setShowSuspendPopUp(true);
    }
  }, [adhocbalance]);

  useEffect(() => {
    return () => {
      setIsbalancecheck({ Isbalancecheck: false });
    };
  }, [setIsbalancecheck]);

  //Need to go through this and get rid of route state
  useEffect(() => {
    setTitle("cash");
  }, [setTitle]);

  useEffect(() => {
    //sets the title dependant on adhocBalancing Route
    const splittedHash = location.pathname.split("%23");

    const titleSelector = () => {
      return `${historicalBpTpTitle} ${
        splittedHash[splittedHash.length - 1]
      } - Cash Balance History`;
    };

    if (location.pathname.includes("TpBp")) {
      const accountingLocation = splittedHash[splittedHash.length - 1].includes("C")
        ? `Cash Drawer ${splittedHash[splittedHash.length - 1].match(/\d/g).join("")}`
        : `Safe ${splittedHash[splittedHash.length - 1].match(/\d/g).join("")}`;
      setAccountingLocation(accountingLocation);
    } else {
      const accountingLocationURL = splittedHash[0].split("/");
      setAccountingLocation(accountingLocationURL[accountingLocationURL.length - 1]);
    }

    if (location.pathname.includes("TpBpHistory")) {
      setTitle(titleSelector());
    } else if (location.pathname.includes("TpBp")) {
      setTitle(`Cash Balance | ${accountingLocation}`);
    } else {
      setTitle(`Cash Balance | ${accountingLocation}`);
    }

    setIsBackButtonActive(false);
    return () => {
      setIsBackButtonActive(true);
    };
  }, [
    accountingLocation,
    accountingLocationID,
    historicalBpTpTitle,
    location?.pathname,
    selectedTask?.accountingLocationName,
    setIsBackButtonActive,
    setTitle,
  ]);

  const { activitiesBetweenBalancesTransactions: cashMovementHistory } =
    useAdhocCashBalancingState(param);

  const submitNext = () => {
    let count = 0;
    const totalCashBySystem: number = adhocbalance.entityData[0]?.itemDetails?.balance;
    cashDetail.map((item) => {
      if (item.btnDisable === false)
        if (item.alertCss !== "alertCss" && item.errorMsg === "" && item.QerrorMsg === "") {
          count++;
        } else {
          count--;
        }

      return 0;
    });

    const denominationArr = cashDetail
      .filter((item) => {
        if (item.value !== null) {
          return {};
        }
      })
      .map((item, Index) => {
        return {
          denomination:
            item.Cash === unUseNote || item.Cash === unUseCoin
              ? item.Cash
              : item.Cash + item.currency,
          index: Index,
          looseCoin: parseInt(item.Quantity as string), // is this correct?
          value: amountInPence(item.value.replace("£", "")),
          itemID: item.itemID,
        };
      });

    if (count > 0 && !cashDetail.some((item) => item.alertCss === "alertCss" && Submitted)) {
      setIsbalancecheck({ Isbalancecheck: true });
    }

    if (!cashDetail) return;
    let totalCashByInput = cashDetail.reduce((total, value) => {
      if (value.value === null) return;
      return total + Number(value.value.replace("£", ""));
    }, 0);
    totalCashByInput = amountInPence(totalCashByInput);

    setTotal({ totalCash: totalCashByInput });
    const currentToDateVariance = totalCashByInput - totalCashBySystem;
    setVariance({
      variance: currentToDateVariance - adhocbalance.entityData[0]?.itemDetails?.toDateVariance, // last variance
      balance: totalCashByInput, // current value of ladder
      currentTDV: currentToDateVariance,
      denomination: denominationArr, // denomination ladder value
    });
  };

  const ResetPopupFlag = () => {
    setResetPopup(true);
  };
  const resetFocus = useRef<HTMLInputElement>();
  const nextFocus = useRef<HTMLInputElement>();

  const submitReset = (e) => {
    if (resetFocus.current) {
      resetFocus.current.focus();
    }
    if (e.target.value === "Yes") {
      setQuantityInputEnabled(!quantityInputEnabled);
      setValueInputEnabled(!valueInputEnabled);
      setfocus(1);
      const updateValue = [...cashDetail];
      cashDetail.map((item, index) => {
        updateValue[index].value = "";
        updateValue[index].Quantity = "";
        updateValue[index].alertCss = "normalCss";
        updateValue[index].QalertCss = "normalCss";
        updateValue[index].errorMsg = "";
        updateValue[index].QerrorMsg = "";
        updateValue[index].btnDisable = true;
        return 0;
      });
      setCashDetail(updateValue);
      setResetPopup(false);
    } else {
      setResetPopup(false);

      if (nextFocus.current) {
        nextFocus.current.focus();
      }
    }
  };

  const userActivitiesData =
    cashMovementHistory?.length > 0 &&
    cashMovementHistory?.map((items) => {
      {
        const log = {
          userName: items?.userName,
          operationType: items?.operationType,
          amount: items?.transferAmount,
          Link: (
            <button
              className="h-8 w-40 flex colorBlue items-center"
              onClick={() => setCashMovementModalData(items)}>
              Break Down
              <img className={`h-4 w-4 ml-2`} src={UserAvatar} />
            </button>
          ),
        };
        return log;
      }
    });

  const handleCellClicked = (params) => {
    setModalData({ ...params, showModal: true });
  };

  const handleCellClickedCallback = useCallback(handleCellClicked, []);
  const arr = cashMovementHistory ? cashMovementHistory : [];
  const accordionData = [
    pouchData.title ? pouchData : {},
    {
      title: "Activities between Balances",
      content: userActivitiesData?.length > 0 && (
        <UserActivitiesTable
          UserData={[...userActivitiesData]}
          handleCellClickedCallback={handleCellClickedCallback}
        />
      ),
    },
  ];

  const handleBackButton = () => {
    navigate(-1);
  };

  const inputCash = (
    <div
      className={`w-full items-start grid grid-cols-1 
        lg:grid-cols-2 gap-5`}>
      <div className="grid grid-cols-1 gap-4 pl-4 pt-4">
        {accordionData?.map(({ title, content }, index) => (
          <Accordion
            title={title}
            content={content}
            key={index}
            feature={"adhocBalance"}
            onToggle={() => {
              return true;
            }}
          />
        ))}
      </div>
      {Isbalancecheck.Isbalancecheck ? (
        <BalanceCheck toDateVariancePassedDownFromParent={toDateVariance} />
      ) : (
        <Ladder
          submitNext={(Submitted) => {
            setSubmitted(Submitted);
            submitNext();
            handleSaveVariance("Incomplete");
          }}
          ResetPopupFlag={() => ResetPopupFlag()}
          quantityInputEnabled={quantityInputEnabled}
          valueInputEnabled={valueInputEnabled}
          nextFocus={nextFocus}
          ResetPopup={ResetPopup}
        />
      )}
    </div>
  );

  return (
    <>
      <div className="subheading font-24 font-bold leading-7 lg:w-screen">
        <div className="width-70 float-left">
          <button
            onClick={() => {
              handleBackButton();
            }}>
            <i className="fas fa-chevron-left"></i> Back
          </button>
        </div>
      </div>

      <div className="containerMain">
        <div className="w-full items-start mainContainerShadow transferOut-height overflow-hidden">
          <div className="flex flex-col lg:flex-row mb-6 w-full rounded shadow overflow-y-auto cashExchangeHeight justify-center">
            {showSuspendPopUp && <SuspendPopup onSuccess={() => setShowSuspendPopUp(false)} />}
            {ResetPopup && <Popup submitReset={submitReset} resetFocus={resetFocus} />}
            {inputCash}
            <CashMovementsModal data={{ ...modalData, data: { ...cashMovementModalData } }} />
          </div>
        </div>
      </div>
    </>
  );
};
export default Dashboard;
