import { useState } from "react";
import { useStyles } from "./CurrencyCountReviewSubHeaderStyles";
import { ICurrency } from "@bbo/features/CurrencyCount";

interface ILocationState {
  iSelected?: ICurrency;
}

function CurrencyCountReviewSubHeader({ PageSubTitle, totalSterlingValue, countType }) {
  const [currencyInfo, setCurrencyInfo] = useState<ILocationState>({
    iSelected: {},
  });
  const classes = useStyles();
  return (
    <div className={classes.subHeader}>
      <div>{`${countType?.iSelected.name} - ${PageSubTitle} `}</div>
      <div>
        {totalSterlingValue} <span className={classes.value}> £213.00</span>
      </div>
    </div>
  );
}

export default CurrencyCountReviewSubHeader;
