import { useGetDiscrepancyTotal } from "@bbo/api/generator";
import Loader from "@bbo/components/Loader";
import { useGlobalState } from "@bbo/lib/state";
import { useEffect, useState } from "react";
import { DISCREPENCY_OVERVIEW_TABS } from "./constant";
import { DetailedView } from "./DetailedView";
import { DiscrepancyPage } from "./DiscrepancyPage";
import HeaderTabs from "./HeaderTabs";
import "./index.css";
import { useDiscrepancyPage } from "./useDiscrepancyPage";

export const DiscrepancyAccount = () => {
  const [tabLabels] = useState(DISCREPENCY_OVERVIEW_TABS);
  const [selectedTab, setSelectedTab] = useState(DISCREPENCY_OVERVIEW_TABS[0].value);
  const [DetailedViewModel, setDetailedViewModel] = useGlobalState("DetailedViewModel");
  // const [, setSummaryTab] = useState<string>();
  // const { isStatus } = useDiscrepancyAccount();
  const { isLoading } = useDiscrepancyPage();
  const [isSubtabLabel, setIsSubtabLabel] = useState<boolean>();
  const { discrepancyTotal, refetchTotal } = useDiscrepancyPage();
  useEffect(() => {
    return () => {
      setDetailedViewModel({ showModel: true });
    };
  }, [setDetailedViewModel]);

  const handleSelectTab = (params) => {
    setSelectedTab(params.value);
  };
  return (
    <>
      {DetailedViewModel.showModel ? (
        isLoading ? (
          <Loader />
        ) : (
          <>
            <HeaderTabs
              tabLabels={tabLabels}
              selected={selectedTab}
              onSelectTab={handleSelectTab}
              isSummary={false}
              discrepancyTotal={discrepancyTotal}
            />
            {/* <DiscrepancyOverview selectedTab={selectedTab} /> */}
            <DiscrepancyPage selectedTab={selectedTab} discrepancyTotal={discrepancyTotal} />
          </>
        )
      ) : (
        <DetailedView DetailedViewData={DetailedViewModel.params} />
      )}
    </>
  );
};
