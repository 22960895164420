import "./errorPopup.css";
import PrintIcon from "../../assets/images/popup/print.svg";
import CheckmarkIcon from "../../assets/images/popup/checkmark.svg";
import ErrorIcon from "../../assets/images/popup/error.svg";

export type PopupType = "error" | "cross" | "success" | "print" | "alert";

const renderIcon = (popupType: PopupType) => {
  switch (popupType) {
    case "success":
      return CheckmarkIcon;
    case "print":
      return PrintIcon;
    case "error":
      return ErrorIcon;
    default:
      return null;
  }
};

export interface ErrorPopupProps {
  popupType: PopupType;
  title?: string;
  header?: string;
  content?: string;
  labelCancel?: string;
  messageHeading?: string;
  messageBody?: string;
  labelSuccess?: string;
  logoIcon?: string;
  labelReprint?: string;
  onSuccess?: () => void;
  onCancel?: () => void;
  OnReprint?: () => void;
}

// Need to refactor this - just copied it from PouchPreparationPopup so it's currently using those styles
export const ErrorPopup = ({
  popupType = "error",
  labelCancel,
  labelSuccess,
  onSuccess,
  onCancel,
  messageHeading,
  messageBody,
}: ErrorPopupProps) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className={`relative my-6 max-w-xl pouch-prepare-popup dimensions`}>
          <div className="messageTitle">
            {/* TODO: REPLACE THIS */}
            {popupType === "cross" ? (
              <i className="fas fa-times paidCrossColor text-5xl bg-white"></i>
            ) : (
              <img src={renderIcon(popupType)} />
            )}
          </div>

          {/* TODO: STYLE THIS */}
          {messageHeading ? <div className="messageBody">{messageHeading}</div> : null}
          <div>{messageBody}</div>

          <div className="flex items-center gap-5 justify-center">
            {onCancel ? (
              <button
                className="cancelBtn"
                type="button"
                value="No"
                data-cy="popupNo"
                onClick={onCancel}>
                {labelCancel}
              </button>
            ) : null}

            {onSuccess ? (
              <button
                className={`success-btn reprint-success-btn`}
                type="button"
                value="Yes"
                data-cy="popupYes"
                onClick={onSuccess}>
                {labelSuccess}
              </button>
            ) : null}
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
