import { CashDetailContext } from "@bbo/context";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { useGlobalState } from "@bbo/lib/state";
import AdhocCashBalancing from "@bbo/screens/AdhocCashBalancing";
import { amountInPound } from "@bbo/utils/currencyFormatter";
import { useEffect, useState } from "react";
import { useAdhocCashBalancingState } from "./CashBalanceHistoryUtil";
import { useGetAccountingLocationByDeclarationID } from "./CashBalanceHooks/useGetAccountingLocationByDeclarationID";

export interface IParamsSet {
  branchId: string;
  from_date: string;
  to_date: string;
}
export interface IUserActivitiesParams {
  fadcode: string;
  entity: string;
  entityID: string;
  status: string;
}

const TpBpCashBalancing = () => {
  const { setTitle } = useTitle();

  const [, setIsReadonlyMode] = useGlobalState("isReadonlyMode");
  const [resStatus, setResStatus] = useState("Incomplete");
  const [param, setparam] = useState({
    fromDate: Math.floor((Date.now() - 42 * 24 * 60 * 60 * 1000) / 1000),
    toDate: Math.floor(Date.now() / 1000),
  });
  useEffect(() => {
    // setTitle("Cash Drawer Cash Balancing"); // This is improper as we are not getting Cash Drawer Number so commenting, implemented it's fix in AdhocBalance for TpBp
    setIsReadonlyMode({ isReadonlyMode: false });
  }, [setTitle, setIsReadonlyMode]);

  const { data: cashDeclarationById, cashItemsArr } = useGetAccountingLocationByDeclarationID();

  useEffect(() => {
    setparam({
      fromDate: cashDeclarationById?.timestamp,
      toDate: Math.floor(Date.now() / 1000),
    });
  }, [cashDeclarationById]);

  const { cashDetail, setCashDetail, activitiesBetweenBalancesTransactions } =
    useAdhocCashBalancingState(param);

  useEffect(() => {
    if (!cashItemsArr) return;

    setCashDetail((previousValue) => {
      const updateValue = [...previousValue];

      previousValue?.forEach((item, index) => {
        cashItemsArr?.forEach((cashItem) => {
          if (cashItem === item.itemID) {
            updateValue[index] = {
              ...updateValue[index],
              value: `£${amountInPound(
                Number(cashDeclarationById?.cashItems[item.itemID].itemValue),
              )}`,
              Quantity: cashDeclarationById?.cashItems[item.itemID].itemQuantity,
            };
          }
        });
      });

      return updateValue;
    });
  }, [cashDeclarationById?.cashItems, cashItemsArr, setCashDetail]);

  return (
    <CashDetailContext.Provider
      value={{
        cashDetail,
        setCashDetail,
        accountingLocationID: cashDeclarationById?.accountingLocationID,
      }}>
      {/* context provider */}
      <AdhocCashBalancing
        activitiesBetweenBalancesTransactions={activitiesBetweenBalancesTransactions}
      />
    </CashDetailContext.Provider>
  );
};

export default TpBpCashBalancing;
