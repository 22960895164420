/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  GetBpTpListResponseResponse,
  BboDeclarationBadRequestResponse,
  UnauthorizedResponse,
  ForbiddenResponse,
  BboDeclarationFailureResponseResponse,
  GetBpTpHistoryParams,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary BP TP History
 */
export const useGetBpTpHistoryHook = () => {
  const getBpTpHistory = useCustomInstance<GetBpTpListResponseResponse>();

  return (params?: GetBpTpHistoryParams) => {
    return getBpTpHistory({ url: `/bm-declaration-service/v1/bp-tp`, method: "get", params });
  };
};

export const getGetBpTpHistoryQueryKey = (params?: GetBpTpHistoryParams) => [
  `/bm-declaration-service/v1/bp-tp`,
  ...(params ? [params] : []),
];

export type GetBpTpHistoryQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetBpTpHistoryHook>>
>;
export type GetBpTpHistoryQueryError = ErrorType<
  | BboDeclarationBadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | BboDeclarationFailureResponseResponse
>;

export const useGetBpTpHistory = <
  TData = AsyncReturnType<ReturnType<typeof useGetBpTpHistoryHook>>,
  TError = ErrorType<
    | BboDeclarationBadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | BboDeclarationFailureResponseResponse
  >,
>(
  params?: GetBpTpHistoryParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetBpTpHistoryHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetBpTpHistoryQueryKey(params);

  const getBpTpHistory = useGetBpTpHistoryHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetBpTpHistoryHook>>> = () =>
    getBpTpHistory(params);

  const query = useQuery<AsyncReturnType<ReturnType<typeof useGetBpTpHistoryHook>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};
