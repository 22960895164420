/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * Transfer Transactions
 * PO Transactions

PO Transactions Viewer
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, UseMutationOptions, MutationFunction } from "@tanstack/react-query";
import type { Response, Error, TransferCore } from "../model";
import { useCustomInstanceNoFadcode, ErrorType } from "../mutator/useCustomInstanceNoFadcode";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary This endpoint is used to create transfer transaction
 */
export const usePostTransferTransactionHook = () => {
  const postTransferTransaction = useCustomInstanceNoFadcode<Response>();

  return (transferCore: TransferCore) => {
    return postTransferTransaction({
      url: `/transfer/v1/transaction`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: transferCore,
    });
  };
};

export type PostTransferTransactionMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof usePostTransferTransactionHook>>
>;
export type PostTransferTransactionMutationBody = TransferCore;
export type PostTransferTransactionMutationError = ErrorType<Error | void>;

export const usePostTransferTransaction = <
  TError = ErrorType<Error | void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof usePostTransferTransactionHook>>,
    TError,
    { data: TransferCore },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const postTransferTransaction = usePostTransferTransactionHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof usePostTransferTransactionHook>>,
    { data: TransferCore }
  > = (props) => {
    const { data } = props || {};

    return postTransferTransaction(data);
  };

  return useMutation<
    AsyncReturnType<typeof postTransferTransaction>,
    TError,
    { data: TransferCore },
    TContext
  >(mutationFn, mutationOptions);
};
