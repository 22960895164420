/**
 * Pouch preparation ladder component
 */

import PopupConfirm from "@bbo/components/Alert";
import { initialCoinValues } from "@bbo/features/PouchLadder/PouchConstants";
import { useEffect, useState } from "react";
import {
  CoinItem,
  LadderFlags,
  LadderItemProps,
  LadderTotal,
  ladderRowCombinedItem,
} from "../../types";
import { prepareFullBagsAndLooseCoinsToPost } from "../PouchPreparation/utility";
import LadderRow from "./LadderRow";
import LadderRowLooseCoins from "./LadderRowLooseCoins";
import { LIMIT_EXCEEDS } from "./PouchConstants";
import { convertPenceToPoundsAsNumber } from "./utility";

/**
 * Pouch Preparation screen functional component
 * @returns
 */

interface iProps {
  cashdetail: LadderItemProps[] | CoinItem[] | ladderRowCombinedItem[];
  onComplete?: (data, total) => void;
  onCancel?: (data, total) => void;
  looseBag?: boolean;
  looseCoin?: boolean;
  onSuspend?: (data) => void;
  heading?: string;
  showCancelButton: boolean;
  showCompleteButton: boolean;
  showSuspendButton: boolean;
  disableFlags: boolean;
  setCashdetail?: (data) => void;
  resetPouchLadder?: boolean;
  prefilledLadder?: boolean;
}

export const PouchLadder = (props: iProps) => {
  const [BagCode] = useState<boolean>(false);
  const [looseBag, setlooseBag] = useState<boolean>(false);
  const [showCancelButon, setShowCancelButon] = useState<boolean>(false);
  const [showCompleteButton, setShowCompleteButton] = useState<boolean>(false);
  const [showSuspendButon, setShowSuspendButon] = useState<boolean>(false);
  const [limitExceeds, setLimitExceeds] = useState<boolean>(false);
  const [Total, setTotal] = useState<LadderTotal>();
  const [totalAmountOnLoad, setTotalAmountOnLoad] = useState([]);
  const [disabled, setDisabled] = useState<LadderFlags>({
    inputQ: props.disableFlags,
    inputLC: props.disableFlags,
    inputV: props.disableFlags,
  });

  const { setCashdetail, cashdetail, looseCoin, prefilledLadder } = props;

  useEffect(() => {
    setlooseBag(props.looseBag);
  }, [props.looseBag]);

  useEffect(() => {
    setShowCancelButon(props.showCancelButton);
  }, [props.showCancelButton]);

  useEffect(() => {
    setShowCompleteButton(props.showCompleteButton);
  }, [props.showCompleteButton]);

  useEffect(() => {
    setShowSuspendButon(props.showSuspendButton);
  }, [props.showSuspendButton]);

  useEffect(() => {
    let totalBag = 0;
    let totalLooseCoins = 0;
    let fullBagTotal = 0;
    let partBagTotal = 0;
    let totalAmount = 0;

    if (looseCoin && cashdetail && cashdetail.length) {
      cashdetail.forEach((item) => {
        totalBag += parseInt(item.fullBagQuantity ? item.fullBagQuantity : 0);
        totalLooseCoins += parseInt(item.partBagQuantity ? item.partBagQuantity : 0);

        fullBagTotal +=
          parseInt(item.fullBagValueInPence ? item.fullBagValueInPence : 0) *
          parseInt(item.fullBagQuantity ? item.fullBagQuantity : 0);

        partBagTotal +=
          parseInt(item.partBagIndividualValue ? item.partBagIndividualValue : 0) *
          parseInt(item.partBagQuantity ? item.partBagQuantity : 0);
      });

      totalAmount = convertPenceToPoundsAsNumber(fullBagTotal + partBagTotal);
    } else {
      cashdetail?.map((item) => {
        totalBag = totalBag + parseInt(item?.Quantity ? item?.Quantity : 0);
        totalLooseCoins = totalLooseCoins + parseInt(item?.looseCoin ? item?.looseCoin : 0);
        totalAmount = totalAmount + Number(item?.value ? item?.value.replace("£", "") : 0);
      });
    }
    setTotalAmountOnLoad((prevState) => [...prevState, totalAmount]);
    setTotal({ totalBag, totalLooseCoin: totalLooseCoins, totalAmount });
  }, [cashdetail]);

  useEffect(() => {
    if (!props.disableFlags && Total && Total.totalAmount && Total.totalAmount > LIMIT_EXCEEDS) {
      setLimitExceeds(true);
    }
  }, [Total]);

  const onChange = (params, index, flags) => {
    setCashdetail((list) => {
      list[index] = { ...params };
      return [...list];
    });
    if (flags) {
      setDisabled(flags);
    }
  };

  const onChangeForLadderRowLooseCoins = (dataToUpdate) => {
    const indexToUpdate = cashdetail.findIndex((item) => item.fullBagID === dataToUpdate.fullBagID);

    if (indexToUpdate !== -1) {
      setCashdetail((list) => {
        const updatedList = [...list];
        updatedList[indexToUpdate] = { ...updatedList[indexToUpdate], ...dataToUpdate };
        return updatedList;
      });
    }
  };

  const handleCancel = (e) => {
    e.preventDefault();
    if (props.onCancel) {
      const mDetails: LadderItemProps[] = JSON.parse(JSON.stringify(cashdetail));
      const mTotal: LadderTotal = JSON.parse(JSON.stringify(Total));
      props.onCancel([...mDetails], { ...mTotal });
    }
  };

  const handleComplete = (e) => {
    e.preventDefault();
    e.target.disabled = true;
    if (props.onComplete) {
      const mDetails = JSON.parse(
        JSON.stringify(looseCoin ? prepareFullBagsAndLooseCoinsToPost(cashdetail) : cashdetail),
      );
      const mTotal = JSON.parse(JSON.stringify(Total));
      props.onComplete([...mDetails], { ...mTotal });
    }
  };

  const handleSuspend = (e) => {
    e.preventDefault();
    if (props.onSuspend) {
      props.onSuspend([...cashdetail]);
    }
  };

  useEffect(() => {
    if (prefilledLadder) {
      setCashdetail(initialCoinValues);
    }
  }, [prefilledLadder]);

  return (
    <>
      <div className="w-full float-left flex justify-between">
        <h2 className="heading-3 text-black font-bold relative bottom-4">{props.heading}</h2>
        {showSuspendButon && (
          <button type="button" className="link-button" onClick={handleSuspend}>
            Suspend this task
          </button>
        )}
      </div>
      <div className="rounded-lg breakdownTableBorder ">
        <table className="border-spacing-zero pouchPopup">
          <thead>
            <tr>
              <th className="text-left w-1/5 rounded-tl-lg">
                <b>Denomination</b>
              </th>
              <th className="width-10"></th>
              <th className="text-left w-1/5">
                <b>{BagCode || looseBag || looseCoin ? "No. of Bags" : "Quantity"}</b>
              </th>
              {!looseCoin && <th className="width-8"></th>}
              {looseBag && looseCoin && (
                <>
                  <th className="text-left w-1/5">
                    <b>Loose Coins</b>
                  </th>
                  <th className="width-10"></th>
                </>
              )}
              <th className="text-center w-1/5 rounded-tr-lg">
                <b>Value</b>
              </th>
            </tr>
          </thead>
          <tbody>
            {looseCoin
              ? cashdetail?.map((row, position) => (
                  <LadderRowLooseCoins
                    key={position}
                    item={row}
                    index={position}
                    looseCoin={looseCoin}
                    disabledFlags={disabled}
                    onChange={onChangeForLadderRowLooseCoins}
                    limitExceeds={limitExceeds}
                  />
                ))
              : cashdetail?.map((row, position) => (
                  <LadderRow
                    key={position}
                    item={row}
                    index={position}
                    looseBag={looseBag}
                    looseCoin={looseCoin}
                    disabledFlags={disabled}
                    onChange={onChange}
                    limitExceeds={limitExceeds}
                  />
                ))}
            <tr key={"total"}>
              <td className="text-left w-1/5 rounded-bl-lg">Total</td>
              <td className="width-15 text-center"></td>
              {!looseCoin && (
                <td className="width-10 text-right">
                  {isNaN(Total?.totalBag) ? 0 : Total?.totalBag}
                </td>
              )}
              {looseBag && looseCoin && (
                <>
                  <td className="text-right w-1/5 text-right">
                    <b>{Total?.totalBag}</b>
                  </td>
                  <td className="width-10 text-right">
                    <b>{Total?.totalLooseCoin}</b>
                  </td>
                </>
              )}
              <td className="width-15 text-center">=</td>
              <td className="text-right w-1/5 rounded-br-lg">
                <b>£{Total?.totalAmount?.toFixed(2)}</b>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-center pt-2 w-full">
        {showCancelButon && (
          <button className="brandWhiteBtn mr-1 w-5/12 font-bold" onClick={handleCancel}>
            Cancel
          </button>
        )}
        {showCompleteButton && (
          <button
            className="accounting-button ml-1 w-5/12"
            disabled={
              !!(
                (Total && Total.totalAmount <= 0) ||
                Total.totalAmount > LIMIT_EXCEEDS ||
                Total.totalAmount === totalAmountOnLoad[0]
              )
            }
            onClick={handleComplete}>
            Complete
          </button>
        )}
      </div>
      {limitExceeds && (
        <PopupConfirm
          header={"Pouch amount above £26000.00 will not be permitted."}
          labelSuccess="Close"
          tickicon={false}
          onSuccess={() => setLimitExceeds(false)}
        />
      )}
    </>
  );
};
