import { DISCREPANCY_STATUS } from "@bbo/constants";
import { amountPound, amountUnSignedPound } from "@bbo/utils/currencyFormatter";
import { formatDateYearMonthDay, getOnlyTime } from "@bbo/utils/dateTimeFormatter";
import React from "react";
import "./index.css";
interface iProps {
  DetailedTable?: any;
  bpTpDeclarationTitle?: string | null;
}

export const DetailedTableView = ({ DetailedTable, bpTpDeclarationTitle }: iProps) => {
  const [isBreakDownExpanded, setIsBreakDownExpanded] = React.useState<boolean>(false);
  const toggleBreakDown = () => {
    setIsBreakDownExpanded((prevState) => !prevState);
  };
  if (DetailedTable) {
    return (
      <>
        <table className="w-full DetailViewtTable ">
          <tbody className="bg-white divide-y divide-gray-200">
            {(DetailedTable.reasonCode === DISCREPANCY_STATUS.TP ||
              DetailedTable.reasonCode === DISCREPANCY_STATUS.BP) && (
              <tr>
                <td className="leftTextStyle">
                  <b>{DetailedTable.reasonCode} Number</b>
                </td>
                <td className="rightTextLight">{bpTpDeclarationTitle || ""}</td>
                <td></td>
              </tr>
            )}
            {DetailedTable.reasonCode === DISCREPANCY_STATUS.TC && (
              <>
                <tr>
                  <td className="leftTextStyle">
                    <b>Description</b>
                  </td>
                  <td className="rightTextLight">{DetailedTable?.reasonCodeDescription}</td>
                  <td></td>
                </tr>
                <tr>
                  <td className="leftTextStyle">
                    <b>Value</b>
                  </td>
                  <td className="rightTextLight">
                    {amountUnSignedPound(DetailedTable?.discrepancyValue)}
                  </td>
                  <td></td>
                </tr>
              </>
            )}
            {DetailedTable.reasonCode !== DISCREPANCY_STATUS.TC && (
              <>
                <tr>
                  <td className="leftTextStyle">
                    <b>User Name</b>
                  </td>
                  <td className="rightTextLight">{DetailedTable?.updatedBy?.userName}</td>
                  <td></td>
                </tr>
                <tr>
                  <td className="leftTextStyle">
                    <b>Smart ID</b>
                  </td>
                  <td className="rightTextLight">{DetailedTable?.updatedBy?.smartID}</td>
                  <td></td>
                </tr>
              </>
            )}
            <tr>
              <td className="leftTextStyle">
                <b>Date</b>
              </td>
              <td className="rightTextLight">{formatDateYearMonthDay(DetailedTable?.timestamp)}</td>
              <td></td>
            </tr>
            <tr>
              <td className="leftTextStyle">
                <b>Time</b>
              </td>
              <td className="rightTextLight">{getOnlyTime(DetailedTable?.timestamp)}</td>
              <td></td>
            </tr>
            {DetailedTable.reasonCode !== DISCREPANCY_STATUS.TC &&
              DetailedTable?.updatedBy?.updatedByName && (
                <>
                  <tr>
                    <td className="leftTextStyle">
                      <b>Accounting Location</b>
                    </td>
                    <td className="rightTextLight">{DetailedTable?.updatedBy?.updatedByName}</td>
                    <td></td>
                  </tr>
                </>
              )}

            {DetailedTable.reasonCode === DISCREPANCY_STATUS.CASH_POUCH_DESPATCH ||
            DetailedTable.reasonCode === DISCREPANCY_STATUS.CASH_POUCH_ACCEPTANCE ||
            DetailedTable.reasonCode === DISCREPANCY_STATUS.BANK_MISS_KEY ||
            DetailedTable.reasonCode === DISCREPANCY_STATUS.CARD_FAILURE ? (
              <tr>
                <td className="leftTextStyle">
                  <b>Value</b>
                </td>
                <td className="rightTextLight">
                  {amountUnSignedPound(DetailedTable?.discrepancyValue)}
                </td>
                <td></td>
              </tr>
            ) : (
              <></>
            )}
            {(DetailedTable.reasonCode === DISCREPANCY_STATUS.TP ||
              DetailedTable.reasonCode === DISCREPANCY_STATUS.BP) && (
              <>
                <tr>
                  <td className="leftTextStyle">
                    <b>Total Value</b>
                  </td>
                  <td className="rightTextLight">{amountPound(DetailedTable?.discrepancyValue)}</td>
                  <td className="breakDownWrapper cursor-pointer" onClick={toggleBreakDown}>
                    <span className="breakDowntitle">Break Down</span>
                    <span className="breakDownIcon">
                      {isBreakDownExpanded ? (
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none">
                          <path d="M20 10L10 -8.74228e-07L0 10L20 10Z" fill="black" />
                        </svg>
                      ) : (
                        <svg width="20" height="10" viewBox="0 0 20 10" fill="none">
                          <path d="M0 0L10 10L20 0L0 0Z" fill="black" />
                        </svg>
                      )}
                    </span>
                  </td>
                </tr>
              </>
            )}
            {isBreakDownExpanded && (
              <>
                <tr className="headerRow">
                  <td className="leftTextStyle">
                    <b>Type</b>
                  </td>
                  <td className=""></td>
                  <td className="leftTextStyle breakDownWrapper ">Value</td>
                </tr>
                {DetailedTable?.commodities?.map(({ commodityName, totalVariance }) => (
                  <tr key={commodityName}>
                    <td className="">{commodityName}</td>
                    <td className=""></td>
                    <td className="breakDownWrapper">{amountPound(totalVariance)}</td>
                  </tr>
                ))}
              </>
            )}
            <tr>
              <td className="leftTextStyle">
                <b>Status</b>
              </td>
              <td className="rightTextLight">{DetailedTable?.status}</td>
              <td></td>
            </tr>
            {DetailedTable.reasonCode !== DISCREPANCY_STATUS.TC && (
              <tr>
                <td className="leftTextStyle">
                  <b>Reason Code</b>
                </td>
                <td className="rightTextLight">{DetailedTable?.reasonCodeDescription}</td>
                <td></td>
              </tr>
            )}
          </tbody>
        </table>
        {DetailedTable.reasonCode === DISCREPANCY_STATUS.TC && (
          <div>
            <p className="subTitle text-color mb-2">Transaction Correction Narrative</p>
            <textarea
              className="correction-Narrative"
              readOnly
              value={DetailedTable?.correctionMessage}
            />
          </div>
        )}
      </>
    );
  }

  return null;
};
