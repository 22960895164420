import Loader from "@bbo/components/Loader";
import {
  Balance_PERIOD_POPUP_DATA,
  BP_TP_PERIOD_TYPES,
  TRADING_PERIOD_POPUP_DATA,
} from "@bbo/constants";
import { CommitTpBp } from "@bbo/features/CommitTpBp";
import {
  APIStatus,
  BpTpButtonVisibilityStatus,
  CommonBpTpProps,
  PostBpTpProps,
  TransactionProps,
} from "@bbo/interfaces/BpTp";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import CommitSuccessPopup from "../CommitTpBp/CommitSuccessPopup";
import { completeBpTpButtonStatus, emptyPostBpTPProps, emptyTransactionProps } from "./BpTpHelpers";
import { BranchView } from "./BranchView";
import { DetailView } from "./DetailView";

export interface IProps {
  BpTpProps: CommonBpTpProps;
  postBpTPProps?: PostBpTpProps;
  transactionProps?: TransactionProps;
}

export const BpTpView = ({ BpTpProps, postBpTPProps, transactionProps }: IProps) => {
  const navigate = useNavigate();
  const { fromHistory, periodName, fromDiscrepancyAccount, periodType } = BpTpProps;
  const [completeBpTpPeriod, setCompleteBpTpPeriod] = useState<boolean>(false);
  const [isCompleteBpTpButtonEnabled, setIsCompleteBpTpButtonEnabled] = useState<boolean>(false);
  const [showCompleteSuccessPopup, setShowCompleteSuccessPopup] = useState<boolean>(false);
  const [showCompleteFailedPopup, setShowCompleteFailedPopup] = useState<boolean>(false);
  const [IsCreateBpTpProcessing, setIsCreateBpTpProcessing] = useState<boolean>(false);
  const {
    postCreateBp,
    isCreateBpSuccess,
    postCreateTp,
    isCreateTpSuccess,
    isCreateTpError,
    isCreateBpError,
    createTpResponse,
    createBpResponse,
  } = postBpTPProps || emptyPostBpTPProps;

  const {
    transactionPostBody,
    postBpTpTransaction,
    isBpTpTransactionSuccess,
    isBpTpTransactionError,
  } = transactionProps || emptyTransactionProps;

  const [completeBpTpButtonVisibilityStatus, setCompleteBpTpButtonVisibilityStatus] =
    useState<BpTpButtonVisibilityStatus>(
      BpTpButtonVisibilityStatus.COMMIT_BUTTON_VISIBILITY_STATUS_HIDE,
    );

  useEffect(() => {
    if (isCompleteBpTpButtonEnabled) {
      setCompleteBpTpButtonVisibilityStatus(completeBpTpButtonStatus[0]);
    } else {
      setCompleteBpTpButtonVisibilityStatus(completeBpTpButtonStatus[1]);
    }
  }, [isCompleteBpTpButtonEnabled]);

  const handleCompleteSuccessPopup = (createBpTpStatus: APIStatus) => {
    setShowCompleteSuccessPopup(false);
    setShowCompleteFailedPopup(false);
    createBpTpStatus === "success" && navigate("/taskManagement");
  };

  const enableCompleteBpTpButton = (isCompleted: boolean) => {
    setIsCompleteBpTpButtonEnabled(isCompleted);
  };

  const handleCompleteConfirm = async () => {
    setCompleteBpTpPeriod(false);
    setIsCreateBpTpProcessing(true);
    periodType === BP_TP_PERIOD_TYPES.BP ? await postCreateBp() : await postCreateTp();
    periodType === BP_TP_PERIOD_TYPES.BP ? await isCreateBpSuccess : await isCreateTpSuccess;
  };

  useEffect(() => {
    if ((isCreateTpSuccess || isCreateBpSuccess) && (createTpResponse || createBpResponse)) {
      if (transactionPostBody?.valueInPence !== 0) {
        postBpTpTransaction({ data: transactionPostBody });
      } else {
        setIsCreateBpTpProcessing(false);
        setShowCompleteSuccessPopup(true);
      }
    }
  }, [
    isCreateBpSuccess,
    isCreateTpSuccess,
    postBpTpTransaction,
    createTpResponse,
    createBpResponse,
    transactionPostBody,
  ]);

  useEffect(() => {
    if (!IsCreateBpTpProcessing) return;
    if (isCreateTpSuccess || isCreateBpSuccess) {
      if (isBpTpTransactionSuccess) {
        setIsCreateBpTpProcessing(false);
        setShowCompleteSuccessPopup(true);
        return;
      }
    }
    if (isCreateTpError || isCreateBpError || isBpTpTransactionError) {
      setIsCreateBpTpProcessing(false);
      setShowCompleteFailedPopup(true);
    }
  }, [
    IsCreateBpTpProcessing,
    isBpTpTransactionError,
    isBpTpTransactionSuccess,
    isCreateBpError,
    isCreateBpSuccess,
    isCreateTpError,
    isCreateTpSuccess,
  ]);

  return (
    <>
      {IsCreateBpTpProcessing && <Loader />}
      <div className="balancePeriod">
        <BranchView
          isAllAccountingLocationsCompleted={enableCompleteBpTpButton}
          BpTpProps={BpTpProps}
        />
        <DetailView BpTpProps={BpTpProps} />
        {completeBpTpPeriod && (
          <CommitTpBp
            content={
              periodType === BP_TP_PERIOD_TYPES.BP
                ? Balance_PERIOD_POPUP_DATA
                : TRADING_PERIOD_POPUP_DATA
            }
            isPasswordValidation={false}
            popupType={periodType}
            onCancelCommit={() => setCompleteBpTpPeriod(false)}
            onCommit={handleCompleteConfirm}
          />
        )}
        {!fromHistory && !fromDiscrepancyAccount && (
          <div className="flex w-full justify-end mb-16 px-10">
            {completeBpTpButtonStatus.includes(completeBpTpButtonVisibilityStatus) && (
              <button
                disabled={completeBpTpButtonVisibilityStatus === completeBpTpButtonStatus[1]}
                className="completeButton ml-2"
                onClick={() => setCompleteBpTpPeriod(true)}>
                {`Complete ${periodName}`}
              </button>
            )}
          </div>
        )}
        {showCompleteSuccessPopup && (
          <CommitSuccessPopup
            title={`${periodName} Committed`}
            onSuccess={(status) => handleCompleteSuccessPopup(status)}
            status="success"
          />
        )}
        {showCompleteFailedPopup && (
          <CommitSuccessPopup
            title={`Create ${periodName} Failed`}
            onSuccess={(status) => handleCompleteSuccessPopup(status)}
            status="error"
          />
        )}
      </div>
    </>
  );
};
