import { useGetTransactions } from "@bbo/api/generatorNoFadcode/endpoints/transactions-viewer";
import { useAppSelector, useGlobalState } from "@bbo/lib";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { amountPound } from "@bbo/utils/currencyFormatter";
import {
  currentDateDDMMYYYY,
  currentOnlyTime,
  formatDateYearMonthDay,
  getOnlyTime,
} from "@bbo/utils/dateTimeFormatter";
import { removeEmptyLinesFromAddress } from "@bbo/utils/receiptHelper";
import { TransactionLogTemplate } from "@bbo/utils/ReceiptPrinterTemplates/TransactionLog";
import { useEffect, useMemo, useState } from "react";
//import { mockResponseData } from "./Data";
export const useTransactionLogPage = () => {
  const receiptPrinterService = useReceiptPrinter();
  const [param, setParam] = useState({ to: null, from: null });
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);
  const [transactionLogParam] = useGlobalState("transactionLogParam");
  const [transactionLogParamDate] = useGlobalState("transactionLogParamDate");
  const [dataPass, setdataPasss] = useState([]);
  const branchDetails = useAppSelector((root) => root.branchDetails);
  const {
    data: transaction,
    // data: mockResponseData,
    isRefetching,
    fetchStatus,
    isLoading,
  } = useGetTransactions(
    fadCode,
    param.from,
    param.to,
    { transactionMode: "1" },
    {
      query: {
        refetchOnWindowFocus: true,
      },
    },
  );

  const DateRangeFun = (parFrom, parTo) => {
    const dateFrom =
      transactionLogParamDate.date && transactionLogParam.to
        ? parFrom
        : transactionLogParamDate.date === null
        ? new Date().setHours(0, 0, 0, 0) / 1000
        : new Date(transactionLogParamDate.date).setHours(0, 0, 0, 0) / 1000;
    const dateTo =
      transactionLogParamDate.date && transactionLogParam.to
        ? parTo
        : transactionLogParamDate.date === null
        ? new Date().setHours(23, 59, 59, 0) / 1000
        : new Date(transactionLogParamDate.date).setHours(23, 59, 59, 0) / 1000;
    return {
      from: dateFrom,
      to: dateTo,
    };
  };

  useEffect(() => {
    const confirmBtn = async () => {
      const from =
        new Date(transactionLogParamDate.date).setHours(
          transactionLogParam.from,
          transactionLogParam.fromMin,
          0,
          0,
        ) / 1000;
      const to =
        new Date(transactionLogParamDate.date).setHours(
          transactionLogParam.to,
          transactionLogParam.toMin,
          0,
          0,
        ) / 1000;
      setParam({
        from: from,
        to: to,
      });

      setParam(DateRangeFun(from, to));

      await transaction;
      if (transactionLogParam.to) {
        setdataPasss(
          transaction?.Transactions?.sort((a, b) => {
            return new Date(a.TransactionStartTime).getTime() <
              new Date(b.TransactionStartTime).getTime()
              ? 1
              : -1;
          }),
        );
      } else {
        setdataPasss(
          transaction?.Transactions?.sort((a, b) => {
            return b.TransactionStartTime - a.TransactionStartTime;
          }),
        );
      }
    };
    confirmBtn();
  }, [transaction, transactionLogParam.from, transactionLogParam.to, transactionLogParamDate.date]);

  const onPrintClick = async (params) => {
    const branchAddressClean = removeEmptyLinesFromAddress(branchDetails.branch_address);
    const transactionLogData = await params.map((item) => {
      return {
        deviceID: item.basketOpenedBy,
        deviceType: item.deviceType,
        basketID: item.basketID,
        entryID: item.nodeID,
        TransactionID: item.basketID,
        date: formatDateYearMonthDay(item.TransactionStartTime),
        time: getOnlyTime(item.TransactionStartTime),
        itemID: item.itemID,
        itemDescription: item.itemDescription,
        quantity: item.quantity,
        valueInPence: item.valueInPence,
      };
    });
    receiptPrinterService.printReceipt({
      template: TransactionLogTemplate,
      context: {
        title: "TRANSACTION LOG",
        fadcode: fadCode,
        branchName: branchDetails.branch_name,
        addressLine1: branchAddressClean[0],
        addressLine2: branchAddressClean[1],
        addressLine3: branchAddressClean[2],
        currentDate: currentDateDDMMYYYY(),
        currentTime: currentOnlyTime(),
        lineItems: transactionLogData,
      },
    });
  };

  const memoisedTransactionData = useMemo(
    () =>
      dataPass
        ?.filter((item) => {
          const newdate = item?.TransactionStartTime;
          return newdate >= param?.from && newdate <= param?.to;
        })
        .reduce((acc, obj) => {
          // const newValue = `£${(obj.valueInPence / 100).toFixed(2)}`;
          const newValue = `${amountPound(obj?.valueInPence)}`;
          return [...acc, { ...obj, valueInPence: newValue }];
        }, []),
    [dataPass],
  );
  return { memoisedTransactionData, onPrintClick, isLoading };
};
