import { formatInTimeZone } from "date-fns-tz";

/**
 * Utility for Date Time Formatting
 */

export const formatUnixTimestampToGMTBST = (unixTimestamp: number, formatString: string) => {
  const timeZone = "Europe/London";

  if (!unixTimestamp) return "";

  return formatInTimeZone(unixTimestamp * 1000, timeZone, formatString);
};

export const formatDateToGMTBST = (inputDate: Date, formatString: string) => {
  const timeZone = "Europe/London";

  if (!inputDate) return "";

  return formatInTimeZone(inputDate, timeZone, formatString);
};

export const formatDateStringToGMTBST = (inputDateString: string, formatString: string) => {
  const timeZone = "Europe/London";
  const inputDate = new Date(inputDateString);

  if (!inputDateString) return "";

  return formatInTimeZone(inputDate, timeZone, formatString);
};

export const getDate = (timestamp: number): string =>
  formatUnixTimestampToGMTBST(timestamp, "dd MMMM yyyy");

export const formatDateYearMonthDay = (timestamp: number): string =>
  formatUnixTimestampToGMTBST(timestamp, "dd/MM/yyyy");

export const getDateTime = (timestamp: number): string =>
  formatUnixTimestampToGMTBST(timestamp, "dd/MM/yyyy HH:mm");

export const getOnlyDateTime = (timestamp: number): string =>
  formatUnixTimestampToGMTBST(timestamp, "dd-MM-yyyy HH:mm");

export const getOnlyTime = (timestamp: number): string =>
  formatUnixTimestampToGMTBST(timestamp, "HH:mm");

export const getOnlyDate = (timestamp: number): string =>
  formatUnixTimestampToGMTBST(timestamp, "dd-MM-yyyy");

export const getTransferDateTime = (timestamp: number): string =>
  formatUnixTimestampToGMTBST(timestamp, "dd-MM-yyyy & HH:mm aaa");

export const getdateFormat = (date: Date): string => formatDateToGMTBST(date, "yyyy-MM-dd");

export const getOnlyDateFormat = (date: Date): string => formatDateToGMTBST(date, "dd/MM/yyyy");

export const getUnixTimeStamp = (date: Date): number => Math.floor(+date / 1000);

export const formatDate = (dateString: string): string =>
  formatDateStringToGMTBST(dateString, "dd-MM-yyyy HH:mm");

export const getHoursOnly = (timestamp: number): number =>
  Number(formatUnixTimestampToGMTBST(timestamp, "HH"));

export const convertUnixTimeStampToDate = (timestamp: number): Date => new Date(timestamp * 1000);

export const getTimeForTpBpHistory = (timestamp: number) =>
  formatUnixTimestampToGMTBST(timestamp, "HH:mm aaa");

export const currentDate = () => formatDateToGMTBST(new Date(), "yyyy/MM/dd");

export const currentDateDDMMYYYY = () => formatDateToGMTBST(new Date(), "dd/MM/yyyy");

export const currentDateTime = () => formatDateToGMTBST(new Date(), "dd-MM-yyyy HH:mm aaa");

// Removed currentUTCTime function since it wasn't actually returning the UTC time,
// it was just doing the same thing as this function
export const currentOnlyTime = () => formatDateToGMTBST(new Date(), "HH:mm");

export const currentDateTimePrintReceiptDDMMYYYY = () =>
  formatDateToGMTBST(new Date(), "dd/MM/yyyy HH:mm");
