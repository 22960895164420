import { TableContext } from "@bbo/context";
import { useContext } from "react";

export const useTableContext = () => {
  const context = useContext(TableContext);
  if (!context) {
    throw new Error("TabsContext must be used within Tab component");
  }
  return context;
};
