import { Popup } from "@bbo/components/Popup";
import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";

interface IStockCountReviewCommitPopupProps {
  variance: number;
  onCancel: () => void;
  onConfirm: () => void;
  showGRNMsg: boolean;
}

export const StockCountReviewCommitPopup = ({
  variance,
  onCancel,
  onConfirm,
  showGRNMsg,
}: IStockCountReviewCommitPopupProps) => {
  return (
    <Popup
      testId="stockCountReviewCommitPopup"
      body={
        <div className="standard-label flex flex-col align-center px-12">
          <div className="flex justify-center pb-6">
            <strong className="pr-2">Total Variance Value : </strong>
            <span className={`${currencyStyle(variance)}`}>{amountSignedPound(variance)}</span>
          </div>
          {showGRNMsg ? (
            <span className="pb-3">
              Burglary/Robbery will be moved into the Branch Discrepancy Account.
            </span>
          ) : null}
          <span className="pb-3 text-center">
            Are you sure you want to commit this Stock Count?
          </span>
        </div>
      }
      hideCrossBtn
      actions={[
        {
          name: "Cancel",
          style: "popUp-button white-button mr-4",
          handleAction: onCancel,
        },
        {
          name: "Commit",
          style: "popUp-button red-button ml-4",
          handleAction: onConfirm,
        },
      ]}
    />
  );
};
