import { BranchCashOverview } from "@bbo/api/generator";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { getAccountingLocationText } from "../BranchOverview/branchOverviewHelpers";

const renderTableRow = (label: string, value: string) => (
  <div className="py-4">
    <p className="heading">{label}</p>
    <p className="text-2xl font-bold">{value}</p>
  </div>
);

interface TransferAccountingLocationInfoProps {
  accountingLocation: BranchCashOverview;
  className?: string;
  infoBoxClassName?: string;
}

export const TransferAccountingLocationInfo = ({
  accountingLocation,
  className,
  infoBoxClassName,
}: TransferAccountingLocationInfoProps) => {
  const { userName } = useAppSelector((root) => root.userName);

  const { infoTitle: accountingLocationTitle, type } = getAccountingLocationText(
    accountingLocation?.accountingLocationType,
    accountingLocation?.counterID,
  );

  return (
    <div className={`pt-6 pl-8 ${className}`.trim()}>
      <h2 className="heading-3 font-bold mb-5">Cash Transfer From</h2>
      <div className={`w-full CashTransferNoBorder transferOutHeight ${infoBoxClassName}`}>
        <div className="w-full table-fixed transfer-out-table height-95 border-none">
          <div>
            {renderTableRow(
              accountingLocationTitle,
              type === "counterTerminal"
                ? accountingLocation?.counterName
                : accountingLocation?.accountingLocationName,
            )}
            {renderTableRow("User Logged In", userName)}
            {accountingLocation?.counterID
              ? renderTableRow("Attached Cash Drawer", accountingLocation?.accountingLocationName)
              : null}
            {/* Commenting out for R2 during the Cash DB Migration work */}
            {/* {renderTableRow(
              "Last Transferred Amount",
              amountUnSignedPound(
                Number(transferAmount || itemDetails?.lastTransferAmount)
              )
            )}
            {renderTableRow(
              "Last Transferred Date & Time",
              getTransferDateTime(
                Number(transferDateTime || itemDetails?.lastBalancedDateTime)
              )
            )} */}
          </div>
        </div>
      </div>
    </div>
  );
};
