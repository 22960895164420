import { BranchCashOverview } from "@bbo/api/generator";
import { amountSignedPound } from "@bbo/utils/currencyFormatter";
import { formatDateYearMonthDay } from "@bbo/utils/dateTimeFormatter";
import { memo } from "react";

/**
 * card view with
 * @param props
 * @returns
 */

interface CardProps {
  accountingLocation: BranchCashOverview;
  cellClass?: string;
  onClick?: (accountingLocation: BranchCashOverview) => void;
  attachedIcon?: boolean;
}

const Card = ({ accountingLocation, onClick, cellClass }: CardProps) => {
  const {
    accountingLocationName,
    totalValue,
    counterName,
    counterID,
    lastDeclarationTimestamp,
    toDateVariance,
  } = accountingLocation;
  return (
    <div onClick={() => onClick(accountingLocation)}>
      <div className="heading">
        {counterName ? <span>{counterName}</span> : <span>{accountingLocationName}</span>}

        {counterID ? <i className="fas fa-link mx-2"></i> : null}
        {counterID && <span>{accountingLocationName}</span>}
      </div>
      <hr className="border-gray-200 border-bottom-none" />
      {/* Commented as no smartId coming from API */}
      <div className="second-heading h-10 truncate">
        {/* {onboardedBySmartID && <i className="fas fa-user-check mr-2"></i>} */}
        <span></span>
      </div>
      <hr className="border-gray-200 border-bottom-none" />
      <div className={`third-heading  ${cellClass} justify-center w-auto`}>
        <table className="w-full border-none  ">
          <tbody className="counterPad ">
            <tr>
              <td>Balance</td>
              <td>
                <b>{amountSignedPound(totalValue)}</b>
              </td>
            </tr>

            {/* {toDateVariance && (
              <tr>
                <td>To Date Variance</td>
                <td>
                  <b>{toDateVariance}</b>
                </td>
              </tr>
            )}
            {lastDeclarationTimestamp && (
              <tr>
                <td>Last Balance Date</td>
                <td>
                  <b>{formatDateYearMonthDay(lastDeclarationTimestamp)}</b>
                </td>
              </tr>
            )} */}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default memo(Card);
