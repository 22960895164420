export const ArrowUp = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.38 12.5L3.62 12.5L7 15.88L10.38 12.5ZM14 11L7 18L-4.80825e-07 11L14 11ZM14 7L-3.0598e-07 7L7 3.0598e-07L14 7Z"
        fill="#2E1A47"
      />
    </svg>
  );
};
