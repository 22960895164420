import { BranchCashOverview } from "@bbo/api/generator";
import SafeItem from "./SafeItem";

interface SafeListProps {
  items: BranchCashOverview[];
  isStockData?: boolean;
}

export const SafeList = ({ items, isStockData }: SafeListProps) => {
  return (
    <>
      <h2 className="account-group mb-1">Safe</h2>
      <div className="overflow-x-scroll whitespace-nowrap">
        {items?.map((item) => (
          <SafeItem key={item.accountingLocationID} data={item} isStockData={isStockData} />
        ))}
      </div>
    </>
  );
};
