/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  GetCashDeclarationHistoryResponseResponse,
  BboCashManagementBadRequestResponse,
  UnauthorisedResponse,
  BboCashManagementForbiddenResponse,
  NotFoundResponse,
  FailureResponseResponse,
  GetCashDeclarationHistoryParams,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get cash declaration history records of all accounting locations
 * @summary Get cash declaration history records of all accounting locations history
 */
export const useGetCashDeclarationHistoryHook = () => {
  const getCashDeclarationHistory = useCustomInstance<GetCashDeclarationHistoryResponseResponse>();

  return (params?: GetCashDeclarationHistoryParams) => {
    return getCashDeclarationHistory({
      url: `/BboCashManagementService/v1/cash-declaration/history`,
      method: "get",
      params,
    });
  };
};

export const getGetCashDeclarationHistoryQueryKey = (params?: GetCashDeclarationHistoryParams) => [
  `/BboCashManagementService/v1/cash-declaration/history`,
  ...(params ? [params] : []),
];

export type GetCashDeclarationHistoryQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetCashDeclarationHistoryHook>>
>;
export type GetCashDeclarationHistoryQueryError = ErrorType<
  | BboCashManagementBadRequestResponse
  | UnauthorisedResponse
  | BboCashManagementForbiddenResponse
  | NotFoundResponse
  | FailureResponseResponse
>;

export const useGetCashDeclarationHistory = <
  TData = AsyncReturnType<ReturnType<typeof useGetCashDeclarationHistoryHook>>,
  TError = ErrorType<
    | BboCashManagementBadRequestResponse
    | UnauthorisedResponse
    | BboCashManagementForbiddenResponse
    | NotFoundResponse
    | FailureResponseResponse
  >,
>(
  params?: GetCashDeclarationHistoryParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetCashDeclarationHistoryHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetCashDeclarationHistoryQueryKey(params);

  const getCashDeclarationHistory = useGetCashDeclarationHistoryHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetCashDeclarationHistoryHook>>
  > = () => getCashDeclarationHistory(params);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetCashDeclarationHistoryHook>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};
