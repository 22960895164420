import {
  BboDeclarationError,
  BboPouchManagementError,
  CommodityAccountingLocations,
  CreateBpTpResponseResponse,
  CurrentBpTp,
  Declaration,
} from "@bbo/api/generator";
import { ErrorType } from "@bbo/api/generator/mutator/useCustomInstance";
import { Error, Response, TransferCore } from "@bbo/api/generatorNoFadcode";
import { ErrorType as NoFadCodeErrorType } from "@bbo/api/generatorNoFadcode/mutator/useCustomInstanceNoFadcode";
import { UseMutateFunction } from "@tanstack/react-query";
export type PeriodType = "BP" | "TP";
export type PeriodName = "Balance Period" | "Trading Period";
export type CommodityTypes = "Cash" | "Forex" | "Stock" | "Obsolete";
export type APIStatus = "error" | "loading" | "success";

//! Created custom interface of Commodity/Declaration
export interface BpTpDeclaration extends Declaration {
  declarationCompleted?: boolean;
}
interface BpTpCommodityAccountingLocations extends CommodityAccountingLocations {
  [key: string]: BpTpDeclaration;
}
export interface Commodity {
  varianceValue?: number;
  progress?: number;
  accountingLocations?: BpTpCommodityAccountingLocations;
}

export interface BpTpHistoryProps {
  fromHistory?: boolean;
  fromDiscrepancyAccount?: boolean;
}
export interface BpTpPeriodProps {
  periodName?: PeriodName;
  periodType?: PeriodType;
}
export interface BpTpCurrentProps {
  currentBpTp?: CurrentBpTp;
  currentBpTpStatus?: APIStatus;
}
export interface PostBpTpProps {
  postCreateBp?: UseMutateFunction<
    CreateBpTpResponseResponse,
    ErrorType<BboDeclarationError | BboPouchManagementError>,
    void,
    unknown
  >;
  isCreateBpSuccess?: boolean;
  postCreateTp?: UseMutateFunction<
    CreateBpTpResponseResponse,
    ErrorType<BboDeclarationError | BboPouchManagementError>,
    void,
    unknown
  >;
  isCreateTpSuccess?: boolean;
  createBpResponse?: { declarationID?: string };
  createTpResponse?: { declarationID?: string };
  isCreateBpError?: ErrorType<BboPouchManagementError | BboDeclarationError>;
  isCreateTpError?: ErrorType<BboPouchManagementError | BboDeclarationError>;
}
export interface TransactionProps {
  postBpTpTransaction?: UseMutateFunction<
    Response,
    NoFadCodeErrorType<Error | void>,
    { data: TransferCore },
    unknown
  >;
  isBpTpTransactionSuccess?: boolean;
  transactionPostBody?: TransferCore;
  isBpTpTransactionError?: NoFadCodeErrorType<void | Error>;
}
export interface BpTpCommodities {
  cashCommodity: Commodity;
  forexCommodity?: Commodity;
  stockCommodity?: Commodity;
  obsoleteStockCommodity?: Commodity;
}
export interface CommonBpTpProps extends BpTpCommodities, BpTpHistoryProps, BpTpPeriodProps {
  status?: APIStatus;
}
export interface BpTpProps
  extends BpTpCommodities,
    PostBpTpProps,
    BpTpHistoryProps,
    TransactionProps {
  status?: APIStatus;
}

export enum BpTpButtonVisibilityStatus {
  COMMIT_BUTTON_VISIBILITY_STATUS_HIDE,
  COMMIT_BUTTON_VISIBILITY_STATUS_DISABLE,
  COMMIT_BUTTON_VISIBILITY_STATUS_SHOW,
}

export interface BranchViewCashInterface {
  cashData: Declaration[];
  cashPercentage: number;
  cashAction: string;
  cashProgressColor: string;
}
export interface BranchViewForexInterface {
  forexData: Declaration[];
  forexPercentage: number;
  forexAction: string;
  forexProgressColor: string;
}
export interface BranchViewStockInterface {
  stockData: Declaration[];
  stockPercentage: number;
  stockAction: string;
  stockProgressColor: string;
}
export interface BranchViewObsoleteStockInterface {
  obsoleteData: Declaration[];
  obsoletePercentage: number;
  obsoleteAction: string;
  obsoleteProgressColor: string;
}
export interface DetailViewCashInterface {
  cashRecount: boolean;
  cashAction: string;
  cashProgressColor: string;
}
export interface DetailViewForexInterface {
  forexRecount: boolean;
  forexAction: string;
  forexProgressColor: string;
}
export interface DetailViewStockInterface {
  stockRecount: boolean;
  stockAction: string;
  stockProgressColor: string;
}
export interface DetailViewObsoleteInterface {
  obsoleteRecount: boolean;
  obsoleteAction: string;
  obsoleteProgressColor: string;
}

export interface AllAccountsInterface {
  cash: boolean;
  forex: boolean;
  stock: boolean;
  obsolete: boolean;
}

export interface DetailViewProps extends BpTpHistoryProps {
  status?: APIStatus;
  selectedTab?: string;
  selectedValue?: BpTpDeclaration;
  selectedKey?: string;
}

export interface EachDetailViewCommodityProps {
  detailViewProps: {
    fromHistory?: boolean;
    fromDiscrepancyAccount?: boolean;
    completeBalance: () => void;
    recountBalance: () => void;
    completedBalance: () => void;
  };
  detailViewCommodityProps: {
    commodityTypeName: string;
    commodityProgressColor: string;
    commodityAction: string;
    commodityRecount: boolean;
    commodityVarianceValue: number;
  };
}
export interface EachBranchViewCommodityProps {
  branchViewProps: {
    fromHistory?: boolean;
    fromDiscrepancyAccount?: boolean;
    completeBalance: (balancingDeclID: string, accountingLocationID: string) => void;
  };
  branchViewCommodityProps: {
    commodityTypeName: string;
    commodityProgressColor: string;
    commodityPercentage: number;
    commodityAction: string;
    commodityData: BpTpDeclaration[];
    commodityVarianceValue: number;
  };
}
export interface ErrorMessageProps {
  message: string;
}
