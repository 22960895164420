import { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { DynamoTableCell } from "./DynamoTableCell";
import { DynamoTableContext } from "./DynamoTableContext";
import { Column } from "./dynamoTableTypes";

interface DynamoTableRowProps<T> {
  index: number;
  selected: boolean;
  data: T;
  columns: Column[];
  onRowClicked: (row: T) => void;
}

export const keyPathValue = (param: unknown, key: string) => {
  const keys = key.replace(/\[(\w+)\]/g, ".$1").split("."); // same as original
  let result = param;
  for (const k of keys) {
    result = result?.[k];
    if (result === undefined) {
      return undefined;
    }
  }
  return result;
};

export const DynamoTableRow = <T,>({
  index,
  selected,
  data,
  columns,
  onRowClicked,
}: DynamoTableRowProps<T>) => {
  const { showCheckboxes, onSelectChange, shouldDisableRowCheckbox } =
    useContext(DynamoTableContext);
  return (
    <tr onClick={() => onRowClicked?.(data)}>
      {showCheckboxes ? (
        <td>
          <input
            type="checkbox"
            className="cell-checkbox"
            checked={selected}
            onClick={(e) => e.stopPropagation()}
            onChange={() => {
              onSelectChange(index);
            }}
            disabled={shouldDisableRowCheckbox?.(data, index)}
          />
        </td>
      ) : null}
      {columns?.map((col) => (
        <DynamoTableCell key={uuidv4()} column={col} cellData={keyPathValue(data, col.fieldName)} />
      ))}
    </tr>
  );
};
