import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type PaginationSlice = {
  currentPage: number;
};
const initialState: PaginationSlice = { currentPage: 1 };
const slice = createSlice({
  name: "pagination",
  initialState,
  reducers: {
    setCurrentGlobalPage: (state: PaginationSlice, action: PayloadAction<PaginationSlice>) => {
      state.currentPage = action.payload.currentPage;
    },
  },
});

export const { setCurrentGlobalPage } = slice.actions;

export default slice.reducer;
