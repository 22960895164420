//ADD,REMOVE,REPRINT, and all other flows will be handled here..
/**
 * Alert/Popup view with
 * @param props
 * @returns
 */
import React from "react";
interface iProps {
  selectedRow?: Array<any>;
  comment: string;
  handleComment?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onSuccess?: () => void;
  onCancel?: () => void;
}

const DisputePopUp = ({ ...props }: iProps): JSX.Element => {
  const handleComment = (event) => {
    if (props.handleComment) {
      props.handleComment(event);
    }
  };
  const onSuccess = () => {
    if (props.onSuccess) {
      props.onSuccess();
    }
  };
  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-xl popupModelHighLevel">
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-8	">
            <div className="flex pl-4  items-center justify-start">
              <div>
                <h2 className="leading-relaxed text-color pr-2 amountHighLevel">Comment</h2>
              </div>
            </div>
            <div className="pl-4 amountHighLevel">
              <textarea
                className=" w-full textArea shadow "
                rows={2}
                value={props.comment}
                onChange={handleComment}
                placeholder="Please provide comments for your records."></textarea>
            </div>
            {/*footer*/}
            <div className="flex items-center pt-8 justify-end">
              <button
                className="restNoBtnMcdHighLevel border-t border-r border-l border-b border-grey border-solid bg-transparent-500 text-black active:bg-transparent-600 font-bold text-sm px-10 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-6 ease-linear transition-all duration-150 hover"
                type="button"
                value="Cancel"
                onClick={onCancel}
                data-cy="Cancel">
                Cancel
              </button>
              <button
                className="brandRedBtnMcdHighLevel text-white active:brandRedBtnMcdHighLevel font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                value="Confirm"
                onClick={onSuccess}
                data-cy="Confirm">
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default DisputePopUp;
