import { Dispatch, SetStateAction, useEffect, useMemo } from "react";

export const useDataSelection = (
  paginatedData: unknown[],
  selectedItemIndexes: number[],
  setSelectedItemIndexes: Dispatch<SetStateAction<number[]>>,
) => {
  // Whenever paginatedData changes we need to reset selectedDataIndexes.
  // This is because the "Select All" header only applies to the current page,
  // so if we don't reset on page change then we have no UI to deselect all the checked rows
  useEffect(() => {
    setSelectedItemIndexes((prev) => (prev.length === 0 ? prev : []));
  }, [paginatedData, setSelectedItemIndexes]);

  const selectedItems = useMemo(
    () => selectedItemIndexes.map((index) => paginatedData[index]),
    [paginatedData, selectedItemIndexes],
  );

  return {
    selectedItems,
  };
};
