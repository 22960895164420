/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, UseMutationOptions, MutationFunction } from "@tanstack/react-query";
import type {
  Success,
  BboCashManagementBadRequestResponse,
  UnauthorisedResponse,
  BboCashManagementForbiddenResponse,
  NotFoundResponse,
  FailureResponseResponse,
  CashBalancePayload,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Create a cash balance declaration record for an accounting location
 * @summary Add a cash declaration of an accounting location
 */
export const usePostCashDeclarationHook = () => {
  const postCashDeclaration = useCustomInstance<Success>();

  return (cashBalancePayload: CashBalancePayload) => {
    return postCashDeclaration({
      url: `/BboCashManagementService/v1/cash-declaration/accounting-location`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: cashBalancePayload,
    });
  };
};

export type PostCashDeclarationMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof usePostCashDeclarationHook>>
>;
export type PostCashDeclarationMutationBody = CashBalancePayload;
export type PostCashDeclarationMutationError = ErrorType<
  | BboCashManagementBadRequestResponse
  | UnauthorisedResponse
  | BboCashManagementForbiddenResponse
  | NotFoundResponse
  | FailureResponseResponse
>;

export const usePostCashDeclaration = <
  TError = ErrorType<
    | BboCashManagementBadRequestResponse
    | UnauthorisedResponse
    | BboCashManagementForbiddenResponse
    | NotFoundResponse
    | FailureResponseResponse
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof usePostCashDeclarationHook>>,
    TError,
    { data: CashBalancePayload },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const postCashDeclaration = usePostCashDeclarationHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof usePostCashDeclarationHook>>,
    { data: CashBalancePayload }
  > = (props) => {
    const { data } = props || {};

    return postCashDeclaration(data);
  };

  return useMutation<
    AsyncReturnType<typeof postCashDeclaration>,
    TError,
    { data: CashBalancePayload },
    TContext
  >(mutationFn, mutationOptions);
};
