import { useGetTransactions } from "@bbo/api/generatorNoFadcode";
import { IParamsSet } from "@bbo/features/AdhocBalance/paramType";
import { constructCashMovementData } from "@bbo/utils";
import { getValueFromStorage } from "@bbo/utils/util";
import { useMemo } from "react";

export interface CashMovementHistoryData {
  dateTime?: number;
  destinationEntityName?: string;
  fadcode?: string;
  nodeID?: string;
  operationType?: string;
  smartID?: string;
  sourceEntityName?: string;
  transferAmount?: number;
  transferAt?: string;
  userName?: string;
}

export const useTransactionApi = ({ fromDate, toDate }: IParamsSet) => {
  const fadCode = getValueFromStorage<string>("fadcode");

  const { data: cashMovementHistory, ...rest } = useGetTransactions(fadCode, fromDate, toDate);

  const cashMovementHistoryData: CashMovementHistoryData[] | undefined = useMemo(() => {
    if (!rest.isSuccess) return undefined; // Just to keep consistent with what the state value was defaulting to. Should probably be null

    return constructCashMovementData(cashMovementHistory);
  }, [cashMovementHistory, rest.isSuccess]);

  return {
    cashMovementHistoryData,
    ...rest,
  };
};
