import { TaskData } from "@bbo/api/generator";
import Loader from "@bbo/components/Loader";
import { formatDateYearMonthDay, getDateTime } from "@bbo/utils/dateTimeFormatter";

const statusesToShow = ["completed", "incomplete"];

export const TaskHistory = ({ data }: { data: TaskData[] }) => {
  return (
    <>
      {data?.map((item, index) => {
        return (
          statusesToShow.includes(item?.status?.toLowerCase()) && (
            <div key={index} className="flex hover justify-center tabRowTaskHistory">
              <div
                data-testid={`taskAndEntity-${index}`}
                className="w-1/5 flex items-center tableFont">
                <div>
                  {item?.task} - {item?.accountingLocationName}
                </div>
              </div>
              <div
                data-testid={`run_date_time-${index}`}
                className="w-1/5 flex items-center tableFont">
                <div> {formatDateYearMonthDay(item?.runDateTime)}</div>
              </div>
              <div data-testid={`status-${index}`} className="width-15 flex items-center tableFont">
                <div className={item?.status}> {item?.status}</div>
              </div>
              <div data-testid={`user-${index}`} className="w-1/4 flex items-center tableFont">
                <div className="break-all"> {item?.updatedBy?.userName}</div>
              </div>
              <div
                data-testid={`completionDateTime-${index}`}
                className="w-1/5 flex items-center tableFont">
                <div>
                  {item?.status?.toLowerCase() === "incomplete"
                    ? ""
                    : getDateTime(item?.completionTimestamp)}
                </div>
              </div>
            </div>
          )
        );
      })}
    </>
  );
};
