interface IStockCountReviewFooterProps {
  handleCancelCount: () => void;
  handleResetCount: () => void;
  handleUpdateCount: () => void;
  handleCommitCount: () => void;
  anyModified: boolean;
}

export const StockCountReviewFooter = ({
  anyModified,
  handleCancelCount,
  handleCommitCount,
  handleResetCount,
  handleUpdateCount,
}: IStockCountReviewFooterProps) => {
  return (
    <div className="flex w-full justify-center top-border-light p-4 bg-white sticky-panel space-x-6">
      <button className="action-button button-blue" onClick={handleCancelCount}>
        Cancel Count
      </button>
      <button className="action-button button-white" onClick={handleResetCount}>
        Reset
      </button>
      {anyModified ? (
        <button className="action-button button-red" onClick={handleUpdateCount}>
          Update
        </button>
      ) : (
        <button className="action-button button-red" onClick={handleCommitCount}>
          Commit
        </button>
      )}
    </div>
  );
};
