import Select from "react-select";
import { customStyles } from "./Styles";
type Props = {
  selectedValue: any;
  groupedOptions: any[];
  handleChange: (params) => void;
  DropdownIndicator: any;
};

export const BranchSelectionDropDown = ({
  selectedValue,
  groupedOptions,
  handleChange,
  DropdownIndicator,
}: Props) => {
  return (
    <Select
      onChange={handleChange}
      options={groupedOptions}
      value={selectedValue}
      styles={customStyles}
      components={{ IndicatorSeparator: () => null, DropdownIndicator }}
      isSearchable={false}
      placeholder="Please select an option"
    />
  );
};
