import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { BookingDetails } from "@bbo/features/BookingStockDetails/BookingDetails";
import { useEffect } from "react";

const BookingDetailsScreen = () => {
  const { setTitle } = useTitle();
  useEffect(() => {
    setTitle("Manual Book In Stock");
  }, [setTitle]);
  return (
    <>
      <BookingDetails />
    </>
  );
};

export default BookingDetailsScreen;
