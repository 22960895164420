import { DiscrepencyHistory } from "@bbo/api/generator";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: DiscrepencyHistory = {
  centralSupport: [],
  centralSupportVarianceBalance: 0,
  history: [],
  settled: [],
  varianceBalance: 0,
  waitedVarianceBalance: 0,
  waitingBranchAction: [],
};

export const DiscrepancyAccount = createSlice({
  name: "DiscrepancyAccount",
  initialState,
  reducers: {
    setDiscrepancyAccountDetails: (state, action: PayloadAction<DiscrepencyHistory>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setDiscrepancyAccountDetails } = DiscrepancyAccount.actions;

export default DiscrepancyAccount.reducer;
