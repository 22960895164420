import { BranchCashOverview } from "@bbo/api/generator";
import { STRING_CONSTANTS } from "@bbo/constants";
import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";
import { formatDateYearMonthDay } from "@bbo/utils/dateTimeFormatter";
import { v4 as uuidv4 } from "uuid";
import { branchOverviewCommonStrings, branchOverviewForexStrings } from "./branchOverviewHelpers";

export const useTableList = (data: BranchCashOverview) => {
  const { lastVariance, toDateVariance, lastBalance, rows } =
    location.pathname === "/branchOverviewForex"
      ? branchOverviewForexStrings
      : branchOverviewCommonStrings;

  const orderedTable = rows.map((row) => {
    switch (row) {
      case "currentVariance":
        return (
          <tr className={currencyStyle(data.currentVariance || 0)} key={uuidv4()}>
            <td>{lastVariance}</td>
            <td>
              <b>{amountSignedPound(data.currentVariance || 0)}</b>
            </td>
          </tr>
        );
      case "toDateVariance":
        return (
          <tr key={uuidv4()}>
            <td>{toDateVariance}</td>
            <td>
              <b>{amountSignedPound(data.toDateVariance)}</b>
            </td>
          </tr>
        );
      case "lastBalancedDateTime":
        return (
          <tr key={uuidv4()}>
            <td>{lastBalance}</td>
            <td>
              <b>
                {data.timestamp
                  ? formatDateYearMonthDay(data.timestamp)
                  : STRING_CONSTANTS.BranchOverview.noDeclarations}
              </b>
            </td>
          </tr>
        );
      default:
        break;
    }
  });
  return { orderedTable, lastVariance, toDateVariance, lastBalance };
};
