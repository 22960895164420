import { SubMenu } from "@bbo/api/generator/model";
import React, { memo } from "react";

type SubMenuProps = {
  items: SubMenu[];
  groupIndex: number;
  group: string;
  expand: boolean;
  onMenuClick: (params) => void;
};

const SubMenuList = ({ expand, items, group, groupIndex, onMenuClick }: SubMenuProps) => {
  const ItemList = () => {
    if (items && items.length > 0) {
      return (
        <>
          {items.map((data, index) => (
            <button
              className={"btnSubMenu hover "}
              data-cy={data.title.replace(" ", "")}
              key={index}
              onClick={() => {
                onMenuClick({
                  data,
                  group,
                  groupIndex,
                  index,
                });
              }}>
              <li>{data.title}</li>
            </button>
          ))}
        </>
      );
    }
    return null;
  };

  return (
    <div className="answer_wrapper" style={{ height: `${expand ? "auto" : "0px"}` }}>
      <div className="answer">
        <ul className="sub-menu-border-left pl-4">
          <ItemList />
        </ul>
      </div>
    </div>
  );
};

export default memo(SubMenuList);
