import { PouchDetails, useGetPouch } from "@bbo/api/generator";
import { ErrorPopup } from "@bbo/components/ErrorPopup";
import Loader from "@bbo/components/Loader";
import { SCREENS } from "@bbo/constants";
import { useScanner } from "@bbo/lib/hooks/useScanner";
import { isValidPOLBarcode } from "@bbo/utils";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PouchPreparationDetailsNavigationState } from "../PouchPreparation/PouchPreparationDetails";
import {
  PouchPreparationModalConfig,
  pouchPreparationModalConfigs,
} from "../PouchPreparation/pouchPreparationModalConfig";
import { usePouchWarningModals } from "../PouchPreparation/usePouchWarningModals";

export const PouchPreparationScanFeature = () => {
  const navigate = useNavigate();
  const [barcode, setBarcode] = useState(""); // Barcode is PouchID
  const [modalConfig, setModalConfig] = useState<PouchPreparationModalConfig | null>(null);
  const [shouldFetch, setShouldFetch] = useState(false);

  const isBarcodeValid = isValidPOLBarcode(barcode);

  // Have to use shouldFetch to drive this, as just setting enabled: false and calling refetch also fetches immediately and then navigates away
  // whenever we navigate back to this screen
  //
  // Using fetchStatus because isLoading is true on page load
  const {
    data: pouchDetails,
    fetchStatus,
    status,
    error,
  } = useGetPouch(barcode, {
    query: { enabled: shouldFetch, cacheTime: 0 },
  });

  useEffect(() => {
    if (!error) return;

    setShouldFetch(false);
  }, [error]);

  const loading = fetchStatus === "fetching";

  const configOverrides = useMemo(
    () => ({
      closeModal: {
        onPrimaryButtonClick: () => {
          setShouldFetch(false);
          setModalConfig(null);
        },
      },
      resumeSuspendedPouch: {
        onSecondaryButtonClick: () => {
          setShouldFetch(false);
          setModalConfig(null);
        },
        onPrimaryButtonClick: () => {
          navigate(`${SCREENS.POUCH_PREPARATION}/${barcode}`, {
            state: {
              shouldResumeSuspendedPouch: true,
            } as PouchPreparationDetailsNavigationState,
          });
          setModalConfig(null);
        },
      },
    }),
    [barcode, navigate],
  );

  const { checkWarningModals } = usePouchWarningModals(
    pouchDetails,
    status,
    setModalConfig,
    configOverrides,
  );

  useEffect(() => {
    return () => {
      setShouldFetch(false);
      setBarcode("");
      setModalConfig(null);
    };
  }, []);

  useEffect(() => {
    // Again using shouldFetch here so we don't instantly navigate away when coming back to this screen and filling in a barcode
    if (status !== "success" || !shouldFetch) return;

    const warningModalsStatus = checkWarningModals();

    if (warningModalsStatus === "should-return") return;

    navigate(`${SCREENS.POUCH_PREPARATION}/${barcode}`);
  }, [barcode, checkWarningModals, navigate, shouldFetch, status]);

  useScanner(
    useCallback(async (text: string) => {
      setBarcode(text);
    }, []),
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    setBarcode(event.target.value);
  };

  const goBack = () => navigate(SCREENS.TASK_MANAGEMENT);

  const handleClick = async () => {
    if (!isBarcodeValid) {
      setModalConfig({
        ...pouchPreparationModalConfigs.invalidBarcode,
        onPrimaryButtonClick: () => setModalConfig(null),
      });
      return;
    }

    setShouldFetch(true);
  };

  // TODO: clean up CSS.
  return (
    <>
      <div className="containerMain flex-1">
        <div className="w-full items-start p-4 transferOut-height overflow-x-auto">
          <div className="w-full grid grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-5 h-full">
            <div className="flex items-center justify-center heading pouchPrepBox p-7">
              <h2 className="heading-3 font-bold">
                Scan or enter pouch barcode in the text box on the right.
              </h2>
            </div>
            <div className="flex items-center justify-center bg-white flex-col pouchPrepBox p-7">
              <input
                className="movementInput mb-4 lg:w-3/5"
                value={barcode}
                onChange={handleChange}
                placeholder="Enter the barcode"
              />
              <div className="flex w-full justify-center">
                <button className="brandWhiteBtn mr-2 lg:w-1/4 font-bold" onClick={goBack}>
                  Cancel
                </button>
                <button className="accounting-button ml-2 lg:w-1/4" onClick={handleClick}>
                  Submit
                </button>
              </div>
            </div>
            {loading ? <Loader fixed /> : null}
          </div>
        </div>
      </div>
      {modalConfig ? (
        <ErrorPopup
          popupType={modalConfig.modalType}
          messageHeading={modalConfig.heading}
          messageBody={modalConfig.message}
          labelSuccess={modalConfig.primaryButtonText}
          labelCancel={modalConfig.secondaryButtonText}
          onSuccess={modalConfig.onPrimaryButtonClick}
          onCancel={modalConfig.onSecondaryButtonClick}
        />
      ) : null}
    </>
  );
};
