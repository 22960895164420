import { PrintCashDrawerData } from "@bbo/types";
import JsBarcode from "jsbarcode";
import { useEffect } from "react";

type Props = {
  printData: PrintCashDrawerData | null;
  onPrintClose: () => void;
};
export const PrintCashDrawer = ({ onPrintClose, printData }: Props) => {
  useEffect(() => {
    if (!printData) return;

    JsBarcode("#barcode", printData.barcode, {
      format: "code128",
      width: 1.3,
      height: 50,
      marginLeft: 0,
      displayValue: true,
    });
    const printCD = () => {
      const printContent = document.getElementById("printarea");
      const previewFrame = document.createElement("iframe");
      previewFrame.name = "previewFrame";
      previewFrame.style.position = "absolute";
      previewFrame.style.top = "-1000000px";
      document.body.appendChild(previewFrame);
      const frameDoc = previewFrame.contentWindow
        ? previewFrame.contentWindow
        : previewFrame.contentDocument["document"]
        ? previewFrame.contentDocument["document"]
        : previewFrame.contentDocument;
      let htmlToPrint = `<style type="text/css">
       .print-view {
       display: inline-block !important;;
       flex-direction: column;
       justify-content: center;
       align-items: center;
       text-align: center;	
       } 
       @page {size: auto;margin: 0mm;}
       div {align-items:center; text-align:center}
       body  {padding-top: 32px; padding-bottom: 32px;}
       </style>`;
      htmlToPrint += printContent.outerHTML;
      frameDoc.document.open();
      frameDoc.document.write(`<html><head><title>${"_"}</title>`);
      frameDoc.document.write("</head><body>");
      frameDoc.document.write(htmlToPrint);
      frameDoc.document.write("</body></html>");
      frameDoc.document.close();
      setTimeout(function () {
        window.frames["previewFrame"].focus();
        window.frames["previewFrame"].print();
        document.body.removeChild(previewFrame);
        onPrintClose();
      }, 500);
      return false;
    };
    printCD();
  }, [onPrintClose, printData]);

  return (
    <div className="print-view">
      <div className="print-outer" id="printarea">
        <div className="print-container">
          <div className="title">{printData?.title || ""}</div>
          <svg id="barcode"></svg>
        </div>
      </div>
    </div>
  );
};
