import { Menu } from "@bbo/api/generator";
import React, { memo } from "react";
import SubMenuList from "./SubMenuList";

type MenuItemProps = {
  data: Menu;
  index: number;
  expand: boolean;
  onToggle: (index) => void;
  onMenuClick: (params) => void;
};

const MenuItem = ({ expand, data, index, onToggle, ...rest }: MenuItemProps) => {
  const ArrowIcon = () => {
    if (data.subMenu && data.subMenu.length > 0) {
      const arrow = expand ? "fa-chevron-down" : "fa-chevron-right";
      return (
        <span className="control float-right">
          <i className={`fas ${arrow}`}></i>{" "}
        </span>
      );
    }
    return null;
  };

  return (
    <li className={`accordion_item${expand ? " active" : ""}`}>
      <button
        className={`button hover${expand ? " active" : ""}`}
        onClick={() => onToggle(`g${index}`)}>
        <i className={`${data.icon} mr-3`}></i>
        {data.title || ""}
        <ArrowIcon />{" "}
      </button>
      <SubMenuList
        expand={expand}
        items={data.subMenu}
        groupIndex={index}
        group={data.title}
        onMenuClick={rest.onMenuClick}
      />
    </li>
  );
};

export default memo(MenuItem);
