import React, { useState } from "react";
import printIconSmallDisable from "../../assets/images/printIconSmallDisable.svg";
/**
 * Uper left of the table component
 * @param props
 * @returns
 */
// interface iProps {}

export interface PrintLogsProps {
  onSuccess?: () => void;
  noRecords?: boolean;
  invokingPage?: string;
}
const PrintLogs = (props) => {
  const [printBtn, setPrintBtn] = useState("Print");
  const [messageBody, setMessageBody] = useState("Do you want to print the transaction log?");
  const [showPopup, setShowPopup] = useState<boolean>(false);
  const [popupType, setPopupType] = useState("PouchPreparationSuccessfull");
  const handlePopup = () => {
    const popmsg = `Do you want to print the ${
      props?.invokingPage === "Sales" ? "Sales Summary" : "transaction log"
    }?`;
    setShowPopup(!showPopup);
    setPrintBtn("Print");
    setPopupType("PouchPreparationSuccessfull");
    setMessageBody(popmsg);
  };
  const onSuccess = (printType) => {
    if (printType !== "Reprint") {
      setPrintBtn("Confirm Successful Print");
      setMessageBody("Print Successful.");

      setPopupType("confirmSuccessfulPrint");
      if (printBtn === "Confirm Successful Print") {
        handlePopup();
      } else {
        if (props.onSuccess) {
          props.onSuccess();
        }
      }
    } else {
      if (props.onSuccess) {
        props.onSuccess();
      }
    }
  };
  const tickIcon = (
    <svg width="60" height="46" viewBox="0 0 60 46" fill="none">
      <path
        d="M19.3167 35.9167L5.41668 22.0167L0.68335 26.7167L19.3167 45.35L59.3167 5.35002L54.6167 0.650024L19.3167 35.9167Z"
        fill="#008000"
      />
    </svg>
  );
  const printIcon = (
    <svg width="68" height="60" viewBox="0 0 68 60" fill="none">
      <path
        d="M57.3333 16.6667H54V0H14V16.6667H10.6667C5.13334 16.6667 0.666672 21.1333 0.666672 26.6667V46.6667H14V60H54V46.6667H67.3333V26.6667C67.3333 21.1333 62.8667 16.6667 57.3333 16.6667ZM20.6667 6.66667H47.3333V16.6667H20.6667V6.66667ZM47.3333 53.3333H20.6667V40H47.3333V53.3333ZM54 40V33.3333H14V40H7.33334V26.6667C7.33334 24.8333 8.83334 23.3333 10.6667 23.3333H57.3333C59.1667 23.3333 60.6667 24.8333 60.6667 26.6667V40H54Z"
        fill="#2B60DE"
      />
      <path
        d="M54 31.6667C55.841 31.6667 57.3333 30.1743 57.3333 28.3333C57.3333 26.4924 55.841 25 54 25C52.1591 25 50.6667 26.4924 50.6667 28.3333C50.6667 30.1743 52.1591 31.6667 54 31.6667Z"
        fill="#2B60DE"
      />
    </svg>
  );
  const printIconSmall = (
    <svg width="33" height="30" viewBox="0 0 68 60" fill="none">
      <path
        d="M57.3333 16.6667H54V0H14V16.6667H10.6667C5.13334 16.6667 0.666672 21.1333 0.666672 26.6667V46.6667H14V60H54V46.6667H67.3333V26.6667C67.3333 21.1333 62.8667 16.6667 57.3333 16.6667ZM20.6667 6.66667H47.3333V16.6667H20.6667V6.66667ZM47.3333 53.3333H20.6667V40H47.3333V53.3333ZM54 40V33.3333H14V40H7.33334V26.6667C7.33334 24.8333 8.83334 23.3333 10.6667 23.3333H57.3333C59.1667 23.3333 60.6667 24.8333 60.6667 26.6667V40H54Z"
        fill="#2B60DE"
      />
      <path
        d="M54 31.6667C55.841 31.6667 57.3333 30.1743 57.3333 28.3333C57.3333 26.4924 55.841 25 54 25C52.1591 25 50.6667 26.4924 50.6667 28.3333C50.6667 30.1743 52.1591 31.6667 54 31.6667Z"
        fill="#2B60DE"
      />
    </svg>
  );
  const popupBox = (
    <>
      <div className="pouch-preapre-wrapper justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className={`relative my-6 max-w-xl pouch-prepare-popup ${
            popupType === "PouchPreparationSuccessfull"
              ? "pouchPreparationPopupDimension"
              : popupType === "confirmSuccessfulPrint"
              ? "confirmSuccessPrintPopupDimension"
              : popupType === "reprintConfirmationPopup"
              ? "reprintConfirmationPopupDimension"
              : ""
          }`}>
          <div className="messageTitle">{printBtn !== "Print" ? tickIcon : printIcon}</div>

          <div className="messageBody">{messageBody}</div>

          <div className="flex items-center gap-5   justify-center">
            <button
              className="cancelBtn"
              type="button"
              value="No"
              data-cy="popupNo"
              onClick={handlePopup}>
              Cancel
            </button>

            {printBtn !== "Print" && (
              <button
                className="reprint-btn"
                type="button"
                data-cy="popupNo"
                onClick={() => onSuccess("Reprint")}>
                Reprint
              </button>
            )}

            <button
              className={`success-btn ${
                popupType === "PouchPreparationSuccessfull"
                  ? "print-receipt-btn"
                  : popupType === "confirmSuccessfulPrint"
                  ? "successful-print-btn"
                  : popupType === "reprintConfirmationPopup"
                  ? "reprint-success-btn"
                  : ""
              } `}
              type="button"
              value="Yes"
              data-cy="popupYes"
              onClick={onSuccess}>
              {printBtn}
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );

  return (
    <>
      <div className={"w-full text-right divDummy"}>
        <button
          type="button"
          className={"printBtn"}
          onClick={handlePopup}
          disabled={props.noRecords}>
          <span className="printicon">
            {!props.noRecords ? (
              printIconSmall
            ) : (
              <img src={printIconSmallDisable} alt="Post Office" />
            )}
          </span>
          <span className={!props.noRecords ? "printBtn" : "print"}>Print</span>
        </button>
      </div>
      {showPopup && popupBox}
    </>
  );
};

export default PrintLogs;
