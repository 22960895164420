import {
  GetBpTpHistoryQueryError,
  GetBpTpHistoryQueryResult,
  useGetBpTpHistory,
} from "@bbo/api/generator";
import { DynamoTable } from "@bbo/components/DynamoTable/DynamoTable";
import Loader from "@bbo/components/Loader";
import { Popup } from "@bbo/components/Popup";
import { TP_BP_HISTORY_COLUMN, TP_BP_HISTORY_Filters } from "@bbo/components/table/tableConstants";
import { BP_TP_Declaration, BP_TP_PERIOD_NAMES, BP_TP_PERIOD_TYPES } from "@bbo/constants";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { BpTpHistoryDeclaration } from "@bbo/interfaces/HistoryDetail";
import { useDynamoPagination } from "@bbo/lib";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { PrintCashReceipt } from "@bbo/types";
import { amountPound } from "@bbo/utils/currencyFormatter";
import { currentDateDDMMYYYY, currentOnlyTime, getDateTime } from "@bbo/utils/dateTimeFormatter";
import { removeEmptyLinesFromAddress } from "@bbo/utils/receiptHelper";
import { TpBpHistoryTemplate } from "@bbo/utils/ReceiptPrinterTemplates/TpBpHistory";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import TpBpDetailView from "./TpBpDetailView";
const { BALANCE_PERIOD, TRADING_PERIOD } = BP_TP_PERIOD_NAMES;
const { TP, BP } = BP_TP_PERIOD_TYPES;
const { TPDeclaration, BPDeclaration } = BP_TP_Declaration;

const TpBpHistory = () => {
  const [showPopup, setShowPopup] = useState(false);
  const [popupData, setPopupData] = useState({
    titleHeader: "",
    title: "",
    actions: [],
    rowData: {},
  });
  const [, setLoader] = useState<boolean>(true);
  const [TpBpRowSelected, setTpBpRowSelected] = useState<BpTpHistoryDeclaration>(null);
  const [actionName, setActionName] = useState<string>("");
  const [printData, setPrintData] = useState<PrintCashReceipt>(null);
  const receiptPrinterService = useReceiptPrinter();
  const { setTitle } = useTitle();
  const navigate = useNavigate();
  const branchDetails = useAppSelector((root) => root.branchDetails);

  const { data, error, isLoading, hasNext, hasPrev, getNext, getPrev, filters, setFilters } =
    useDynamoPagination<
      typeof useGetBpTpHistory,
      GetBpTpHistoryQueryResult,
      GetBpTpHistoryQueryError
    >(
      useGetBpTpHistory,
      {
        filters: TP_BP_HISTORY_Filters,
      },
      {},
      {
        // here we could specify react query options, if needed
        query: {
          refetchOnWindowFocus: true,
        },
      },
    );
  const BpTpHistoryLists = useMemo(() => {
    if (isLoading) return [];
    if (!data?.data?.length) return [];
    const modifiedBpTpHistoryData = data?.data?.map((list) => {
      const isBpTp = list?.itemDescription === BPDeclaration ? BP : TP;
      const name = isBpTp === BP ? `${BP}${list[BP]}-${TP}${list[TP]}` : `${TP}${list[TP]}`;
      return { ...list, name };
    });
    return modifiedBpTpHistoryData;
  }, [data?.data, isLoading]);

  useEffect(() => {
    setTitle("Balance / Trading Period History");
  }, [setTitle]);

  const handleRowClick = (row: BpTpHistoryDeclaration) => {
    setShowPopup(true);
    setTpBpRowSelected(row);
    if (row.itemDescription === TPDeclaration) {
      setActionName(TRADING_PERIOD);
    } else setActionName(BALANCE_PERIOD);

    setPopupData({
      ...popupData,
      titleHeader: "Detail View : ",
      title: row?.name,
      rowData: row,
    });
  };

  const actions = useMemo(() => {
    if (!TpBpRowSelected) return;
    const { declarationID, cashCommodity, updatedBy, timestamp } = TpBpRowSelected;
    return [
      {
        name: `Go to ${actionName}`,
        handleAction: () => {
          if (actionName === TRADING_PERIOD) {
            navigate(`../tradingPeriod/${encodeURIComponent(declarationID)}`);
          } else {
            navigate(`../balancePeriod/${encodeURIComponent(declarationID)}`);
          }
        },
        style: "popupBtn border p-3 flex-auto mr-1",
      },
      {
        name: "Print",
        handleAction: () => {
          const branchAddressClean = removeEmptyLinesFromAddress(branchDetails.branch_address);
          setShowPopup(false);
          receiptPrinterService.printReceipt({
            template: TpBpHistoryTemplate,
            context: {
              title: "BALANCE / TRADING PERIOD HISTORY",
              fadcode: branchDetails.fadcode,
              branchName: branchDetails.branch_name,
              addressLine1: branchAddressClean[0],
              addressLine2: branchAddressClean[1],
              addressLine3: branchAddressClean[2],
              postcode: branchAddressClean[branchAddressClean.length - 1],
              currentDate: currentDateDDMMYYYY(),
              currentTime: currentOnlyTime(),
              createdByUserName: updatedBy.userName,
              createdBySmartID: updatedBy.smartID,
              dateTimeSystem: getDateTime(timestamp),
              totalVariance: amountPound(cashCommodity.varianceValue),
            },
          });
        },
        style: "popupBtn border p-3 bg-red-600 text-white flex-auto ml-1",
      },
    ];
  }, [
    TpBpRowSelected,
    actionName,
    branchDetails.branch_address,
    branchDetails.branch_name,
    branchDetails.fadcode,
    navigate,
    receiptPrinterService,
  ]);
  return (
    <>
      <>
        {isLoading && <Loader />}
        {showPopup && (
          <Popup
            titleHeader={popupData?.titleHeader}
            title={popupData?.title}
            body={<TpBpDetailView data={popupData?.rowData} />}
            actions={actions}
            onClose={() => {
              setShowPopup(false);
            }}
          />
        )}
        {/* {printData && (
          <ReceiptPrint
            title={"BALANCE / TRADING PERIOD HISTORY"}
            data={printData}
            onPrintClose={() => {
              navigate("../BalanceTradingPeriodHistory", {
                replace: true,
                state: { showLimit: false },
              });
              setPrintData(null);
            }}
          />
        )} */}
        <div className="w-full transferOut-height">
          <div className="container-margin lg:w-full h-full relative dynamictable">
            <DynamoTable
              hasPrev={hasPrev}
              hasNext={hasNext}
              onPrev={getPrev}
              onNext={getNext}
              filters={filters}
              onRowClicked={(row) => handleRowClick(row)}
              setFilters={setFilters}
              data={BpTpHistoryLists}
              columns={TP_BP_HISTORY_COLUMN}
            />
          </div>
        </div>
      </>
    </>
  );
};

export default TpBpHistory;
