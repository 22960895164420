import { formatValue } from "react-currency-input-field";
import { Format } from "./dynamoTableTypes";
import { getOnlyDateTime } from "@bbo/utils/dateTimeFormatter";

// export const getFilterInitialValue = (filter: Filter) => {
//   switch (filter.variant) {
//     case "freeText":
//       return filter.value ?? null;
//     case "singleDropdown":
//       return filter.value ?? null;
//     case "fromTo":
//       return {
//         from: filter.value?.from ?? null,
//         to: filter.value?.to ?? null,
//       };
//     default:
//       return null;
//   }
// };

// export const getInputPattern = (unit: Filter["unit"]) => {
//   switch (unit) {
//     case "number":
//     case "currency":
//       return "[0-9]";
//     case "string":
//     default:
//       return undefined;
//   }
// };

export const formatCellData = <T extends string | number | Date>(cellData: T, format: Format) => {
  switch (format) {
    case "date":
      return getOnlyDateTime(Number(cellData));
    case "currencyPenceUnsigned":
      return formatValue({
        value: `${isNaN(Number(cellData)) ? 0 : Number(cellData) / 100}`,
        prefix: "£",
        decimalScale: 2,
      })
        .replace("-", "")
        .replace("+", "");
    case "currencyPence":
    case "currencyVariancePence":
      return formatValue({
        value: `${isNaN(Number(cellData)) ? 0 : Number(cellData) / 100}`,
        prefix: `${Number(cellData) > 0 ? "+" : ""}£`,
        decimalScale: 2,
      });
    default:
      return cellData;
  }
};

export const DYNAMO_DEBOUNCE_DURATION = 300;
export const DYNAMO_ITEMS_PER_PAGE = 8;

// export const styleCellData = (cellData: string, format: Format) => {};
