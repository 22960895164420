import { useContext } from "react";
import { Column, Filter } from "../dynamoTableTypes";
import { DynamoTableFilter } from "./DynamoTableFilter";
import { DynamoTableContext } from "../DynamoTableContext";

interface DynamoTableFiltersProps<TFilters> {
  filters: TFilters;
  columns: Column[];
  onFilterChange: (filterKey: string, filter: Filter) => void;
}

export const DynamoTableFilters = <TFilters,>({
  filters,
  columns,
  onFilterChange,
}: DynamoTableFiltersProps<TFilters>) => {
  const { allItemsSelected, showCheckboxes, onSelectAll } = useContext(DynamoTableContext);
  return (
    <tr className="filterNode">
      {showCheckboxes ? (
        <td className="px-[20px] py-[14px]">
          <div className="cell-checkbox-header">
            <input
              id={`col-checkbox`}
              className="cell-checkbox"
              name="select-all"
              type="checkbox"
              checked={allItemsSelected || false}
              onChange={onSelectAll}
            />
            <label title="Show Page Source" htmlFor={`col-0`}>
              Select All
            </label>
          </div>
        </td>
      ) : null}
      {columns?.map((col) => {
        return (
          <DynamoTableFilter
            key={col.fieldName}
            fieldName={col.fieldName}
            filter={filters ? filters[col.fieldName] : null}
            onFilterChange={onFilterChange}
          />
        );
      })}
    </tr>
  );
};
