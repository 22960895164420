function dd_mm_yyyy_number(date) {
  if (date === undefined || date === null || date.toString().length < 10) {
    return null;
  }
  if (date.toString().length === 10) {
    return date * 1000;
  }
  const day = date.substring(0, 2);
  const month = date.substring(3, 5);
  const year = date.substring(6, 10);
  const time_value = date.substring(11);
  return new Date(year + "-" + month + "-" + day + " " + time_value).getTime();
}

function hh_mm_number(date) {
  if (date === undefined || date === null || date.length < 15) {
    return null;
  }

  let hour = 0;
  let mimute = 0;
  let second = 0;
  const time_value = date.substring(11);
  const items = time_value.split(" ");
  if (items && items.length > 0) {
    items.map((item, index) => {
      if (index === 0) {
        const times = item.split(":");
        if (times && times.length > 0) {
          times.map((value, position) => {
            if (position === 0) {
              hour = hour + value;
            } else if (position === 1) {
              mimute = mimute + value;
            } else if (position === 2) {
              second = second + value;
            }
          });
        }
      } else if (index === 1) {
        hour = hour + (item && item === "PM" ? 12 : 0);
      }
    });
  }
  return hour * 10000 + mimute * 100 + second;
}

function dd_mm_yyyy_hh_mm_comparator(date1, date2) {
  const date1Number = dd_mm_yyyy_number(date1);
  const date2Number = dd_mm_yyyy_number(date2);
  if (date1Number === null && date2Number === null) {
    return 0;
  }
  if (date1Number === null) {
    return -1;
  }
  if (date2Number === null) {
    return 1;
  }
  if (date1Number !== date2Number) {
    return date1Number - date2Number >= 0;
  } else {
    const time1Number = hh_mm_number(date1);
    const time2Number = hh_mm_number(date2);
    if (time1Number === null && time2Number === null) {
      return 0;
    }
    if (time1Number === null) {
      return -1;
    }
    if (time2Number === null) {
      return 1;
    }
    return time1Number - time2Number >= 0;
  }
}

//Table text sort in the column and filter
function textComparator(text1, text2) {
  if (text1 == null || text1 === undefined || text1 === "undefined") {
    if (text2 == null || text2 === undefined || text1 === "undefined") {
      return 0;
    }
    return -1;
  }

  if (text2 == null || text2 === undefined || text1 === "undefined") {
    return 1;
  }

  const value1 = text1.toString().toLowerCase().trim();
  const value2 = text2.toString().toLowerCase().trim();
  if (value1 < value2) {
    return -1;
  }
  if (value1 > value2) {
    return 1;
  }
  return 0;
}

export { dd_mm_yyyy_hh_mm_comparator, textComparator };
