import { useGetBranchData } from "@bbo/api/generator/endpoints/get-branch-details";
import { useGetItemCounts } from "@bbo/api/generatorNoFadcode/endpoints/transactions-viewer";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { useTitle } from "../AuthRoutes";
import { amountPound } from "@bbo/utils/currencyFormatter";
import { useGlobalState, useAppSelector } from "@bbo/lib";
import { useEffect, useState } from "react";
interface paginationData {
  currentPage: number;
  lastPage: number;
}
interface itemDetails {
  itemID?: string;
  itemDescription?: string;
  quantity?: number;
  value?: number | string;
  valueInPence?: number | string;
}
interface salesData {
  totalValue: number | string;
  totalQuantity: number | string;
  ItemCounts: itemDetails[];
}
export const useSalesSummary = () => {
  const [isDateRangeSelected, setIsDateRangeSelected] = useState(false);
  const [paginationData, setPaginationData] = useState<paginationData>(null);
  const [formattedData, setFormatedData] = useState<salesData>();
  const [totalQuantity, setTotalQuantity] = useState(0);
  const [totalValue, setTotalValue] = useState("0.00");
  const [transactionLogParamDate] = useGlobalState("dateCashbalance");
  const [param, setParam] = useState({ to: null, from: null });
  const userName = useAppSelector((root) => root.userName);
  const receiptPrinterService = useReceiptPrinter();
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);
  const { setTitle } = useTitle();
  const { data: branchData } = useGetBranchData(fadCode);
  const {
    data: transaction,
    isLoading,
    isRefetching,
  } = useGetItemCounts(fadCode, param.from, param.to, {
    transactionMode: "1",
    includeTender: false,
  });
  useEffect(() => {
    setTitle("Sales Summary");
  }, [setTitle]);

  const setTotals = (totQuantity: number, totValue: number) => {
    setTotalQuantity(totQuantity);
    setTotalValue(totValue ? amountPound(totValue) : amountPound(0));
  };
  useEffect(() => {
    if (transaction?.ItemCounts && !isRefetching) {
      //api data to check with this
      let totalQuantity = 0;
      let totalValue = 0;
      let data: itemDetails[];
      data = transaction?.ItemCounts.filter((item) => item.itemID !== "1");
      data = data.map((item) => {
        totalQuantity = totalQuantity + item.quantity;
        totalValue = totalValue + (item?.valueInPence ? Number(item?.valueInPence) : 0);
        if (item?.valueInPence) {
          return {
            ...item,
            valueInPence: amountPound(Number(item?.valueInPence)),
            value: item?.valueInPence,
          };
        } else {
          return { ...item, valueInPence: amountPound(0), value: 0 };
        }
      });
      data = data?.sort((a, b) => {
        if (a.itemDescription && b.itemDescription) {
          if (a.itemDescription < b.itemDescription) {
            return -1;
          }
          if (a.itemDescription > b.itemDescription) {
            return 1;
          }
        }
        return 0;
      });
      setFormatedData({
        ItemCounts: [...data],
        totalValue: amountPound(totalValue),
        totalQuantity: totalQuantity ? totalQuantity : 0,
      });
    } else if (!isRefetching) {
      setFormatedData(null);
    }
  }, [isRefetching, transaction]);
  useEffect(() => {
    const confirmBtn = async () => {
      // setting time 2AM to exclude daylight saving scenario and also transaction-viewer API only considering dates (not time only if isn't changing date due to daylight saving)
      const from = new Date(transactionLogParamDate.from).setHours(2, 0, 0, 0) / 1000;
      const to = new Date(transactionLogParamDate.todate).setHours(2, 0, 0, 0) / 1000;
      setParam({
        from: from,
        to: to,
      });

      setParam({
        from:
          transactionLogParamDate.from && transactionLogParamDate.todate
            ? from
            : new Date().setHours(2, 0, 0, 0) / 1000,
        to:
          transactionLogParamDate.from && transactionLogParamDate.todate
            ? to
            : new Date().setHours(2, 0, 0, 0) / 1000,
      });
      await transaction;
    };
    confirmBtn();
  }, [transaction, transactionLogParamDate]);
  return {
    isDateRangeSelected,
    paginationData,
    setPaginationData,
    formattedData,
    transactionLogParamDate,
    userName,
    receiptPrinterService,
    param,
    fadCode,
    data: branchData,
    isLoading,
    totalQuantity,
    setTotals,
    totalValue,
  };
};
