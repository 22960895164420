import { Error, Response, TransferCore } from "@bbo/api/generatorNoFadcode";
import { ErrorType } from "@bbo/api/generatorNoFadcode/mutator/useCustomInstanceNoFadcode";
import { UseMutateFunction } from "@tanstack/react-query";
import { convertToTransferPayloadParams } from "../../utils/pouch/pouchHelpers";
import { useAppSelector } from "@bbo/lib";
import { PouchStatus, PouchSubType, PouchType } from "@bbo/api/generator";
import { Dispatch, SetStateAction, useCallback } from "react";
import { SCREENS } from "@bbo/constants";
import { useNavigate } from "react-router-dom";
import { PouchDetailsItemsInfo } from "@bbo/utils";
import {
  PouchPreparationModalConfig,
  pouchPreparationModalConfigs,
} from "./pouchPreparationModalConfig";

export const usePouchPrepCancel = (
  pouchID: string,
  pouchSubType: PouchSubType,
  denominationLadder: PouchDetailsItemsInfo,
  setModalConfig: Dispatch<SetStateAction<PouchPreparationModalConfig>>,
  setTransactionSubmissionType: Dispatch<SetStateAction<PouchStatus>>,
  postPreparedPouch: UseMutateFunction<
    Response,
    ErrorType<void | Error>,
    {
      data: TransferCore;
    },
    unknown
  >,
) => {
  const navigate = useNavigate();
  const { branch_name: branchName, branch_address: branchAddress } = useAppSelector(
    (rootState) => rootState.branchDetails,
  );
  const { smartid, fadCode, username } = useAppSelector((rootState) => rootState.auth.authUserData);

  const handleCancelPouch = () => {
    postPreparedPouch({
      data: convertToTransferPayloadParams({
        assignedBranchName: branchName,
        fadCode,
        pouchBarcode: pouchID,
        pouchDetailsItemsInfo: denominationLadder,
        pouchSubType,
        pouchType: PouchType.cash,
        status: "cancelled",
        smartID: smartid,
        userName: username,
      }),
    });
    setTransactionSubmissionType(PouchStatus.cancelled);
  };

  const openCancelPouchModal = () => {
    setModalConfig({
      ...pouchPreparationModalConfigs.cancelPouch,
      onSecondaryButtonClick: () => {
        setModalConfig(null);
      },
      onPrimaryButtonClick: handleCancelPouch,
    });
  };

  const openHandleDespatchModal = () => {
    setModalConfig({
      ...pouchPreparationModalConfigs.pouchDespatch,
      onPrimaryButtonClick: () => {
        setModalConfig(null);
      },
    });
  };

  const openPouchCancelSuccessModal = useCallback(() => {
    setModalConfig({
      ...pouchPreparationModalConfigs.cancelPouchSuccess,
      onPrimaryButtonClick: () => {
        navigate(SCREENS.POUCH_PREPARATION);
      },
    });
  }, [navigate, setModalConfig]);

  const openPouchCancelFailedModal = useCallback(() => {
    setModalConfig({
      ...pouchPreparationModalConfigs.cancelPouchFailed,
      onPrimaryButtonClick: () => {
        setModalConfig(null);
      },
    });
  }, [setModalConfig]);

  return {
    openCancelPouchModal,
    openHandleDespatchModal,
    openPouchCancelSuccessModal,
    openPouchCancelFailedModal,
  };
};
