/**
 * Table UI currency range filter
 */
import { amountFormatter, currencyAmount, inputInPence } from "@bbo/utils/currencyFormatter";
import { memo, ReactNode, useEffect, useState } from "react";
import PopupConfirm from "../Alert";

//Props declaration
interface iProps {
  id?: string;
  name: string;
  activeFilters: any[];
  placeholderStart?: string;
  placeholderEnd?: string;
  children?: ReactNode;
  onChange?: (params: any) => any;
}

//Initial input values
const initialAmount = { fromAmount: "", toAmount: "" };

const AmountRangeFilter = (props: iProps) => {
  const [amount, setAmount] = useState({ ...initialAmount });
  const [isInvalid, setInvalid] = useState<boolean>(false);

  //Handle reset filter
  useEffect(() => {
    if (props.activeFilters.length === 0) {
      setAmount({ ...initialAmount }); // reset filter
    }
  }, [props.activeFilters]);

  //Handle input callback filter
  useEffect(() => {
    if (
      amount.fromAmount !== "" &&
      currencyAmount(amount.fromAmount) > currencyAmount(amount.toAmount)
    ) {
      if (amount.toAmount) {
        setInvalid(true);
      }
      if (props.onChange) {
        props.onChange({
          field: props.name,
          filter: "amountRange",
          from: "",
          to: "",
        });
      }
    } else if (
      amount.toAmount !== "" &&
      currencyAmount(amount.fromAmount) <= currencyAmount(amount.toAmount)
    ) {
      if (isInvalid) {
        setInvalid(false);
      }
      if (props.onChange) {
        props.onChange({
          field: props.name,
          filter: "amountRange",
          from: inputInPence(amount.fromAmount),
          to: inputInPence(amount.toAmount),
        });
      }
    }
  }, [amount]);

  /**
   * Get event on input change
   * @param e EventListener on change input
   */
  const handleAmountChange = (e) => {
    const { name, value } = e.target;
    const rx_live = /^[0-9,-£\b]+(\.\d{0,2})?$/;
    if (rx_live.test(value)) {
      setAmount((data) => {
        return {
          ...data,
          [name]: amountFormatter(value),
        };
      });
    } else {
      if (value.length === 0) {
        setAmount((data) => {
          return {
            ...data,
            [name]: "",
          };
        });
      }
    }
  };

  /**
   * Get event on input change
   * @param e Key pressed EventListener
   */
  const handleKeyDown = (e) => {
    if (e.keyCode === 189) {
      //Minus key pressed
      const { name } = e.target;
      if (!amount[name].includes("-")) {
        setAmount((data) => {
          return {
            ...data,
            [name]: `-${amountFormatter(data[name])}`,
          };
        });
      }
    }
  };

  //Render UI
  return (
    <div className="container-amount-range">
      <input
        id={"input-from-amount"}
        name={"fromAmount"}
        pattern="[0-9]"
        className={"input-amount-range"}
        placeholder={props.placeholderStart || ""}
        value={amount.fromAmount}
        onChange={handleAmountChange}
        onKeyDown={handleKeyDown}
      />
      <input
        id={"input-to-amount"}
        name={"toAmount"}
        pattern="[0-9]"
        disabled={amount.fromAmount === ""}
        className={"ml-5 input-amount-range"}
        placeholder={props.placeholderEnd || ""}
        value={amount.toAmount}
        onChange={handleAmountChange}
        onKeyDown={handleKeyDown}
      />
      {isInvalid && (
        <PopupConfirm
          header="Please change input value"
          content={"'To' value should be greater than 'From' value."}
          labelSuccess="Close"
          tickicon={false}
          logoIcon="paidCrossColor"
          onSuccess={() => setInvalid(false)}
        />
      )}
    </div>
  );
};

export default memo(AmountRangeFilter);
