import { useGetLogsDetails, useGetSystemCashAnAccountingLocation } from "@bbo/api/generator";
import { SCREENS } from "@bbo/constants";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { getUnixTimeStamp } from "@bbo/utils/dateTimeFormatter";
import { useContext, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { getLegacyAccountingLocationType, Status } from "./branchOverviewHelpers";
import { CashManagementBranchOverviewContext } from "./BranchOverviewProvider";
import CashDrawerList from "./CashDrawerList";
import CounterList from "./CounterList";
import LimitWarningPopUp from "./LimitWarningPopUp";
import { SafeList } from "./SafeList";
import useBranchApi from "@bbo/lib/hooks/useBranchApi";
import { OverviewLocationState } from "./branchOverviewTypes";
import { TransferOutLocationState } from "../TransferOut/transferOutTypes";

export const Overview = () => {
  const navigation = useNavigate();
  const { username, fadCode, smartid } = useAppSelector((root) => root.auth.authUserData);
  const { accountingLocationGroups, accountingLocation } = useContext(
    CashManagementBranchOverviewContext,
  );
  const [lastBalanceOnEscape, setLastBalanceOnEscape] = useState(0);
  const [status, setStatus] = useState<Status | null>(null);
  const { maximumLimit: maxLimitCounter } = useAppSelector((root) => root.MaxBranchLimit);

  const [hasExceededLimit, setHasExceededLimit] = useState(false);

  const state = useLocation()?.state as OverviewLocationState;

  const { data: accountingLocationToCheckLimit } = useGetSystemCashAnAccountingLocation(
    state?.cashDrawerLimit?.accountingLocationID,
    {
      query: {
        enabled: !!state?.cashDrawerLimit?.accountingLocationID,
      },
    },
  );

  const { data: getLogsData } = useGetLogsDetails(
    fadCode,
    getLegacyAccountingLocationType(accountingLocation?.accountingLocationType),
    accountingLocation?.accountingLocationID,
    "continue_cash_transfer",
    undefined,
    {
      query: {
        enabled: !!accountingLocation,
      },
    },
  );

  const lastBalanceInLogs = getLogsData?.[0]?.entity.itemDetails?.balance || 0;

  const requestPayload = {
    status: status,
    fadcode: fadCode,
    dateTime: getUnixTimeStamp(new Date()),
    username: username,
    smartID: smartid,
    entity: {
      entityName: accountingLocation?.accountingLocationName,
      // sequenceNo: accountingLocation?.sequenceID,
      entityID: accountingLocation?.accountingLocationID,
      entity: accountingLocation?.counterName,
      fadcode: fadCode,
      itemDetails: {
        balance: status === "escape_cash_transfer" ? lastBalanceOnEscape : lastBalanceInLogs,
        lastBalancedDateTime: accountingLocation?.lastDeclarationTimestamp,
        toDateVariance: accountingLocation?.toDateVariance,
        currentVariance: accountingLocation?.currentVariance,
      },
    },
  };

  // Business Rules API
  const [{ data: postLogsData, status: postLogsStatus }, postLogs] = useBranchApi(
    "",
    { fadcode: fadCode },
    requestPayload,
    "POST",
    requestPayload,
  );

  // Function to determine whether the "Cash Drawer Limit" popup should be displayed. Conditions are:
  // - has the last transfer taken the accounting location above the cash drawer limit (the max total value that the cash drawer can hold)
  // - or was it already over the limit and has the last transfer been for a value >= £500
  useEffect(() => {
    if (!accountingLocationToCheckLimit || maxLimitCounter === null) return;

    // systemValue is still under the maximum limit, so we're good
    const isWithinLimit = accountingLocationToCheckLimit.systemValue < maxLimitCounter;
    if (isWithinLimit) return;

    const lastTransactionExceededLimit =
      accountingLocationToCheckLimit.systemValue - state.cashDrawerLimit.lastTransferAmountPence <
      maxLimitCounter;

    const lastTransferExceededIndividualTransferLimit =
      state.cashDrawerLimit.lastTransferAmountPence >= 50000;

    // Neither of the conditions were met, so we're good
    if (!lastTransactionExceededLimit && !lastTransferExceededIndividualTransferLimit) return;

    // The limit that has been used for the limit check is completely wrong, the £26k value applies only to the pouch preparation.
    // The correct limit depends on the counter type, which we will not have access to until R3 at the earliest.
    // Therefore the limit check has been disabled for now
    //
    // setHasExceededLimit(true);
  }, [
    accountingLocationToCheckLimit,
    maxLimitCounter,
    state?.cashDrawerLimit?.lastTransferAmountPence,
  ]);

  const handleContinue = async (status: Status) => {
    if (status === "continue") {
      setStatus("escape_cash_transfer");
      setLastBalanceOnEscape(accountingLocation?.totalValue);
    } else {
      setStatus("continue_cash_transfer");
    }
    postLogs("postLogsDetails");
    await postLogsData;

    // Reset the navigation state for the current screen so the popup isn't shown again on reload
    window.history.replaceState({}, document.title);
  };

  useEffect(() => {
    if (["error", "fetched"].includes(postLogsStatus)) {
      if (status === "escape_cash_transfer") {
        setHasExceededLimit(false);
      } else {
        setHasExceededLimit(false);
        const state: TransferOutLocationState = {
          transferTarget: {
            accountingLocationID: "S01",
          },
        };
        navigation(
          `${SCREENS.TRANSFER_OUT}/${accountingLocationToCheckLimit?.accountingLocationID}`,
          {
            state,
          },
        );
      }
    }
  }, [accountingLocationToCheckLimit?.accountingLocationID, navigation, postLogsStatus, status]);

  return (
    <>
      {hasExceededLimit ? (
        <LimitWarningPopUp
          onSuccess={() => {
            handleContinue("transfer");
          }}
          onCancel={() => handleContinue("continue")}
        />
      ) : null}
      <CounterList items={accountingLocationGroups?.counterTerminal} />
      <hr className="border-black border-bottom-none mb-5 w-full" />
      <div className="w-full accounting-card block float-left h-56 overflow-x-auto whitespace-nowrap">
        <SafeList items={accountingLocationGroups?.safe} />

        {/* {shouldShowAtmItem ? <AtmItem header={ATM} data={cashList?.atm} /> : null} */}
      </div>
      <hr className="border-black border-bottom-none mb-5 w-full" />
      <CashDrawerList items={accountingLocationGroups?.cashDrawer} />
    </>
  );
};
