import { amountInPound } from "@bbo/utils/currencyFormatter";
import { UNUSABLE_TRANSACTION_NOTE_COIN_ITEM_ID } from "@bbo/utils/transferPayload";
import { CashDenominationRow } from "./cashBalanceDetailPopup";
interface CashBalanceDenominationRowProps {
  item: CashDenominationRow;
  index: number;
}

export const CashBalanceDenominationRow = ({ item, index }: CashBalanceDenominationRowProps) => {
  if (!item || item.itemDenomination === undefined)
    return (
      <tr data-testid="data-not-found">
        <td>No data found</td>
      </tr>
    );

  const usableDenomination = !Object.values(UNUSABLE_TRANSACTION_NOTE_COIN_ITEM_ID).includes(
    item.itemId,
  );
  return (
    <tr key={index} data-testid={"row" + item.itemDenomination + index}>
      <td data-testid={"denomination" + item.itemDenomination + index}>{item.itemDenomination}</td>
      <td data-testid={"multiply" + item.itemDenomination + index}>
        {usableDenomination ? "X" : ""}
      </td>
      <td data-testid={"quantity" + item.itemDenomination + index}>
        {usableDenomination ? item.itemQuantity : ""}
      </td>
      <td>=</td>
      <td data-testid={"total" + item.itemDenomination + index}>{amountInPound(item.itemValue)}</td>
    </tr>
  );
};
