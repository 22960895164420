import { memo } from "react";
import "./index.css";

interface iProps {
  onChangeBranch: () => void;
  onChangeCounter: () => void;
  isBranch: boolean;
  isCounter: boolean;
}

const DespatchTypeSelection = (props: iProps) => {
  return (
    <div className="flex items-center">
      <div className="flex items-center">
        <input
          data-testid="branch"
          type="radio"
          className="inputRadioChecbox"
          checked={props.isBranch}
          onChange={() => props.onChangeBranch()}
        />
        <label className="ml-2">Branch</label>
      </div>
      <div className="flex items-center ml-10">
        <input
          data-testid="counter"
          type="radio"
          className="inputRadioChecbox"
          checked={props.isCounter}
          onChange={() => props.onChangeCounter()}
        />
        <label className="ml-2">Counter</label>
      </div>
    </div>
  );
};

export default memo(DespatchTypeSelection);
