// Custom styles

export const customStyles = {
  control: (base) => ({
    ...base,
    border: "1px solid #444",
    width: "100%",
    padding: "8px 0px",
    marginBottom: "25px",
    "&:hover": {
      border: "1px solid #444",
    },
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isFocused ? "#9999" : null,
    color: "#444",
    margin: state.data.label === "All" ? "-15px 0px" : "",
    position: state.data.label === "All" ? "relative" : "",
    top: state.data.label === "All" ? "5px" : "",
  }),
};
