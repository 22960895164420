export const accountingLocationTypeToText = (accountingLocationType: string) => {
  switch (accountingLocationType) {
    case "cashDrawer":
      return "Cash Drawer";
    case "safe":
      return "Safe";
    default:
      return "Unknown";
  }
};

export const constructAccountingLocationBarcode = (fadcode: string, accountingLocationId: string) =>
  `${fadcode}-${accountingLocationId}`;
