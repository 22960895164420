import { Dispatch, SetStateAction, createContext } from "react";
import { AccountingLocationGroups, useBranchOverview } from "./useBranchOverview";
import { Operation } from "./branchOverviewHelpers";
import { BranchCashOverview } from "@bbo/api/generator";
import { SCREENS } from "@bbo/constants";

export interface Action {
  id: string;
  name: string;
  url: string;
  operation: Operation;
}

export type ActionListKeys =
  | "transferOut"
  | "cashBalances"
  | "paidIn"
  | "paidOut"
  | "transferIn"
  | "pouchAcceptance"
  | "pouchDespatch"
  | "pouchPreparation";

export const actionListMap: Record<ActionListKeys, Action> = {
  transferOut: {
    id: "t_out_1",
    name: "Transfer Out",
    url: SCREENS.TRANSFER_OUT,
    operation: "transfer_out",
  },
  cashBalances: {
    id: "c_bal_1",
    name: "Cash Balances",
    url: SCREENS.CASH_BALANCE,
    operation: "cash_balance",
  },
  paidIn: {
    id: "p_in_1",
    name: "Paid In",
    url: SCREENS.PAID_IN,
    operation: "paid_in",
  },
  paidOut: {
    id: "p_out_1",
    name: "Paid Out",
    url: SCREENS.PAID_OUT,
    operation: "paid_out",
  },
  transferIn: {
    id: "t_in_1",
    name: "Transfer In",
    url: SCREENS.TRANSFER_IN,
    operation: "transfer_in",
  },
  pouchAcceptance: {
    id: "pouch_acc_1",
    name: "Pouch Acceptance",
    url: "spm://app/pouchacceptance/",
    operation: null,
  },
  pouchDespatch: {
    id: "pouch_despatch_1",
    name: "Pouch Despatch",
    url: "spm://app/pouchdispatch/",
    operation: null,
  },
  pouchPreparation: {
    id: "pouch_pre_1",
    name: "Pouch Preparation",
    url: SCREENS.POUCH_PREPARATION,
    operation: null,
  },
} as const;

const {
  transferIn,
  transferOut,
  cashBalances,
  paidIn,
  paidOut,
  pouchAcceptance,
  pouchDespatch,
  pouchPreparation,
} = actionListMap;

export const commonActionLists = [transferOut, cashBalances, paidIn, paidOut];
export const safeActionLists = [
  transferIn,
  transferOut,
  pouchAcceptance,
  pouchDespatch,
  paidIn,
  paidOut,
  cashBalances,
  pouchPreparation,
];

export const getActionList = (accountingLocation: BranchCashOverview) =>
  accountingLocation?.accountingLocationType === "safe" ? safeActionLists : commonActionLists;

export interface CashManagementBranchOverviewContextType {
  selectedTab?: string;
  handleSelectTab?: (params: { name: string }) => void;
  accountingLocation: BranchCashOverview | null;
  setAccountingLocation: Dispatch<SetStateAction<BranchCashOverview | null>>;
  isLoading: boolean;
  accountingLocationGroups: AccountingLocationGroups;
  branchOverviewData?: BranchCashOverview[] | null;
}

const emptyFn = () => {
  //
};

export const initialValue: CashManagementBranchOverviewContextType = {
  selectedTab: "",
  handleSelectTab: emptyFn,
  accountingLocation: null,
  setAccountingLocation: emptyFn,
  isLoading: true,
  accountingLocationGroups: null,
  branchOverviewData: null,
};

export const CashManagementBranchOverviewContext =
  createContext<CashManagementBranchOverviewContextType>(initialValue);

export const BranchOverviewProvider = ({ children }) => {
  return (
    <CashManagementBranchOverviewContext.Provider value={useBranchOverview()}>
      {children}
    </CashManagementBranchOverviewContext.Provider>
  );
};
