import { createContext, MutableRefObject } from "react";

export type MapRefProps = MutableRefObject<MapProps | Record<string, never>>;

export type MapProps = MutableRefObject<{
  [key: string]: {
    index: number;
    props: number | MutableRefObject<HTMLLIElement>;
  };
}>;

export interface DescendantContextProps {
  get: (id: string, props: unknown) => number;
  map: MapRefProps;
  reset: () => void;
}

export const DescendantContext = createContext<DescendantContextProps>(null);
