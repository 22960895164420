/* eslint-disable */
/*
  
  useAPI hook is a tuple which provides a predictable state container for all api calls that are modeled via OpenAPI specification.
  USAGE: 
  Using the Open API model you will create an object for the request params

    const requestParams: RegisterDeviceRequest = {
    id: 'test',
    name: '1234'
  }

  This can then be used in a variety of methods to handle api calls.

  API Call on component mount requires a the url to be set initially: 

  const [{ data, status, error, statusCode}, postDevice , refresh ] = useApi('devicePost', requestParams);

  API Call when called via function requires url to be empty and the second parameter in the tuple to be called:
  
  postDevice('devicePost');

  Refresh an API Call requires the third parameter in the tuple to be called;

  refresh()

*/

import { GetBranchDetailsApi } from "@bbo/openapi/api";
import { Configuration } from "@bbo/openapi/configuration";
import { Auth } from "aws-amplify";
import axios from "axios";
import { Dispatch, SetStateAction, useEffect, useReducer, useRef, useState } from "react";

// useAPI tuple return.
type ReturnType = [State, Dispatch<SetStateAction<string>>, () => void];

// Interface for Api state
interface State {
  status: "init" | "fetching" | "error" | "fetched";
  data?: any;
  error?: string;
  statusCode?: number;
}

interface Cache<T> {
  [url: string]: T;
}

// discriminated union type
type Action =
  | { type: "FETCH_INIT" }
  | { type: "FETCH_SUCCESS"; payload: unknown; apiStatus?: number }
  | { type: "FETCH_FAILURE"; payload: string; apiStatus?: number };

// Gets JWT token from amplify and assigns it to OpenAPI Configuration Object
async function getAccessToken() {
  const idToken = (await Auth.currentSession()).getIdToken();
  const config = new Configuration();
  config.accessToken =
    idToken.getJwtToken() ||
    localStorage.getItem(
      "CognitoIdentityServiceProvider.3bug83m4vi0j030qikm703k87n.rita.dhiman@coforgetech.com.accessToken",
    );
  return config;
}

const axiosInstance = axios.create();
// Helper to identify empty data objects when passed into Hook
function isEmpty(value: string | any[]) {
  return value == null || value.length === 0 || Object.keys(value).length === 0;
}

export function useBranchApi<T = unknown>(
  initialUrl?: string,
  options?: any,
  data?: any,
  method?: string,
  metadataEntry?: any,
): ReturnType {
  const config = getAccessToken();
  const cache = useRef<Cache<T>>({});
  const cancelRequest = useRef<boolean>(false);
  const [url, setUrl] = useState<string>(initialUrl ?? "");
  const [refreshIndex, setRefreshIndex] = useState<number>(0);
  const initialState: State = {
    status: "init",
    error: undefined,
    data: data,
    statusCode: undefined,
  };

  // Sets Index to know when an api is refreshed
  const refresh = () => {
    setRefreshIndex(refreshIndex + 1);
  };

  // Keep state logic separated
  const fetchReducer = (state: State, action: Action): State => {
    switch (action.type) {
      case "FETCH_INIT":
        return { ...initialState, status: "fetching" };
      case "FETCH_SUCCESS":
        return {
          ...initialState,
          status: "fetched",
          data: action.payload,
          statusCode: action.apiStatus,
        };
      case "FETCH_FAILURE":
        return {
          ...initialState,
          status: "error",
          error: action.payload,
          statusCode: action.apiStatus,
        };
      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(fetchReducer, initialState);

  useEffect(() => {
    if (!url) {
      return;
    }

    if (!isEmpty(initialState.data) && refreshIndex === 0)
      dispatch({ type: "FETCH_SUCCESS", payload: initialState.data });
    const fetchData = async () => {
      // const apiService is ts-ignore as the openAPI configuration json mime throws an type error under all circumstances.

      const apiService = new GetBranchDetailsApi(await config, undefined, axiosInstance);
      dispatch({ type: "FETCH_INIT" });
      if (cache.current[url] && refreshIndex === 0) {
        dispatch({ type: "FETCH_SUCCESS", payload: cache.current[url] });
      } else {
        try {
          const params = options;
          let response: any = {};
          if (method === "GET") {
            response = await apiService[url](params);
          } else if (method === "PUT") {
            //@ts-ignore
            response = await apiService[url](params, metadataEntry);
          } else {
            //@ts-ignore

            response = await apiService[url](params, metadataEntry);
          }
          cache.current[url] = response.data;
          if (!cancelRequest) return;
          dispatch({
            type: "FETCH_SUCCESS",
            payload: response.data,
            apiStatus: response.status,
          });
        } catch (error) {
          if (!cancelRequest) return;
          dispatch({
            type: "FETCH_FAILURE",
            payload: error.response,
            apiStatus: error.status,
          });
        }
      }
    };

    if (url !== "" || refreshIndex > 0) {
      fetchData();
    }
    return () => {
      cancelRequest.current = true;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [url, refreshIndex]);
  return [state, setUrl, refresh];
}

export default useBranchApi;
