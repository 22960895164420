import { useGetGlobal } from "@bbo/api/generator";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { RecordKnownVariance } from "@bbo/features/RecordKnownVariance";
import { useGlobalState } from "@bbo/lib/state";
import { useEffect } from "react";

const ReportKnownPage = () => {
  const [, setRecordVarianceDataList] = useGlobalState("RecordVarianceData");

  const { data: encodeData } = useGetGlobal("varianceTypes");

  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Record a Known Variance");
  }, [setTitle]);

  useEffect(() => {
    if (encodeData) {
      setRecordVarianceDataList(JSON.parse(atob(encodeData?.message)));
    }
  }, [encodeData, setRecordVarianceDataList]);

  return (
    <>
      <div className="containerMain">
        <div className="w-full items-start overflow-x-auto newAutoHeight">
          <div className="px-4 py-10 md:p-18 mx-auto w-full">
            <div>
              <RecordKnownVariance />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ReportKnownPage;
