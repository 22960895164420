import Loader from "@bbo/components/Loader";
import { Tab } from "@bbo/components/Tab/Tab";
import { TaskHistory, TasksData } from "./Panels";
import { taskHistoryTitleProps, tasksTitleProps } from "./titleValues";
import { useHomeTab } from "./useHomeTab";

export const HomeTab = () => {
  const { taskList, isTaskListLoading, onCashFlow } = useHomeTab();

  if (isTaskListLoading) {
    return <Loader />;
  }
  return (
    <>
      <Tab id={"TaskListTab"}>
        <Tab.TitleList>
          <Tab.Title>Tasks</Tab.Title>
          <Tab.Title>Task History</Tab.Title>
        </Tab.TitleList>
        <Tab.Panel className="w-full bg-white">
          <Tab.PanelHeaders>
            <Tab.PanelHeaderTitle titleProps={tasksTitleProps} />
            <Tab.PanelHeaderTitle titleProps={taskHistoryTitleProps} />
          </Tab.PanelHeaders>
          <Tab.PanelContent>
            <Tab.PanelData>
              <TasksData data={taskList} onCashFlow={onCashFlow} />
            </Tab.PanelData>
            <Tab.PanelData>
              <TaskHistory data={taskList} />
            </Tab.PanelData>
          </Tab.PanelContent>
        </Tab.Panel>
      </Tab>
    </>
  );
};
