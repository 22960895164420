import { MapRefProps, TabContext } from "@bbo/context";

import { MutableRefObject, ReactNode, SetStateAction, SyntheticEvent, useState } from "react";
import { TabPanel } from "./TabPanel";
import { TabPanelContent } from "./TabPanelContent";
import { TabPanelData } from "./TabPanelData";
import { TabPanelHeaders } from "./TabPanelHeaders";
import { TabPanelHeaderTitle } from "./TabPanelHeaderTitle";
import { TabTitle } from "./TabTitle";
import { TabTitleList } from "./TabTitleList";

type TabsProps = {
  id: string;
  children: ReactNode;
  className?: string;
  initialIndex?: number;
};

type CurrentTabProps = {
  index: SetStateAction<number>;
  props: { ref: MutableRefObject<HTMLLIElement> };
};

export const Tab = ({ children, id, initialIndex = 0, className }: TabsProps) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(initialIndex);
  const getId = (...rest: (string | number)[]) => [...rest, id].join("-");

  const handleClick = (index: SetStateAction<number>) => (event: SyntheticEvent) => {
    event.preventDefault();
    setActiveTabIndex(index);
  };

  const focusTab = (currentTab: CurrentTabProps) => {
    setActiveTabIndex(currentTab.index);
    currentTab.props.ref.current.focus();
  };

  const handleKeyboard =
    (index: number, context: { map: MapRefProps }) => (event: KeyboardEvent & SyntheticEvent) => {
      const tabCount = Object.keys(context.map.current).length - 1;

      const getTab = (index: number) => {
        const tabs = context.map.current;
        const id = Object.keys(tabs).find((id) => tabs[id].index === index);
        return tabs[id];
      };
      console.log(event.key);
      switch (event.key) {
        case "ArrowLeft":
          return tabCount > 0 ? focusTab(getTab(index - 1)) : focusTab(getTab(tabCount));
        case "ArrowRight":
          return tabCount > index ? focusTab(getTab(index + 1)) : focusTab(getTab(0));
        case "Home":
          event.preventDefault();
          return focusTab(getTab(0));

        case "End":
          event.preventDefault();
          return focusTab(getTab(tabCount));
        default:
          return;
      }
    };

  return (
    <TabContext.Provider value={{ activeTabIndex, handleClick, handleKeyboard, getId }}>
      {children}
    </TabContext.Provider>
  );
};

Tab.TitleList = TabTitleList;
Tab.Title = TabTitle;
Tab.PanelContent = TabPanelContent;
Tab.Panel = TabPanel;
Tab.PanelHeaderTitle = TabPanelHeaderTitle;
Tab.PanelData = TabPanelData;
Tab.PanelHeaders = TabPanelHeaders;
