import { useGetPouchidVersion } from "@bbo/api/generator";
import PrintConfirm from "@bbo/components/Printconfirm/index";
import { CURRENCY_ITEM_ID } from "@bbo/constants/currencyItemID";
import { useAppSelector } from "@bbo/lib";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { useGlobalState } from "@bbo/lib/state";
import { amountUnSignedPound } from "@bbo/utils/currencyFormatter";
import {
  currentDateDDMMYYYY,
  currentOnlyTime,
  getDateTime,
  getOnlyDateTime,
} from "@bbo/utils/dateTimeFormatter";
import { CashMovementHistoryTemplate } from "@bbo/utils/ReceiptPrinterTemplates/CashMovementHistory";
import { formattedQuantity } from "@bbo/utils/util";
import { useEffect, useState } from "react";
import UserAvatar from "../../assets/images/downarrow.png";

export default function CashMovementsModal(props) {
  const [cashMovementModal, setCashMovementModal] = useGlobalState("cashMovementModal");
  const [isActive, setActive] = useState(false);
  const [pouchType, setPouchType] = useState<string>("N/A");
  const [printData, setPrintData] = useState<boolean>(false);
  const branchDetails = useAppSelector((root) => root.branchDetails);
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);
  const receiptPrinterService = useReceiptPrinter();

  const { data: pouchDetailData, status, isSuccess } = useGetPouchidVersion(props.versionID);

  const pouchTypes = ["Transfer In", "Transfer Out", "Paid In", "Paid Out"];

  const pouchStatus = (operationType: string): string => {
    switch (operationType.toLowerCase()) {
      case "pouch preparation":
        return "prepared";
      case "pouch cancelled":
        return "cancelled";
      case "pouch despatch":
        return "despatched";
      case "pouch acceptance":
        return "accepted";
    }
  };

  useEffect(() => {
    setCashMovementModal({ cashMovementModal: props.data.showModal });
    if (props.versionID) {
      setPouchType("Cash");
    } else {
      setPouchType("N/A");
    }
  }, [props.data, props.versionID, setCashMovementModal]);

  const toggleTable = async () => {
    setActive(!isActive);
  };

  const onPrintClick = (title: string) => {
    receiptPrinterService.printReceipt({
      template: CashMovementHistoryTemplate,
      context: {
        title: title,
        fadcode: branchDetails.fadcode,
        currentDate: currentDateDDMMYYYY(),
        currentTime: currentOnlyTime(),
        amount: amountUnSignedPound(props.data.data.transferAmount),
        branchType: props.data.data.userName,
        cashMovementType: props.data.data.operationType,
        pouchType: pouchTypes?.includes(props.data.data.operationType)
          ? "NA"
          : props.data.data.operationType,
        pouchBarcode: props?.data?.data?.destinationEntityID?.substring(
          props?.data?.data?.destinationEntityID?.indexOf("-") +
            props?.data?.data?.destinationEntityID?.indexOf("-") +
            1,
        ),
        Username: props.data.data.userName,
        SmartID: props.data.data.smartID,
        DateandTime: getDateTime(props.data.data.dateTime),
        TransactionLocation: props.data.data.transferAt,
        From: props.data.data.sourceEntityName,
        to: props.data.data.destinationEntityName,
        Terminal: props.data.data.nodeID,
      },
    });
  };

  return (
    <>
      {printData && (
        <PrintConfirm
          onCancel={() => {
            setPrintData(false);
          }}
          onReprint={() => {
            onPrintClick("CASH MOVEMENT SUMMARY DUPLICATE");
            setPrintData(false);
          }}
          onSuccess={() => {
            setPrintData(false);
          }}
        />
      )}
      {cashMovementModal.cashMovementModal && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-2 mx-auto max-w-xl">
              <div className="border-0 rounded-lg shadow-lg relative height-39 overflow-y-auto flex flex-col w-full bg-white outline-none focus:outline-none p-4	">
                <div className="flex items-center justify-between">
                  <h3 className="text-lg font-bold text-color">Cash Movements - Details</h3>
                  <button
                    className="ml-auto border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {
                      setCashMovementModal({ cashMovementModal: false });
                      setActive(false);
                    }}>
                    <span className="text-black opacity-1 w-6 text-3xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-center p-4  rounded-b">
                  {props.data.showModal && (
                    <table className="w-full movementTable">
                      <tbody className="bg-white divide-y divide-gray-200">
                        <tr>
                          <td>
                            <b>Cash Movement Type</b>
                          </td>
                          <td>{props.data.data.operationType}</td>
                        </tr>
                        {props.data.data.transactionType !== "cash_transfer" && (
                          <tr>
                            <td>
                              <b>Pouch Type</b>
                            </td>
                            <td>
                              <div className="flex justify-between">
                                <span>{pouchType}</span>
                                {pouchType !== "N/A" && (
                                  <a
                                    className="ml-2 flex colorBlue items-center"
                                    href="javascript:void(0)"
                                    onClick={toggleTable}>
                                    Break Down
                                    <img
                                      className={`h-4 w-4 ${
                                        isActive
                                          ? "transform rotate-180 transition duration-150 ease-in-out"
                                          : ""
                                      }`}
                                      src={UserAvatar}
                                    />
                                  </a>
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                        {pouchType !== "N/A" && isActive && (
                          <tr>
                            <td colSpan={2}>
                              <table className="breakdownTable">
                                <thead>
                                  <tr>
                                    <th>
                                      <b>Denomination</b>
                                    </th>
                                    <th></th>

                                    <th>
                                      <b>Quantity</b>
                                    </th>

                                    <th></th>
                                    <th>
                                      <b>Value</b>
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {isSuccess &&
                                    pouchDetailData &&
                                    Object.values(pouchDetailData.items).map((data, index) => {
                                      return (
                                        <tr key={index}>
                                          <td>{CURRENCY_ITEM_ID[data.itemID]}</td>
                                          <td>X</td>

                                          <>
                                            <td>{formattedQuantity(data.itemQuantity)}</td>
                                          </>

                                          <td>=</td>
                                          <td>{amountUnSignedPound(data.itemValue)}</td>
                                        </tr>
                                      );
                                    })}
                                </tbody>
                              </table>
                            </td>
                          </tr>
                        )}
                        <tr>
                          <td>
                            <b>User Name</b>
                          </td>
                          <td>{props.data.data.userName}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Smart ID</b>
                          </td>
                          <td>{props.data.data.smartID}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Date & Time</b>
                          </td>
                          <td>{getOnlyDateTime(props.data.data.dateTime)}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Transaction Location</b>
                          </td>
                          <td>{props.data.data.transferAt}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>From</b>
                          </td>
                          <td>{props.data.data.sourceEntityName}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>To</b>
                          </td>
                          <td>{props.data.data.destinationEntityName}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Terminal </b>
                          </td>
                          <td>{props.data.data.nodeID}</td>
                        </tr>
                        <tr>
                          <td>
                            <b>Amount</b>
                          </td>
                          <td>
                            {amountUnSignedPound(
                              props?.data?.data?.transferAmount || props?.data?.data?.amount,
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  )}
                </div>
                <div className="flex justify-center pt-2">
                  <button
                    className="accounting-button mr-1"
                    onClick={() => {
                      setCashMovementModal({ cashMovementModal: false });
                      setPrintData(true);
                      onPrintClick("CASH MOVEMENT SUMMARY");
                    }}>
                    Print Summary
                  </button>
                  <button
                    className="accounting-button ml-1"
                    onClick={() => {
                      setCashMovementModal({ cashMovementModal: false });
                      setPrintData(true);
                      onPrintClick("CASH MOVEMENT SUMMARY DUPLICATE");
                    }}>
                    Print Confirmation
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
}
