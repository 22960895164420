/**
 * Status constants
 * Discrepancy movement status
 * Record a Know variance status
 */
export const DISCREPANCY_STATUS = {
  AWAITING_BRANCH_ACTION: "Awaiting Branch Action",
  AWAITING_CENTRAL_SUPPORT: "Awaiting Central Support",
  HELP_REQUESTED: "Help Requested",
  DISPUTE_RAISED: "Dispute Raised",
  SETTLED: "Settled",
  AWAITING_CODE: "AwaitingBranchAction",
  HELP_CODE: "HelpRequested",
  DISPUTE_CODE: "DisputeRaised",
  SETTLED_CODE: "Settled",
  TP: "TP",
  BP: "BP",
  TC: "Transaction Correction",
  CASH_POUCH_DESPATCH: "csc_pd_cc",
  CASH_POUCH_ACCEPTANCE: "csc_pa_cc",
  CARD_FAILURE: "bank_dcpf",
  BANK_MISS_KEY: "bank_mke",
};
