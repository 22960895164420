export const tasksTitleProps = [
  {
    layout: "leftText w-2/4",
    title: "Task",
  },
  {
    layout: "leftText w-1/3",
    title: "Due Date",
  },
  {
    layout: "leftText w-1/3",
    title: "Status",
  },
];
