import { ForexCurrencyData, ForexCurrencyAtAL } from "@bbo/api/generator";
import { TableBody, TableRow } from "@material-ui/core";
import { useMemo } from "react";
import Cell from "./Cell";
import { Column } from "./types";

interface ITableDataBodyProps {
  rowData: ForexCurrencyData[];
  cols: Column[];
  currentPage: number;
}
let forexItems;

const genTableCell = (value: string, name: string, index: number, type: string) => {
  let blnCurrencyExist = false;
  if (name === "currentVariance") {
    return (
      <Cell headClassName={"body-cell children-body-cell"}>
        {forexItems?.map((item: ForexCurrencyAtAL, i) => {
          if (+item.itemDetails.currentVariance > 0 || +item.itemDetails.currentVariance < 0) {
            blnCurrencyExist = true;
            return (
              <Cell
                key={`variance${i}`}
                name={`${item.barcode}, ${item.itemDetails.currentVariance}`}
                type={type}
                headClassName={"body-cell sub-body-cell"}></Cell>
            );
          }
        })}
        {!blnCurrencyExist && (
          <Cell key={index} name={"0.00"} type={type} headClassName={"body-cell"}></Cell>
        )}
      </Cell>
    );
  } else {
    return <Cell key={index} name={value} type={type} headClassName={"body-cell"}></Cell>;
  }
};
const TableDataBody = ({ rowData, cols, currentPage }: ITableDataBodyProps) => {
  const currentRows = useMemo(
    () => rowData?.slice((currentPage - 1) * 5, currentPage * 5),
    [currentPage, rowData],
  );
  if (currentRows.length === 0) {
    return (
      <TableBody>
        <TableRow className="table-row">
          <Cell headClassName="emptyCell" colSpan={8}>
            No count summary exists for the entity
          </Cell>
        </TableRow>
      </TableBody>
    );
  }
  return (
    <TableBody>
      {currentRows?.map((row, i) => (
        <TableRow key={i} className="table-row">
          {cols?.map(({ field, type }) => {
            forexItems = Object.values(row["forexItems"]);
            return genTableCell(row[field], field, i, type);
          })}
        </TableRow>
      ))}
    </TableBody>
  );
};

export default TableDataBody;
