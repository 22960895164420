/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */

export type PouchStatus =
  | "prepared"
  | "cancelled"
  | "suspended"
  | "despatched"
  | "accepted"
  | "expected";

// eslint-disable-next-line @typescript-eslint/no-redeclare
export const PouchStatus = {
  prepared: "prepared" as PouchStatus,
  cancelled: "cancelled" as PouchStatus,
  suspended: "suspended" as PouchStatus,
  despatched: "despatched" as PouchStatus,
  accepted: "accepted" as PouchStatus,
  expected: "expected" as PouchStatus,
};
