import { useGetDiscrepancyList } from "@bbo/api/generator/endpoints/get-discrepancy-list";
import { useGetDiscrepancyTotal } from "@bbo/api/generator";
import {
  Filters,
  FromToFilter,
  MultiDropdownFilter,
  SingleDropdownFilter,
} from "@bbo/components/DynamoTable/dynamoTableTypes";
import { DISCREPANCY_STATUS } from "@bbo/constants/CashConstants";
import { useGlobalState } from "@bbo/lib";
import { useCallback, useEffect, useMemo, useState } from "react";
import {
  ALL,
  AWAITING_BRANCH_ACTION,
  AWAITING_CENTRAL_SUPPORT,
  SETTLED,
  SUMMARY_OVERVIEW_TABS,
} from "./constant";
import { BpTpDeclaration } from "@bbo/api/generator";

interface GetDiscrepancyFilters extends Filters {
  reasonCode: SingleDropdownFilter;
  discrepancyRecordType: SingleDropdownFilter;
  status: MultiDropdownFilter;
  timestamp: FromToFilter;
  discrepancyValue: FromToFilter;
}
const timestampTest = {
  from: Math.floor(Date.now() - 42 * 24 * 60 * 60 * 1000),
  to: Math.floor(Date.now()),
};
interface DetailedViewModel {
  showModel?: boolean;
  params?: any;
}
export const useDiscrepancyPage = () => {
  const [statusFilterValue, setstatusFilterValue] = useState([]);
  const [, setShowSettleDispute] = useGlobalState("showSettleDispute");

  const [isAwaitBranch, setIsAwaitBranch] = useState<boolean>(false);
  const [isAwaitCentral, setIsAwaitCentral] = useState<boolean>(false);
  const [isSettle, setIsSettle] = useState<boolean>(false);
  // const [DetailedViewModel, setDetailedViewModel] = useState<DetailedViewModel>({
  //   showModel: true,
  // });

  const [isHistory, setIsHistory] = useState<boolean>(true);
  const [DetailedViewModel, setDetailedViewModel] = useGlobalState("DetailedViewModel");
  const [, setGlobalActiveFilters] = useGlobalState("activeFilters");

  const [selectedSubTab, setSelectedSubTab] = useState(SUMMARY_OVERVIEW_TABS[0]);

  const initialFilters: GetDiscrepancyFilters = useMemo(
    () => ({
      reasonCode: {
        variant: "singleDropdown",
        placeholder: "ALL",
        generateValues: true,
        values: [],
      },
      discrepancyRecordType: {
        variant: "singleDropdown",
        placeholder: "ALL",
        generateValues: true,
        values: [
          { label: "Stock", value: "stock", selected: false },
          { label: "Forex", value: "forex", selected: false },
          { label: "Cash", value: "cash", selected: false },
        ],
      },
      status: {
        variant: "multiDropdown",
        placeholder: "ALL",
        generateValues: true,
        values: [
          { label: "Help Requested", value: "HelpRequested", selected: isAwaitCentral },
          {
            label: "Awaiting Branch Action",
            value: "AwaitingBranchAction",
            selected: isAwaitBranch,
          },
          { label: "Dispute Raised", value: "DisputeRaised", selected: isAwaitCentral },
          { label: "Settled", value: "Settled", selected: isSettle },
        ],
      },
      timestamp: {
        variant: "fromTo",
        unit: "date",
        // value: timestampTest,
      },
      discrepancyValue: {
        variant: "fromTo",
        unit: "currency",
        value: {
          from: null,
          to: null,
        },
      },
    }),
    [isAwaitBranch, isAwaitCentral, isSettle],
  );
  const getList = (arr) => {
    return arr.filter((item, index) => arr.indexOf(item) === index);
  };
  const handleClickRow = useCallback(
    (params) => {
      setDetailedViewModel({ showModel: false, params: params.data });
      setShowSettleDispute({ showSettleDisputeButton: true });
    },
    [setDetailedViewModel, setShowSettleDispute],
  );
  const {
    data: PutDiscrepancyData,
    isSuccess: DiscrepancyStatus,
    isLoading,
    isRefetching,
    isFetching,
    refetch: refetchGetDiscrepancyList,
  } = useGetDiscrepancyList();

  const { data: discrepancyTotal, refetch: refetchTotal } = useGetDiscrepancyTotal();

  const DiscrepancyData = useMemo(() => {
    if (PutDiscrepancyData) {
      let newData;
      if (selectedSubTab === AWAITING_BRANCH_ACTION) {
        newData = PutDiscrepancyData.data.filter((list) => {
          if (
            list.status === DISCREPANCY_STATUS.AWAITING_CODE ||
            list.status === DISCREPANCY_STATUS.AWAITING_BRANCH_ACTION
          ) {
            return list;
          }
        });
      } else if (selectedSubTab === AWAITING_CENTRAL_SUPPORT) {
        newData = PutDiscrepancyData.data.filter((list) => {
          if (
            list.status === DISCREPANCY_STATUS.HELP_CODE ||
            list.status === DISCREPANCY_STATUS.DISPUTE_CODE ||
            list.status === DISCREPANCY_STATUS.HELP_REQUESTED ||
            list.status === DISCREPANCY_STATUS.DISPUTE_RAISED
          ) {
            return list;
          }
        });
      } else if (selectedSubTab === SETTLED) {
        newData = PutDiscrepancyData.data.filter((list) => {
          if (list.status === DISCREPANCY_STATUS.SETTLED_CODE) {
            return list;
          }
        });
      } else {
        newData = PutDiscrepancyData.data;
      }
      setGlobalActiveFilters([]);
      return newData;
    }
  }, [PutDiscrepancyData, selectedSubTab, setGlobalActiveFilters]);
  // const {
  //   data,
  //   error,
  //   isLoading,
  //   hasNext,
  //   hasPrev,
  //   getNext,
  //   getPrev,
  //   filters,
  //   setFilters,
  //   selectedItems,
  //   selectedItemIndexes,
  //   setSelectedItemIndexes,
  // } = useDynamoPagination<
  //   typeof useGetDiscrepancyList,
  //   GetDiscrepancyListQueryResult,
  //   GetDiscrepancyListQueryError
  // >(
  //   useGetDiscrepancyList,
  //   {
  //     filters: initialFilters,
  //   },
  //   {
  //     // here we could pass query params to be used by the useGetCashDeclarationHistory hook
  //   },
  //   {
  //     // here we could specify react query options, if needed
  //   },
  // );

  // const onFilterChange = useCallback(
  //   (filterKey: string, filter: Filter) => {
  //     setFilters((prev) => ({
  //       ...prev,
  //       [filterKey]: filter,
  //     }));
  //   },
  //   [setFilters],
  // );

  const handleSelectTab = useCallback((params) => {
    setSelectedSubTab(params.value);
  }, []);

  // const resetFunc = useCallback(async () => {
  //   // setFilters(initialFilters);
  //   const clearedFilters = clearFilters(filters);
  //   setFilters(clearedFilters);
  // }, [filters, setFilters]);

  useEffect(() => {
    if (selectedSubTab === AWAITING_BRANCH_ACTION) {
      setIsAwaitCentral(false);
      setIsAwaitBranch(true);
      setIsSettle(false);
    } else if (selectedSubTab === AWAITING_CENTRAL_SUPPORT) {
      setIsAwaitBranch(false);
      setIsAwaitCentral(true);
      setIsSettle(false);
    } else if (selectedSubTab === SETTLED) {
      setIsSettle(true);
      setIsAwaitBranch(false);
      setIsAwaitCentral(false);
    } else {
      setIsSettle(false);
      setIsAwaitBranch(false);
      setIsAwaitCentral(false);
    }
    // setFilters(initialFilters);
  }, [initialFilters, selectedSubTab]);

  const subTabLabels = [
    {
      value: ALL,
      valueLength: 0,
    },
    {
      value: AWAITING_BRANCH_ACTION,
      valueLength: 0,
    },
    {
      value: AWAITING_CENTRAL_SUPPORT,
      valueLength: 0,
    },
    { value: SETTLED, valueLength: 0 },
  ];
  const GetDiscrepancyID = useCallback((value) => {
    if (value) {
      const NewValue = value?.split("#");

      return NewValue[0];
    }
  }, []);

  const getBpTpDeclarationTitle = (
    bpTpDeclaration: BpTpDeclaration,
    declarationType: "BP" | "TP" | null,
  ) => {
    if (!bpTpDeclaration || !declarationType) return null;

    const { BP, TP } = bpTpDeclaration;

    switch (declarationType) {
      case "BP":
        return `BP${BP}-TP${TP}`;
      case "TP":
        return `TP${TP}`;
      default:
        return null;
    }
  };

  return {
    DiscrepancyData,
    isLoading,
    isRefetching,
    // error,
    // hasNext,
    // hasPrev,
    // getNext,
    // getPrev,
    // filters,
    // setFilters,
    handleClickRow,
    handleSelectTab,
    isAwaitCentral,
    isAwaitBranch,
    subTabLabels,
    selectedSubTab,
    DetailedViewModel,
    setDetailedViewModel,
    // selectedItems: selectedItems as DiscrepancyDeclarationList,
    // selectedItemIndexes,
    // setSelectedItemIndexes,
    GetDiscrepancyID,
    // resetFunc,
    setIsHistory,
    isHistory,
    refetchGetDiscrepancyList,
    getBpTpDeclarationTitle,
    discrepancyTotal,
    refetchTotal,
  };
};
