const swapIndex = (array, from, to) => {
  const newArr = [...array];
  newArr.splice(to, 0, newArr.splice(from, 1)[0]);
  return newArr;
};

export const formatCurrency = (cur) => {
  const newCur = [...cur];
  const from = cur.split(".")[1].length > 1 ? newCur.indexOf(".") : newCur.indexOf(".") - 1;
  if (cur.split(".")[1].length === 2 && (Number(cur.split(".")[1]) || Number(cur.split(".")[0]))) {
    return cur;
  }
  return swapIndex(newCur, from, from + 1).join("");
};
export const getCurrencyConstraints = (name, value, baseValue) => {
  let errorMsg = null;
  let alertCss = "";
  let quantity = 0;
  let amount = 0;
  if (name === "Quantity") {
    amount = Number(value) * Number(baseValue);
    quantity = Number(value);
  }
  if (name === "value" || name === "tvalue") {
    const newVal = formatCurrency(value);
    quantity = Number(newVal) / Number(baseValue);
    quantity = quantity - Math.floor(quantity) !== 0 ? 0 : quantity;
    amount = Number(newVal);
  }
  if (quantity * Number(baseValue) === amount && amount && quantity) {
    alertCss = "successCss";
  }
  if (quantity - Math.floor(quantity) !== 0 || quantity * Number(baseValue) !== amount) {
    errorMsg = "Entered value is not correct, please check again.";
    alertCss = "alertCss";
  }

  return { errorMsg, quantity, amount, alertCss };
};

export const simulateTotal = (list, amount) => {
  let newAmount = Number(amount);
  for (let index = 0; index < list.length; index++) {
    const element = Number(list[index]);
    if (newAmount >= element) {
      newAmount = newAmount - element;
      return { alertCss: "successCss", errorMsg: null };
    }
    if (newAmount >= element) {
      index--;
    }
  }
  if (newAmount > 0) {
    return {
      errorMsg: "Entered Total is not correct, please check again.",
      alertCss: "alertCss",
    };
  }
  if (newAmount === 0) {
    return { alertCss: "", errorMsg: null };
  }
};
