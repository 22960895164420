import PopupConfirm from "@bbo/components/Alert";
import { ErrorPopup } from "@bbo/components/ErrorPopup";
import Loader from "@bbo/components/Loader";
import PrintConfirm from "@bbo/components/Printconfirm/index";
import { SCREENS } from "@bbo/constants";
import { getAccountingLocationText } from "../BranchOverview/branchOverviewHelpers";
import {
  TransferAccountingLocationInfo,
  TransferAccountingLocationList,
  TransferInputPanel,
} from "../TransferInOut";
import { useTransferIn } from "./useTransferIn";

export const TransferInPage = () => {
  const {
    accountingLocation,
    otherAccountingLocationsGrouped,
    selectedOtherAccountingLocation,
    setSelectedOtherAccountingLocation,
    isBranchOverviewLoading,
    activeTab,
    setActiveTab,
    tabs,
    handleCashDetails,
    ReceiptPrint,
    selectedAmount,
    setSelectedAmount,
    activeModal,
    setActiveModal,
    error,
    setError,
    branchOverviewData,
    navigate,
    onBackPress,
  } = useTransferIn();

  return (
    <>
      {isBranchOverviewLoading ? (
        <Loader />
      ) : (
        <>
          <div className="subheading font-24 font-bold leading-7 lg:w-screen">
            <div className="width-70 float-left">
              <button onClick={onBackPress}>
                <i className="fas fa-chevron-left"></i> Back
              </button>
            </div>
          </div>
          <div className="w-full grid grid-cols-1 gap-4 lg:grid-cols-2 lg:gap-12 transferOut-height overflow-y-auto">
            {!selectedOtherAccountingLocation ? (
              <TransferAccountingLocationList
                className="pl-8"
                title="Cash Transfer From"
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                otherAccountingLocationsGrouped={otherAccountingLocationsGrouped}
                selectedOtherAccountingLocation={selectedOtherAccountingLocation}
                setSelectedOtherAccountingLocation={setSelectedOtherAccountingLocation}
                branchOverviewData={branchOverviewData}
              />
            ) : (
              <TransferAccountingLocationInfo
                accountingLocation={selectedOtherAccountingLocation}
              />
            )}
            <TransferInputPanel
              title={`Cash Transfer to the ${
                getAccountingLocationText(accountingLocation?.accountingLocationType).title
              }`}
              selectedAmount={selectedAmount}
              setSelectedAmount={setSelectedAmount}
              selectedOtherAccountingLocation={selectedOtherAccountingLocation}
              handleCashDetails={handleCashDetails}
              setError={setError}
              error={error}
            />
            {activeModal === "Print" ? (
              <PrintConfirm
                onCancel={() => {
                  setActiveModal(null);
                  navigate(SCREENS.BRANCH_OVERVIEW);
                }}
                onReprint={() => {
                  ReceiptPrint("TRANSFER IN DUPLICATE");
                }}
                onSuccess={() => {
                  setActiveModal(null);
                  navigate(SCREENS.BRANCH_OVERVIEW);
                }}
              />
            ) : null}
            {activeModal === "TransferSuccess" ? (
              <PopupConfirm
                header="Cash Transfer in is successful!"
                labelCancel="Close"
                labelSuccess="Print Receipt"
                tickicon={true}
                onCancel={() => {
                  setActiveModal(null);
                  navigate(SCREENS.BRANCH_OVERVIEW);
                }}
                onSuccess={() => {
                  ReceiptPrint("TRANSFER IN");
                  setActiveModal("Print");
                }}
              />
            ) : null}
          </div>
          {error?.type === "modalError" ? (
            <ErrorPopup
              popupType="error"
              messageHeading={error?.message}
              labelSuccess="Back"
              onSuccess={() => navigate(-1)}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default TransferInPage;
