import { useDescendants } from "@bbo/lib";
import { PropsWithChildren } from "react";
import { Descendants } from "./Descendants";

export const TabPanelContent = ({
  children,
  className,
}: PropsWithChildren<{ className?: string }>) => {
  const context = useDescendants();

  return (
    <Descendants value={context}>
      <div className={className} role="tabpanel">
        {children}
      </div>
    </Descendants>
  );
};
