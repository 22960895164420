import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";

interface IStockCountReviewHeaderProps {
  countGroup: string;
  entityName: string;
  total: number;
  grapevineRefNumber: string;
}

export const StockCountReviewHeader = ({
  countGroup,
  entityName,
  total,
  grapevineRefNumber,
}: IStockCountReviewHeaderProps) => {
  return (
    <>
      <div className="grid grid-cols-12 gap-2 right-panel p-7">
        <div className="col-span-6 standard-label">
          <strong>{countGroup}</strong> - {entityName}
          {grapevineRefNumber ? (
            <>
              <br />
              <strong>Grapevine Reference Number</strong> - {grapevineRefNumber}
            </>
          ) : null}
        </div>

        <div className="col-span-6 standard-label text-right">
          <strong>Total Variance Value </strong> :
          <span className={`ml-2 ${currencyStyle(total)}`}>{amountSignedPound(total)}</span>
        </div>
      </div>
    </>
  );
};
