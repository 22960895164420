import Loader from "@bbo/components/Loader";
import useDespatchApi from "@bbo/lib/hooks/useDespatchApi";
import { FC, useEffect, useLayoutEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import CarrierSelection from "./CarrierSelection";
import CutoffDespatch from "./CutoffDespatch";
import DespatchItemsSelection, { Item } from "./DespatchItemsSelection";
import DespatchTypeSelection from "./DespatchTypeSelection";
import "./index.css";

export const MailsDespatch: FC = () => {
  const { branchId, nodeId: counterId } = useParams();

  const navigate = useNavigate();

  const [isBranch, setIsBranch] = useState<boolean>(false);
  const [isCounter, setIsCounter] = useState<boolean>(false);
  const [layoutIndex, setLayoutIndex] = useState<number>(1);
  const [showCutoffPopup, setShowCutoffPopup] = useState<boolean>(false);
  const [isYes, setIsYes] = useState<boolean>(false);
  const [isNo, setIsNo] = useState<boolean>(false);
  const [isRoyalMailDespatch, setIsRoyalMailDespatch] = useState<boolean>(true);
  const [isParcelForceDespatch, setIsParcelForceDespatch] = useState<boolean>(false);

  const getRoyalMailRequestPayload = {
    carrierId: "RM",
    branchId: branchId,
    clerkId: "1",
    counterId: counterId,
  };
  const [{ data: getRoyalMailResponse, status: rmStatus }, getRMDespatchableItems] = useDespatchApi(
    "",
    getRoyalMailRequestPayload,
    "",
    "GET",
  );

  const getParcelForceRequestPayload = {
    carrierId: "PF",
    branchId: branchId,
    clerkId: "1",
    counterId: counterId,
  };
  const [{ data: getParcelForceResponse, status: pfStatus }, getPFDespatchableItems] =
    useDespatchApi("", getParcelForceRequestPayload, "", "GET");

  const [rmDespatchItemList, setRmDespatchItemList] = useState<Item[]>([]);
  const [pfDespatchItemList, setPfDespatchItemList] = useState<Item[]>([]);

  const [itemSelected, setItemSelected] = useState<Item[]>([]);
  const [despatchItemList, setDespatchItemList] = useState<Item[]>([]);

  if (itemSelected.length > 0) {
    itemSelected.map((element) => (element.status = "CARRIER_COLLECTED"));
  }
  const updateRequestParams = {
    branchId: branchId,
    counterId: counterId,
    clerkId: "1",
    parcels: itemSelected,
  };
  const [{ data: updateTaskRepsonse, status: updateStatus }, updateDespatchItemsStatus] =
    useDespatchApi("", updateRequestParams, "", "PUT", "");

  const updateLastDespatchParams = {
    branchId: branchId,
    counterId: counterId,
    clerkId: "1",
    carrierId: isRoyalMailDespatch ? "RM" : "PF",
  };
  const [
    { data: updateLastDespatchStatusResponse, status: updateLastStatus },
    updateLastDespatchStatus,
  ] = useDespatchApi("", updateLastDespatchParams, "", "PUT", {
    afterLastDispatch: true,
  });

  useEffect(() => {
    if (getRoyalMailResponse && Object.keys(getRoyalMailResponse).length > 0) {
      let arr = [];
      if (getRoyalMailResponse.parcels.length > 0) {
        console.log(getRoyalMailResponse.parcels.length);
        arr = getRoyalMailResponse.parcels.filter(
          (element: Item) => element.status === "READY_FOR_CARRIER_COLLECTION",
        );
      }
      setRmDespatchItemList(arr);
    }
  }, [getRoyalMailResponse]);

  useEffect(() => {
    if (getParcelForceResponse && Object.keys(getParcelForceResponse).length > 0) {
      let arr = [];
      if (getParcelForceResponse.parcels.length > 0) {
        arr = getParcelForceResponse.parcels.filter(
          (element: Item) => element.status === "READY_FOR_CARRIER_COLLECTION",
        );
      }
      setPfDespatchItemList(arr);
    }
  }, [getParcelForceResponse]);

  useEffect(() => {
    if (layoutIndex === 2) {
      getRMDespatchableItems("getDespatchableList");
      getPFDespatchableItems("getDespatchableList");
    }
  }, [getPFDespatchableItems, getRMDespatchableItems, layoutIndex]);

  useLayoutEffect(() => {
    if (updateTaskRepsonse !== typeof undefined && updateStatus === "fetched") {
      navigate("/taskManagement");
    }
  }, [history, updateStatus, updateTaskRepsonse]);

  useLayoutEffect(() => {
    if (updateLastDespatchStatusResponse !== typeof undefined && updateLastStatus === "fetched") {
      setLayoutIndex(3);
    }
  }, [updateLastDespatchStatusResponse, updateLastStatus]);

  useEffect(() => {
    if (layoutIndex === 2) {
      if (isRoyalMailDespatch) {
        setDespatchItemList(rmDespatchItemList);
        setItemSelected(rmDespatchItemList);
      }
      if (isParcelForceDespatch) {
        setDespatchItemList(pfDespatchItemList);
        setItemSelected(pfDespatchItemList);
      }
    }
  }, [
    isParcelForceDespatch,
    isRoyalMailDespatch,
    layoutIndex,
    pfDespatchItemList,
    rmDespatchItemList,
  ]);

  const addItem = (item: Item) => {
    if (itemSelected.some((element) => element.trackingId === item.trackingId)) {
      const index = itemSelected.findIndex((element) => element.trackingId === item.trackingId);
      if (index > -1) {
        setItemSelected(itemSelected.filter((element) => element !== itemSelected[index]));
      }
    } else {
      setItemSelected((prev) => [...prev, item]);
    }
  };

  const onPressCutoffAndDespatch = () => updateDespatchItemsStatus("updateDespatchStatus");

  return (
    <div>
      {(rmStatus === "fetching" ||
        pfStatus === "fetching" ||
        updateStatus === "fetching" ||
        updateLastStatus === "fetching") && <Loader />}
      {showCutoffPopup && (
        <CutoffDespatch
          onPressBack={() => setShowCutoffPopup(false)}
          onPressCutoffAndDespatch={onPressCutoffAndDespatch}
        />
      )}
      {layoutIndex === 1 && (
        <DespatchTypeSelection
          isBranch={isBranch}
          isCounter={isCounter}
          onChangeBranch={() => {
            setIsBranch(true);
            setIsCounter(false);
          }}
          onChangeCounter={() => {
            setIsBranch(false);
            setIsCounter(true);
          }}
        />
      )}
      {layoutIndex === 2 && (
        <div>
          <CarrierSelection
            isParcelForceDespatch={isParcelForceDespatch}
            isRoyalMailDespatch={isRoyalMailDespatch}
            onSelectParcelforce={() => {
              setIsRoyalMailDespatch(false);
              setIsParcelForceDespatch(true);
            }}
            onSelectRoyalMail={() => {
              setIsRoyalMailDespatch(true);
              setIsParcelForceDespatch(false);
            }}
            rmDespatchItemList={rmDespatchItemList}
            pfDespatchItemList={pfDespatchItemList}
            isYes={isYes}
            isNo={isNo}
            onSelectYes={() => {
              setIsYes(true);
              setIsNo(false);
            }}
            onSelectNo={() => {
              setIsYes(false);
              setIsNo(true);
            }}
          />
        </div>
      )}
      {layoutIndex === 3 && (
        <DespatchItemsSelection
          addItem={addItem}
          itemSelected={itemSelected}
          despatchItemList={despatchItemList}
        />
      )}
      <div className="flex item-center justify-center mt-10">
        {layoutIndex === 2 && (
          <button
            className="restNoBtn border-t border-r border-l border-b border-black border-solid bg-transparent-500 text-black active:bg-transparent-600 font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 hover"
            onClick={() => setLayoutIndex(1)}>
            Back
          </button>
        )}
        {(layoutIndex === 1 || layoutIndex === 2) && (
          <button
            className="brandRedBtn text-white active:brandRedBtn font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
            onClick={() => {
              if (layoutIndex === 1) {
                if (!isBranch && !isCounter) {
                  alert("Select one option!!");
                } else {
                  setLayoutIndex(2);
                }
              } else if (layoutIndex === 2) {
                if (!isYes && !isNo) {
                  alert("Select one option!!");
                } else if (isYes) {
                  updateLastDespatchStatus("updateLastDespatchStatus");
                } else if (isNo) {
                  setLayoutIndex(3);
                }
              }
            }}>
            Next
          </button>
        )}
        {layoutIndex === 3 && (
          <button
            onClick={() => {
              if (itemSelected.length === 0) {
                alert("Despatchable items should not be empty");
              } else {
                setShowCutoffPopup(true);
              }
            }}
            className="brandRedBtn text-white active:brandRedBtn font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150 sticky bottom-0">
            Print report
          </button>
        )}
      </div>
    </div>
  );
};
