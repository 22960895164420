import {
  ReceiptPrinterClient,
  ReceiptPrinterModes,
  ReceiptPrinterResult,
  setup,
  SupportedServices,
} from "postoffice-peripheral-management-service";
import { compile } from "postoffice-receipt-generator";
import { useAppSelector } from "./useAppSelector";

export interface ReceiptService {
  printReceipt: (
    templateId: string,
    context: Record<string, unknown>,
  ) => Promise<ReceiptPrinterResult>;
}

export interface Receipt {
  template: string;
  context: Record<string, unknown>;
}
export type ReceiptContext = Record<string, unknown>;

export const POL_PRINTER_DEVICE_SERVER_HOST = "ws://localhost:2001/nbit-server";

export const compileReceipt = async (
  template: string,
  context: ReceiptContext,
): Promise<string> => {
  if (!template) {
    throw new Error(`Receipt template '${template}' not found`);
  }

  return await compile(template as string, context);
};

export const printReceiptOnDevice = async (
  templateId: string,
  context: Record<string, unknown>,
): Promise<boolean> => {
  if (!templateId || !context) {
    console.error("Missing context and/or template name");
  }

  const compiledTemplate = await compileReceipt(templateId as string, context as ReceiptContext);
  if (!compiledTemplate) {
    console.error(`Unable to locate receipt template '${templateId || "not provided"}'`);
  }

  const printingService = setup({
    deviceServerHost: POL_PRINTER_DEVICE_SERVER_HOST,
    disconnectAfterResponse: true,
  });

  const receiptPrinterClient = printingService.buildClient(
    SupportedServices.ReceiptPrinter,
    {},
  ) as ReceiptPrinterClient;

  return (await receiptPrinterClient.print(compiledTemplate, ReceiptPrinterModes.Html)).printed;
};

export const useReceiptPrinter = () => {
  const { isCtAccess } = useAppSelector((root) => root.counterTerminalAccess);
  const printReceipt = async (receipt): Promise<boolean> => {
    if (!receipt.template || !receipt.context) {
      console.error("Missing context and/or template name");
    }
    try {
      const printReceiptResult = await printReceiptOnDevice(receipt.template, {
        ...receipt.context,
      });
      return printReceiptResult;
    } catch (error) {
      console.error(`not able to print ${error}`);
    }
  };

  return {
    printReceipt,
  };
};
