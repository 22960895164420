export const POUCH_PREPARATION_DUPLICATE = "POUCH PREPARATION DUPLICATE";
export const POUCH_ACCEPTANCE_DUPLICATE = "POUCH ACCEPTANCE DUPLICATE";
export const POUCH_DESPATCH_DUPLICATE = "POUCH DESPATCH DUPLICATE";
export const POUCH_DESPATCH_SUMMARY = "POUCH DESPATCH SUMMARY";
export const POUCH_ACCEPTANCE_SUMMARY = "POUCH ACCEPTANCE SUMMARY";
export const POUCH_PREPARATION_SUMMARY = "POUCH PREPARATION SUMMARY";
export const DESPATCHED = "Despatched";
export const ACCEPTED = "Accepted";
export const COUNTER_TERMINAL = "Counter Terminal";
export const CASH_CENTER = "Cash Center";
export const POUCH_DESPATCH = "Pouch Despatch";
export const POUCH_ACCEPTANCE = "Pouch Acceptance";
export const Pouch_Acceptance_History_Details = "Pouch Acceptance History - Details";
export const Pouch_Despatch_History_Details = "Pouch Despatch History - Details";
export const Pouch_Preparation_History_Details = "Pouch Preparation History - Details";
