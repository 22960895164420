import { millisecondsToSeconds } from "date-fns";
import { Dispatch, SetStateAction, useEffect } from "react";
import { Options } from "./paginationTypes";
import { ErrorState } from "./useDynamoPagination";
import { getDateTime } from "@bbo/utils/dateTimeFormatter";

// Data Fetching Hook
export const useDataFetching = <T extends (...params: any[]) => any, TData, TError>(
  queryHook: T,
  options: Options,
  queryHookParams: Parameters<T>,
  chunkedDate: {
    from: number;
    to: number;
  },
  needsToFetch: boolean,
  errorState: ErrorState,
  setError: Dispatch<SetStateAction<ErrorState>>,
  setAllData: Dispatch<SetStateAction<TData[]>>,
) => {
  const { apiFromDateKey, apiToDateKey } = options;
  // console.log(
  //   "from: ",
  //   getDateTime(millisecondsToSeconds(chunkedDate.from)),
  //   "to: ",
  //   getDateTime(millisecondsToSeconds(chunkedDate.to)),
  // );

  const result = queryHook(
    {
      [apiFromDateKey]: millisecondsToSeconds(chunkedDate.from),
      [apiToDateKey]: millisecondsToSeconds(chunkedDate.to),
    },
    {
      ...queryHookParams[1],
      query: {
        keepPreviousData: false,
        cacheTime: 0,
        staleTime: 0,
        ...queryHookParams[1]?.query,
        enabled: errorState === null && needsToFetch,
      },
    },
  );
  const { data: responseData, error, failureReason, isInitialLoading, fetchStatus } = result;
  const data = responseData?.[options.dataKey] || result?.[options.dataKey];
  // console.log({ result, options, data });

  useEffect(() => {
    if (error || failureReason) setError({ isError: true, failureReason });
    if (!data || !Array.isArray(data)) return;
    setAllData((prev) => [...prev, ...(data || [])]);
  }, [data, error, failureReason, setAllData, setError]);

  // console.log(data);
  return { data, responseData, error, isInitialLoading, isLoading: fetchStatus === "fetching" };
};
