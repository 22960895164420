import { CashTransferHistoryData, useGetBusinessRulesByBranchId } from "@bbo/api/generator";
import AdhocBalance from "@bbo/features/AdhocBalance/AdhocBalance";
import { useAppSelector } from "@bbo/lib";
import { useGlobalState } from "@bbo/lib/state";
import { amountUnSignedPound } from "@bbo/utils/currencyFormatter";
import { getOnlyDateTime } from "@bbo/utils/dateTimeFormatter";
import { useEffect, useState } from "react";
import "./Index.css";

export interface AdhocCashBalancingProps {
  pouchData?:
    | {
        title: string;
        content: React.ReactNode;
      }
    | Record<string, never>;
  activitiesBetweenBalancesTransactions?: any;
}

const AdhocCashBalancing = ({
  pouchData = {},
  activitiesBetweenBalancesTransactions,
}: AdhocCashBalancingProps | Record<string, never>) => {
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);
  const [, setMaxLimitCounter] = useGlobalState("maxLimitCounter");
  const selectedTask = useAppSelector((root) => root.selectedTask);
  const [cashMovementStatus, setCashMovementStatus] = useState(true);

  //could be moved out into util file?
  const dataFormatter = (data) => {
    const newdata =
      data.cashMovement?.map((item: CashTransferHistoryData) => {
        const mValue = {};
        if (item.dateTime) {
          mValue["lastBalancedDate"] = getOnlyDateTime(item.dateTime);
        }
        if (item) {
          mValue["amount"] = amountUnSignedPound(item.transferAmount);
        }

        return {
          ...item,
          ...mValue,
        };
      }) || [];
    return [newdata, data.actionList];
  };

  const { data: counterData } = useGetBusinessRulesByBranchId(fadCode, {
    rule_type: "transfer_out",
    location: "",
    entity_id: selectedTask?.entityID,
  });

  useEffect(() => {
    if (counterData !== undefined) {
      setMaxLimitCounter(counterData[0]?.permittedLimit);
    } else setMaxLimitCounter(0);
  }, [counterData, setMaxLimitCounter]);

  return (
    <div>
      <AdhocBalance
        pouchData={pouchData}
        activitiesBetweenBalancesTransactions={activitiesBetweenBalancesTransactions}
      />
    </div>
  );
};
export default AdhocCashBalancing;
