import PopupConfirm from "@bbo/components/Alert";
import { ErrorPopup } from "@bbo/components/ErrorPopup";
import Loader from "@bbo/components/Loader";
import PrintConfirm from "@bbo/components/Printconfirm/index";
import { useNavigate } from "react-router-dom";
import {
  TransferAccountingLocationInfo,
  TransferAccountingLocationList,
  TransferInputPanel,
} from "../TransferInOut";
import { useTransferOut } from "./useTransferOut";

const TransferOutPage = () => {
  const {
    accountingLocation,
    otherAccountingLocationsGrouped,
    selectedOtherAccountingLocation,
    setSelectedOtherAccountingLocation,
    isBranchOverviewLoading,
    activeTab,
    setActiveTab,
    tabs,
    handleCashDetails,
    ReceiptPrint,
    selectedAmount,
    setSelectedAmount,
    activeModal,
    setActiveModal,
    setError,
    error,
    branchOverviewData,
    navigateToBranchOverview,
  } = useTransferOut();

  const navigate = useNavigate();

  return (
    <>
      {isBranchOverviewLoading ? (
        <Loader />
      ) : (
        <>
          <div className="subheading font-24 font-bold leading-7 lg:w-screen">
            <div className="width-70 float-left">
              <button onClick={() => navigate(-1)}>
                <i className="fas fa-chevron-left"></i> Back
              </button>
            </div>
          </div>
          <div className="containerMain">
            <div className="lg:w-full transferOut-height overflow-y-auto grid grid-cols-1 gap-5 lg:grid-cols-3 lg:gap-10">
              <TransferAccountingLocationInfo
                accountingLocation={accountingLocation}
                infoBoxClassName="bg-white"
              />
              <TransferAccountingLocationList
                title="Cash Transfer To"
                listClassName="bg-white"
                tabs={tabs}
                activeTab={activeTab}
                setActiveTab={setActiveTab}
                otherAccountingLocationsGrouped={otherAccountingLocationsGrouped}
                selectedOtherAccountingLocation={selectedOtherAccountingLocation}
                setSelectedOtherAccountingLocation={setSelectedOtherAccountingLocation}
                branchOverviewData={branchOverviewData}
              />
              <TransferInputPanel
                title="Cash Transfer Amount"
                selectedAmount={selectedAmount}
                setSelectedAmount={setSelectedAmount}
                selectedOtherAccountingLocation={selectedOtherAccountingLocation}
                handleCashDetails={handleCashDetails}
                setError={setError}
                error={error}
              />
              {activeModal === "Print" ? (
                <PrintConfirm
                  onCancel={() => {
                    setActiveModal(null);
                    navigateToBranchOverview();
                  }}
                  onReprint={() => {
                    ReceiptPrint("TRANSFER OUT DUPLICATE");
                  }}
                  onSuccess={() => {
                    setActiveModal(null);
                    navigateToBranchOverview();
                  }}
                />
              ) : null}
              {activeModal === "TransferSuccess" ? (
                <PopupConfirm
                  header="Cash Transfer out is successful!"
                  labelCancel="Close"
                  labelSuccess="Print Receipt"
                  tickicon={true}
                  onCancel={() => {
                    setActiveModal(null);
                    navigateToBranchOverview();
                  }}
                  onSuccess={() => {
                    ReceiptPrint("TRANSFER OUT");
                    setActiveModal("Print");
                  }}
                />
              ) : null}
            </div>
          </div>
          {error?.type === "modalError" ? (
            <ErrorPopup
              popupType="error"
              messageHeading={error?.message}
              labelSuccess="Back"
              onSuccess={() => navigate(-1)}
            />
          ) : null}
        </>
      )}
    </>
  );
};

export default TransferOutPage;
