import {
  AdditionalItems,
  CashPaidInOut,
  CashPreparedPouch,
  CashTransfer,
  DiscrepancyMovementParams,
  discrepancyMovementSettleToCash,
  TransferPayloadParams,
  updateDiscrepancyStatus,
} from "@bbo/types/TransferTypes";
import { createBpTpDiscrepancy, createPouchDiscrepancy } from "../types/TransferTypes";
import { getUnixTimeStamp } from "./dateTimeFormatter";
import { getUUID } from "./util";

/**
 * Transaction Modes
 * Transfer API transaction mode constants
 */
export const TRANSACTION_MODE = {
  TRANSFER_IN: 7,
  TRANSFER_OUT: 13,
  PAID_IN: 15,
  PAID_OUT: 15,
  POUCH_PREPARATION: 26,
  POUCH_PREPARATION_CANCEL: 27,
  DISCREPANCY_MODE: 15,
  TC_SETTLE_T0_PL: 31,
  TC_SETTLE_TO_NOMINEE: 32,
  TC_SETTLE_TO_CASH: 29,
  POUCH_ACCEPTANCE: 24,
  POUCH_DESPATCH: 28,
};

export const CARD_FAILURE_ITEMID_TC = ["34179", "34197", "39162", "39163", "47170"];
/**
 * Cash Transaction ItemIDs
 * Transfer ItemIDs
 */
export const TRANSACTION_ITEM_ID = {
  CASH: "1",
  TRANSFER_IN: "6276",
  TRANSFER_OUT: "6277",
  PAID_IN: "247", //"6295",
  PAID_OUT: ["248", "6497", "6495", "6496"], //"6295",
  POUCH_PREPARATION: "5610", //Same for Cancel Prepared Pouch
  POUCH_ACCEPTANCE: "6287",
  POUCH_DESPATCH: "6826",
  DISCREPANCY_POUCH: "50818",
  SETTLED_TO_NOMINEE: "50919",
  MULTIPLE_SETTLED: "50917",
  SETTLE_TO_ACCOUNT: "50918",
  SETTLE_TO_PL: "6292",
  //known variance dispcrepancy itemID
  POUCH_DESPATCH_SURPLUS: "50817",
  POUCH_DESPATCH_SHORTAGE: "50818",
  POUCH_ACCEPTANCE_SURPLUS: "50811",
  POUCH_ACCEPTANCE_SHORTAGE: "50812",
  BANKING_MISS_KEY_LOSS: "50824",
  BANKING_MISS_KEY_GAIN: "50823",
  DEBIT_CREDIT_FAILURE_LOSS: "50826",
  //known variance (TP-BP) dispcrepancy itemID
  TP_BP_BALANCING_SURPLUS: "50832",
  TP_BP_BALANCING_SHORTAGE: "50833",
  //TC update itemId
  TC_SETTLE: "9574",
  TC_SETTLE_TO_CASH: "50817",
  TC_SURPLUS_SETTLEMENT: "8570",
  TC_CASH_PA: "9243",
};

/**
 * only Unusable Cash Transaction Note/Coin ItemIDs
 * Transfer ItemIDs
 */
export const UNUSABLE_TRANSACTION_NOTE_COIN_ITEM_ID = {
  NOTE_UNUSABLE_50_POUND_ID: "3323",
  NOTE_UNUSABLE_20_POUND_ID: "3324",
  NOTE_UNUSABLE_10_POUND_ID: "3325",
  NOTE_UNUSABLE_5_POUND_ID: "3326",
  NOTE_UNUSABLE_1_POUND_ID: "3327",
  COIN_UNUSABLE_5_POUND_ID: "3344",
  COIN_UNUSABLE_2_POUND_ID: "3345",
  COIN_UNUSABLE_1_POUND_ID: "3346",
  COIN_UNUSABLE_50_PENCE_ID: "3347",
  COIN_UNUSABLE_20_PENCE_ID: "3348",
  COIN_UNUSABLE_10_PENCE_ID: "3349",
  COIN_UNUSABLE_5_PENCE_ID: "3350",
  COIN_UNUSABLE_2_PENCE_ID: "3351",
  COIN_UNUSABLE_1_PENCE_ID: "3352",
  UNUSABLE_COINS_ID: "3321",
  UNUSABLE_NOTES_ID: "3320",
};

/**
 * Cash Transaction Note/Coin ItemIDs
 * Transfer ItemIDs
 */
export const TRANSACTION_NOTE_COIN_ITEM_ID = {
  NOTE_50_POUND_ID: "655",
  NOTE_20_POUND_ID: "656",
  NOTE_10_POUND_ID: "657",
  NOTE_5_POUND_ID: "658",
  COIN_2_POUND_ID: "357",
  COIN_1_POUND_ID: "660",
  COIN_50_PENCE_ID: "661",
  COIN_20_PENCE_ID: "2191",
  COIN_10_PENCE_ID: "2190",
  COIN_5_PENCE_ID: "2189",
  COIN_2_PENCE_ID: "663",
  COIN_1_PENCE_ID: "662",
  NOTE_UNUSABLE_50_POUND_ID: "3323",
  NOTE_UNUSABLE_20_POUND_ID: "3324",
  NOTE_UNUSABLE_10_POUND_ID: "3325",
  NOTE_UNUSABLE_5_POUND_ID: "3326",
  NOTE_UNUSABLE_1_POUND_ID: "3327",
  BAG_2_POUND_FULL_ID: "3328",
  BAG_1_POUND_FULL_ID: "3329",
  BAG_50_PENCE_FULL_ID: "3330",
  BAG_20_PENCE_FULL_ID: "3331",
  BAG_10_PENCE_FULL_ID: "3332",
  BAG_5_PENCE_FULL_ID: "3333",
  BAG_2_PENCE_FULL_ID: "3334",
  BAG_1_PENCE_FULL_ID: "3335",
  BAG_2_POUND_PARTIAL_ID: "3336",
  BAG_1_POUND_PARTIAL_ID: "3337",
  BAG_50_PENCE_PARTIAL_ID: "3338",
  BAG_20_PENCE_PARTIAL_ID: "3339",
  BAG_10_PENCE_PARTIAL_ID: "3340",
  BAG_5_PENCE_PARTIAL_ID: "3341",
  BAG_2_PENCE_PARTIAL_ID: "3342",
  BAG_1_PENCE_PARTIAL_ID: "3343",
  COIN_UNUSABLE_5_POUND_ID: "3344",
  COIN_UNUSABLE_2_POUND_ID: "3345",
  COIN_UNUSABLE_1_POUND_ID: "3346",
  COIN_UNUSABLE_50_PENCE_ID: "3347",
  COIN_UNUSABLE_20_PENCE_ID: "3348",
  COIN_UNUSABLE_10_PENCE_ID: "3349",
  COIN_UNUSABLE_5_PENCE_ID: "3350",
  COIN_UNUSABLE_2_PENCE_ID: "3351",
  COIN_UNUSABLE_1_PENCE_ID: "3352",
  UNUSABLE_COINS_ID: "3321",
  UNUSABLE_NOTES_ID: "3320",
};

/**
 * Transfer API request payload for Discrepancy movement
 * @returns Discrepancy movement Params
 */

export const createPouchDiscrepancyParams = (
  params: createPouchDiscrepancy,
  itemID: string,
): DiscrepancyMovementParams => {
  return {
    id: getUUID(),
    transactionStartTime: getUnixTimeStamp(new Date()),
    itemID: itemID,
    quantity: params.varianceAmount < 0 ? -1 : 1,
    valueInPence: params.varianceAmount,
    additionalItems: [
      {
        itemID: "1",
        valueInPence: -params.varianceAmount,
        quantity: params.varianceAmount < 0 ? 1 : -1,
        tokens: {
          TCReference: params.pouchNumber,
          ClientReference: params.branchDetails.fadcode.slice(0, -1),
        },
      },
    ],
    tokens: {
      TCReference: params.pouchNumber,
      pouchBarcode: params.pouchNumber,
      branchReferenceNumber: params.branchReferenceNumber,
      comment: " ",
      reasonCode: params.reason.code,
      reasonCodeDescription: params.reason.title,
      discrepancyRecordType: "Cash",
      status: params.status,
      userName: params.userName,
      operationType: params.operationType,
    },
    fadCode: params.fadcode,
    transactionMode: TRANSACTION_MODE.DISCREPANCY_MODE,
    stockunitIdentifier: "S01",
    accountReferenceID: params.pouchNumber,
    ClientReference: params.branchDetails.fadcode.slice(0, -1),
  };
};

export const createDiscreapancyForUnknownVariance = (
  params: createBpTpDiscrepancy,
  itemID: string,
) => {
  return {
    id: getUUID(),
    transactionStartTime: getUnixTimeStamp(new Date()),
    itemID: itemID,
    quantity: params.varianceAmount < 0 ? -1 : 1,
    valueInPence: params.varianceAmount,
    additionalItems: [
      {
        itemID: "1",
        valueInPence: -params.varianceAmount,
        quantity: params.varianceAmount < 0 ? 1 : -1,
        tokens: {
          TCReference: params.branchDetails.fadcode + getUnixTimeStamp(new Date()),
          ClientReference: params.branchDetails.fadcode.slice(0, -1),
        },
      },
    ],
    tokens: {
      TCReference: params?.branchDetails?.fadcode + getUnixTimeStamp(new Date()),
      comment: " ",
      reasonCode: params?.reason?.title,
      reasonCodeDescription: params.reason.title,
      discrepancyRecordType: "Cash",
      status: params?.status,
      userName: params?.userName,
      operationType: "discrepancyRaised",
      BPDeclarationID: params?.declarationID,
    },
    fadCode: params.fadcode,
    transactionMode: TRANSACTION_MODE.DISCREPANCY_MODE,
    stockunitIdentifier: "S01",
    accountReferenceID: params.branchDetails.fadcode + getUnixTimeStamp(new Date()),
    ClientReference: params.branchDetails.fadcode.slice(0, -1),
  };
};

export const updateDiscrepancyParams = (
  params: updateDiscrepancyStatus,
  itemID: string,
  itemIDIn?: string,
): DiscrepancyMovementParams => {
  return {
    id: params.discrepencyUpdateDetails[0]?.transferID.concat(
      params.discrepencyUpdateDetails[0]?.status,
    ),
    transactionStartTime: getUnixTimeStamp(new Date()),
    itemID: itemID,
    quantity: params.discrepencyUpdateDetails[0]?.discrepancyValue < 0 ? 1 : -1,
    valueInPence: -params.discrepencyUpdateDetails[0]?.discrepancyValue,
    additionalItems: [
      {
        itemID: String(itemIDIn),
        valueInPence: params.discrepencyUpdateDetails[0]?.discrepancyValue,
        quantity: params.discrepencyUpdateDetails[0]?.discrepancyValue < 0 ? -1 : 1,
        tokens: {
          TCReference: params.discrepencyUpdateDetails[0].TCReference,
          ClientReference: "",
        },
      },
    ],
    tokens: {
      TCReference: params.discrepencyUpdateDetails[0].TCReference,
      pouchBarcode: params.discrepencyUpdateDetails[0].accountReferenceID,
      comment: params.discrepencyUpdateDetails[0].comments,
      status: params.discrepencyUpdateDetails[0].status,
      userName: params.userName,
      reasonCode: params.discrepencyUpdateDetails[0].reason,
      reasonCodeDescription: params.discrepencyUpdateDetails[0].reasonCodeDescription,
      operationType: "discrepancyUpdated",
      discrepancyID: params.discrepencyUpdateDetails[0].discrepencyDataID,
    },
    fadCode: params.fadCode,
    transactionMode: TRANSACTION_MODE.DISCREPANCY_MODE,
    stockunitIdentifier: "S01",
    accountReferenceID: params.discrepencyUpdateDetails[0].accountReferenceID,
    ClientReference: "",
  };
};

export const updateTCParams = (
  params: updateDiscrepancyStatus,
  transactionMode: number,
  itemID: string,
  itemIDIn: string,
): DiscrepancyMovementParams => {
  return {
    id: params.discrepencyUpdateDetails[0].accountReferenceID.concat(
      params.discrepencyUpdateDetails[0].status,
    ),
    transactionStartTime: getUnixTimeStamp(new Date()),
    itemID: itemID,
    quantity: params.discrepencyUpdateDetails[0]?.discrepancyValue < 0 ? -1 : 1,
    valueInPence: params.discrepencyUpdateDetails[0]?.discrepancyValue,
    additionalItems: [
      {
        itemID: String(itemIDIn),
        valueInPence: -params.discrepencyUpdateDetails[0]?.discrepancyValue,
        quantity: params.discrepencyUpdateDetails[0]?.discrepancyValue < 0 ? 1 : -1,
        tokens: {
          TCReference: params.discrepencyUpdateDetails[0].TCReference,
          ClientReference: params.branchDetails.fadcode.slice(0, -1),
        },
      },
    ],
    tokens: {
      TCReference: params.discrepencyUpdateDetails[0].TCReference,
      pouchBarcode: params.discrepencyUpdateDetails[0].accountReferenceID,
      comment: params.discrepencyUpdateDetails[0].comments,
      status: params.discrepencyUpdateDetails[0].status,
      reasonCode: params.discrepencyUpdateDetails[0].reason,
      reasonCodeDescription: params.discrepencyUpdateDetails[0].reasonCodeDescription,
      userName: params.userName,
      operationType: "discrepancyUpdated",
      discrepancyID: params.discrepencyUpdateDetails[0].discrepencyDataID,
    },
    fadCode: params.fadCode,
    transactionMode: transactionMode,
    stockunitIdentifier: "S01",
    accountReferenceID: params.discrepencyUpdateDetails[0].accountReferenceID,
    ClientReference: params.branchDetails.fadcode.slice(0, -1),
  };
};

export const updateTCParamsForSP = (
  params: updateDiscrepancyStatus,
  transactionMode: number,
  itemID: string,
  itemIDIn: string,
): DiscrepancyMovementParams => {
  return {
    id: params.discrepencyUpdateDetails[0].accountReferenceID.concat(
      params.discrepencyUpdateDetails[0].status,
    ),
    transactionStartTime: getUnixTimeStamp(new Date()),
    itemID: itemID,
    quantity: params.discrepencyUpdateDetails[0]?.discrepancyValue < 0 ? -1 : 1,
    valueInPence: params.discrepencyUpdateDetails[0]?.discrepancyValue,
    additionalItems: [
      {
        itemID: String(itemIDIn),
        valueInPence: -params.discrepencyUpdateDetails[0]?.discrepancyValue,
        quantity: params.discrepencyUpdateDetails[0]?.discrepancyValue < 0 ? 1 : -1,
        tokens: {
          TCReference: params.discrepencyUpdateDetails[0].TCReference,
          ClientReference: params.branchDetails.fadcode.slice(0, -1),
        },
      },
      {
        itemID: "50917",
        valueInPence: -params.discrepencyUpdateDetails[0]?.discrepancyValue,
        quantity: params.discrepencyUpdateDetails[0]?.discrepancyValue < 0 ? 1 : -1,
        tokens: {
          TCReference: params.discrepencyUpdateDetails[0].TCReference,
          ClientReference: params.branchDetails.fadcode.slice(0, -1),
        },
      },
      {
        itemID: "50916",
        valueInPence: params.discrepencyUpdateDetails[0]?.discrepancyValue,
        quantity: params.discrepencyUpdateDetails[0]?.discrepancyValue < 0 ? -1 : 1,
        tokens: {
          TCReference: params.discrepencyUpdateDetails[0].TCReference,
          ClientReference: params.branchDetails.fadcode.slice(0, -1),
        },
      },
    ],
    tokens: {
      TCReference: params.discrepencyUpdateDetails[0].TCReference,
      pouchBarcode: params.discrepencyUpdateDetails[0].accountReferenceID,
      comment: params.discrepencyUpdateDetails[0].comments,
      status: params.discrepencyUpdateDetails[0].status,
      userName: params.userName,
      reasonCode: params.discrepencyUpdateDetails[0].reason,
      reasonCodeDescription: params.discrepencyUpdateDetails[0].reasonCodeDescription,
      operationType: "discrepancyUpdated",
      discrepancyID: params.discrepencyUpdateDetails[0].discrepencyDataID,
    },
    fadCode: params.fadCode,
    transactionMode: transactionMode,
    stockunitIdentifier: "S01",
    accountReferenceID: params.discrepencyUpdateDetails[0].accountReferenceID,
    ClientReference: params.branchDetails.fadcode.slice(0, -1),
  };
};

export const updateTCParamsForIN = (
  params: updateDiscrepancyStatus,
  transactionMode: number,
  itemID: string,
  itemIDIn: string,
): DiscrepancyMovementParams => {
  return {
    id: params.discrepencyUpdateDetails[0].accountReferenceID.concat(
      params.discrepencyUpdateDetails[0].status,
    ),
    transactionStartTime: getUnixTimeStamp(new Date()),
    itemID: itemID,
    quantity: params.discrepencyUpdateDetails[0]?.discrepancyValue < 0 ? -1 : 1,
    valueInPence: params.discrepencyUpdateDetails[0]?.discrepancyValue,
    additionalItems: [
      {
        itemID: String(itemIDIn),
        valueInPence: -params.discrepencyUpdateDetails[0]?.discrepancyValue,
        quantity: params.discrepencyUpdateDetails[0]?.discrepancyValue < 0 ? 1 : -1,
        tokens: {
          TCReference: params.discrepencyUpdateDetails[0].TCReference,
          ClientReference: params.branchDetails.fadcode.slice(0, -1),
        },
      },
      {
        itemID: "50916",
        valueInPence: params.discrepencyUpdateDetails[0]?.discrepancyValue,
        transactionMode: TRANSACTION_MODE.DISCREPANCY_MODE,
        quantity: params.discrepencyUpdateDetails[0]?.discrepancyValue < 0 ? -1 : 1,
        tokens: {
          TCReference: params.discrepencyUpdateDetails[0].TCReference,
          ClientReference: params.branchDetails.fadcode.slice(0, -1),
        },
      },
      {
        itemID: "50917",
        valueInPence: -params.discrepencyUpdateDetails[0]?.discrepancyValue,
        transactionMode: TRANSACTION_MODE.DISCREPANCY_MODE,
        quantity: params.discrepencyUpdateDetails[0]?.discrepancyValue < 0 ? 1 : -1,
        tokens: {
          TCReference: params.discrepencyUpdateDetails[0].TCReference,
          ClientReference: params.branchDetails.fadcode.slice(0, -1),
        },
      },
    ],
    tokens: {
      TCReference: params.discrepencyUpdateDetails[0].TCReference,
      pouchBarcode: params.discrepencyUpdateDetails[0].accountReferenceID,
      comment: params.discrepencyUpdateDetails[0].comments,
      status: params.discrepencyUpdateDetails[0].status,
      userName: params.userName,
      reasonCode: params.discrepencyUpdateDetails[0].reason,
      reasonCodeDescription: params.discrepencyUpdateDetails[0].reasonCodeDescription,
      operationType: "discrepancyUpdated",
      discrepancyID: params.discrepencyUpdateDetails[0].discrepencyDataID,
    },
    fadCode: params.fadCode,
    transactionMode: transactionMode,
    stockunitIdentifier: "S01",
    accountReferenceID: params.discrepencyUpdateDetails[0].accountReferenceID,
    ClientReference: params.branchDetails.fadcode.slice(0, -1),
  };
};

export const settleTCToCash = (
  params: discrepancyMovementSettleToCash,
  transactionMode: number,
  itemID: string,
  itemIDIn: string,
): DiscrepancyMovementParams => {
  return {
    id: params.discrepencyUpdateDetails[0]?.transferID.concat(
      params.discrepencyUpdateDetails[0]?.status,
    ),
    transactionStartTime: getUnixTimeStamp(new Date()),
    itemID: itemID,
    quantity: params.discrepencyUpdateDetails[0]?.discrepancyValue < 0 ? -1 : 1,
    valueInPence: params.discrepencyUpdateDetails[0]?.discrepancyValue,
    additionalItems: [
      {
        itemID: String(itemIDIn),
        valueInPence: -params.discrepencyUpdateDetails[0]?.discrepancyValue,
        quantity: params?.discrepencyUpdateDetails[0]?.discrepancyValue < 0 ? 1 : -1,
        tokens: {
          TCReference: params?.discrepencyUpdateDetails[0]?.TCReference,
          ClientReference: params.branchDetails.fadcode.slice(0, -1),
        },
      },
    ],
    tokens: {
      TCReference: params.discrepencyUpdateDetails[0].TCReference,
      pouchBarcode: params.discrepencyUpdateDetails[0].accountReferenceID,
      comment: params.discrepencyUpdateDetails[0].comments,
      status: params.discrepencyUpdateDetails[0].status,
      userName: params.userName,
      reasonCode: params.discrepencyUpdateDetails[0].reason,
      reasonCodeDescription: params.discrepencyUpdateDetails[0].reasonCodeDescription,
      operationType: "discrepancyUpdated",
      discrepancyID: params.discrepencyUpdateDetails[0].discrepencyDataID,
    },
    fadCode: params.branchDetails.fadcode,
    transactionMode: transactionMode,
    stockunitIdentifier: "S01",
    accountReferenceID: params?.discrepencyUpdateDetails[0]?.accountReferenceID,
    ClientReference: params.branchDetails.fadcode.slice(0, -1),
  };
};

/**
 * Transfer API request payload for Transfer In
 * @returns Transfer In Params
 */
export const transferInParams = (params: CashTransfer): TransferPayloadParams => {
  return {
    id: getUUID(),
    transactionStartTime: getUnixTimeStamp(new Date()),
    itemID: TRANSACTION_ITEM_ID.CASH,
    quantity: -1,
    valueInPence: -params.transferAmount,
    additionalItems: [
      {
        itemID: TRANSACTION_ITEM_ID.TRANSFER_IN,
        valueInPence: params.transferAmount,
        quantity: 1,
      },
      {
        itemID: TRANSACTION_ITEM_ID.CASH,
        valueInPence: params.transferAmount,
        quantity: 1,
        tokens: {
          transactionMode: TRANSACTION_MODE.TRANSFER_OUT,
          stockunitIdentifierDest: params.stockunitIdentifierDest,
        },
      },
      {
        itemID: TRANSACTION_ITEM_ID.TRANSFER_OUT,
        valueInPence: -params.transferAmount,
        quantity: -1,
        tokens: {
          transactionMode: TRANSACTION_MODE.TRANSFER_OUT,
          stockunitIdentifierDest: params.stockunitIdentifierDest,
        },
      },
    ],
    tokens: {
      operationType: params.operationType,
      stockunitIdentifier: params.stockunitIdentifier,
      stockunitIdentifierDest: params.stockunitIdentifierDest,
      transferAt: params.transferAt,
      userName: params.userName,
    },
    fadCode: params.fadcode,
    transactionMode: TRANSACTION_MODE.TRANSFER_IN,
    stockunitIdentifier: params.stockunitIdentifier,
  };
};

/**
 * Transfer API request payload for Transfer In
 * @returns Transfer In Params
 */
export const transferOutParams = (params: CashTransfer): TransferPayloadParams => {
  return {
    id: getUUID(),
    transactionStartTime: getUnixTimeStamp(new Date()),
    itemID: TRANSACTION_ITEM_ID.CASH,
    quantity: -1,
    valueInPence: -params.transferAmount,
    additionalItems: [
      {
        itemID: TRANSACTION_ITEM_ID.TRANSFER_OUT,
        valueInPence: params.transferAmount,
        quantity: 1,
      },
      {
        itemID: TRANSACTION_ITEM_ID.CASH,
        valueInPence: params.transferAmount,
        quantity: 1,
        tokens: {
          transactionMode: TRANSACTION_MODE.TRANSFER_IN,
          stockunitIdentifierDest: params.stockunitIdentifierDest,
        },
      },
      {
        itemID: TRANSACTION_ITEM_ID.TRANSFER_IN,
        valueInPence: -params.transferAmount,
        quantity: -1,
        tokens: {
          transactionMode: TRANSACTION_MODE.TRANSFER_IN,
          stockunitIdentifierDest: params.stockunitIdentifierDest,
        },
      },
    ],
    tokens: {
      operationType: params.operationType,
      stockunitIdentifier: params.stockunitIdentifier,
      stockunitIdentifierDest: params.stockunitIdentifierDest,
      transferAt: params.transferAt,
      userName: params.userName,
    },
    fadCode: params.fadcode,
    transactionMode: TRANSACTION_MODE.TRANSFER_OUT,
    stockunitIdentifier: params.stockunitIdentifier,
  };
};

/**
 * Transfer API request payload for Paid In
 * @returns Paid In Params
 */
export const paidInParams = (params: CashPaidInOut, itemID = "0"): TransferPayloadParams => {
  return {
    id: getUUID(),
    transactionStartTime: getUnixTimeStamp(new Date()),
    itemID: TRANSACTION_ITEM_ID.CASH,
    quantity: -1,
    valueInPence: -params.transferAmount,
    additionalItems: [
      {
        itemID: itemID === "0" ? TRANSACTION_ITEM_ID.PAID_IN : itemID,
        valueInPence: params.transferAmount,
        quantity: 1,
      },
    ],
    tokens: {
      operationType: params.operationType,
      stockunitIdentifier: params.stockunitIdentifier,
      reasonCode: params.reasonCode,
      comments: params.comments,
      transferAt: params.transferAt,
      userName: params.userName,
    },
    fadCode: params.fadcode,
    transactionMode: TRANSACTION_MODE.PAID_IN,
    stockunitIdentifier: params.stockunitIdentifier,
  };
};

/**
 * Transfer API request payload for Paid Out
 * @returns Paid Out Params
 */
export const paidOutParams = (params: CashPaidInOut, itemID = "0"): TransferPayloadParams => {
  return {
    id: getUUID(),
    transactionStartTime: getUnixTimeStamp(new Date()),
    itemID: itemID === "0" ? TRANSACTION_ITEM_ID.PAID_OUT[0] : itemID,
    quantity: -1,
    valueInPence: -params.transferAmount,
    additionalItems: [
      {
        itemID: TRANSACTION_ITEM_ID.CASH,
        valueInPence: params.transferAmount,
        quantity: 1,
      },
    ],
    tokens: {
      operationType: params.operationType,
      stockunitIdentifier: params.stockunitIdentifier,
      reasonCode: params.reasonCode,
      comments: params.comments,
      transferAt: params.transferAt,
      userName: params.userName,
    },
    fadCode: params.fadcode,
    transactionMode: TRANSACTION_MODE.PAID_OUT,
    stockunitIdentifier: params.stockunitIdentifier,
  };
};

/**
 * Pouch Prepared Transfer API request payload
 * @returns Prepared Pouch Params
 */
export const preparedPouchParams = (params: CashPreparedPouch): TransferPayloadParams => {
  let totalValueInPence = 0;
  const items: AdditionalItems[] = [];
  if (params?.denominationLadder?.length === 0) {
    totalValueInPence = 0;
    items.push({
      itemID: "655",
      valueInPence: 0,
      quantity: -1,
      tokens: {
        denomination: 10,
        currencyCode: "GBP",
        materialType: "Notes",
      },
    });
  } else {
    params?.denominationLadder?.map((item) => {
      if (item.value > 0) {
        totalValueInPence += item.value;
        items.push({
          itemID: item.itemID,
          valueInPence:
            params.status === "cancel" || params.status === "delete" ? -item.value : item.value,
          quantity:
            params.status === "cancel" || params.status === "delete"
              ? -Number(item.bag || item.looseCoin)
              : Number(item.bag || item.looseCoin),
          tokens: {
            denomination: parseInt(item.denomination),
            currencyCode: "GBP",
            materialType: item.cashType,
          },
        });
      }
    });
  }

  return {
    id: getUUID(),
    transactionStartTime: getUnixTimeStamp(new Date()),
    itemID: TRANSACTION_ITEM_ID.POUCH_PREPARATION,
    quantity: params.status === "cancel" || params.status === "delete" ? 1 : -1,
    valueInPence:
      params.status === "cancel" || params.status === "delete"
        ? totalValueInPence
        : -totalValueInPence,
    additionalItems: items,
    transactionMode:
      params.status === "cancel" || params.status === "delete"
        ? TRANSACTION_MODE.POUCH_PREPARATION_CANCEL
        : TRANSACTION_MODE.POUCH_PREPARATION,
    stockunitIdentifier: "S01",
    accountReferenceID: params.pouchBarcode,
    fadCode: params.fadCode,
    tokens: {
      pouchType: params.pouchType,
      pouchBarcode: params.pouchBarcode,
      userName: params.status === "delete" ? "SYSTEM" : params.preparedByUserID,
      assignedBranchName: "Meanwood",
      pouchSubType: params.pouchBagType,
      operationType: params.status === "delete" ? "cancel" : params.status,
    },
  };
};

/**
 * Pouch Prepared Cancel Transfer API request payload
 * @returns Cancel Prepared Pouch Params
 */
export const cancelPreparedPouchParams = (params: CashPreparedPouch): TransferPayloadParams => {
  let totalValueInPence = 0;
  const items: AdditionalItems[] = [];
  params.denominationLadder.map((item) => {
    if (item.value > 0) {
      totalValueInPence += item.value;
      items.push({
        itemID: item.itemID,
        valueInPence: item.value,
        quantity: Number(item.bag || item.looseCoin),
        tokens: {},
      });
    }
  });

  return {
    id: getUUID(),
    transactionStartTime: getUnixTimeStamp(new Date()),
    itemID: TRANSACTION_ITEM_ID.POUCH_PREPARATION,
    quantity: 1,
    valueInPence: totalValueInPence,
    additionalItems: items,
    transactionMode: TRANSACTION_MODE.POUCH_PREPARATION_CANCEL,
    stockunitIdentifier: "S01",
    accountReferenceID: params.pouchBarcode,
    fadCode: params.fadCode,
    tokens: {
      ...params,
      totalPouchValue: totalValueInPence,
    },
  };
};

/**
 * Format number with digit
 * @returns string two digit number formatted
 */
export const getStockUnitIdentifier = (entityType: string, sequenceNumber?: number): string => {
  if (entityType === "safe") {
    return "S01"; //Safe
  }
  const sequence = `${sequenceNumber}`.padStart(2, "0");
  return `C${sequence}`; // Cash Drawer
};

export const getStockUnitIdentifierName = (entityType: string): string => {
  if (entityType) {
    if (entityType?.includes("S")) {
      return "Safe";
    }
    return `Cash Drawer ${entityType.substring(1)}`;
  }
};
