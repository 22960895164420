import { Menu } from "@bbo/api/generator";
import { useGlobalState } from "@bbo/lib/state";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MenuData } from "../../types";
import { ALL } from "../DiscrepancyAccount/constant";
import MenuItem from "./MenuItem";

type MenuProps = {
  items: Menu[];
  onMenuClose: () => void;
};

/**
 *Menu List component
 * @returns Menu List
 */
const MenuList = ({ items, onMenuClose }: MenuProps) => {
  const [expandGroupId, setExpandGroupId] = useState("g0");
  const navigate = useNavigate();
  const [, setrouteState] = useGlobalState("routeState");
  const [, setSummaryTab] = useGlobalState("summaryTab");

  //Group menu callback to handle menu expand/collapse
  const handleToggle = (groupId: string) => {
    if (expandGroupId !== groupId) {
      setExpandGroupId(groupId);
    }
  };

  //Menu click callback
  const onMenuClick = (params) => {
    if (params.data && params.data.link) {
      if (params.data.link.includes("https")) {
        window.open(params.data.link);
      } else {
        navigate(params.data.link);
      }
      setrouteState(params.data.link);
      onMenuClose();
    }
  };

  const menuClickCallback = useCallback((params) => {
    onMenuClick(params);
    setSummaryTab(ALL);
  }, []);

  //Render JSX
  if (items && items.length > 0) {
    return (
      <ul className="accordion" id="menu-view">
        {items.map((item: Menu, index) => {
          const isEaxpand = expandGroupId === `g${index}`;
          return (
            <MenuItem
              key={index}
              index={index}
              data={item}
              expand={isEaxpand}
              onToggle={handleToggle}
              onMenuClick={menuClickCallback}
            />
          );
        })}
      </ul>
    );
  }
  return null;
};

export default MenuList;
