import { useDescendant, useTabsContext } from "@bbo/lib";
import { PropsWithChildren } from "react";

export const TabPanelHeaderTitle = ({
  titleProps,
  children,
}: PropsWithChildren<{ titleProps?: { layout: string; title: string }[] }>) => {
  const { activeTabIndex, getId } = useTabsContext();
  const { index } = useDescendant(activeTabIndex);
  const isActive = activeTabIndex === index;

  return (
    <div className="flex justify-start">
      {titleProps
        ? titleProps.map((titleProp, i) => {
            const { layout, title } = titleProp;
            return (
              <div
                key={i}
                role="headerTitle"
                hidden={!isActive}
                className={layout}
                aria-hidden={!isActive}
                aria-selected={isActive}
                tabIndex={isActive ? 0 : -1}
                aria-labelledby={getId(i)}
                id={getId(i, "headerTitle")}
                data-testid={`headerTitle-${i}`}
                data-selected={isActive ? "" : undefined}
                aria-controls={getId(i, "headerTitle")}>
                {title}
              </div>
            );
          })
        : children}
    </div>
  );
};
