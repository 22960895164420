import { useLocation, useParams } from "react-router-dom";
import { useTitle } from "../AuthRoutes";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { TransferInOutTab, groupBranchOverviewData } from "../BranchOverview/branchOverviewHelpers";
import { BranchCashOverview, useGetCashBranchOverviewDetails } from "@bbo/api/generator";
import { useEffect, useMemo, useState } from "react";
import { AccountingLocationGroups } from "../BranchOverview/useBranchOverview";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import { useAppSelector } from "@bbo/lib";
import { TransferOutLocationState } from "../TransferOut/transferOutTypes";

interface NavigationProps {
  accountingLocationID: string;
  otherAccountingLocationID?: string;
}

const initialSelectedAmount = {
  float: 0,
  formatted: "£0",
  value: "0",
};

const tabLabels: TransferInOutTab[] = [
  { value: "counterTerminal", label: "Counters" },
  { value: "cashDrawer", label: "Cash Drawers" },
  { value: "safe", label: "Safe" },
];

// Common data/functions used across TransferIn and TransferOut
export const useTransferInOut = () => {
  const { setIsBackButtonActive } = useTitle();
  const { accountingLocationID } = useParams() as unknown as NavigationProps;
  const receiptPrinterService = useReceiptPrinter();

  const state = useLocation()?.state as TransferOutLocationState;

  const {
    data: branchOverviewData,
    isLoading: isBranchOverviewLoading,
    error: branchOverviewError,
  } = useGetCashBranchOverviewDetails();

  const accountingLocation = useMemo(
    () => branchOverviewData?.find((item) => item.accountingLocationID === accountingLocationID),
    [accountingLocationID, branchOverviewData],
  );

  const otherAccountingLocationsGrouped: AccountingLocationGroups = useMemo(() => {
    const otherAccountingLocations = branchOverviewData?.filter(
      (item) => item.accountingLocationID !== accountingLocationID,
    );
    return groupBranchOverviewData(otherAccountingLocations);
  }, [accountingLocationID, branchOverviewData]);

  const [activeTab, setActiveTab] = useState<keyof AccountingLocationGroups | null>(null);
  const [selectedOtherAccountingLocation, setSelectedOtherAccountingLocation] =
    useState<BranchCashOverview | null>(null);
  const [selectedAmount, setSelectedAmount] =
    useState<CurrencyInputOnChangeValues>(initialSelectedAmount);
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);
  const { userName } = useAppSelector((root) => root.userName);
  const branchDetails = useAppSelector((root) => root.branchDetails);

  const tabs = useMemo(
    () => tabLabels.filter(({ value }) => otherAccountingLocationsGrouped?.[value]?.length),
    [otherAccountingLocationsGrouped],
  );

  useEffect(() => {
    setIsBackButtonActive(false);

    return () => {
      setIsBackButtonActive(true);
    };
  }, [setIsBackButtonActive]);

  useEffect(() => {
    if (!branchOverviewData?.length || !tabs?.length) return;

    // If activeTab hasn't been set, set it to a sensible default
    setActiveTab((prev) => prev ?? tabs[0].value);

    if (!state?.transferTarget?.accountingLocationID) return;

    // Below sets the selected accounting location and active tab if the transfer target has been specified in the navigation state
    const _selectedOtherAccountingLocation = branchOverviewData?.find(
      (item) => item.accountingLocationID === state.transferTarget.accountingLocationID,
    );

    if (!_selectedOtherAccountingLocation) return;

    setSelectedOtherAccountingLocation(_selectedOtherAccountingLocation);
    setActiveTab(_selectedOtherAccountingLocation.accountingLocationType);
  }, [branchOverviewData, state?.transferTarget?.accountingLocationID, tabs]);

  const invalidAccountingLocation = useMemo(() => {
    if (!branchOverviewData) {
      return false;
    }

    return (
      branchOverviewError ||
      !branchOverviewData.find((item) => item.accountingLocationID === accountingLocationID)
    );
  }, [accountingLocationID, branchOverviewData, branchOverviewError]);

  return {
    fadCode,
    userName,
    accountingLocationID,
    accountingLocation,
    selectedOtherAccountingLocation,
    setSelectedOtherAccountingLocation,
    otherAccountingLocationsGrouped,
    branchOverviewData,
    isBranchOverviewLoading,
    invalidAccountingLocation,
    selectedAmount,
    setSelectedAmount,
    branchDetails,
    receiptPrinterService,
    activeTab,
    setActiveTab,
    tabs,
  };
};
