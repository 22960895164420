import { TaskData } from "@bbo/api/generator";
import { formatDateYearMonthDay } from "@bbo/utils/dateTimeFormatter";
import { SyntheticEvent } from "react";

const statusToClassMapping = {
  pending: "Pending",
  due: "Due",
  overdue: "Overdue",
  late: "Late",
  completed: "Completed",
  incomplete: "Incomplete",
};

const getClassName = (status) => {
  if (!status) return "";
  status = status.toLowerCase();

  const className = statusToClassMapping[status];
  return className || "";
};

const statusesToIgnore = ["complete", "completed", "incomplete"];

export const TasksData = ({
  data,
  onCashFlow,
}: {
  onCashFlow: (item: TaskData) => (e: SyntheticEvent) => void;
  data: TaskData[];
}) => {
  return (
    <>
      {data.map((item, index) => {
        return (
          !statusesToIgnore.includes(item.status?.toLowerCase()) && (
            <div key={index} className="flex hover justify-start tabRow">
              <div className="w-2/4 flex items-center tableFont">
                <button
                  data-testid={`taskButton-${index}`}
                  onClick={onCashFlow(item)}
                  className=""
                  data-cy="cashLink">
                  {item.task} | {item.accountingLocationName}
                </button>
              </div>
              <div
                data-testid={`run_date_time-${index}`}
                className="w-1/3 flex items-center tableFont">
                <div> {formatDateYearMonthDay(item.runDateTime)}</div>
              </div>
              <div data-testid={`status-${index}`} className="w-1/3 flex items-center tableFont">
                <div className={getClassName(item.status)}>{item.status}</div>
              </div>
            </div>
          )
        );
      })}
    </>
  );
};
