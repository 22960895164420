import React from "react";

interface DynamoPaginationProps {
  hasPrev: boolean;
  hasNext: boolean;
  onPrev: () => void;
  onNext: () => void;
}

export const DynamoPagination = ({ hasPrev, hasNext, onPrev, onNext }: DynamoPaginationProps) => {
  return (
    <ul
      data-testid="pagination"
      className={`inset-1 pagination-container pagination-bar flex justify-center`}>
      <li
        key="previous"
        className={`pagination-item ${!hasPrev ? "disabled" : ""}`}
        onClick={onPrev}>
        &lt; Previous
      </li>

      <li key="next" className={`pagination-item ${!hasNext ? "disabled" : ""}`} onClick={onNext}>
        Next &gt;
      </li>
    </ul>
  );
};
