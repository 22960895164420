import { BpTpDeclaration, CommonBpTpProps } from "@bbo/interfaces/BpTp";
import { useEffect, useMemo, useState } from "react";
import { listAllAccountingLocationNames, sortHistoryCashCommodityData } from "./BpTpHelpers";
import { DetailOverview } from "./DetailOverview";
import HeaderTabs from "./HeaderTabs";
import "./index.css";

interface DetailViewProps {
  BpTpProps: CommonBpTpProps;
}
// to show dynamic details view list on detail view header tab
const maxRecordsPerPage = window.innerWidth / 225;
export const DetailView = ({
  BpTpProps: { fromHistory, cashCommodity, fromDiscrepancyAccount, status },
}: DetailViewProps) => {
  const [selectedTab, setSelectedTab] = useState<string>();
  const [allDetailLists, setAllDetailLists] = useState<string[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [currentDetailViewLists, setCurrentDetailViewLists] = useState<string[]>([]);
  const [totalPages, setTotalPages] = useState<number>();

  const handleSelectTab = ({ tabName }) => {
    setSelectedTab(tabName);
  };

  const selectedValue: BpTpDeclaration = useMemo(() => {
    if (!cashCommodity && !cashCommodity?.accountingLocations && !selectedTab) return null;
    const getSelectedItem = Object.values(cashCommodity?.accountingLocations)?.find(
      (item) => item.accountingLocationName === selectedTab,
    );
    return getSelectedItem;
  }, [cashCommodity, selectedTab]);

  const selectedKey = useMemo(() => {
    if (!cashCommodity && !cashCommodity?.accountingLocations && !selectedTab) return null;
    const getSelectedKey = Object.keys(cashCommodity?.accountingLocations)?.filter(
      (item) => cashCommodity?.accountingLocations[item] === selectedValue,
    );
    return getSelectedKey[0];
  }, [cashCommodity, selectedTab, selectedValue]);

  useEffect(() => {
    if (!cashCommodity) return;
    const simplifiedList = Object.values(cashCommodity?.accountingLocations);
    const firstID = [];
    const restID = [];
    simplifiedList?.map((List) => {
      if (List.accountingLocationName.includes("Safe")) {
        firstID.push(List);
      } else {
        restID.push(List);
      }
    });
    const simplifiedLocations = [...firstID, ...restID];
    const indexOfLastCashList = currentPage * maxRecordsPerPage;
    const indexOfFirstCashList = indexOfLastCashList - maxRecordsPerPage;
    let headingNames: string[];
    if (fromHistory || fromDiscrepancyAccount) {
      headingNames = sortHistoryCashCommodityData(simplifiedLocations) ?? [];
    } else {
      headingNames = listAllAccountingLocationNames(simplifiedLocations) ?? [];
    }
    setAllDetailLists(headingNames);
    headingNames?.length > 0 && setSelectedTab(headingNames[0]);
    const currentCashList = headingNames?.slice(indexOfFirstCashList, indexOfLastCashList);
    setCurrentDetailViewLists(currentCashList);
    const calculateTotalPage = Math.ceil(headingNames.length / maxRecordsPerPage);
    setTotalPages(calculateTotalPage);
  }, [cashCommodity, currentPage, fromDiscrepancyAccount, fromHistory]);

  const loadNextData = () => {
    const nextPage = currentPage + 1;
    setCurrentPage(nextPage);
  };
  const loadPreviousData = () => {
    const previousPage = currentPage - 1;
    setCurrentPage(previousPage);
  };

  return (
    <div className="w-full px-10 mt-6">
      <div className="flex justify-center">
        <h2 className="testSizeSubHead text-black">Detail View</h2>
      </div>
      <div className="w-full detailViewHeader overflow-y-auto">
        <HeaderTabs
          tabLabels={currentDetailViewLists}
          selectedTab={selectedTab}
          onSelectTab={handleSelectTab}
          loadNextData={loadNextData}
          loadPreviousData={loadPreviousData}
          totalPages={totalPages}
          currentPage={currentPage}
        />
        <DetailOverview
          status={status}
          selectedValue={selectedValue}
          fromHistory={fromHistory}
          fromDiscrepancyAccount={fromDiscrepancyAccount}
          selectedKey={selectedKey}
        />
      </div>
    </div>
  );
};
