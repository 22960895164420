import { useGetCashDeclarationHistoryId } from "@bbo/api/generator";
import { useMemo } from "react";
import { useParams } from "react-router-dom";

export const useGetAccountingLocationByDeclarationID = () => {
  const { declarationID } = useParams();

  const data = useGetCashDeclarationHistoryId(encodeURIComponent(declarationID));

  const cashItemsArr = useMemo(
    () => (data.data?.cashItems ? Object.keys(data.data.cashItems) : null),
    [data.data?.cashItems],
  );

  return { ...data, cashItemsArr };
};
