import { useGlobalState } from "@bbo/lib";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
export const BookingDetails = () => {
  const [BookingFormEnable, setBookingFormEnable] = useGlobalState("BookingFormEnable");
  const handleChange = (): void => {
    setBookingFormEnable({ bookingFormEnable: false });
  };
  return (
    <>
      <div className="grid mt-3">
        <label className="standard-label font-bold ml-8">Order Number</label>

        <form>
          <div className="mx-8">
            <input
              className="movementInput md:w-full mt-3"
              spellCheck="false"
              placeholder="Please enter the order number"
              type="text"
              onChange={() => handleChange()}
              autoFocus
            />
          </div>
          <div className="flex justify-center mt-10">
            <button
              className="action-button button-red"
              disabled={BookingFormEnable.bookingFormEnable}>
              Enter
            </button>
          </div>
        </form>
      </div>
    </>
  );
};
