/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  GetPouchResponseResponse,
  BboPouchManagementBadRequestResponse,
  UnauthorizedResponse,
  BboPouchManagementForbiddenResponse,
  BboPouchManagementFailureResponseResponse,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get Pouch last status(version V0000000000) record for a pouchID
 * @summary Get Pouch of latest status for a pouchID
 */
export const useGetPouchHook = () => {
  const getPouch = useCustomInstance<GetPouchResponseResponse>();

  return (pouchID: string) => {
    return getPouch({ url: `/bm-pouch-management/v1/pouch/${pouchID}`, method: "get" });
  };
};

export const getGetPouchQueryKey = (pouchID: string) => [
  `/bm-pouch-management/v1/pouch/${pouchID}`,
];

export type GetPouchQueryResult = NonNullable<AsyncReturnType<ReturnType<typeof useGetPouchHook>>>;
export type GetPouchQueryError = ErrorType<
  | BboPouchManagementBadRequestResponse
  | UnauthorizedResponse
  | BboPouchManagementForbiddenResponse
  | BboPouchManagementFailureResponseResponse
>;

export const useGetPouch = <
  TData = AsyncReturnType<ReturnType<typeof useGetPouchHook>>,
  TError = ErrorType<
    | BboPouchManagementBadRequestResponse
    | UnauthorizedResponse
    | BboPouchManagementForbiddenResponse
    | BboPouchManagementFailureResponseResponse
  >,
>(
  pouchID: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<ReturnType<typeof useGetPouchHook>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetPouchQueryKey(pouchID);

  const getPouch = useGetPouchHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetPouchHook>>> = () =>
    getPouch(pouchID);

  const query = useQuery<AsyncReturnType<ReturnType<typeof useGetPouchHook>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!pouchID, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};
