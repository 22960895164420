import Loader from "@bbo/components/Loader";
import Card from "./Card";
import { ManageCashDrawerModal } from "./ManageCashDrawerModal";
import { PrintCashDrawer } from "./PrintCashDrawer";
import { useManageCashDrawer } from "./useManageCashDrawer";

export const ManageCashDrawer = () => {
  const {
    printData,
    setPrintData,
    isPrintError,
    loading,
    associatedCashDrawers,
    unassociatedCashDrawers,
    handleAddClick,
    handleRemoveClick,
    modalConfig,
    setPrintError,
    handleAccountingLocationClick,
  } = useManageCashDrawer();

  return (
    <>
      <h2 className={"accounting-card-label-name mb-1 "}>Counters</h2>
      <div className="w-full accounting-card float-left h-52 overflow-x-scroll whitespace-nowrap">
        {associatedCashDrawers?.map((accountingLocation, index) => {
          return (
            <div className="main widthAccounting mr-8 inline-block" key={index}>
              <Card
                key={index}
                cellClass="balanceLayout "
                accountingLocation={accountingLocation}
                onClick={handleAccountingLocationClick}
              />
            </div>
          );
        })}
      </div>
      <hr className="border-gray-200 border-bottom-none mb-5 w-full" />
      <h2 className={"accounting-card-label-name mb-1 "}>Safe</h2>
      <div className="w-full accounting-card float-left h-52 overflow-x-scroll whitespace-nowrap">
        {unassociatedCashDrawers?.map((accountingLocation, index) => {
          return (
            <div className="main widthAccounting mr-8 inline-block" key={index}>
              <Card
                key={index}
                cellClass="safeBalanceHeight "
                accountingLocation={accountingLocation}
                onClick={handleAccountingLocationClick}
              />
            </div>
          );
        })}
      </div>
      <hr className="border-gray-200 border-bottom-none mb-5 w-full" />
      <div className="whitespace-nowrap w-full  flex ">
        <div
          className="main AddRemoveBtn morebtn justify-center flex-col cursor-pointer items-center flex block  grid-cols-4 gap-5"
          onClick={handleAddClick}>
          <div>
            <i className="fas fa-plus mb-2"></i>
          </div>
          <p> Add New Drawer</p>
        </div>
        <div
          className="main ml-4  AddRemoveBtn morebtn justify-center flex-col cursor-pointer items-center flex block  grid-cols-4 gap-5"
          style={{ pointerEvents: "none", backgroundColor: "#d4d4d4" }} // click disabled as we don't have remove CD API yet
          onClick={handleRemoveClick}>
          <div>
            <i className="fas fa-minus mb-2"></i>
          </div>
          <p>Remove Drawer</p>
        </div>
      </div>
      {!loading && <ManageCashDrawerModal modalConfig={modalConfig} />}
      {loading ? <Loader /> : null}
      {isPrintError && (
        <PrintCashDrawer
          printData={printData}
          onPrintClose={() => {
            setPrintData(null);
            setPrintError(false);
          }}
        />
      )}
    </>
  );
};
