import { usePutStockManagementStockCountStatus } from "@bbo/api/generator";
import Loader from "@bbo/components/Loader";
import { TableCellCallbackParams } from "@bbo/components/table/types";
import { CountedProduct, IEntityData } from "@bbo/features/StockCount/StockCount";
import { useGlobalState } from "@bbo/lib";
import { amountInPence } from "@bbo/utils/currencyFormatter";
import { getValueFromStorage, setValueInStorage } from "@bbo/utils/util";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./index.css";
import { StockCountReviewFooter } from "./StockCountReviewFooter";
import { StockCountReviewHeader } from "./StockCountReviewHeader";
import {
  calculateVariance,
  convertStockCountArrayToObject,
  getStockCountCompleted,
  persistStockCountCompleted,
} from "./stockCountReviewHelpers";
import {
  StockCountReviewCommitPopup,
  StockCountReviewCompletedPopup,
} from "./StockCountReviewModals";
import { StockCountReviewWarningPopup } from "./StockCountReviewModals/StockCountReviewWarningPopup";
import { StockCountReviewTable } from "./StockCountReviewTable";

interface IStockCountData {
  countType: string;
  data: CountedProduct[];
  grapevineReferenceNumber: string;
}

interface IProps {
  entityData: IEntityData;
}
enum Actions {
  IDLE = "IDLE",
  UPDATE = "UPDATE",
  COMMIT = "COMMIT",
}

export const StockCountReview = ({ entityData }: IProps) => {
  const navigate = useNavigate();
  const [showErrorPopup, setShowErrorPopup] = useState(false);
  const [showCommitPopup, setShowCommitPopup] = useState(false);
  const [showCompletedPopup, setShowCompletedPopup] = useState(false);
  const [globalUserName] = useGlobalState("userName");
  const fadcode = globalUserName.attributes["custom:branch_id"];
  const [currentAction, setCurrentAction] = useState<Actions>(Actions.IDLE);
  const [isStockCountCompleted, setIsStockCountCompleted] = useState(getStockCountCompleted());

  const updateStockCountCompleted = (completed: boolean) => {
    persistStockCountCompleted(completed);
    setIsStockCountCompleted(getStockCountCompleted());
  };

  const locationData = {
    location: entityData?.fadcode,
    locationAccount: entityData?.entity,
    locationAccountID: entityData?.entityID,
    locationAccountName: entityData?.entityName,
  };

  const [tableData, setTableData] = useState(
    getValueFromStorage<IStockCountData>("stockProductData"),
  );

  // stockData is an object of type Record<string, CountedProduct> so that we can search
  // the items more efficiently for anyModified. Otherwise this would get quite performance
  // heavy for large stock counts
  const [stockData, setStockData] = useState(convertStockCountArrayToObject(tableData?.data));

  const [tableDataPending, setTableDataPending] = useState(tableData);

  const tableDataWithPendingEnteredQuantity = useMemo(
    () =>
      tableData?.data?.map((item, i) => ({
        ...item,
        enteredQuantity: tableDataPending?.data[i].enteredQuantity,
      })),
    [tableData, tableDataPending],
  );

  const total = useMemo(
    () => tableData?.data?.reduce((total, value) => total + Number(value.varianceAmount), 0),
    [tableData],
  );

  const anyModified = useMemo(
    () =>
      tableDataPending?.data?.filter(
        (item) => item.enteredQuantity !== stockData[item.barcode].enteredQuantity,
      ).length > 0,
    [tableDataPending, stockData],
  );

  const { mutate, isSuccess, error, isError, isLoading } = usePutStockManagementStockCountStatus();
  // grapevineRefNumber to be add in Open.yaml then to use in component (for now it commented for further analysis)
  const handleMutation = (products: CountedProduct[], status: string) => {
    mutate({
      status,
      data: products.map((item) => {
        const { varianceAmount } = calculateVariance(item);
        return {
          accountingLocation: locationData.locationAccount,
          accountingLocationID: locationData.locationAccountID,
          accountinglocationName: locationData.locationAccountName,
          barcode: item.barcode,
          countGroup: item.countGroup,
          currentVariance: varianceAmount,
          description: item.description,
          ean: item.ean,
          fadcode,
          itemID: item.itemID,
          name: item.name,
          packSize: item.packSize,
          quantity: Number(item.enteredQuantity),
          status,
          totalVariance: amountInPence(total / 100),
          unitPrice: item.value,
          value: item.value,
        };
      }),
    });
  };

  useEffect(() => {
    if (isError) {
      setShowErrorPopup(true);
    }
  }, [isError]);

  const handleCellChange = useCallback(
    (row: TableCellCallbackParams<CountedProduct>) => {
      const { data } = row;
      const rowIndex = tableDataPending?.data.findIndex((node) => node.itemID === data.itemID);
      const rowDataPending = tableDataPending.data[rowIndex];

      const validatedEnteredQuantity = Math.max(Number(data.enteredQuantity), 0);

      const updatedRowData: CountedProduct = {
        ...rowDataPending,
        enteredQuantity: validatedEnteredQuantity,
        varianceQuantity: validatedEnteredQuantity - Number(data.systemQuantity),
        varianceAmount: (validatedEnteredQuantity - Number(data.systemQuantity)) * data.value,
      };

      const tableDataPendingClone = JSON.parse(JSON.stringify(tableDataPending));
      tableDataPendingClone.data[rowIndex] = updatedRowData;

      setTableDataPending(tableDataPendingClone);
    },
    [tableDataPending],
  );

  const handleResetCount = () => {
    const resetTableData = getValueFromStorage<IStockCountData>("stockProductData");
    setTableData(resetTableData);
    setTableDataPending(resetTableData);
  };

  const handleCancelCount = () => {
    setValueInStorage("stockProductData", null);
    navigate("../StockCount");
  };

  const updateStorageData = (updatedData: IStockCountData) => {
    setValueInStorage("stockProductData", updatedData);
  };

  const handleUpdateCount = () => {
    const updatedRows = tableDataPending?.data.filter(
      (item) => item.enteredQuantity !== stockData[item.barcode].enteredQuantity,
    );

    setCurrentAction(Actions.UPDATE);

    handleMutation(updatedRows, "update");
  };

  useEffect(() => {
    if (currentAction === Actions.UPDATE && isSuccess) {
      setCurrentAction(Actions.IDLE);
      setTableData({ ...tableDataPending });
      updateStorageData(tableDataPending);
      setStockData(convertStockCountArrayToObject(tableDataPending?.data));
    }
  }, [currentAction, isSuccess, tableDataPending]);

  const handleCommitCount = () => {
    setCurrentAction(Actions.COMMIT);

    handleMutation(tableData?.data, "commit");
  };

  useEffect(() => {
    if (currentAction === Actions.COMMIT && isSuccess) {
      setShowCommitPopup(false);
      setShowCompletedPopup(true);
      updateStockCountCompleted(true);
    }
  }, [currentAction, isSuccess]);

  return (
    <>
      <div>
        <StockCountReviewHeader
          countGroup={tableData?.countType || ""}
          entityName={entityData?.entityName}
          total={total}
          grapevineRefNumber={tableData?.grapevineReferenceNumber}
        />
        <div className="w-full container-margin flex-1">
          <StockCountReviewTable
            heading="Negative Variance"
            onCellChange={handleCellChange}
            rows={tableDataWithPendingEnteredQuantity?.filter((item) => item.varianceAmount < 0)}
            isStockCountCompleted={isStockCountCompleted}
          />
          <StockCountReviewTable
            heading="Positive Variance"
            onCellChange={handleCellChange}
            rows={tableDataWithPendingEnteredQuantity?.filter((item) => item.varianceAmount > 0)}
            isStockCountCompleted={isStockCountCompleted}
          />
          <StockCountReviewTable
            heading="Zero Variance"
            onCellChange={handleCellChange}
            rows={tableDataWithPendingEnteredQuantity?.filter((item) => item.varianceAmount === 0)}
            isStockCountCompleted={isStockCountCompleted}
          />
        </div>

        {!isStockCountCompleted ? (
          <StockCountReviewFooter
            anyModified={anyModified}
            handleCancelCount={handleCancelCount}
            handleCommitCount={() => setShowCommitPopup(true)}
            handleResetCount={handleResetCount}
            handleUpdateCount={handleUpdateCount}
          />
        ) : null}

        {showErrorPopup ? (
          <StockCountReviewWarningPopup
            onConfirm={() => {
              setShowErrorPopup(false);
              setCurrentAction(Actions.IDLE);
            }}
          />
        ) : null}
        {showCommitPopup ? (
          <StockCountReviewCommitPopup
            variance={total}
            onCancel={() => {
              setShowCommitPopup(false);
            }}
            onConfirm={handleCommitCount}
            showGRNMsg={!!tableData?.grapevineReferenceNumber}
          />
        ) : null}
        {showCompletedPopup ? (
          <StockCountReviewCompletedPopup
            onConfirm={() => {
              setShowCompletedPopup(false);
            }}
          />
        ) : null}
      </div>
      {isLoading ? <Loader fixed /> : null}
    </>
  );
};
