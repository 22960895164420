/**
 * Uper left of the table component
 * @param props
 * @returns
 */
// interface iProps {}

export interface PrintConfirm {
  onSuccess?: () => void;
  onCancel?: () => void;
  onReprint?: () => void;
}
const PrintLogs = (props) => {
  const onSuccessHandle = () => {
    if (props.onSuccess) {
      props.onSuccess();
    }
  };
  const onCancelHandle = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };
  const onReprintHandle = () => {
    if (props.onReprint) {
      props.onReprint();
      props.onCancel();
    }
  };

  return (
    <>
      <div className="pouch-preapre-wrapper justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 max-w-xl pouch-prepare-popup confirmSuccessPrintPopupDimension">
          <div className="messageTitle">
            <i className="fas fa-check text-5xl paidTickColor bg-white"></i>
          </div>

          <div className="messageBody">Print Successfull</div>

          <div className="flex items-center gap-5   justify-center">
            <button
              className="cancelBtn"
              type="button"
              value="No"
              data-cy="popupNo"
              onClick={onCancelHandle}>
              Cancel
            </button>

            <button
              className="reprint-btn"
              type="button"
              data-cy="popupNo"
              onClick={onReprintHandle}>
              Reprint
            </button>

            <button
              className="success-btn successful-print-btn"
              type="button"
              value="Yes"
              data-cy="popupYes"
              onClick={onSuccessHandle}>
              Confirm Print Successful
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default PrintLogs;
