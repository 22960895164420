export const cols = [
  {
    headerName: "Date Range",
    field: "",
    filter: "DateRangeWithoutTime",
    type: "string",
    cellClass: "cell-center",
  },
  {
    headerName: "Product Description",
    field: "itemDescription",
    filter: "InputSearch",
    type: "string",
  },
  {
    headerName: "Quantity",
    field: "quantity",
    type: "string",
    tdClass: "text-right",
    headingClass: "text-center",
  },
  {
    headerName: "Value",
    field: "valueInPence",
    type: "string",
    headingClass: "text-center",
    tdClass: "text-right",
  },
];
export const colslot = [
  {
    headerName: "Total",
    field: "Total",
    type: "string",
  },
  {
    headerName: " ",
    field: " ",
    // filter: "InputSearch",
    type: "string",
  },
  {
    headerName: "Quantity",
    field: "quantity",
    type: "string",
  },
  {
    headerName: "Value",
    field: "valueInPence",
    type: "string",
  },
];
