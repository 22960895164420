import { BranchCashOverview } from "@bbo/api/generator";
import link from "@bbo/assets/images/link.svg";
import userCheck from "@bbo/assets/images/user-check.svg";
import PopupConfirm from "@bbo/components/Alert";
import { useState } from "react";
import { getActionList } from "./BranchOverviewProvider";
import { useTableList } from "./useTableList";
import { ActionButtons } from "./ActionButtons";

interface ActionListProps {
  data: BranchCashOverview;
  onClose: () => void;
}

export const ActionList = ({ data, onClose }: ActionListProps) => {
  const { orderedTable } = useTableList(data);
  const [pouchPopup, setPouchPopup] = useState({ isPopupPouch: false, message: "" });
  const actionList = getActionList(data);

  return (
    <div className="justify-center bg-opacity-75 transition-opacity bg-gray-500 items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
      <div className="relative w-auto my-6 mx-auto max-w-3xl">
        <div className="p-6 relative flex flex-col w-full cursor-pointer  bg-white outline-none focus:outline-none">
          <div className="flex items-start justify-between">
            <h3 className="heading-5 font-bold text-color">
              <span className="capitalize">{data.accountingLocationName}</span>
              {data.counterName ? (
                <>
                  <img src={link} className="mx-2 inline" />
                  <span>{data?.counterName}</span>
                </>
              ) : null}
            </h3>
            <button
              className="ml-auto border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
              onClick={onClose}>
              <span className="text-black opacity-1 w-6 text-3xl block outline-none focus:outline-none">
                ×
              </span>
            </button>
          </div>
          {data?.counterName && (
            <div className="second-heading h-10 truncate">
              {/* API needs updating to return the username */}
              <img src={userCheck} className="mr-2" /> <span>{""}</span>
            </div>
          )}
          <hr className="border-gray-200 border-bottom-none my-3.5" />

          <div className="relativeflex-auto">
            <div className="w-full accounting-card">
              <div className="float-left w-full">
                <div className="accounting-content">
                  <table className="w-full border-none">
                    <tbody>{orderedTable}</tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="grid grid-cols-2 gap-2">
            <ActionButtons actionList={actionList} data={data} setPouchPopup={setPouchPopup} />
          </div>
          {pouchPopup.isPopupPouch ? (
            <PopupConfirm
              header={pouchPopup.message || ""}
              labelSuccess="Close"
              onSuccess={() => setPouchPopup({ isPopupPouch: false, message: "" })}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
};
