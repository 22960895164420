import { HEADER_CONSTANTS } from "@bbo/constants/HeaderConstants";
import { useAppDispatch, useAppSelector, useGlobalState } from "@bbo/lib";
import { useRef, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { logoutFederatedUser } from "../../lib/reduxStore/auth.slice";

export const UserMenu = () => {
  const { username } = useAppSelector((root) => root.auth.authUserData);
  const [show, setShow] = useState(false);
  const [, setIsUserLoggedIn] = useGlobalState("isUserLoggedIn");
  const refDrawer = useRef<HTMLDivElement>(null);
  const place = { width: "145px" };
  const location = useLocation();
  const dispatch = useAppDispatch();

  // TODO: check this logic - Auth related data should come from authUserData
  // const getAccessToken = useCallback(async () => {
  //   const userInfo = await Auth.currentAuthenticatedUser();
  //   dispatch(
  //     setUserName(
  //       userInfo?.signInUserSession?.idToken?.payload.name ||
  //         userInfo?.signInUserSession?.idToken?.payload.given_name,
  //     ),
  //   );
  // }, [dispatch]);

  const handleLogout = async () => {
    if (location.pathname === "/stockCount" || location.pathname === "stockCountReview") {
      setIsUserLoggedIn({ IsLoggedIn: false });
    }
    dispatch(logoutFederatedUser());
  };

  const handleMyProfile = async () => {
    // it will redirect to branch hub page
    window.open(`${process.env.REACT_APP_BRANCH_HUB_PROFILE}`, `_blank`);
  };

  const handleClick = () => {
    setShow((v) => !v);
  };

  return (
    <div>
      <div id="user-menu" className="view-user-menu">
        <span className="header-user-name text-white text-lg font-bold">{username}</span>
        <div onClick={handleClick} className="user-profile-icon">
          <i className="fa fa-user-circle fa-2x text-white" aria-hidden="true"></i>
        </div>
      </div>
      {show && (
        <div
          ref={refDrawer}
          id="sidebar"
          style={place}
          className={
            "absolute z-40 transform bg-white transition-transform duration-200 ease-in-out translate-x-0"
          }>
          <ul className="headerMenu">
            <li>
              <Link to="/" onClick={handleMyProfile} data-cy="profile">
                {HEADER_CONSTANTS.MY_PROFILE}
              </Link>
            </li>
            <li>
              <Link to={location.pathname} onClick={handleLogout} data-cy="logout">
                {HEADER_CONSTANTS.LOG_OUT}
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
