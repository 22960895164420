/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { BranchDetails } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get branch details.
 * @summary This endpoint is used to get the branch details
 */
export const useGetBranchDataHook = () => {
  const getBranchData = useCustomInstance<BranchDetails>();

  return (fadcode: string) => {
    return getBranchData({ url: `/iam-management/branchDetails/${fadcode}`, method: "get" });
  };
};

export const getGetBranchDataQueryKey = (fadcode: string) => [
  `/iam-management/branchDetails/${fadcode}`,
];

export type GetBranchDataQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetBranchDataHook>>
>;
export type GetBranchDataQueryError = ErrorType<void>;

export const useGetBranchData = <
  TData = AsyncReturnType<ReturnType<typeof useGetBranchDataHook>>,
  TError = ErrorType<void>,
>(
  fadcode: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetBranchDataHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetBranchDataQueryKey(fadcode);

  const getBranchData = useGetBranchDataHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetBranchDataHook>>> = () =>
    getBranchData(fadcode);

  const query = useQuery<AsyncReturnType<ReturnType<typeof useGetBranchDataHook>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!fadcode, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};
