import { PouchSubType, PouchType, usePouchPreparationSuspend } from "@bbo/api/generator";
import { SCREENS } from "@bbo/constants";
import { Dispatch, SetStateAction, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import {
  calculateTotalValueInPence,
  convertToPouchDetailsItems,
} from "../../utils/pouch/pouchHelpers";
import {
  PouchPreparationModalConfig,
  pouchPreparationModalConfigs,
} from "./pouchPreparationModalConfig";
import { PouchDetailsItemsInfo } from "@bbo/utils";

export const usePouchPrepSuspend = (
  pouchID: string,
  pouchSubType: PouchSubType,
  suspendCount: number | undefined,
  setModalConfig: Dispatch<SetStateAction<PouchPreparationModalConfig>>,
) => {
  const navigate = useNavigate();

  const {
    data: suspendPouchResponse,
    mutate: suspendPouch,
    status: suspendPouchStatus,
    isSuccess: suspendPouchSuccess,
    isError: suspendPouchError,
    reset,
  } = usePouchPreparationSuspend();

  useEffect(() => {
    if (!suspendPouchSuccess) return;

    navigate(SCREENS.POUCH_PREPARATION);
  }, [navigate, suspendPouchSuccess]);

  useEffect(() => {
    if (!suspendPouchError) return;

    setModalConfig({
      ...pouchPreparationModalConfigs.suspendPouchFailed,
      onPrimaryButtonClick: () => {
        setModalConfig(null);
      },
    });
  }, [setModalConfig, suspendPouchError]);

  const handleSuspendPouch = (updatedDenominationLadder: PouchDetailsItemsInfo) => {
    suspendPouch({
      pouchID,
      data: {
        pouchID,
        pouchType: PouchType.cash,
        pouchSubType,
        totalValue: calculateTotalValueInPence(updatedDenominationLadder),
        items: convertToPouchDetailsItems(updatedDenominationLadder),
      },
    });
  };

  const openSuspendPouchModal = (updatedDenominationLadder: PouchDetailsItemsInfo) => {
    setModalConfig({
      ...pouchPreparationModalConfigs.suspendPouch,
      onPrimaryButtonClick: () => handleSuspendPouch(updatedDenominationLadder),
      onSecondaryButtonClick: () => {
        setModalConfig(null);
      },
    });
  };

  const openSuspendLimitModal = () => {
    setModalConfig({
      ...pouchPreparationModalConfigs.suspendPouchLimit,
      onPrimaryButtonClick: () => {
        setModalConfig(null);
      },
    });
  };

  const handleSuspendCheck = (updatedDenominationLadder: PouchDetailsItemsInfo) => {
    // Pouches can only be suspended twice
    // suspendCount will be undefined when pouch hasn't been suspended yet
    if (!suspendCount || suspendCount < 2) {
      openSuspendPouchModal(updatedDenominationLadder);
      return;
    }
    openSuspendLimitModal();
  };

  return {
    openSuspendPouchModal,
    handleSuspendPouch,
    handleSuspendCheck,
  };
};
