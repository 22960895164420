import { Paper } from "@material-ui/core";
import "./BurglaryRobberyCompleteButton.css";

export const BurglaryRobberyCompleteButton = ({ burglaryState }) => {
  return (
    <Paper component="footer" square variant="outlined">
      <div className="butn">
        <button
          disabled={!burglaryState}
          className="burglary-button"
          onClick={() => console.log("BR")}>
          Complete
        </button>
      </div>
    </Paper>
  );
};
