import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface uncountedCurrencies {
  currencyCountReviewData: {
    currencyName: string;
    systemQuantity: string;
    enteredQuantity: string;
    currencyVariance: string;
    sterlingValue: string;
    adjustments: string;
    varianceType: string;
  };
  currencyOrderCode: {
    ordercode: string;
  };
}
const initialState: { countedCurrencies: string[]; uncountedCurrencies: uncountedCurrencies[] } = {
  countedCurrencies: [],
  uncountedCurrencies: [],
};

export const countedCurrencies = createSlice({
  name: "countedCurrencies",
  initialState,
  reducers: {
    setCountedCurrencies: (state, action: PayloadAction<string>) => {
      state.countedCurrencies = [...state.countedCurrencies, action.payload];
    },
    setUncountedCurrencies: (state, action: PayloadAction<uncountedCurrencies[]>) => {
      state.uncountedCurrencies = action.payload;
    },
  },
});

export const { setCountedCurrencies, setUncountedCurrencies } = countedCurrencies.actions;

export default countedCurrencies.reducer;
