import { HEADER_CONSTANTS } from "@bbo/constants/HeaderConstants";
import { SideMenu } from "@bbo/features/SideMenu/SideMenu";
import { UserMenu } from "@bbo/features/UserMenu/UserMenu";
import { useAppSelector } from "@bbo/lib";
import {
  deepLink,
  getValueFromStorage,
  isDeviceMobile,
  isDeviceMobileElectron,
} from "@bbo/utils/util";
import { useDispatch } from "react-redux";
import { useNavigate, useSearchParams } from "react-router-dom";
import Logo from "../../assets/images/logo.png";

type Header1 = {
  title: string;
  includeBackBtn?: boolean;
};

export const Header = ({ title, includeBackBtn }: Header1) => {
  const { userName, smartID } = useAppSelector((root) => root.userName);
  const [searchParams] = useSearchParams();
  const { isCtAccess } = useAppSelector((root) => root.counterTerminalAccess);
  const { entityName } = useAppSelector((root) => root.selectedTask);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  //URL to open counter terminal when user comes from counter terminal simulator
  let CT_URL = "https://simulator.spm-dev.com/apps/counterterminal/index.html";
  const CT_URL_LINK = "po://ct";
  if (isDeviceMobile()) {
    const refreshToken = localStorage.getItem(
      `CognitoIdentityServiceProvider.3bug83m4vi0j030qikm703k87n.${userName}.refreshToken`,
    );
    CT_URL = deepLink.home(refreshToken);
  }

  const LogoIcon = () => (
    <img className="ml-7 rounded-full" src={Logo} width="59" height="44" alt="User" />
  );

  const handleBackPressed = () => {
    navigate(-1);
  };
  const getForgerockTokenKeys = (storage: Record<string, string>) => {
    const startsWithString = `CognitoIdentityServiceProvider.${
      process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID || ""
    }`;

    return Object.keys(storage).filter((key) => key.startsWith(startsWithString));
  };

  const removeFederatedTokens = () => {
    getForgerockTokenKeys(localStorage).forEach((key) => {
      localStorage.removeItem(key);
    });
  };

  return (
    <>
      <header className="fixed w-full top-0 head-background z-30">
        <div className="bg-blue-primary px-4 lg:pl-4 pr-8">
          <div className="flex items-center justify-between h-16 -mb-px">
            <div className="flex">
              <SideMenu />
              <LogoIcon />
            </div>
            <div className="font-bold text-white text-2xl">{title}</div>
            <div className="flex items-center">
              {isCtAccess && (
                <a href={CT_URL} className="text-lg font-bold text-right text-white">
                  {HEADER_CONSTANTS.COUNTER_TERMINAL}
                </a>
              )}

              {getValueFromStorage("navigatedFromCT") && (
                <>
                  <i className="fa fa-angle-left fa-lg  text-white" aria-hidden="true"></i>
                  {isDeviceMobileElectron() ? (
                    <a
                      href={CT_URL_LINK}
                      className="ml-2 text-2xl font-bold text-white"
                      onClick={() => removeFederatedTokens()}>
                      Back to CT
                    </a>
                  ) : (
                    <button
                      className="ml-2 text-2xl font-bold text-white"
                      onClick={() => {
                        window.close();
                        removeFederatedTokens();
                      }}>
                      Back to CT
                    </button>
                  )}
                </>
              )}
              <hr className="w-px h-6 bg-gray-200 mx-3" />
              <UserMenu />
            </div>
          </div>
        </div>
      </header>
      {includeBackBtn && (
        <div className=" text-blue-primary bg-blue-blue20 pl-5 pt-2 text-xl float-left flex items-center">
          <i className=" fa fa-angle-left" aria-hidden="true"></i>
          <button className="testSizeSubHead ml-2 text-2xl font-bold" onClick={handleBackPressed}>
            {HEADER_CONSTANTS.BACK}
          </button>
        </div>
      )}
    </>
  );
};
