import { useGetForexDenomination } from "@bbo/api/generator";
import { useAppDispatch } from "@bbo/lib/hooks/useAppDispatch";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { setCountedCurrencies } from "@bbo/lib/reduxStore/countedCurrencies.slice";
import { getValueFromStorage } from "@bbo/utils/util";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTitle } from "../AuthRoutes/AuthRoutes";
import { getCurrencyConstraints } from "./CurrencyUtils";
import { IDenomination } from "./ForexDenomination";
import { ICurrency } from "./Icurrency";

export const useForexDenomination = (code: string, iSelected: ICurrency) => {
  const [open, setOpen] = useState(false);
  const [denominationState, setDenominationState] = useState(null);
  const [isConfirmEnabled, setConfirmEnabled] = useState(true);
  const [popupTitle, setPopupTitle] = useState("");
  const location = useLocation();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { setTitle, setIsBackButtonActive } = useTitle();
  const [totalData, setTotalData] = useState<IDenomination>({
    cash: "Total",
    cashType: "",
    currency: "",
    value: 0,
  });
  const selectedEntityDetails = useAppSelector((root) => root.selectedTask);
  let pagetitle = "";
  if (
    selectedEntityDetails?.associatedEntityName &&
    selectedEntityDetails?.associatedEntityName !== ""
  ) {
    pagetitle = `Currency Count | ${selectedEntityDetails.entityName} - ${selectedEntityDetails?.associatedEntityName}`;
  } else {
    pagetitle = `Currency Count | ${selectedEntityDetails.entityName}`;
  }
  const fadCode = getValueFromStorage<string>("fadcode");
  const currencyCode = getValueFromStorage<string>("currencyCode");

  const { data, isLoading } = useGetForexDenomination(
    selectedEntityDetails.fadcode || fadCode,
    code || currencyCode,
  );
  const sortByNumber = (a, b) => {
    return parseInt(b.cash) - parseInt(a.cash);
  };

  useEffect(() => {
    setTitle(pagetitle);
    setIsBackButtonActive(false);
    return () => {
      setIsBackButtonActive(true);
    };
  }, [setTitle, pagetitle, setIsBackButtonActive]);
  const handleClickOpen = (rowdata: IDenomination) => {
    switch (code) {
      case "EUR":
        switch (rowdata?.cash.toLowerCase()) {
          case "100.00":
            setPopupTitle(
              "This denomination of note €100 can only be sold if it has come from the cash centre. If It has been part of a buy back, then it needs to be returned",
            );
            break;
          case "200.00":
            setPopupTitle(
              "This denomination of note €200 can only be sold if it has come from the cash centre. If It has been part of a buy back, then it needs to be returned",
            );
            break;
          case "total":
            setPopupTitle(
              "The following note denominations - €200 and €100 can only be sold if it has come from the cash centre. If it has been part of a buy back, then it needs to be returned",
            );
            break;
        }
        break;
      case "USD":
        switch (rowdata?.cash.toLowerCase()) {
          case "100.00":
            setPopupTitle(
              "This denomination of note $100 can only be sold if it has come from the cash centre. If It has been part of a buy back, then it needs to be returned",
            );
            break;
          case "total":
            setPopupTitle(
              "The following note denominations $100 can only be sold if it has come from the cash centre. If it has been part of a buy back, then it needs to be returned",
            );
            break;
        }
        break;
    }
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleCurrencyChange = (e, data) => {
    const { name, value } = e.target;
    let status = null;
    if (name === "tvalue") {
      for (let i = 0; i < denominationState?.length; i++) {
        const { amount, ...rest1 } = getCurrencyConstraints(name, value, denominationState[i].cash);
        if (rest1?.errorMsg === null) {
          status = { value: amount, ...rest1 };
          break;
        }
        status = { value: amount, ...rest1 };
      }
    }
    const { amount, ...rest } = getCurrencyConstraints(name, value, data.cash);
    if (rest?.errorMsg !== null) {
      setConfirmEnabled(true);
    } else {
      setConfirmEnabled(false);
    }
    const { total, list, cashlist } = denominationState.reduce((acc, cur) => {
      cur.changeKey = cur.changeKey || name;
      if (cur.cash === data.cash) {
        cur = {
          ...cur,
          ...rest,
          value: amount,
        };
      }
      acc.cashlist = [...(acc.cashlist || []), cur.cash];
      acc.total = (acc.total || 0) + Number(cur.value);
      acc.list = acc.list ? [...acc.list, cur] : [cur];
      return acc;
    }, {});
    const changeKey = data.changeKey || name;
    if (data.cash === "Total") {
      if (status?.errorMsg !== null) {
        setConfirmEnabled(true);
      } else {
        setConfirmEnabled(false);
      }
      setTotalData({
        ...data,
        ...status,
        changeKey,
      });
    } else {
      setTotalData({ ...totalData, changeKey, value: total });
    }
    setDenominationState(list);
  };

  useEffect(() => {
    const element = denominationState?.filter((item) => item.value !== "" && item.value !== 0);
    if (element?.length === 0 && totalData.value === 0) {
      if (!isConfirmEnabled) {
        const temp = denominationState.map((item) => {
          return { ...item, changeKey: undefined };
        });
        setDenominationState([...temp]);
        setTotalData({
          ...totalData,
          changeKey: undefined,
        });
      }
      setConfirmEnabled(true);
    }
  }, [denominationState, setConfirmEnabled, totalData.value]);

  useEffect(() => {
    if (!isLoading && denominationState === null) {
      const denomination = data[0]?.denomination?.sort(sortByNumber);
      setDenominationState(denomination);
      setTotalData({
        cash: "Total",
        cashType: "",
        currency: "",
        value: 0,
      });
    }
  }, [data, denominationState, isLoading, setDenominationState]);

  const fnBack = () => {
    navigate("/currencyCount", {
      state: { iSelected: iSelected },
    });
  };

  const handleSubmit = () => {
    dispatch(setCountedCurrencies(code));
    navigate("/currencyCount", {
      state: { iSelected: iSelected },
    });
  };

  return {
    open,
    denominationState,
    isConfirmEnabled,
    popupTitle,
    handleClickOpen,
    handleClose,
    handleCurrencyChange,
    totalData,
    data,
    isLoading,
    fnBack,
    handleSubmit,
  };
};
