import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";

export const handleAxiosRequest =
  (axiosInstance: AxiosInstance) => (config: AxiosRequestConfig) => {
    const source = axios.CancelToken.source();
    const promise = axiosInstance({
      ...config,
      cancelToken: source.token,
    }).then((res) => {
      const { data } = res;
      return data;
    });

    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-expect-error
    promise.cancel = () => {
      source.cancel("Query was cancelled by React Query");
    };

    return promise;
  };

export interface AxiosError<T> {
  config: AxiosRequestConfig;
  code?: string;
  request?: unknown;
  response?: AxiosResponse<T>;
}
