import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { Divider, Grid } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import "./currencyCountScreen.css";
import { LoadMore } from "@bbo/components/MoreButton";
interface ICurrencyData {
  ordercode: string;
  name: string;
  balance?: number;
}

interface GenerateTopOnHandCurrenciesProps {
  onHandCurr?: ICurrencyData[];
  currentPage?: number;
  onHandCount?: number;
  fnOpenDenominationLadder?: (code: string, name: string) => void;
  onHandCurrencies?: ICurrencyData[];
  setOnHandCountValue?: (count: number) => void;
}
const GenerateTopOnHandCurrencies = ({
  onHandCurr,
  currentPage,
  fnOpenDenominationLadder,
  onHandCount,
  onHandCurrencies,
  setOnHandCountValue,
}: GenerateTopOnHandCurrenciesProps) => {
  const { countedCurrencies } = useAppSelector((root) => root.countedCurrencies);

  return (
    <>
      {onHandCurr?.map((item, index) => {
        if (index === 0 && currentPage === 1) {
          return (
            <>
              <Grid item md={12} key={`cur-${index}`}>
                <span className="count-part">{"Top Sellers"}</span>
              </Grid>
              <Grid item md={4} key={`full-${item.ordercode}`}>
                <button
                  className="grid-box"
                  onClick={() => {
                    fnOpenDenominationLadder(item.ordercode, item.name);
                  }}>
                  <span className="count-part-content">{item.name}</span>
                </button>
                {countedCurrencies.includes(item.ordercode) && (
                  <div className="tick-container">
                    <CheckCircleIcon className="tick" fontSize="inherit" />
                  </div>
                )}
              </Grid>
            </>
          );
        }
        if (index === 3 && currentPage === 1) {
          return (
            <>
              <Grid item md={12} key={`cur-${index}`}>
                <Divider className="divider" />
                <span className="count-part">{"On Hand Currencies"}</span>
              </Grid>
              <Grid item md={4} key={`full-${item.ordercode}`}>
                <button
                  className="grid-box"
                  onClick={() => {
                    fnOpenDenominationLadder(item.ordercode, item.name);
                  }}>
                  <span className="count-part-content"> {item.name}</span>
                </button>
                {countedCurrencies.includes(item.ordercode) && (
                  <div className="tick-container">
                    <CheckCircleIcon className="tick" fontSize="inherit" />
                  </div>
                )}
              </Grid>
            </>
          );
        }
        if (item.name !== "more" && item.name !== "") {
          return (
            <Grid item md={4} key={`full-${item.ordercode}`}>
              <button
                className="grid-box"
                onClick={() => {
                  fnOpenDenominationLadder(item.ordercode, item.name);
                }}>
                <span className="count-part-content"> {item.name}</span>
              </button>
              {countedCurrencies.includes(item.ordercode) && (
                <div className="tick-container">
                  <CheckCircleIcon className="tick" fontSize="inherit" />
                </div>
              )}
            </Grid>
          );
        }
        if (item.name === "") {
          return <Grid item md={4} key={`full-${item.ordercode}`}></Grid>;
        }
        if (item.name === "more") {
          return (
            <Grid item md={4} key={`cur-${index}`}>
              <LoadMore
                count={onHandCount}
                items={onHandCurrencies}
                setCountfn={setOnHandCountValue}
              />
            </Grid>
          );
        }
      })}
    </>
  );
};
export default GenerateTopOnHandCurrencies;
