import { useEffect, useState } from "react";
import downArrow from "../../assets/images/downArrow.svg";
import rightArrow from "../../assets/images/rightArrow.svg";

export default function CategoryList({ title, content, key, handleSelect, productName }) {
  const [isActive, setIsActive] = useState(false);
  const { items } = content;

  useEffect(() => {
    if (productName) {
      setIsActive(items.find((el) => el.productName === productName || el.barcode === productName));
    }
  }, [productName]);

  return (
    <div key={key} className="">
      <div className=" grid grid-cols-1 bg-gray-50">
        <div
          className={`grid col-span-12 group focus:outline-none ${
            isActive ? "bg-gray-50" : "bg-white border-b"
          }`}
          onClick={() => setIsActive(!isActive)}>
          <div className="grid grid-flow-row-dense grid-cols-12 h-10 px-3 hover:bg-gray-200 cursor-pointer">
            <div className={`col-span-1 ${!isActive ? "pt-3" : "pt-3.5"}`}>
              {!isActive ? (
                <img src={rightArrow} width="10" height="10" alt="Right arrow" />
              ) : (
                <img src={downArrow} width="15" height="15" alt="Down arrow" />
              )}
            </div>
            <div className="col-span-10 pt-2.5 text-gray-500">
              <span>{title}</span>
            </div>
            <div className="col-span-1 rounded-full mt-3 pt-0.5 justify-end text-xs font-medium text-gray-400 text-center align-middle w-11 h-5 bg-gray-100">
              <span>{content.numberOfItems}</span>
            </div>
          </div>
        </div>
        <div className="col-span-12">
          {isActive &&
            items &&
            items.map((item, i: number) => {
              return (
                <div
                  key={i}
                  onClick={() => {
                    handleSelect(item.productName);
                  }}
                  className={
                    !isActive ? "max-h-0 overflow-hidden duration-300" : "group-focus:max-h-40"
                  }>
                  <a
                    className={`${
                      (productName === item.productName || productName === item.barcode) &&
                      "bg-select-product"
                    } flex justify-start h-10 pl-20 pt-3 cursor-pointer text-sm`}>
                    {item.productName}
                  </a>
                </div>
              );
            })}
        </div>
      </div>
    </div>
  );
}
