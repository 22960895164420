import { featureFlags } from "@bbo/constants";

/* eslint-disable no-constant-condition */
const baseApiUrl =
  process.env.REACT_APP_STAGE === undefined
    ? "https://api.spm-dev.com"
    : `https://api.spm-${process.env.REACT_APP_STAGE}.com`;
const baseDomainUrl =
  process.env.REACT_APP_STAGE === undefined
    ? "http://localhost:8080"
    : `https://branch-back-office.spm-${process.env.REACT_APP_STAGE}.com/apps`;

const redirectSignInUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : process.env.REACT_APP_REDIRECT_SIGN_IN;

const redirectSignOutUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : process.env.REACT_APP_REDIRECT_SIGN_OUT;

export const forgerockConfig = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
    oauth: {
      domain: process.env.REACT_APP_DOMAIN,
      scope: ["openid", "profile"],
      redirectSignIn: redirectSignInUrl,
      redirectSignOut: redirectSignOutUrl,
      responseType: "code",
    },
  },
  Storage: {
    AWSS3: {
      bucket: process.env.REACT_APP_S3_BUCKET, //REQUIRED -  Amazon S3 bucket name
    },
  },
};

export const cognitoConfig = {
  Auth: {
    mandatorySignIn: true,
    region: process.env.REACT_APP_REGION,
    userPoolId: process.env.REACT_APP_USER_POOL_ID,
    userPoolWebClientId: process.env.REACT_APP_USER_POOL_WEB_CLIENT_ID,
  },
};

export const apiConfig = {
  baseApi: baseApiUrl,
  api: `${baseApiUrl}`,
  isProduction: process.env.REACT_APP_STAGE === "prod",
  isDevelopment: process.env.REACT_APP_STAGE === "dev",
  awsBranchBackOfficeConfig: featureFlags.shouldUseFederatedSignIn
    ? forgerockConfig
    : cognitoConfig,
};

export default apiConfig;
