import { PouchStatus, PouchSubType, PouchType } from "@bbo/api/generator";
import { Error, Response, TransferCore } from "@bbo/api/generatorNoFadcode";
import { ErrorType } from "@bbo/api/generatorNoFadcode/mutator/useCustomInstanceNoFadcode";
import { UseMutateFunction } from "@tanstack/react-query";
import { convertToTransferPayloadParams } from "../../utils/pouch/pouchHelpers";
import { useAppSelector } from "@bbo/lib";
import { Dispatch, SetStateAction, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { PouchDetailsItemsInfo } from "@bbo/utils";
import {
  PouchPreparationModalConfig,
  pouchPreparationModalConfigs,
} from "./pouchPreparationModalConfig";

export const usePouchPrepSubmit = (
  pouchID: string,
  denominationLadder: PouchDetailsItemsInfo,
  pouchSubType: PouchSubType,
  setModalConfig: Dispatch<SetStateAction<PouchPreparationModalConfig>>,
  setTransactionSubmissionType: Dispatch<SetStateAction<PouchStatus>>,
  postPreparedPouch: UseMutateFunction<
    Response,
    ErrorType<void | Error>,
    {
      data: TransferCore;
    },
    unknown
  >,
) => {
  const navigate = useNavigate();
  const { smartid, fadCode, username } = useAppSelector((rootState) => rootState.auth.authUserData);
  const { branch_name: branchName, branch_address: branchAddress } = useAppSelector(
    (rootState) => rootState.branchDetails,
  );

  const handleSubmitPouch = (updatedDenominationLadder: PouchDetailsItemsInfo) => {
    postPreparedPouch({
      data: convertToTransferPayloadParams({
        assignedBranchName: branchName,
        fadCode,
        pouchBarcode: pouchID,
        pouchDetailsItemsInfo: updatedDenominationLadder,
        pouchSubType,
        pouchType: PouchType.cash,
        status: "prepared",
        smartID: smartid,
        userName: username,
      }),
    });
    setTransactionSubmissionType(PouchStatus.prepared);
  };

  const openPouchPreparationFailedModal = useCallback(() => {
    setModalConfig({
      ...pouchPreparationModalConfigs.pouchPreparationFailed,
      onPrimaryButtonClick: () => {
        setModalConfig(null);
      },
    });
  }, [setModalConfig]);

  return {
    handleSubmitPouch,
    openPouchPreparationFailedModal,
  };
};
