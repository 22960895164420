import { SETTLED } from "@bbo/features/DiscrepancyAccount/constant";
import { amountSignedPound, currencyWithoutSign } from "@bbo/utils/currencyFormatter";
import { formatDate, formatDateYearMonthDay, getOnlyTime } from "@bbo/utils/dateTimeFormatter";
import { keyPathValue } from "@bbo/utils/util";
import { Column, TableCellCallbackParams, TableRowCallbackParams } from "./types";

/**
 * Table Cell component
 * @param props
 * @returns CellNode JSX Element
 */

//Table Cell Component props model
interface CellNodeProps<T> {
  column: Column;
  data: T;
  rowIndex: number;
  columnIndex: number;
  cellClass?: string;
  tdClass?: string;
  onCellClicked?: (params: TableCellCallbackParams<T>) => void;
  onRowSelection?: (params: TableRowCallbackParams<T>) => void;
  onCellChange?: (params: TableCellCallbackParams<T>) => void;
  isSelected?: boolean;
  isStockReview?: boolean;
  inputValue?: number;
  change?: boolean;
  min?: string;
}

/**
 * Add space to alignmet verticall amount when no signed '+' or '-' avaialable
 * @param params
 * @returns JSX.Element
 */
const rendererValueSpace = (amount: number, flag) => {
  // if (params.column.type === 'currency' && params.data[params.field]) {
  //   if (params.data[params.field].indexOf('+') === -1
  //     && params.data[params.field].indexOf('-') === -1) {
  //     return (<span>&nbsp;&nbsp;&nbsp;</span>)
  //   }
  // }
  if (!flag && amount === 0) {
    return <span>&nbsp;&nbsp;&nbsp;</span>;
  }
  return null;
};

/**
 * Cell classes
 * @param params
 * @returns string class
 */
const cellStyle = (params: any) => {
  if (params.column.cellClass) {
    if (typeof params.column.cellClass === "function") {
      return ` ${params.column.cellClass(params)}`;
    }
    return ` ${params.column.cellClass}`;
  }
  return "";
};

/**
 * Create Cell
 * @param param0 as CellNodeProps object
 * @returns JSX.Element
 */
const CellNode = <T extends { status?: unknown }>({
  column,
  data,
  rowIndex,
  columnIndex,
  ...props
}: CellNodeProps<T>) => {
  const onCellClicked = (params) => {
    if (props.onCellClicked) {
      props.onCellClicked(params);
    }
  };

  /**
   * Checkbox event listener
   * @param e EventListener on change input
   */
  const handleOnChange = (e) => {
    if (props.onRowSelection) {
      const mParams = {
        field: e.target.name,
        checked: e.target.checked,
        rowIndex,
        column,
        data,
      };
      props.onRowSelection(mParams);
    }
  };

  // Input data change
  const handleInput = (e) => {
    if (props.onCellChange) {
      const mParams = {
        field: e.target.name,
        column,
        rowIndex,
        data: { ...data, [column.field]: e.target.value },
      };
      props.onCellChange(mParams);
    }
  };

  /**
   * Render Cell
   * @param params cell data object
   * @returns JSX.Element
   */
  const cellRenderer = (params) => {
    if (column.checkboxSelection) {
      return (
        <input
          name={column.field}
          type="checkbox"
          className="cell-checkbox"
          checked={props.isSelected && data.status !== SETTLED}
          onChange={handleOnChange}
          disabled={data.status === SETTLED}
        />
      );
    }

    if (column.inputBox) {
      const amount = keyPathValue(data, column.field);
      return (
        <input
          key={params.rowIndex}
          min={props.min}
          value={amount ?? ""}
          onChange={handleInput}
          className="px-1 py-1 w-full paidTextArea text-lg inputCash"
          autoComplete="off"
          pattern="[0-9]"
          type="number"
        />
      );
    }

    if (column.dynamicQuantity) {
      const value = keyPathValue(data, column.field || "");
      if (value < 0) {
        return (
          <div className={`TableFontStyle ${cellStyle(params)}`}>
            {isNaN(value) ? " " : -1 * value}
          </div>
        );
      } else {
        return <div className={`TableFontStyle ${cellStyle(params)}`}>{value}</div>;
      }
    }
    if (column.headerName === "Date") {
      const value = keyPathValue(data, column.field || "");
      if (column.field === "" && value === undefined) {
        return <div className={`TableFontStyle ${cellStyle(params)}`}></div>;
      }
      return (
        <div className={`TableFontStyle ${cellStyle(params)}`}>{formatDateYearMonthDay(value)}</div>
      );
    }
    if (column.filter === "datetime") {
      const value = keyPathValue(data, column.field || "");

      return <div className={`TableFontStyle ${cellStyle(params)}`}>{formatDate(value)}</div>;
    }
    if (column.headerName === "Time Range") {
      const value = keyPathValue(data, column.field || "");
      return <div className={`TableFontStyle ${cellStyle(params)}`}>{getOnlyTime(value)}</div>;
    }

    if (props.change && column.dynamicVariance) {
      const amount = keyPathValue(data, column.field || "");
      return (
        <div className={`TableFontStyle ${cellStyle(params)} float-right`}>
          {rendererValueSpace(amount, props.isStockReview)}
          {amountSignedPound(amount)}
        </div>
      );
    }

    if (column.cellRenderer) {
      return <div dangerouslySetInnerHTML={{ __html: column.cellRenderer }} />;
    }

    if (params.column.type === "currency") {
      const amount = keyPathValue(data, column.field);
      return (
        <div className={`TableFontStyle ${cellStyle(params)} float-right`}>
          {amountSignedPound(amount)}
        </div>
      );
    }
    if (params.column.type === "currencyPence") {
      const amount = keyPathValue(data, column.field);
      return (
        <div className={`TableFontStyle ${cellStyle(params)} float-right`}>
          {rendererValueSpace(amount, props.isStockReview)}
          {amountSignedPound(amount)}
        </div>
      );
    }
    if (params.column.type === "currencyPenceWithoutSign") {
      const amount = keyPathValue(data, column.field);
      return (
        <div className={`TableFontStyle ${cellStyle(params)} float-right`}>
          {rendererValueSpace(amount, props.isStockReview)}
          {currencyWithoutSign(amount)}
        </div>
      );
    }

    if (column?.field === "amount") {
      const amount = keyPathValue(data, column.field);
      return (
        <div className={`TableFontStyle ${cellStyle(params)} float-right`}>
          {amountSignedPound(amount)}
        </div>
      );
    }

    return (
      <div className={`TableFontStyle ${cellStyle(params)}`}>
        {keyPathValue(data, column.field) || ""}
      </div>
    );
  };

  const mParams = {
    field: column.field,
    column,
    data,
    rowIndex,
    columnIndex,
  };

  //Render UI
  if (column.cellClickable) {
    return (
      <td
        className={props.tdClass}
        key={`row-${rowIndex}-col-${columnIndex}`}
        onClick={() => onCellClicked(mParams)}>
        <div>{cellRenderer(mParams)}</div>
      </td>
    );
  }

  //Render UI
  return (
    <td className={props.tdClass} key={`row-${rowIndex}-col-${columnIndex}`}>
      {cellRenderer(mParams)}
    </td>
  );
};

export default CellNode;
