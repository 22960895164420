import Loader from "@bbo/components/Loader";
import useBranchLogsApi from "@bbo/lib/hooks/useBranchLogsApi";
import { useGlobalState } from "@bbo/lib/state";
import { getUnixTimeStamp } from "@bbo/utils/dateTimeFormatter";
import { Auth } from "aws-amplify";
import { useEffect, useLayoutEffect, useState } from "react";
import "./index.css";

interface iProps {
  onCancel: (params) => void;
  onSuccess: (params) => void;
}

export const PasswordValidation = (props: iProps) => {
  const [userName] = useGlobalState("userName");
  const [disabledCommit, setDisabledCommit] = useState(true);
  const [passwordAttempt, setPasswordAttempt] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [alertMessage, setAlertMessage] = useState("");
  const [formData, setFormData] = useState({
    username: userName?.username || "",
    password: "",
  });

  const getFromDate = () => {
    const dt = new Date();
    dt.setMinutes(dt.getMinutes() - 10);
    return getUnixTimeStamp(dt);
  };

  // Create Log API calling
  const saveLogRequestParams = {
    logID: userName?.username,
    logType: "password_validation",
    fadcode: userName?.attributes["custom:branch_id"],
    dateTime: getUnixTimeStamp(new Date()),
    username: userName?.attributes["name"],
    createdBySmartID: userName?.username,
    status: "tp-password-validation",
    statusID: "TPPV",
    entity: {},
  };
  const [
    { data: saveLogResponse, status: saveLogStatus, error: saveLogError, statusCode: Code },
    createLog,
    refresh,
  ] = useBranchLogsApi(
    "",
    { fadcode: userName?.attributes["custom:branch_id"] },

    "",
    "POST",
    saveLogRequestParams,
  );

  // Get Log API calling
  const params = {
    fadcode: userName?.attributes["custom:branch_id"],
    entity: "password_validation",
    entityID: userName?.username,
    status: "tp-password-validation",
  };

  const [{ data: attemptData, status: logStatus }, getPasswordLogs, refreshGetLog] =
    useBranchLogsApi("", params, "", "GET");

  useEffect(() => {
    getPasswordLogs("getLogsDetails");
  }, []);

  useEffect(() => {
    if (saveLogStatus === "fetched") {
      refreshGetLog();
    }
  }, [saveLogStatus]);

  useEffect(() => {
    if (saveLogStatus === "fetched" && logStatus === "fetched") {
      setIsLoading(false);
    }
  }, [saveLogStatus, logStatus]);

  useEffect(() => {
    if (formData.password.length === 0 || passwordAttempt > 2) {
      setDisabledCommit(true);
    } else {
      setDisabledCommit(false);
    }
  }, [formData, passwordAttempt]);

  useLayoutEffect(() => {
    if (attemptData && attemptData.length > 0) {
      const mTime = getFromDate();
      const count = attemptData[0].dateTime >= mTime ? attemptData[0].attempt : 0;
      setPasswordAttempt(count);
    }
  }, [attemptData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((data) => ({ ...data, [name]: value }));
  };

  async function handleSubmit(e) {
    e.preventDefault();
    setIsLoading(true);
    try {
      await Auth.signIn(formData.username, formData.password);
      setIsLoading(false);
      if (props.onSuccess) {
        props.onSuccess({
          passwordValidation: false,
          initial: false,
          cancelCommit: false,
        });
      }
    } catch (e) {
      createLog("postLogsDetails");
      if (passwordAttempt > 0) {
        refresh();
      }
      // setPasswordAttempt((value) => value + 1);
      if (formData.username === "" && formData.password === "") {
        setAlertMessage("SmartId and Password cannot be empty");
      } else if (e.message === "Incorrect username or password.") {
        setAlertMessage("Incorrect SmartId or Password.");
      } else if (e.message === "Username cannot be empty") {
        setAlertMessage("SmartId cannot be empty");
      } else if (e.message === "Custom auth lambda trigger is not configured for the user pool.") {
        setAlertMessage("Password cannot be empty");
      } else if (e.message === "Pending sign-in attempt already in progress") {
        console.log("Login inprogress");
      } else {
        setAlertMessage(e.message);
      }
    }
  }

  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel({
        passwordValidation: false,
        initial: false,
        cancelCommit: true,
        cancelFlagName: "passwordValidation",
      });
    }
  };

  const ShowWaring = () => {
    if (passwordAttempt > 2) {
      return (
        <span className="password-warning-label">
          Notification: You have provided the wrong password 3 times, your access has been blocked,
          please try again in 10 minutes.
        </span>
      );
    }

    if (passwordAttempt > 0) {
      return (
        <span className="password-warning-label">
          {`Warning : Your password does not match your Smart ID, please try again. You have ${
            3 - passwordAttempt
          } attempts left`}
        </span>
      );
    }

    return null;
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div className="password-validation-container">
      <div className="flex items-center justify-between">
        <p className="password-validation-content">
          By completing this TP, I certify that this is an accurate reflection of the
          Cash/Forex/Stock in this branch.
        </p>
      </div>
      <form name="form" className="password-validation-form" onSubmit={handleSubmit}>
        {passwordAttempt < 1 && (
          <div className="password-validation-input-container">
            <label className="password-validation-label">Smart ID</label>
            <input
              className="password-validation-input"
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              data-cy="SmartId"
              disabled
            />
          </div>
        )}
        <div className="password-validation-input-container">
          <span className="flex">
            <label className="password-validation-label">Password</label>
          </span>
          <ShowWaring />
          <input
            className={`password-validation-input${
              passwordAttempt > 0 ? " password-validation-input-error" : ""
            }`}
            type="password"
            name="password"
            placeholder="Enter Your Password"
            value={formData.password}
            onChange={handleChange}
            data-cy="password"
          />
        </div>
        <div className="tp-login-btn-container">
          <button className="tp-btn-cancel mr-4" onClick={handleCancel}>
            Cancel
          </button>
          <button className="tp-btn-submit ml-4" disabled={disabledCommit}>
            Complete Trading Period
          </button>
        </div>
      </form>
    </div>
  );
};
