export const WarningPopUp = () => {
  return (
    <div>
      <div className="leading-relaxed text-color px-12 amountHighLevel text-center">
        The item selected is not included <br />
        in this count. <br />
        Please scan next item.
      </div>
    </div>
  );
};
