import { useMemo } from "react";
import Select, { components, GroupBase, StylesConfig } from "react-select";
import { DropdownValue } from "../dynamoTableTypes";

interface SingleDropdownProps {
  placeholder?: string;
  values: DropdownValue[] | undefined;
  onChange: (selectedValue: DropdownValue) => void;
}

const selectAllValue: DropdownValue = {
  label: "All",
  value: "",
  selected: true,
};

const customStyles: StylesConfig<DropdownValue, false, GroupBase<DropdownValue>> = {
  control: (base) => ({
    ...base,
    border: "1px solid #444",
    width: "100%",
    padding: "8px 0px",
    "&:hover": {
      border: "1px solid #444",
    },
  }),
  option: (styles, state) => ({
    ...styles,
    backgroundColor: state.isFocused ? "#9999" : null,
    color: "#444",
  }),
};

const DropdownIndicator = (props) => {
  return (
    components.DropdownIndicator && (
      <components.DropdownIndicator {...props}>
        <div className="arrowSelect"></div>
      </components.DropdownIndicator>
    )
  );
};

export const SingleDropdown = ({ placeholder, values, onChange }: SingleDropdownProps) => {
  const selectedValue = useMemo(
    () => (values && values.find((item) => item.selected)) || null,
    [values],
  );

  const allValues = [selectAllValue, ...values];

  return (
    <Select
      placeholder={placeholder}
      value={selectedValue}
      options={allValues}
      styles={customStyles}
      components={{ IndicatorSeparator: () => null, DropdownIndicator }}
      onChange={onChange}
    />
  );
};
