import { keyPathValue } from "@bbo/utils/util";
import { Column } from "./types";

export const getFilters = <T>(cols: Column[] = [], rows: T[] = []) => {
  const filters = cols.reduce((p, c) => {
    if (c.filter && c.filter === "dropdown") {
      p[c.field] = [];
    }

    return p;
  }, {});

  if (rows && rows.length > 0) {
    rows.map((row) => {
      for (const field in filters) {
        if (filters[field].indexOf(row[field]) === -1) {
          filters[field] = [...filters[field], keyPathValue(row, field)];
        }
      }
    });
  }
  return filters;
};

export const UniqueFilters = (arr = []) => {
  let filteredArray = arr;
  filteredArray = filteredArray.filter((item, index) => {
    return filteredArray.indexOf(item) === index;
  });
  return filteredArray;
};
export const columnStyle = (column) => {
  const headerStyle = {};
  if (column.width) {
    headerStyle["width"] = column.width;
  }
  if (column.minWidth) {
    headerStyle["minWidth"] = column.minWidth;
  }
  if (column.maxWidth) {
    headerStyle["maxWidth"] = column.maxWidth;
  }
  return headerStyle;
};

export const convertUnixTimetoDate = (unixTime: number): string => {
  const dateTime = new Date(unixTime * 1000);
  return (
    dateTime.toLocaleDateString("en-GB", { day: "2-digit" }) +
    "/" +
    dateTime.toLocaleString("en-GB", { month: "2-digit" }) +
    "/" +
    dateTime.toLocaleString("en-GB", { year: "numeric" })
  );
};
