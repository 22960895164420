import React, { SetStateAction } from "react";

interface IType {
  Negative: string | any[];
  Positive: string | any[];
  Zero: string | any[];
}
export default function useCurrencyCountTableHook(rows) {
  const [tableRows, setRows] = React.useState([]);
  const [isAsc, setAsc] = React.useState(false);
  React.useEffect(() => setRows(rows as unknown as SetStateAction<any[]>), [rows]);

  const sortAscending = (new_rows) => {
    new_rows.sort((r1, r2) => {
      const n1 =
        r1.sterlingValue.split("£")[1] > 0
          ? r1.sterlingValue.split("£")[1]
          : -r1.sterlingValue.split("£")[1];
      const n2 =
        r2.sterlingValue.split("£")[1] > 0
          ? r2.sterlingValue.split("£")[1]
          : -r2.sterlingValue.split("£")[1];
      console.log("the values are as follows", n1, n2);

      return n1 - n2;
    });
    setRows([...new_rows]);
    setAsc((state) => !state);
  };

  const sortDescending = (new_rows) => {
    new_rows.sort((r1, r2) => {
      const n1 =
        r1.sterlingValue.split("£")[1] > 0
          ? r1.sterlingValue.split("£")[1]
          : -r1.sterlingValue.split("£")[1];
      const n2 =
        r2.sterlingValue.split("£")[1] > 0
          ? r2.sterlingValue.split("£")[1]
          : -r2.sterlingValue.split("£")[1];

      return n2 - n1;
    });
    setRows([...new_rows]);
    setAsc((state) => !state);
  };
  return { tableRows, isAsc, sortAscending, sortDescending };
}
