import "./BurglaryRobbery.css";

export const BurglaryRobbery = () => {
  return (
    <div className="burglary-input-container">
      <h2 className="burglary-heading">GrapeVine Reference Number</h2>
      <input
        className="burglary-input"
        type={"text"}
        placeholder={"Please enter the Grapevine Reference Number"}
      />
      <br />
      <button disabled={true} className="burglary-button">
        Enter
      </button>
    </div>
  );
};
