/**
 * Alert/Popup view with
 * @param props
 * @returns
 */

interface iProps {
  message?: string;
  fixed?: boolean;
}

const Loader = (props: iProps) => {
  return (
    <div className={props.fixed ? "fixed w-full h-full" : undefined}>
      <div className="loader">
        <i className="fas fa-sync-alt fa-spin fa-2x loaderColor"></i>
        <h2>{props.message || "Loading"}</h2>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </div>
  );
};

export default Loader;
