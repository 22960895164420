import { memo } from "react";
import "./index.css";

interface iProps {
  onPressBack?: () => void;
  onPressCutoffAndDespatch?: () => void;
}

const CutoffDespatch = (props: iProps) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-xl popupmodel">
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4	">
            <div className="flex px-4 items-center justify-start">
              <h2 className="text-blueGray-500 text-lg leading-relaxed heading-5 font-bold text-color">
                Complete Mails Despatch
              </h2>
            </div>
            <div className="px-4">Hand the items and copy of the despatch report to the driver</div>
            <div className="justify-start p-4 rounded-b">
              <button
                className="restNoBtn border-t border-r border-l border-b border-black border-solid bg-transparent-500 text-black active:bg-transparent-600 font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-2 ease-linear transition-all duration-150 hover"
                type="button"
                value="No"
                onClick={() => props.onPressBack()}
                data-cy="popupNo"
                data-testid="back">
                Back
              </button>
              <button
                className="brandRedBtn text-white active:brandRedBtn font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                value="Yes"
                onClick={() => props.onPressCutoffAndDespatch()}
                data-cy="popupYes"
                data-testid="cutoff">
                Cutoff and despatch
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default memo(CutoffDespatch);
