import { PouchStatus, PouchSubType, useGetPouch } from "@bbo/api/generator";
import { usePostTransferTransaction } from "@bbo/api/generatorNoFadcode";
import { ErrorPopup } from "@bbo/components/ErrorPopup";
import { ReactQueryLoader } from "@bbo/components/ReactQueryLoader";
import { SCREENS } from "@bbo/constants";
import {
  Action,
  actionsLookup,
  checkBarcodeBagType,
  isValidPOLBarcode,
  PouchDetailsItemsInfo,
  SpecialActionKey,
} from "@bbo/utils";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  constructDenominationLadderForPouchSubType,
  constructPouchDetailsItemInfo,
} from "../../utils/pouch/pouchHelpers";
import { ActionSelection } from "./ActionSelection";
import { DenominationLadderNew } from "./DenominationLadderNew";
import {
  PouchPreparationModalConfig,
  pouchPreparationModalConfigs,
} from "./pouchPreparationModalConfig";
import { usePouchPrepCancel } from "./usePouchPrepCancel";
import { usePouchPrepPrint } from "./usePouchPrepPrint";
import { usePouchPrepSubmit } from "./usePouchPrepSubmit";
import { usePouchPrepSuspend } from "./usePouchPrepSuspend";
import { usePouchWarningModals } from "./usePouchWarningModals";

export interface PouchPreparationDetailsNavigationState {
  shouldResumeSuspendedPouch?: boolean;
}

export const PouchPreparationDetails = () => {
  const { pouchID } = useParams<{ pouchID: string }>();
  const location = useLocation();
  const { shouldResumeSuspendedPouch } = (location.state ||
    {}) as PouchPreparationDetailsNavigationState;
  const navigate = useNavigate();
  const { data: pouchData, isLoading, status } = useGetPouch(pouchID, { query: { cacheTime: 0 } });

  const pouchDetails = useMemo(() => {
    return {
      ...pouchData,
      pouchID: pouchData?.pouchID ?? pouchID,
    };
  }, [pouchData, pouchID]);

  const [modalConfig, setModalConfig] = useState<PouchPreparationModalConfig | null>(null);
  const [denominationLadder, setDenominationLadder] = useState<PouchDetailsItemsInfo | null>(null);
  const [actions, setActions] = useState<Action[] | null>(null);
  const [action, setAction] = useState<Action | null>(null);
  const bagType = checkBarcodeBagType(pouchID);
  // If the pouch already has a subType use that, otherwise let the user choose from the action buttons
  const pouchSubType =
    pouchDetails?.pouchSubType ||
    (Object.keys(SpecialActionKey).includes(action?.id) ? null : (action?.id as PouchSubType));
  const [transactionSubmissionType, setTransactionSubmissionType] = useState<PouchStatus | null>(
    null,
  );

  const resetAll = () => {
    setModalConfig(null);
    setDenominationLadder(null); // So the denomination ladder isn't pre-filled when navigating away and coming back
    setActions(null);
    setAction(null);
  };

  // const showSuspendButton = !pouchDetails?.suspendCount || pouchDetails?.suspendCount < 3;
  const {
    mutate: postPreparedPouch,
    isSuccess,
    isError,
    data: pouchCreated,
    status: postPreparedPouchStatus,
  } = usePostTransferTransaction();

  const { handleSuspendCheck } = usePouchPrepSuspend(
    pouchID,
    pouchSubType,
    pouchDetails?.suspendCount,
    setModalConfig,
  );

  const { handlePrintReceipt } = usePouchPrepPrint(
    pouchDetails,
    pouchSubType,
    denominationLadder,
    pouchCreated,
    setModalConfig,
  );

  const {
    openCancelPouchModal,
    openHandleDespatchModal,
    openPouchCancelSuccessModal,
    openPouchCancelFailedModal,
  } = usePouchPrepCancel(
    pouchID,
    pouchSubType,
    denominationLadder,
    setModalConfig,
    setTransactionSubmissionType,
    postPreparedPouch,
  );

  const { handleSubmitPouch, openPouchPreparationFailedModal } = usePouchPrepSubmit(
    pouchID,
    denominationLadder,
    pouchSubType,
    setModalConfig,
    setTransactionSubmissionType,
    postPreparedPouch,
  );

  // Memoized so checkWarningModals isn't recreated every re-render
  const configOverrides = useMemo(
    () => ({
      closeModal: {
        onPrimaryButtonClick: () => {
          navigate(SCREENS.POUCH_PREPARATION);
        },
      },
      resumeSuspendedPouch: {
        onSecondaryButtonClick: () => {
          navigate(SCREENS.POUCH_PREPARATION);
          setModalConfig(null);
        },
        onPrimaryButtonClick: () => {
          setAction(actionsLookup[pouchDetails.pouchSubType]);
          setDenominationLadder(constructPouchDetailsItemInfo(pouchDetails.items));
          setModalConfig(null);
        },
      },
    }),
    [navigate, pouchDetails?.items, pouchDetails?.pouchSubType],
  );

  const { checkWarningModals } = usePouchWarningModals(
    pouchDetails,
    status,
    setModalConfig,
    configOverrides,
  );

  useEffect(() => {
    return () => {
      resetAll();
    };
  }, []);

  // Sets up any initial state based on the pouchID and pouchDetails
  useEffect(() => {
    if (status !== "success") return;

    const isBarcodeValid = isValidPOLBarcode(pouchID);
    if (!isBarcodeValid) {
      setModalConfig({
        ...pouchPreparationModalConfigs.invalidBarcode,
        primaryButtonText: "Back",
        onPrimaryButtonClick: () => {
          navigate(-1);
        },
      });
      return;
    }

    // If the resume suspend modal button was pressed on the pouchPreparationScan screen
    if (shouldResumeSuspendedPouch && pouchDetails) {
      setAction(actionsLookup[pouchDetails.pouchSubType]);
      setDenominationLadder(constructPouchDetailsItemInfo(pouchDetails.items));
      return;
    }

    // Triggers one of the warning modal to display if conditions are met
    const warningModalsStatus = checkWarningModals();
    if (warningModalsStatus === "should-return") return;

    if (pouchDetails?.status === "prepared") {
      // Set prepared pouch actions
      setActions([actionsLookup.cancel, actionsLookup.despatch]);
      setDenominationLadder(constructPouchDetailsItemInfo(pouchDetails.items));
      return;
    }

    // If pouch isn't suspended or prepared then
    // set the action buttons to the actions allowed for the pouch subtypes
    setActions(bagType.subTypes.map((subType) => actionsLookup[subType]));
  }, [
    bagType?.subTypes,
    checkWarningModals,
    navigate,
    pouchDetails,
    pouchDetails?.items,
    pouchDetails?.pouchSubType,
    pouchDetails?.status,
    pouchID,
    shouldResumeSuspendedPouch,
    status,
  ]);

  const openPouchPreparationSuccessModal = useCallback(() => {
    setModalConfig({
      ...pouchPreparationModalConfigs.pouchPreparationSuccess,
      onPrimaryButtonClick: handlePrintReceipt,
      onSecondaryButtonClick: () => {
        navigate(SCREENS.POUCH_PREPARATION);
      },
    });
  }, [handlePrintReceipt, navigate]);

  useEffect(() => {
    if (!transactionSubmissionType || !["success"].includes(postPreparedPouchStatus)) return;

    switch (transactionSubmissionType) {
      case "prepared":
        openPouchPreparationSuccessModal();
        break;
      case "cancelled":
        openPouchCancelSuccessModal();
        break;
      default:
        break;
    }

    setTransactionSubmissionType(null);
  }, [
    openPouchCancelSuccessModal,
    openPouchPreparationSuccessModal,
    postPreparedPouchStatus,
    transactionSubmissionType,
  ]);

  useEffect(() => {
    if (!transactionSubmissionType || !["error"].includes(postPreparedPouchStatus)) return;

    switch (transactionSubmissionType) {
      case "prepared":
        openPouchPreparationFailedModal();
        break;
      case "cancelled":
        openPouchCancelFailedModal();
        break;
      default:
        break;
    }
    setTransactionSubmissionType(null);
  }, [
    openPouchCancelFailedModal,
    openPouchPreparationFailedModal,
    postPreparedPouchStatus,
    transactionSubmissionType,
  ]);

  const handleActionClick = (action: Action) => {
    switch (action.id) {
      case "cancel":
        openCancelPouchModal();
        break;
      case "despatch":
        openHandleDespatchModal();
        break;
      default: {
        setAction(action);
        const subType = action.id as PouchSubType;
        setDenominationLadder(constructDenominationLadderForPouchSubType(subType));
        break;
      }
    }
  };

  const handleLimitExceeds = useCallback(() => {
    setModalConfig({
      ...pouchPreparationModalConfigs.limitExceeds,
      onPrimaryButtonClick: () => {
        setModalConfig(null);
      },
    });
  }, []);

  const handleLeavePouchPreparation = () => {
    setModalConfig(() => ({
      ...pouchPreparationModalConfigs.leavePouchPreparation,
      onPrimaryButtonClick: () => {
        navigate(SCREENS.POUCH_PREPARATION);
      },
      onSecondaryButtonClick: () => {
        setModalConfig(null);
      },
    }));
  };

  return (
    <>
      <ReactQueryLoader loading={!pouchDetails && isLoading} />

      <div className="containerMain">
        <div className="w-full items-start p-4 transferOut-height overflow-x-auto">
          <div className="w-full grid grid-cols-1 gap-5 lg:grid-cols-2 lg:gap-5 h-full">
            {!denominationLadder || ["prepared", "cancelled"].includes(pouchDetails?.status) ? (
              <ActionSelection actions={actions} onClick={handleActionClick} />
            ) : (
              <DenominationLadderNew
                pouchSubType={pouchSubType}
                denominationLadder={denominationLadder}
                setDenominationLadder={setDenominationLadder}
                handleSuspendCheck={handleSuspendCheck}
                handleLimitExceeds={handleLimitExceeds}
                handleLeavePouchPreparation={handleLeavePouchPreparation}
                handleSubmitPouch={handleSubmitPouch}
              />
            )}
          </div>
        </div>
      </div>

      {/* This has evolved into being more of a standard modal, need to rename */}
      {modalConfig ? (
        <ErrorPopup
          popupType={modalConfig.modalType}
          messageHeading={modalConfig.heading}
          messageBody={modalConfig.message}
          labelSuccess={modalConfig.primaryButtonText}
          labelCancel={modalConfig.secondaryButtonText}
          onSuccess={modalConfig.onPrimaryButtonClick}
          onCancel={modalConfig.onSecondaryButtonClick}
        />
      ) : null}
    </>
  );
};
