import { CashBalancePayload, CashBalanceStatus, usePostCashDeclaration } from "@bbo/api/generator";
import { useGetList } from "@bbo/api/generator/endpoints/cash-management-api";
import { SCREENS } from "@bbo/constants";
import { CURRENCY_ITEM_ID } from "@bbo/constants/currencyItemID";
import { CashDetailContext } from "@bbo/context";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { useGlobalState } from "@bbo/lib/state";
import { inputInPence } from "@bbo/utils/currencyFormatter";
import { useCallback, useContext, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

interface requestData {
  branchId?: string;
  operationType?: string;
  entityID?: string;
}

export const useAdhocBalance = () => {
  const navigate = useNavigate();
  const [adhocData] = useGlobalState("adhocData");
  const [total] = useGlobalState("totalCash");
  const [rollOver, setRollOver] = useState(false);
  const [, setAdhocBalance] = useGlobalState("adhocbalance");
  const [, setTaskFlow] = useState("");
  const [statusRes, setStatusRes] = useState<CashBalanceStatus | "">("");
  const [, setIsbalancecheck] = useGlobalState("Isbalancecheck");

  const [maxLimitCounter] = useGlobalState("maxLimitCounter");
  const [PopupData] = useGlobalState("PopupData"); // TODO: REMOVE
  const [routeState] = useGlobalState("routeState");
  const selectedTask = useAppSelector((root) => root.selectedTask);
  const { cashDetail, setCashDetail, accountingLocationID } = useContext(CashDetailContext);
  const { state } = useLocation();
  const [toDateVariance, setToDateVariance] = useState("");

  const emptyAdhocBalance = {
    entityData: [],
    isSuspendedPouch: false,
    preparedPouches: [],
    suspendedBy: "",
    suspendedPouches: [],
  };
  useEffect(() => {
    if (selectedTask?.task === "Cash Balance") {
      setTaskFlow("cash Balance Task");
    } else if (routeState.RouteState === "TradingPeriodCashBalance") {
      setTaskFlow("TP Balance");
    }
    if (routeState.RouteState === "BalancePeriodCashBalance") {
      setTaskFlow("BP Balance");
    } else setTaskFlow("Adhoc Balance");
  }, [routeState.RouteState, selectedTask]);

  const createCashItemsPayLoad = useCallback(() => {
    const cashItemsPayLoad = {};
    cashDetail?.forEach((cashItem) => {
      if (cashItem?.value !== null && cashItem?.value !== "£0.00") {
        const cashItemQuantity = isNaN(parseInt(`${cashItem.Quantity}`, 10))
          ? 0
          : parseInt(`${cashItem.Quantity}`, 10);

        cashItemsPayLoad[cashItem.itemID] = {
          itemID: cashItem.itemID,
          itemValue: inputInPence(cashItem.value),
          itemQuantity: cashItemQuantity,
          itemDenomination: CURRENCY_ITEM_ID[cashItem.itemID],
        };
      }
    });
    return cashItemsPayLoad;
  }, [cashDetail]);

  // There is a reduce function in adhoc balance line 130 that calcs total value can replace this with that
  const totalValuePayLoad = useCallback(() => {
    let totalValue = 0;
    cashDetail.forEach((cashItem) => {
      if (cashItem?.value) {
        totalValue += inputInPence(cashItem.value);
      }
    });
    return totalValue;
  }, [cashDetail]);

  const saveVarianceRequestParams: CashBalancePayload = useMemo(
    () => ({
      accountingLocationID: accountingLocationID,
      status: statusRes as CashBalanceStatus,
      totalValue: totalValuePayLoad(), //hardcoded for now
      timestamp: Math.round(+new Date() / 1000),
      cashItems: createCashItemsPayLoad(),
    }),
    [accountingLocationID, createCashItemsPayLoad, statusRes, totalValuePayLoad],
  );

  const transferListApiRequest = useMemo(
    () => ({
      branchId: 1,
      operationType: "transfer_out",
      entityID: PopupData?.data.entityID,
    }),
    [PopupData?.data.entityID],
  );

  const resetLadderValue = () => {
    setAdhocBalance({ ...emptyAdhocBalance });
    setCashDetail((prev) => {
      return prev.map((item, index) => ({
        ...item,
        value: "",
        Quantity: "",
        alertCss: "normalCss",
        QalertCss: "normalCss",
        errorMsg: "",
        QerrorMsg: "",
        btnDisable: true,
      }));
    });
  };

  const handleNext = () => {
    setRollOver(false);
    setIsbalancecheck({ Isbalancecheck: false });
    resetLadderValue();

    if (!state?.from) {
      navigate(-1);
    } else {
      navigate(state.from, {
        state: { accountingLocationID },
      });
    }
  };

  const [res, setres] = useState<requestData>();

  const {
    data: CounterList,
    status: CounterListStatus,
    error: CounterListError,
  } = useGetList(res?.branchId, res?.operationType, { entityID: res?.entityID });

  const {
    mutate: saveVarianceCall,
    data: saveVarianceResponse,
    status: saveVarianceStatus,
    error: saveVarianceError,
  } = usePostCashDeclaration();

  const TransferListApiCall = useCallback(
    async (res) => {
      setres(res);
      await CounterList;
    },
    [CounterList],
  );

  const saveVarianceCallApi = useCallback(
    async (res2: CashBalancePayload) => {
      saveVarianceCall({ data: res2 });
    },
    [saveVarianceCall],
  );

  useEffect(() => {
    setToDateVariance(saveVarianceResponse?.TDV);
  }, [saveVarianceResponse?.TDV, setToDateVariance]);

  const handleSaveVariance = useCallback(
    (statusResPassed) => {
      setStatusRes(statusResPassed);
      saveVarianceCallApi({ ...saveVarianceRequestParams, status: statusResPassed });
    },
    [saveVarianceCallApi, saveVarianceRequestParams],
  );

  const handleTransferOut = useCallback(() => {
    TransferListApiCall(transferListApiRequest);
  }, [TransferListApiCall, transferListApiRequest]);

  useEffect(() => {
    if (adhocData.variance === 0) {
      setStatusRes("Accepted");
    }
  }, [adhocData.variance, handleSaveVariance, statusRes]);

  useLayoutEffect(() => {
    if (saveVarianceStatus === "success") {
      if (statusRes === "Recount") {
        setIsbalancecheck({ Isbalancecheck: false });
      } else if (statusRes === "Incomplete") {
        setRollOver(false);
      } else if (adhocData.variance === 0) {
        setRollOver(false);
        setIsbalancecheck({ Isbalancecheck: false });
        navigate(SCREENS.BRANCH_OVERVIEW, { replace: true });
      } else {
        setRollOver(true);
      }
    }
  }, [adhocData.variance, navigate, saveVarianceStatus, setIsbalancecheck, statusRes]);

  useLayoutEffect(() => {
    if (CounterListStatus === "success") {
      navigate("../transferOut", {
        replace: true,
        state: { to: "safe_1", from: res?.entityID },
      });
    }
  }, [CounterListStatus, navigate, res?.entityID]);

  return {
    adhocData,
    PopupData,
    maxLimitCounter,
    rollOver,
    total,
    statusRes,
    navigate,
    routeState,
    handleTransferOut,
    handleNext,
    handleSaveVariance,
    setRollOver,
    resetLadderValue,
    setStatusRes,
    toDateVariance,
  };
};
