import { DescendantContext, DescendantContextProps } from "@bbo/context";
import { PropsWithChildren } from "react";

export const Descendants = ({
  value,
  children,
}: PropsWithChildren<{ value: DescendantContextProps }>) => {
  // On every re-render of children, reset the count
  value.reset();

  return <DescendantContext.Provider value={value}>{children}</DescendantContext.Provider>;
};
