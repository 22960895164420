import { Divider, List, ListItem, ListItemText } from "@material-ui/core";
import { CurrencyCount, CurrencyCountList } from "@bbo/api/generator";

interface GenerateCountTypesProps {
  data?: CurrencyCount;
  handleChange?: (listData: CurrencyCountList) => void;
}
const GenerateCountTypes = ({ data, handleChange }: GenerateCountTypesProps) => {
  return (
    <List component="nav" className="nav-list">
      {data?.currencyCount?.map((cur, i) => (
        <>
          <ListItem
            className="nav-hover"
            key={cur.id}
            button
            onClick={() => {
              handleChange(cur);
            }}>
            <ListItemText>{cur.name}</ListItemText>
          </ListItem>
          <Divider />
        </>
      ))}
    </List>
  );
};
export default GenerateCountTypes;
