/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type { LogsDetails, GetLogsDetailsParams } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * get logs details
 * @summary this endpoints is used to get logs of particular branch
 */
export const useGetLogsDetailsHook = () => {
  const getLogsDetails = useCustomInstance<LogsDetails[]>();

  return (
    fadcode: string,
    entity: string,
    entityID: string,
    status: string,
    params?: GetLogsDetailsParams,
  ) => {
    return getLogsDetails({
      url: `/adhoc_balances/log/${fadcode}/${entity}/${entityID}/${status}`,
      method: "get",
      params,
    });
  };
};

export const getGetLogsDetailsQueryKey = (
  fadcode: string,
  entity: string,
  entityID: string,
  status: string,
  params?: GetLogsDetailsParams,
) => [
  `/adhoc_balances/log/${fadcode}/${entity}/${entityID}/${status}`,
  ...(params ? [params] : []),
];

export type GetLogsDetailsQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetLogsDetailsHook>>
>;
export type GetLogsDetailsQueryError = ErrorType<void>;

export const useGetLogsDetails = <
  TData = AsyncReturnType<ReturnType<typeof useGetLogsDetailsHook>>,
  TError = ErrorType<void>,
>(
  fadcode: string,
  entity: string,
  entityID: string,
  status: string,
  params?: GetLogsDetailsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetLogsDetailsHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLogsDetailsQueryKey(fadcode, entity, entityID, status, params);

  const getLogsDetails = useGetLogsDetailsHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetLogsDetailsHook>>> = () =>
    getLogsDetails(fadcode, entity, entityID, status, params);

  const query = useQuery<AsyncReturnType<ReturnType<typeof useGetLogsDetailsHook>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(fadcode && entity && entityID && status), ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};
