/**
 * Uper left of the table component
 * @param props
 * @returns
 */
interface iProps {
  title?: string;
  showResetFilter?: boolean;
  onResetAllFilters?: () => void;
  isDiscrpencyTable?: boolean;
}

const ResetBtn = (props: iProps) => {
  const handleResetAllFilters = () => {
    if (props.onResetAllFilters) {
      props.onResetAllFilters();
    }
  };

  if (props.showResetFilter) {
    return (
      <div
        className={`${
          props.isDiscrpencyTable ? "w-full text-right divDummy" : "w-full text-right mb-1 divDummy"
        }`}>
        <button
          type="button"
          className={`${props.isDiscrpencyTable ? "link-button-discrepancy" : "link-button"}`}
          onClick={handleResetAllFilters}>
          Reset All Filters
        </button>
      </div>
    );
  }

  return props.title && <div className="w-full text-right mb-5 divDummy">{props.title}</div>;
};

export default ResetBtn;
