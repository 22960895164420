import { Item, PouchDetailsItems, PouchSubType } from "@bbo/api/generator";
import { TRANSACTION_NOTE_COIN_ITEM_ID } from "../transferPayload";
import { PouchSubTypeDenominationLadder } from "./denominationLadderHelpers";

export type BagTypeKeys = "01" | "03" | "05" | "08" | "31" | "32" | "57" | "58";

export interface BagType {
  pouchType: "coins" | "notes";
  comment: string; // Taken word for word from: https://pol-jira.atlassian.net/l/cp/ffqeQGRh
  subTypes: PouchSubType[];
  deprecated?: boolean;
}

export enum SpecialActionKey {
  "cancel" = "cancel",
  "despatch" = "despatch",
}

export type ActionKey = PouchSubType | SpecialActionKey;

export interface Action {
  id: ActionKey;
  label: string;
}

export const actionsLookup: Record<ActionKey, Action> = {
  non_useable_coins: {
    id: "non_useable_coins",
    label: "Non Useable Coins",
  },
  non_useable_notes: {
    id: "non_useable_notes",
    label: "Non Useable Notes",
  },
  useable_coins_full_bag: {
    id: "useable_coins_full_bag",
    label: "Useable Coins Full Bag",
  },
  useable_coins_part_bag: {
    id: "useable_coins_part_bag",
    label: "Useable Coins Part Bag",
  },
  useable_notes: {
    id: "useable_notes",
    label: "Useable Notes",
  },
  cancel: {
    id: "cancel" as ActionKey,
    label: "Cancel Pouch",
  },
  despatch: {
    id: "despatch" as ActionKey,
    label: "Despatch",
  },
};

export const commonNotesActions: PouchSubType[] = [
  "useable_notes",
  "non_useable_notes",
  "non_useable_coins",
];
export const commonCoinsActions: PouchSubType[] = [
  "useable_coins_full_bag",
  "useable_coins_part_bag",
  "non_useable_coins",
];

export const bagTypes: Record<BagTypeKeys, BagType> = {
  "01": {
    pouchType: "notes",
    subTypes: commonNotesActions,
    comment:
      "Branch to CC pouch (GBP notes) - Old barcode range but occasionally sent to CCs; also barcodes beginning 301999 are used for contingency delivery of GBP notes",
  },
  "03": {
    pouchType: "notes",
    subTypes: commonNotesActions,
    comment: "Branch to CC pouch (GBP notes) - Old barcode range but occasionally sent to CCs",
  },
  "05": {
    pouchType: "notes",
    comment: "Branch to branch pouch - cash - barcode begins 9",
    subTypes: commonNotesActions,
  },
  "08": {
    pouchType: "notes",
    comment: "Branch to CC pouch (GBP notes) - current barcode range",
    subTypes: commonNotesActions,
  },
  "31": {
    pouchType: "coins",
    comment: "External customer coin bag - obsolete - TBC",
    deprecated: true,
    subTypes: ["useable_coins_full_bag"],
  },
  "32": {
    pouchType: "coins",
    comment: "External customer part coin bag - obsolete - TBC",
    deprecated: true,
    subTypes: ["useable_coins_part_bag"],
  },
  "57": {
    pouchType: "coins",
    comment: 'Branch to CC (coin) "Misc label"',
    subTypes: commonCoinsActions,
  },
  "58": {
    pouchType: "coins",
    comment: 'Branch to CC (coin) "Misc label"',
    subTypes: commonCoinsActions,
  },
};

const {
  NOTE_50_POUND_ID,
  NOTE_20_POUND_ID,
  NOTE_10_POUND_ID,
  NOTE_5_POUND_ID,
  COIN_2_POUND_ID,
  COIN_1_POUND_ID,
  COIN_50_PENCE_ID,
  COIN_20_PENCE_ID,
  COIN_10_PENCE_ID,
  COIN_5_PENCE_ID,
  COIN_2_PENCE_ID,
  COIN_1_PENCE_ID,
} = TRANSACTION_NOTE_COIN_ITEM_ID;

const {
  BAG_2_POUND_FULL_ID,
  BAG_1_POUND_FULL_ID,
  BAG_50_PENCE_FULL_ID,
  BAG_20_PENCE_FULL_ID,
  BAG_10_PENCE_FULL_ID,
  BAG_5_PENCE_FULL_ID,
  BAG_2_PENCE_FULL_ID,
  BAG_1_PENCE_FULL_ID,
  BAG_2_POUND_PARTIAL_ID,
  BAG_1_POUND_PARTIAL_ID,
  BAG_50_PENCE_PARTIAL_ID,
  BAG_20_PENCE_PARTIAL_ID,
  BAG_10_PENCE_PARTIAL_ID,
  BAG_5_PENCE_PARTIAL_ID,
  BAG_2_PENCE_PARTIAL_ID,
  BAG_1_PENCE_PARTIAL_ID,
  NOTE_UNUSABLE_50_POUND_ID,
  NOTE_UNUSABLE_20_POUND_ID,
  NOTE_UNUSABLE_10_POUND_ID,
  NOTE_UNUSABLE_5_POUND_ID,
  COIN_UNUSABLE_2_POUND_ID,
  COIN_UNUSABLE_1_POUND_ID,
  COIN_UNUSABLE_50_PENCE_ID,
  COIN_UNUSABLE_20_PENCE_ID,
  COIN_UNUSABLE_10_PENCE_ID,
  COIN_UNUSABLE_5_PENCE_ID,
  COIN_UNUSABLE_2_PENCE_ID,
  COIN_UNUSABLE_1_PENCE_ID,
} = TRANSACTION_NOTE_COIN_ITEM_ID;

export interface ItemInfo extends Item {
  maxQuantityInBag?: number;
  itemType: "fullBag" | "partBag" | "coin" | "note" | "unusableCoin" | "unusableNote";
}

export interface PouchDetailsItemsInfo {
  [key: string]: ItemInfo;
}

const baseItemInfo: PouchDetailsItems = {
  [NOTE_50_POUND_ID]: {
    denomination: 50,
    currency: "GBP",
    materialType: "NOTE",
    itemQuantity: 0,
    itemValue: 0,
  },
  [NOTE_20_POUND_ID]: {
    denomination: 20,
    currency: "GBP",
    materialType: "NOTE",
    itemQuantity: 0,
    itemValue: 0,
  },
  [NOTE_10_POUND_ID]: {
    denomination: 10,
    currency: "GBP",
    materialType: "NOTE",
    itemQuantity: 0,
    itemValue: 0,
  },
  [NOTE_5_POUND_ID]: {
    denomination: 5,
    currency: "GBP",
    materialType: "NOTE",
    itemQuantity: 0,
    itemValue: 0,
  },
  [COIN_2_POUND_ID]: {
    denomination: 2,
    currency: "GBP",
    materialType: "COIN",
    itemQuantity: 0,
    itemValue: 0,
  },
  [COIN_1_POUND_ID]: {
    denomination: 1,
    currency: "GBP",
    materialType: "COIN",
    itemQuantity: 0,
    itemValue: 0,
  },
  [COIN_50_PENCE_ID]: {
    denomination: 50,
    currency: "p",
    materialType: "COIN",
    itemQuantity: 0,
    itemValue: 0,
  },
  [COIN_20_PENCE_ID]: {
    denomination: 20,
    currency: "p",
    materialType: "COIN",
    itemQuantity: 0,
    itemValue: 0,
  },
  [COIN_10_PENCE_ID]: {
    denomination: 10,
    currency: "p",
    materialType: "COIN",
    itemQuantity: 0,
    itemValue: 0,
  },
  [COIN_5_PENCE_ID]: {
    denomination: 5,
    currency: "p",
    materialType: "COIN",
    itemQuantity: 0,
    itemValue: 0,
  },
  [COIN_2_PENCE_ID]: {
    denomination: 2,
    currency: "p",
    materialType: "COIN",
    itemQuantity: 0,
    itemValue: 0,
  },
  [COIN_1_PENCE_ID]: {
    denomination: 1,
    currency: "p",
    materialType: "COIN",
    itemQuantity: 0,
    itemValue: 0,
  },
};

export const denominationLookup: PouchDetailsItemsInfo = {
  /**
   * BASE NOTES/COINS
   */
  [NOTE_50_POUND_ID]: {
    ...baseItemInfo[NOTE_50_POUND_ID],
    itemID: NOTE_50_POUND_ID,
    itemType: "note",
  },
  [NOTE_20_POUND_ID]: {
    ...baseItemInfo[NOTE_20_POUND_ID],
    itemID: NOTE_20_POUND_ID,
    itemType: "note",
  },
  [NOTE_10_POUND_ID]: {
    ...baseItemInfo[NOTE_10_POUND_ID],
    itemID: NOTE_10_POUND_ID,
    itemType: "note",
  },
  [NOTE_5_POUND_ID]: {
    ...baseItemInfo[NOTE_5_POUND_ID],
    itemID: NOTE_5_POUND_ID,
    itemType: "note",
  },
  [COIN_2_POUND_ID]: {
    ...baseItemInfo[COIN_2_POUND_ID],
    itemID: COIN_2_POUND_ID,
    itemType: "coin",
  },
  [COIN_1_POUND_ID]: {
    ...baseItemInfo[COIN_1_POUND_ID],
    itemID: COIN_1_POUND_ID,
    itemType: "coin",
  },
  [COIN_50_PENCE_ID]: {
    ...baseItemInfo[COIN_50_PENCE_ID],
    itemID: COIN_50_PENCE_ID,
    itemType: "coin",
  },
  [COIN_20_PENCE_ID]: {
    ...baseItemInfo[COIN_20_PENCE_ID],
    itemID: COIN_20_PENCE_ID,
    itemType: "coin",
  },
  [COIN_10_PENCE_ID]: {
    ...baseItemInfo[COIN_10_PENCE_ID],
    itemID: COIN_10_PENCE_ID,
    itemType: "coin",
  },
  [COIN_5_PENCE_ID]: {
    ...baseItemInfo[COIN_5_PENCE_ID],
    itemID: COIN_5_PENCE_ID,
    itemType: "coin",
  },
  [COIN_2_PENCE_ID]: {
    ...baseItemInfo[COIN_2_PENCE_ID],
    itemID: COIN_2_PENCE_ID,
    itemType: "coin",
  },
  [COIN_1_PENCE_ID]: {
    ...baseItemInfo[COIN_1_PENCE_ID],
    itemID: COIN_1_PENCE_ID,
    itemType: "coin",
  },
  /**
   * FULL BAGS
   */
  [BAG_2_POUND_FULL_ID]: {
    ...baseItemInfo[COIN_2_POUND_ID],
    itemID: BAG_2_POUND_FULL_ID,
    maxQuantityInBag: 250,
    itemType: "fullBag",
  },
  [BAG_1_POUND_FULL_ID]: {
    ...baseItemInfo[COIN_1_POUND_ID],
    itemID: BAG_1_POUND_FULL_ID,
    maxQuantityInBag: 500,
    itemType: "fullBag",
  },
  [BAG_50_PENCE_FULL_ID]: {
    ...baseItemInfo[COIN_50_PENCE_ID],
    itemID: BAG_50_PENCE_FULL_ID,
    maxQuantityInBag: 500,
    itemType: "fullBag",
  },
  [BAG_20_PENCE_FULL_ID]: {
    ...baseItemInfo[COIN_20_PENCE_ID],
    itemID: BAG_20_PENCE_FULL_ID,
    maxQuantityInBag: 1250,
    itemType: "fullBag",
  },
  [BAG_10_PENCE_FULL_ID]: {
    ...baseItemInfo[COIN_10_PENCE_ID],
    itemID: BAG_10_PENCE_FULL_ID,
    maxQuantityInBag: 1000,
    itemType: "fullBag",
  },
  [BAG_5_PENCE_FULL_ID]: {
    ...baseItemInfo[COIN_5_PENCE_ID],
    itemID: BAG_5_PENCE_FULL_ID,
    maxQuantityInBag: 2000,
    itemType: "fullBag",
  },
  [BAG_2_PENCE_FULL_ID]: {
    ...baseItemInfo[COIN_2_PENCE_ID],
    itemID: BAG_2_PENCE_FULL_ID,
    maxQuantityInBag: 1000,
    itemType: "fullBag",
  },
  [BAG_1_PENCE_FULL_ID]: {
    ...baseItemInfo[COIN_1_PENCE_ID],
    itemID: BAG_1_PENCE_FULL_ID,
    maxQuantityInBag: 2000,
    itemType: "fullBag",
  },
  /**
   * PARTIAL BAGS
   */
  [BAG_2_POUND_PARTIAL_ID]: {
    ...baseItemInfo[COIN_2_POUND_ID],
    itemID: BAG_2_POUND_PARTIAL_ID,
    maxQuantityInBag: 10,
    itemType: "partBag",
  },
  [BAG_1_POUND_PARTIAL_ID]: {
    ...baseItemInfo[COIN_1_POUND_ID],
    itemID: BAG_1_POUND_PARTIAL_ID,
    maxQuantityInBag: 20,
    itemType: "partBag",
  },
  [BAG_50_PENCE_PARTIAL_ID]: {
    ...baseItemInfo[COIN_50_PENCE_ID],
    itemID: BAG_50_PENCE_PARTIAL_ID,
    maxQuantityInBag: 20,
    itemType: "partBag",
  },
  [BAG_20_PENCE_PARTIAL_ID]: {
    ...baseItemInfo[COIN_20_PENCE_ID],
    itemID: BAG_20_PENCE_PARTIAL_ID,
    maxQuantityInBag: 50,
    itemType: "partBag",
  },
  [BAG_10_PENCE_PARTIAL_ID]: {
    ...baseItemInfo[COIN_10_PENCE_ID],
    itemID: BAG_10_PENCE_PARTIAL_ID,
    maxQuantityInBag: 50,
    itemType: "partBag",
  },
  [BAG_5_PENCE_PARTIAL_ID]: {
    ...baseItemInfo[COIN_5_PENCE_ID],
    itemID: BAG_5_PENCE_PARTIAL_ID,
    maxQuantityInBag: 100,
    itemType: "partBag",
  },
  [BAG_2_PENCE_PARTIAL_ID]: {
    ...baseItemInfo[COIN_2_PENCE_ID],
    itemID: BAG_2_PENCE_PARTIAL_ID,
    maxQuantityInBag: 50,
    itemType: "partBag",
  },
  [BAG_1_PENCE_PARTIAL_ID]: {
    ...baseItemInfo[COIN_1_PENCE_ID],
    itemID: BAG_1_PENCE_PARTIAL_ID,
    maxQuantityInBag: 100,
    itemType: "partBag",
  },
  /**
   * NON USEABLE NOTES/COINS
   */
  [NOTE_UNUSABLE_50_POUND_ID]: {
    ...baseItemInfo[NOTE_50_POUND_ID],
    itemID: NOTE_UNUSABLE_50_POUND_ID,
    itemType: "unusableNote",
  },
  [NOTE_UNUSABLE_20_POUND_ID]: {
    ...baseItemInfo[NOTE_20_POUND_ID],
    itemID: NOTE_UNUSABLE_20_POUND_ID,
    itemType: "unusableNote",
  },
  [NOTE_UNUSABLE_10_POUND_ID]: {
    ...baseItemInfo[NOTE_10_POUND_ID],
    itemID: NOTE_UNUSABLE_10_POUND_ID,
    itemType: "unusableNote",
  },
  [NOTE_UNUSABLE_5_POUND_ID]: {
    ...baseItemInfo[NOTE_5_POUND_ID],
    itemID: NOTE_UNUSABLE_5_POUND_ID,
    itemType: "unusableNote",
  },
  [COIN_UNUSABLE_2_POUND_ID]: {
    ...baseItemInfo[COIN_2_POUND_ID],
    itemID: COIN_UNUSABLE_2_POUND_ID,
    itemType: "unusableCoin",
  },
  [COIN_UNUSABLE_1_POUND_ID]: {
    ...baseItemInfo[COIN_1_POUND_ID],
    itemID: COIN_UNUSABLE_1_POUND_ID,
    itemType: "unusableCoin",
  },
  [COIN_UNUSABLE_50_PENCE_ID]: {
    ...baseItemInfo[COIN_50_PENCE_ID],
    itemID: COIN_UNUSABLE_50_PENCE_ID,
    itemType: "unusableCoin",
  },
  [COIN_UNUSABLE_20_PENCE_ID]: {
    ...baseItemInfo[COIN_20_PENCE_ID],
    itemID: COIN_UNUSABLE_20_PENCE_ID,
    itemType: "unusableCoin",
  },
  [COIN_UNUSABLE_10_PENCE_ID]: {
    ...baseItemInfo[COIN_10_PENCE_ID],
    itemID: COIN_UNUSABLE_10_PENCE_ID,
    itemType: "unusableCoin",
  },
  [COIN_UNUSABLE_5_PENCE_ID]: {
    ...baseItemInfo[COIN_5_PENCE_ID],
    itemID: COIN_UNUSABLE_5_PENCE_ID,
    itemType: "unusableCoin",
  },
  [COIN_UNUSABLE_2_PENCE_ID]: {
    ...baseItemInfo[COIN_2_PENCE_ID],
    itemID: COIN_UNUSABLE_2_PENCE_ID,
    itemType: "unusableCoin",
  },
  [COIN_UNUSABLE_1_PENCE_ID]: {
    ...baseItemInfo[COIN_1_PENCE_ID],
    itemID: COIN_UNUSABLE_1_PENCE_ID,
    itemType: "unusableCoin",
  },
};

export type ColKey =
  | "denomination"
  | "quantity"
  | "bags"
  | "looseCoins"
  | "value"
  | "multiply"
  | "equals";
export type ColType = "input" | "text" | "total";

export interface Col {
  label: string;
  key: ColKey;
  type: ColType;
  displayValue?: string;
  itemID?: string;
}

const cols: Record<ColKey, Col> = {
  denomination: {
    key: "denomination",
    label: "Denomination",
    type: "text",
  },
  quantity: {
    key: "quantity",
    label: "Quantity",
    type: "input",
  },
  bags: {
    key: "bags",
    label: "No. of Bags",
    type: "input",
  },
  looseCoins: {
    key: "looseCoins",
    label: "Loose Coins",
    type: "input",
  },
  multiply: {
    key: "multiply",
    label: "X",
    type: "text",
  },
  equals: {
    key: "equals",
    label: "=",
    type: "text",
  },
  value: {
    key: "value",
    label: "Value",
    type: "total",
  },
};

// For each pouch subType:
// This can be thought of as the denominationLadder definition (rows & cols)
// Key is the itemID of the standard denomination (just used to display the denomination cell)
// Value is an array of itemIDs to be displayed as the cols of the row
export const denominationLadderForPouchSubTypes: Record<
  PouchSubType,
  PouchSubTypeDenominationLadder
> = {
  useable_coins_full_bag: {
    cols: [cols.denomination, cols.multiply, cols.bags, cols.equals, cols.value],
    rows: {
      [COIN_2_POUND_ID]: [BAG_2_POUND_FULL_ID],
      [COIN_1_POUND_ID]: [BAG_1_POUND_FULL_ID],
      [COIN_50_PENCE_ID]: [BAG_50_PENCE_FULL_ID],
      [COIN_20_PENCE_ID]: [BAG_20_PENCE_FULL_ID],
      [COIN_10_PENCE_ID]: [BAG_10_PENCE_FULL_ID],
      [COIN_5_PENCE_ID]: [BAG_5_PENCE_FULL_ID],
      [COIN_2_PENCE_ID]: [BAG_2_PENCE_FULL_ID],
      [COIN_1_PENCE_ID]: [BAG_1_PENCE_FULL_ID],
    },
    rowsOrder: [
      COIN_2_POUND_ID,
      COIN_1_POUND_ID,
      COIN_50_PENCE_ID,
      COIN_20_PENCE_ID,
      COIN_10_PENCE_ID,
      COIN_5_PENCE_ID,
      COIN_2_PENCE_ID,
      COIN_1_PENCE_ID,
    ],
  },
  useable_coins_part_bag: {
    cols: [cols.denomination, cols.multiply, cols.bags, cols.looseCoins, cols.equals, cols.value],
    rows: {
      [COIN_2_POUND_ID]: [BAG_2_POUND_PARTIAL_ID, COIN_2_POUND_ID],
      [COIN_1_POUND_ID]: [BAG_1_POUND_PARTIAL_ID, COIN_1_POUND_ID],
      [COIN_50_PENCE_ID]: [BAG_50_PENCE_PARTIAL_ID, COIN_50_PENCE_ID],
      [COIN_20_PENCE_ID]: [BAG_20_PENCE_PARTIAL_ID, COIN_20_PENCE_ID],
      [COIN_10_PENCE_ID]: [BAG_10_PENCE_PARTIAL_ID, COIN_10_PENCE_ID],
      [COIN_5_PENCE_ID]: [BAG_5_PENCE_PARTIAL_ID, COIN_5_PENCE_ID],
      [COIN_2_PENCE_ID]: [BAG_2_PENCE_PARTIAL_ID, COIN_2_PENCE_ID],
      [COIN_1_PENCE_ID]: [BAG_1_PENCE_PARTIAL_ID, COIN_1_PENCE_ID],
    },
    rowsOrder: [
      COIN_2_POUND_ID,
      COIN_1_POUND_ID,
      COIN_50_PENCE_ID,
      COIN_20_PENCE_ID,
      COIN_10_PENCE_ID,
      COIN_5_PENCE_ID,
      COIN_2_PENCE_ID,
      COIN_1_PENCE_ID,
    ],
  },
  non_useable_coins: {
    cols: [cols.denomination, cols.multiply, cols.quantity, cols.equals, cols.value],
    rows: {
      [COIN_2_POUND_ID]: [COIN_UNUSABLE_2_POUND_ID],
      [COIN_1_POUND_ID]: [COIN_UNUSABLE_1_POUND_ID],
      [COIN_50_PENCE_ID]: [COIN_UNUSABLE_50_PENCE_ID],
      [COIN_20_PENCE_ID]: [COIN_UNUSABLE_20_PENCE_ID],
      [COIN_10_PENCE_ID]: [COIN_UNUSABLE_10_PENCE_ID],
      [COIN_5_PENCE_ID]: [COIN_UNUSABLE_5_PENCE_ID],
      [COIN_2_PENCE_ID]: [COIN_UNUSABLE_2_PENCE_ID],
      [COIN_1_PENCE_ID]: [COIN_UNUSABLE_1_PENCE_ID],
    },
    rowsOrder: [
      COIN_2_POUND_ID,
      COIN_1_POUND_ID,
      COIN_50_PENCE_ID,
      COIN_20_PENCE_ID,
      COIN_10_PENCE_ID,
      COIN_5_PENCE_ID,
      COIN_2_PENCE_ID,
      COIN_1_PENCE_ID,
    ],
  },
  useable_notes: {
    cols: [cols.denomination, cols.multiply, cols.quantity, cols.equals, cols.value],
    rows: {
      [NOTE_50_POUND_ID]: [NOTE_50_POUND_ID],
      [NOTE_20_POUND_ID]: [NOTE_20_POUND_ID],
      [NOTE_10_POUND_ID]: [NOTE_10_POUND_ID],
      [NOTE_5_POUND_ID]: [NOTE_5_POUND_ID],
    },
    rowsOrder: [NOTE_50_POUND_ID, NOTE_20_POUND_ID, NOTE_10_POUND_ID, NOTE_5_POUND_ID],
  },
  non_useable_notes: {
    cols: [cols.denomination, cols.multiply, cols.quantity, cols.equals, cols.value],
    rows: {
      [NOTE_50_POUND_ID]: [NOTE_UNUSABLE_50_POUND_ID],
      [NOTE_20_POUND_ID]: [NOTE_UNUSABLE_20_POUND_ID],
      [NOTE_10_POUND_ID]: [NOTE_UNUSABLE_10_POUND_ID],
      [NOTE_5_POUND_ID]: [NOTE_UNUSABLE_5_POUND_ID],
    },
    rowsOrder: [NOTE_50_POUND_ID, NOTE_20_POUND_ID, NOTE_10_POUND_ID, NOTE_5_POUND_ID],
  },
};
