import React, { ReactNode, useEffect, useState } from "react";
import Select, { components } from "react-select";
interface iProps {
  id?: string;
  name: string;
  items: any[];
  activeFilters?: any[];
  children?: ReactNode;
  onChange?: (params: any) => any;
}

const Dropdown = (props: iProps) => {
  const [selectedValue, setSelectedValue] = useState(null);

  useEffect(() => {
    if (props.activeFilters.length === 0) {
      setSelectedValue({ value: "", label: "All" }); // reset filter
    }
  }, [props.activeFilters]);

  const options = props.items.map((city) => {
    return { value: city, label: city };
  }); // filter options

  const component = [{ value: " ", label: "All" }]; // filter All options

  const groupedOptions = [
    {
      label: "",
      options: component,
    },
    {
      label: "",
      options: options,
    },
  ]; // filter options

  const handleChange = (e) => {
    setSelectedValue(e);
    props.onChange({
      field: props.name,
      value: e.value,
      filter: "dropdown",
    });
  };

  const customStyles = {
    control: (base) => ({
      ...base,
      border: "1px solid #444",
      width: "100%",
      padding: "8px 0px",
      "&:hover": {
        border: "1px solid #444",
      },
    }),
    option: (styles, state) => ({
      ...styles,
      backgroundColor: state.isFocused ? "#9999" : null,
      color: "#444",
      margin: state.data.label === "All" ? "-15px 0px" : "",
      position: state.data.label === "All" ? "relative" : "",
      top: state.data.label === "All" ? "5px" : "",
    }),
  }; // filter options styling

  const DropdownIndicator = (props) => {
    return (
      components.DropdownIndicator && (
        <components.DropdownIndicator {...props}>
          <div className="arrowSelect"></div>
        </components.DropdownIndicator>
      )
    );
  };

  return (
    //     <select name={props.name || "dropdown"}
    //         className='filterDropdown'
    //         value={selectedValue}
    //         onChange={handleChange}>
    //         {renderSelect()}
    //         {renderItems()}
    //     </select>
    // );
    <Select
      onChange={handleChange}
      options={groupedOptions}
      value={selectedValue}
      styles={customStyles}
      components={{ IndicatorSeparator: () => null, DropdownIndicator }}
      isSearchable={false}
    />
  );
};

export default Dropdown;
