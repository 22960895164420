import {
  GetCashDeclarationHistoryQueryError,
  GetCashDeclarationHistoryQueryResult,
  useGetCashDeclarationHistory,
} from "@bbo/api/generator";
import { DynamoTable } from "@bbo/components/DynamoTable/DynamoTable";
import {
  Filters,
  FreeTextFilter,
  FromToFilter,
  MultiDropdownFilter,
  SingleDropdownFilter,
} from "@bbo/components/DynamoTable/dynamoTableTypes";
import { useDynamoPagination } from "@bbo/lib";

interface GetCashDeclarationFilters extends Filters {
  "updatedBy.userName": MultiDropdownFilter;
  "updatedBy.smartID": FreeTextFilter;
  status: SingleDropdownFilter;
  timestamp: FromToFilter;
  currentVariance: FromToFilter;
}

const timestampTest = {
  from: +new Date("04/09/2023"),
  to: +new Date(),
};

export const Test = () => {
  const initialFilters: GetCashDeclarationFilters = {
    "updatedBy.userName": {
      variant: "multiDropdown",
      placeholder: "Select",
      generateValues: true,
      values: [
        {
          label: "Group 1",
          value: "user1",
          caseSensitive: false,
          additionalValues: ["juana schmeler", "ron luettgen"],
          selected: false,
        },
        {
          label: "User 2",
          value: "user2",
          selected: false,
        },
      ],
    },
    "updatedBy.smartID": {
      variant: "freeText",
      unit: "string",
      placeholder: "Smart ID",
      value: "",
    },
    status: {
      variant: "singleDropdown",
      placeholder: "Select",
      generateValues: true,
      values: [
        {
          label: "Group 1",
          value: "group1",
          caseSensitive: false,
          additionalValues: ["recount", "incomplete"],
          selected: false,
        },
      ],
    },
    timestamp: {
      variant: "fromTo",
      unit: "date",
      // value: timestampTest,
    },
    currentVariance: {
      variant: "fromTo",
      unit: "currency",
      value: {
        from: null,
        to: null,
      },
    },
  };

  const {
    data,
    error,
    isLoading,
    hasNext,
    hasPrev,
    getNext,
    getPrev,
    filters,
    setFilters,
    selectedItems,
    selectedItemIndexes,
    setSelectedItemIndexes,
  } = useDynamoPagination<
    typeof useGetCashDeclarationHistory,
    GetCashDeclarationHistoryQueryResult,
    GetCashDeclarationHistoryQueryError
  >(
    useGetCashDeclarationHistory,
    {
      filters: initialFilters,
    },
    {
      // here we could pass query params to be used by the useGetCashDeclarationHistory hook
    },
    {
      // here we could specify react query options, if needed
    },
  );

  return (
    <DynamoTable
      hasPrev={hasPrev}
      hasNext={hasNext}
      error={error}
      onPrev={getPrev}
      onNext={getNext}
      filters={filters}
      setFilters={setFilters}
      selectedItemIndexes={selectedItemIndexes}
      setSelectedItemIndexes={setSelectedItemIndexes}
      shouldDisableRowCheckbox={(item, index) => item.accountingLocationName === "Grady Bartell"}
      data={data.data}
      columns={[
        {
          heading: "Accounting Locations",
          fieldName: "accountingLocationName",
          format: "text",
        },
        {
          heading: "User Name",
          fieldName: "updatedBy.userName",
          format: "text",
        },
        {
          heading: "Smart ID",
          fieldName: "updatedBy.smartID",
          format: "text",
        },
        {
          heading: "Date and Time Range",
          fieldName: "timestamp",
          format: "date",
        },
        {
          heading: "Variance",
          fieldName: "currentVariance",
          format: "currencyVariancePence",
        },
        {
          heading: "Status",
          fieldName: "status",
          format: "text",
        },
      ]}
    />
  );
};
