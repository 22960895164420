import "./CountReview.css";
const CommitPopup = () => {
  return (
    <div className="forex-den-commit-alert-content">
      <div className="variance">
        Total Variance Value: <span className="variance-value">-£40</span>
      </div>
      <div className="variance2">Are you sure you want to commit this Currency Count?</div>
    </div>
  );
};

export default CommitPopup;
