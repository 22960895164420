import { DescendantContext } from "@bbo/context";
import { useContext } from "react";

export const useDescendantContext = () => {
  const context = useContext(DescendantContext);
  if (!context) {
    throw new Error("DescendantContext must be used within Descendant component");
  }
  return context;
};
