import { amountUnSignedPound } from "./currencyFormatter";

export type Denomination = {
  denomination: string;
  index: number;
  looseCoin: number;
  value: number;
  floatValue?: number;
};

export const cashText = (cash: string) => {
  const value = cash.slice(0, -1);
  switch (cash[cash.length - 1]) {
    case "£":
      return parseInt(value) >= 5 ? `£${value} Note` : `£${value} Coin`;
    case "p":
      return `${value}p Coin`;
    default:
      return cash;
  }
};

export const convertDenomination = (denom: Denomination[]) => {
  return denom.map((item) => {
    const denomination = cashText(item.denomination);
    const { denomination: _, value, ...rest } = item;
    const newValue = amountUnSignedPound(value);
    return { denomination, value: newValue, ...rest };
  });
};

export const removeEmptyLinesFromAddress = (address: string) => {
  const addressArray = address.split(",");
  const cleanedArray = addressArray.filter((item) => item !== "");
  return cleanedArray;
};
