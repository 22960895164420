import Grid from "@bbo/components/Grid";
import Loader from "@bbo/components/Loader";
import { useCurrencyCountGrid } from "./useCurrencyCountGrid";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { useEffect } from "react";
export const CurrencyCountGrid = () => {
  const { isLoading, ...gridData } = useCurrencyCountGrid();
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Currency Count Summary");
  }, [setTitle]);
  return isLoading ? (
    <Loader />
  ) : (
    <>
      <Grid {...gridData} />
    </>
  );
};
