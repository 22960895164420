import { useGetBpTpDetails } from "@bbo/api/generator";
import { CashDetailContext } from "@bbo/context";
import { useTitle } from "@bbo/features";
import { PeriodName, BpTpProps, PeriodType } from "@bbo/interfaces/BpTp";
import { formatDateYearMonthDay } from "@bbo/utils/dateTimeFormatter";
import { useEffect, useCallback, useState, useContext } from "react";
import { useParams } from "react-router-dom";
import { useGlobalState } from "../state";
import { setHistoricalBpTpTitle } from "../reduxStore/historicalBpTp.slice";
import { useAppDispatch } from "@bbo/lib";

export const useHistoricalBpTp = (periodName: PeriodName, periodType: PeriodType): BpTpProps => {
  const dispatch = useAppDispatch();
  const { setTitle } = useTitle();
  const { declarationID } = useParams();
  const [fromHistory] = useState<boolean>(true);
  const [fromDiscrepancyAccount] = useState<boolean>(true);
  const { data: historicalTpBpData, status } = useGetBpTpDetails(periodType, { declarationID });

  const commonTitle = useCallback(() => {
    return `${periodName} ${historicalTpBpData[periodType]} ${formatDateYearMonthDay(
      historicalTpBpData.timestamp,
    )}`;
  }, [historicalTpBpData, periodName, periodType]);

  const titleSelector = useCallback(() => {
    if (!historicalTpBpData) return `${periodName} - History`;
    const title = commonTitle();
    dispatch(setHistoricalBpTpTitle({ title }));
    return `${title} - History`;
  }, [commonTitle, dispatch, historicalTpBpData, periodName]);

  const historyHandlers = () => {
    setTitle(titleSelector());
  };

  useEffect(historyHandlers, [historicalTpBpData, setTitle, titleSelector]);
  return {
    cashCommodity: historicalTpBpData?.cashCommodity,
    status,
    fromHistory,
    fromDiscrepancyAccount,
  };
};
