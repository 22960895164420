import { ErrorMessageProps } from "@bbo/interfaces/BpTp";

const ErrorMessage = ({ message }: ErrorMessageProps) => {
  return (
    <div className="noRecordFound noRecordFoundDynamo h-72">
      <span>{message}</span>
    </div>
  );
};

export default ErrorMessage;
