interface iProps {
  onCancel: (params) => void;
  onSuccess: (params) => void;
  isPasswordValidation: boolean;
  popupData: {
    commitButton: string;
    title: string;
    lines: string[];
  };
}

export const CommitPopup = (props: iProps) => {
  const handleCancel = () => {
    if (props.onCancel) {
      props.onCancel({
        passwordValidation: false,
        initial: false,
        cancelCommit: true,
        cancelFlagName: "initial",
      });
    }
  };

  const handleSuccess = () => {
    if (props.onSuccess) {
      props.onSuccess({
        passwordValidation: props.isPasswordValidation || false,
        initial: false,
        cancelCommit: false,
      });
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="tp-popup-commit-container">
          <div className="flex items-center justify-between">
            <span className="tp-popup-content">
              {props.popupData.title} :
              <ul>
                {props.popupData.lines.map((value, index) => (
                  <li key={index}>{value}</li>
                ))}
              </ul>
            </span>
          </div>
          <div className="tp-login-btn-container">
            <button className="tp-btn-cancel mr-4" onClick={handleCancel}>
              Cancel
            </button>
            <button className="tp-btn-submit ml-4" onClick={handleSuccess}>
              {props.popupData.commitButton}
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
