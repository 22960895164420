/**
 * PouchPreparationFeature helper functions
 */

import { PouchDetailsItems } from "@bbo/api/generator";
import {
  CoinItem,
  completedCashDetailFullandPartBag,
  LadderItemProps,
  ladderRowCombinedItem,
  PostCoinItem,
} from "@bbo/types";
import {
  fullBagCoinIds,
  loosePartialCoinIds,
  initialCoinValues,
} from "../PouchLadder/PouchConstants";
import { convertPenceToPoundsAsNumber, convertPenceToPoundsAsString } from "../PouchLadder/utility";

export const combineFullBagsAndLooseCoins = (
  coinsList: PouchDetailsItems,
): ladderRowCombinedItem[] => {
  const initialCoinState = [...initialCoinValues];

  return Object.values(coinsList).reduce((acc, coin) => {
    if (coin?.itemQuantity > 0 && fullBagCoinIds.includes(coin.itemID)) {
      const index = acc.findIndex((c) => c.fullBagID === coin.itemID);
      acc[index].fullBagQuantity = coin?.itemQuantity;
    } else if (coin?.itemQuantity > 0 && loosePartialCoinIds.includes(coin.itemID)) {
      const index = acc.findIndex((c) => c.partBagID === coin.itemID);
      acc[index].partBagQuantity = coin?.itemQuantity;
    }
    return acc;
  }, initialCoinState);
};

export const calculateFullBagAndLooseCoinValues = (
  coinsList: ladderRowCombinedItem[],
): ladderRowCombinedItem[] => {
  return coinsList.map((coin) => {
    if (coin.fullBagQuantity > 0) {
      coin.fullBagCalculatedValue = coin.fullBagQuantity * coin.fullBagValueInPence;
    }
    if (coin.partBagQuantity > 0) {
      coin.partBagCalculatedValue = coin.partBagQuantity * coin.partBagIndividualValue;
    }
    return coin;
  });
};

export const calculateCombinedValue = (
  coinsList: ladderRowCombinedItem[],
): ladderRowCombinedItem[] => {
  return coinsList.map((coin) => {
    coin.combinedValueInPence = coin.fullBagCalculatedValue + coin.partBagCalculatedValue;
    return coin;
  });
};

export const prepareFullBagAndLooseCoinsPouch = (coinsList: PouchDetailsItems) => {
  const combinedFullBagsAndLooseCoins = combineFullBagsAndLooseCoins(coinsList);
  const calculatedFullBagAndLooseCoinValues = calculateFullBagAndLooseCoinValues(
    combinedFullBagsAndLooseCoins,
  );
  const combinedValue = calculateCombinedValue(calculatedFullBagAndLooseCoinValues);
  return combinedValue;
};

export const prepareFullBagsAndLooseCoinsToPost = (
  cashDetail:
    | completedCashDetailFullandPartBag[]
    | LadderItemProps[]
    | CoinItem[]
    | ladderRowCombinedItem[],
): PostCoinItem[] => {
  const postCoinItems: PostCoinItem[] = [];
  cashDetail.forEach((coin) => {
    if (Number(coin.fullBagQuantity) > 0) {
      postCoinItems.push({
        itemID: coin.fullBagID,
        Cash: convertPenceToPoundsAsNumber(coin.fullBagIndividualValueInPence),
        Quantity: coin.fullBagQuantity.toString(),
        value: convertPenceToPoundsAsString(coin.fullBagValueInPence),
        cashType: coin.cashType,
        currency: coin.currency,
      });
    }
    if (Number(coin.partBagQuantity) > 0) {
      postCoinItems.push({
        itemID: coin.partBagID,
        Cash: convertPenceToPoundsAsNumber(coin.partBagIndividualValue),
        Quantity: coin.partBagQuantity.toString(),
        value: convertPenceToPoundsAsString(coin.partBagIndividualValue),
        cashType: coin.cashType,
        currency: coin.currency,
      });
    }
  });

  return postCoinItems;
};
