import { useCallback } from "react";
import { Filter, FromToFilter as BaseFromToFilter } from "../dynamoTableTypes";
import { DateRange } from "./FromToFilter/DateRange/DateRange";
import { TextRange } from "./FromToFilter/TextRange";

interface FromToFilterProps<T extends string | number> {
  fieldName: string;
  filter: BaseFromToFilter<T>;
  onFilterChange: (filterKey: string, filter: Filter<T>) => void;
}

export const FromToFilter = <T extends string | number = number>({
  fieldName,
  filter,
  onFilterChange,
}: FromToFilterProps<T>) => {
  const { unit, placeholder, value } = filter;
  const onChange = useCallback(
    (from: T, to: T) => {
      onFilterChange(fieldName, {
        ...filter,
        value: { from, to },
      });
    },
    [fieldName, filter, onFilterChange],
  );

  if (unit === "date") {
    return <DateRange fromDate={value?.from} toDate={value?.to} onChange={onChange} />;
  }

  return (
    <TextRange
      from={value?.from}
      to={value?.to}
      placeholder={placeholder}
      unit={unit}
      onChange={onChange}
    />
  );
};
