export const ArrowDown = () => {
  return (
    <svg width="14" height="18" viewBox="0 0 14 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M10.38 5.5L3.62 5.5L7 2.12L10.38 5.5ZM14 7L7 -3.0598e-07L-4.80825e-07 7L14 7ZM14 11L-3.0598e-07 11L7 18L14 11Z"
        fill="#2E1A47"
      />
    </svg>
  );
};
