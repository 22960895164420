import {
  BranchCashOverview,
  useCreateAccountingLocation,
  useDissociationCashDrawer,
  useGetCashBranchOverviewDetails,
} from "@bbo/api/generator";
import { featureFlags } from "@bbo/constants";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { PrintCashDrawerData } from "@bbo/types/CashDrawer";
import {
  LabelAvailablePrinters,
  LabelPrinterClient,
  setup as setupDeviceService,
  SupportedServices,
} from "postoffice-peripheral-management-service";
import { useCallback, useEffect, useState } from "react";
import { HandlePrintParams, ModalConfig } from "./manageCashDrawerTypes";

const labelPrinterService = setupDeviceService({
  deviceServerHost: process.env.REACT_APP_POL_DEVICE_SERVER_HOST,
  disconnectAfterResponse: true,
});

const labelPrinterClient = labelPrinterService.buildClient(
  SupportedServices.LabelPrinter,
  {},
) as LabelPrinterClient;

const zplString = ({ barcode, title }: { barcode: string; title: string }) =>
  `^XA
^MMT
^PW1200
^LL1800
^LS0
^BY6,3,341^FT844,1104^BCB,,Y,N,,A
^FD${barcode}^FS
^FT406,1800^A0B,106,106^FB1800,1,27,C^FH^CI28^FD${title}^FS^CI27
^MMC,N
^XZ`;

export const useManageCashDrawer = () => {
  const [modalConfig, setModalConfig] = useState<ModalConfig | null>(null);
  const [printData, setPrintData] = useState<PrintCashDrawerData>(null);

  const [isPrintError, setPrintError] = useState<boolean>(false);
  const {
    data: accountingLocations,
    isLoading: accountingLocationsLoading,
    refetch: refetchAccountingLocations,
  } = useGetCashBranchOverviewDetails();

  const refetchAll = useCallback(() => {
    refetchAccountingLocations();
  }, [refetchAccountingLocations]);

  const {
    status: addCashDrawerStatus,
    data: addCashDrawerResponse,
    isLoading: addCashDrawerLoading,
    error: addCashDrawerError,
    mutate: addCashDrawer,
    reset: addCashDrawerReset,
  } = useCreateAccountingLocation();

  const {
    status: dissociateDrawerStatus,
    isLoading: dissociateCashDrawerLoading,
    error: dissociateCashDrawerError,
    mutate: dissociateCashDrawer,
    reset: dissociateReset,
  } = useDissociationCashDrawer();

  const loading = accountingLocationsLoading || addCashDrawerLoading || dissociateCashDrawerLoading;
  const { isCtAccess } = useAppSelector((root) => root.counterTerminalAccess);

  const associatedCashDrawers = accountingLocations?.filter((obj) => !!obj?.counterID);

  const unassociatedCashDrawers = accountingLocations?.filter(
    (obj) => obj?.accountingLocationType === "cashDrawer" && !obj?.counterID,
  );

  const resetModalConfig = () => {
    setModalConfig(null);
  };

  const handlePrintZpl = useCallback(
    async ({ accountingLocationID, accountingLocationName }: HandlePrintParams) => {
      if (!featureFlags.isLabelPrinterEnabled) return;

      const title = accountingLocationName;

      try {
        const result = await labelPrinterClient.print({
          label: zplString({ barcode: accountingLocationID, title }),
          printer: LabelAvailablePrinters.RoyalMail,
        });
        return Promise.resolve(result);
      } catch (e) {
        setPrintError(true);
        return Promise.reject(e);
      }
    },
    [],
  );

  const handleStandardPrint = useCallback(({ accountingLocationID, accountingLocationName }) => {
    setPrintData({
      title: accountingLocationName,
      barcode: accountingLocationID,
    });
  }, []);

  useEffect(() => {
    if (addCashDrawerStatus === "error") {
      setModalConfig({
        variant: "failed",
        label: `Unable To Add Cash Drawer`,
        primaryButton: {
          label: "OK",
          onClick: () => {
            resetModalConfig();
          },
        },
      });
      return;
    }
    if (addCashDrawerStatus !== "success") return;

    const { accountingLocationName, accountingLocationID } = addCashDrawerResponse;
    refetchAll();
    handlePrintZpl({
      accountingLocationID: addCashDrawerResponse.accountingLocationID,
      accountingLocationName,
    });
    handleStandardPrint({ accountingLocationName, accountingLocationID });
    setModalConfig({
      variant: "success",
      label: `${accountingLocationName} has been successfully added. Please check the label has printed.`,
      primaryButton: {
        label: "Confirm",
        onClick: () => {
          addCashDrawerReset();
          resetModalConfig();
        },
      },
      secondaryButton: {
        label: "Re-print",
        onClick: () => {
          handlePrintZpl({ accountingLocationName, accountingLocationID });
          handleStandardPrint({ accountingLocationName, accountingLocationID });
        },
      },
    });
  }, [
    addCashDrawerReset,
    addCashDrawerResponse,
    addCashDrawerStatus,
    handlePrintZpl,
    handleStandardPrint,
    refetchAll,
  ]);

  const handleAddClick = () => {
    setModalConfig({
      variant: "reprint",
      label: "Are you sure you want to add a Cash Drawer?",
      primaryButton: {
        label: "Yes",
        onClick: addCashDrawer,
      },
      secondaryButton: {
        label: "No",
        onClick: resetModalConfig,
      },
    });
  };

  useEffect(() => {
    if (dissociateDrawerStatus === "error") {
      setModalConfig({
        variant: "failed",
        label: "Unable To Remove Cash Drawer",
        primaryButton: {
          label: "OK",
          onClick: () => {
            resetModalConfig();
          },
        },
      });
      return;
    }
    if (dissociateDrawerStatus !== "success") return;

    refetchAll();
    setModalConfig({
      variant: "success",
      label: "Successfully logged off user and cash drawer disassociated.",
      primaryButton: {
        label: "Confirm",
        onClick: () => {
          dissociateReset();
          resetModalConfig();
        },
      },
    });
  }, [dissociateDrawerStatus, dissociateReset, refetchAll]);

  const handleDissociateCashDrawer = (cashDrawerId: string, counterId: string) => {
    dissociateCashDrawer({
      data: {
        cashDrawerID: cashDrawerId,
        counterID: counterId,
      },
    });
  };

  const handleForcedLogOff = (accountingLocation: BranchCashOverview) => {
    setModalConfig({
      variant: "reprint",
      label: "The user will be logged off and the cash drawer disassociated",
      primaryButton: {
        label: "Yes",
        onClick: () => {
          handlePrintZpl(accountingLocation);
          handleDissociateCashDrawer(
            accountingLocation?.accountingLocationID,
            accountingLocation?.counterID,
          );
        },
      },
      secondaryButton: {
        label: "No",
        onClick: resetModalConfig,
      },
    });
  };

  const handleRemoveClick = () => {
    // On hold until API endpoint is added
    // const label={
    //   unassociatedCashDrawers.length - 1 === 0
    //     ? "Cash drawer can only be deleted when moved to the safe and balance is zero."
    //     : "Cash drawer can only be deleted when Cash Balance is zero."
    // }
  };

  const handleReprint = (accountingLocation: BranchCashOverview) => {
    setModalConfig({
      variant: "reprint",
      label: "Are you sure you want to re-print the label?",
      primaryButton: {
        label: "Yes",
        onClick: () => {
          handlePrintZpl(accountingLocation);
          handleStandardPrint(accountingLocation);
          resetModalConfig();
        },
      },
      secondaryButton: {
        label: "No",
        onClick: resetModalConfig,
      },
    });
  };

  const handleAccountingLocationClick = (accountingLocation: BranchCashOverview) => {
    setModalConfig({
      variant: "accountingLocation",
      accountingLocation,
      primaryButton: {
        label: "Re-print Label",
        onClick: () => handleReprint(accountingLocation),
      },
      secondaryButton: {
        label: "Forced Logoff",
        onClick: () => handleForcedLogOff(accountingLocation),
      },
    });
  };

  return {
    printData,
    setPrintError,
    setPrintData,
    isPrintError,
    loading,
    accountingLocations,
    associatedCashDrawers,
    unassociatedCashDrawers,
    handleAddClick,
    handleRemoveClick,
    modalConfig,
    handleAccountingLocationClick,
  };
};
