import React, { useState } from "react";
import { ArrowDown, ArrowUp, ArrowUpDownOutline } from "../Icons";
import { textComparator } from "./comparator";
import { Column, SortData } from "./types";
import { columnStyle } from "./utility";

/**
 * Table column header component
 * @param props
 * @returns
 */
interface iProps {
  column: Column;
  columnIndex: number;
  rows: unknown[];
  class?: string;
  onHeaderClicked?: (params) => void;
  onHeaderSelection?: (params) => void;
  onSortChanged?: (params) => void;
  checkedList?: boolean[];
  hideSortIcon?: boolean;
}

const initiaData: SortData = {
  sortKey: "",
  sortType: "",
  rows: [],
};

const ColumnHeader = ({ columnIndex, column, hideSortIcon, ...props }: iProps) => {
  const [colData, setColData] = useState({ ...initiaData, rows: props.rows });
  const isSelectAll = props.checkedList ? props.checkedList.every((check) => check) : false;

  const handleOnChange = (e) => {
    if (props.onHeaderSelection) {
      const mParams = {
        field: column.field,
        column,
        columnIndex,
        checked: e.target.checked,
      };
      props.onHeaderSelection(mParams);
    }
  };

  //Change sorting type
  const getSortType = (key, type) => {
    let stype = "asc";
    if (key === colData.sortKey) {
      if (type === "") {
        stype = "asc";
      } else if (type === "asc") {
        stype = "desc";
      } else if (type === "desc") {
        stype = "";
      }
    }
    return stype;
  };

  //Perform sorting
  const orderBy = (first, next, type) => {
    if (type === "asc") {
      return textComparator(first, next);
    } else if (type === "desc") {
      return textComparator(next, first);
    }
  };

  //Call on column sorting click
  const sortBy = (key) => {
    const arrayCopy = [...props.rows];
    const mData = { ...initiaData };
    mData.sortType = getSortType(key, colData.sortType);
    mData.sortKey = key;
    if (mData.sortType !== "") {
      arrayCopy.sort((a, b) => orderBy(a[key], b[key], mData.sortType));
    }
    mData.rows = arrayCopy;
    setColData(mData);
    if (props.onSortChanged) {
      const mParams = {
        field: column.field,
        column,
        columnIndex,
        ...mData,
      };
      props.onSortChanged(mParams);
    }
  };

  //Change sorting icon
  const SortIcon = ({ sortKey }) => {
    if (sortKey === colData.sortKey) {
      if (colData.sortType === "asc") {
        return <ArrowUp />;
      } else if (colData.sortType === "desc") {
        return <ArrowDown />;
      }
    }
    return <ArrowUpDownOutline />;
  };

  if (column.headerCheckboxSelection) {
    return (
      <th key={`col-${columnIndex}`} style={columnStyle(column)}>
        <div className="cell-checkbox-header">
          <input
            id={`col-${columnIndex}`}
            className="cell-checkbox"
            name={column.field}
            type="checkbox"
            checked={isSelectAll}
            onChange={handleOnChange}
          />
          <label title="Show Page Source" htmlFor={`col-${columnIndex}`}>
            {column.headerName}
          </label>
        </div>
      </th>
    );
  }

  if (column.suppressSorting) {
    // Disabled sorting
    return (
      <th
        className={
          column.headerName === "Date and Time Range"
            ? "width-21 text-center"
            : column.headerName === " Cash Movement Type"
            ? "width-15"
            : column.headerName === "Time Range"
            ? "w-1/5"
            : ""
        }
        key={`col-${columnIndex}`}
        style={columnStyle(column)}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span id={column.field}>{column.headerName}</span>
        </div>
      </th>
    );
  }

  return (
    <th
      className={
        column.headerName === "Date and Time Range"
          ? "width-21 text-center"
          : column.headerName === " Cash Movement Type"
          ? "width-15"
          : ""
      }
      key={`col-${columnIndex}`}
      style={columnStyle(column)}>
      <div
        className={`header-column-container ${props.class ? "text-center" : ""}`}
        onClick={() => sortBy(column.field)}>
        <span id={column.field} className="header-col-name">
          {column.headerName}
        </span>
        {!hideSortIcon && (
          <span style={{ paddingRight: "3px", float: "right" }}>
            <SortIcon sortKey={colData.sortKey} />
          </span>
        )}
      </div>
    </th>
  );
};

export default React.memo(ColumnHeader);
