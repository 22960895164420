import { memo } from "react";
import "./index.css";

interface Items {
  status: string;
}

interface iProps {
  onSelectRoyalMail: () => void;
  onSelectParcelforce: () => void;
  rmDespatchItemList: Items[];
  pfDespatchItemList: Items[];
  isParcelForceDespatch: boolean;
  isRoyalMailDespatch: boolean;
  isYes: boolean;
  isNo: boolean;
  onSelectYes: () => void;
  onSelectNo: () => void;
}

const CarrierSelection = (props: iProps) => {
  return (
    <>
      <div className="flex mt-8">
        <div className="outerDespatchType relative">
          {!!props.rmDespatchItemList.length && (
            <div className="grid justify-items-end absolute -top-8 right-0">
              <div className="count py-1 px-2">{props.rmDespatchItemList.length}</div>
            </div>
          )}
          <div>
            {props.isRoyalMailDespatch ? (
              <div className="rmDespatchSelected pl-2 pr-4 py-2 ">Royal Mail Despatch</div>
            ) : (
              <div
                data-testid="royal-mail"
                className="rmDespatch pl-2 pr-4 py-2"
                onClick={() => props.onSelectRoyalMail()}>
                Royal Mails Despatch
              </div>
            )}
          </div>
        </div>
        <div className="ml-3 outerDespatchType relative">
          {!!props.pfDespatchItemList.length && (
            <div className="grid justify-items-end absolute -top-8 right-0">
              <div className="count py-1 px-2">{props.pfDespatchItemList.length}</div>
            </div>
          )}
          {props.isParcelForceDespatch ? (
            <div className="rmDespatchSelected pl-2 pr-4 py-2">Parcelforce Despatch</div>
          ) : (
            <div
              data-testid="parcel-force"
              className="rmDespatch pl-2 pr-4 py-2"
              onClick={() => props.onSelectParcelforce()}>
              Parcelforce Despatch
            </div>
          )}
        </div>
      </div>
      <div>
        <div className="mt-3">
          Is this the final
          {` ${props.isRoyalMailDespatch ? "Royal Mail" : "Parcelforce"}`} Despatch today?
        </div>
        <div className="flex mt-3 items-center">
          <div className="flex items-center mr-3">
            <input
              data-testid="yes"
              type="radio"
              className="inputRadioChecbox"
              checked={props.isYes}
              onChange={() => props.onSelectYes()}
            />
            <label className="ml-2">Yes</label>
          </div>
          <div className="flex items-center ml-3">
            <input
              data-testid="no"
              type="radio"
              className="inputRadioChecbox"
              checked={props.isNo}
              onChange={() => props.onSelectNo()}
            />
            <label className="ml-2">No</label>
          </div>
        </div>
      </div>
    </>
  );
};

export default memo(CarrierSelection);
