import { ReprintModalConfig } from "./manageCashDrawerTypes";

export const ReprintPopUp = ({ label, primaryButton, secondaryButton }: ReprintModalConfig) => {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-xl popupmodel">
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4	">
            <div className="flex p-7  items-center justify-start">
              <h2 className="text-blueGray-700 text-lg leading-relaxed heading-5 font-bold text-color">
                {label || "Are you sure you want to re-print the label?"}
              </h2>
            </div>

            {/*footer*/}
            <div className="justify-start p-7 rounded-b">
              {secondaryButton ? (
                <button
                  className="popUp-button white-button mt-8 flex-initial mr-4"
                  type="button"
                  value="No"
                  onClick={secondaryButton.onClick}
                  data-cy="popupNo">
                  {secondaryButton.label}
                </button>
              ) : null}
              <button
                className="popUp-button red-button mt-8 flex-initial ml-4"
                type="button"
                value="Yes"
                onClick={primaryButton.onClick}
                data-cy="popupYes">
                {primaryButton.label}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
