import { GREENCODE, REDCODE } from "@bbo/features/constants";
import { useEffect, useState } from "react";
import "./index.css";

const ProgressBar = ({ bgcolor, progress, reason }) => {
  const [percentage, setPercentage] = useState<string>();

  useEffect(() => {
    if (progress) {
      const percent = `${progress}%`;
      setPercentage(percent);
    }
  }, [progress]);

  const width = {
    width: percentage,
    backgroundColor: bgcolor,
  };

  return (
    <div className="ParentDiv">
      <div className="ChildDiv" style={width}>
        <span
          className={`${
            bgcolor === REDCODE || bgcolor === GREENCODE
              ? "weightText progressText"
              : " progressText"
          }`}>
          {reason}
        </span>
      </div>
    </div>
  );
};
export default ProgressBar;
