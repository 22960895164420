import { useAppDispatch } from "@bbo/lib/hooks/useAppDispatch";
import { setUncountedCurrencies } from "@bbo/lib/reduxStore/countedCurrencies.slice";

export const useCompletedCurrencyCountCheck = () => {
  const dispatch = useAppDispatch();

  const completedCurrencyCountCheck = (selected, countedCurrencies) => {
    const uncountedCurrenciesArr = selected.currencys
      .filter((element) => {
        return !countedCurrencies.includes(element.ordercode);
      })
      .sort((a, b) => {
        if (a.name < b.name) {
          return -1;
        }
        if (a.name > b.name) {
          return 1;
        }
        return 0;
      });

    if (uncountedCurrenciesArr.length === 0) return true;

    dispatch(
      setUncountedCurrencies(
        uncountedCurrenciesArr.map((obj) => {
          return {
            currencyCountReviewData: {
              currencyName: obj.name,
              systemQuantity: "0",
              enteredQuantity: "0",
              currencyVariance: "0",
              sterlingValue: "0",
              adjustments: "Recount",
              varianceType: "Zero",
            },
            currencyOrderCode: {
              ordercode: obj.ordercode,
            },
          };
        }),
      ),
    );

    return false;
  };

  return { completedCurrencyCountCheck };
};
