import {
  CashBalance,
  GetCashDeclarationHistoryQueryError,
  GetCashDeclarationHistoryQueryResult,
  useGetCashDeclarationHistory,
  useGetCashDeclarationHistoryId,
} from "@bbo/api/generator";
import { DynamoTable } from "@bbo/components/DynamoTable/DynamoTable";
import {
  DropdownValue,
  Filters,
  FromToFilter,
  MultiDropdownFilter,
} from "@bbo/components/DynamoTable/dynamoTableTypes";
import Loader from "@bbo/components/Loader";
import { Popup } from "@bbo/components/Popup";
import CashBalanceDetailPopup from "@bbo/components/layout/PopupScreen/cashBalanceDetailPopup";
import { CASH_BALANCE_HISTORY_COLUMN } from "@bbo/components/table/tableConstants";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { useAppSelector, useDynamoPagination } from "@bbo/lib";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { useGlobalState } from "@bbo/lib/state";
import { CashBalancingHistory } from "@bbo/utils/ReceiptPrinterTemplates/CashBalancingHistory";
import { amountPound } from "@bbo/utils/currencyFormatter";
import {
  currentDateDDMMYYYY,
  currentOnlyTime,
  getUnixTimeStamp,
  getOnlyDateTime,
} from "@bbo/utils/dateTimeFormatter";
import { useEffect, useState } from "react";
import { removeEmptyLinesFromAddress } from "../utils/receiptHelper";

const filterValuesSort = (value1: DropdownValue, value2: DropdownValue) => {
  if (value1.label.toLowerCase().includes("safe")) return -1;
  if (value2.label.toLowerCase().includes("safe")) return 1;

  // Extract numeric values from the strings
  const numA = parseInt(value1.label.replace(/\D/g, ""), 10);
  const numB = parseInt(value2.label.replace(/\D/g, ""), 10);

  // Sort by numeric values in ascending order
  return numA - numB;
};

interface GetCashBalanceHistoryFilters extends Filters {
  accountingLocationName: MultiDropdownFilter;
  "updatedBy.userName": MultiDropdownFilter;
  "updatedBy.smartID": MultiDropdownFilter;
  timestamp: FromToFilter;
  currentVariance: FromToFilter;
  status: MultiDropdownFilter;
}

const CashBalanceHistory = () => {
  const [param, setparam] = useState<any>({});
  const [cashRowSelected, setCashRowSelected] = useState(null);

  const initialFilters: GetCashBalanceHistoryFilters = {
    accountingLocationName: {
      variant: "multiDropdown",
      placeholder: "Select",
      generateValues: true,
      values: [],
      filterValuesSort,
    },
    "updatedBy.userName": {
      variant: "multiDropdown",
      placeholder: "Select",
      generateValues: true,
      values: [],
    },
    "updatedBy.smartID": {
      variant: "multiDropdown",
      placeholder: "Select",
      generateValues: true,
      values: [],
    },
    timestamp: {
      variant: "fromTo",
      unit: "date",
      placeholder: {
        from: "From",
        to: "To",
      },
      // value: { from: null, to: null },
    },
    currentVariance: {
      variant: "fromTo",
      unit: "currency",
      placeholder: {
        from: "From",
        to: "To",
      },
      value: {
        from: null,
        to: null,
      },
    },
    status: {
      variant: "multiDropdown",
      placeholder: "Select",
      generateValues: true,
      values: [],
      filterValuesSort,
    },
  };

  const {
    data: historicalCashDeclaration,
    isLoading: historicalCashDeclarationIsLoading,
    hasNext,
    hasPrev,
    getNext,
    getPrev,
    filters,
    setFilters,
  } = useDynamoPagination<
    typeof useGetCashDeclarationHistory,
    GetCashDeclarationHistoryQueryResult,
    GetCashDeclarationHistoryQueryError
  >(
    useGetCashDeclarationHistory,
    {
      filters: initialFilters,
    },
    {},
    {},
  );

  const [cashdate] = useGlobalState("dateCashbalance");
  const [, setIsDateRangeSelected] = useState<boolean>(false);
  const [modalData, setModalData] = useState<CashBalance>();
  const { setTitle } = useTitle();
  const receiptPrinterService = useReceiptPrinter();
  const [showPopup, setShowPopup] = useState(false);

  const branchDetails = useAppSelector((root) => root.branchDetails);
  const [loader] = useState<boolean>(false);
  const [declarationId, setDeclarationId] = useState("");

  useEffect(() => {
    if (modalData?.declarationID) {
      setDeclarationId(modalData?.declarationID);
    }
  }, [modalData?.declarationID]);

  const { data: cashDeclarationData } = useGetCashDeclarationHistoryId(
    encodeURIComponent(declarationId),
  );

  useEffect(() => {
    setTitle("Cash Balances History");
  }, [setTitle]);

  useEffect(() => {
    if (cashdate.todate) {
      setIsDateRangeSelected(true);
      setparam({
        toDate: getUnixTimeStamp(cashdate.todate),
        fromDate: getUnixTimeStamp(cashdate.from),
      });
    } else {
      setIsDateRangeSelected(false);
    }
  }, [cashdate.from, cashdate.todate]);

  const handleRowClicked = (params) => {
    setModalData({ ...params });
    setShowPopup(true);
    setCashRowSelected(params);
  };
  const actions = [
    {
      name: "Cancel",
      handleAction: () => {
        setShowPopup(false);
      },
      style: "popupBtn border p-3 flex-auto mr-1",
    },
    {
      name: "Print",
      handleAction: () => {
        const branchAddressClean = removeEmptyLinesFromAddress(branchDetails.branch_address);
        setShowPopup(false);
        receiptPrinterService.printReceipt({
          template: CashBalancingHistory,
          context: {
            title: "Cash Balance History",
            branchName: branchDetails.branch_name,
            addressLine1: branchAddressClean[0],
            addressLine2: branchAddressClean[1],
            addressLine3: branchAddressClean[2],
            postcode: branchAddressClean[branchAddressClean.length - 1],
            fadcode: branchDetails.fadcode,
            currentDate: currentDateDDMMYYYY(),
            currentTime: currentOnlyTime(),
            accountingLocation: cashRowSelected.accountingLocationName,
            userName: cashRowSelected.updatedBy.userName,
            smartId: cashRowSelected.updatedBy.smartID,
            dateAndTime: getOnlyDateTime(cashRowSelected.timestamp),
            Variance: amountPound(cashRowSelected.currentVariance),
            Status: cashRowSelected.status,
          },
        });
      },
      style: "accounting-button border p-3 text-white flex-auto ml-1",
    },
  ];

  return (
    <>
      <>
        {historicalCashDeclarationIsLoading ? <Loader /> : loader && <Loader />}
        <div className="w-full transferOut-height">
          <div className="p-4 w-full h-full relative dynamictable">
            <DynamoTable
              data={historicalCashDeclaration.data}
              hasPrev={hasPrev}
              hasNext={hasNext}
              onPrev={getPrev}
              onNext={getNext}
              filters={filters}
              setFilters={setFilters}
              onRowClicked={(params) => handleRowClicked(params)}
              columns={CASH_BALANCE_HISTORY_COLUMN}
            />

            {showPopup && (
              <Popup
                titleHeader="Cash Balance History"
                title=" - Details"
                body={
                  <CashBalanceDetailPopup
                    data={modalData}
                    showPopup={showPopup}
                    cashItems={cashDeclarationData?.cashItems}
                  />
                }
                actions={actions}
                onClose={() => {
                  setShowPopup(false);
                }}
              />
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default CashBalanceHistory;
