import React, { useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { FromToFilter } from "../../dynamoTableTypes";
import { MultiInput } from "../MultiInput";
import { DYNAMO_DEBOUNCE_DURATION } from "../../dynamoTableHelpers";

interface TextRangeProps {
  from: number | string | null;
  to: number | string | null;
  placeholder?: FromToFilter["placeholder"];
  unit: "string" | "number" | "currency" | "date";
  onChange: (from: string | number, to: string | number) => void;
}

// TODO: Handle filter recalculation and validation onBlur
export const TextRange = ({ from, to, placeholder, unit, onChange }: TextRangeProps) => {
  const [localFrom, setLocalFrom] = useState(from);
  const [localTo, setLocalTo] = useState(to);

  const debouncedOnChange = useDebouncedCallback(
    (debouncedFrom: TextRangeProps["from"], debouncedTo: TextRangeProps["to"]) => {
      onChange(debouncedFrom, debouncedTo);
    },
    DYNAMO_DEBOUNCE_DURATION,
  );

  useEffect(() => {
    debouncedOnChange(localFrom, localTo);
  }, [debouncedOnChange, localFrom, localTo]);

  useEffect(() => {
    setLocalFrom(from);
    setLocalTo(to);
  }, [from, to]);

  return (
    <div className="flex flex-row space-x-4">
      <MultiInput
        placeholder={placeholder?.from}
        value={localFrom}
        unit={unit}
        onChange={setLocalFrom}
        onBlur={() => {
          // console.log("BLUR");
        }}
      />
      <MultiInput
        placeholder={placeholder?.to}
        value={localTo}
        unit={unit}
        onChange={setLocalTo}
        onBlur={() => {
          // console.log("BLUR");
        }}
      />
    </div>
  );
};
