import {
  MenuManagementreasonCode,
  useGetBranchConfig,
  useGetCashBranchOverviewDetails,
  useGetGlobal,
  useGetSystemCashAnAccountingLocation,
} from "@bbo/api/generator";
import { usePostTransferTransaction } from "@bbo/api/generatorNoFadcode";
import PaidInOutButton from "@bbo/components/Buttons/PaidInOutButton";
import { SCREENS } from "@bbo/constants";
import { useAppSelector } from "@bbo/lib";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { CashPaidInOut } from "@bbo/types/TransferTypes";
import { inputInPence } from "@bbo/utils/currencyFormatter";
import { currentDateDDMMYYYY, currentOnlyTime } from "@bbo/utils/dateTimeFormatter";
import { removeEmptyLinesFromAddress } from "@bbo/utils/receiptHelper";
import { PaidInOutTemplate } from "@bbo/utils/ReceiptPrinterTemplates/PaidInOut";
import { paidInParams, paidOutParams } from "@bbo/utils/transferPayload";
import { ChangeEvent, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  checkIfAccountingLocationActionInvalid,
  getLegacyAccountingLocationType,
  TransferInOutError,
  transferInOutErrors,
} from "../BranchOverview/branchOverviewHelpers";
import { actionListMap } from "../BranchOverview/BranchOverviewProvider";
import { OverviewLocationState } from "../BranchOverview/branchOverviewTypes";
import { BACK_OFFICE } from "../DiscrepancyAccount/constant";

interface NavigationProps {
  accountingLocationID: string;
}

interface UsePaidInOutProps {
  operationType: "paid_in" | "paid_out";
}

const maxCommentLength = 120;

export const usePaidInOut = ({ operationType }: UsePaidInOutProps) => {
  const navigate = useNavigate();
  const { accountingLocationID } = useParams() as unknown as NavigationProps;
  const branchDetails = useAppSelector((root) => root.branchDetails);
  const { branch_type } = useAppSelector((root) => root.branchDetails);
  const receiptPrinterService = useReceiptPrinter();
  const { fadCode, username } = useAppSelector((root) => root.auth.authUserData);
  const { data: branchData } = useGetBranchConfig();
  const [invalidAmount, setInvalidAmount] = useState<boolean>(false);
  const [selectedAmount, setSelectedAmount] = useState<string>("£0.00");
  const [warningFlag, setWarningFlag] = useState<boolean>(false);
  const [invalidFlag, setInvalidFlag] = useState<boolean>(false);
  const [invalidLimitFlag, setInvalidLimitFlag] = useState<boolean>(false);
  const [activeHeadingName, setActiveHeadingName] = useState<string>();
  const [disable, setDisable] = useState<boolean>(false);
  const [ActiveId, setActiveId] = useState<string>();
  const [optedItemID, setOptedItemID] = useState<string>();
  const [invalidFund, setInvalidFund] = useState<boolean>(false);
  const [clearFlag, setClearFlag] = useState<boolean>(false);
  const [isSubmitClick, setIsSubmitClick] = useState<boolean>(false);

  const [systemValue, setSystemValue] = useState<number>(0);
  const [comment, setComment] = useState<string>("");
  const [printData, setPrintData] = useState(false);

  const [list, setList] = useState([]);
  const [error, setError] = useState<TransferInOutError | null>(null);
  const txtAreaComment = useRef(null);

  const { data: branchOverviewData } = useGetCashBranchOverviewDetails();
  const { MaxBranchLimit: selectedRule } = useAppSelector((root) => root);

  const { mutate: postTransferOut, isSuccess, isError, isLoading } = usePostTransferTransaction();

  const commentLength = comment.trim().length;

  const accountingLocation = useMemo(
    () => branchOverviewData?.find((item) => item.accountingLocationID === accountingLocationID),
    [accountingLocationID, branchOverviewData],
  );

  // If action isn't in the actionList for this accountingLocationType or the operation is disabled, then throw an error
  useEffect(() => {
    if (
      checkIfAccountingLocationActionInvalid(
        accountingLocation,
        operationType === "paid_in" ? actionListMap.paidIn : actionListMap.paidOut,
        branchDetails.branch_type,
      )
    ) {
      setError(transferInOutErrors.invalidOperation);
    }
  }, [accountingLocation, branchDetails.branch_type, operationType]);

  useEffect(() => {
    setWarningFlag(selectedRule?.permittedLimit < parseInt(selectedAmount.substring(1)));
    setInvalidLimitFlag(selectedRule?.maximumLimit < parseInt(selectedAmount.substring(1)));
  }, [
    accountingLocationID,
    selectedAmount,
    selectedRule?.maximumLimit,
    selectedRule?.permittedLimit,
  ]);

  useEffect(() => {
    if (isError) {
      setInvalidFund(true);
      setWarningFlag(false);
      setClearFlag(false);
    } else if (isSuccess) {
      setClearFlag(true);
    }
  }, [isSuccess, isError]);

  const selectOption = (reason: MenuManagementreasonCode) => {
    setDisable(true);
    setActiveHeadingName(reason.title);
    setActiveId(reason.code);
    reason?.itemID && setOptedItemID(reason.itemID);
    setSelectedAmount("£0.00");
    setClearFlag(false);
    setInvalidFund(false);
    if (txtAreaComment) {
      txtAreaComment.current.focus();
    }
  };

  const onPrintClick = (title: string) => {
    const branchAddressClean = removeEmptyLinesFromAddress(branchDetails.branch_address);
    receiptPrinterService.printReceipt({
      template: PaidInOutTemplate,
      context: {
        title: title,
        fadcode: branchDetails.fadcode,
        branchName: branchDetails.branch_name,
        addressLine1: branchAddressClean[0],
        addressLine2: branchAddressClean[1],
        addressLine3: branchAddressClean[2],
        postcode: branchAddressClean[branchAddressClean.length - 1],
        currentDate: currentDateDDMMYYYY(),
        currentTime: currentOnlyTime(),
        amount: selectedAmount,
        branchType: branchData?.branchType,
        reasonCode: activeHeadingName,
        accountingLocation: accountingLocation?.accountingLocationName,
      },
    });
  };

  const enterAmount = (amount: string) => {
    setInvalidFlag(true);
    if (Number(amount) > 0) {
      setInvalidAmount(false);
      setSelectedAmount(amount);
    } else {
      setInvalidAmount(true);
      setSelectedAmount("");
    }

    if (Number(amount) > accountingLocation?.totalValue) {
      setIsSubmitClick(true);
    }
  };
  const { data: SystemValue } = useGetSystemCashAnAccountingLocation(accountingLocationID);

  useEffect(() => {
    if (SystemValue) {
      setSystemValue(SystemValue.systemValue);
    }
  }, [SystemValue, accountingLocationID]);

  const paidInPaidOutReasons = "paidInPaidOutReasons";
  const { data: encodeData } = useGetGlobal(paidInPaidOutReasons);

  useEffect(() => {
    if (encodeData?.message && branchData?.branchType) {
      const newValue = JSON.parse(atob(encodeData?.message));
      const inOperationType = operationType === "paid_in" ? "paidIn" : "paidOut";
      const listDetails = newValue[branchData?.branchType][inOperationType];
      setList(listDetails);
    }
  }, [branchData?.branchType, encodeData?.message, operationType]);

  const reasonCodes = list.filter(({ code }) => code !== "DS");

  const btnCounterView = reasonCodes.map((reason, index) => {
    if (reason.title !== "Obsolete Stock") {
      return (
        <PaidInOutButton
          key={index}
          disabled={clearFlag}
          className={`transfer-out-tiles heading font-bold ${
            reason.title === activeHeadingName ? "active-counter" : ""
          } `}
          onClick={() => {
            selectOption(reason);
          }}
          text={reason.title}
        />
      );
    }
  });

  const handleComment = (e: ChangeEvent<HTMLTextAreaElement>) => {
    const value = e.target.value;
    if (value.length > maxCommentLength) return;
    setComment(value);
  };

  const saveCashRequestParams = (): CashPaidInOut => {
    return {
      transferAmount: inputInPence(selectedAmount),
      fadcode: fadCode,
      userName: username,
      reasonCode: ActiveId,
      comments: comment,
      transferAt: BACK_OFFICE,
      operationType: operationType,
      stockunitIdentifier: accountingLocationID,
      ...(operationType === "paid_in"
        ? {
            destinationEntityType: getLegacyAccountingLocationType(
              accountingLocation?.accountingLocationType,
            ),
            destinationentityID: accountingLocation?.accountingLocationID,
          }
        : {
            sourceEntityType: getLegacyAccountingLocationType(
              accountingLocation?.accountingLocationType,
            ),
            sourceentityID: accountingLocation?.accountingLocationID,
          }),
    };
  };

  const handleCashDetails = () => {
    if (!isLoading) {
      const isBalanceSufficient =
        Number(systemValue) >= inputInPence(selectedAmount) || operationType !== "paid_out";

      setInvalidFund(!isBalanceSufficient);
      setWarningFlag(isBalanceSufficient);

      if (!invalidFlag || !isBalanceSufficient) return;

      const transferParams =
        operationType === "paid_in"
          ? paidInParams(saveCashRequestParams(), optedItemID)
          : paidOutParams(saveCashRequestParams(), optedItemID);

      postTransferOut({ data: transferParams });
    }
  };

  const navigateToBranchOverview = () => {
    // Only a paid_in operation can cause the balance to increase above the maximum limit
    if (
      accountingLocation?.accountingLocationType !== "cashDrawer" ||
      operationType === "paid_out"
    ) {
      navigate(SCREENS.BRANCH_OVERVIEW);
      return;
    }

    const state: OverviewLocationState = {
      cashDrawerLimit: {
        accountingLocationID: accountingLocation.accountingLocationID,
        lastTransferAmountPence: inputInPence(selectedAmount), // TODO: This will need to change to inputNumberInPence once the correct input type is used
      },
    };
    // Sends the accountingLocationID of the accounting location to transfer into, so that the "Cash Drawer Limit" modal can be displayed if necessary
    navigate(SCREENS.BRANCH_OVERVIEW, {
      state,
    });
  };

  return {
    accountingLocation,
    clearFlag,
    setClearFlag,
    btnCounterView,
    txtAreaComment,
    comment,
    handleComment,
    commentLength,
    maxCommentLength,
    disable,
    warningFlag,
    invalidLimitFlag,
    invalidFund,
    selectedAmount,
    enterAmount,
    handleCashDetails,
    printData,
    setPrintData,
    onPrintClick,
    isSubmitClick,
    setInvalidFund,
    error,
    navigateToBranchOverview,
    invalidAmount,
  };
};
