import {
  GetPouchListQueryError,
  GetPouchListQueryResult,
  PouchItem,
  PouchStatus,
  useGetPouch,
  useGetPouchList,
} from "@bbo/api/generator";
import PouchPopupModel from "@bbo/components/layout/PouchPopupModel";
import Loader from "@bbo/components/Loader";
import {
  CASH_CENTER,
  COUNTER_TERMINAL,
  POUCH_ACCEPTANCE,
  POUCH_ACCEPTANCE_DUPLICATE,
  POUCH_ACCEPTANCE_SUMMARY,
  POUCH_DESPATCH,
  POUCH_DESPATCH_DUPLICATE,
  POUCH_DESPATCH_SUMMARY,
  POUCH_PREPARATION_DUPLICATE,
  POUCH_PREPARATION_SUMMARY,
  SAFE,
} from "@bbo/constants";
import { CURRENCY_ITEM_ID } from "@bbo/constants/currencyItemID";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { PouchPreparationPopup } from "@bbo/features/PouchLadder/PouchPreparationPopup";
import { PrintIcon } from "@bbo/features/PouchLadder/SvgIcon";
import { useAppSelector, useDynamoPagination } from "@bbo/lib";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { useGlobalState } from "@bbo/lib/state";
import { PouchDespatchAcceptanceSummaryTemplate } from "@bbo/utils/ReceiptPrinterTemplates/PouchDespatchAcceptanceSummary";
import { amountUnSignedPound } from "@bbo/utils/currencyFormatter";
import {
  currentDateDDMMYYYY,
  currentOnlyTime,
  getDateTime,
  getUnixTimeStamp,
} from "@bbo/utils/dateTimeFormatter";
import { removeEmptyLinesFromAddress } from "@bbo/utils/receiptHelper";
import { PouchPreparationHistoryTemplate } from "@bbo/utils/ReceiptPrinterTemplates/PouchPreparationHistory";
import { PouchPreparationSummaryTemplate } from "@bbo/utils/ReceiptPrinterTemplates/PouchPreparationSummary";
import React, { useEffect, useState } from "react";
import {
  Filters,
  FromToFilter,
  SingleDropdownFilter,
} from "@bbo/components/DynamoTable/dynamoTableTypes";
import { DynamoTable } from "@bbo/components/DynamoTable/DynamoTable";

// Removes underscores and capitalises words
const transform = (value: string) =>
  value
    ?.split("_")
    .map((item) => {
      if (!item.length) return item;
      if (item.length === 1) return item.toUpperCase();
      return `${item[0].toUpperCase()}${item.substring(1)}`;
    })
    .join(" ");

type PrintDataInterface = PouchItem;

interface PouchHistoryFilters extends Filters {
  "updatedBy.userName": SingleDropdownFilter;
  "updatedBy.smartID": SingleDropdownFilter;
  pouchID: SingleDropdownFilter;
  pouchType: SingleDropdownFilter;
  timestamp: FromToFilter;
  totalValue: SingleDropdownFilter;
  status: SingleDropdownFilter;
}

const initialFilters: PouchHistoryFilters = {
  pouchID: {
    variant: "singleDropdown",
    placeholder: "Select",
    generateValues: true,
    values: [],
  },
  pouchType: {
    variant: "singleDropdown",
    placeholder: "Select",
    generateValues: true,
    values: [],
    transform,
  },
  "updatedBy.smartID": {
    variant: "singleDropdown",
    placeholder: "Select",
    generateValues: true,
    values: [],
  },
  "updatedBy.userName": {
    variant: "singleDropdown",
    placeholder: "Select",
    generateValues: true,
    values: [],
  },
  status: {
    variant: "singleDropdown",
    placeholder: "Select",
    generateValues: true,
    values: [],
    transform,
  },
  timestamp: {
    variant: "fromTo",
    unit: "date",
    // value: timestampTest,
  },
  totalValue: {
    variant: "singleDropdown",
    placeholder: "Select",
    generateValues: true,
    values: [],
  },
};

const PouchPreparationHistory = () => {
  const [param, setparam] = useState<any>({});
  const [showModal, setShowModal] = useState(false);
  const [printCondition, setprintCondition] = useState("printConfirm");
  const [openReprintConfirmationPopup, setOpenReprintConfirmationPopup] = useState<boolean>(false);
  const [openSuccessfulPrintConfirmationPopup, setOpenSuccessfulPrintConfirmationPopup] =
    useState<boolean>(false);
  const [printdata, setPrintData] = useState<PrintDataInterface>(null);
  const {
    data: pouchData,
    isRefetching: isPouchDataRefetching,
    isLoading: isPouchDataLoading,
  } = useGetPouch(printdata?.pouchID);
  const { data, hasNext, hasPrev, getNext, isLoading, getPrev, filters, setFilters } =
    useDynamoPagination<typeof useGetPouchList, GetPouchListQueryResult, GetPouchListQueryError>(
      useGetPouchList,
      {
        filters: initialFilters,
      },
      {},
      {},
    );

  const [cashdate] = useGlobalState("dateCashbalance");
  const [loader] = React.useState<boolean>(false);
  const [GlobalUSerName] = useGlobalState("userName");
  const { setTitle } = useTitle();
  const branchDetails = useAppSelector((root) => root.branchDetails);
  const { username, fadCode, smartid } = useAppSelector((root) => root.auth.authUserData);
  const receiptPrinterService = useReceiptPrinter();
  const [fullAndPartBagData, setFullAndPartBagData] = useState<any>([]);
  useEffect(() => {
    setTitle("Pouch History");
  }, [setTitle]);

  useEffect(() => {
    if (cashdate.todate) {
      const param = {
        fromDate: getUnixTimeStamp(cashdate.from),
        toDate: getUnixTimeStamp(cashdate.todate),
      };
      setparam(param);
    }
  }, [cashdate.from, cashdate.todate]);

  //Click row show details
  const handleRowClicked = (params: PrintDataInterface) => {
    // setFullAndPartBagData(null);
    // if (params.data.pouchBagType.includes("Part Bag")) {
    //   setFullAndPartBagData(combineFullAndPartCoinHistory(params.data.denominationLadder));
    // }
    setShowModal(true);
    setPrintData(params);
  };

  // List Item Function bind the data pouchDataData
  const listItems = () => {
    if (!pouchData?.items) return null;
    const data = Object.values(pouchData.items);

    const pouchDataData = data?.map((item) => {
      return {
        Currency: CURRENCY_ITEM_ID[item?.itemID],
        Quantity: item?.itemQuantity,
        Value: amountUnSignedPound(item?.itemValue),
      };
    });
    return pouchDataData;
  };
  //Pouch Edit Details Function bind the data pouchEditDetails
  // const pouchEditDetails = () => {
  //   const denominationData = printdata?.data?.pouchEditDetails?.map((editData, index) => {
  //     const denominationLadder = editData?.denominationLadder?.map((data) => {
  //       return {
  //         Currency: CURRENCY_ITEM_ID[data.itemID],
  //         Quantity: editData.pouchBagType.includes("Bag")
  //           ? editData?.pouchBagType?.includes("Part Bag")
  //             ? formattedQuantity(data.quantity) + "" + data.looseCoin
  //             : formattedQuantity(data.quantity)
  //           : data.looseCoin !== " " && data.quantity === 0
  //           ? data.looseCoin
  //           : formattedQuantity(data.quantity),
  //         Value:
  //           data.value !== 0 && data.valueInPence === 0
  //             ? amountUnSignedPound(data.value)
  //             : amountUnSignedPound(data.valueInPence),
  //       };
  //     });
  //     if (editData?.preparedBySmartID)
  //       return {
  //         preparedBySmartID: editData?.preparedBySmartID,
  //         preparedByUserID: editData?.preparedByUserID,
  //         editDate: formatDateYearMonthDay(editData?.date),
  //         editTime: getTime(editData?.date),
  //         TotalValue: amountUnSignedPound(editData?.totalPouchValue),
  //         listItems: denominationLadder,
  //       };
  //   });
  //   const newdenominationData = denominationData.filter((data) => data !== undefined);
  //   return newdenominationData;
  // };

  const printReciptsCall = (titleValue: string) => {
    const templates =
      titleValue === POUCH_PREPARATION_DUPLICATE ||
      titleValue === POUCH_ACCEPTANCE_DUPLICATE ||
      titleValue === POUCH_DESPATCH_DUPLICATE
        ? PouchPreparationHistoryTemplate
        : titleValue === POUCH_ACCEPTANCE_SUMMARY || titleValue === POUCH_DESPATCH_SUMMARY
        ? PouchDespatchAcceptanceSummaryTemplate
        : PouchPreparationSummaryTemplate;

    const branchAddressClean = removeEmptyLinesFromAddress(branchDetails.branch_address);
    receiptPrinterService.printReceipt({
      template: templates,
      context: {
        title: titleValue,
        fadcode: branchDetails.fadcode,
        branchName: branchDetails.branch_name,
        addressLine1: branchAddressClean[0],
        addressLine2: branchAddressClean[1],
        addressLine3: branchAddressClean[2],
        postcode: branchAddressClean[branchAddressClean.length - 1],
        currentDate: currentDateDDMMYYYY(),
        currentTime: currentOnlyTime(),
        createdByUserName: pouchData?.updatedBy.userName || username,
        createdBySmartID: pouchData?.updatedBy.smartID || smartid,
        dateTimeSystem: getDateTime(pouchData?.updatedBy.transactionTimestamp),
        pouchID: pouchData?.pouchID,
        pouchType: pouchData?.pouchType,
        list_items: listItems(),
        Location:
          pouchData?.status === "despatched" || pouchData?.status === "accepted"
            ? COUNTER_TERMINAL
            : SAFE,
        cashMovementType: pouchData?.status === "despatched" ? POUCH_DESPATCH : POUCH_ACCEPTANCE,
        from: pouchData?.status === "despatched" ? SAFE : CASH_CENTER,
        to: pouchData?.status === "despatched" ? CASH_CENTER : SAFE,
        terminalID: 0,
        TotalValue: amountUnSignedPound(pouchData?.totalValue),
      },
    });
  };

  // POUCH PREPARATION Receipt
  const printConfirmReceipt = () => {
    const titleContent =
      printdata?.status === "despatched"
        ? POUCH_DESPATCH_DUPLICATE
        : printdata?.status === "accepted"
        ? POUCH_ACCEPTANCE_DUPLICATE
        : POUCH_PREPARATION_DUPLICATE;
    printReciptsCall(titleContent);
  };

  const getPrintSummaryTitle = (status: PouchStatus) => {
    switch (status) {
      case "despatched":
        return POUCH_DESPATCH_SUMMARY;
      case "accepted":
        return POUCH_ACCEPTANCE_SUMMARY;
      default:
        return POUCH_PREPARATION_SUMMARY;
    }
  };

  //POUCH PREPARATION SUMMARY Receipt
  const printSummaryReceipt = () => {
    const title = getPrintSummaryTitle(pouchData?.status);
    printReciptsCall(title);
  };

  const onPrintConfirmation = () => {
    setOpenSuccessfulPrintConfirmationPopup(true);
    setShowModal(false);
    setprintCondition("printConfirm");
    printConfirmReceipt();
  };
  const onPrintSummary = () => {
    setOpenSuccessfulPrintConfirmationPopup(true);
    setShowModal(false);
    setprintCondition("printSummary");
    printSummaryReceipt();
  };

  const handleReprint = () => {
    setOpenSuccessfulPrintConfirmationPopup(false);
    setOpenReprintConfirmationPopup(true);
  };

  const reload = () => {
    setOpenSuccessfulPrintConfirmationPopup(false);
    setOpenReprintConfirmationPopup(false);
  };

  const handleReprintSuccess = () => {
    setOpenSuccessfulPrintConfirmationPopup(false);
  };

  const handleResendReprint = () => {
    if (printCondition === "printConfirm") {
      printConfirmReceipt();
    } else {
      printSummaryReceipt();
    }
    setOpenReprintConfirmationPopup(false);
  };

  return (
    <>
      <>
        {(loader || isLoading) && <Loader />}
        <div className="w-full transferOut-height">
          <div className="p-4 lg:w-full h-full relative dynamictable">
            <DynamoTable
              hasPrev={hasPrev}
              hasNext={hasNext}
              onPrev={getPrev}
              onNext={getNext}
              filters={filters}
              onRowClicked={handleRowClicked}
              setFilters={setFilters}
              data={data.data}
              columns={[
                {
                  heading: "Pouch ID",
                  fieldName: "pouchID",
                  format: "text",
                },
                {
                  heading: "Pouch Type",
                  fieldName: "pouchType",
                  format: "text",
                },
                {
                  heading: "Prepared By User Name",
                  fieldName: "updatedBy.userName",
                  format: "text",
                },
                {
                  heading: "Prepared By Smart ID",
                  fieldName: "updatedBy.smartID",
                  format: "text",
                },
                {
                  heading: "Date and Time Range",
                  fieldName: "timestamp",
                  format: "date",
                },
                {
                  heading: "Value",
                  fieldName: "totalValue",
                  format: "currencyVariancePence",
                },
                {
                  heading: "Status",
                  fieldName: "status",
                  format: "text",
                },
              ]}
            />
            <PouchPopupModel
              data={pouchData && !isPouchDataRefetching && !isPouchDataLoading ? pouchData : null}
              showModal={showModal}
              setShowModal={setShowModal}
              onPrintConfirmation={onPrintConfirmation}
              onPrintSummary={onPrintSummary}
            />
          </div>
        </div>
        {openSuccessfulPrintConfirmationPopup && (
          <PouchPreparationPopup
            headerIcon={<PrintIcon />}
            popupType="confirmSuccessfulPrint"
            messageBody="Print Successful."
            labelCancel="Cancel"
            labelReprint="Reprint"
            onCancel={reload}
            labelSuccess="Confirm Successful Print"
            onSuccess={handleReprintSuccess}
            OnReprint={handleReprint}
          />
        )}
        {openReprintConfirmationPopup && (
          <PouchPreparationPopup
            headerIcon={<PrintIcon />}
            popupType="confirmSuccessfulPrint"
            messageBody="Reprint Successful."
            labelCancel="Cancel"
            onCancel={reload}
            labelSuccess="Ok"
            onSuccess={handleResendReprint}
          />
        )}
        ;
      </>
    </>
  );
};

export default PouchPreparationHistory;
