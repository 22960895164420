/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  BboCashManagementCashBalanceDetails,
  BboCashManagementBadRequestResponse,
  UnauthorisedResponse,
  BboCashManagementForbiddenResponse,
  NotFoundResponse,
  FailureResponseResponse,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Returns last cash balance declaration of an accounting location
 * @summary Get latest cash declaration of an accounting location
 */
export const useGetLatestCashDeclarationHook = () => {
  const getLatestCashDeclaration = useCustomInstance<BboCashManagementCashBalanceDetails>();

  return (accountingLocationID: string) => {
    return getLatestCashDeclaration({
      url: `/BboCashManagementService/v1/cash-declaration/accounting-location/${accountingLocationID}`,
      method: "get",
    });
  };
};

export const getGetLatestCashDeclarationQueryKey = (accountingLocationID: string) => [
  `/BboCashManagementService/v1/cash-declaration/accounting-location/${accountingLocationID}`,
];

export type GetLatestCashDeclarationQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetLatestCashDeclarationHook>>
>;
export type GetLatestCashDeclarationQueryError = ErrorType<
  | BboCashManagementBadRequestResponse
  | UnauthorisedResponse
  | BboCashManagementForbiddenResponse
  | NotFoundResponse
  | FailureResponseResponse
>;

export const useGetLatestCashDeclaration = <
  TData = AsyncReturnType<ReturnType<typeof useGetLatestCashDeclarationHook>>,
  TError = ErrorType<
    | BboCashManagementBadRequestResponse
    | UnauthorisedResponse
    | BboCashManagementForbiddenResponse
    | NotFoundResponse
    | FailureResponseResponse
  >,
>(
  accountingLocationID: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetLatestCashDeclarationHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetLatestCashDeclarationQueryKey(accountingLocationID);

  const getLatestCashDeclaration = useGetLatestCashDeclarationHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetLatestCashDeclarationHook>>
  > = () => getLatestCashDeclaration(accountingLocationID);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetLatestCashDeclarationHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!accountingLocationID, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};
