import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { useEffect } from "react";
import { IEntityData } from "@bbo/features/StockCount";
import { StockCountReview } from "@bbo/features/StockCountReview";
import { getValueFromStorage } from "@bbo/utils/util";

const StockCountReviewScreen = () => {
  const { setTitle } = useTitle();
  const entityData: IEntityData = getValueFromStorage("selectedALName");

  useEffect(() => {
    setTitle(`Stock Count | ${entityData?.entityName}`);
  }, [setTitle, entityData]);

  return (
    <>
      <StockCountReview entityData={entityData} />
    </>
  );
};

export default StockCountReviewScreen;
