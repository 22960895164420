import { PouchPreparationDetails, useTitle } from "@bbo/features";
import { useEffect } from "react";

export const PouchPreparation = () => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Pouch Preparation");
  }, [setTitle]);

  return <PouchPreparationDetails />;
};
