import React, { useCallback } from "react";
import {
  DropdownValue,
  Filter,
  SingleDropdownFilter as BaseSingleDropdownFilter,
} from "../../dynamoTableTypes";
import { SingleDropdown } from "../SingleDropdown";

interface SingleDropdownFilterProps {
  fieldName: string;
  filter: BaseSingleDropdownFilter;
  onFilterChange: (filterKey: string, filter: Filter) => void;
}

export const SingleDropdownFilter = ({
  fieldName,
  filter,
  onFilterChange,
}: SingleDropdownFilterProps) => {
  const { values, placeholder } = filter;

  const onChange = useCallback(
    (newValue: DropdownValue) => {
      const newValues = values.map((value) => ({
        ...value,
        selected: value.value === newValue.value && !!newValue.value,
      }));

      onFilterChange(fieldName, {
        ...filter,
        values: newValues,
      });
    },
    [fieldName, filter, onFilterChange, values],
  );

  return <SingleDropdown placeholder={placeholder} values={values} onChange={onChange} />;
};
