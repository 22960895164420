import Loader from "@bbo/components/Loader";
import { useCurrentBpTp } from "@bbo/lib";
import { useEffect } from "react";
import { BpTpView } from "./BpTpView";
import { useBpTP } from "./useBpTP";

const CurrentBpTp = ({ periodName, periodType }) => {
  const { SetIsPeriodName } = useBpTP();
  const {
    status,
    cashCommodity,
    postCreateBp,
    isCreateBpSuccess,
    postCreateTp,
    isCreateTpSuccess,
    createTpResponse,
    createBpResponse,
    transactionPostBody,
    postBpTpTransaction,
    isBpTpTransactionSuccess,
    fromHistory,
    fromDiscrepancyAccount,
    isCreateTpError,
    isCreateBpError,
    isBpTpTransactionError,
  } = useCurrentBpTp(periodName, periodType);

  const BpTpProps = {
    status,
    cashCommodity,
    periodName,
    periodType,
    fromHistory,
    fromDiscrepancyAccount,
  };
  const postBpTPProps = {
    postCreateBp,
    isCreateBpSuccess,
    postCreateTp,
    isCreateTpSuccess,
    createTpResponse,
    createBpResponse,
    isCreateTpError,
    isCreateBpError,
  };
  const transactionProps = {
    transactionPostBody,
    postBpTpTransaction,
    isBpTpTransactionSuccess,
    isBpTpTransactionError,
  };

  useEffect(() => {
    SetIsPeriodName(periodName);
  }, [SetIsPeriodName, periodName]);

  return (
    <>
      {status === "loading" && <Loader />}
      <BpTpView
        BpTpProps={BpTpProps}
        postBpTPProps={postBpTPProps}
        transactionProps={transactionProps}
      />
    </>
  );
};

export default CurrentBpTp;
