import React from "react";
import { columnStyle } from "../table/utility";
import { DynamoTableFilter } from "./DynamoTableFilters/DynamoTableFilter";
import { Column } from "./dynamoTableTypes";

interface DynamoTableColumnProps {
  column: Column;
  className?: string;
  onHeaderSelection?: (params) => void;
  // onSortChanged?: (params) => void;
  checkedList?: boolean[];
  // hideSortIcon?: boolean;
}

export const DynamoTableColumn = ({
  column,
  checkedList,
  className,
  onHeaderSelection,
}: DynamoTableColumnProps) => {
  return (
    <th key={`col-${column.fieldName}`}>
      <div className={`header-column-container flex-col items-start`}>
        <span id={column.fieldName} className="header-col-name">
          {column.heading}
        </span>
      </div>
    </th>
  );
};
