import Warning2 from "@bbo/assets/images/Warning2.svg";
import { Popup } from "@bbo/components/Popup";

interface IStockCountWarningPopupProps {
  onYes: () => void;
  onNo: () => void;
}

export const StockCountWarningPopup = ({ onYes, onNo }: IStockCountWarningPopupProps) => {
  return (
    <Popup
      body={
        <div className="flex flex-col px-8 items-center text-center">
          <div>
            <img src={Warning2} className="pb-10 pt-4" />
          </div>
          <div className="popup-alert-text mb-4">Warning</div>
          <div className="leading-relaxed text-color px-8 amountHighLevel text-center mb-8">
            Are you sure you want to cancel this <br />
            stock count?
          </div>
        </div>
      }
      hideCrossBtn
      actions={[
        {
          name: "No",
          style: "popUp-button white-button mr-4",
          handleAction: onNo,
        },
        {
          name: "Yes",
          style: "popUp-button red-button",
          handleAction: onYes,
        },
      ]}
    />
  );
};
