import { cognitoConfig, forgerockConfig } from "@bbo/config";
import { featureFlags } from "@bbo/constants";
import { useMemo } from "react";
import { useFeatureFlag } from "./useFeatureFlag";

export const useAWSConfig = () => {
  const [shouldUseFederatedSignIn, loadingConfig] = useFeatureFlag(
    featureFlags.shouldUseFederatedSignIn,
  );

  const authConfig = useMemo(() => {
    return shouldUseFederatedSignIn ? forgerockConfig : cognitoConfig;
  }, [shouldUseFederatedSignIn]);

  return { authConfig, loadingConfig };
};
