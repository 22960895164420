import {
  BranchCashOverviewResponseResponse,
  CashCommodityResponseResponse,
  GetBpTpResponseResponse,
  GetCashDeclarationHistoryResponseResponse,
  GetPouchResponseResponse,
} from "@bbo/api/generator";
import { getTpBpHistoryMockData } from "@bbo/screens/TpBpHistoryMockData";
import { rest, setupWorker } from "msw";
import { getGetPouchList200Response, handlers } from "./newCashMockApi";
import { faker } from "@faker-js/faker";

export const baseURL = "https://api.spm-dev.com";

const MAX_ARRAY_LENGTH = 20;

let i = 0;
const next = () => {
  if (i === Number.MAX_SAFE_INTEGER - 1) {
    i = 0;
  }
  return i++;
};
const getBpTpResponseResponse: GetBpTpResponseResponse = {
  BP: 12,
  TP: 123,
  fadcode: "2341221",
  itemDescription: "BP",
  declarationID: "FY2023-002-001#V0000000000#S01",
  totalVarianceValue: 1250000,
  timestamp: 1661037773,
  TPstartTimestamp: 1661037783,
  TPendTimestamp: 1661037789,
  BPstartTimestamp: 1661038789,
  BPendTimestamp: 1661037989,
  cashCommodity: {
    varianceValue: 12000,
    accountingLocations: {
      28: {
        varianceValue: 1200,
        balancingDeclID: "938748",
        accountingLocationName: "Cash Drawer - 28",
      },
      2: {
        varianceValue: 15000,
        balancingDeclID: "3764",
        accountingLocationName: "Cash Drawer - 2",
      },
      3: {
        varianceValue: 1200,
        balancingDeclID: "24343",
        accountingLocationName: "Cash Drawer - 3",
      },
      4: {
        varianceValue: 34000,
        balancingDeclID: "32423",
        accountingLocationName: "Cash Drawer - 4",
      },
    },
  },
  forexCommodity: {
    varianceValue: 123000,
    accountingLocations: {
      12: {
        varianceValue: 3000,
        balancingDeclID: "string",
      },
      13: {
        varianceValue: 4000,
        balancingDeclID: "34543",
      },
      14: {
        varianceValue: 5000,
        balancingDeclID: "3425423",
      },
      15: {
        varianceValue: 6000,
        balancingDeclID: "433",
      },
    },
  },
  obsoleteStockCommodity: {
    varianceValue: 0,
    accountingLocations: {
      property1: {
        varianceValue: 0,
        balancingDeclID: "23443",
      },
      property2: {
        varianceValue: 0,
        balancingDeclID: "3423",
      },
    },
  },
  updatedBy: {
    smartID: "12672533420",
    userName: "SUMIT",
    timestamp: 1661037773,
  },
};

const cashCommodityResponseResponse: CashCommodityResponseResponse = {
  data: [
    {
      accountingLocationID: "CD-0",
      accountingLocationName: "Cash Drawer-0",
      accountingLocationType: "cash",
      status: "Incomplete",
      totalValue: 15000,
      timestamp: 1678701721,
      toDateVariance: 50,
      currentVariance: 1000,
      BP: 28,
      TP: 12,
      declarationID: "FY2023-002-001#V0000000000#C00",
      declarationCompleted: true,
    },
    {
      accountingLocationID: "CD-1",
      accountingLocationName: "Cash Drawer-1",
      accountingLocationType: "cash",
      status: "Incomplete",
      totalValue: 15000,
      timestamp: 1678701721,
      toDateVariance: 150,
      currentVariance: 1000,
      lastDeclarationTimestamp: 1648704721,
      BP: 28,
      TP: 12,
      declarationID: "FY2023-002-001#V0000000000#C01",
      declarationCompleted: true,
    },
    {
      accountingLocationID: "CD-2",
      accountingLocationName: "Cash Drawer-2",
      accountingLocationType: "cash",
      status: "Accepted",
      totalValue: 16000,
      timestamp: 1678701721,
      toDateVariance: 100,
      currentVariance: 1100,
      lastDeclarationTimestamp: 1678724721,
      BP: 28,
      TP: 12,
      declarationID: "FY2023-002-001#V0000000000#C02",
      declarationCompleted: false,
    },
    {
      accountingLocationID: "CD-3",
      accountingLocationName: "Cash Drawer-3",
      accountingLocationType: "safe",
      status: "Incomplete",
      totalValue: 17000,
      timestamp: 1678701721,
      lastDeclarationTimestamp: 1678704721,
      toDateVariance: 200,
      currentVariance: 1200,
      BP: 28,
      TP: 12,
      declarationID: "FY2023-002-001#V0000000000#C03",
      declarationCompleted: true,
    },
    {
      accountingLocationID: "Safe-0",
      accountingLocationName: "Safe",
      accountingLocationType: "safe",
      status: "Accepted",
      totalValue: 17000,
      timestamp: 1678701721,
      lastDeclarationTimestamp: 1678704721,
      toDateVariance: 50,
      currentVariance: 1200,
      BP: 28,
      TP: 12,
      declarationID: "FY2023-002-001#V0000000000#S01",
      declarationCompleted: false,
    },
  ],
  progress: 60,
  totalVarianceValue: 500,
};

const getGetDiscrepancyList200Response = () => {
  return {
    data: [...new Array(faker.datatype.number({ min: 1, max: MAX_ARRAY_LENGTH })).keys()].map(
      (_) => ({
        reasonCode: faker.helpers.arrayElement([
          "Trading Period",
          "Transaction Corrections",
          "Cash Pouch Acceptance",
          "Transaction Corrections",
          "Cash Pouch Acceptance",
          "Transaction Correction",
          "Forex Pouch Acceptance",
          "Cash Pouch Despatch",
          "Banking Miss Key",
        ]),
        reasonCodeDescription: faker.lorem.slug(1),
        status: faker.helpers.arrayElement([
          "AwaitingBranchAction",
          "HelpRequested",
          "DisputeRaised",
          "Settled",
        ]),
        discrepancyRecordType: faker.helpers.arrayElement(["cash", "stock", "forex"]),
        discrepancyID: faker.helpers.arrayElement([
          "BP#2314010##FY2023-001-001",
          "TP#2314010##FY2023-001-001",
          "TC#2314010##FY2023-001-001",
        ]),
        BPDeclarationID: faker.lorem.slug(1),
        TPDeclarationID: faker.lorem.slug(1),
        discrepancyValue: faker.datatype.number(),
        timestamp: 1661037773,
        itemID: 9574,
        TCReference: "",
        accountReferenceID: "600000001310002023",
        branchRefenceNumber: "600000001310002023",
        comment: faker.lorem.slug(1),
        correctionMessage: faker.lorem.slug(1),
        updatedBy: {
          smartID: faker.lorem.slug(1),
          userName: faker.name.fullName(),
          timestamp: 1661037773,
          transactionID: faker.lorem.slug(1),
          transactionTimestamp: 1661037773,
          BasketClosingTimestamp: 1661037773,
        },
      }),
    ),
    lastEvaluatedKey: faker.lorem.slug(1),
  };
};

export const manualHandlers = [
  // Define manual helper overrides like the example below, or use worker.use: https://mswjs.io/docs/api/setup-worker/use

  // Need to include this before /bm-pouch-management/v1/pouch/:pouchId
  // otherwise /pouch/list will be matched to /pouch/:pouchId
  rest.get(`${baseURL}/bm-pouch-management/v1/pouch/list`, (_, res, ctx) => {
    const resultArray = [[ctx.status(200), ctx.json(getGetPouchList200Response())]];

    return res(...resultArray[next() % resultArray.length]);
  }),
  rest.get(`${baseURL}/bm-pouch-management/v1/pouch/history/:versionID`, (_, res, ctx) => {
    const json: GetPouchResponseResponse = {
      pouchID: "1343434242344",
      pouchType: "cash",
      pouchSubType: "useable_coins_part_bag",
      status: "prepared",
      suspendCount: 1, // this mock data is used
      totalValue: 25000,
      updatedBy: {
        smartID: "2314010",
        userName: "SUMIT",
        transactionTimestamp: 1661037773,
      },
      items: {
        property1: {
          itemValue: 15000,
          itemID: "655",
          itemQuantity: 3,
          denomination: 0,
          currency: "p",
        },
        property2: {
          itemValue: 10000,
          itemID: "656",
          itemQuantity: 5,
          denomination: 0,
          currency: "p",
        },
      },
    };

    const resultArray = [[ctx.status(200), ctx.json(json)]];
    return res(...resultArray[next() % resultArray.length]);
  }),
  rest.get(`${baseURL}/bm-pouch-management/v1/pouch/:pouchId`, (_, res, ctx) => {
    const json: GetPouchResponseResponse = {
      pouchID: "1343434242344",
      pouchType: "cash",
      pouchSubType: "useable_coins_full_bag",
      status: "prepared",
      suspendCount: 1, // this mock data is used
      totalValue: 25000,
      updatedBy: {
        smartID: "2314010",
        userName: "SUMIT",
        transactionTimestamp: 1661037773,
      },
      items: {
        property1: {
          itemValue: 15000,
          itemID: "655",
          itemQuantity: 3,
          denomination: 0,
          currency: "p",
        },
        property2: {
          itemValue: 10000,
          itemID: "656",
          itemQuantity: 5,
          denomination: 0,
          currency: "p",
        },
      },
    };

    const resultArray = [[ctx.status(200), ctx.json(json)]];
    return res(...resultArray[next() % resultArray.length]);
  }),

  rest.get(`${baseURL}/BboCashManagementService/v1/branch-overview`, (_, res, ctx) => {
    const json: BranchCashOverviewResponseResponse = [
      {
        BP: 31327,
        TP: 68521,
        accountingLocationID: "repellat",
        accountingLocationName: "Mrs. Bradford Abbott",
        accountingLocationType: "cashDrawer",
        counterID: "consectetur",
        counterName: "Beth Dibbert",
        currentVariance: 41919,
        lastDeclarationTimestamp: 1680513061,
        status: "Recount",
        timestamp: 93553,
        toDateVariance: 84631,
        totalValue: 39676,
      },
      {
        BP: 12345,
        TP: 32421,
        accountingLocationID: "john",
        accountingLocationName: "Mrs. Roger Abbott",
        accountingLocationType: "cashDrawer",
        counterID: "stanford",
        counterName: "Beth Steve",
        currentVariance: 41919,
        lastDeclarationTimestamp: 1680513061,
        status: "Recount",
        timestamp: 93553,
        toDateVariance: 84631,
        totalValue: 212121,
      },
      {
        BP: 31327,
        TP: 68521,
        accountingLocationID: "repellat",
        accountingLocationName: "Mrs. Bradford Abbott",
        accountingLocationType: "safe",
        currentVariance: 41919,
        lastDeclarationTimestamp: 1680513061,
        status: "Recount",
        timestamp: 93553,
        toDateVariance: 84631,
        totalValue: 39676,
      },
    ];
    const resultArray = [[ctx.status(200), ctx.json(json)]];
    return res(...resultArray[next() % resultArray.length]);
  }),

  rest.get(`${baseURL}/bm-declaration-service/v1/bp-tp`, (_, res, ctx) => {
    const resultArray = [[ctx.status(200), ctx.json(getTpBpHistoryMockData)]];
    return res(...resultArray[next() % resultArray.length]);
  }),
  // Including this here so it doesn't get overridden by our /bm-declaration-service/v1/:declarationType override
  rest.get(`${baseURL}/bm-declaration-service/v1/discrepancy`, (_, res, ctx) => {
    const resultArray = [[ctx.status(200), ctx.json(getGetDiscrepancyList200Response())]];
    return res(...resultArray[next() % resultArray.length]);
  }),

  rest.get(`${baseURL}/bm-declaration-service/v1/:declarationType`, (_, res, ctx) => {
    const resultArray = [[ctx.status(200), ctx.json(getBpTpResponseResponse)]];
    return res(...resultArray[next() % resultArray.length]);
  }),
  rest.get(`${baseURL}/bm-declaration-service/v1/commodity/cash`, (_, res, ctx) => {
    const resultArray = [[ctx.status(200), ctx.json(cashCommodityResponseResponse)]];
    return res(...resultArray[next() % resultArray.length]);
  }),
  rest.get(`${baseURL}/BboCashManagementService/v1/cash-declaration/history`, (_, res, ctx) => {
    const cashData: GetCashDeclarationHistoryResponseResponse = {
      data: [
        {
          accountingLocationID: "occaecati",
          accountingLocationName: "Grady Bartell",
          accountingLocationType: "safe",
          declarationID: "CashBalancingL#2314010##FY2023-003-004#V1654356788",
          status: "Recount",
          totalValue: 80523,
          timestamp: 1681886215,
          toDateVariance: 48006,
          currentVariance: 69094,
          lastDeclarationTimestamp: 48757,
          updatedBy: {
            smartID: "ratione",
            userName: "Ron Luettgen",
            transactionID: "asperiores",
            transactionTimestamp: 1680513061,
          },
        },
        {
          accountingLocationID: "neque",
          accountingLocationName: "Sheila Fahey",
          accountingLocationType: "safe",
          declarationID: "CashBalancingL#2314010##FY2023-003-004#V1654356788",
          status: "Recount",
          totalValue: 45697,
          timestamp: 1681886215,
          toDateVariance: 5692,
          currentVariance: 87985,
          lastDeclarationTimestamp: 50851,
          updatedBy: {
            smartID: "quam",
            userName: "Juana Schmeler",
            transactionID: "odio",
            transactionTimestamp: 1681886215,
          },
        },
        {
          accountingLocationID: "neque",
          accountingLocationName: "Douglas Sporer",
          accountingLocationType: "safe",
          declarationID: "CashBalancingL#2314010##FY2023-003-004#V1654356788",
          status: "Recount",
          totalValue: 78758,
          timestamp: 1681886215,
          toDateVariance: 90360,
          currentVariance: 54095,
          lastDeclarationTimestamp: 21952,
          updatedBy: {
            smartID: "sapiente",
            userName: "Ms. Wilbert Gerhold",
            transactionID: "aspernatur",
            transactionTimestamp: 1681886215,
          },
        },
        {
          accountingLocationID: "ipsam",
          accountingLocationName: "Patrick Ernser",
          accountingLocationType: "safe",
          declarationID: "CashBalancingL#2314010##FY2023-003-004#V1654356788",
          status: "Incomplete",
          totalValue: 45697,
          timestamp: 42861,
          toDateVariance: 36127,
          currentVariance: 10901,
          lastDeclarationTimestamp: 81600,
          updatedBy: {
            smartID: "culpa",
            userName: "Jody Howell",
            transactionID: "cumque",
            transactionTimestamp: 1681886215,
          },
        },
      ],
      lastEvaluatedKey: "nostrum",
    };
    const resultArray = [[ctx.status(200), ctx.json(cashData)]];
    return res(...resultArray[next() % resultArray.length]);
  }),
];

// This configures a Service Worker with the given request handlers.
export const worker =
  process.env.NODE_ENV !== "test" ? setupWorker(...manualHandlers, ...handlers) : null;
export { rest };

export const startWorker = () => {
  worker?.start();
};
