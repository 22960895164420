import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core";
import "./style.css";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  dialogPaper: {
    height: "340px",
    "max-width": "728px",
  },
}));
interface Iprops {
  className?: string;
  isVisible: boolean;
  modalTitle?: string;
  modalText?: string;
  modalContent?: JSX.Element;
  buttons?: buttonProps[];
  handleClose?: () => void;
}

interface buttonProps {
  title?: string;
  btnStyle?: string;
  onPress?: () => void;
}

const AlertDialog = ({
  className,
  isVisible,
  modalTitle,
  modalText,
  modalContent,
  buttons,
  handleClose,
}: Iprops) => {
  const classes = useStyles();
  return (
    <div data-testid="test-description">
      <Dialog
        classes={{ paper: className || classes.dialogPaper }}
        open={isVisible}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">{modalTitle}</DialogTitle>
        <DialogContent>
          {modalContent == null ? (
            <DialogContentText
              id="alert-dialog-description"
              className={`txtStyle`}
              data-testid="dialog-text">
              {modalText}
            </DialogContentText>
          ) : (
            modalContent
          )}
        </DialogContent>
        <DialogActions className={`btnContainer`}>
          {buttons?.map((button, index) => {
            return (
              <Button
                variant="contained"
                key={`btn-${index}`}
                onClick={button.onPress}
                className={button.btnStyle || `btnStyle`}>
                {button.title}
              </Button>
            );
          })}
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default AlertDialog;
