import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface BranchDetails {
  branch_address: string;
  branch_name: string;
  branch_type: string;
  is_royal_mails_branch: boolean;
  fadcode: string;
}
const initialState: BranchDetails = {
  branch_address: "",
  branch_name: "",
  branch_type: "",
  fadcode: "",
  is_royal_mails_branch: false,
};

export const branchDetails = createSlice({
  name: "BranchDetails",
  initialState,
  reducers: {
    setBranchDetails: (state, action: PayloadAction<BranchDetails>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setBranchDetails } = branchDetails.actions;

export default branchDetails.reducer;
