/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  SuccessResponse,
  BboBranchConfigBadRequestResponse,
  BboBranchConfigUnauthorizedResponse,
  BboBranchConfigForbiddenResponse,
  BboBranchConfigFailureResponseResponse,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Get Configuration Details
 */
export const useGetGlobalHook = () => {
  const getGlobal = useCustomInstance<SuccessResponse>();

  return (id: string) => {
    return getGlobal({ url: `/BboBranchConfigService/v1/global/${id}`, method: "get" });
  };
};

export const getGetGlobalQueryKey = (id: string) => [`/BboBranchConfigService/v1/global/${id}`];

export type GetGlobalQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetGlobalHook>>
>;
export type GetGlobalQueryError = ErrorType<
  | BboBranchConfigBadRequestResponse
  | BboBranchConfigUnauthorizedResponse
  | BboBranchConfigForbiddenResponse
  | BboBranchConfigFailureResponseResponse
>;

export const useGetGlobal = <
  TData = AsyncReturnType<ReturnType<typeof useGetGlobalHook>>,
  TError = ErrorType<
    | BboBranchConfigBadRequestResponse
    | BboBranchConfigUnauthorizedResponse
    | BboBranchConfigForbiddenResponse
    | BboBranchConfigFailureResponseResponse
  >,
>(
  id: string,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<ReturnType<typeof useGetGlobalHook>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetGlobalQueryKey(id);

  const getGlobal = useGetGlobalHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetGlobalHook>>> = () =>
    getGlobal(id);

  const query = useQuery<AsyncReturnType<ReturnType<typeof useGetGlobalHook>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!id, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};
