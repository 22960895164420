import { useDescendant, useTabsContext } from "@bbo/lib";
import { ReactNode, useRef } from "react";

export const TabTitle = ({ children }: { children: ReactNode }) => {
  const ref = useRef<HTMLLIElement>(null);
  const { index, context } = useDescendant({ ref });

  const { activeTabIndex, handleClick, handleKeyboard, getId } = useTabsContext();
  const isActive = activeTabIndex === index;

  return (
    <li
      aria-controls={getId(index, "tabpanel")}
      aria-selected={isActive}
      data-testid={`tabPanel-${index}`}
      aria-hidden={!isActive}
      className={isActive ? "active" : ""}
      data-selected={isActive ? "" : undefined}
      id={getId(index)}
      key={index}
      onClick={handleClick(index)}
      onKeyDown={handleKeyboard(index, context)}
      ref={ref}
      role="tab"
      tabIndex={isActive ? 0 : -1}>
      {children}
    </li>
  );
};
