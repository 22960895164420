import { ErrorState } from "@bbo/lib";
import { clearFilters } from "@bbo/lib/hooks/useDynamoPagination/filterHelpers";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import "../table/style.css";
import { DynamoPagination } from "./DynamoPagination";
import { DynamoTableColumns } from "./DynamoTableColumns";
import { DynamoTableContext } from "./DynamoTableContext";
import { DynamoTableErrorRow } from "./DynamoTableErrorRow";
import { DynamoTableFilters } from "./DynamoTableFilters/DynamoTableFilters";
import { DynamoTableRows } from "./DynamoTableRows";
import { Column, Filter } from "./dynamoTableTypes";

interface DynamoTableProps<TData, TFilters, TError> {
  columns: Column[];
  data: TData[];
  onRowClicked?: (selectedRow: TData) => void;
  filters: TFilters;
  setFilters: Dispatch<SetStateAction<TFilters>>;
  selectedItemIndexes?: number[];
  setSelectedItemIndexes?: Dispatch<SetStateAction<number[]>>;
  shouldDisableRowCheckbox?: (item: TData, index: number) => boolean;
  hasPrev: boolean;
  hasNext: boolean;
  onPrev: () => void;
  onNext: () => void;
  hideResetFiltersButton?: boolean;
  error?: ErrorState;
  errorMessage?: string;
  resetFilters?: () => void;
}

export const DynamoTable = <TData, TFilters, TError>({
  columns,
  data,
  onRowClicked,
  filters,
  setFilters,
  selectedItemIndexes,
  setSelectedItemIndexes,
  shouldDisableRowCheckbox,
  hasPrev,
  hasNext,
  onPrev,
  onNext,
  hideResetFiltersButton,
  error,
  errorMessage,
  resetFilters,
}: DynamoTableProps<TData, TFilters, TError>) => {
  const onFilterChange = useCallback(
    (filterKey: string, filter: Filter) => {
      setFilters((prev) => ({
        ...prev,
        [filterKey]: filter,
      }));
    },
    [setFilters],
  );

  const handleFilterReset = () => {
    if (resetFilters) {
      resetFilters();
      return;
    }

    // Otherwise, fallback
    const clearedFilters = clearFilters(filters);
    setFilters(clearedFilters);
  };

  const maxSelections = useMemo(
    () =>
      shouldDisableRowCheckbox
        ? data.filter((value, index) => !shouldDisableRowCheckbox(value, index)).length
        : data.length,
    [data, shouldDisableRowCheckbox],
  );

  const allItemsSelected =
    selectedItemIndexes?.length && selectedItemIndexes.length === maxSelections;

  const onSelectAll = () => {
    if (!setSelectedItemIndexes) return;
    setSelectedItemIndexes((prev) =>
      prev.length !== maxSelections
        ? data
            .map((item, index) => (!shouldDisableRowCheckbox?.(item, index) ? index : undefined))
            .filter((item) => item !== undefined)
        : [],
    );
  };

  const onSelectChange = (index: number) => {
    if (!setSelectedItemIndexes) return;
    setSelectedItemIndexes((prev) =>
      prev.includes(index) ? prev.filter((i) => i !== index) : [...prev, index],
    );
  };

  return (
    <DynamoTableContext.Provider
      value={{
        maxSelections,
        allItemsSelected,
        selectedIndexes: selectedItemIndexes,
        showCheckboxes: !!selectedItemIndexes,
        onSelectAll,
        onSelectChange,
        shouldDisableRowCheckbox,
      }}>
      <div className="ui-table-view">
        <div className={"w-full dynamictableHeight overflow-y-auto"}>
          <div className="w-full text-right mb-1 divDummy">
            {!hideResetFiltersButton ? (
              <button type="button" className={"link-button"} onClick={handleFilterReset}>
                Reset All Filters
              </button>
            ) : null}
          </div>
          <table className="table-fixed dynamictable newTheme w-full border-collapse">
            <thead>
              <tr>
                <DynamoTableColumns columns={columns} />
              </tr>
            </thead>
            <tbody>
              <DynamoTableFilters
                columns={columns}
                filters={filters}
                onFilterChange={onFilterChange}
              />

              {data.length > 0 ? (
                <DynamoTableRows data={data} columns={columns} onRowClicked={onRowClicked} />
              ) : null}
            </tbody>
          </table>
          <DynamoTableErrorRow data={data} error={error} errorMessage={errorMessage} />
        </div>
        <div className="font-24 flex font-bold leading-7 fixed bottom-2 items-center full-width">
          <div className="w-full float-right">
            <DynamoPagination hasPrev={hasPrev} hasNext={hasNext} onPrev={onPrev} onNext={onNext} />
          </div>
        </div>
      </div>
    </DynamoTableContext.Provider>
  );
};
