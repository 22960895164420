/* eslint-disable react/jsx-key */
import { useState } from "react";
import downArrow from "../../assets/images/down.png";
import upArrow from "../../assets/images/up.png";
import "./index.css";
import Loader from "../Loader";

interface iProps {
  title?: string;
  content?: any;
  feature?: string;
  onClick?: (product) => void;
  onToggle?: () => void;
}

export default function Accordion(props: iProps) {
  const [isActive, setIsActive] = useState(false);
  const handleToggle = () => {
    props.onToggle();
    setIsActive(!isActive);
  };

  return (
    <>
      {isActive && !props?.content && <Loader />}
      {props.title && (
        <div className="accordion-item">
          <div className="accordion-title" onClick={() => handleToggle()}>
            <div className="accordionTitleName heading-3 pl-2 font-bold mb-3">
              {props.title}
              <label>
                {!isActive ? (
                  <img className="pt-3" src={downArrow} width="15" height="15" alt="User" />
                ) : (
                  <img className="pt-3" src={upArrow} width="15" height="15" alt="User" />
                )}
              </label>
            </div>
            <hr className="border-gray-200 border-bottom-none mb-2 w-full"></hr>
          </div>
          {isActive && <div className="accordion-content">{props.content}</div>}
        </div>
      )}
    </>
  );
}
