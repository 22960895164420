interface HeaderProps {
  children: React.ReactNode;
}

export const Header = ({ children }: HeaderProps) => (
  <div className=" p-4  items-center justify-start">
    <h2 className="text-blueGray-500 text-lg leading-relaxed heading-5 font-bold text-color">
      {children}
    </h2>
  </div>
);
