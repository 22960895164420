/* eslint-disable no-empty */
import { useGetBpTpDetails } from "@bbo/api/generator";
import { DiscrepancyDeclaration } from "@bbo/api/generator/model/discrepancyDeclaration";
import { DISCREPANCY_STATUS } from "@bbo/constants";
import { useAppSelector } from "@bbo/lib";
import { useGlobalState } from "@bbo/lib/state";
import { getStockUnitIdentifier } from "@bbo/utils/transferPayload";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  BACK_OFFICE,
  COMMENT,
  DISCREPANCY_SETTLEMENT,
  PAID_IN,
  PAID_OUT,
  SETTLE_CASH,
} from "./constant";
import { DetailedTableView } from "./DetailedTableView";
import DisputePopUp from "./DisputePopUp";
import HighLevelPopUp from "./HighLevelPopUp";
import "./index.css";
import SettleMentPopUp from "./SettleMentPopUp";
import { useDiscrepancyAccount } from "./useDiscrepancyAccount";
import { useDiscrepancyPage } from "./useDiscrepancyPage";

interface iProps {
  DetailedViewData?: DiscrepancyDeclaration;
}

export const DetailedView = ({ DetailedViewData }: iProps) => {
  const [, setDetailedViewModel] = useGlobalState("DetailedViewModel");
  const [showSettleDispute] = useGlobalState("showSettleDispute");
  const [disConfimBtn, setdisConfimBtn] = useState(true);
  const [comment, setComment] = React.useState<string>("");
  const [status, setStatus] = useState<string>("");
  const navigate = useNavigate();
  const [selectValue] = useGlobalState("selectValue");
  const [apiChange, setapiChange] = useGlobalState("apiChange");
  const [showPopUP, setShowPopUp] = useState<boolean>(false);
  const [totalVariance, setTotalVariance] = useState<number>(0);
  const [selectedRowDetails, setSelectedRowDetails] = useState([]);
  const [disputePopUp, showDisputePopUp] = useState<boolean>(false);
  const { isDiscrepancyStatus, DiscrepancyApi, saveCashDetailsApi } = useDiscrepancyAccount();
  const { GetDiscrepancyID, isHistory, getBpTpDeclarationTitle } = useDiscrepancyPage();
  const branchDetails = useAppSelector((root) => root.branchDetails);
  const { username } = useAppSelector((root) => root.auth.authUserData);

  const declarationType: "BP" | "TP" | null = ["BP", "TP"].includes(DetailedViewData?.reasonCode)
    ? (DetailedViewData?.reasonCode as "BP" | "TP")
    : null;

  const { data: bpTpDeclaration } = useGetBpTpDetails(
    declarationType,
    {
      declarationID: DetailedViewData?.BPDeclarationID,
    },
    {
      query: {
        enabled: !!declarationType && !!DetailedViewData?.BPDeclarationID,
      },
    },
  );

  const bpTpDeclarationTitle = getBpTpDeclarationTitle(bpTpDeclaration, declarationType);

  const branchIndependent = "IN";
  //cannot bind to updateDiscrepancy payload type as it will call transaction engine

  // need to update Discrepancy Status Request Parm have missing attributes is commented below
  const updateDiscrepancyStatusRequestParms = {
    discrepencyDataID: DetailedViewData?.discrepancyID,
    status: status,
    comments: comment,
    TCReference: DetailedViewData?.TCReference,
    accountReferenceID: DetailedViewData?.accountReferenceID,
    transferID: DetailedViewData?.updatedBy?.transactionID,
    // clientReference: DetailedViewData?.clientReference,
    // branchRefenceNumber: DetailedViewData?.branchRefenceNumber,
    itemID: DetailedViewData?.itemID,
    reason: DetailedViewData?.reasonCode,
    reasonCodeDescription: DetailedViewData?.reasonCodeDescription,
    discrepancyValue: DetailedViewData?.discrepancyValue,
    // description: undefined,
    // discrepencyDateTime: undefined,
    // discrepencyName: undefined,
    // narration: undefined,
    // pouchNumber: undefined,
  };

  const saveCashRequestParams =
    selectValue?.data === "null"
      ? null
      : {
          transferAmount: totalVariance > 0 ? totalVariance : -totalVariance,
          fadcode: branchDetails?.fadcode,
          userName: username,
          reasonCode: DISCREPANCY_SETTLEMENT,
          comments: COMMENT,
          transferAt: BACK_OFFICE,
          operationType: totalVariance > 0 ? PAID_OUT : PAID_IN,
          stockunitIdentifier: getStockUnitIdentifier(
            selectValue?.data?.type,
            selectValue?.data?.label.slice(-1),
          ),
          ...(totalVariance < 0
            ? {
                destinationEntityType: selectValue?.data?.type,
                destinationentityID: selectValue?.data?.value,
              }
            : {
                sourceEntityType: selectValue?.data?.type,
                sourceentityID: selectValue?.data?.value,
              }),
        };

  const SubmitComment = () => {
    // eslint-disable-next-line no-empty
    if (!disConfimBtn) {
      setDetailedViewModel({ showModel: true });
    }
  };
  const handleComment = (e) => {
    const value = e.target.value;
    setComment(value);
    setdisConfimBtn(false);
  };

  const handleCashDetails = () => {
    saveCashDetailsApi(saveCashRequestParams, updateDiscrepancyStatusRequestParms);
  };

  const handleUpdateCall = () => {
    if (selectValue.ShowSelect === SETTLE_CASH) {
      handleCashDetails();
    } else DiscrepancyApi(updateDiscrepancyStatusRequestParms);
    setShowPopUp(false);
    showDisputePopUp(false);
  };

  const handleStatus = (status) => {
    setSelectedRowDetails([DetailedViewData]);
    const TotalVariance = DetailedViewData?.discrepancyValue;
    //  = [DetailedViewData].reduce(
    //   (total, value) => total + Number(value?.entity?.itemDetails?.toDateVariance),
    //   0,
    // );
    switch (status) {
      case "help":
        setStatus(DISCREPANCY_STATUS.HELP_REQUESTED);
        showDisputePopUp(true);
        break;
      case "settled":
        setStatus(DISCREPANCY_STATUS.SETTLED);
        showDisputePopUp(false);
        setShowPopUp(true);
        setTotalVariance(TotalVariance);
        break;
      case "dispute":
        setStatus(DISCREPANCY_STATUS.DISPUTE_RAISED);
        showDisputePopUp(true);
        break;
      default:
        setStatus("");
        break;
    }
  };

  const handleExitPreview = () => {
    setDetailedViewModel({ showModel: true });
  };
  useEffect(() => {
    if (isDiscrepancyStatus) {
      setDetailedViewModel({ showModel: true });
    }
  }, [isDiscrepancyStatus, setDetailedViewModel]);
  const balancePeriodUrl = `../balancePeriod/${encodeURIComponent(
    DetailedViewData?.BPDeclarationID,
  )}`;
  const tradingPeriodUrl = `../tradingPeriod/${encodeURIComponent(
    DetailedViewData?.BPDeclarationID,
  )}`;

  return (
    <div className="flex flex-col lg:flex-row w-full rounded">
      <div className="px-4 py-10 md:p-6 mx-auto w-full mainlist">
        <div className="flex justify-between ">
          <div className="DetailHeadingText pl-2 mb-3">
            {DetailedViewData?.reasonCodeDescription}
          </div>
          {GetDiscrepancyID(DetailedViewData?.BPDeclarationID) === "BP" && (
            <div
              className="DetailSideHeadingText"
              onClick={() => {
                navigate(balancePeriodUrl, {
                  state: {
                    from: "discrepancyAccount",
                    balancingId: DetailedViewData?.discrepancyID,
                  },
                });
              }}>
              Go to Balance Period
            </div>
          )}
          {GetDiscrepancyID(DetailedViewData?.BPDeclarationID) === "TP" && (
            <div
              className="DetailSideHeadingText"
              onClick={() => {
                navigate(tradingPeriodUrl, {
                  state: {
                    from: "discrepancyAccount",
                    balancingId: DetailedViewData?.discrepancyID,
                  },
                });
              }}>
              Go to Trading Period
            </div>
          )}
        </div>
        <div className="flex w-full shadowDetailsView my-4v">
          <div className="border-0 w-2/5 overflow-y-auto  bg-white outline-none focus:outline-none p-4	">
            <DetailedTableView
              DetailedTable={DetailedViewData}
              bpTpDeclarationTitle={bpTpDeclarationTitle}
            />
          </div>
          <div className="border-0 w-3/5	  overflow-y-auto  bg-white outline-none focus:outline-none p-4	">
            <div className="m-4">
              <div className="commentStyleBtn " onClick={() => SubmitComment}>
                Comment
              </div>
              <textarea
                disabled={DetailedViewData?.status === DISCREPANCY_STATUS.SETTLED}
                className=" w-full textArea shadow "
                rows={21}
                value={comment || DetailedViewData?.comment}
                onChange={handleComment}
                placeholder="Please provide comments for your records."></textarea>
            </div>
          </div>
        </div>
        <div className="flex justify-between mt-6">
          <div>
            <button
              className="accounting-button ml-2 ExitPreviewStyle"
              onClick={() => handleExitPreview()}>
              Exit Preview
            </button>
          </div>
          {isHistory && (
            <>
              {" "}
              {showPopUP && branchDetails.branch_type !== branchIndependent && !disputePopUp && (
                <HighLevelPopUp
                  selectedRow={selectedRowDetails}
                  totalAmount={totalVariance}
                  onSuccess={() => handleUpdateCall()}
                  onCancel={() => setShowPopUp(false)}
                />
              )}
              {showPopUP && branchDetails.branch_type === branchIndependent && !disputePopUp && (
                <SettleMentPopUp
                  totalAmount={totalVariance}
                  onSuccess={() => handleUpdateCall()}
                  onCancel={() => setShowPopUp(false)}
                />
              )}
              {!showPopUP && disputePopUp && (
                <DisputePopUp
                  comment={comment || DetailedViewData?.comment}
                  handleComment={handleComment}
                  onSuccess={() => handleUpdateCall()}
                  onCancel={() => {
                    setComment("");
                    setShowPopUp(false);
                    showDisputePopUp(false);
                  }}
                />
              )}
              {(DetailedViewData?.status === DISCREPANCY_STATUS.HELP_CODE ||
                DetailedViewData?.status === DISCREPANCY_STATUS.HELP_REQUESTED) &&
                showSettleDispute.showSettleDisputeButton && (
                  <div className="flex w-full justify-end">
                    <button
                      className="brandWhiteBtn mr-2  font-bold RightalignBtn"
                      onClick={() => handleStatus("settled")}>
                      Settle
                    </button>
                    {DetailedViewData?.reasonCode !== DISCREPANCY_STATUS.TC && (
                      <button
                        className="accounting-button ml-2 RightalignBtn"
                        onClick={() => handleStatus("dispute")}>
                        Dispute
                      </button>
                    )}
                  </div>
                )}
              {(DetailedViewData?.status === DISCREPANCY_STATUS.AWAITING_CODE ||
                DetailedViewData?.status === DISCREPANCY_STATUS.AWAITING_BRANCH_ACTION) &&
                showSettleDispute.showSettleDisputeButton && (
                  <div className="flex w-full justify-end">
                    {DetailedViewData.reasonCode === DISCREPANCY_STATUS.TC && (
                      <button
                        className="accounting-button ml-2 RightalignBtn"
                        onClick={() => handleStatus("dispute")}>
                        Dispute
                      </button>
                    )}
                    {DetailedViewData?.reasonCode === DISCREPANCY_STATUS.TP ||
                    DetailedViewData?.reasonCode === DISCREPANCY_STATUS.BP ? (
                      <button
                        className="brandWhiteBtn mr-2  font-bold RightalignBtn"
                        onClick={() => handleStatus("help")}>
                        Help
                      </button>
                    ) : (
                      ""
                    )}
                    <button
                      className="accounting-button ml-2 RightalignBtn"
                      onClick={() => handleStatus("settled")}>
                      Settle
                    </button>
                  </div>
                )}{" "}
              {(DetailedViewData?.status === DISCREPANCY_STATUS.DISPUTE_CODE ||
                DetailedViewData?.status === DISCREPANCY_STATUS.DISPUTE_RAISED) &&
                showSettleDispute.showSettleDisputeButton && (
                  <div className="flex w-full justify-end">
                    <button
                      className="accounting-button ml-2 RightalignBtn"
                      onClick={() => handleStatus("settled")}>
                      Settle
                    </button>
                  </div>
                )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};
