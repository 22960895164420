import { useState } from "react";
import { Categories } from "./Categories";
import "./index.css";
import { ProductDetails } from "./ProductDetails";
import SearchForm from "./Searchform";

export const StockCheck = () => {
  const [selectedOption, setSelectedOption] = useState("");

  const handleProductDetails = (product: string) => {
    setSelectedOption(product);
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-4 bg-white">
        <div>
          <Categories productName={selectedOption} handleSelect={handleProductDetails} />
        </div>
        <div className="col-span-2 bg-color-stock h-screen">
          <div>
            <SearchForm onChange={handleProductDetails} />
          </div>
          <div className="p-10">
            <ProductDetails product={selectedOption} />
          </div>
        </div>
      </div>
    </>
  );
};
