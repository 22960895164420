import { useGlobalState } from "@bbo/lib/state";
import React from "react";
import { DateCalender, DateRange, DateRangeWithoutTime, TimeRange } from "../dateRange";
import Dropdown from "../dropdown";
import InputSearch from "./InputSearch";
import AmountRangeFilter from "./amountRangeFilter";
import SearchFilter from "./searchFilter";
import { Column, DateRangeParam } from "./types";
import { UniqueFilters, getFilters } from "./utility";

/**
 * Filter row component
 * @param props
 * @returns
 */
interface iProps<T> {
  columns: Column[];
  rows: any[];
  filters: any[];
  TransactionType?: T[];
  secondSearch?: T[];
  smartSearch?: boolean;
  dateRange?: DateRangeParam<T>;
  onFilterChanged?: (params) => void;
  isDateRangeSelected?: boolean;
  checkedList?: boolean[];
  onHeaderSelection?: (params) => void;
  onRowSelection?: (params) => void;
  onSelectAll?: (params) => void;
}

const FilterNode = <T,>(props: iProps<T>) => {
  const [cashBalanceFilters] = useGlobalState("cashBalanceFilters");
  const isSelectAll =
    props.checkedList && props.checkedList.length !== 0
      ? props.checkedList.every((check) => check)
      : false;
  const handleOnFilterChanged = (params: any) => {
    if (props.onFilterChanged) {
      props.onFilterChanged(params);
    }
    if (props.onHeaderSelection) {
      props.onHeaderSelection({});
    }
  };

  const handleOnChange = (e, colIndex, col) => {
    if (props.onHeaderSelection) {
      const mParams = {
        field: "checkbox",
        column: col,
        columnIndex: colIndex,
        checked: e.target.checked || false,
        data: props.rows,
      };

      props.onHeaderSelection(mParams);
    }
  };

  const renderFilters = () => {
    const filter = getFilters(props.columns, props.rows);

    if (props.columns && props.columns.length > 0) {
      return props.columns.map((col, colIndex) => {
        if (col.filter && !col.suppressFilter && col.filter === "checkBox") {
          return (
            <td key={`col-filter-${colIndex}`}>
              <div className="cell-checkbox-header">
                <input
                  id={`col-checkbox`}
                  className="cell-checkbox"
                  name="select-all"
                  type="checkbox"
                  checked={isSelectAll || false}
                  onChange={(e) => {
                    handleOnChange(e, colIndex, col);
                  }}
                />
                <label title="Show Page Source" htmlFor={`col-0`}>
                  Select All
                </label>
              </div>
            </td>
          );
        } else if (col.filter && !col.suppressFilter && col.filter === "dropdown") {
          return (
            <td key={`col-filter-${colIndex}`}>
              <Dropdown
                name={col.field}
                items={
                  props.isDateRangeSelected
                    ? UniqueFilters(cashBalanceFilters[col.field].concat(filter[col.field]))
                    : filter[col.field]
                }
                activeFilters={props.filters}
                onChange={handleOnFilterChanged}
              />
            </td>
          );
        } else if (col.filter && !col.suppressFilter && col.filter === "searchFilter") {
          return (
            <td key={`col-filter-${colIndex}`}>
              <SearchFilter
                name={col.field}
                rows={props.rows}
                column={col}
                TransactionType={props.TransactionType}
                columnIndex={colIndex}
                activeFilters={props.filters}
                onChange={handleOnFilterChanged}
              />
            </td>
          );
        } else if (col.filter && !col.suppressFilter && col.filter === "InputSearch") {
          return (
            <td key={`col-filter-${colIndex}`}>
              <InputSearch
                name={col.field}
                rows={props.rows}
                secondSearch={props.secondSearch}
                column={col}
                columnIndex={colIndex}
                activeFilters={props.filters}
                onChange={handleOnFilterChanged}
                smartSearch={props?.smartSearch || false}
              />
            </td>
          );
        } else if (col.filter && !col.suppressFilter && col.filter === "DateRangeWithoutTime") {
          return (
            <td key={`col-filter-${colIndex}`}>
              <DateRangeWithoutTime
                fromDate={props?.dateRange?.from}
                toDate={props?.dateRange?.to}
                activeFilters={props.filters}
                name={col.field}
                onChange={handleOnFilterChanged}
              />
            </td>
          );
        } else if (col.filter && !col.suppressFilter && col.filter === "datetime") {
          return (
            <td key={`col-filter-${colIndex}`}>
              <DateRange
                name={col.field}
                activeFilters={props.filters}
                onChange={handleOnFilterChanged}
              />
            </td>
          );
        } else if (!col.suppressFilter && col.headerName === "Time Range") {
          return (
            <td key={`col-filter-${colIndex}`}>
              <TimeRange
                name={col.field}
                onChange={handleOnFilterChanged}
                activeFilters={props.filters}
              />
            </td>
          );
        } else if (col.filter && !col.suppressFilter && col.filter === "amountRange") {
          return (
            <td key={`col-filter-${colIndex}`}>
              <AmountRangeFilter
                name={col.field}
                activeFilters={props.filters}
                placeholderStart={"From"}
                placeholderEnd={"To"}
                onChange={handleOnFilterChanged}
              />
            </td>
          );
        } else if (!col.suppressFilter && col.headerName === "Date") {
          return (
            <td key={`col-filter-${colIndex}`}>
              <DateCalender
                name={col.field}
                onChange={handleOnFilterChanged}
                activeFilters={props.filters}
              />
            </td>
          );
        } else if (col.suppressFilter) {
          return <td key={`col-filter-${colIndex}`}></td>;
        }
        return <td key={`col-filter-${colIndex}`}></td>;
      });
    }
    return null;
  };

  const filters = renderFilters();
  if (filters) {
    return <tr className="filterNode">{filters}</tr>;
  }

  return null;
};

export default React.memo(FilterNode);
