import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export type historicalBpTpAction = { title: string };
const initialState = { title: "" };

export const historicalBpTp = createSlice({
  name: "historicalBpTp",
  initialState,
  reducers: {
    setHistoricalBpTpTitle: (state, action: PayloadAction<historicalBpTpAction>) => {
      state.title = action.payload.title;
    },
  },
});

export const { setHistoricalBpTpTitle } = historicalBpTp.actions;

export default historicalBpTp.reducer;
