import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";
import { formatDateYearMonthDay } from "@bbo/utils/dateTimeFormatter";

interface IAssociatedInformation {
  currentVariance: number;
  lastBalancedDateTime: number;
  toDateVariance: number;
}

export const StockCardData = ({
  currentVariance,
  lastBalancedDateTime,
  toDateVariance,
}: IAssociatedInformation) => {
  return (
    <table className="w-full">
      <tbody className="counterPad">
        <tr className={currencyStyle(currentVariance)}>
          <td>Last Count Variance</td>
          <td>
            <b>{amountSignedPound(currentVariance)}</b>
          </td>
        </tr>
        <tr>
          <td>Last Count Date</td>
          <td>
            <b>{formatDateYearMonthDay(lastBalancedDateTime)}</b>
          </td>
        </tr>
        <tr>
          <td>To Date Variance</td>
          <td>
            <b>{amountSignedPound(toDateVariance)}</b>
          </td>
        </tr>
      </tbody>
    </table>
  );
};
