import { useCallback } from "react";
import { Filter, FreeTextFilter as BaseFreeTextFilter } from "../../dynamoTableTypes";
import { MultiInput } from "../MultiInput";

interface FreeTextFilterProps {
  fieldName: string;
  filter: BaseFreeTextFilter;
  onFilterChange: (filterKey: string, filter: Filter) => void;
}

export const FreeTextFilter = ({ fieldName, filter, onFilterChange }: FreeTextFilterProps) => {
  const { unit, value, placeholder } = filter;

  const onChange = useCallback(
    (newValue: string | number) => {
      onFilterChange(fieldName, {
        ...filter,
        value: newValue,
      });
    },
    [fieldName, filter, onFilterChange],
  );

  return (
    <MultiInput
      placeholder={placeholder}
      value={value}
      unit={unit}
      onChange={(newValue) => onChange(newValue)}
      onBlur={() => {
        // console.log("BLUR");
      }}
    />
  );
};
