import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { StockCheck } from "@bbo/features/StockCheck";
import { useEffect } from "react";

const StockCheckScreen = () => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Stock Check");
  }, [setTitle]);

  return <StockCheck />;
};

export default StockCheckScreen;
