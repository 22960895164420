import { TransactionType } from "@bbo/constants/TransactionViewConstants";
import { useState } from "react";

export const useCashMovementHistory = () => {
  const [modalData, setModalData] = useState({ showModal: false });
  const [versionID, setVersionID] = useState<string>();

  const handleRowClicked = (params) => {
    if (params.data.token.Tokens.pouchbarcode) {
      const pouchID = params.data.token.Tokens.pouchbarcode;
      const Timestamp = params.data.token.BasketClosedTime;
      const fadCode = params.data.token.fadCode;
      const ID = encodeURIComponent(`Pouch#${fadCode}##V${Timestamp}#${pouchID}`);

      setVersionID(ID);
    } else {
      setVersionID("");
    }
    setModalData({ ...params, showModal: true });
  };

  return {
    handleRowClicked,
    versionID,
    modalData,
    TransactionType,
  };
};
