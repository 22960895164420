import { useAppSelector } from "@bbo/lib";
import useStockApi from "@bbo/lib/hooks/useStockApi";
import { useGlobalState } from "@bbo/lib/state";
import { getValueFromStorage } from "@bbo/utils/util";
import { useEffect, useState } from "react";
import placeHolderImg from "../../assets/images/placeHolderImage.svg";
// interface iProduct {
//   product: string;
// }
// interface iDrawer {
//   drawer: string;
//   cash: number;
// }
interface iProps {
  product: string;
}
export const ProductDetails = (props: iProps) => {
  const { product } = props;

  const { isCtAccess } = useAppSelector((root) => root.counterTerminalAccess);
  const [productDetails, setProductDetails] = useState<any>({});
  const [initialCall, setInitialCall] = useState<boolean>(true);
  const [{ data: productDetailsResponse }, getProductDetails, refresh] = useStockApi(
    "",
    { fadcode: getValueFromStorage("fadcode"), searchedProduct: props.product },
    "",
    "GET",
  );

  const handleProductDetails = async () => {
    getProductDetails("getStockProductDetails");
    await productDetailsResponse;
    setInitialCall(false);
    if (!initialCall) {
      refresh();
    }
  };

  useEffect(() => {
    if (props.product) {
      handleProductDetails();
    }
  }, [props.product]);

  useEffect(() => {
    console.log(productDetailsResponse);
    setProductDetails(productDetailsResponse);
    // productDetailsResponse?.stockItems.map((data, i) => {
    //   console.log(data);
    // });
  }, [productDetailsResponse]);

  return (
    <>
      {!productDetailsResponse && (
        <div className="flex justify-center text-2xl font-bold mt-6">
          {isCtAccess
            ? "Scan product or enter barcode/product name on the top."
            : "Enter barcode/product name on the top."}
        </div>
      )}
      {productDetailsResponse && (
        <div className="col-span-2 mb-8">
          <div className="grid grid-cols-12 gap-4">
            <div className="col-span-1">
              <img
                src={productDetails?.productImage || placeHolderImg}
                width="63px"
                height="83px"
              />
            </div>
            <div className="col-span-7 pl-3 justify-start">
              <label className="font-medium">Product Description</label>
              <p>{productDetails?.description}</p>
            </div>
            <div className="col-span-12">
              <label className="font-medium">Branch stock on-hand - </label>
              <span>{productDetails?.quantity}</span>
            </div>
            <div className="col-span-5">
              <label className="font-medium">Accounting location and stock on-hand </label>
              {productDetails?.stockItems &&
                productDetails?.stockItems.map((data, i) => {
                  return (
                    <div key={i} className="grid grid-cols-2">
                      <div className="col-span-1">{data?.locationAccountName}</div>
                      <div className="col-span-1 font-medium">- {data?.quantity}</div>
                    </div>
                  );
                })}
            </div>
            <div className="col-span-12">
              <label className="font-medium">Price of the product - </label>
              <span>£ {(productDetails?.value / 100).toFixed(2)}</span>
            </div>
            <div className="col-span-12">
              <label className="font-medium">Product status - </label>
              <span>{productDetails?.productStatus || "Available"}</span>
            </div>
            <div className="col-span-12">
              <label className="font-medium">Orderable status - </label>
              <span>{productDetails?.orderableStatus || "Available"}</span>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
