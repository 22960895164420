/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  GetBpTpResponseResponse,
  BboDeclarationBadRequestResponse,
  UnauthorizedResponse,
  ForbiddenResponse,
  BboDeclarationFailureResponseResponse,
  GetBpTpDetailsParams,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Get details by BPDeclarationID or TPDeclarationID
 */
export const useGetBpTpDetailsHook = () => {
  const getBpTpDetails = useCustomInstance<GetBpTpResponseResponse>();

  return (declarationType: "BP" | "TP", params?: GetBpTpDetailsParams) => {
    return getBpTpDetails({
      url: `/bm-declaration-service/v1/${declarationType}`,
      method: "get",
      params,
    });
  };
};

export const getGetBpTpDetailsQueryKey = (
  declarationType: "BP" | "TP",
  params?: GetBpTpDetailsParams,
) => [`/bm-declaration-service/v1/${declarationType}`, ...(params ? [params] : [])];

export type GetBpTpDetailsQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetBpTpDetailsHook>>
>;
export type GetBpTpDetailsQueryError = ErrorType<
  | BboDeclarationBadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | BboDeclarationFailureResponseResponse
>;

export const useGetBpTpDetails = <
  TData = AsyncReturnType<ReturnType<typeof useGetBpTpDetailsHook>>,
  TError = ErrorType<
    | BboDeclarationBadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | BboDeclarationFailureResponseResponse
  >,
>(
  declarationType: "BP" | "TP",
  params?: GetBpTpDetailsParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetBpTpDetailsHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetBpTpDetailsQueryKey(declarationType, params);

  const getBpTpDetails = useGetBpTpDetailsHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetBpTpDetailsHook>>> = () =>
    getBpTpDetails(declarationType, params);

  const query = useQuery<AsyncReturnType<ReturnType<typeof useGetBpTpDetailsHook>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!declarationType, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};
