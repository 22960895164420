/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  GetPouchResponseResponse,
  BboPouchManagementBadRequestResponse,
  UnauthorizedResponse,
  BboPouchManagementForbiddenResponse,
  BboPouchManagementFailureResponseResponse,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get specific historical version for a single pouch
 * @summary Get specific historical version for a single pouch
 */
export const useGetPouchidVersionHook = () => {
  const getPouchidVersion = useCustomInstance<GetPouchResponseResponse>();

  return (versionID: string) => {
    return getPouchidVersion({
      url: `/bm-pouch-management/v1/pouch/history/${versionID}`,
      method: "get",
    });
  };
};

export const getGetPouchidVersionQueryKey = (versionID: string) => [
  `/bm-pouch-management/v1/pouch/history/${versionID}`,
];

export type GetPouchidVersionQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetPouchidVersionHook>>
>;
export type GetPouchidVersionQueryError = ErrorType<
  | BboPouchManagementBadRequestResponse
  | UnauthorizedResponse
  | BboPouchManagementForbiddenResponse
  | BboPouchManagementFailureResponseResponse
>;

export const useGetPouchidVersion = <
  TData = AsyncReturnType<ReturnType<typeof useGetPouchidVersionHook>>,
  TError = ErrorType<
    | BboPouchManagementBadRequestResponse
    | UnauthorizedResponse
    | BboPouchManagementForbiddenResponse
    | BboPouchManagementFailureResponseResponse
  >,
>(
  versionID: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetPouchidVersionHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetPouchidVersionQueryKey(versionID);

  const getPouchidVersion = useGetPouchidVersionHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetPouchidVersionHook>>> = () =>
    getPouchidVersion(versionID);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetPouchidVersionHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!versionID, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};
