import { Popup } from "@bbo/components/Popup";
import { useGlobalState } from "@bbo/lib/state";
import { useState } from "react";
import { StockCountPopupBody } from "./StockCountPopupBody";

import { StockCount, StockCountList } from "@bbo/api/generator";
import "./index.css";
import { CountedProduct } from "./StockCount";

interface IProduct {
  barcode: string;
  eanCode: string;
  itemCode: string;
  name: string;
  packSize: string;
}

interface ScannedProduct {
  name?: string;
}
interface CountTypesProps {
  countType: string;
  scannedProducts: CountedProduct[];
  handleCountSelect: (countType: string) => void;
  isCountSuspended: boolean;
  handleResume: () => void;
  triggerPrint: () => void;
  stockCount: StockCount | void;
}

export const CountTypes = ({
  countType,
  scannedProducts,
  handleCountSelect,
  isCountSuspended,
  handleResume,
  triggerPrint,
  stockCount,
}: CountTypesProps) => {
  const [, setCountTypeSelection] = useGlobalState("COUNTTYPE");
  const [, setShowOption] = useState<string>("");
  const [showPopUP, setShowPopUp] = useState<boolean>(false);
  const [, setBarCodeFormEnable] = useGlobalState("BarCodeFormEnable");
  const [CountTypeTitleShow, setCountTypeTitleShow] = useGlobalState("CountTypeTitleShow");

  const [popupData, setPopupData] = useState({
    actions: [],
    inProgress: false,
  });

  const handlePopupAndSelection = (isShow: boolean, itemName: string): void => {
    setShowPopUp(isShow);
    setShowOption(itemName);
  };

  const checkStatus = (inProgress: boolean, item: StockCountList): void => {
    setShowOption(item.id);
    handleCountSelect(item.name);
    let actions = [
      {
        name: `No`,
        handleAction: () => {
          setCountTypeTitleShow({ countTypeTitleShow: true });
          setBarCodeFormEnable({ barCodeFormEnable: false });
          handlePopupAndSelection(false, item.name);
        },
        style: "popUp-button white-button mt-8 flex-initial mr-4",
      },
      {
        name: "Yes",
        handleAction: () => {
          setCountTypeTitleShow({ countTypeTitleShow: false });
          setBarCodeFormEnable({ barCodeFormEnable: false });
          handlePopupAndSelection(false, item.name);
          triggerPrint();
        },
        style: "popUp-button red-button mt-8 flex-initial ml-4",
      },
    ];
    if (inProgress) {
      actions = [
        {
          name: `Cancel`,
          handleAction: () => {
            handlePopupAndSelection(false, "");
          },
          style: "popUp-button white-button mt-6 flex-initial mr-4",
        },
        {
          name: "Complete",
          handleAction: () => {
            handlePopupAndSelection(false, item.name);
          },
          style: "popUp-button red-button mt-6 flex-initial ml-4",
        },
      ];
    }
    setPopupData({
      ...popupData,
      actions,
      inProgress,
    });
    setShowPopUp(true);
  };

  const showScannedProductList = (
    products: ScannedProduct[],
  ): React.ReactElement<HTMLLIElement> | null => {
    return products?.length ? (
      <div>
        <div>
          <h1 className="nav-sub-heading">Last items counted</h1>
        </div>

        <div className="overflow-y-auto scroll-height">
          {products.map((product, i) => {
            return (
              <li key={i} className="subItemList">
                {product.name}
              </li>
            );
          })}
        </div>
      </div>
    ) : null;
  };

  return (
    <>
      {CountTypeTitleShow.countTypeTitleShow ? (
        <div className="nav-heading">Select Stock Count </div>
      ) : (
        <div className="nav-heading">
          <span className="text-2xl">Stock Count in progress</span>{" "}
          {isCountSuspended && (
            <button
              className="resumeButton"
              onClick={() => {
                handleResume();
              }}>
              <span className="resumeLabel">Resume</span>
            </button>
          )}
        </div>
      )}
      <div className="categoryList overflow-scroll overflow-x-hidden">
        <div className="">
          <div className=" grid grid-cols-1">
            <div className="grid col-span-12 group">
              <div className="grid grid-flow-row-dense grid-cols-12 cursor-pointer">
                {stockCount &&
                  stockCount?.stockCount?.length &&
                  stockCount?.stockCount.map((item) => {
                    return (
                      <div
                        className={
                          "col-span-12 itemList" + (item.name === countType ? " show-option " : " ")
                        }
                        style={{
                          display:
                            countType &&
                            countType.replace(/\s/g, "") !== item.name.replace(/\s/g, "")
                              ? "none"
                              : null,
                        }}
                        key={item.id}
                        onClick={() => {
                          setCountTypeSelection(item);
                          checkStatus(item.inProgress, item);
                        }}>
                        <span>{item.name}</span>
                      </div>
                    );
                  })}
              </div>
              <div className="grid grid-flow-row-dense grid-cols-12 ">
                <ul className="col-span-12">{showScannedProductList(scannedProducts)}</ul>
              </div>
            </div>
          </div>
        </div>
        {showPopUP && (
          <Popup
            body={<StockCountPopupBody inProgress={popupData.inProgress} />}
            actions={popupData.actions}
            hideCrossBtn={true}
          />
        )}
      </div>
    </>
  );
};
