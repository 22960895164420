//ADD,REMOVE,REPRINT, and all other flows will be handled here..
import { APIStatus } from "@bbo/interfaces/BpTp";
import "./index.css";
/**
 * Alert/Popup view with
 * @param props
 * @returns
 */

interface iProps {
  onSuccess?: (status: APIStatus) => void;
  title: string;
  status: APIStatus;
}

const CommitSuccessPopup = (props: iProps) => {
  const { title, status } = props;
  const onSuccess = (status: APIStatus) => {
    if (props.onSuccess) {
      props.onSuccess(status);
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 max-w-xl commit-success-popup">
          <div className="messageTitle">
            {status === "error" ? (
              <i className="fas fa-times paidCrossColor text-5xl bg-white"></i>
            ) : (
              <i className="fas fa-check text-5xl paidTickColor bg-white"></i>
            )}
          </div>
          <div className="messageBody">{title}</div>
          <div className="flex items-center pt-8 justify-center">
            <button
              className="text-base ok-btn text-white font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
              type="button"
              value="Confirm"
              onClick={() => onSuccess(status)}
              data-cy="Confirm">
              OK
            </button>
          </div>
        </div>
      </div>
      <div className="popUp-Overlay fixed inset-0 z-40"></div>
    </>
  );
};

export default CommitSuccessPopup;
