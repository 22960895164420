import WarningIcon from "../../assets/images/warning.png";
interface iProps {
  onCancel: (params) => void;
  onSuccess: (params) => void;
  cancelFlagName: string;
  popupData: {
    cancelNavButton: string;
    title: string;
    lines: string[];
  };
}

export const CancelCommitPopup = (props: iProps) => {
  const handleGoBackToDeclaration = () => {
    if (props.onCancel) {
      props.onCancel({
        passwordValidation: false,
        initial: true,
        cancelCommit: false,
        [props.cancelFlagName]: true,
      });
    }
  };
  const handleConfirmCancellation = () => {
    if (props.onSuccess) {
      props.onSuccess({
        passwordValidation: false,
        initial: false,
        cancelCommit: false,
      });
    }
  };

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="tp-popup-commit-container">
          <div className="tp-popup-header-view">
            <img className="tp-warning-icon" src={WarningIcon} alt="Warning" />
            <p className="tp-popup-title">Warning</p>
            <span className="tp-popup-content">
              {props.popupData.title}:
              <ul>
                {props.popupData.lines.map((value, index) => (
                  <li key={index}>{value}</li>
                ))}
              </ul>
            </span>
          </div>
          <div className="tp-login-btn-container">
            <button className="tp-btn-cancel mr-4" onClick={handleGoBackToDeclaration}>
              {props.popupData.cancelNavButton}
            </button>
            <button className="tp-btn-submit ml-4" onClick={handleConfirmCancellation}>
              Confirm Cancellation
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
