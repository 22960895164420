import { ForexCurrencyData } from "@bbo/api/generator";
import { Paper, Table, TableContainer, Button, Grid as Layout } from "@material-ui/core";

import Pagination from "@bbo/components/table/pagination";
import "./Grid.css";
import TableDataBody from "./GridBody";
import TableHeader from "./GridHeader";
import { Column } from "./types";
import { PAGINATION_SIZE } from "../table/tableConstants";

interface IHybridTableProps {
  cols: Column[];
  rows?: ForexCurrencyData[];
  currentPage?: number;
  onPaginationClick: any;
}

const Grid = ({ cols, rows, currentPage, onPaginationClick }: IHybridTableProps) => {
  return (
    <Layout container xl={12} md={12} spacing={4} style={{ margin: "auto" }}>
      <Layout item xl={12} md={12} className="layout">
        <TableContainer component={Paper}>
          <Table>
            <TableHeader cols={cols} />
            <TableDataBody cols={cols} rowData={rows} currentPage={currentPage} />
          </Table>
        </TableContainer>
      </Layout>
      <Layout item xl={12} md={12}>
        <Pagination
          className="pagination-bar flex justify-center"
          currentPage={currentPage}
          totalCount={rows?.length}
          pageSize={PAGINATION_SIZE}
          onPageChange={onPaginationClick}
        />
      </Layout>
    </Layout>
  );
};

export default Grid;
