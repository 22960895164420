import { Currency, MaterialType } from "@bbo/api/generator";
import { Col, ItemInfo, PouchDetailsItemsInfo } from "./pouchItemsInfo";

export interface DenominationLadderDisplayValue {
  itemID: string;
  currency: Currency;
  denomination: number;
  itemQuantity: string;
  itemValue: string;
  materialType: MaterialType;
  itemType: ItemInfo["itemType"];
  maxQuantityInBag?: number;
  totalInputState: "error" | "success" | "neutral";
}

// Same interface as PouchDetailsItemInfo but with the values as strings instead of numbers
export interface DenominationLadderDisplayValues {
  [itemID: string]: DenominationLadderDisplayValue;
}

export const getDenominationLadderDisplayValues = (
  denominationLadder: PouchDetailsItemsInfo,
): DenominationLadderDisplayValues =>
  Object.keys(denominationLadder).reduce((acc, curr) => {
    acc[curr] = {
      itemID: denominationLadder[curr].itemID,
      currency: denominationLadder[curr].currency,
      denomination: denominationLadder[curr].denomination,
      itemQuantity: `${denominationLadder[curr].itemQuantity}`,
      itemValue: `${denominationLadder[curr].itemValue / 100}`,
      materialType: denominationLadder[curr].materialType,
      itemType: denominationLadder[curr].itemType,
      maxQuantityInBag: denominationLadder[curr].maxQuantityInBag,
      totalInputState: "neutral",
    };

    return acc;
  }, {} as DenominationLadderDisplayValues);

export const getValuesFromDenominationLadderDisplayValues = (
  displayValues: DenominationLadderDisplayValues,
): PouchDetailsItemsInfo =>
  Object.keys(displayValues).reduce((acc, curr) => {
    const itemQuantity = Number(displayValues[curr].itemQuantity);
    const itemValue = Number(displayValues[curr].itemValue);
    acc[curr] = {
      itemID: displayValues[curr].itemID,
      currency: displayValues[curr].currency,
      denomination: displayValues[curr].denomination,
      itemQuantity: isNaN(itemQuantity) ? 0 : itemQuantity,
      itemValue: isNaN(itemValue) ? 0 : itemValue * 100,
      materialType: displayValues[curr].materialType,
      itemType: displayValues[curr].itemType,
      maxQuantityInBag: displayValues[curr].maxQuantityInBag,
    };

    return acc;
  }, {} as PouchDetailsItemsInfo);

export interface PouchSubTypeDenominationLadder {
  rows: { [baseItemID: string]: string[] };
  rowsOrder: string[];
  cols: Col[];
}

export const isDisplayValueRowValid = (
  rowKey: string,
  rows: PouchSubTypeDenominationLadder["rows"],
  displayValues: DenominationLadderDisplayValues,
) => {
  const rowValues = rows[rowKey];
  // For part bags the value isn't editable, so will always be valid
  if (rowValues.length > 1) return true;

  const { currency, denomination, itemQuantity, itemValue } = displayValues[rowValues[0]];

  if (isNaN(Number(itemValue))) return false;

  return Number(itemValue) / Number(itemQuantity) === denomination / (currency === "GBP" ? 1 : 100);
};

// rowValues: A denominationLadderForPouchSubTypes row
export const getTotalRowValue = (
  rowValues: string[],
  displayValues: DenominationLadderDisplayValues,
) =>
  rowValues.reduce((acc, curr) => {
    const currentValue = displayValues[curr].itemValue;
    if (isNaN(Number(currentValue))) {
      return acc;
    }
    return acc + Number(currentValue);
  }, 0);

export const getColumnsForInputRow = (
  rowValues: string[],
  cols: Col[],
  displayValues: DenominationLadderDisplayValues,
): Col[] => {
  // The length of the rowValues will be shorter than the length of the cols array
  // because it only contains the input keys. Therefore we need to convert it to be the same length as the
  // cols array
  let currentRowInputIndex = 0;

  return cols.map((col) => {
    if (col.type === "input") {
      const { itemID, itemQuantity } = displayValues[rowValues[currentRowInputIndex++]];
      return {
        ...col,
        displayValue: itemQuantity,
        itemID,
      };
    }
    if (col.type === "total" && rowValues.length > 1) {
      return {
        ...col,
        displayValue: `${getTotalRowValue(rowValues, displayValues)}`,
      };
    }
    if (col.type === "total") {
      const { itemID, itemValue } = displayValues[rowValues[0]];

      return {
        ...col,
        displayValue: itemValue,
        itemID,
      };
    }
    return col;
  });
};

export const calculateDisplayValuesTotalValueInPence = (
  displayValues: DenominationLadderDisplayValues,
) =>
  Object.values(displayValues).reduce((acc, curr) => {
    const currentValue = isNaN(Number(curr.itemValue)) ? 0 : Number(curr.itemValue);
    const currentValuePence = currentValue * 100;
    return acc + currentValuePence;
  }, 0);

export const sortRows = (
  rowsAndColumns: { rowKey: string; colsWithValue: Col[] }[],
  rowsOrder: string[],
) =>
  rowsAndColumns.sort((a, b) => {
    const indexA = rowsOrder.indexOf(a.rowKey);
    const indexB = rowsOrder.indexOf(b.rowKey);
    return indexA - indexB;
  });
