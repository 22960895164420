/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  GetCashDeclarationResponseResponse,
  BboCashManagementBadRequestResponse,
  UnauthorisedResponse,
  BboCashManagementForbiddenResponse,
  NotFoundResponse,
  FailureResponseResponse,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get cash declaration history by declarationID
 * @summary Get cash declaration history by declarationID
 */
export const useGetCashDeclarationHistoryIdHook = () => {
  const getCashDeclarationHistoryId = useCustomInstance<GetCashDeclarationResponseResponse>();

  return (declarationID: string) => {
    return getCashDeclarationHistoryId({
      url: `/BboCashManagementService/v1/cash-declaration/history/${declarationID}`,
      method: "get",
    });
  };
};

export const getGetCashDeclarationHistoryIdQueryKey = (declarationID: string) => [
  `/BboCashManagementService/v1/cash-declaration/history/${declarationID}`,
];

export type GetCashDeclarationHistoryIdQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetCashDeclarationHistoryIdHook>>
>;
export type GetCashDeclarationHistoryIdQueryError = ErrorType<
  | BboCashManagementBadRequestResponse
  | UnauthorisedResponse
  | BboCashManagementForbiddenResponse
  | NotFoundResponse
  | FailureResponseResponse
>;

export const useGetCashDeclarationHistoryId = <
  TData = AsyncReturnType<ReturnType<typeof useGetCashDeclarationHistoryIdHook>>,
  TError = ErrorType<
    | BboCashManagementBadRequestResponse
    | UnauthorisedResponse
    | BboCashManagementForbiddenResponse
    | NotFoundResponse
    | FailureResponseResponse
  >,
>(
  declarationID: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetCashDeclarationHistoryIdHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetCashDeclarationHistoryIdQueryKey(declarationID);

  const getCashDeclarationHistoryId = useGetCashDeclarationHistoryIdHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetCashDeclarationHistoryIdHook>>
  > = () => getCashDeclarationHistoryId(declarationID);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetCashDeclarationHistoryIdHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!declarationID, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};
