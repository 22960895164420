import { Tab } from "@bbo/components/Tab/Tab";
import CashDrawerList from "../BranchOverview/CashDrawerList";
import Counters from "../BranchOverview/CounterList";
import { Overview } from "./Sections/Overview";
import Loader from "@bbo/components/Loader";
import { useForexBranchOverview } from "./useForexBranchOverview";
import { SafeList } from "../BranchOverview/SafeList";

export const ForexAccountsContainer = () => {
  const contextValue = useForexBranchOverview();
  const { isLoading, accountingLocationGroups } = contextValue;

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Tab id="branchOviewOverTab">
      <Tab.TitleList>
        <Tab.Title data-testid="tab-0">Branch Overview</Tab.Title>
        <Tab.Title data-testid="tab-1">Counters</Tab.Title>
        <Tab.Title data-testid="tab-2">Safe</Tab.Title>
        <Tab.Title data-testid="tab-3">Cash Drawers</Tab.Title>
      </Tab.TitleList>

      <Tab.Panel className="w-full bg-white">
        <Tab.PanelContent>
          <Tab.PanelData>
            <Overview forexData={accountingLocationGroups} />
          </Tab.PanelData>
          <Tab.PanelData>
            <Counters items={accountingLocationGroups.counterTerminal} data-testid="counter-id" />
          </Tab.PanelData>
          <Tab.PanelData>
            <SafeList items={accountingLocationGroups.safe} />
            <CashDrawerList items={accountingLocationGroups.cashDrawer} showAll={false} />
          </Tab.PanelData>
          <Tab.PanelData>
            <CashDrawerList items={accountingLocationGroups.cashDrawer} showAll={true} />
          </Tab.PanelData>
        </Tab.PanelContent>
      </Tab.Panel>
    </Tab>
  );
};
