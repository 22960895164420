export const taskHistoryTitleProps = [
  {
    layout: "leftText w-1/5",
    title: "Task",
  },
  {
    layout: "leftText w-1/5",
    title: "Due Date",
  },
  {
    layout: "leftText width-15",
    title: "Status",
  },
  {
    layout: "leftText w-1/4",
    title: "Completed By",
  },
  {
    layout: "leftText w-1/5",
    title: "Completion Date",
  },
];
