import { currencyStyle } from "@bbo/utils/currencyFormatter";
import { v4 as uuidv4 } from "uuid";
import { formatCellData } from "./dynamoTableHelpers";
import { Column } from "./dynamoTableTypes";

interface DynamoTableCellProps<T> {
  cellData: T;
  column: Column;
}

export const DynamoTableCell = <T,>({ cellData, column }: DynamoTableCellProps<T>) => {
  return (
    <td className="" key={uuidv4()}>
      <div
        className={`TableFontStyle ${
          typeof cellData == "number" &&
          column.format === "currencyVariancePence" &&
          currencyStyle(cellData)
        }`}>
        {`${formatCellData(cellData as unknown as string | number | Date, column.format)}`}
      </div>
    </td>
  );
};
