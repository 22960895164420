import "./index.css";

interface InputProps {
  className?: string;
  spellCheck?: boolean;
  placeholder?: string;
  type: React.ElementType;
  props: Record<string, unknown>;
}

interface ValidateInput {
  errorMessage: string;
  style?: React.CSSProperties;
  isError: boolean;
  inputComponent: InputProps;
}

export const ValidateInput = ({ errorMessage, inputComponent, isError, style }: ValidateInput) => {
  const errorClass = isError ? "movementInput w-full error-border" : "movementInput w-full";
  return (
    <>
      {isError && <div className="error-message">{errorMessage}</div>}
      <inputComponent.type
        {...inputComponent.props}
        {...{ className: errorClass }}
        {...{ style }}
      />
    </>
  );
};
