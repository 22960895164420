import "./index.css";
/**
 * Alert/Popup view with
 * @param props
 * @returns
 */

export interface PoupchPreparationPopupProps {
  popupType?: string;
  title?: string;
  header?: string;
  content?: string;
  labelCancel?: string;
  messageBody?: string;
  labelSuccess?: string;
  logoIcon?: string;
  headerIcon?: any;
  labelReprint?: string;
  onSuccess?: () => void;
  onCancel?: () => void;
  OnReprint?: () => void;
}

const PouchPreparationPopup = ({
  popupType,
  labelCancel,
  labelSuccess,
  headerIcon,
  labelReprint,
  onSuccess,
  onCancel,
  OnReprint,
  messageBody,
}: PoupchPreparationPopupProps) => {
  return (
    <>
      <div className="pouch-preapre-wrapper justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div
          className={`relative my-6 max-w-xl pouch-prepare-popup ${
            popupType === "PouchPreparationSuccessfull"
              ? "pouchPreparationPopupDimension"
              : popupType === "confirmSuccessfulPrint"
              ? "confirmSuccessPrintPopupDimension"
              : popupType === "reprintConfirmationPopup"
              ? "reprintConfirmationPopupDimension"
              : ""
          }`}>
          <div className="messageTitle">{headerIcon}</div>

          <div className="messageBody">{messageBody}</div>

          <div className="flex items-center gap-5   justify-center">
            {labelCancel && (
              <button
                className="cancelBtn"
                type="button"
                value="No"
                data-cy="popupNo"
                onClick={onCancel}>
                {`${labelCancel}`}
              </button>
            )}
            {labelReprint && (
              <button className="reprint-btn" type="button" data-cy="popupNo" onClick={OnReprint}>
                {`${labelReprint}`}
              </button>
            )}
            <button
              className={`success-btn ${
                popupType === "PouchPreparationSuccessfull"
                  ? "print-receipt-btn"
                  : popupType === "confirmSuccessfulPrint"
                  ? "successful-print-btn"
                  : popupType === "reprintConfirmationPopup"
                  ? "reprint-success-btn"
                  : ""
              } `}
              type="button"
              value="Yes"
              data-cy="popupYes"
              onClick={onSuccess}>
              {labelSuccess}
            </button>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export { PouchPreparationPopup };
