import DateFnsUtils from "@date-io/date-fns"; // choose your lib
import { makeStyles } from "@material-ui/core";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import { addDays, subYears } from "date-fns";
import { useMemo } from "react";
import "./dateRange.css";

const useStyles = makeStyles({
  root: {
    "& .MuiFormLabel-root": {
      color: "#222",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#444444",
    },
    "&:focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#444444",
    },
    "& .MuiOutlinedInput-notchedOutline": {
      borderColor: "#444444",
    },
    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
      borderColor: "#444444",
      border: "1px solid #444444",
    },
    "& .MuiFormLabel-root.Mui-focused": {
      color: "#222",
    },
  },
  dateRangeWithoutTime: {
    "& MuiInputBase-root MuiInput-root MuiInput-underline MuiInputBase-formControl MuiInput-formControl":
      {
        borderColor: "#444444",
        border: "1px solid red",
      },
  },
});

const fromMinDate = +subYears(new Date(), 6);
const fromMaxDate = +new Date();

interface DateRangeProps<T extends string | number = number> {
  fromDate: T | undefined;
  toDate: T | undefined;
  onChange: (from: T, to: T) => void;
}

export const DateRange = <T extends string | number = number>({
  fromDate,
  toDate,
  onChange,
}: DateRangeProps<T>) => {
  const classes = useStyles();

  const toMinDate = useMemo(() => {
    if (fromDate) return fromDate;
    return +subYears(fromDate ? +new Date(fromDate) : new Date(), 6);
  }, [fromDate]);

  const toMaxDate = useMemo(() => {
    if (!fromDate) return +new Date();

    const today = +new Date();
    const fromDatePlus42Days = +addDays(+new Date(fromDate), 42);
    if (fromDatePlus42Days > today) return today;

    return fromDatePlus42Days;
  }, [fromDate]);

  return (
    <div className="po-date-range" id="id-po-date-picker">
      <MuiPickersUtilsProvider utils={DateFnsUtils}>
        <div className="po-date-picker">
          <div className="innerContainer">
            <DateTimePicker
              label="From"
              inputVariant="outlined"
              ampm={true}
              value={fromDate ?? Math.floor(Date.now() - 42 * 24 * 60 * 60 * 1000)}
              className={classes.root}
              // error={isInvalid}
              minDate={fromMinDate}
              maxDate={+new Date()}
              format="dd/MM/yyyy HH"
              openTo="date"
              onChange={(date) =>
                onChange(+date as T, toDate ? (+new Date(toDate) as T) : undefined)
              }
              autoOk
              views={["date", "hours"]}
            />
            <i className="far fa-calendar-alt"></i>
          </div>
          <div className="innerContainer">
            <DateTimePicker
              label="To"
              inputVariant="outlined"
              ampm={true}
              value={toDate ?? Math.floor(Date.now())}
              // error={isInvalid}
              className={classes.root}
              minDate={toMinDate}
              maxDate={toMaxDate}
              format="dd/MM/yyyy HH"
              onChange={(date) =>
                onChange(fromDate ? (+new Date(fromDate) as T) : undefined, +date as T)
              }
              autoOk={false}
            />
            <i className="far fa-calendar-alt"></i>
          </div>
        </div>
      </MuiPickersUtilsProvider>
    </div>
  );
};
