import { PouchDetails } from "@bbo/api/generator";
import { CURRENCY_ITEM_ID } from "@bbo/constants/currencyItemID";
import { amountUnSignedPound } from "@bbo/utils/currencyFormatter";
import { getOnlyDate, getOnlyTime } from "@bbo/utils/dateTimeFormatter";
import { formattedQuantity } from "@bbo/utils/util";
import React, { useState } from "react";
import dropUP from "../../assets/images/dropUP.svg";

interface EditHistoryDetailsProps {
  data: PouchDetails;
}

const pouchEditArray = [];

const EditHistoryDetails = (props: EditHistoryDetailsProps) => {
  const [viewActive, setViewActive] = useState(false);

  const toggleView = () => {
    setViewActive(!viewActive);
  };

  return (
    <>
      <tr>
        <td>
          <b>Edit History Details </b>
        </td>
        <td>
          <div className="flex justify-between">
            <button className="ml-2 flex colorBlue items-center" onClick={toggleView}>
              View Details{" "}
              <img
                className={`h-4 w-4 ${
                  viewActive ? "transform rotate-180 transition duration-150 ease-in-out" : ""
                }`}
                src={dropUP}
              />
            </button>
          </div>
        </td>
      </tr>
      {viewActive && (
        <>
          {/* {props.data.pouchEditDetails?.map((editData, index) => {  As we don't getting pouchEditDetails response hence we created dummy empty array  */}
          {pouchEditArray?.map((editData, index) => {
            return (
              editData.preparedByUserID && (
                <React.Fragment key={index}>
                  <tr>
                    <td>
                      <b>Edit by User Name</b>
                    </td>{" "}
                    <td>{editData.preparedByUserID}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Edited by Smart ID</b>
                    </td>{" "}
                    <td>{editData.preparedBySmartID}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Date</b>
                    </td>
                    <td>{getOnlyDate(editData.dateOfPreparation)}</td>
                  </tr>
                  <tr>
                    <td>
                      <b>Time</b>
                    </td>{" "}
                    <td>{getOnlyTime(editData.dateOfPreparation)}</td>
                  </tr>
                  <tr>
                    <td colSpan={2}>
                      <table className="breakdownTable">
                        <thead>
                          <tr>
                            <th>
                              <b>Denomination</b>
                            </th>
                            <th></th>
                            {["useable_coins_full_bag", "useable_coins_part_bag"].includes(
                              props.data?.pouchSubType,
                            ) ? (
                              props.data?.pouchSubType === "useable_coins_part_bag" ? (
                                <>
                                  <th>
                                    <b>No. of bags</b>
                                  </th>
                                  <th>
                                    <b>Loose Coin</b>
                                  </th>
                                </>
                              ) : (
                                <>
                                  <th>
                                    <b>No. of bags</b>
                                  </th>{" "}
                                  <th></th>
                                </>
                              )
                            ) : (
                              <th>
                                <b>Quantity</b>
                              </th>
                            )}
                            <th></th>
                            <th>
                              <b>Value</b>
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {editData.denominationLadder?.map((data, position) => {
                            return (
                              <tr key={position}>
                                <td>{CURRENCY_ITEM_ID[data.itemID]}</td>
                                <td>X</td>
                                {props.data?.pouchSubType?.includes("useable_coins_full_bag") ? (
                                  props.data?.pouchSubType?.includes("useable_coins_part_bag") ? (
                                    <>
                                      <td>{formattedQuantity(data.quantity)}</td>
                                      <td>{data.looseCoin}</td>
                                    </>
                                  ) : (
                                    <>
                                      <td>{formattedQuantity(data.quantity)}</td>
                                      <th></th>
                                    </>
                                  )
                                ) : (
                                  <>
                                    <td>
                                      {data.looseCoin !== " " && data.quantity === 0
                                        ? data.looseCoin
                                        : formattedQuantity(data.quantity)}
                                    </td>
                                  </>
                                )}
                                <td>=</td>
                                <td>
                                  {data.value !== 0 && data.valueInPence === 0
                                    ? amountUnSignedPound(data.value)
                                    : amountUnSignedPound(data.valueInPence)}
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </td>
                  </tr>
                </React.Fragment>
              )
            );
          })}
        </>
      )}
    </>
  );
};

export default EditHistoryDetails;
