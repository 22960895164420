import { useGetTransactions } from "@bbo/api/generatorNoFadcode";
import { DynamoTable } from "@bbo/components/DynamoTable/DynamoTable";
import {
  DropdownValue,
  Filters,
  FromToFilter,
  MultiDropdownFilter,
  SingleDropdownFilter,
} from "@bbo/components/DynamoTable/dynamoTableTypes";
import Loader from "@bbo/components/Loader";
import CashMovementsModal from "@bbo/components/layout/cashMovement-Popup";
import { useAppSelector } from "@bbo/lib";
import { useCustomPaginator } from "@bbo/lib/hooks/useCustomPaginator";
import { constructCashMovementData } from "@bbo/utils";
import { subDays } from "date-fns";
import { useMemo, useState } from "react";
import { useCashMovementHistory } from "./useCashMovementHistory";

interface CashMovementHistoryFilters extends Filters {
  userName: SingleDropdownFilter;
  smartID: SingleDropdownFilter;
  operationType: MultiDropdownFilter;
  dateTime: FromToFilter;
  transferAt: SingleDropdownFilter;
  sourceEntityName: SingleDropdownFilter;
  destinationEntityName: SingleDropdownFilter;
  transferAmount: FromToFilter;
}

const defaultFromDate = +subDays(+new Date(), 42);
const defaultToDate = +new Date();

const filterValuesSort = (value1: DropdownValue, value2: DropdownValue) => {
  if (value1.label.toLowerCase().includes("safe")) return -1;
  if (value2.label.toLowerCase().includes("safe")) return 1;

  // Extract numeric values from the strings
  const numA = parseInt(value1.label.replace(/\D/g, ""), 10);
  const numB = parseInt(value2.label.replace(/\D/g, ""), 10);

  // Sort by numeric values in ascending order
  return numA - numB;
};

const CashMovementHistoryPage = () => {
  const initialFilters: CashMovementHistoryFilters = {
    userName: {
      variant: "singleDropdown",
      placeholder: "Select",
      generateValues: true,
      values: [],
    },
    smartID: {
      variant: "singleDropdown",
      placeholder: "Select",
      generateValues: true,
      values: [],
    },
    operationType: {
      variant: "multiDropdown",
      placeholder: "Select",
      generateValues: false,
      values: [
        {
          label: "Pouch Preparation",
          value: "Pouch Preparation",
          selected: false,
        },
        {
          label: "Pouch Despatch",
          value: "Pouch Despatch",
          selected: false,
        },
        {
          label: "Pouch Acceptance",
          value: "Pouch Acceptance",
          selected: false,
        },
        {
          label: "Transfer In",
          value: "Transfer In",
          selected: false,
        },
        {
          label: "Transfer Out",
          value: "Transfer Out",
          selected: false,
        },
        {
          label: "Paid In",
          value: "Paid In",
          selected: false,
        },
        {
          label: "Paid Out",
          value: "Paid Out",
          selected: false,
        },
      ],
      transform: (value) => {
        if (value === "Pouch Preparation Cancel Pouch") return "Pouch Preparation";
        return value
          .toLowerCase()
          .split(" ")
          .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
          .join(" ");
      },
    },
    dateTime: {
      variant: "fromTo",
      unit: "date",
      value: {
        from: defaultFromDate,
        to: defaultToDate,
      },
    },
    transferAt: {
      variant: "singleDropdown",
      placeholder: "Select",
      generateValues: true,
      values: [],
    },
    sourceEntityName: {
      variant: "singleDropdown",
      placeholder: "Select",
      generateValues: true,
      values: [],
      filterValuesSort,
    },
    destinationEntityName: {
      variant: "singleDropdown",
      placeholder: "Select",
      generateValues: true,
      values: [],
      filterValuesSort,
    },
    transferAmount: {
      variant: "fromTo",
      unit: "currency",
      value: {
        from: null,
        to: null,
      },
    },
  };

  const { fadCode } = useAppSelector((rootState) => rootState.auth.authUserData);

  const [filters, setFilters] = useState(initialFilters);

  const { data, isLoading } = useGetTransactions(
    fadCode,
    Math.floor(filters.dateTime.value?.from / 1000),
    Math.floor(filters.dateTime.value?.to / 1000),
    {},
    {
      query: {
        keepPreviousData: false,
        cacheTime: 0,
        staleTime: 0,
      },
    },
  );

  const cashMovementData = useMemo(() => constructCashMovementData(data), [data]);

  const { paginatedData, getNext, getPrev, hasNext, hasPrev, resetFilters } = useCustomPaginator({
    data: cashMovementData,
    initialFilters,
    filters,
    setFilters,
  });

  const { handleRowClicked, modalData, versionID } = useCashMovementHistory();

  return (
    <>
      <div className="w-full transferOut-height">
        <div className="p-4 w-full h-full relative dynamictable">
          <DynamoTable
            hasPrev={hasPrev}
            hasNext={hasNext}
            resetFilters={resetFilters}
            // error={error}
            onRowClicked={(row) => handleRowClicked({ data: row })}
            onPrev={getPrev}
            onNext={getNext}
            filters={filters}
            setFilters={setFilters}
            data={paginatedData || []}
            columns={[
              {
                heading: "User Name",
                fieldName: "userName",
                format: "text",
              },
              {
                heading: "Smart ID",
                fieldName: "smartID",
                format: "text",
              },
              {
                heading: "Transaction Type",
                fieldName: "operationType",
                format: "text",
              },
              {
                heading: "Date and Time Range",
                fieldName: "dateTime",
                format: "date",
              },
              {
                heading: "Transaction Locations",
                fieldName: "transferAt",
                format: "text",
              },
              {
                heading: "From",
                fieldName: "sourceEntityName",
                format: "text",
              },
              {
                heading: "To",
                fieldName: "destinationEntityName",
                format: "text",
              },
              {
                heading: "Amount",
                fieldName: "transferAmount",
                format: "currencyPenceUnsigned",
              },
            ]}
          />
        </div>
      </div>
      {modalData.showModal && <CashMovementsModal data={modalData} versionID={versionID} />}
      {isLoading ? <Loader /> : null}
    </>
  );
};

export default CashMovementHistoryPage;
