import { BRANCH_OVERVIEW, CASH_DRAWERS, COUNTERS, SAFE } from "@bbo/constants";
import CashDrawerList from "./CashDrawerList";
import CounterList from "./CounterList";
import { Overview } from "./Overview";
import { CashManagementBranchOverviewContext } from "./BranchOverviewProvider";
import { useContext } from "react";
import { SafeList } from "./SafeList";

export const AccountingOverview = () => {
  const { selectedTab, accountingLocationGroups } = useContext(CashManagementBranchOverviewContext);

  // const [branchVarianceFeatureFlag] = useFeatureFlag(featureFlags.shouldShowBranchVariance);

  switch (selectedTab) {
    case BRANCH_OVERVIEW:
      return <Overview />;

    case COUNTERS:
      return <CounterList items={accountingLocationGroups?.counterTerminal} />;

    case CASH_DRAWERS:
      return <CashDrawerList items={accountingLocationGroups?.cashDrawer} showAll={true} />;

    case SAFE:
      return (
        <>
          <SafeList items={accountingLocationGroups?.safe} />
          <CashDrawerList items={accountingLocationGroups?.cashDrawer} showAll={false} />
        </>
      );

    // case ATM:
    //   return <AtmItem data={accountingLocationGroups?.atm} />;

    // case VARIANCE_SUMMARY:
    //   return (
    //     branchVarianceFeatureFlag && <BranchVarianceSummary rows={accountingLocationGroups?.varianceSummary} />
    //   );

    default:
      return null;
  }
};
