/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  GetDiscrepancyListResponseResponse,
  BboDeclarationBadRequestResponse,
  UnauthorizedResponse,
  ForbiddenResponse,
  BboDeclarationFailureResponseResponse,
  GetDiscrepancyListParams,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Discrepancy List
 */
export const useGetDiscrepancyListHook = () => {
  const getDiscrepancyList = useCustomInstance<GetDiscrepancyListResponseResponse>();

  return (params?: GetDiscrepancyListParams) => {
    return getDiscrepancyList({
      url: `/bm-declaration-service/v1/discrepancy`,
      method: "get",
      params,
    });
  };
};

export const getGetDiscrepancyListQueryKey = (params?: GetDiscrepancyListParams) => [
  `/bm-declaration-service/v1/discrepancy`,
  ...(params ? [params] : []),
];

export type GetDiscrepancyListQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetDiscrepancyListHook>>
>;
export type GetDiscrepancyListQueryError = ErrorType<
  | BboDeclarationBadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | BboDeclarationFailureResponseResponse
>;

export const useGetDiscrepancyList = <
  TData = AsyncReturnType<ReturnType<typeof useGetDiscrepancyListHook>>,
  TError = ErrorType<
    | BboDeclarationBadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | BboDeclarationFailureResponseResponse
  >,
>(
  params?: GetDiscrepancyListParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetDiscrepancyListHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetDiscrepancyListQueryKey(params);

  const getDiscrepancyList = useGetDiscrepancyListHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetDiscrepancyListHook>>
  > = () => getDiscrepancyList(params);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetDiscrepancyListHook>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};
