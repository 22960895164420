import React from "react";

interface MultiDropdownItemProps<T> {
  label: string;
  value: string;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
  selected: boolean;
}

export const MultiDropdownItem = <T,>({
  label,
  value,
  onChange,
  selected,
}: MultiDropdownItemProps<T>) => {
  return (
    <span className="row-option-checkbox-list">
      <input
        name={label}
        value={value}
        type="checkbox"
        className="filter-option-checkbox"
        checked={selected}
        onChange={onChange}
      />
      {label}
    </span>
  );
};
