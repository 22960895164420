import { BranchCashOverview } from "@bbo/api/generator";
import CurrencyInput from "react-currency-input-field";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import { TransferInOutError } from "../BranchOverview/branchOverviewHelpers";
import { useState } from "react";

interface TransferInputPanelProps {
  title: string;
  selectedAmount: CurrencyInputOnChangeValues;
  setSelectedAmount: React.Dispatch<React.SetStateAction<CurrencyInputOnChangeValues>>;
  selectedOtherAccountingLocation: BranchCashOverview | null;
  handleCashDetails: () => Promise<void>;
  error: TransferInOutError;
  setError?: React.Dispatch<React.SetStateAction<TransferInOutError>>;
}

export const TransferInputPanel = ({
  title,
  selectedAmount,
  setSelectedAmount,
  selectedOtherAccountingLocation,
  handleCashDetails,
  error,
  setError,
}: TransferInputPanelProps) => {
  const [invalidAmount, setInvalidAmount] = useState<boolean>(false);
  const disabled = error?.key === "exceededMaxSourceLimit" || !selectedOtherAccountingLocation;
  return (
    <div className={`transferIn-right-side bg-white pt-6 pl-8 ${disabled ? "" : "diableBtnCss"}`}>
      <h2 className="heading-3 font-bold mb-5">{title}</h2>
      <div className="h-4/5 flex w-full">
        <div className="grid grid-cols-1 gap-12 w-full">
          <div className={`flex items-center ${disabled ? "" : "diableBtnCss"}`}>
            <div className="transfer-out-section w-full flex flex-col justify-center items-center mt-5">
              {error?.type === "inputError" ? (
                <h2 className={`heading w-full text-center ${error.className}`}>
                  {error.message} {error.icon}
                </h2>
              ) : null}
              <div
                className={`justify-center flex-col cursor-pointer items-center flex block w-full ${
                  disabled ? "" : "diableBtnCss"
                }`}>
                <>
                  <h2 className="text-lg font-bold mb-5">Enter Amount</h2>
                  <CurrencyInput
                    prefix="£"
                    allowNegativeValue={false}
                    className={`brandWhiteBtn w-full mb-4 ${disabled ? "" : "diableBtnCss"}`}
                    disabled={disabled}
                    value={selectedAmount?.value}
                    onValueChange={(_, __, values) => {
                      setInvalidAmount(!Number(values.value));
                      setSelectedAmount(values);
                      setError(null);
                    }}
                  />
                  <button
                    className={`w-10/12 accountingBtnNoHover  ${
                      disabled || invalidAmount ? "accounting-button" : "diableBtnCss"
                    }`}
                    disabled={disabled || invalidAmount}
                    onClick={handleCashDetails}>
                    Submit
                  </button>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
