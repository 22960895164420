import Loader from "@bbo/components/Loader";
import { useHistoricalBpTp } from "@bbo/lib";
import { BpTpView } from "./BpTpView";

const HistoricalBpTp = ({ periodName, periodType }) => {
  const { cashCommodity, status, fromHistory, fromDiscrepancyAccount } = useHistoricalBpTp(
    periodName,
    periodType,
  );

  const BpTpProps = {
    status,
    cashCommodity,
    periodName,
    periodType,
    fromHistory,
    fromDiscrepancyAccount,
  };

  return (
    <>
      {status === "loading" && <Loader />}
      <BpTpView BpTpProps={BpTpProps} />
    </>
  );
};

export default HistoricalBpTp;
