import "./CountReview.css";
const ConfirmPopup = () => {
  return (
    <div className="forex-den-completed-alert-content">
      <div>{<i className="fas fa-check text-5xl paidTickColor bg-white"></i>}</div>
      <div className="description">Currency Count Completed</div>
    </div>
  );
};

export default ConfirmPopup;
