import { apiConfig } from "@bbo/config";
import { useResponse401Interceptor } from "@bbo/lib";
import { AxiosError, handleAxiosRequest } from "@bbo/utils/apiHelper";
import Axios, { AxiosRequestConfig } from "axios";

export const AXIOS_INSTANCE_NO_FADCODE = Axios.create({ baseURL: apiConfig.baseApi });

export const useCustomInstanceNoFadcode = <T>(): ((config: AxiosRequestConfig) => Promise<T>) => {
  const { response404Interceptor } = useResponse401Interceptor();

  AXIOS_INSTANCE_NO_FADCODE.interceptors.response.use(...response404Interceptor);

  return handleAxiosRequest(AXIOS_INSTANCE_NO_FADCODE);
};

export type ErrorType<Error> = AxiosError<Error>;
