import { useEffect, useState } from "react";

export const useFeatureFlag = (featureFlag: boolean) => {
  const [isActive, setIsActive] = useState<boolean>(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (featureFlag) {
      setIsActive(true);
    }
    setLoading(false);
  }, [featureFlag]);

  return [isActive, loading];
};
