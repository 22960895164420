import { useState } from "react";
import { Search, Close } from "@material-ui/icons";
import "./index.css";
export const InputSelect = ({ fullCurrencyList, setSearchedForCurrency }) => {
  const { currencys: currencies } = fullCurrencyList;
  const [inputValue, setInputValue] = useState("");
  const [open, setOpen] = useState(false);

  //alphabetically sort:
  currencies.sort((a, b) => {
    if (a.name < b.name) {
      return -1;
    }
    if (a.name > b.name) {
      return 1;
    }
    return 0;
  });

  const onInputChange = (e) => {
    setInputValue(e.target.value);
  };
  const onItemSelected = (option) => {
    setInputValue(option.name);
    setOpen(false);
    setSearchedForCurrency({ name: option.name, ordercode: option.ordercode });
  };
  const handleClearSearch = (e) => {
    setOpen(false);
    setInputValue("");
    setSearchedForCurrency(null);
  };

  // const handleOnBlur = () => {
  //   setTimeout(() => setOpen(false), 150);
  // };

  return (
    <div
      className="dropdown-container"
      onFocus={(e) => {
        setOpen(true);
      }}
      // onBlur={handleOnBlur}
    >
      <div className="input-container">
        <input
          type="text"
          value={inputValue}
          onChange={onInputChange}
          placeholder="Search Currency"
        />
        {(open || inputValue) && (
          <div className="x-icon-container" onClick={handleClearSearch}>
            <Close className="x-icon" />
          </div>
        )}
        <div className="search-icon-container">
          <Search className="search-icon" />
        </div>
      </div>
      <div className={`dropdown ${open ? "visible" : ""}`}>
        {inputValue &&
          currencies
            .filter((currency) => {
              const searchTerm = inputValue.toLowerCase();
              const comparisonWord = currency.name.toLowerCase();
              if (!searchTerm) return true;
              if (currency.name === "more") return false;
              return comparisonWord.startsWith(searchTerm);
            })
            .map((option, key) => (
              <div key={key} className="option" onClick={() => onItemSelected(option)}>
                {option.name}
              </div>
            ))}
      </div>
    </div>
  );
};
