export const SortingIcon = () => {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
      <g clipPath="url(#clip0_1606_37345)">
        <path d="M12 3L18 9H6L12 3Z" fill="white" />
        <path d="M12 20L18 14H6L12 20Z" fill="white" />
      </g>
      <defs>
        <clipPath id="clip0_1606_37345">
          <rect width="24" height="24" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};
