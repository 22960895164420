import { PouchSubType } from "@bbo/api/generator";
import { NewPouchLadder } from "../NewPouchLadder";
import { Dispatch, SetStateAction } from "react";
import { PouchDetailsItemsInfo } from "@bbo/utils";

interface DenominationLadderNewProps {
  pouchSubType: PouchSubType;
  denominationLadder: PouchDetailsItemsInfo;
  setDenominationLadder: Dispatch<SetStateAction<PouchDetailsItemsInfo>>;
  handleSuspendCheck: (updatedDenominationLadder: PouchDetailsItemsInfo) => void;
  handleLimitExceeds: () => void;
  handleLeavePouchPreparation: () => void;
  handleSubmitPouch: (updatedDenominationLadder: PouchDetailsItemsInfo) => void;
}

export const DenominationLadderNew = ({
  pouchSubType,
  denominationLadder,
  setDenominationLadder,
  handleSuspendCheck,
  handleLimitExceeds,
  handleLeavePouchPreparation,
  handleSubmitPouch,
}: DenominationLadderNewProps) => {
  return (
    <>
      <div className="flex items-center justify-center heading pouchPrepBox p-7">
        <h2 className="heading-3 font-bold">
          Enter denomination by quantity or value in the text boxes on the right-hand side.
        </h2>
      </div>
      <div className="flex items-center justify-center bg-white flex-col pouchPrepBox p-7">
        <NewPouchLadder
          pouchSubType={pouchSubType}
          denominationLadder={denominationLadder}
          setDenominationLadder={setDenominationLadder}
          handleSuspendCheck={handleSuspendCheck}
          handleLimitExceeds={handleLimitExceeds}
          handleLeavePouchPreparation={handleLeavePouchPreparation}
          handleSubmitPouch={handleSubmitPouch}
        />
      </div>
    </>
  );
};
