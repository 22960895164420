export const PrintIcon = () => {
  return (
    <svg width="68" height="60" viewBox="0 0 68 60" fill="none">
      <path
        d="M57.3333 16.6667H54V0H14V16.6667H10.6667C5.13334 16.6667 0.666672 21.1333 0.666672 26.6667V46.6667H14V60H54V46.6667H67.3333V26.6667C67.3333 21.1333 62.8667 16.6667 57.3333 16.6667ZM20.6667 6.66667H47.3333V16.6667H20.6667V6.66667ZM47.3333 53.3333H20.6667V40H47.3333V53.3333ZM54 40V33.3333H14V40H7.33334V26.6667C7.33334 24.8333 8.83334 23.3333 10.6667 23.3333H57.3333C59.1667 23.3333 60.6667 24.8333 60.6667 26.6667V40H54Z"
        fill="#2B60DE"
      />
      <path
        d="M54 31.6667C55.841 31.6667 57.3333 30.1743 57.3333 28.3333C57.3333 26.4924 55.841 25 54 25C52.1591 25 50.6667 26.4924 50.6667 28.3333C50.6667 30.1743 52.1591 31.6667 54 31.6667Z"
        fill="#2B60DE"
      />
    </svg>
  );
};

export const ReprintIcon = () => {
  return (
    <svg width="68" height="68" viewBox="0 0 68 68" fill="none">
      <path
        d="M30.6667 44H37.3334V50.6667H30.6667V44ZM30.6667 17.3334H37.3334V37.3334H30.6667V17.3334ZM33.9667 0.666687C15.5667 0.666687 0.666748 15.6 0.666748 34C0.666748 52.4 15.5667 67.3334 33.9667 67.3334C52.4001 67.3334 67.3334 52.4 67.3334 34C67.3334 15.6 52.4001 0.666687 33.9667 0.666687ZM34.0001 60.6667C19.2667 60.6667 7.33342 48.7334 7.33342 34C7.33342 19.2667 19.2667 7.33335 34.0001 7.33335C48.7334 7.33335 60.6668 19.2667 60.6668 34C60.6668 48.7334 48.7334 60.6667 34.0001 60.6667Z"
        fill="#B20101"
      />
    </svg>
  );
};
