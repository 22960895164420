import { WarningMsgPanel } from "@bbo/components";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { HomeTab } from "@bbo/features/HomeTab";
import { useEffect } from "react";

const Home = () => {
  // TODO: move to authentication

  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Task List");
  }, [setTitle]);
  const WarningMsg = "This accounting location is due an end of day balance by 7pm.";
  return (
    <>
      <div className="containerMain">
        <div className="w-full items-start transferOut-height overflow-hidden">
          <div className="flex flex-col lg:flex-row mb-6 w-full rounded shadow overflow-y-auto height-86">
            <div className="px-4 py-10 md:p-6 mx-auto w-full maintasklist mb-10">
              <div>
                <WarningMsgPanel Msg={WarningMsg} />
                <HomeTab />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Home;
