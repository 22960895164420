import { createContext, Dispatch, SetStateAction } from "react";

export interface CashDetail {
  itemID: string;
  Cash: string | number;
  Quantity: null | string | number;
  value: any; // need to fix error in adhoc balance ln 117
  cashType: string;
  currency: string;
  btnDisable: boolean;
  alertCss: string;
  QalertCss: string;
  errorMsg: string;
  QerrorMsg: string;
}
export const initialValue: CashDetailContextProps = {
  cashDetail: [
    {
      itemID: "",
      Cash: "",
      Quantity: "",
      value: "", // need to fix error in adhoc balance ln 117
      cashType: "",
      currency: "",
      btnDisable: true,
      alertCss: "",
      QalertCss: "",
      errorMsg: "",
      QerrorMsg: "",
    },
  ],
  accountingLocationID: "",
};

export interface CashDetailContextProps {
  cashDetail: CashDetail[];
  setCashDetail?: Dispatch<SetStateAction<CashDetail[]>>;
  accountingLocationID?: string;
  // resStatus?: string;
  // setResStatus?: Dispatch<SetStateAction<string>>;
}

export const CashDetailContext = createContext<CashDetailContextProps | null>(initialValue);
