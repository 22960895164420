import { Divider, Grid } from "@material-ui/core";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";

export const SearchedCurrencyTile = ({
  fullRange,
  searchedForCurrency,
  fnOpenDenominationLadder,
  countedCurrencies,
}) => {
  const header = fullRange.find((obj) => obj.ordercode === searchedForCurrency.ordercode)
    ? "Full Currencies "
    : searchedForCurrency.ordercode === "EUR" || searchedForCurrency.ordercode === "USD"
    ? "Top Sellers"
    : "On Hand Currencies";

  return (
    <>
      <Grid item md={12} key={`full--${1}`}>
        <span className="count-part">{`${header} `}</span>
      </Grid>
      <Grid
        item
        md={4}
        key={`full-${searchedForCurrency.ordercode}`}
        className="count-part-content">
        <button
          className="grid-box"
          onClick={() => {
            fnOpenDenominationLadder(searchedForCurrency.ordercode, searchedForCurrency.name);
          }}>
          <span className="count-part-content">{searchedForCurrency.name}</span>
        </button>
        {countedCurrencies.includes(searchedForCurrency.ordercode) && (
          <div className="tick-container">
            <CheckCircleIcon className="tick" fontSize="inherit" />
          </div>
        )}
      </Grid>
      <Grid item md={12} key={`full--${2}`}>
        <Divider className="divider" />
      </Grid>
    </>
  );
};
