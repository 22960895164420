export const rows = {
  Positive: [
    {
      currencyName: "Euros",
      systemQuantity: "420",
      enteredQuantity: "440",
      currencyVariance: "£20",
      sterlingValue: "£20",
      adjustments: "Recount",
      varianceType: "Positive",
    },
    {
      currencyName: "Australian Dollar",
      systemQuantity: "150",
      enteredQuantity: "240",
      currencyVariance: "A$90",
      sterlingValue: "£90",
      adjustments: "Recount",
      varianceType: "Positive",
    },
    {
      currencyName: "Croatian Kuna",
      systemQuantity: "1500",
      enteredQuantity: "1600",
      currencyVariance: "kn100",
      sterlingValue: "£100",
      adjustments: "Recount",
      varianceType: "Positive",
    },
    {
      currencyName: "New Zealand Dollar",
      systemQuantity: "10",
      enteredQuantity: "50",
      currencyVariance: "$40",
      sterlingValue: "£40",
      adjustments: "Recount",
      varianceType: "Positive",
    },
  ],

  Negative: [
    {
      currencyName: "South African Rand",
      systemQuantity: "150",
      enteredQuantity: "130",
      currencyVariance: "-R20",
      sterlingValue: "-£20",
      adjustments: "Recount",
      varianceType: "Negative",
    },
    {
      currencyName: "U.S Dollars",
      systemQuantity: "157",
      enteredQuantity: "145",
      currencyVariance: "-$12",
      sterlingValue: "-£12",
      adjustments: "Recount",
      varianceType: "Negative",
    },
    {
      currencyName: "Turkish Lira",
      systemQuantity: "347",
      enteredQuantity: "342",
      currencyVariance: "-₺5",
      sterlingValue: "-£5",
      adjustments: "Recount",
      varianceType: "Negative",
    },
  ],

  Zero: [
    {
      currencyName: "Canadian Dollar",
      systemQuantity: "50",
      enteredQuantity: "50",
      currencyVariance: "CAD$0",
      sterlingValue: "£0",
      adjustments: "Recount",
      varianceType: "Zero",
    },
    {
      currencyName: "Egyptian pound",
      systemQuantity: "300",
      enteredQuantity: "300",
      currencyVariance: "E£0",
      sterlingValue: "£0",
      adjustments: "Recount",
      varianceType: "Zero",
    },
  ],
};
export const cols = [
  "Currency name",
  "System Value",
  "Entered value",
  "Currency Variance",
  "Sterling Value",
  "Edit",
];
export const currentPageSize = 5;
export const totalSterlingValue = "Total Sterling Value :";

//export const PageTitle = "Currency Count | Counter 1 - Cash Drawer 1";
//export const PageSubTitle = "Euros & Dollars-Cash Drawer 1";

export enum varianceType {
  "Negative Variance" = "Negative Variance",
  "Positive Variance" = "Positive Variance",
  "Zero Variance" = "Zero Variance",
}
