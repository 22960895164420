import { ErrorState } from "@bbo/lib";

interface DynamoTableErrorRowProps<TData> {
  data: TData[];
  error?: ErrorState;
  errorMessage?: string;
}

export const DynamoTableErrorRow = <TData,>({
  data,
  error,
  errorMessage,
}: DynamoTableErrorRowProps<TData>) => {
  if (error && error.isError)
    return (
      <div className="noRecordFound noRecordFoundDynamo">
        <span>{errorMessage ? errorMessage : "Network Error"}</span>
      </div>
    );
  if (!data.length)
    return (
      <div className="noRecordFound noRecordFoundDynamo">
        <span>No Results Found</span>
      </div>
    );
};
