import { useGetSystemCashAnAccountingLocation } from "@bbo/api/generator";
import { usePostTransferTransaction } from "@bbo/api/generatorNoFadcode/endpoints/transfer-ap-i";
import { SCREENS } from "@bbo/constants";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { inputNumberInPence } from "@bbo/utils/currencyFormatter";
import { currentDateDDMMYYYY, currentOnlyTime } from "@bbo/utils/dateTimeFormatter";
import { removeEmptyLinesFromAddress } from "@bbo/utils/receiptHelper";
import { TransferOutTemplate } from "@bbo/utils/ReceiptPrinterTemplates/TransferOutTemplate";
import { transferOutParams } from "@bbo/utils/transferPayload";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  checkIfAccountingLocationActionInvalid,
  formatCounterTerminalName,
  getLegacyAccountingLocationType,
  hasExceededTotalFunds,
  TransferInOutError,
  transferInOutErrors,
  TransferInOutModals,
} from "../BranchOverview/branchOverviewHelpers";
import { actionListMap } from "../BranchOverview/BranchOverviewProvider";
import { OverviewLocationState } from "../BranchOverview/branchOverviewTypes";
import { BACK_OFFICE } from "../DiscrepancyAccount/constant";
import { useTransferInOut } from "../TransferInOut";

export const useTransferOut = () => {
  const {
    fadCode,
    userName,
    accountingLocationID,
    accountingLocation,
    selectedOtherAccountingLocation,
    setSelectedOtherAccountingLocation,
    otherAccountingLocationsGrouped,
    branchOverviewData,
    isBranchOverviewLoading,
    invalidAccountingLocation,
    selectedAmount,
    setSelectedAmount,
    branchDetails,
    receiptPrinterService,
    activeTab,
    setActiveTab,
    tabs,
  } = useTransferInOut();

  const navigate = useNavigate();
  const [systemValue, setSystemValue] = useState<number>(0);
  const { mutate: postTransferOut, isSuccess, isError, isLoading } = usePostTransferTransaction();

  const [error, setError] = useState<TransferInOutError | null>(null);
  const [activeModal, setActiveModal] = useState<TransferInOutModals | null>(null);
  const { MaxBranchLimit: selectedRule } = useAppSelector((root) => root);

  const { data: SystemValue } = useGetSystemCashAnAccountingLocation(accountingLocationID);

  useEffect(() => {
    if (SystemValue) {
      setSystemValue(SystemValue.systemValue);
    }
  }, [SystemValue, accountingLocationID]);

  useEffect(() => {
    if (!selectedAmount) return null;
    setError(null);
  }, [selectedAmount]);

  const checkErrors = () => {
    // The limit that has been used for the limit check is completely wrong, the £26k value applies only to the pouch preparation.
    // The correct limit depends on the counter type, which we will not have access to until R3 at the earliest.
    // Therefore the limit check has been disabled for now
    //
    // if (hasExceededPermittedSourceLimit(selectedRule, selectedAmount)) {
    //   setError(transferInOutErrors.exceededPermittedSourceLimit);
    //   return true;
    // }
    // if (hasExceededMaxDestinationLimit(selectedRule, selectedAmount)) {
    //   setError(transferInOutErrors.exceededMaxDestinationLimit);
    //   return true;
    // }
    // if (hasExceededMaxSourceLimit(selectedRule, selectedAmount)) {
    //   setError(transferInOutErrors.exceededMaxSourceLimit(selectedRule.maximumLimit));
    //   return true;
    // }
    if (hasExceededTotalFunds(systemValue, selectedAmount)) {
      setError(transferInOutErrors.exceededTotalFunds);
      return true;
    }
    return false;
  };

  const handleCashDetails = async () => {
    if (!isLoading) {
      if (checkErrors()) return;

      postTransferOut({
        data: transferOutParams({
          sourceEntityType: getLegacyAccountingLocationType(
            accountingLocation?.accountingLocationType,
          ),
          sourceEntityID: accountingLocationID,
          destinationEntityType: getLegacyAccountingLocationType(
            selectedOtherAccountingLocation?.accountingLocationType,
          ),
          destinationEntityID: selectedOtherAccountingLocation?.accountingLocationID,
          transferAmount: inputNumberInPence(selectedAmount.float),
          fadcode: fadCode,
          userName: userName,
          operationType: "transfer_out",
          transferAt: BACK_OFFICE,
          stockunitIdentifier: accountingLocationID,
          stockunitIdentifierDest: selectedOtherAccountingLocation?.accountingLocationID,
        }),
      });
    }
  };

  // If action isn't in the actionList for this accountingLocationType or the operation is disabled, then throw an error
  useEffect(() => {
    if (
      checkIfAccountingLocationActionInvalid(
        accountingLocation,
        actionListMap.transferOut,
        branchDetails.branch_type,
      )
    ) {
      setError(transferInOutErrors.invalidOperation);
    }
  }, [accountingLocation, branchDetails.branch_type]);

  useEffect(() => {
    if (invalidAccountingLocation) {
      setError(transferInOutErrors.invalidAccountingLocation);
    }
  }, [invalidAccountingLocation]);

  useEffect(() => {
    if (isError) {
      setError(transferInOutErrors.exceededTotalFunds);
    }
  }, [isError]);

  useEffect(() => {
    if (isSuccess) {
      setActiveModal("TransferSuccess");
    }
  }, [isSuccess]);

  const ReceiptPrint = (title: string) => {
    const branchAddressClean = removeEmptyLinesFromAddress(branchDetails.branch_address);
    receiptPrinterService.printReceipt({
      template: TransferOutTemplate,
      context: {
        title: title,
        fadcode: fadCode,
        branchName: branchDetails.branch_name,
        addressLine1: branchAddressClean[0],
        addressLine2: branchAddressClean[1],
        addressLine3: branchAddressClean[2],
        postcode: branchAddressClean[branchAddressClean.length - 1],
        currentDate: currentDateDDMMYYYY(),
        currentTime: currentOnlyTime(),
        fromAccountingLocation: formatCounterTerminalName(accountingLocation)
          .replace("(", "")
          .replace(")", ""),
        toAccountingLocation: formatCounterTerminalName(selectedOtherAccountingLocation)
          .replace("(", "")
          .replace(")", ""),
        amount: selectedAmount.formatted,
      },
    });
  };

  const navigateToBranchOverview = () => {
    if (selectedOtherAccountingLocation?.accountingLocationType !== "cashDrawer") {
      navigate(SCREENS.BRANCH_OVERVIEW);
      return;
    }

    const state: OverviewLocationState = {
      cashDrawerLimit: {
        accountingLocationID: selectedOtherAccountingLocation.accountingLocationID,
        lastTransferAmountPence: inputNumberInPence(selectedAmount.float),
      },
    };
    // Sends the accountingLocationID of the accounting location to transfer into, so that the "Cash Drawer Limit" modal can be displayed if necessary
    navigate(SCREENS.BRANCH_OVERVIEW, {
      state,
    });
  };

  return {
    accountingLocation,
    otherAccountingLocationsGrouped,
    selectedOtherAccountingLocation,
    setSelectedOtherAccountingLocation,
    isBranchOverviewLoading,
    activeTab,
    setActiveTab,
    tabs,
    handleCashDetails,
    ReceiptPrint,
    selectedAmount,
    setSelectedAmount,
    activeModal,
    setActiveModal,
    error,
    setError,
    branchOverviewData,
    navigateToBranchOverview,
  };
};
