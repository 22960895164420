import PopupConfirm from "@bbo/components/Alert";
import { ErrorPopup } from "@bbo/components/ErrorPopup";
import PrintConfirm from "@bbo/components/Printconfirm/index";
import { useEffect } from "react";
import CurrencyInput from "react-currency-input-field";
import { useNavigate } from "react-router-dom";
import { useTitle } from "../AuthRoutes";
import "./Index.css";
import { usePaidInOut } from "./usePaidInOut";

interface PaidInOutProps {
  operationType: "paid_in" | "paid_out";
}

export const PaidInOut = ({ operationType }: PaidInOutProps) => {
  const {
    accountingLocation,
    clearFlag,
    setClearFlag,
    setInvalidFund,
    btnCounterView,
    txtAreaComment,
    comment,
    handleComment,
    commentLength,
    maxCommentLength,
    disable,
    warningFlag,
    invalidLimitFlag,
    invalidFund,
    selectedAmount,
    enterAmount,
    handleCashDetails,
    printData,
    setPrintData,
    onPrintClick,
    isSubmitClick,
    error,
    invalidAmount,
    navigateToBranchOverview,
  } = usePaidInOut({ operationType });

  const operationTypeText = operationType === "paid_in" ? "Paid In" : "Paid Out";
  const operationTypeTextUppercase = operationType === "paid_in" ? "PAID IN" : "PAID OUT";

  const navigate = useNavigate();
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle(operationTypeText);
  }, [setTitle, operationTypeText]);

  if (!accountingLocation) return null;

  return (
    <>
      <div className="containerMain">
        <div className="transferOut-height overflow-y-auto grid grid-cols-1 lg:grid-cols-2 gap-12">
          <div className={`pt-6 pl-8  ${!clearFlag ? "" : "diableBtnCss"}`}>
            <h2 className="heading-3 font-bold mb-5">Select Reason Code / Label</h2>
            <div className="grid grid-cols-2 gap-4 pb-4 pr-4">{btnCounterView}</div>
            <div className="float-left mt-10 w-full">
              <p className="heading text-color">
                <b>Please provide a comment for your records</b>
              </p>
              <textarea
                ref={txtAreaComment}
                disabled={clearFlag}
                className="paidTextArea w-full textArea"
                rows={5}
                value={comment}
                onChange={handleComment}
                placeholder="Add comments"></textarea>
              <p className="text-right">{`${commentLength}/${maxCommentLength}`}</p>
            </div>
          </div>
          <div
            className={`pt-6 pl-8 flex flex-col bg-white transferIn-right-side h-full ${
              disable ? "" : "diableBtnCss"
            }`}>
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
              <h2 className="heading-3 font-bold mb-5">{`${operationTypeText} Amount`}</h2>
            </div>
            <div className="flex justify-center items-center h-full">
              <div className={`${disable ? "lg:w-4/6 w-full" : "diableBtnCss lg:w-4/6 w-full"}`}>
                {!clearFlag ? (
                  <>
                    <div className="transfer-out-section w-full flex flex-col justify-center items-center">
                      {/* // The limit that has been used for the limit check is completely wrong, the £26k value applies only to the pouch preparation.
                          // The correct limit depends on the counter type, which we will not have access to until R3 at the earliest.
                          // Therefore the limit check has been disabled for now
                      */}
                      {/* {warningFlag && !invalidLimitFlag && (
                        <h2 className="heading w-full text-center warningMsg">
                          Warning: Transfer limit has been surpassed
                        </h2>
                      )} */}
                      {invalidLimitFlag ? (
                        <h2 className="heading w-full text-center errorMsg">
                          This transaction will not be permitted
                          <i
                            className="fas fa-info-circle errorMsg cursor-pointer"
                            title="Transaction above £26000.00 will not be permitted."></i>
                        </h2>
                      ) : null}
                      {invalidFund ? (
                        <h2 className="heading w-full text-center errorMsg">
                          This cash Drawer has insufficient fund to transfer
                        </h2>
                      ) : null}
                      <div
                        className={`justify-center flex-col cursor-pointer items-center flex block w-full ${
                          disable ? "" : "diableBtnCss"
                        }`}></div>{" "}
                      <h2 className="text-lg font-bold mb-5">Enter Amount</h2>
                      <CurrencyInput
                        id="PaidInOut-Input"
                        name="PaidInOut-Input"
                        prefix="£"
                        defaultValue={0}
                        decimalsLimit={2}
                        onValueChange={(value) => {
                          enterAmount(value);
                          setInvalidFund(false);
                        }}
                        allowNegativeValue={false}
                        disabled={!disable}
                        disableAbbreviations={true}
                        className="amountInput w-full mb-4"
                        data-testid="PaidInOut-Input"
                      />
                      <button
                        className={`accountingBtnNoHover ${
                          disable ? "accounting-button" : "diableBtnCss"
                        } btn-submit-cash`}
                        disabled={
                          invalidAmount ||
                          invalidLimitFlag ||
                          !selectedAmount ||
                          selectedAmount === "£" ||
                          selectedAmount === "£0.00"
                        }
                        onClick={() => {
                          handleCashDetails();
                        }}>
                        Submit
                      </button>
                    </div>
                  </>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {printData ? (
        <PrintConfirm
          onCancel={() => {
            setPrintData(false);
            navigateToBranchOverview();
          }}
          onReprint={() => {
            onPrintClick(`${operationTypeTextUppercase} DUPLICATE`);
          }}
          onSuccess={() => {
            setPrintData(false);
            navigateToBranchOverview();
          }}
        />
      ) : null}
      {clearFlag ? (
        <PopupConfirm
          header={`${operationTypeText} Transaction is successful!`}
          labelCancel="Close"
          labelSuccess="Print Receipt"
          tickicon={true}
          onCancel={() => {
            setClearFlag(false);
            navigateToBranchOverview();
          }}
          onSuccess={() => {
            setClearFlag(false);
            setPrintData(true);
            onPrintClick(`${operationTypeTextUppercase}`);
          }}
        />
      ) : null}
      {error?.type === "modalError" ? (
        <ErrorPopup
          popupType="error"
          messageHeading={error?.message}
          labelSuccess="Back"
          onSuccess={() => navigate(-1)}
        />
      ) : null}
    </>
  );
};
