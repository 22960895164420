import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import CashMovementHistoryPage from "@bbo/features/CashMovementHistory/CashMovementHistoryPage";
import { useEffect } from "react";

const CashMovementHistory = () => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Cash Movements History");
  }, [setTitle]);

  return <CashMovementHistoryPage />;
};

export default CashMovementHistory;
