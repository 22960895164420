import ProgressBar from "@bbo/components/ProgressBar";
import { BP_TP_STATUS } from "@bbo/constants";
import { BpTpDeclaration, EachBranchViewCommodityProps } from "@bbo/interfaces/BpTp";
import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";
import { getUUID } from "@bbo/utils/util";
import { useEffect, useState } from "react";
import Accordion from "./Accordion";
const { LOCATIONS_PENDING, COMPLETE_BALANCE } = BP_TP_STATUS;
const EachBranchViewCommodity = ({
  branchViewCommodityProps,
  branchViewProps,
}: EachBranchViewCommodityProps) => {
  const {
    commodityTypeName,
    commodityProgressColor,
    commodityAction,
    commodityData,
    commodityPercentage,
    commodityVarianceValue,
  } = branchViewCommodityProps;
  const { fromHistory, fromDiscrepancyAccount, completeBalance } = branchViewProps;
  const [list, SetList] = useState<BpTpDeclaration[]>();
  useEffect(() => {
    if (!commodityData) return;
    const simplifiedList = Object.values(commodityData);
    const firstID = [];
    const restID = [];
    simplifiedList?.map((List) => {
      if (List.accountingLocationName.includes("Safe")) {
        firstID.push(List);
      } else {
        restID.push(List);
      }
    });
    const simplifiedLocations = [...firstID, ...restID];
    SetList(simplifiedLocations);
  }, [commodityData]);

  /* This supports for all commodity types just call with different params */
  const CashContentDetail = (
    progressColor: string,
    commodityData: BpTpDeclaration[],
  ): JSX.Element | null =>
    commodityData.length ? (
      <>
        <div className="mt-2 ">
          <span className="dot mr-2" style={{ backgroundColor: progressColor }}></span>
          <span className="subTitle">{LOCATIONS_PENDING}</span>
        </div>
        <div className="ContentDetailAlign">
          <table>
            <tbody>
              {list?.map(({ accountingLocationName, accountingLocationID, balancingDeclID }) => {
                return (
                  <tr
                    key={getUUID()}
                    data-testid={accountingLocationName}
                    onClick={() => completeBalance(balancingDeclID, accountingLocationID)}>
                    <td className="accordian-title">{accountingLocationName}</td>
                    <td className="linkBtnHighLevel text-right">{COMPLETE_BALANCE}</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </>
    ) : null;

  return (
    <>
      <tr className="h-20">
        <td className="sideTitle">{commodityTypeName}</td>
        <td>
          <Accordion
            title={
              <ProgressBar
                bgcolor={commodityProgressColor}
                progress={commodityPercentage}
                reason={commodityAction}
              />
            }
            content={
              fromHistory || fromDiscrepancyAccount
                ? null
                : CashContentDetail(commodityProgressColor, commodityData)
            }
          />
        </td>
        <td className={`valueDot ${currencyStyle(commodityVarianceValue)}`}>
          {amountSignedPound(commodityVarianceValue)}
        </td>
      </tr>
    </>
  );
};

export default EachBranchViewCommodity;
