import { useGlobalState } from "@bbo/lib";
import { useEffect, useState } from "react";
import { ValidateInput } from "../../components/ValidateInput";
import "./index.css";

export default function BarcodeScanForm({ handleInput, statusCode, countType, grnRefNum }) {
  const [showGRNInput, setShowGRNInput] = useState(false);
  const [BarCodeFormEnable] = useGlobalState("BarCodeFormEnable");
  const [searchValue, setSearchValue] = useState("");
  const errorMsg =
    "Item not found. Please check and try again. If problem continues please contact the Business Support Centre.";
  const grnErrorMsg = "Incorrect format. Please try again.";
  const [errorState, setErrorState] = useState({
    isError: false,
    errorMessage: errorMsg,
  });

  useEffect(() => {
    if (countType === "Burglary/Robbery" && !grnRefNum) {
      setShowGRNInput(true);
      setErrorState((prev) => ({
        ...prev,
        errorMessage: grnErrorMsg,
      }));
    } else {
      setShowGRNInput(false);
      setErrorState((prev) => ({
        ...prev,
        errorMessage: errorMsg,
      }));
    }
  }, [countType, grnRefNum]);

  const checkUserInputGRN = () => {
    if (!searchValue) return false;
    return !/^-?\d+$/.test(searchValue) || searchValue?.length > 7;
  };

  const checkError = () => {
    return showGRNInput ? checkUserInputGRN() : statusCode === 204;
  };

  const invalidGRN = () => {
    if (showGRNInput && (searchValue?.length < 7 || searchValue?.length > 7)) {
      return true;
    }
    return false;
  };

  const handleChange = (input: string): void => {
    setSearchValue(input);
    setErrorState({
      ...errorState,
      isError: false,
    });
  };

  const handleProductDisplay = (e) => {
    e.preventDefault();
    if (showGRNInput && invalidGRN()) {
      setErrorState({
        ...errorState,
        errorMessage: grnErrorMsg,
        isError: true,
      });
      return;
    }
    setShowGRNInput(false);
    handleInput(searchValue, showGRNInput);
    setShowGRNInput(false);
    setSearchValue("");
  };

  const inputComponent = () => {
    return (
      <input
        className="movementInput w-full "
        spellCheck="false"
        placeholder={
          showGRNInput
            ? "Please enter the Grapevine Reference Number"
            : "Scan Product or Enter Barcode"
        }
        type="text"
        value={searchValue}
        onChange={(e) => handleChange(e.target.value)}
        autoFocus
        disabled={BarCodeFormEnable.barCodeFormEnable}
      />
    );
  };

  return (
    <div className="">
      <form className="mb-4">
        <div className="wrapper px-10 mt-8">
          {showGRNInput ? (
            <div className="standard-label font-bold pb-3">Grapevine Reference Number</div>
          ) : null}
          <ValidateInput
            errorMessage={errorState.errorMessage}
            isError={checkError() || errorState.isError}
            inputComponent={inputComponent()}
          />
        </div>

        <div className="flex w-full justify-center mt-8">
          <button
            className="action-button button-red"
            disabled={BarCodeFormEnable.barCodeFormEnable}
            onClick={(e) => handleProductDisplay(e)}>
            Enter
          </button>
        </div>
      </form>
    </div>
  );
}
