import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
export const useStyles = makeStyles(() =>
  createStyles({
    subHeader: {
      display: "flex",
      justifyContent: "space-between",
      "font-family": "Nunito Sans !important",
      "font-style": "normal",
      "font-weight": "700",
      "font-size": "24px",
      "line-height": "34px",
      color: "#000000",
    },
    value: {
      color: " #B20101 !important",
    },
  }),
);
