import { useDescendant, useTabsContext } from "@bbo/lib";
import { ReactNode } from "react";

export const TabPanelData = ({ children }: { children: ReactNode }) => {
  const { activeTabIndex, getId } = useTabsContext();
  const { index } = useDescendant(activeTabIndex);
  const isActive = activeTabIndex === index;
  return (
    <div
      key={index}
      tabIndex={0}
      role="tabpanelData"
      hidden={!isActive}
      id={getId(index, "tabpanelData")}
      aria-labelledby={getId(index)}
      aria-hidden={!isActive}
      data-testid={`tabpanelData-${index}`}>
      {children}
    </div>
  );
};
