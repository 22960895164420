import { PouchDetails } from "@bbo/api/generator";
import { SetStateAction, useCallback, useEffect } from "react";
import {
  PouchPreparationModalConfig,
  pouchPreparationModalConfigs,
} from "./pouchPreparationModalConfig";
import { useAppSelector } from "@bbo/lib";

interface ConfigOverrides {
  resumeSuspendedPouch: {
    onPrimaryButtonClick: () => void;
    onSecondaryButtonClick: () => void;
  };
  closeModal: {
    onPrimaryButtonClick: () => void;
  };
}

type WarningModalsStatus = "should-return" | "should-not-return";

export const usePouchWarningModals = (
  pouchDetails: PouchDetails,
  status: "error" | "success" | "loading",
  setModalConfig: (value: SetStateAction<PouchPreparationModalConfig>) => void,
  configOverrides: ConfigOverrides,
) => {
  const { smartid } = useAppSelector((rootState) => rootState.auth.authUserData);

  const getCloseModalConfig = (status: "cancelled" | "despatched") => {
    switch (status) {
      case "cancelled":
        return pouchPreparationModalConfigs.cancelledPouch;
      case "despatched":
        return pouchPreparationModalConfigs.despatchedPouch;
    }
  };

  const checkWarningModals = useCallback((): WarningModalsStatus => {
    if (!status) return "should-return";
    if (!pouchDetails?.status) return "should-not-return";

    switch (pouchDetails.status) {
      case "cancelled":
      case "despatched":
        setModalConfig({
          ...getCloseModalConfig(pouchDetails.status),
          ...configOverrides.closeModal,
        });
        return "should-return";
      case "suspended": {
        const suspendedByCurrentUser = pouchDetails?.updatedBy?.smartID === smartid;

        if (suspendedByCurrentUser) {
          setModalConfig({
            ...pouchPreparationModalConfigs.resumeSuspendedPouch,
            ...configOverrides.resumeSuspendedPouch,
          });
          return "should-return";
        }

        // Prepared by other user
        setModalConfig({
          // Use the suspendedPouchByOther config and replace the {username} template with the other user's username
          ...pouchPreparationModalConfigs.suspendedPouchByOther,
          heading: pouchPreparationModalConfigs.suspendedPouchByOther.heading.replace(
            "{username}",
            pouchDetails?.updatedBy?.userName,
          ),
          ...configOverrides.closeModal,
        });
        return "should-return";
      }
      default:
        return "should-not-return";
    }
  }, [configOverrides, pouchDetails, setModalConfig, smartid, status]);

  return { checkWarningModals };
};
