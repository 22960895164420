import printIcon from "../../assets/images/printIcon.svg";
import errorIcon from "./../../assets/images/errorIcon.svg";
export const StockCountPopupBody = ({ inProgress }) => {
  return (
    <div>
      <div>
        <div className="flex object-center mt-2">
          <img
            className="block mx-auto"
            src={inProgress ? errorIcon : printIcon}
            alt="Post Office"
          />
        </div>
        <div>
          {inProgress ? (
            <div className="leading-relaxed mt-10 text-color px-12 amountHighLevel text-center">
              Pouch Preparation in progress.
              <br /> Please cancel or complete before proceeding.
            </div>
          ) : (
            <div className="leading-relaxed mt-10 text-color px-12 amountHighLevel text-center">
              Would you like to print a list of all the
              <br /> items expected in this count?.
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
