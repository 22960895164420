import {
  ScannerClient,
  ServiceEvent,
  setup,
  SupportedServices,
} from "postoffice-peripheral-management-service";
import { useEffect, useRef, useState } from "react";

type UseScanner = {
  isConnected: boolean;
};

export const useScanner = (onScan: (text: string) => void): UseScanner => {
  const [isConnected, setIsConnected] = useState<boolean>(false);
  const callback = useRef<((text: string) => void) | null>(null);

  useEffect(() => {
    callback.current = onScan;
    return () => {
      callback.current = null;
    };
  }, [onScan]);

  useEffect(() => {
    const connect = async () => {
      const pms = setup({
        deviceServerHost: process.env.REACT_APP_POL_DEVICE_SERVER_HOST,
        disconnectAfterResponse: false,
        callbacks: {
          onConnectionOpened: () => {
            console.log("scanner opened");
          },
          onConnectionClosed: () => {
            console.log("scanner connection closed");
          },
          onConnectionError: () => {
            console.log("scanner connection error");
          },
          onDisplayUpdate: ({ service, message }: ServiceEvent) => {
            if (service === SupportedServices.Scanner) {
              callback.current?.(message.trim());
            }
          },
        },
      });

      const scanner = pms.buildClient(SupportedServices.Scanner, {}) as ScannerClient;

      try {
        const res = await scanner.connect();
        setIsConnected(res.connected);
      } catch (error) {
        console.log(error);
      }
    };

    connect();
  }, []);

  return { isConnected };
};
