/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, UseMutationOptions, MutationFunction } from "@tanstack/react-query";
import type {
  SuccessResponse,
  BadRequestResponse,
  UnauthorizedResponse,
  ForbiddenResponse,
  FailureResponseResponse,
  DissociateCashDrawerBodyBody,
  AssociateCashDrawerBodyBody,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Dissociate a Cash Drawer and Counter
 */
export const useDissociationCashDrawerHook = () => {
  const dissociationCashDrawer = useCustomInstance<SuccessResponse>();

  return (dissociateCashDrawerBodyBody: DissociateCashDrawerBodyBody) => {
    return dissociationCashDrawer({
      url: `/bm-accounting-location/v1/dissociation`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: dissociateCashDrawerBodyBody,
    });
  };
};

export type DissociationCashDrawerMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useDissociationCashDrawerHook>>
>;
export type DissociationCashDrawerMutationBody = DissociateCashDrawerBodyBody;
export type DissociationCashDrawerMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | FailureResponseResponse
>;

export const useDissociationCashDrawer = <
  TError = ErrorType<
    BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | FailureResponseResponse
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof useDissociationCashDrawerHook>>,
    TError,
    { data: DissociateCashDrawerBodyBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const dissociationCashDrawer = useDissociationCashDrawerHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof useDissociationCashDrawerHook>>,
    { data: DissociateCashDrawerBodyBody }
  > = (props) => {
    const { data } = props || {};

    return dissociationCashDrawer(data);
  };

  return useMutation<
    AsyncReturnType<typeof dissociationCashDrawer>,
    TError,
    { data: DissociateCashDrawerBodyBody },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * @summary (From Counter) Dissociate a Cash Drawer and Counter
 */
export const useDissociationCashDrawerCounterHook = () => {
  const dissociationCashDrawerCounter = useCustomInstance<SuccessResponse>();

  return (associateCashDrawerBodyBody: AssociateCashDrawerBodyBody) => {
    return dissociationCashDrawerCounter({
      url: `/bm-accounting-location/v1/dissociation-counter`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: associateCashDrawerBodyBody,
    });
  };
};

export type DissociationCashDrawerCounterMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useDissociationCashDrawerCounterHook>>
>;
export type DissociationCashDrawerCounterMutationBody = AssociateCashDrawerBodyBody;
export type DissociationCashDrawerCounterMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | FailureResponseResponse
>;

export const useDissociationCashDrawerCounter = <
  TError = ErrorType<
    BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | FailureResponseResponse
  >,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof useDissociationCashDrawerCounterHook>>,
    TError,
    { data: AssociateCashDrawerBodyBody },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const dissociationCashDrawerCounter = useDissociationCashDrawerCounterHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof useDissociationCashDrawerCounterHook>>,
    { data: AssociateCashDrawerBodyBody }
  > = (props) => {
    const { data } = props || {};

    return dissociationCashDrawerCounter(data);
  };

  return useMutation<
    AsyncReturnType<typeof dissociationCashDrawerCounter>,
    TError,
    { data: AssociateCashDrawerBodyBody },
    TContext
  >(mutationFn, mutationOptions);
};
