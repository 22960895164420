import { API_RESPONSE_RESULTS, CASH_BALANCE_SCREENS, SCREENS } from "@bbo/constants";
import { BP_TP_COMMODITY_TYPES, BP_TP_STATUS } from "@bbo/constants/TradingConstants";
import { DetailViewProps } from "@bbo/interfaces/BpTp";
import { useGlobalState } from "@bbo/lib";
import { getUUID } from "@bbo/utils/util";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GREENCODE, REDCODE } from "../constants";
import {
  detailViewHeaderLists,
  initialEachCashCommodity,
  initialEachForexCommodity,
  initialEachObsoleteCommodity,
  initialEachStockCommodity,
} from "./BpTpHelpers";
import EachDetailViewCommodity from "./EachDetailViewCommodity";
import ErrorMessage from "./ErrorMessage";
import "./index.css";

const { CASH, FOREX } = BP_TP_COMMODITY_TYPES;
const { COMPLETED, AWAITING_ACTION } = BP_TP_STATUS;
const { NO_DATA_FOUND_MESSAGE, FAILED_API_MESSAGE } = API_RESPONSE_RESULTS;

export const DetailOverview = ({
  status,
  selectedValue,
  fromHistory,
  fromDiscrepancyAccount,
  selectedKey,
}: DetailViewProps) => {
  const navigate = useNavigate();
  const [cashState, setCashState] = useState(initialEachCashCommodity);
  const [forexState, setForexState] = useState(initialEachForexCommodity);
  const [stockState, setStockState] = useState(initialEachStockCommodity);
  const [obsoleteState, setObsoleteState] = useState(initialEachObsoleteCommodity);
  const [, setIsReadonlyMode] = useGlobalState("isReadonlyMode");
  const { cashRecount, cashAction, cashProgressColor } = cashState;
  const { forexRecount, forexAction, forexProgressColor } = forexState;
  const { stockRecount, stockAction, stockProgressColor } = stockState;
  const { obsoleteRecount, obsoleteAction, obsoleteProgressColor } = obsoleteState;

  const detailViewCommodityProps = (commodityTypeName: string) => {
    switch (commodityTypeName) {
      case CASH:
        return {
          commodityTypeName,
          commodityProgressColor: cashProgressColor,
          commodityAction: cashAction,
          commodityRecount: cashRecount,
          commodityVarianceValue: selectedValue?.varianceValue,
        };
      case FOREX:
        return {
          commodityTypeName,
          commodityProgressColor: forexProgressColor,
          commodityAction: forexAction,
          commodityRecount: forexRecount,
          commodityVarianceValue: 0,
        };
      // Commented as per BM-8944
      // case STOCK:
      //   return {
      //     commodityTypeName,
      //     commodityProgressColor: stockProgressColor,
      //     commodityAction: stockAction,
      //     commodityRecount: stockRecount,
      //     commodityVarianceValue: 0,
      //   };
      // case OBSOLETE_STOCK:
      //   return {
      //     commodityTypeName,
      //     commodityProgressColor: obsoleteProgressColor,
      //     commodityAction: obsoleteAction,
      //     commodityRecount: obsoleteRecount,
      //     commodityVarianceValue: 0,
      //   };
    }
  };
  const completeBalance = () => {
    navigate(
      `${SCREENS.CASH_BALANCE}/${CASH_BALANCE_SCREENS.TP_BP_WITHOUT_DECLARATION_ID}/${selectedValue?.accountingLocationID}`,
    );
    return;
  };

  const completedBalance = () => {
    if (!selectedValue?.balancingDeclID) {
      navigate(
        `${SCREENS.CASH_BALANCE}/${CASH_BALANCE_SCREENS.TP_BP_WITHOUT_DECLARATION_ID}/${selectedKey}`,
      );
      return;
    }
    if (!fromHistory && !fromDiscrepancyAccount) {
      navigate(
        `${SCREENS.CASH_BALANCE}/${
          CASH_BALANCE_SCREENS.TP_BP_WITH_DECLARATION_ID
        }/${encodeURIComponent(selectedValue?.balancingDeclID)}`,
      );

      return;
    }

    navigate(
      `${SCREENS.CASH_BALANCE}/${CASH_BALANCE_SCREENS.TP_BP_HISTORY}/${encodeURIComponent(
        selectedValue?.balancingDeclID,
      )}`,
    );

    setIsReadonlyMode({ isReadonlyMode: true });
  };

  const recountBalance = () => {
    if (!selectedValue?.balancingDeclID) {
      navigate(
        `${SCREENS.CASH_BALANCE}/${CASH_BALANCE_SCREENS.TP_BP_WITHOUT_DECLARATION_ID}/${selectedValue?.accountingLocationID}`,
      );
      return;
    }

    navigate(
      `${SCREENS.CASH_BALANCE}/${
        CASH_BALANCE_SCREENS.TP_BP_WITH_DECLARATION_ID
      }/${encodeURIComponent(selectedValue?.balancingDeclID)}`,
    );
  };

  // TODO this is only for cash Commodity functionality we have to do same for other commodities
  useEffect(() => {
    if (!selectedValue) return;
    if (!fromHistory && !fromDiscrepancyAccount) {
      if (selectedValue?.declarationCompleted) {
        setCashState({ cashRecount: true, cashAction: COMPLETED, cashProgressColor: GREENCODE });
        return;
      }
      setCashState({
        cashRecount: false,
        cashAction: AWAITING_ACTION,
        cashProgressColor: REDCODE,
      });
      return;
    } else {
      setCashState(initialEachCashCommodity);
    }
  }, [fromDiscrepancyAccount, fromHistory, selectedValue]);

  const detailViewProps = () => ({
    completeBalance,
    recountBalance,
    completedBalance,
    fromHistory,
    fromDiscrepancyAccount,
  });

  return (
    <>
      <div className="w-full py-4">
        <div className="w-full balanceTpTable overflow-y-auto">
          <table className="table-fixed dynamictable w-full border-collapse">
            <thead>
              <tr className="h-14">
                {detailViewHeaderLists.map(({ headerName, css: { width } }) => (
                  <th className={`text-left ${width} text-lg font-bold textColor`} key={getUUID()}>
                    {headerName}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {selectedValue &&
                Object.values(BP_TP_COMMODITY_TYPES).map((commodityTypeName) => (
                  <EachDetailViewCommodity
                    key={getUUID()}
                    detailViewCommodityProps={detailViewCommodityProps(commodityTypeName)}
                    detailViewProps={detailViewProps()}
                  />
                ))}
            </tbody>
          </table>
        </div>
        {status === "success" && !selectedValue && (
          <ErrorMessage message={NO_DATA_FOUND_MESSAGE}></ErrorMessage>
        )}
        {status === "error" && !selectedValue && (
          <ErrorMessage message={FAILED_API_MESSAGE}></ErrorMessage>
        )}
      </div>
    </>
  );
};
