import { useAppSelector } from "@bbo/lib";
import { useReceiptPrinter } from "@bbo/lib/hooks/useReceiptPrinter";
import { PouchPreparationTemplate } from "@bbo/utils/ReceiptPrinterTemplates/PouchPreparation";
import {
  currentDateDDMMYYYY,
  currentDateTimePrintReceiptDDMMYYYY,
  currentOnlyTime,
} from "@bbo/utils/dateTimeFormatter";
import { removeEmptyLinesFromAddress } from "@bbo/utils/receiptHelper";
import { Dispatch, SetStateAction, useCallback } from "react";
import { calculateTotalValueInPence, formatDisplayCurrency } from "../../utils/pouch/pouchHelpers";
import { SCREENS } from "@bbo/constants";
import { useNavigate } from "react-router-dom";
import { PouchDetails, PouchSubType } from "@bbo/api/generator";
import { Response } from "@bbo/api/generatorNoFadcode";
import { PouchDetailsItemsInfo, denominationLadderForPouchSubTypes } from "@bbo/utils";
import {
  PouchPreparationModalConfig,
  pouchPreparationModalConfigs,
} from "./pouchPreparationModalConfig";

interface ListItem {
  Currency: string;
  Quantity: number;
  value: number;
}

export const usePouchPrepPrint = (
  pouchDetails: PouchDetails,
  pouchSubType: PouchSubType,
  denominationLadder: PouchDetailsItemsInfo,
  pouchCreated: Response,
  setModalConfig: Dispatch<SetStateAction<PouchPreparationModalConfig>>,
) => {
  const navigate = useNavigate();
  const receiptPrinterService = useReceiptPrinter();
  const { smartid, fadCode, username } = useAppSelector((rootState) => rootState.auth.authUserData);
  const { branch_name: branchName, branch_address: branchAddress } = useAppSelector(
    (rootState) => rootState.branchDetails,
  );

  const { rows } = denominationLadderForPouchSubTypes[pouchSubType] || {};

  const listItems = useCallback((): ListItem[] => {
    const itemIDs = Object.values(rows).flat(1);
    const items = itemIDs.map((itemID) => denominationLadder[itemID]);

    return items
      .filter((item) => !!item.itemValue) // Remove 0 value items
      .map((item) => ({
        Currency: formatDisplayCurrency(item),
        Quantity: item.itemQuantity,
        value: item.itemValue / 100,
      }));
  }, [denominationLadder, rows]);

  const printCallFunction = useCallback(() => {
    const branchAddressClean = removeEmptyLinesFromAddress(branchAddress);
    receiptPrinterService.printReceipt({
      template: PouchPreparationTemplate,
      context: {
        title: "POUCH PREPARATION",
        fadcode: fadCode,
        branchName,
        addressLine1: branchAddressClean[0],
        addressLine2: branchAddressClean[1],
        addressLine3: branchAddressClean[2],
        barCode: pouchDetails?.pouchID,
        pouchType: pouchDetails?.pouchType === "cash" ? "Cash" : "N/A",
        transactionId: pouchCreated?.basketId.substring(pouchCreated?.basketId.indexOf("-") + 1),
        postcode: branchAddressClean[branchAddressClean?.length - 1],
        currentDate: currentDateDDMMYYYY(),
        currentTime: currentOnlyTime(),
        Location: "Safe",
        createdByUserName: pouchDetails?.updatedBy?.userName || username,
        createdBySmartID: pouchDetails?.updatedBy?.smartID || smartid,
        dateTimeSystem: currentDateTimePrintReceiptDDMMYYYY(),
        list_items: listItems(),
        TotalValue: calculateTotalValueInPence(denominationLadder) / 100,
      },
    });
  }, [
    branchAddress,
    branchName,
    denominationLadder,
    fadCode,
    listItems,
    pouchCreated?.basketId,
    pouchDetails?.pouchID,
    pouchDetails?.pouchType,
    pouchDetails?.updatedBy?.smartID,
    pouchDetails?.updatedBy?.userName,
    receiptPrinterService,
    smartid,
    username,
  ]);

  const openReprintSuccessModal = useCallback(() => {
    setModalConfig({
      ...pouchPreparationModalConfigs.reprintSuccess,
      onPrimaryButtonClick: () => {
        navigate(SCREENS.POUCH_PREPARATION);
      },
    });
  }, [navigate, setModalConfig]);

  const handleReprintReceipt = useCallback(() => {
    printCallFunction();
    openReprintSuccessModal();
  }, [openReprintSuccessModal, printCallFunction]);

  const openReprintModal = useCallback(() => {
    setModalConfig({
      ...pouchPreparationModalConfigs.reprintReceipt,
      onPrimaryButtonClick: handleReprintReceipt,
      onSecondaryButtonClick: () => {
        navigate(SCREENS.POUCH_PREPARATION);
      },
    });
  }, [handleReprintReceipt, navigate, setModalConfig]);

  const openPrintSuccessModal = useCallback(() => {
    setModalConfig({
      ...pouchPreparationModalConfigs.printSuccess,
      onPrimaryButtonClick: openReprintModal,
      onSecondaryButtonClick: () => {
        navigate(SCREENS.POUCH_PREPARATION);
      },
    });
  }, [navigate, openReprintModal, setModalConfig]);

  const handlePrintReceipt = useCallback(() => {
    printCallFunction();
    openPrintSuccessModal();
  }, [openPrintSuccessModal, printCallFunction]);

  return {
    handlePrintReceipt,
    openPrintSuccessModal,
    openReprintModal,
    handleReprintReceipt,
    openReprintSuccessModal,
    printCallFunction,
    listItems,
  };
};
