import { useCallback, useState } from "react";
import { ErrorState } from "./useDynamoPagination";

// State Management Hook
export const usePaginationState = () => {
  const [allData, setAllData] = useState<unknown[]>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [currentChunk, setCurrentChunk] = useState(0);
  const [needsToFetch, setNeedsToFetch] = useState(true);
  const [errorState, setError] = useState<ErrorState | null>(null);
  const [selectedItemIndexes, setSelectedItemIndexes] = useState<number[]>([]);

  const resetAll = useCallback(() => {
    setAllData([]);
    setError(null);
    setCurrentPage(0);
    setCurrentChunk(0);
    setSelectedItemIndexes([]);
    setNeedsToFetch(true);
  }, []);

  return {
    allData,
    setAllData,
    currentPage,
    setCurrentPage,
    currentChunk,
    setCurrentChunk,
    needsToFetch,
    setNeedsToFetch,
    selectedItemIndexes,
    setSelectedItemIndexes,
    errorState,
    setError,
    resetAll,
  };
};
