import { API_RESPONSE_RESULTS, CASH_BALANCE_SCREENS, SCREENS } from "@bbo/constants";
import { BP_TP_COMMODITY_TYPES, BP_TP_STATUS } from "@bbo/constants/TradingConstants";
import { CommonBpTpProps } from "@bbo/interfaces/BpTp";
import { getUUID } from "@bbo/utils/util";
import { useCallback, useEffect, useLayoutEffect, useReducer } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AMBERCODE, GREENCODE, REDCODE } from "../constants";
import {
  accountsReducer,
  branchViewHeaderLists,
  cashReducer,
  defaultAllAccountsStatus,
  forexReducer,
  initialAllAccountsStatus,
  initialCashCommodity,
  initialForexCommodity,
  initialObsoleteCommodity,
  initialStockCommodity,
  obsoleteReducer,
  stockReducer,
} from "./BpTpHelpers";
import EachBranchViewCommodity from "./EachBranchViewCommodity";
import ErrorMessage from "./ErrorMessage";
import "./index.css";
import { useBpTP } from "./useBpTP";

interface IProps {
  isAllAccountingLocationsCompleted?: (isCompleted: boolean) => void;
  BpTpProps: CommonBpTpProps;
}

const { CASH, FOREX } = BP_TP_COMMODITY_TYPES;
const { COMPLETED, AWAITING_ACTION, IN_PROGRESS } = BP_TP_STATUS;
const { NO_DATA_FOUND_MESSAGE, FAILED_API_MESSAGE } = API_RESPONSE_RESULTS;

export const BranchView = ({ isAllAccountingLocationsCompleted, BpTpProps }: IProps) => {
  const navigate = useNavigate();
  const { fromHistory, cashCommodity, fromDiscrepancyAccount, status } = BpTpProps;
  const [cashState, setCashState] = useReducer(cashReducer, initialCashCommodity);
  const [forexState, setForexState] = useReducer(forexReducer, initialForexCommodity);
  const [stockState, setStockState] = useReducer(stockReducer, initialStockCommodity);
  const [obsoleteState, setObsoleteState] = useReducer(obsoleteReducer, initialObsoleteCommodity);
  const [eachAccountingLocationsDone, setEachAccountingLocationsDone] = useReducer(
    accountsReducer,
    initialAllAccountsStatus,
  );

  const { cashData, cashPercentage, cashProgressColor, cashAction } = cashState;
  const { forexData, forexPercentage, forexProgressColor, forexAction } = forexState;
  const { stockData, stockPercentage, stockProgressColor, stockAction } = stockState;
  const { obsoleteData, obsoletePercentage, obsoleteProgressColor, obsoleteAction } = obsoleteState;
  // const { isPeriodName } = useBpTP(); // giving undefined
  const location = useLocation();

  // TODO Need to modify for forex,stock,obsolete just like cash after all commodity implementation done
  const handleAllCommodityPercentages = useCallback(
    (commodityType: string) => {
      switch (commodityType) {
        case CASH: {
          const { progress = 0 } = cashCommodity ?? {};
          setCashState({
            cashPercentage: progress,
            cashAction: `${
              progress === 100 ? COMPLETED : progress > 0 ? IN_PROGRESS : AWAITING_ACTION
            }`,
            cashProgressColor: `${
              progress === 100 ? GREENCODE : progress > 0 ? AMBERCODE : REDCODE
            }`,
          });
          break;
        }
        case FOREX: {
          const forexPercentage = 100;
          setForexState({ forexPercentage });
          break;
        }
        // Commented as per BM-8944
        // case STOCK: {
        //   const stockPercentage = 100;
        //   setStockState({ stockPercentage });
        //   break;
        // }
        // case OBSOLETE_STOCK: {
        //   const obsoletePercentage = 100;
        //   setObsoleteState({ obsoletePercentage });
        //   break;
        // }
      }
    },
    [cashCommodity],
  );

  const historyDefaultEnabled = useCallback(() => {
    setEachAccountingLocationsDone(defaultAllAccountsStatus);
    isAllAccountingLocationsCompleted(true);
  }, [isAllAccountingLocationsCompleted]);

  // TODO this is only for cash Commodity functionality we have to do same for other commodities
  useEffect(() => {
    if (!cashCommodity) return;
    const cashAccounts = Object.values(cashCommodity?.accountingLocations);
    if (!fromHistory && !fromDiscrepancyAccount) {
      const inCompleteData = cashAccounts?.filter((item) => !item.declarationCompleted);

      setCashState({
        cashData: inCompleteData,
      });
      if (cashAccounts.length > 0 && inCompleteData?.length === 0) {
        setEachAccountingLocationsDone({ cash: true });
      } else {
        setEachAccountingLocationsDone({ cash: false });
      }
    } else {
      cashAccounts.length > 0 && historyDefaultEnabled();
    }
  }, [cashCommodity, fromHistory, fromDiscrepancyAccount, historyDefaultEnabled]);

  //TODO Add other commodity dependency after all commodity features implemented
  useLayoutEffect(() => {
    const allAccountsCompleted = Object.values(eachAccountingLocationsDone).every(
      (item) => item === true,
    );
    isAllAccountingLocationsCompleted(allAccountsCompleted);
  }, [cashData, eachAccountingLocationsDone, isAllAccountingLocationsCompleted]);

  useEffect(() => {
    if (!cashData.length) return;
    if (!fromHistory && !fromDiscrepancyAccount) {
      handleAllCommodityPercentages(CASH);
      //TODO Below Functionality will be uncommented in future
      //? can be separate in different effects based on commodity data dependency
      // handleAllCommodityPercentages(FOREX);
      // handleAllCommodityPercentages(STOCK);
      // handleAllCommodityPercentages(OBSOLETE_STOCK);
    }
  }, [cashData, fromHistory, fromDiscrepancyAccount, handleAllCommodityPercentages]);

  const findRoute = useCallback(() => {
    if (location?.pathname === "/balancePeriod") {
      return SCREENS.BALANCE_PERIOD;
    } else {
      return SCREENS.TRADING_PERIOD;
    }
  }, [location?.pathname]);

  const completeBalance = (balancingDeclID: string, accountingLocationID: string) => {
    navigate(
      `${SCREENS.CASH_BALANCE}/${CASH_BALANCE_SCREENS.TP_BP_WITHOUT_DECLARATION_ID}/${accountingLocationID}`,
      { state: { from: findRoute() } },
    );
  };

  const branchViewCommodityProps = (commodityTypeName: string) => {
    switch (commodityTypeName) {
      case CASH:
        return {
          commodityTypeName,
          commodityProgressColor: cashProgressColor,
          commodityAction: cashAction,
          commodityPercentage: cashPercentage,
          commodityData: cashData,
          commodityVarianceValue: cashCommodity?.varianceValue,
        };
      case FOREX:
        return {
          commodityTypeName,
          commodityProgressColor: forexProgressColor,
          commodityAction: forexAction,
          commodityPercentage: forexPercentage,
          commodityData: forexData,
          commodityVarianceValue: 0,
        };
      // Commented as per BM-8944
      // case STOCK:
      //   return {
      //     commodityTypeName,
      //     commodityProgressColor: stockProgressColor,
      //     commodityAction: stockAction,
      //     commodityPercentage: stockPercentage,
      //     commodityData: stockData,
      //     commodityVarianceValue: 0,
      //   };
      // case OBSOLETE_STOCK:
      //   return {
      //     commodityTypeName,
      //     commodityProgressColor: obsoleteProgressColor,
      //     commodityAction: obsoleteAction,
      //     commodityPercentage: obsoletePercentage,
      //     commodityData: obsoleteData,
      //     commodityVarianceValue: 0,
      //   };
    }
  };

  const branchViewProps = () => ({
    completeBalance,
    fromHistory,
    fromDiscrepancyAccount,
  });

  return (
    <div className="w-full px-10 py-4">
      <div className="flex justify-center">
        <h2 className="testSizeSubHead mb-6">Branch View</h2>
      </div>
      <div className="w-full balanceTpTable overflow-y-auto">
        <table className="table-fixed dynamictable w-full border-collapse">
          <thead>
            <tr className="h-14">
              {branchViewHeaderLists.map(({ headerName, css: { width } }) => (
                <th className={`text-left ${width} text-lg font-bold textColor`} key={getUUID()}>
                  {headerName}
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {cashCommodity &&
              Object.values(cashCommodity?.accountingLocations)?.length > 0 &&
              Object.values(BP_TP_COMMODITY_TYPES).map((commodityTypeName) => (
                <EachBranchViewCommodity
                  key={getUUID()}
                  branchViewCommodityProps={branchViewCommodityProps(commodityTypeName)}
                  branchViewProps={branchViewProps()}
                />
              ))}
          </tbody>
        </table>
      </div>
      {cashCommodity && !Object.values(cashCommodity?.accountingLocations)?.length && (
        <ErrorMessage message={NO_DATA_FOUND_MESSAGE}></ErrorMessage>
      )}
      {status === "error" && !cashCommodity && (
        <ErrorMessage message={FAILED_API_MESSAGE}></ErrorMessage>
      )}
    </div>
  );
};
