import { useEffect } from "react";
import { iPagination } from "../types";
import "./pagination.css";
import { DOTS, usePagination } from "./usePagination";
interface iProps extends iPagination {
  className: string;
  onPageChange: (param) => void;
  handleSetPaginationData?: (currentPage, lastPage) => void;
}
/*** Pagination component* @param props* @returns*/ const Pagination = (props: iProps) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
    handleSetPaginationData,
  } = props;
  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });
  const lastPage = paginationRange && paginationRange[paginationRange?.length - 1];
  useEffect(() => {
    if (handleSetPaginationData) handleSetPaginationData(currentPage, lastPage);
  }, [currentPage, handleSetPaginationData, lastPage]);
  const onNext = () => {
    onPageChange(currentPage + 1);
  };
  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };
  if (currentPage === 0 || paginationRange?.length < 2) {
    return null;
  }
  return (
    <ul data-testid="pagination" className={`inset-1 pagination-container ${className}`}>
      <li
        key="previous"
        className={`pagination-item ${currentPage === 1 ? "disabled" : ""}`}
        onClick={onPrevious}>
        &lt; Previous
      </li>
      {paginationRange?.map((pageNumber, index) => {
        if (pageNumber === DOTS) {
          return (
            <li key={index} className="pagination-item dots">
              &#8230;
            </li>
          );
        }
        return (
          <li
            key={index}
            className={`pagination-item ${pageNumber === currentPage ? "selected" : ""}`}
            onClick={() => onPageChange(pageNumber)}>
            {pageNumber}
          </li>
        );
      })}
      <li
        key="next"
        className={`pagination-item ${
          currentPage === lastPage ? "disabled" : totalCount === undefined ? "disabled" : ""
        }`}
        onClick={onNext}>
        Next &gt;
      </li>
    </ul>
  );
};
export default Pagination;
