import Loader from "@bbo/components/Loader";
import { featureFlags } from "@bbo/constants";
import { useAppDispatch, useHubListener } from "@bbo/lib/hooks";
import { useIsPOLauncher } from "@bbo/lib/hooks/useIsPOLauncher";
import { ReactNode, useState } from "react";
import { Navigate, Outlet, useLocation, useOutletContext, useSearchParams } from "react-router-dom";
import { Header } from "../Header/Header";

type titleType = {
  setTitle: (title: string) => void;
  setIsBackButtonActive?: (includeBackBtn: boolean) => void;
};

export const useTitle = () => {
  return useOutletContext<titleType>();
};

export const AuthRoutes = ({ children }: { children?: ReactNode }) => {
  const [title, setTitle] = useState<string>("");
  const [BackBtn, setIsBackButtonActive] = useState<boolean>(true);
  const [query] = useSearchParams();
  useIsPOLauncher({ query });
  const location = useLocation();
  const dispatch = useAppDispatch();
  const { isLoggedIn, loading } = useHubListener();
  if (loading) {
    return <Loader />;
  }

  if (!isLoggedIn && !featureFlags.shouldUseFederatedSignIn) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  return (
    <>
      {isLoggedIn && <Header title={title} includeBackBtn={BackBtn} />}
      {children ? children : <Outlet context={{ setTitle, setIsBackButtonActive }} />}
      {/* Remove Support Chat Bubble */}
      {/* {isLoggedIn && <ChatBot />} */}
    </>
  );
};
