import { useGlobalState } from "@bbo/lib";
import { useEffect, useState } from "react";
import placeHolderImg from "../../assets/images/placeHolderImage.svg";
import { StockCountWarningPopup } from "../../features/StockCount/StockCountWarningPopup";
import { CountedProduct } from "./StockCount";

interface iProps {
  countType: string;
  productDetailsResponse: CountedProduct;
  handleCountedProduct: (isCancel?: boolean, ...args: CountedProduct[]) => void;
  handleCancel: () => void;
  isCountedProduct: boolean;
  showCompletedPopup?: boolean;
  handleCancelPopUp: (flag: boolean) => void;
}

const initialState: CountedProduct = {
  description: "",
  productImage: "",
  name: "",
  itemID: "",
  quantity: 0,
  barcode: "",
  countGroup: "",
  enteredQuantity: "",
  additionalQuantity: "",
};

export const ProductDescription = ({
  countType,
  productDetailsResponse,
  handleCountedProduct,
  handleCancel,
  isCountedProduct,
  showCompletedPopup,
  handleCancelPopUp,
}: iProps) => {
  const [showAdditionalQuantity, setShowAdditionalQuantity] = useState(false);
  const [state, setState] = useState<CountedProduct>(initialState);

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      enteredQuantity: productDetailsResponse?.enteredQuantity ?? "",
    }));
  }, [productDetailsResponse]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    setState((prev) => ({
      ...prev,
      [e.target.name]: Math.max(Number(e.target.value), 0),
    }));
  };

  const handleConfirm = (isCancel: boolean): void => {
    handleCancelPopUp(false);
    handleCountedProduct(isCancel, {
      ...productDetailsResponse,
      enteredQuantity: Number(state.enteredQuantity) + Number(state.additionalQuantity),
      additionalQuantity: Number(state.additionalQuantity),
    });
  };

  return (
    <>
      <div className="col-span-2 mb-8">
        {productDetailsResponse && (
          <div className="grid grid-cols-12">
            <div className="col-span-1">
              <img
                src={productDetailsResponse?.productImage || placeHolderImg}
                width="98px"
                height="103px"
              />
            </div>
            <div className="col-span-7 ml-10 standard-label">
              <div className="col-span-12 justify-start">
                <label className="font-bold">Product Description</label>
                <p>{productDetailsResponse?.description}</p>
              </div>
              <div className="col-span-12 mt-5">
                <label className="font-bold">
                  {isCountedProduct ? "Edit Quantity" : "Quantity"}
                </label>
                <div className="mt-3">
                  <input
                    name="enteredQuantity"
                    type="number"
                    min="0"
                    pattern="[0-9]"
                    className={"input-amount-range"}
                    placeholder="Enter quantity"
                    value={`${state.enteredQuantity}`}
                    disabled={showAdditionalQuantity}
                    onChange={(e) => handleInputChange(e)}
                  />
                  {isCountedProduct && (
                    <button
                      type="button"
                      className={`circle blue-border-bg plus ml-6  ${
                        showAdditionalQuantity ? "opacity-25" : ""
                      }`}
                      disabled={showAdditionalQuantity}
                      onClick={() => {
                        setShowAdditionalQuantity(true);
                      }}></button>
                  )}
                </div>
                {showAdditionalQuantity && (
                  <div className="mt-5">
                    <label className="font-normal">Additional Quantity </label>
                    <div className="mt-3">
                      <input
                        name="additionalQuantity"
                        type="number"
                        min="0"
                        pattern="[0-9]"
                        className={"input-amount-range"}
                        placeholder="Enter quantity"
                        value={`${state.additionalQuantity}`}
                        onChange={(e) => handleInputChange(e)}
                      />
                      <button
                        onClick={() => {
                          setShowAdditionalQuantity(false);
                        }}
                        type="button"
                        className="circle orange-border-bg cross ml-6"></button>
                    </div>
                    <div className="font-bold mt-5">
                      New Total :
                      <span className="ml-2">
                        {Number(state.enteredQuantity) + Number(state.additionalQuantity)}
                      </span>
                    </div>
                  </div>
                )}
              </div>
              <div className="flex mt-12">
                <button className="action-button button-white mr-8" onClick={handleCancel}>
                  Cancel
                </button>
                <button
                  className="action-button button-red"
                  onClick={() => handleConfirm(false)}
                  disabled={state.enteredQuantity === ""}>
                  Confirm
                </button>
              </div>
            </div>
            <div className="col-span-4 text-right">
              <button className="col-span-4 action-label-orange">Remove product from count</button>
            </div>
          </div>
        )}
        {showCompletedPopup && (
          <StockCountWarningPopup
            onNo={() => {
              handleCancelPopUp(false);
            }}
            onYes={() => {
              handleConfirm(true);
            }}
          />
        )}
      </div>
    </>
  );
};
