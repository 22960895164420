/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, UseMutationOptions, MutationFunction } from "@tanstack/react-query";
import type {
  CreateAccountingLocationResponseResponse,
  BadRequestResponse,
  UnauthorizedResponse,
  ForbiddenResponse,
  FailureResponseResponse,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Create a new Cash Drawer Accounting Location, and creates a safe if none exist for provided fadcode
 */
export const useCreateAccountingLocationHook = () => {
  const createAccountingLocation = useCustomInstance<CreateAccountingLocationResponseResponse>();

  return () => {
    return createAccountingLocation({ url: `/bm-accounting-location/v1/`, method: "post" });
  };
};

export type CreateAccountingLocationMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useCreateAccountingLocationHook>>
>;

export type CreateAccountingLocationMutationError = ErrorType<
  BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | FailureResponseResponse
>;

export const useCreateAccountingLocation = <
  TError = ErrorType<
    BadRequestResponse | UnauthorizedResponse | ForbiddenResponse | FailureResponseResponse
  >,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof useCreateAccountingLocationHook>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const createAccountingLocation = useCreateAccountingLocationHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof useCreateAccountingLocationHook>>,
    TVariables
  > = () => {
    return createAccountingLocation();
  };

  return useMutation<
    AsyncReturnType<typeof createAccountingLocation>,
    TError,
    TVariables,
    TContext
  >(mutationFn, mutationOptions);
};
