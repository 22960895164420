import { MapProps } from "@bbo/context";
import { useRef } from "react";

export const useDescendants = () => {
  const indexCounter = useRef<number>(0);
  const map = useRef<MapProps | Record<string, never>>({});

  if (!map.current) map.current = {};
  const reset = () => {
    indexCounter.current = 0;
    map.current = {};
  };

  const get = (id: string | number, props: Record<string, unknown>) => {
    const hidden = props ? props.hidden : false;
    if (!map.current[id]) map.current[id] = { index: hidden ? -1 : indexCounter.current++ };
    map.current[id].props = props;
    return map.current[id].index;
  };

  // Do NOT memoize context value, so that we bypass memo on any children
  // We NEED them to re-render, in case stable children were re-ordered
  // (this creates a new object every render, so children reading the context MUST re-render)
  return { get, map, reset };
};
