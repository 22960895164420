import { TabContext } from "@bbo/context";
import { useContext } from "react";

export const useTabsContext = () => {
  const context = useContext(TabContext);
  if (!context) {
    throw new Error("TabsContext must be used within Tab component");
  }
  return context;
};
