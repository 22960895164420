export const TRANSACTION_TITLE = {
  PaidIn: "Paid in",
  PaidOut: "Paid out",
  TransferIn: "Transfer In",
  TransferOut: "Transfer Out",
  PouchAcceptance: "Pouch Acceptance",
  PouchPreparation: "Pouch Preparation",
  PouchDespatch: "Pouch Despatch",
  PouchPreparationCancelPouch: "Pouch Cancellation ",
  Safe: "Safe",
  BackOffice: "Back Office",
  CounterTerminal: "Counter Terminal",
  NA: "N/A",
  CashStockCentre: "Cash/Stock Centre",
  OutOfBranch: "Out of Branch",
} as const;

export const TRANSACTION_TABLE = {
  Token: "Token",
  nodeID: "nodeID",
  fadcode: "fadcode",
  userName: "userName",
  smartID: "smartID",
  operationType: "operationType",
  dateTime: "dateTime",
  transferAt: "transferAt",
  transferAmount: "transferAmount",
  destinationEntityName: "destinationEntityName",
  sourceEntityName: "sourceEntityName",
} as const;

export const TransactionType = [
  { name: TRANSACTION_TITLE.PaidIn },
  { name: TRANSACTION_TITLE.PaidOut },
  { name: TRANSACTION_TITLE.TransferIn },
  { name: TRANSACTION_TITLE.TransferOut },
  { name: TRANSACTION_TITLE.PouchAcceptance },
  { name: TRANSACTION_TITLE.PouchPreparation },
  { name: TRANSACTION_TITLE.PouchDespatch },
  { name: TRANSACTION_TITLE.PouchPreparationCancelPouch },
];
