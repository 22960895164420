import { CashDetail, CashDetailContext } from "@bbo/context";
import { CASH_DETAILS } from "@bbo/features/AdhocBalance/ladderConstant";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { useGlobalState } from "@bbo/lib";
import AdhocCashBalancing from "@bbo/screens/AdhocCashBalancing";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

const CashDrawerCashBalancing = () => {
  const { setTitle } = useTitle();
  const [, setIsReadonlyMode] = useGlobalState("isReadonlyMode");
  const { accountingLocationID } = useParams();

  useEffect(() => {
    setTitle("Cash Drawer Cash Balancing");
    setIsReadonlyMode({ isReadonlyMode: false });
  }, [setIsReadonlyMode, setTitle]);

  const [cashDetail, setCashDetail] = useState<CashDetail[]>([...CASH_DETAILS]);

  return (
    <CashDetailContext.Provider
      value={{
        cashDetail,
        setCashDetail,
        accountingLocationID,
      }}>
      <AdhocCashBalancing />
    </CashDetailContext.Provider>
  );
};

export default CashDrawerCashBalancing;
