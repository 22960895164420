/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  GetPouchListResponseResponse,
  BboPouchManagementBadRequestResponse,
  UnauthorizedResponse,
  BboPouchManagementForbiddenResponse,
  BboPouchManagementFailureResponseResponse,
  GetPouchListParams,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Pouch list of last status(version V0000000000) records for a branch
 * @summary Get Pouch list of latest status for a branch
 */
export const useGetPouchListHook = () => {
  const getPouchList = useCustomInstance<GetPouchListResponseResponse>();

  return (params?: GetPouchListParams) => {
    return getPouchList({ url: `/bm-pouch-management/v1/pouch/list`, method: "get", params });
  };
};

export const getGetPouchListQueryKey = (params?: GetPouchListParams) => [
  `/bm-pouch-management/v1/pouch/list`,
  ...(params ? [params] : []),
];

export type GetPouchListQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetPouchListHook>>
>;
export type GetPouchListQueryError = ErrorType<
  | BboPouchManagementBadRequestResponse
  | UnauthorizedResponse
  | BboPouchManagementForbiddenResponse
  | BboPouchManagementFailureResponseResponse
>;

export const useGetPouchList = <
  TData = AsyncReturnType<ReturnType<typeof useGetPouchListHook>>,
  TError = ErrorType<
    | BboPouchManagementBadRequestResponse
    | UnauthorizedResponse
    | BboPouchManagementForbiddenResponse
    | BboPouchManagementFailureResponseResponse
  >,
>(
  params?: GetPouchListParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<ReturnType<typeof useGetPouchListHook>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetPouchListQueryKey(params);

  const getPouchList = useGetPouchListHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetPouchListHook>>> = () =>
    getPouchList(params);

  const query = useQuery<AsyncReturnType<ReturnType<typeof useGetPouchListHook>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};
