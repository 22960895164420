import { useGetBranchConfig, useGetGlobal, useGetMenuList } from "@bbo/api/generator";
import { HEADER_CONSTANTS } from "@bbo/constants/HeaderConstants";
import { useHubListener } from "@bbo/lib";
import { setMaxBranchLimit } from "@bbo/lib/reduxStore/MaxBranchLimit";
import { useEffect, useRef, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../lib";
import { setBranchDetails } from "../../lib/reduxStore/branchDetais.slice";
import MenuList from "./MenuList";
/**
 * SideMenu component
 */

interface MaxBranchLimit {
  dailyCutOff: number;
  endOfDay: number;
  toleranceAllowed: number;
  maximumLimit: number;
  permittedLimit: number;
}
export const SideMenu = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const { isLoggedIn } = useHubListener();
  const { fadCode } = useAppSelector((root) => root.auth.authUserData);
  const { data: menuListData } = useGetMenuList();
  const { data: branchDetails } = useGetBranchConfig();
  const { data: encodeData } = useGetGlobal("metadata");

  const refDrawer = useRef(null);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (encodeData?.message) {
      const newValue: MaxBranchLimit = JSON.parse(atob(encodeData?.message));

      dispatch(
        setMaxBranchLimit({
          dailyCutOff: newValue["dailyCutOff"],
          endOfDay: newValue["endOfDay"],
          toleranceAllowed: newValue["toleranceAllowed"],
          maximumLimit: newValue["maximumLimit"],
          permittedLimit: newValue["permittedLimit"],
        }),
      );
    }
  }, [dispatch, encodeData?.message]);

  const address = `${branchDetails?.addressLine1} ${branchDetails?.addressLine2} ${branchDetails?.addressLine3} ${branchDetails?.addressLine4} ${branchDetails?.addressLine5}`;

  useEffect(() => {
    branchDetails &&
      dispatch(
        setBranchDetails({
          branch_address: address,
          branch_name: branchDetails?.branchName,
          branch_type: branchDetails?.branchType,
          fadcode: branchDetails?.fadcode,
          is_royal_mails_branch: false,
        }),
      );
  }, [address, branchDetails, dispatch]);

  useEffect(() => {
    const handleClick = (e) => {
      if (menuOpen) {
        if (!refDrawer || (refDrawer && refDrawer.current.contains(e.target))) return;
        setMenuOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  const handleMenuClose = () => {
    setMenuOpen(false);
  };

  const MenuDrawer = () => {
    if (!isLoggedIn) return null;

    return (
      <div className="sidebarclass">
        <div
          id="sidebar"
          className={`absolute z-40 left-0 top-0 transform h-screen overflow-y-scroll no-scrollbar flex-shrink-0 bg-white p-4 transition-transform duration-200 ease-in-out sidebarBorder ${
            menuOpen ? "translate-x-0 w-72" : "-translate-x-72 w-0"
          }`}>
          <MenuList items={menuListData} onMenuClose={handleMenuClose} />
        </div>
      </div>
    );
  };

  return (
    <div id="side-menu" className="justify-center flex items-center" ref={refDrawer}>
      <button
        id="btn-hamburger"
        className="text-gray-500 hover:text-gray-600 sidebarBtnCss"
        aria-controls="sidebar"
        onClick={() => setMenuOpen((open) => !open)}
        data-cy="leftTab">
        <span className="sr-only"> {HEADER_CONSTANTS.OPEN_SIDEBAR}</span>
        <svg
          id="svg-hamburger"
          className="w-10 h-10 fill-current text-white"
          viewBox="4 4 24 24"
          xmlns="http://www.w3.org/2000/svg">
          <rect x="4" y="5" width="16" height="2" />
          <rect x="4" y="11" width="16" height="2" />
          <rect x="4" y="17" width="16" height="2" />
        </svg>
      </button>
      <MenuDrawer />
    </div>
  );
};
