import { PasswordValidation } from "@bbo/features/PasswordValidation";
import { useState } from "react";
import { CancelCommitPopup } from "./CancelCommitPopup";
import { CommitPopup } from "./CommitPopup";
import "./index.css";

const INITIAL_FLAGS = {
  initial: true,
  passwordValidation: false,
  cancelCommit: false,
  cancelFlagName: "",
};

interface iProps {
  isPasswordValidation: boolean;
  popupType: string;
  onCancelCommit: () => void;
  onCommit: () => void;
  content: {
    initialPopup: {
      commitButton: string;
      title: string;
      lines: string[];
    };
    cancelPopup: {
      cancelNavButton: string;
      title: string;
      lines: string[];
    };
  };
}

export const CommitTpBp = (props: iProps) => {
  const [showPopup, setShowPopup] = useState({ ...INITIAL_FLAGS });

  const handleCancel = (params) => {
    setShowPopup((flags) => ({
      ...flags,
      ...params,
    }));
  };

  const handleSuccess = (params) => {
    setShowPopup((flags) => ({
      ...flags,
      ...params,
    }));

    if (!props.isPasswordValidation) {
      // Call commit callback if Password validation no required
      if (props.onCommit) {
        props.onCommit();
      }
    }
  };

  const handlePasswordValidationSuccess = async (params) => {
    setShowPopup((flags) => ({
      ...flags,
      ...params,
    }));

    if (props.onCommit) {
      props.onCommit();
    }
  };

  const handleConfirmCancellation = (params) => {
    setShowPopup((flags) => ({
      ...flags,
      ...params,
    }));
    if (props.onCancelCommit) {
      props.onCancelCommit();
    }
  };

  // Show popup for password validation
  if (showPopup.passwordValidation && props.isPasswordValidation) {
    return (
      <>
        <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
          <PasswordValidation onCancel={handleCancel} onSuccess={handlePasswordValidationSuccess} />
        </div>
        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
      </>
    );
  }

  // Show popup for cancel commit Trading Period
  if (showPopup.cancelCommit) {
    return (
      <CancelCommitPopup
        onCancel={handleCancel}
        onSuccess={handleConfirmCancellation}
        cancelFlagName={showPopup.cancelFlagName}
        popupData={props.content.cancelPopup}
      />
    );
  }

  // Show initial popup
  if (showPopup.initial) {
    return (
      <CommitPopup
        onCancel={handleCancel}
        onSuccess={handleSuccess}
        popupData={props.content.initialPopup}
        isPasswordValidation={props.isPasswordValidation}
      />
    );
  }

  return null;
};
