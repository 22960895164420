import { AuthRoutes } from "@bbo/features";
import { useAWSConfig, useHubListener } from "@bbo/lib/hooks";
import AdhocCashBalancing from "@bbo/screens/AdhocCashBalancing";
import BranchSummary from "@bbo/screens/BranchSummary";
import CashBalanceHistory from "@bbo/screens/CashBalanceHistory";
import CashMovementHistory from "@bbo/screens/CashMovementHistory";
import DiscrepancyAccountPage from "@bbo/screens/DiscrepancyAccountPage";
import Login from "@bbo/screens/Login";
import MailDespatch from "@bbo/screens/MailDespatch";
import ManageCashDrawers from "@bbo/screens/ManageCashDrawer";
import PageNotFound from "@bbo/screens/PageNotFound";
import PouchPreparationHistory from "@bbo/screens/PouchPreparationHistory";
import RecordKnownPage from "@bbo/screens/RecordKnownVariance";
import BranchOverviewStockScreen from "@bbo/screens/StockBranchOverview";
import StockCheck from "@bbo/screens/StockCheckScreen";
import StockCount from "@bbo/screens/StockCount";
import StockCountReviewScreen from "@bbo/screens/StockCountReview";
import TaskManagement from "@bbo/screens/TaskManagement";
import TpBpHistory from "@bbo/screens/TpBpHistory";
import TransferIn from "@bbo/screens/TransferIn";
import TransferOut from "@bbo/screens/Transferout";
import { Auth } from "aws-amplify";
import { Navigate, Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import { startWorker } from "./__mocks__/newCashMockApiHelper";
import { CASH_BALANCE_SCREENS, SCREENS, featureFlags } from "./constants";
import BookingDetailsScreen from "./screens/BookingDetails";
import BranchSelectionPage from "./screens/BranchSelectionPage";
import CashDrawerCashBalancing from "./screens/CashDrawerCashBalancing";
import { CurrencyCount } from "./screens/CurrencyCount";
import { CurrencyCountReview } from "./screens/CurrencyCountReview";
import { CurrencyCountSummary } from "./screens/CurrencyCountSummary";
import CurrentBp from "./screens/CurrentBp";
import CurrentTp from "./screens/CurrentTp";
import ForexDenominationLadder from "./screens/DenominationLadder";
import ForexOverview from "./screens/ForexBranchOverview";
import HistoricalBp from "./screens/HistoricalBp";
import HistoricalTp from "./screens/HistoricalTp";
import { PaidIn } from "./screens/PaidIn";
import { PaidOut } from "./screens/PaidOut";
import SafeCashBalancing from "./screens/SafeCashBalancing";
import SalesSummaryScreen from "./screens/SalesSummary";
import { Test } from "./screens/Test";
import TpBpCashBalancing from "./screens/TpBpCashBalancing";
import TpBpHistoryCashBalancing from "./screens/TpBpHistoryCashBalancing";
import TpBpWithoutDeclarationId from "./screens/TpBpWithoutDeclarationId";
import TransactionLog from "./screens/TransactionLog";
import { setValueInStorage } from "./utils/util";
import { PouchPreparationScan } from "./screens/PouchPreparationScan";
import { PouchPreparation } from "./screens/PouchPreparation";

// If we're in a build, don't mock the cash APIs
const MOCK_NEW_CASH_APIS =
  featureFlags.shouldUseMockServiceWorker && process.env.REACT_APP_IS_BUILD !== "true";

if (MOCK_NEW_CASH_APIS) {
  startWorker();
}

const App = () => {
  const { isLoggedIn } = useHubListener();
  const { authConfig, loadingConfig } = useAWSConfig();
  const location = useLocation();
  const [searchParams] = useSearchParams();

  const fadcodeInURL = searchParams.get("fadcode");

  const navigatedFromCT = searchParams.get("navigatedFromCT");
  if (navigatedFromCT) {
    setValueInStorage("navigatedFromCT", navigatedFromCT);
  }
  if (fadcodeInURL) {
    setValueInStorage("fadcode", fadcodeInURL);
  }
  if (loadingConfig) {
    return null;
  }

  Auth.configure(authConfig);

  return (
    <div data-testid="app" className="flex h-screen overflow-hidden">
      <div
        className={
          location.pathname === SCREENS.BRANCH_SELECTION
            ? "relative flex flex-1 overflow-y-auto containerTop loginBackground"
            : `relative flex flex-col flex-1 overflow-y-auto containerTop ${
                isLoggedIn ? "marginTop10" : "loginBackground"
              }`
        }>
        <Routes>
          <Route element={<AuthRoutes />}>
            <Route path={SCREENS.TASK_MANAGEMENT} element={<TaskManagement />} />
            <Route path={SCREENS.BRANCH_SELECTION} element={<BranchSelectionPage />} />
            <Route path={`${SCREENS.PAID_IN}/:accountingLocationID`} element={<PaidIn />} />
            <Route path={`${SCREENS.PAID_OUT}/:accountingLocationID`} element={<PaidOut />} />
            <Route path={`${SCREENS.TRANSFER_IN}/:accountingLocationID`} element={<TransferIn />} />
            <Route
              path={`${SCREENS.TRANSFER_OUT}/:accountingLocationID`}
              element={<TransferOut />}
            />
            <Route path={SCREENS.HOME} element={<AdhocCashBalancing />} />
            <Route path={SCREENS.BRANCH_OVERVIEW} element={<BranchSummary />} />
            <Route path={SCREENS.CASH_BALANCE}>
              <Route
                path={`${CASH_BALANCE_SCREENS.CASH_DRAWER}/:accountingLocationID`}
                element={<CashDrawerCashBalancing />}
              />
              <Route
                path={`${CASH_BALANCE_SCREENS.SAFE}/:accountingLocationID`}
                element={<SafeCashBalancing />}
              />
              <Route
                path={`${CASH_BALANCE_SCREENS.TP_BP_WITH_DECLARATION_ID}/:declarationID`}
                element={<TpBpCashBalancing />}
              />
              <Route
                path={`${CASH_BALANCE_SCREENS.TP_BP_HISTORY}/:declarationID`}
                element={<TpBpHistoryCashBalancing />}
              />
              <Route
                path={`${CASH_BALANCE_SCREENS.TP_BP_WITHOUT_DECLARATION_ID}/:accountingLocationID`}
                element={<TpBpWithoutDeclarationId />}
              />
            </Route>
            <Route path={SCREENS.POUCH_PREPARATION}>
              {/* Same component, route handled by a ternary component */}
              <Route index element={<PouchPreparationScan />} />
              <Route path=":pouchID" element={<PouchPreparation />} />
            </Route>{" "}
            <Route path={SCREENS.MANAGE_CASH_DRAWER} element={<ManageCashDrawers />} />
            <Route path={SCREENS.RECORD_KNOWN_VARIANCE} element={<RecordKnownPage />} />
            <Route path={SCREENS.CASH_BALANCES_HISTORY} element={<CashBalanceHistory />} />
            <Route path={SCREENS.BALANCE_PERIOD}>
              <Route index element={<CurrentBp />} />
              <Route path=":declarationID" element={<HistoricalBp />} />
            </Route>
            <Route path={SCREENS.TRADING_PERIOD}>
              <Route index element={<CurrentTp />} />
              <Route path=":declarationID" element={<HistoricalTp />} />
            </Route>
            <Route path={SCREENS.CASH_MOVEMENT_HISTORY} element={<CashMovementHistory />} />
            <Route path={SCREENS.DISCREPANCY_ACCOUNT} element={<DiscrepancyAccountPage />} />
            <Route path={`${SCREENS.MAIL_DESPATCH}/:branchId/:nodeId`} element={<MailDespatch />} />
            <Route path={SCREENS.POUCH_PREPARATION_HISTORY} element={<PouchPreparationHistory />} />
            <Route path={SCREENS.STOCK_CHECK} element={<StockCheck />} />
            <Route path={SCREENS.BALANCE_TRADING_PERIOD_HISTORY} element={<TpBpHistory />} />
            <Route path={SCREENS.STOCK_COUNT} element={<StockCount />} />
            <Route path={SCREENS.BRANCH_OVERVIEW_STOCK} element={<BranchOverviewStockScreen />} />
            <Route path={SCREENS.STOCK_COUNT_REVIEW} element={<StockCountReviewScreen />} />
            <Route path={SCREENS.BRANCH_OVERVIEW_FOREX} element={<ForexOverview />} />
            <Route path={SCREENS.CURRENCY_COUNT_SUMMARY} element={<CurrencyCountSummary />} />
            <Route path={SCREENS.TRANSACTION_LOG} element={<TransactionLog />} />
            <Route path={SCREENS.CURRENCY_COUNT} element={<CurrencyCount />} />
            <Route
              path={SCREENS.CURRENCY_COUNT_DENOMINATION_LADDER}
              element={<ForexDenominationLadder />}
            />
            <Route path={SCREENS.MANUAL_BOOK_IN_STOCK} element={<BookingDetailsScreen />} />
            <Route path={SCREENS.CURRENCY_COUNT_REVIEW} element={<CurrencyCountReview />} />
            <Route path={SCREENS.SALES_SUMMARY} element={<SalesSummaryScreen />} />
            <Route path={SCREENS.TEST} element={<Test />} />
          </Route>
          <Route path={SCREENS.LOGIN} element={<Login />} />
          <Route path="*" element={<PageNotFound />} />
          <Route path="/" element={<Navigate to={SCREENS.TASK_MANAGEMENT} replace />} />
        </Routes>
      </div>
    </div>
  );
};

export default App;
