/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, UseMutationOptions, MutationFunction } from "@tanstack/react-query";
import type {
  CreateBpTpResponseResponse,
  BboDeclarationBadRequestResponse,
  UnauthorizedResponse,
  ForbiddenResponse,
  BboDeclarationFailureResponseResponse,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Create a new BP
 */
export const useCreateBpHook = () => {
  const createBp = useCustomInstance<CreateBpTpResponseResponse>();

  return () => {
    return createBp({ url: `/bm-declaration-service/v1/bp`, method: "post" });
  };
};

export type CreateBpMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useCreateBpHook>>
>;

export type CreateBpMutationError = ErrorType<
  | BboDeclarationBadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | BboDeclarationFailureResponseResponse
>;

export const useCreateBp = <
  TError = ErrorType<
    | BboDeclarationBadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | BboDeclarationFailureResponseResponse
  >,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof useCreateBpHook>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const createBp = useCreateBpHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof useCreateBpHook>>,
    TVariables
  > = () => {
    return createBp();
  };

  return useMutation<AsyncReturnType<typeof createBp>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions,
  );
};
