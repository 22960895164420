import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { SetStateAction, useEffect, useState } from "react";
import { currentPageSize, rows } from "../Constants";
import { useLocation, useNavigate } from "react-router-dom";
import { ICurrency } from "@bbo/features/CurrencyCount/CurrencyCountScreen";

interface IType {
  Negative: string | any[];
  Positive: string | any[];
  Zero: string | any[];
}
interface ILocationState {
  iSelected?: ICurrency;
}
export default function useCurrencyCountReviewScreenHook() {
  const { setTitle } = useTitle();
  const [tableData, settableData] = useState(rows);
  const [currentPage, setCurrentPage] = useState(1);
  const [open, setOpen] = useState(false);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const selectedTask = useAppSelector((root) => root.selectedTask);
  const location = useLocation();
  const [currencyInfo, setCurrencyInfo] = useState<ILocationState>({
    iSelected: {},
  });
  const navigate = useNavigate();
  let pagetitle = "";
  if (selectedTask?.associatedEntityName && selectedTask?.associatedEntityName !== "") {
    pagetitle = `${selectedTask.entityName} - ${selectedTask?.associatedEntityName}`;
  } else {
    pagetitle = `${selectedTask.entityName}`;
  }
  const getTotalCountOfRows = (rows: IType) => {
    return rows.Negative.length + rows.Positive.length + rows.Zero.length;
  };
  function setRowsBasedOnPagination() {
    const { Positive, Negative, Zero }: IType = rows;
    const fullArr = [...Negative, ...Positive, ...Zero];
    const startIndex = (currentPage - 1) * currentPageSize;
    const endIndex = currentPage * currentPageSize;
    const res = fullArr.slice(startIndex, endIndex);
    const d = { Positive: [], Negative: [], Zero: [] };

    res.map((r) => {
      if (r.varianceType === "Positive") {
        d.Positive.push(r);
      } else if (r.varianceType === "Negative") {
        d.Negative.push(r);
      } else if (r.varianceType === "Zero") {
        d.Zero.push(r);
      }
    });
    settableData({ ...d });
  }
  const onPaginationClick = (pageNo: SetStateAction<number>) => {
    console.log("the current page and page no is as follows", pageNo, currentPage);
    setCurrentPage(pageNo);
    setRowsBasedOnPagination();
  };

  useEffect(() => {
    setTitle(`Currency Count | ${pagetitle}`);
  }, [setTitle]);

  useEffect(() => {
    setRowsBasedOnPagination();
  }, []);

  useEffect(() => {
    setRowsBasedOnPagination();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPage]);

  useEffect(() => {
    if (location?.state !== null) {
      setCurrencyInfo({
        iSelected: { ...location?.state.iSelected },
      });
    }
    () => {
      navigate("/currencyCountReview", {
        replace: true,
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handlePopupClose = () => {
    setOpen(false);
    setConfirmOpen(true);
  };
  const handleConfirmPopupClose = () => {
    //setOpen(false);
    setConfirmOpen(false);
  };
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleCancelPopupClose = () => {
    setOpen(false);
  };
  return {
    tableData,
    currentPage,
    getTotalCountOfRows,
    onPaginationClick,
    pagetitle,
    currencyInfo,
    handlePopupClose,
    handleConfirmPopupClose,
    handleCancelPopupClose,
    handleClickOpen,
    open,
    confirmOpen,
  };
}
