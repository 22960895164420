import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MaxBranchLimit {
  dailyCutOff: number;
  endOfDay: number;
  toleranceAllowed: number;
  maximumLimit: number;
  permittedLimit: number;
}
const initialState: MaxBranchLimit = {
  dailyCutOff: 0,
  endOfDay: 0,
  toleranceAllowed: 0,
  maximumLimit: 0,
  permittedLimit: 0,
};

export const MaxBranchLimit = createSlice({
  name: "MaxBranchLimit",
  initialState,
  reducers: {
    setMaxBranchLimit: (state, action: PayloadAction<MaxBranchLimit>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setMaxBranchLimit } = MaxBranchLimit.actions;

export default MaxBranchLimit.reducer;
