import { MailsDespatch } from "@bbo/features/MailsDespatch";
import { useEffect } from "react";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";

const MailDespatch = () => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Mail Despatch");
  }, [setTitle]);

  return (
    <>
      <>
        <h2 className="font-24 font-bold leading-7 subheading text-white">Mail Despatch</h2>
        <div className="containerMain">
          <div className="w-full items-start transferOut-height overflow-hidden">
            <div className="flex flex-col lg:flex-row mb-6 w-full rounded shadow height-86">
              <div className="px-4 py-10 md:p-6 mx-auto w-full maintasklist mb-10">
                <div>
                  <MailsDespatch />
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    </>
  );
};

export default MailDespatch;
