import { CurrencyCountList, useGetCurrencyCountList } from "@bbo/api/generator";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { getValueFromStorage, setValueInStorage } from "@bbo/utils/util";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useTitle } from "../AuthRoutes/AuthRoutes";
import { useCurrencyCountGrid } from "../CurrencyCountGrid/useCurrencyCountGrid";
import {
  fnJoinAllCurrencies,
  getFullRangeCurrencies,
  getOnHandCurrencies,
  getTopCurrencies,
} from "./CurrencyCountFormat";

export interface ICurrencyData {
  ordercode: string;
  name: string;
  balance?: number;
}

let totalPageCount = 0;
export const useCurrencyCount = () => {
  const { setTitle, setIsBackButtonActive } = useTitle();
  const [selected, setSelected] = useState<CurrencyCountList>({});
  const [stage, setStage] = useState("types");
  const selectedTask = useAppSelector((root) => root.selectedTask);
  const [topSellers, setTopSellers] = useState<ICurrencyData[]>([]);
  const [onHandCurrencies, setOnhandCurrencies] = useState<ICurrencyData[]>([]);
  const [fullRange, setfullRange] = useState<ICurrencyData[]>([]);
  const [allCurrencies, setAllCurrencies] = useState<ICurrencyData[]>([]);
  const [onHandCount, setonHandCount] = useState(5);
  const [fullRangeCount, setfullRangeCount] = useState(5);
  const { onPaginationClick, currentPage } = useCurrencyCountGrid();
  const fadCode = getValueFromStorage<string>("fadcode");

  const Location = useLocation();
  const navigate = useNavigate();
  const { data, isLoading } = useGetCurrencyCountList(selectedTask.fadcode || fadCode);
  let pagetitle = "";
  if (selectedTask?.associatedEntityName && selectedTask?.associatedEntityName !== "") {
    pagetitle = `Currency Count | ${selectedTask.entityName} - ${selectedTask?.associatedEntityName}`;
  } else {
    pagetitle = `Currency Count | ${selectedTask.entityName}`;
  }

  const handleBackPressed = () => {
    if (stage === "tiles") {
      setStage("types");
      setSelected({});
    } else if (stage === "types") {
      navigate("/branchOverviewForex");
    }
  };
  const handleChange = useCallback(
    (listdata: CurrencyCountList) => {
      setSelected(listdata);
      setStage("tiles");
      onPaginationClick(1);
      setonHandCount(5);
      setfullRangeCount(5);
    },
    [onPaginationClick],
  );

  useEffect(() => {
    if (Location?.state !== null) {
      setStage("tiles");
      setSelected(Location?.state?.iSelected);
    }
    () => {
      navigate("/currencyCount", {
        replace: true,
      });
    };
  }, [Location?.state, navigate]);

  //Set the Title and back btn
  useEffect(() => {
    setTitle(pagetitle);
    setIsBackButtonActive(false);
    return () => {
      setIsBackButtonActive(true);
    };
  }, [setTitle, pagetitle, setIsBackButtonActive]);
  //Splitting the currency based on the types
  const segregateCurrency = useCallback(() => {
    let currencies = [];
    if (selected?.currencys) {
      currencies = getTopCurrencies(selected);
      setTopSellers([...currencies]);
      currencies = [];
      currencies = getOnHandCurrencies(selected);
      setOnhandCurrencies([...currencies]);
      currencies = [];
      currencies = getFullRangeCurrencies(selected);
      setfullRange([...currencies]);
    }
  }, [selected]);

  useEffect(() => {
    setTopSellers([]);
    setOnhandCurrencies([]);
    setfullRange([]);
    segregateCurrency();
  }, [segregateCurrency]);

  const fnCombineAllCurrencies = useCallback(() => {
    let tempCurrencies: ICurrencyData[] = [];
    tempCurrencies = fnJoinAllCurrencies(
      topSellers,
      onHandCurrencies,
      fullRange,
      onHandCount,
      fullRangeCount,
    );
    setAllCurrencies(tempCurrencies);
  }, [topSellers, onHandCurrencies, fullRange, onHandCount, fullRangeCount]);

  //Combining the subset of all the currencies
  useEffect(() => {
    fnCombineAllCurrencies();
  }, [
    fnCombineAllCurrencies,
    topSellers,
    fullRangeCount,
    fullRange,
    onHandCurrencies,
    onHandCount,
  ]);

  const setFullCountValue = (count: number) => {
    setfullRangeCount(count);
  };
  const setOnHandCountValue = (count: number) => {
    setonHandCount(count);
  };

  const arrCount = ((2 + onHandCount + fullRangeCount) / 3).toString().split(".");
  if (arrCount.length > 1) {
    totalPageCount = Number(arrCount[0]) + 1;
  } else {
    totalPageCount = Number(arrCount[0]);
  }

  //Onclick of currencies - Invoking Denomination Ladder
  const fnOpenDenominationLadder = (code: string, name: string) => {
    setValueInStorage("currencyCode", code);
    navigate("/currencyCountDenominationLadder", {
      state: { code: code, name: name, iSelected: selected },
    });
  };

  return {
    stage,
    selected,
    handleChange,
    handleBackPressed,
    data,
    isLoading,
    onHandCurrencies,
    fullRange,
    allCurrencies,
    setFullCountValue,
    setOnHandCountValue,
    onHandCount,
    fullRangeCount,
    totalPageCount,
    fnOpenDenominationLadder,
    onPaginationClick,
    currentPage,
  };
};
