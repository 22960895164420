/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, UseMutationOptions, MutationFunction } from "@tanstack/react-query";
import type {
  CreateBpTpResponseResponse,
  BboDeclarationBadRequestResponse,
  UnauthorizedResponse,
  ForbiddenResponse,
  BboDeclarationFailureResponseResponse,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Create a new TP
 */
export const useCreateTpHook = () => {
  const createTp = useCustomInstance<CreateBpTpResponseResponse>();

  return () => {
    return createTp({ url: `/bm-declaration-service/v1/tp`, method: "post" });
  };
};

export type CreateTpMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useCreateTpHook>>
>;

export type CreateTpMutationError = ErrorType<
  | BboDeclarationBadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | BboDeclarationFailureResponseResponse
>;

export const useCreateTp = <
  TError = ErrorType<
    | BboDeclarationBadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | BboDeclarationFailureResponseResponse
  >,
  TVariables = void,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof useCreateTpHook>>,
    TError,
    TVariables,
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const createTp = useCreateTpHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof useCreateTpHook>>,
    TVariables
  > = () => {
    return createTp();
  };

  return useMutation<AsyncReturnType<typeof createTp>, TError, TVariables, TContext>(
    mutationFn,
    mutationOptions,
  );
};
