import { BranchCashOverview, useGetStockBranchOverview } from "@bbo/api/generator";
import { convertToBranchCashOverview } from "@bbo/features/BranchOverview/branchOverviewHelpers";
import { AccountingLocationGroups } from "@bbo/features/BranchOverview/useBranchOverview";
import { useAppSelector } from "@bbo/lib";
import { useMemo, useState } from "react";

export const useStockBranchOverview = () => {
  const { fadCode } = useAppSelector((rootState) => rootState.auth.authUserData);
  const { data: stockData, isLoading } = useGetStockBranchOverview(fadCode);
  const [accountingLocation, setAccountingLocation] = useState<BranchCashOverview | null>(null);

  const accountingLocationGroups: AccountingLocationGroups | null = useMemo(() => {
    if (isLoading || !stockData) return null;

    return {
      cashDrawer: stockData.cashDrawers?.map(convertToBranchCashOverview),
      counterTerminal: stockData.counters?.map(convertToBranchCashOverview),
      safe: stockData.safes?.map(convertToBranchCashOverview),
    };
  }, [stockData, isLoading]);

  return {
    accountingLocation,
    setAccountingLocation,
    isLoading,
    accountingLocationGroups,
  };
};
