export const TableResults = {
  headerResults: ["BP / TP Number", "Date", "Username", "Smart ID", "Discrepancy Value"],
  rowPopUpResults: {
    title: ["Detail View : ", "TP21"],
    subHead: ["Commodity", "Value"],
    body: [
      { "User Name": "SUMIT" },
      { "Smart ID": "6QUM" },
      // ! don't change Date and time instead setup your local time as UK time
      // ? if you change value it Might be chances test case fail in GITHUB again due to IST ad UK time variations.
      { "Date & Time": "07:16 am" },
      { "Commodity Value": "£890.00" },
      { Cash: "£120.00" },
      { Forex: "£280.00" },
      { Stock: "£230.00" },
      { Obsolete: "£260.00" },
    ],
    footer: ["Go to Trading Period", "Print", "Go to Balance Period"],
  },
  paginationResults: ["< Previous", "Next >"],
};

export const getTpBpHistoryMockData = {
  data: [
    {
      BP: "12",
      TP: "21",
      fadcode: "2314010",
      itemDescription: "TP",
      declarationID: "2812",
      totalVarianceValue: 89000,
      timestamp: 1672211791,
      TPstartTimestamp: 1672211791,
      TPendTimestamp: 1672211791,
      BPstartTimestamp: 1672211791,
      BPendTimestamp: 1672211791,
      cashCommodity: {
        varianceValue: 12000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      forexCommodity: {
        varianceValue: 28000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      stockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      obsoleteStockCommodity: {
        varianceValue: 26000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      updatedBy: {
        smartID: "6QUM",
        userName: "SUMIT",
        timestamp: 1672211791,
      },
    },
    {
      BP: "12",
      TP: "21",
      fadcode: "2314010",
      itemDescription: "TP",
      declarationID: "2812",
      totalVarianceValue: 89000,
      timestamp: 1672211791,
      TPstartTimestamp: 1672211791,
      TPendTimestamp: 1672211791,
      BPstartTimestamp: 1672211791,
      BPendTimestamp: 1672211791,
      cashCommodity: {
        varianceValue: 12000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      forexCommodity: {
        varianceValue: 28000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      stockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      obsoleteStockCommodity: {
        varianceValue: 26000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      updatedBy: {
        smartID: "6QUM",
        userName: "SUMIT",
        timestamp: 1672211791,
      },
    },
    {
      BP: "12",
      TP: "21",
      fadcode: "2314010",
      itemDescription: "TP",
      declarationID: "2812",
      totalVarianceValue: 89000,
      timestamp: 1672211791,
      TPstartTimestamp: 1672211791,
      TPendTimestamp: 1672211791,
      BPstartTimestamp: 1672211791,
      BPendTimestamp: 1672211791,
      cashCommodity: {
        varianceValue: 12000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      forexCommodity: {
        varianceValue: 28000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      stockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      obsoleteStockCommodity: {
        varianceValue: 26000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      updatedBy: {
        smartID: "6QUM",
        userName: "SUMIT",
        timestamp: 1672211791,
      },
    },
    {
      BP: "12",
      TP: "21",
      fadcode: "2314010",
      itemDescription: "TP",
      declarationID: "2812",
      totalVarianceValue: 89000,
      timestamp: 1672211791,
      TPstartTimestamp: 1672211791,
      TPendTimestamp: 1672211791,
      BPstartTimestamp: 1672211791,
      BPendTimestamp: 1672211791,
      cashCommodity: {
        varianceValue: 12000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      forexCommodity: {
        varianceValue: 28000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      stockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      obsoleteStockCommodity: {
        varianceValue: 26000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      updatedBy: {
        smartID: "6QUM",
        userName: "SUMIT",
        timestamp: 1672211791,
      },
    },
    {
      BP: "20",
      TP: "28",
      fadcode: "2314010",
      itemDescription: "BP",
      declarationID: "2812",
      totalVarianceValue: 1250000,
      timestamp: 1672211791,
      TPstartTimestamp: 1672211791,
      TPendTimestamp: 1672211791,
      BPstartTimestamp: 1672211791,
      BPendTimestamp: 1672211791,
      cashCommodity: {
        varianceValue: 12000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      forexCommodity: {
        varianceValue: 28000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      stockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      obsoleteStockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      updatedBy: {
        smartID: "6QUM",
        userName: "SUMIT",
        timestamp: 1672211791,
      },
    },
    {
      BP: "12",
      TP: "21",
      fadcode: "2314010",
      itemDescription: "TP",
      declarationID: "2812",
      totalVarianceValue: 89000,
      timestamp: 1672211791,
      TPstartTimestamp: 1672211791,
      TPendTimestamp: 1672211791,
      BPstartTimestamp: 1672211791,
      BPendTimestamp: 1672211791,
      cashCommodity: {
        varianceValue: 12000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      forexCommodity: {
        varianceValue: 28000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      stockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      obsoleteStockCommodity: {
        varianceValue: 26000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      updatedBy: {
        smartID: "6QUM",
        userName: "SUMIT",
        timestamp: 1672211791,
      },
    },
    {
      BP: "12",
      TP: "21",
      fadcode: "2314010",
      itemDescription: "TP",
      declarationID: "2812",
      totalVarianceValue: 89000,
      timestamp: 1672211791,
      TPstartTimestamp: 1672211791,
      TPendTimestamp: 1672211791,
      BPstartTimestamp: 1672211791,
      BPendTimestamp: 1672211791,
      cashCommodity: {
        varianceValue: 12000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      forexCommodity: {
        varianceValue: 28000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      stockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      obsoleteStockCommodity: {
        varianceValue: 26000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      updatedBy: {
        smartID: "6QUM",
        userName: "SUMIT",
        timestamp: 1672211791,
      },
    },
    {
      BP: "12",
      TP: "21",
      fadcode: "2314010",
      itemDescription: "TP",
      declarationID: "2812",
      totalVarianceValue: 89000,
      timestamp: 1672211791,
      TPstartTimestamp: 1672211791,
      TPendTimestamp: 1672211791,
      BPstartTimestamp: 1672211791,
      BPendTimestamp: 1672211791,
      cashCommodity: {
        varianceValue: 12000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      forexCommodity: {
        varianceValue: 28000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      stockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      obsoleteStockCommodity: {
        varianceValue: 26000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      updatedBy: {
        smartID: "6QUM",
        userName: "SUMIT",
        timestamp: 1672211791,
      },
    },
    {
      BP: "20",
      TP: "28",
      fadcode: "2314010",
      itemDescription: "BP",
      declarationID: "2812",
      totalVarianceValue: 1250000,
      timestamp: 1672211791,
      TPstartTimestamp: 1672211791,
      TPendTimestamp: 1672211791,
      BPstartTimestamp: 1672211791,
      BPendTimestamp: 1672211791,
      cashCommodity: {
        varianceValue: 12000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      forexCommodity: {
        varianceValue: 28000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      stockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      obsoleteStockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      updatedBy: {
        smartID: "6QUM",
        userName: "SUMIT",
        timestamp: 1672211791,
      },
    },
    {
      BP: "20",
      TP: "28",
      fadcode: "2314010",
      itemDescription: "BP",
      declarationID: "2812",
      totalVarianceValue: 1250000,
      timestamp: 1672211791,
      TPstartTimestamp: 1672211791,
      TPendTimestamp: 1672211791,
      BPstartTimestamp: 1672211791,
      BPendTimestamp: 1672211791,
      cashCommodity: {
        varianceValue: 12000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      forexCommodity: {
        varianceValue: 28000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      stockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      obsoleteStockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      updatedBy: {
        smartID: "6QUM",
        userName: "SUMIT",
        timestamp: 1672211791,
      },
    },
    {
      BP: "20",
      TP: "28",
      fadcode: "2314010",
      itemDescription: "BP",
      declarationID: "2812",
      totalVarianceValue: 1250000,
      timestamp: 1672211791,
      TPstartTimestamp: 1672211791,
      TPendTimestamp: 1672211791,
      BPstartTimestamp: 1672211791,
      BPendTimestamp: 1672211791,
      cashCommodity: {
        varianceValue: 12000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      forexCommodity: {
        varianceValue: 28000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      stockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      obsoleteStockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      updatedBy: {
        smartID: "6QUM",
        userName: "SUMIT",
        timestamp: 1672211791,
      },
    },
    {
      BP: "20",
      TP: "28",
      fadcode: "2314010",
      itemDescription: "BP",
      declarationID: "2812",
      totalVarianceValue: 1250000,
      timestamp: 1672211791,
      TPstartTimestamp: 1672211791,
      TPendTimestamp: 1672211791,
      BPstartTimestamp: 1672211791,
      BPendTimestamp: 1672211791,
      cashCommodity: {
        varianceValue: 12000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      forexCommodity: {
        varianceValue: 28000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      stockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      obsoleteStockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      updatedBy: {
        smartID: "6QUM",
        userName: "SUMIT",
        timestamp: 1672211791,
      },
    },
    {
      BP: "20",
      TP: "28",
      fadcode: "2314010",
      itemDescription: "BP",
      declarationID: "2812",
      totalVarianceValue: 1250000,
      timestamp: 1672211791,
      TPstartTimestamp: 1672211791,
      TPendTimestamp: 1672211791,
      BPstartTimestamp: 1672211791,
      BPendTimestamp: 1672211791,
      cashCommodity: {
        varianceValue: 12000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      forexCommodity: {
        varianceValue: 28000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      stockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      obsoleteStockCommodity: {
        varianceValue: 23000,
        accountingLocations: {
          28: {
            varianceValue: 1200,
            balancingDeclID: "string",
            accountingLocationName: "Cash Drawer - 28",
          },
        },
      },
      updatedBy: {
        smartID: "6QUM",
        userName: "SUMIT",
        timestamp: 1672211791,
      },
    },
  ],
  lastEvaluatedKey: "string",
};
