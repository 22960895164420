//ADD,REMOVE,REPRINT, and all other flows will be handled here..
import { useAppSelector } from "@bbo/lib";
import {
  amountPound,
  amountSignedPound,
  amountUnSignedPound,
  currencyStyle,
} from "@bbo/utils/currencyFormatter";
/**
 * Alert/Popup view with
 * @param props
 * @returns
 */

type SelectedRow = {
  discrepancyRecordType?: string;
  reasonCodeDescription?: string;
  discrepancyValue?: number;
};
interface iProps {
  selectedRow?: Array<SelectedRow>;
  totalAmount?: number;
  onSuccess?: () => void;
  onCancel?: () => void;
}

const HighLevelPopUp = ({ selectedRow, totalAmount, ...props }: iProps) => {
  const branchDetails = useAppSelector((root) => root.branchDetails);
  const onSuccess = () => {
    if (props.onSuccess) {
      props.onSuccess();
    }
  };
  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative my-6 mx-auto max-w-xl popupModelHighLevel">
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-8	">
            <div className="flex pl-4  items-center justify-start">
              <div>
                {branchDetails.branch_type === "DMB" && (
                  <h2 className="leading-relaxed text-color pr-2 amountHighLevel">Settle to P&L</h2>
                )}
                {branchDetails.branch_type === "SP" && (
                  <h2 className="leading-relaxed text-color pr-2 amountHighLevel">
                    Settle to assigned nominee
                  </h2>
                )}
              </div>
            </div>
            {selectedRow && selectedRow.length > 1 && (
              <div className="listStyle pl-4 mt-6 mb-6">
                {selectedRow.map((item, index) => (
                  <div key={index}>
                    {item.discrepancyRecordType} - {item.reasonCodeDescription}{" "}
                    {amountPound(item.discrepancyValue)}
                  </div>
                ))}
              </div>
            )}
            <div className="pl-4 amountHighLevel">
              {selectedRow && selectedRow.length > 1 && <div>Total to settle</div>}
              <span>
                <b>Amount = </b>
              </span>
              <span className={currencyStyle(totalAmount)}>
                <b>&nbsp;{amountPound(totalAmount)}</b>
              </span>
            </div>
            {/*footer*/}
            <div className="flex items-center pt-8 justify-end">
              <button
                className="restNoBtnMcdHighLevel border-t border-r border-l border-b border-grey border-solid bg-transparent-500 text-black active:bg-transparent-600 font-bold text-sm px-10 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-6 ease-linear transition-all duration-150 hover"
                type="button"
                value="Cancel"
                onClick={onCancel}
                data-cy="Cancel">
                Cancel
              </button>
              <button
                className="brandRedBtnMcdHighLevel text-white active:brandRedBtnMcdHighLevel font-bold text-sm px-6 py-2 rounded shadow hover:shadow-lg outline-none focus:outline-none ease-linear transition-all duration-150"
                type="button"
                value="Confirm"
                onClick={onSuccess}
                data-cy="Confirm">
                Accept
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};

export default HighLevelPopUp;
