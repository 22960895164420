import createStyles from "@material-ui/core/styles/createStyles";
import makeStyles from "@material-ui/core/styles/makeStyles";

export const useStyles = makeStyles(() =>
  createStyles({
    heading: {
      "font-family": "Nunito Sans !important",
      "font-style": "normal",
      "font-weight": "700",
      "font-size": "21px",
      "line-height": "30px",
      display: "flex",
      "align-items": "center",
      color: "#000000",
      margin: "32px 0px",
    },
    tableHeading: {
      display: "flex",
      "justify-content": "space-between",
    },
  }),
);
