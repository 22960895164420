import { amountPound, currencyStyle } from "@bbo/utils/currencyFormatter";
import { getTimeForTpBpHistory } from "@bbo/utils/dateTimeFormatter";

const TpBpDetailView = ({ data }) => {
  const {
    updatedBy: { userName, smartID, timestamp },
    cashCommodity: { varianceValue: cashValue = 0 },
    // TODO Uncomment below code if API have implemented those features
    /* forexCommodity: { varianceValue: forexValue = 0 },
    stockCommodity: { varianceValue: stockValue = 0 },
    obsoleteStockCommodity: { varianceValue: obsoleteValue = 0 }, */
    totalVarianceValue = 0,
  } = data;
  // ToDO Delete below data if below features are implemented in API
  const { forexValue, stockValue, obsoleteValue } = {
    forexValue: 0,
    stockValue: 0,
    obsoleteValue: 0,
  };
  return (
    <>
      <div className="flex items-center justify-center rounded-b">
        <table className="w-full BalanceTable detailViewTable">
          <tbody className="bg-white divide-y divide-gray-200">
            <tr>
              <td colSpan={2}>
                <b>User Name</b>
              </td>
              <td colSpan={3}>{userName}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Smart ID</b>
              </td>
              <td colSpan={3}>{smartID}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Date & Time</b>
              </td>
              <td colSpan={3}>{getTimeForTpBpHistory(timestamp)}</td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Commodity Value</b>
              </td>
              <td colSpan={3}>{amountPound(totalVarianceValue)}</td>
            </tr>
            <tr className="subHead">
              <td>
                <b>Commodity</b>
              </td>
              <td></td>
              <td></td>
              <td className="text-right">
                <b>Value</b>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Cash</b>
              </td>
              <td className="text-right" colSpan={3}>
                <div className={currencyStyle(cashValue)}>{amountPound(cashValue)}</div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Forex</b>
              </td>
              <td className="text-right" colSpan={3}>
                <div className={currencyStyle(forexValue)}>{amountPound(forexValue)}</div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Stock</b>
              </td>
              <td className="text-right" colSpan={3}>
                <div className={currencyStyle(stockValue)}>{amountPound(stockValue)}</div>
              </td>
            </tr>
            <tr>
              <td colSpan={2}>
                <b>Obsolete</b>
              </td>
              <td className="text-right" colSpan={3}>
                <div className={currencyStyle(obsoleteValue)}>{amountPound(obsoleteValue)}</div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TpBpDetailView;
