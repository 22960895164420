import jwt_decode from "jwt-decode";

export const expiredTokenCheck = (error: any): boolean => {
  if (!error) return false;
  const tokenExpiration = (
    jwt_decode(JSON.parse(JSON.stringify(error)).config?.headers?.Authorization) as any
  )?.exp;
  if (!tokenExpiration) return false;
  // This 1000 is to match the timestamp to exact place 10 digit (milliseconds to seconds)
  const expiredToken = tokenExpiration < Date.now() / 1000;
  return expiredToken && tokenExpiration;
};
