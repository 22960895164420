import { BranchCashOverview, useGetForexBranchOverview } from "@bbo/api/generator";
import { useAppSelector } from "@bbo/lib";
import { useMemo, useState } from "react";
import { AccountingLocationGroups } from "../BranchOverview/useBranchOverview";
import { convertToBranchCashOverview } from "../BranchOverview/branchOverviewHelpers";

export const useForexBranchOverview = () => {
  const { fadCode } = useAppSelector((rootState) => rootState.auth.authUserData);
  const { data: forexData, isLoading } = useGetForexBranchOverview(fadCode);
  const [accountingLocation, setAccountingLocation] = useState<BranchCashOverview | null>(null);

  const accountingLocationGroups: AccountingLocationGroups | null = useMemo(() => {
    if (isLoading || !forexData) return null;

    return {
      cashDrawer: forexData.cashDrawer?.map(convertToBranchCashOverview),
      counterTerminal: forexData.counterTerminal?.map(convertToBranchCashOverview),
      safe: [convertToBranchCashOverview(forexData.safe)],
    };
  }, [forexData, isLoading]);

  return {
    accountingLocation,
    setAccountingLocation,
    isLoading,
    accountingLocationGroups,
  };
};
