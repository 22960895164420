import link from "@bbo/assets/images/link.svg";
import userCheck from "@bbo/assets/images/user-check.svg";
import { Dispatch, SetStateAction, useContext, useState } from "react";
import { StockCardData } from "../StockBranchOverview/StockCardData/StockCardData";
import { ActionList } from "./ActionList";
import { useTableList } from "./useTableList";
import { BranchCashOverview } from "@bbo/api/generator";
import { CashManagementBranchOverviewContext } from "./BranchOverviewProvider";

type Props = {
  data: BranchCashOverview;
  index: number;
  isStockData?: boolean;
};

const CounterItem = ({ data, index, isStockData }: Props) => {
  const { orderedTable } = useTableList(data);
  const { setAccountingLocation } = useContext(CashManagementBranchOverviewContext);

  return (
    <div
      className="main widthAccounting mr-8 inline-block"
      key={index}
      onClick={() => setAccountingLocation(data)}>
      <div className="heading">
        <span>{data?.accountingLocationName}</span>
        <img src={link} className="mx-2" />
        <span>{data?.counterName}</span>
      </div>
      <hr className="border-gray-200 border-bottom-none" />
      <div className="second-heading h-10 truncate">
        {/* Needs sending by the API */}
        <img src={userCheck} className="mr-2" /> <span>{""}</span>
      </div>
      <hr className="border-gray-200 border-bottom-none" />
      <div className="third-heading">
        {isStockData ? (
          <StockCardData
            currentVariance={data.currentVariance}
            lastBalancedDateTime={data.lastDeclarationTimestamp}
            toDateVariance={data.toDateVariance}
          />
        ) : (
          <table className="w-full">
            <tbody className="counterPad">{orderedTable}</tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default CounterItem;
