import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export type TaskSwitchProps = "Cash Balance" | "Transfer Out" | "Transfer In";
/**
 * @remarks - possibly incorrect data structure, needs looking at when other components are refactored
 */
export type SelectedTask = {
  taskDescription?: string;
  itemDescription?: string;
  task?: string | never;
  level?: string;
  trigger?: string;
  runDateTime?: number;
  completionTimestamp?: number;
  fadcode?: string;
  status?: string;
  accountingLocationID?: string;
  accountingLocationType?: string;
  taskID?: string;
  taskType?: string;
  warningMsg?: boolean;
  accountingLocationName?: string;
  associatedEntityName?: string;
  entityName?: string;
  entityID?: string;
  role?: string;
  updatedBy?: {
    smartID?: string;
    userName?: string;
    timestamp?: number;
  };
};

const initialState: SelectedTask = {
  taskDescription: "",
  itemDescription: "",
  task: "",
  level: "",
  trigger: "",
  runDateTime: 0,
  completionTimestamp: 0,
  fadcode: "",
  status: "",
  accountingLocationID: "",
  accountingLocationType: "",
  taskID: "",
  taskType: "",
  warningMsg: false,
  accountingLocationName: "",
  role: "",
  updatedBy: {
    smartID: "",
    userName: "",
    timestamp: 0,
  },
};

export const selectedTask = createSlice({
  name: "selectedTask",
  initialState,
  reducers: {
    setSelectedTask: (state, action: PayloadAction<SelectedTask>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setSelectedTask } = selectedTask.actions;

export default selectedTask.reducer;
