import { PopupProps } from "@bbo/interfaces/BalanceCheck";
import "./index.css";

export default function Popup({ submitReset, resetFocus }: PopupProps) {
  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-xl">
          <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4	">
            <div className="flex p-4 flex-col items-center justify-center">
              <i className="fas fa-undo fa-2x mb-4"></i>
              <h2 className="text-blueGray-500 text-lg leading-relaxed heading-5 font-bold text-color">
                Do you really want to reset the values?
              </h2>
            </div>
            <div className="flex pl-4 items-center justify-center">
              <h2 className="text-blueGray-500 text-lg leading-relaxed heading-4 text-color">
                Cancel or Reset Values.
              </h2>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-center p-4  rounded-b">
              <button
                className="popUp-button white-button mt-8 flex-initial mr-4"
                type="button"
                value="No"
                onClick={submitReset}
                data-cy="popupNo">
                Cancel
              </button>
              <button
                className="popUp-button red-button mt-8 flex-initial ml-4"
                type="button"
                value="Yes"
                ref={resetFocus}
                onClick={submitReset}
                data-cy="popupYes">
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
    </>
  );
}
