import { AccountingLocationPopUp } from "./AccountingLocationPopUp";
import { ModalConfig } from "./manageCashDrawerTypes";
import { ReprintPopUp } from "./ReprintPopUp";
import { SuccessPopUp, FailedPopUp } from "./SuccessPopUp";

interface ManageCashDrawerModalProps {
  modalConfig: ModalConfig | null;
}

export const ManageCashDrawerModal = ({ modalConfig }: ManageCashDrawerModalProps) => {
  switch (modalConfig?.variant) {
    case "accountingLocation":
      return <AccountingLocationPopUp {...modalConfig} />;
    case "reprint":
      return <ReprintPopUp {...modalConfig} />;
    case "success":
      return <SuccessPopUp {...modalConfig} />;
    case "failed":
      return <FailedPopUp {...modalConfig} />;
    default:
      return null;
  }
};
