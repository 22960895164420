import CashDrawerList from "../BranchOverview/CashDrawerList";
import CounterList from "../BranchOverview/CounterList";
import { SafeList } from "../BranchOverview/SafeList";
import { AccountingLocationGroups } from "../BranchOverview/useBranchOverview";

interface StockAccountingOverviewProps {
  stockData: AccountingLocationGroups;
  isStockData?: boolean;
}

export const StockAccountingOverview = ({
  stockData,
  isStockData,
}: StockAccountingOverviewProps) => {
  return (
    <div data-testid="overview-testid">
      <CounterList items={stockData?.counterTerminal} isStockData={isStockData} />
      <hr className="border-gray-200 border-bottom-none mb-5 w-full" />
      <div className="w-full accounting-card block float-left h-56 overflow-x-auto whitespace-nowrap">
        <SafeList items={stockData?.safe} isStockData={isStockData} />
      </div>
      <hr className="border-gray-200 border-bottom-none mb-5 w-full" />
      <CashDrawerList items={stockData?.cashDrawer} isStockData={isStockData} />
    </div>
  );
};
