import { createContext } from "react";

export interface TableContextProps {
  onComplete: () => void;
  onCancel: () => void;
  // onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  // other props
}

export const TableContext = createContext<TableContextProps>(null);
