import { useEffect, useState } from "react";

import "@bbo/screens/Index.css";

import { useGetPouch } from "@bbo/api/generator";
import { CURRENCY_ITEM_ID } from "@bbo/constants/currencyItemID";
import { CoinItem } from "@bbo/types/PouchTypes";
import { amountInPound } from "@bbo/utils/currencyFormatter";
import { PouchLadder } from "../PouchLadder";
import { COINS_DETAIL, NOTES_DETAIL } from "../PouchLadder/PouchConstants";
import { CASH_POP_UP_DETAILS } from "./ladderConstant";

export const PreparedPouch = ({ pouchList }) => {
  const [pouchPopUp, setPouchPopUp] = useState<boolean>(false);
  const [notesdetail, setnotesdetail] = useState([...NOTES_DETAIL.map((item) => ({ ...item }))]);
  const [coindetail, setcoindetail] = useState([...COINS_DETAIL.map((item) => ({ ...item }))]);
  const [cashDetailPopUp, setcashDetailPopUp] = useState<CoinItem[]>(CASH_POP_UP_DETAILS);
  const [looseBag, setlooseBag] = useState<boolean>(false);
  const [looseCoin, setlooseCoin] = useState<boolean>(false);

  const [individualPouchId, setIndividualPouchId] = useState(null);

  const { data: individualPouchData } = useGetPouch(individualPouchId);

  //useEffect that triggers each time handlePouchInfo is called - sets cashDetailPopUp Denomination ladder
  useEffect(() => {
    if (!individualPouchData) return;

    const denominationLadderArr = Object.keys(individualPouchData.items);
    //THIS NEEDS TO BE REVIEWED AND OPTIMISED!
    const newCashDetails = denominationLadderArr.map((item, index) => {
      const current = individualPouchData.items[denominationLadderArr[index]];
      return {
        value: amountInPound(current.itemValue),
        Quantity: current.itemQuantity,
        alertCss: "normalCss",
        QalertCss: "normalCss",
        Cash: CURRENCY_ITEM_ID[current.itemID],
        cashType: "",
        currency: "",
        errorMsg: "",
        QerrorMsg: "",
        btnDisable: true,
        itemID: current?.itemID,
      };
    });

    setcashDetailPopUp(newCashDetails);
  }, [individualPouchData]);

  const handlePouchInfo = (element) => {
    setPouchPopUp(true);
    setIndividualPouchId(element.pouchID);
  };

  return (
    <>
      {/* <div className="flex justify-between width-97"><h2 className="heading-2 font-bold">Prepared Pouches</h2> <h2 className="heading">3 of {adhocbalance?.prepared_pouches?.length}</h2></div>  */}
      <h2 className="heading-2 font-bold pl-1">Prepared Pouches</h2>
      <div className="safeBalancePreparedHeight overflow-y-auto grid grid-cols-1 gap-4 mb-8">
        {pouchList?.preparedPouches?.map((element, index) => {
          return (
            <button
              key={index}
              className={`transfer-out-tiles heading font-bold  ${
                pouchList?.preparedPouches.length > 2 ? "w-full " : "width-97"
              }`}
              onClick={() => handlePouchInfo(element)}>
              <div className="flex justify-between w-full">
                <div>
                  <div className="float-left w-full text-left heading-3 font-bold">
                    Pouch Type : {element.pouchType}
                  </div>
                  <div className="float-left w-full text-left heading text-color font-normal">
                    Pouch ID : {element.pouchID}
                  </div>
                </div>
                <div className="flex items-center heading-3 font-bold">
                  £{amountInPound(element.totalValue)}
                </div>
              </div>
            </button>
          );
        })}
      </div>
      {pouchList?.suspendedPouches?.length > 0 && (
        <>
          {" "}
          <h2 className="heading-2 font-bold">Suspended Pouches</h2>
          {/* <div className="flex justify-between width-97"><h2 className="heading-2 font-bold">Suspended Pouches</h2> <h2 className="heading">2 of {adhocData?.suspended_pouches?.length}</h2></div>   */}
          <div className="safeBalanceSuspendedHeight overflow-y-auto grid grid-cols-1 gap-4">
            {pouchList?.suspendedPouches?.map((element, index) => {
              return (
                <button
                  key={index}
                  className={`transfer-out-tiles heading font-bold  ${
                    pouchList?.suspendedPouches.length > 1 ? "w-full " : "width-97"
                  }`}
                  onClick={() => handlePouchInfo(element)}>
                  <div className="flex justify-between w-full">
                    <div>
                      <div className="float-left w-full text-left heading-3 font-bold">
                        Pouch Type : {element.pouchType}
                      </div>
                      <div className="float-left w-full text-left heading text-color font-normal">
                        Pouch ID : {element.pouchID}
                      </div>
                    </div>
                    <div className="flex items-center heading-3 font-bold">
                      £{amountInPound(element.totalValue)}
                    </div>
                  </div>
                </button>
              );
            })}
          </div>
        </>
      )}
      {pouchPopUp && (
        <>
          <div className="justify-center items-center flex overflow-x-hidden fixed inset-0 z-50 outline-none focus:outline-none">
            <div className="relative w-auto my-2 mx-auto max-w-3xl">
              <div className="border-0 shadow-lg relative overflow-y-auto flex flex-col w-full bg-white outline-none focus:outline-none p-4	">
                <div className="flex flex-col p-4  rounded-b">
                  <PouchLadder
                    heading="Pouch Type - Pouch ID"
                    cashdetail={cashDetailPopUp}
                    looseBag={looseBag}
                    looseCoin={looseCoin}
                    showCancelButton={false}
                    showCompleteButton={false}
                    showSuspendButton={false}
                    disableFlags={true}
                  />
                  <div className="flex justify-start pt-2">
                    <button
                      className="brandWhiteBtn mr-2 lg:w-1/3 font-bold"
                      onClick={() => {
                        setPouchPopUp(false);
                        setnotesdetail([...NOTES_DETAIL.map((item) => ({ ...item }))]);
                        setcoindetail([...COINS_DETAIL.map((item) => ({ ...item }))]);
                      }}>
                      Close Preview
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
        </>
      )}
    </>
  );
};
