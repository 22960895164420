/**
 * Pouch preparation helper functions
 */

import { LadderFlags } from "../../types";

/**
 *
 * @param Cash
 * @returns
 */

export const convertPenceToPoundsAsNumber = (pence: number): number => {
  return pence / 100;
};

export const convertPenceToPoundsAsString = (pence: number): string => {
  return (pence / 100).toFixed(2);
};

export const cashBagLimit = (cash) => {
  let mValue;
  if (cash === "2£") {
    mValue = 250 * 2;
  } else if (cash === "1£") {
    mValue = 500 * 1;
  } else if (cash === "50p") {
    mValue = 500 * 0.5;
  } else if (cash === "20p") {
    mValue = 1250 * 0.2;
  } else if (cash === "10p") {
    mValue = 1000 * 0.1;
  } else if (cash === "5p") {
    mValue = 2000 * 0.05;
  } else if (cash === "2p") {
    mValue = 1000 * 0.02;
  } else if (cash === "1p") {
    mValue = 2000 * 0.01;
  }
  return mValue;
};
const addDecimal = (Value) => {
  return Value.toFixed(2);
};
const RemoveSign = (value) => {
  return value.replace("£", "");
};

export const handleCashNote = (item, name, Mvalue, inputBlock) => {
  let value = RemoveSign(Mvalue);
  const updateValue = { ...item };
  const unUseNote = "Unusable Notes";
  const unUseCoin = "Unusable Coins";
  if (inputBlock === "Q") {
    if (!value.includes(".") || !value.includes("e")) {
      updateValue.Quantity = value.replace(/[^0-9]/g, "");
      updateValue.QalertCss = "successCss";
      updateValue.QerrorMsg = "";

      if (item.currency === "£") {
        const mValue = item.Cash * value.replace(/[^0-9]/g, "");
        updateValue.btnDisable = false;
        if (value !== "") {
          updateValue.value = mValue.toFixed(2);
        } else {
          updateValue.value = "";
          updateValue.QalertCss = "normalCss";
        }
      } else {
        const mValue = (item.Cash * value.replace(/[^0-9]/g, "")) / 100;
        if (value !== "") {
          updateValue.value = mValue.toFixed(2);
        } else {
          updateValue.value = "";
          updateValue.QalertCss = "normalCss";
        }
        updateValue.btnDisable = false;
      }
    } else {
      updateValue.Quantity = value.replace(/[^0-9]/g, "");
      updateValue.QalertCss = "successCss";
      updateValue.QerrorMsg = "";

      if (item.currency === "£") {
        const mValue = item.Cash * value * 10;
        updateValue.btnDisable = false;
        if (value !== "") {
          updateValue.value = mValue.toFixed(2);
        } else {
          updateValue.value = "";
          updateValue.QalertCss = "normalCss";
        }
      } else {
        const mValue = (item.Cash * value) / 10;
        if (value !== "") {
          updateValue.value = mValue.toFixed(2);
        } else {
          updateValue.value = "";
          updateValue.QalertCss = "normalCss";
        }
        updateValue.btnDisable = false;
      }
    }
    if (value.length === 0) {
      updateValue.value = "";
      updateValue.btnDisable = true;
      updateValue.Quantity = "";
      updateValue.alertCss = "normalCss";
      updateValue.QerrorMsg = "";
    }
  } else {
    value = value.replace(".", "");
    const rx_live = /^[0-9\b]+(\.\d{0,2})?$/;
    if (rx_live.test(value)) {
      updateValue.value = String(value);
      value = value / 100;
      if (item.currency === "£") {
        if (value % item.Cash === 0) {
          updateValue.alertCss = "successCss";
          updateValue.btnDisable = false;
          const mValue = value / item.Cash;
          if (value !== "") {
            updateValue.Quantity = mValue;
          } else {
            updateValue.Quantity = "";
            updateValue.alertCss = "normalCss";
          }
          updateValue.errorMsg = "";
        } else if (item.Cash === unUseNote || item.Cash === unUseCoin) {
          updateValue.Quantity = "";
          updateValue.btnDisable = false;
          updateValue.alertCss = "successCss";
        } else {
          updateValue.Quantity = "";
          updateValue.alertCss = "alertCss";
          updateValue.btnDisable = true;
          updateValue.errorMsg = "Entered value is not correct, please check again.";
        }
      } else {
        if (Math.round(value * 100) % item.Cash === 0) {
          updateValue.alertCss = "successCss";
          updateValue.btnDisable = false;
          const mValue = Math.round(value * 100) / item.Cash;
          if (value !== "") {
            updateValue.Quantity = mValue;
          } else {
            updateValue.Quantity = "";
            updateValue.alertCss = "normalCss";
          }
          updateValue.errorMsg = "";
        } else if (item.Cash === unUseNote || item.Cash === unUseCoin) {
          updateValue.Quantity = "";
          updateValue.btnDisable = false;
          updateValue.alertCss = "successCss";
        } else {
          updateValue.alertCss = "alertCss";
          updateValue.btnDisable = true;
          updateValue.Quantity = "";
          updateValue.errorMsg = "Entered value is not correct, please check again.";
        }
      }
    } else {
      if (value.length === 0) {
        updateValue.value = "";
        updateValue.btnDisable = true;
        updateValue.Quantity = "";
        updateValue.alertCss = "normalCss";
        updateValue.errorMsg = "";
      }
      if (value.includes("-")) {
        updateValue.value = value.replace(/[^0-9]/g, "");

        const nvalue = updateValue.value;
        if (item.currency === "£") {
          if (nvalue % item.Cash === 0) {
            updateValue.alertCss = "successCss";
            updateValue.btnDisable = false;
            const value = nvalue / item.Cash;
            if (nvalue !== "") {
              updateValue.Quantity = value;
            } else {
              updateValue.Quantity = "";
              updateValue.alertCss = "normalCss";
            }
            updateValue.errorMsg = "";
          } else {
            updateValue.Quantity = "";
            updateValue.alertCss = "alertCss";
            updateValue.btnDisable = true;
            updateValue.errorMsg = "Entered value is not correct, please check again.";
          }
        } else {
          if (Math.round(nvalue * 100) % item.Cash === 0) {
            updateValue.alertCss = "successCss";
            updateValue.btnDisable = false;
            const value = Math.round(nvalue * 100) / item.Cash;
            if (nvalue !== "") {
              updateValue.Quantity = value;
            } else {
              updateValue.Quantity = "";
              updateValue.alertCss = "normalCss";
            }
            updateValue.errorMsg = "";
          } else {
            updateValue.alertCss = "alertCss";
            updateValue.btnDisable = true;
            updateValue.Quantity = "";
            updateValue.errorMsg = "Entered value is not correct, please check again.";
          }
        }
      }
    }
  }
  return updateValue;
};

export const handleCashNote1 = (item, name, Mvalue, inputBlock) => {
  let value = RemoveSign(Mvalue);
  const updateValue = { ...item };
  if (inputBlock === "Q") {
    if (!value.includes(".") || !value.includes("e")) {
      updateValue[name] = value.replace(/[^0-9]/g, "");
      updateValue.QalertCss = "successCss";
      updateValue.QerrorMsg = "";

      let looseCoinValue = updateValue.looseCoin ? updateValue.looseCoin * updateValue.Cash : 0;
      looseCoinValue = updateValue.currency === "p" ? looseCoinValue / 100 : looseCoinValue;
      let quantityValue = updateValue.Quantity ? updateValue.Quantity : 0;
      quantityValue =
        cashBagLimit(updateValue.Cash.toString().concat(updateValue.currency)) * quantityValue;

      const mValue = quantityValue + looseCoinValue;
      updateValue.btnDisable = false;
      if (value !== "") {
        updateValue.value = mValue.toFixed(2);
      } else {
        updateValue.value = "";
        updateValue.QalertCss = "normalCss";
      }
    } else {
      updateValue.Quantity = value.replace(/[^0-9]/g, "");
      updateValue.QalertCss = "successCss";
      updateValue.QerrorMsg = "";

      if (item.currency === "£") {
        const mValue = item.Cash * value * 10;
        updateValue.btnDisable = false;
        if (value !== "") {
          updateValue.value = mValue.toFixed(2);
        } else {
          updateValue.value = "";
          updateValue.QalertCss = "normalCss";
        }
      } else {
        const mValue = (item.Cash * value) / 10;
        if (value !== "") {
          updateValue.value = mValue.toFixed(2);
        } else {
          updateValue.value = "";
          updateValue.QalertCss = "normalCss";
        }
        updateValue.btnDisable = false;
      }
    }
    if (value.length === 0) {
      updateValue.value = "";
      updateValue.btnDisable = true;
      updateValue.Quantity = "";
      updateValue.alertCss = "normalCss";
      updateValue.QerrorMsg = "";
    }
  } else {
    value = value.replace(".", "");
    const rx_live = /^[0-9\b]+(\.\d{0,2})?$/;
    if (rx_live.test(value)) {
      updateValue.value = String(value);
      value = value / 100;
      if (item.currency === "£") {
        if (value % item.Cash === 0) {
          updateValue.alertCss = "successCss";
          updateValue.btnDisable = false;
          const bagValue = cashBagLimit(updateValue.Cash.toString().concat(updateValue.currency));
          const quantity = Math.floor(value / bagValue);
          const lcValue = value % bagValue;
          const lcCoins = lcValue / updateValue.Cash;
          if (value !== "") {
            updateValue.Quantity = quantity;
            updateValue.looseCoin = lcCoins;
          } else {
            updateValue.Quantity = "";
            updateValue.looseCoin = "";
            updateValue.alertCss = "normalCss";
          }
          updateValue.errorMsg = "";
        } else {
          updateValue.Quantity = "";
          updateValue.looseCoin = "";
          updateValue.alertCss = "alertCss";
          updateValue.btnDisable = true;
          updateValue.errorMsg = "Entered value is not correct, please check again.";
        }
      } else {
        if (Math.round(value * 100) % item.Cash === 0) {
          updateValue.alertCss = "successCss";
          updateValue.btnDisable = false;
          const bagValue = cashBagLimit(updateValue.Cash.toString().concat(updateValue.currency));
          const quantity = Math.floor(value / bagValue);
          const lcValue = (value % bagValue) * 100;
          const lcCoins = lcValue / updateValue.Cash;
          if (value !== "") {
            updateValue.Quantity = quantity;
            updateValue.looseCoin = lcCoins;
          } else {
            updateValue.Quantity = "";
            updateValue.looseCoin = "";
            updateValue.alertCss = "normalCss";
          }
          updateValue.errorMsg = "";
        } else {
          updateValue.alertCss = "alertCss";
          updateValue.btnDisable = true;
          updateValue.Quantity = "";
          updateValue.errorMsg = "Entered value is not correct, please check again.";
        }
      }
    } else {
      if (value.length === 0) {
        updateValue.value = "";
        updateValue.btnDisable = true;
        updateValue.Quantity = "";
        updateValue.alertCss = "normalCss";
        updateValue.errorMsg = "";
      }
      if (value.includes("-")) {
        updateValue.value = value.replace(/[^0-9]/g, "");

        const nvalue = updateValue.value;
        if (item.currency === "£") {
          if (nvalue % item.Cash === 0) {
            updateValue.alertCss = "successCss";
            updateValue.btnDisable = false;
            const value = nvalue / item.Cash;
            if (nvalue !== "") {
              updateValue.Quantity = value;
            } else {
              updateValue.Quantity = "";
              updateValue.alertCss = "normalCss";
            }
            updateValue.errorMsg = "";
          } else {
            updateValue.Quantity = "";
            updateValue.alertCss = "alertCss";
            updateValue.btnDisable = true;
            updateValue.errorMsg = "Entered value is not correct, please check again.";
          }
        } else {
          if (Math.round(nvalue * 100) % item.Cash === 0) {
            updateValue.alertCss = "successCss";
            updateValue.btnDisable = false;
            const value = Math.round(nvalue * 100) / item.Cash;
            if (nvalue !== "") {
              updateValue.Quantity = value;
            } else {
              updateValue.Quantity = "";
              updateValue.alertCss = "normalCss";
            }
            updateValue.errorMsg = "";
          } else {
            updateValue.alertCss = "alertCss";
            updateValue.btnDisable = true;
            updateValue.Quantity = "";
            updateValue.errorMsg = "Entered value is not correct, please check again.";
          }
        }
      }
    }
  }

  return updateValue;
};

export const handleCashCoin = (params, name, value, isLooseCoin = false) => {
  const mValue = value.replace(/[^0-9]/g, "");
  let amount = cashBagLimit(params.Cash.toString().concat(params.currency)) * mValue * 10;
  if (isLooseCoin && name === "Quantity") {
    amount =
      amount + cashBagLimit(params.Cash.toString().concat(params.currency)) * params.looseCoin * 10;
  } else if (isLooseCoin && name === "looseCoin") {
    amount =
      amount + cashBagLimit(params.Cash.toString().concat(params.currency)) * params.Quantity * 10;
  }
  return {
    ...params,
    [name]: mValue,
    value: amount || "",
    QalertCss: "successCss",
    QerrorMsg: "",
  };
};

export const getCoinValue = (params, name, value, isLooseCoin = false) => {
  const mValue = value.replace(/[^0-9]/g, "");
  let amount = cashBagLimit(params.Cash.toString().concat(params.currency)) * mValue * 10;
  if (isLooseCoin && name === "Quantity") {
    amount =
      amount + cashBagLimit(params.Cash.toString().concat(params.currency)) * params.looseCoin * 10;
  } else if (isLooseCoin && name === "looseCoin") {
    amount =
      amount + cashBagLimit(params.Cash.toString().concat(params.currency)) * params.Quantity * 10;
  }
  return {
    ...params,
    [name]: mValue,
    value: amount || "",
    QalertCss: "successCss",
    QerrorMsg: "",
  };
};

export const getValueOfNotes = (params, name, value) => {
  const rx_live = /^[0-9\b]+(\.\d{0,2})?$/;
  const updateValue = { ...params };
  const cash = params.Cash;

  if (rx_live.test(value)) {
    updateValue.value = value;
    if (params.currency === "£") {
      if (value % params.Cash === 0) {
        updateValue.alertCss = "successCss";
        updateValue.btnDisable = false;
        const mValue = value / cash;
        if (value !== "") {
          updateValue.Quantity = mValue;
        } else {
          updateValue.Quantity = "";
          updateValue.alertCss = "normalCss";
        }
        updateValue.errorMsg = "";
      } else {
        updateValue.Quantity = "";
        updateValue.alertCss = "alertCss";
        updateValue.btnDisable = true;
        updateValue.errorMsg = "Entered value is not correct, please check again.";
      }
    } else {
      if (Math.round(value * 100) % cash === 0) {
        updateValue.alertCss = "successCss";
        updateValue.btnDisable = false;
        const mValue = Math.round(value * 100) / cash;
        if (value !== "") {
          updateValue.Quantity = mValue;
        } else {
          updateValue.Quantity = "";
          updateValue.alertCss = "normalCss";
        }
        updateValue.errorMsg = "";
      } else {
        updateValue.alertCss = "alertCss";
        updateValue.btnDisable = true;
        updateValue.Quantity = "";
        updateValue.errorMsg = "Entered value is not correct, please check again.";
      }
    }
  } else {
    if (value.length === 0) {
      updateValue.value = "";
      updateValue.btnDisable = true;
      updateValue.Quantity = "";
      updateValue.alertCss = "normalCss";
      updateValue.errorMsg = "";
    }
    if (value.includes("-")) {
      updateValue.value = value.replace(/[^0-9]/g, "");

      const nvalue = updateValue.value;
      if (params.currency === "£") {
        if (nvalue % cash === 0) {
          updateValue.alertCss = "successCss";
          updateValue.btnDisable = false;
          const value = nvalue / cash;
          if (nvalue !== "") {
            updateValue.Quantity = value;
          } else {
            updateValue.Quantity = "";
            updateValue.alertCss = "normalCss";
          }
          updateValue.errorMsg = "";
        } else {
          updateValue.Quantity = "";
          updateValue.alertCss = "alertCss";
          updateValue.btnDisable = true;
          updateValue.errorMsg = "Entered value is not correct, please check again.";
        }
      } else {
        if (Math.round(nvalue * 100) % cash === 0) {
          updateValue.alertCss = "successCss";
          updateValue.btnDisable = false;
          const value = Math.round(nvalue * 100) / cash;
          if (nvalue !== "") {
            updateValue.Quantity = value;
          } else {
            updateValue.Quantity = "";
            updateValue.alertCss = "normalCss";
          }
          updateValue.errorMsg = "";
        } else {
          updateValue.alertCss = "alertCss";
          updateValue.btnDisable = true;
          updateValue.Quantity = "";
          updateValue.errorMsg = "Entered value is not correct, please check again.";
        }
      }
    }
  }
  return {
    ...updateValue,
    [name]: value,
  };
};

/**
 * Disable input in laddar
 * @param item
 * @param flags
 * @returns
 */
export const updateFlags = (item, flags: LadderFlags, looseCoin = false) => {
  const mFlags: LadderFlags = { inputLC: false, inputQ: false, inputV: false };
  let isAnyDisabled = false;
  for (const key in flags) {
    if (flags[key]) {
      isAnyDisabled = flags[key];
    }
  }

  if (!isAnyDisabled && (item.Quantity || (looseCoin && item.looseCoin))) {
    mFlags.inputV = true;
    return mFlags;
  } else if (!isAnyDisabled && item.value) {
    mFlags.inputQ = true;
    mFlags.inputLC = true;
    return mFlags;
  } else if (
    isAnyDisabled &&
    (item.Quantity == null || item.Quantity === "") &&
    (item.value !== null || item.value !== "")
  ) {
    if (looseCoin && (item.looseCoin !== null || item.looseCoin !== "")) {
      return null;
    }

    return flags;
  }

  return null;
};
