import React, { useContext } from "react";
import ColumnHeader from "../table/columnHeader";
import { DynamoTableColumn } from "./DynamoTableColumn";
import { Column } from "./dynamoTableTypes";
import { DynamoTableContext } from "./DynamoTableContext";

interface DynamoTableColumnsProps {
  columns: Column[];
}

export const DynamoTableColumns = ({ columns }: DynamoTableColumnsProps) => {
  const { showCheckboxes } = useContext(DynamoTableContext);
  return (
    <>
      {showCheckboxes ? <th></th> : null}
      {columns?.map((col, index) => (
        <DynamoTableColumn
          key={col.fieldName}
          column={col}
          // key={col.field}
          // column={col}
          // className={col.headingClass}
          // columnIndex={index}
          // checkedList={checkedRows}
          // onHeaderSelection={headerSelectionCallback}
          // onSortChanged={sortChangedCallback}
          // hideSortIcon={props.hideSortIcon}
        />
      ))}
    </>
  );
};
