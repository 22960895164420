import { StockBranchOverviewTab } from "@bbo/features/StockBranchOverview/StockBranchOverviewTab/StockBranchOverviewTab";
import { useEffect } from "react";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";

const BranchOverviewStockScreen = () => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Stock Management");
  }, [setTitle]);

  return (
    <>
      <div className="containerMain">
        <div className="w-full items-start transferOut-height overflow-x-auto">
          <div className="px-4 py-10 md:p-4 mx-auto w-full">
            <StockBranchOverviewTab />
          </div>
        </div>
      </div>
    </>
  );
};

export default BranchOverviewStockScreen;
