import { DiscrepancyDeclaration } from "@bbo/api/generator/model/discrepancyDeclaration";
import { TotalDiscrepancy } from "@bbo/api/generator/model/totalDiscrepancy";
import Loader from "@bbo/components/Loader";
import { ErrorPopup } from "@bbo/components/ErrorPopup";
import TableView from "@bbo/components/table";
import {
  BRANCH_DISCREPENCY_HISTORY_COLUMN,
  BRANCH_DISCREPENCY_SUMMARY_COLUMN,
} from "@bbo/components/table/tableConstants";
import { Column } from "@bbo/components/table/types";
import { DISCREPANCY_STATUS } from "@bbo/constants";
import { useAppSelector } from "@bbo/lib";
import { useGlobalState } from "@bbo/lib/state";
import { getStockUnitIdentifier } from "@bbo/utils/transferPayload";
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react";
import {
  BACK_OFFICE,
  COMMENT,
  DISCREPANCY_SETTLEMENT,
  IN_BRANCH_TYPE,
  PAID_IN,
  PAID_OUT,
  SETTLED,
  SETTLE_CASH,
  ERROR_MESSAGE,
} from "./constant";
import DisputePopUp from "./DisputePopUp";
import HeaderTabs from "./HeaderTabs";
import HighLevelPopUp from "./HighLevelPopUp";
import SettleMentPopUp from "./SettleMentPopUp";
import { useDiscrepancyAccount } from "./useDiscrepancyAccount";
import { useDiscrepancyPage } from "./useDiscrepancyPage";
import { useNavigate } from "react-router-dom";
// import Summary from "./Summary"
interface iProps {
  selectedTab: any;
  rowLength?: number;
  discrepancyTotal: TotalDiscrepancy;
}

export const DiscrepancyPage = ({ selectedTab, discrepancyTotal }: iProps) => {
  const navigate = useNavigate();
  const [, setDetailedViewModel] = useGlobalState("DetailedViewModel");
  const [showPopUP, setShowPopUp] = useState<boolean>(false);
  const [checkedRows, setCheckedRows] = useState([]);
  const [selectValue] = useGlobalState("selectValue");
  const [selectedRows, setSelectedRows] = useState([]);
  const [apiChange, setApiChange] = useGlobalState("apiChange");
  const [totalVariance, setTotalVariance] = useState<number>(0);
  const [selectedRowDetails, setSelectedRowDetails] = useState([]);
  const [disputePopUp, showDisputePopUp] = useState<boolean>(false);
  const [showDispute, setShowDispute] = useState<boolean>(false);
  const [showHelp, setShowHelp] = useState<boolean>(false);
  const [comment, setComment] = React.useState<string>("");
  const [, setShowSettleDispute] = useGlobalState("showSettleDispute");
  const [globalCurrentRows] = useGlobalState("currentRows");
  const [rows, setRows] = useState<DiscrepancyDeclaration[]>([]);
  const branchDetails = useAppSelector((root) => root.branchDetails);
  const [errorPopUp, setErrorPopUp] = useState<boolean>(false);
  const { username } = useAppSelector((root) => root.auth.authUserData);
  const [columnData, setcolumnData] = useState();
  const {
    isDiscrepancyStatus,
    DiscrepancyApi,
    saveCashDetailsApi,
    isLoaderStatus,
    updateDiscrepancyError,
  } = useDiscrepancyAccount();
  const {
    DiscrepancyData,
    handleSelectTab,
    isAwaitCentral,
    isAwaitBranch,
    subTabLabels,
    selectedSubTab,
    isHistory,
    isLoading,
    isRefetching,
    setIsHistory,
  } = useDiscrepancyPage();

  useEffect(() => {
    if (selectedTab === "History") {
      setIsHistory(false);
    } else {
      setIsHistory(true);
    }
  }, [selectedTab, setIsHistory]);

  useEffect(() => {
    setCheckedRows([]);
    if (DiscrepancyData || isRefetching) {
      const modifiedRow = DiscrepancyData.map((item) => {
        return {
          ...item,
          reasonType: item.reasonCode,
        };
      }).sort((a, b) => b?.timestamp - a?.timestamp);
      setRows(modifiedRow);
    }
  }, [DiscrepancyData, isRefetching]);
  // eslint-disable-next-line @typescript-eslint/no-explicit-any

  useEffect(() => {
    if (updateDiscrepancyError) {
      setErrorPopUp(true);
    }
  }, [updateDiscrepancyError]);
  const handleCellClicked = (params: {
    field: string;
    column: Column;
    data: unknown;
    rowIndex: number;
    columnIndex: number;
  }) => {
    setDetailedViewModel({ params: params.data, showModel: false });
    setShowSettleDispute({ showSettleDisputeButton: true });
  };

  const handleSelectAll = (params: { checked: boolean; data: any }) => {
    if (params.checked) {
      setCheckedRows(params.data?.map((v, index) => index));
    } else {
      setCheckedRows([]);
    }
  };

  const handleRowSelection = (params: { checked: boolean; data: any; rowIndex: number }) => {
    if (params.checked) {
      if (
        params.data?.reasonCode === DISCREPANCY_STATUS.TC &&
        params?.data.status === DISCREPANCY_STATUS.AWAITING_CODE
      ) {
        setShowHelp(false);
        setShowDispute(true);
      } else if (
        (params.data?.reasonCode === DISCREPANCY_STATUS.TP ||
          params.data?.reasonCode === DISCREPANCY_STATUS.BP) &&
        params?.data.status === DISCREPANCY_STATUS.AWAITING_CODE
      ) {
        setShowDispute(false);
        setShowHelp(true);
      } else if (
        params.data?.reasonCode !== DISCREPANCY_STATUS.TC &&
        params?.data.status === DISCREPANCY_STATUS.HELP_CODE
      ) {
        setShowHelp(false);
        setShowDispute(true);
      } else if (params?.data.status === DISCREPANCY_STATUS.DISPUTE_CODE) {
        setShowHelp(false);
        setShowDispute(false);
      }
      setCheckedRows((item) => [...item, params?.rowIndex]);
    } else {
      setCheckedRows((item) => {
        const position = item.indexOf(params.rowIndex);
        if (position !== -1) {
          return [...item.slice(0, position), ...item.slice(position + 1)];
        }
      });
    }
  };

  const saveCashRequestParams =
    selectValue?.data === "null"
      ? null
      : {
          transferAmount: totalVariance > 0 ? totalVariance : -totalVariance,
          fadcode: branchDetails?.fadcode,
          userName: username,
          reasonCode: DISCREPANCY_SETTLEMENT,
          comments: COMMENT,
          transferAt: BACK_OFFICE,
          operationType: totalVariance > 0 ? PAID_OUT : PAID_IN,
          stockunitIdentifier: getStockUnitIdentifier(
            selectValue?.data?.type,
            selectValue?.data?.label.slice(-1),
          ),
          ...(totalVariance < 0
            ? {
                destinationEntityType: selectValue?.data?.type,
                destinationentityID: selectValue?.data?.value,
              }
            : {
                sourceEntityType: selectValue?.data?.type,
                sourceentityID: selectValue?.data?.value,
              }),
        };

  const handleCashDetails = (selectedRowsData) => {
    saveCashDetailsApi(saveCashRequestParams, selectedRowsData);
  };
  const callbackHandleUpdate = () => {
    if (selectedRows.length === 1) {
      if (selectValue.ShowSelect === SETTLE_CASH) {
        handleCashDetails(selectedRows[0]);
      } else DiscrepancyApi(selectedRows[0]);
    } else {
      selectedRows.map((Data, index) => {
        if (selectValue.ShowSelect === SETTLE_CASH) {
          handleCashDetails(Data);
        } else DiscrepancyApi(Data);
      });
    }
    setShowPopUp(false);
    setCheckedRows([]);
  };
  useLayoutEffect(() => {
    if (isDiscrepancyStatus) {
      setDetailedViewModel({ showModel: true });
      setShowPopUp(false);
    }
  }, [isDiscrepancyStatus]);

  const handleSettle = (value: string) => {
    const selectedRow = [];
    const mValue = {};
    globalCurrentRows.map((v, index) => {
      return checkedRows.filter((indexValue) => {
        if (indexValue === index && v?.status !== SETTLED) {
          return selectedRow.push(v);
        }
      });
    });
    let status: string;
    switch (value) {
      case "help":
        showDisputePopUp(true);
        status = DISCREPANCY_STATUS.HELP_REQUESTED;
        break;
      case "settled":
        showDisputePopUp(false);
        status = DISCREPANCY_STATUS.SETTLED;
        break;
      case "dispute":
        showDisputePopUp(true);
        status = DISCREPANCY_STATUS.DISPUTE_RAISED;
        break;
      default:
        status = "";
        break;
    }
    const requestPayLoad = selectedRow.map((item) => {
      mValue["discrepencyDataID"] = item?.discrepancyID;
      mValue["status"] = status;
      mValue["comment"] = comment;
      mValue["TCReference"] = item?.TCReference;
      mValue["accountReferenceID"] = item?.accountReferenceID;
      mValue["clientReference"] = item?.clientReference;
      mValue["reason"] = item?.reasonCode;
      mValue["reasonCodeDescription"] = item?.reasonCodeDescription;
      mValue["discrepancyValue"] = item?.discrepancyValue;
      mValue["itemID"] = item?.itemID;
      mValue["branchRefenceNumber"] = item?.branchRefenceNumber;
      mValue["TCReferenceForTC"] = item?.clientReference;
      mValue["transferID"] = item?.updatedBy?.transactionID;
      return { ...mValue };
    });
    setSelectedRows(requestPayLoad);
    const TotalVariance = selectedRow.reduce(
      (total, value) => total + Number(value?.discrepancyValue),
      0,
    );
    setTotalVariance(TotalVariance);
    setShowPopUp(true);
    setSelectedRowDetails(selectedRow);
  };
  const handleCellClickedCallback = useCallback(handleCellClicked, [
    setDetailedViewModel,
    setShowSettleDispute,
  ]);
  const rowSelectionCallback = useCallback(handleRowSelection, []);
  const handleSelectionAllCallback = useCallback(handleSelectAll, []);
  const renderAddonBtn = checkedRows && checkedRows.length > 0 && (
    <div className="flex w-1/3 justify-end mr-20">
      {checkedRows.length === 1 && showHelp && (
        <button
          onClick={() => handleSettle("help")}
          className="brandWhiteBtn mr-2 font-bold RightalignBtn">
          Help
        </button>
      )}
      {checkedRows.length === 1 && showDispute && (
        <button
          onClick={() => handleSettle("dispute")}
          className="brandWhiteBtn mr-2 font-bold RightalignBtn">
          Dispute
        </button>
      )}
      <button
        className="accounting-button-discrepancy ml-2 RightalignBtn"
        onClick={() => handleSettle("settled")}>
        Settle
      </button>
    </div>
  );
  const handleComment = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    setComment(value);
  };
  return (
    <div className="w-full transferOut-height">
      {isRefetching || isLoaderStatus ? (
        <Loader />
      ) : (
        <>
          <div className="p-4 w-full h-full relative dynamictable hideDiv">
            <HeaderTabs
              tabLabels={subTabLabels}
              selected={selectedSubTab}
              onSelectTab={handleSelectTab}
              isSummary={true}
              isAwaitBranch={isAwaitBranch}
              isAwaitCentral={isAwaitCentral}
              discrepancyTotal={discrepancyTotal}
            />
            <TableView
              columns={
                isHistory ? BRANCH_DISCREPENCY_SUMMARY_COLUMN : BRANCH_DISCREPENCY_HISTORY_COLUMN
              }
              rows={rows}
              onRowSelection={rowSelectionCallback}
              onCellClicked={handleCellClickedCallback}
              onSelectAll={handleSelectionAllCallback}
              isDiscrpencyTable={true}
              renderAddonBtn={renderAddonBtn}
            />
            {showPopUP && branchDetails.branch_type !== IN_BRANCH_TYPE && !disputePopUp && (
              <HighLevelPopUp
                selectedRow={selectedRowDetails}
                totalAmount={totalVariance}
                onSuccess={() => {
                  callbackHandleUpdate();
                }}
                onCancel={() => {
                  setShowPopUp(false);
                }}
              />
            )}
            {showPopUP && branchDetails.branch_type === IN_BRANCH_TYPE && !disputePopUp && (
              <SettleMentPopUp
                selectedRow={selectedRowDetails}
                totalAmount={totalVariance}
                onSuccess={() => callbackHandleUpdate()}
                onCancel={() => setShowPopUp(false)}
              />
            )}
            {showPopUP && disputePopUp && (
              <DisputePopUp
                comment={comment}
                handleComment={handleComment}
                onSuccess={() => callbackHandleUpdate()}
                onCancel={() => {
                  setComment("");
                  setShowPopUp(false);
                }}
              />
            )}
            {errorPopUp && (
              <ErrorPopup
                popupType="error"
                messageHeading={ERROR_MESSAGE}
                labelSuccess="Close"
                onSuccess={() => setErrorPopUp(false)}
              />
            )}
          </div>
        </>
      )}
    </div>
  );
};
