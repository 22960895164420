/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import { useMutation, UseMutationOptions, MutationFunction } from "@tanstack/react-query";
import type { StockManagementApiResponse, SuspendCountRequest } from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * update suspendcount data
 * @summary This endpoint is used to store the suspended count data into dB
 */
export const usePutStockManagementStockCountSuspendHook = () => {
  const putStockManagementStockCountSuspend = useCustomInstance<StockManagementApiResponse>();

  return (suspendCountRequest: SuspendCountRequest) => {
    return putStockManagementStockCountSuspend({
      url: `/stock-management/stock/count/suspend`,
      method: "put",
      headers: { "Content-Type": "application/json" },
      data: suspendCountRequest,
    });
  };
};

export type PutStockManagementStockCountSuspendMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof usePutStockManagementStockCountSuspendHook>>
>;
export type PutStockManagementStockCountSuspendMutationBody = SuspendCountRequest;
export type PutStockManagementStockCountSuspendMutationError = ErrorType<void>;

export const usePutStockManagementStockCountSuspend = <
  TError = ErrorType<void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof usePutStockManagementStockCountSuspendHook>>,
    TError,
    { data: SuspendCountRequest },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const putStockManagementStockCountSuspend = usePutStockManagementStockCountSuspendHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof usePutStockManagementStockCountSuspendHook>>,
    { data: SuspendCountRequest }
  > = (props) => {
    const { data } = props || {};

    return putStockManagementStockCountSuspend(data);
  };

  return useMutation<
    AsyncReturnType<typeof putStockManagementStockCountSuspend>,
    TError,
    { data: SuspendCountRequest },
    TContext
  >(mutationFn, mutationOptions);
};
