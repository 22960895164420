import Loader from "@bbo/components/Loader";
import { CashDetailContext } from "@bbo/context";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { useGlobalState } from "@bbo/lib/state";
import AdhocCashBalancing from "@bbo/screens/AdhocCashBalancing";
import { amountInPound } from "@bbo/utils/currencyFormatter";
import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useAdhocCashBalancingState } from "./CashBalanceHistoryUtil";
import { useGetAccountingLocationByDeclarationID } from "./CashBalanceHooks/useGetAccountingLocationByDeclarationID";

export interface IParamsSet {
  branchId: string;
  from_date: string;
  to_date: string;
}
export interface IUserActivitiesParams {
  fadcode: string;
  entity: string;
  entityID: string;
  status: string;
}
interface IRequestParamTpBP {
  fadcode: string;
  entity: string;
  entityID: string;
  timestamp: number;
}
//this screen is not functioning properly due to api?
const TpBpHistoryCashBalancing = () => {
  const { setTitle } = useTitle();

  const [isReadonlyMode, setIsReadonlyMode] = useGlobalState("isReadonlyMode");
  const [param, setparam] = useState({
    fromDate: Math.floor((Date.now() - 42 * 24 * 60 * 60 * 1000) / 1000),
    toDate: Math.floor(Date.now() / 1000),
  });
  useEffect(() => {
    setTitle("Cash Drawer Cash Balancing");
    setIsReadonlyMode({ isReadonlyMode: true });
  }, [setTitle, setIsReadonlyMode]);

  //new api call
  const {
    data: cashDeclarationById,
    isLoading,
    cashItemsArr,
  } = useGetAccountingLocationByDeclarationID();

  useEffect(() => {
    setparam({
      fromDate: cashDeclarationById?.timestamp,
      toDate: Math.floor(Date.now() / 1000),
    });
  }, [cashDeclarationById]);

  const { cashDetail, setCashDetail, activitiesBetweenBalancesTransactions } =
    useAdhocCashBalancingState(param);

  useEffect(() => {
    if (!isReadonlyMode.isReadonlyMode || !cashItemsArr) return;

    //reuasable bit of code?
    setCashDetail((previousValue) => {
      const updateValue = [...previousValue];

      previousValue?.forEach((item, index) => {
        cashItemsArr?.forEach((list) => {
          if (list === item.itemID) {
            updateValue[index] = {
              ...updateValue[index],
              value: `£${amountInPound(
                Number(cashDeclarationById?.cashItems[item.itemID].itemValue),
              )}`,
              Quantity: cashDeclarationById?.cashItems[item.itemID].itemQuantity,
            };
          }
        });
      });

      return updateValue;
    });
  }, [cashItemsArr, cashDeclarationById?.cashItems, isReadonlyMode.isReadonlyMode, setCashDetail]);

  //Check if routed from a safe entitiy
  const { declarationID } = useParams();
  const displaySafePouchData = declarationID.toLowerCase().includes("safe");

  return (
    <CashDetailContext.Provider value={{ cashDetail, setCashDetail }}>
      {/* context provider */}
      {isLoading ? (
        <Loader />
      ) : (
        <AdhocCashBalancing
          activitiesBetweenBalancesTransactions={activitiesBetweenBalancesTransactions}
        />
      )}
    </CashDetailContext.Provider>
  );
};

export default TpBpHistoryCashBalancing;
