/**
 * Ladder row component
 */

import { useEffect, useState } from "react";
import { LadderFlags, LadderItemProps } from "../../types";
import { handleCashNote, handleCashNote1, updateFlags } from "./utility";

/**
 * Pouch Preparation screen functional component
 * @returns
 */
interface iProps {
  item?: LadderItemProps;
  index?: number;
  looseBag?: boolean;
  looseCoin?: boolean;
  disabledFlags?: LadderFlags;
  onChange?: (params, index, flags) => void;
  limitExceeds?: boolean;
}

const LaddarRow = (props: iProps) => {
  const [data, setData] = useState(props.item);
  const { index, looseCoin, looseBag, disabledFlags } = props;

  useEffect(() => {
    setData(props.item);
  }, [props.item]);

  const CashText = (cash, type) => {
    if (type === "£") {
      return (
        <span className="textCash text-lg font-normal textCashalign"> £{cash.toFixed(2)}</span>
      );
    } else {
      return <span className="textCash text-lg font-normal textCashalign"> {cash}</span>;
    }
  };
  const blockInvalidChar = (e) => ["e", "E", "+", "-", "."].includes(e.key) && e.preventDefault();
  const restrictAlphabets = (event) => {
    const key = window.event ? event.keyCode : event.which;

    if (
      event.keyCode === 8 ||
      event.keyCode === 46 ||
      event.keyCode === 37 ||
      event.keyCode === 39
    ) {
      return true;
    } else if (key < 48 || key > 57) {
      return event.preventDefault();
    } else return true;
  };
  const handleOnChange = (e) => {
    if (!props.limitExceeds) {
      const { name, value } = e.target;
      const mValue = value.replace(/[^0-9]/g, "");
      setData((params) => {
        const flags = updateFlags({ ...params, [name]: mValue }, disabledFlags, looseCoin);
        let data = null;
        const inputField = name !== "value" ? "Q" : "V";
        if (looseCoin || looseBag) {
          data = handleCashNote1(params, name, value, inputField);
          if (data.value && inputField === "Q") {
            data.value = `£${data.value.replace("£", "")}`;
          } else {
            data.value = `£${(Number(data.value.replace("£", "")) / 100).toFixed(2)}`;
          }
        } else {
          data = handleCashNote(params, name, value, inputField);
          if (data.value && inputField === "Q") {
            data.value = `£${data.value.replace("£", "")}`;
          } else {
            data.value = `£${(Number(data.value.replace("£", "")) / 100).toFixed(2)}`;
          }
        }
        // TODO: This is throwing a React error and needs moving out of the setData call
        props.onChange({ ...data }, index, flags);
        return data;
      });
    }
  };

  return (
    <tr>
      <td className="text-left w-1/5">
        {CashText(data?.Cash, data?.currency)} {data?.cashType}
      </td>
      <td className="width-10 text-left">X</td>
      <td className="text-left w-1/5">
        <input
          key={index}
          data-cy={data?.Cash + "Q"}
          min="0"
          pattern="^[0-9\b]+$"
          disabled={disabledFlags.inputQ}
          type="number"
          id="Quantity"
          name="Quantity"
          value={data?.Quantity || ""}
          onKeyDown={blockInvalidChar}
          autoComplete="off"
          className={
            "h-9 p-2 commonTableBorder w-full text-right border-4px heading-6 inputCash " +
            data?.Quantity
          }
          onChange={handleOnChange}
        />
      </td>
      {looseBag && looseCoin && (
        <td className="text-left w-1/5">
          <input
            data-cy={data?.Cash + "V"}
            min="0"
            type="number"
            id="looseCoin"
            name="looseCoin"
            value={data?.looseCoin || ""}
            disabled={disabledFlags.inputLC}
            autoComplete="off"
            className={
              "h-9 commonTableBorder p-2 w-full text-right border-4px heading-6 inputCash " +
              data?.alertCss
            }
            onChange={handleOnChange}
          />
        </td>
      )}
      <td className="width-10 text-center">=</td>
      <td className="text-left w-1/5">
        <input
          key={index}
          data-cy={data?.Cash + "V"}
          min="0"
          name="value"
          id="value"
          value={data?.value || ""}
          disabled={disabledFlags.inputV}
          onKeyDown={restrictAlphabets}
          autoComplete="off"
          className={
            "h-9 commonTableBorder heading-6 text-right border-4px inputCash " +
            (looseCoin ? " width-100 " : "") +
            data?.alertCss
          }
          onChange={handleOnChange}
        />
      </td>
    </tr>
  );
};

export default LaddarRow;
