//ADD,REMOVE,REPRINT, and all other flows will be handled here..

import { useGlobalState } from "@bbo/lib/state";
import "./index.css";

/**
 * Alert/Popup view with
 * @param props
 * @returns
 */

interface iProps {
  handleChange: (value: string) => void;
}

const RecordVarianceBtn = ({ handleChange }: iProps) => {
  const [disableButton] = useGlobalState("requiredDetailsdisableBtn");
  const [Show] = useGlobalState("requiredDetailsShow");

  const onCommit = () => {
    handleChange("commit");
  };

  const onCancel = () => {
    handleChange("cancel");
  };

  return (
    <div className="recordVarianceBtn ">
      <button
        type="submit"
        className="inline-flex hover text-black font-bold p-2 rounded resetBtn BtnSize"
        onClick={onCancel}>
        Cancel
      </button>
      <button
        type="submit"
        disabled={disableButton.requiredDetailsdisableBtn && Show.requiredDetailsShow}
        className={`inline-flex lg:ml-6 text-white  p-2 rounded brand_button BtnSize ${
          !disableButton.requiredDetailsdisableBtn && !Show.requiredDetailsShow
            ? " brandRedBtn"
            : " next-background pointer-events-none"
        }`}
        onClick={() => onCommit()}>
        Commit
      </button>
    </div>
  );
};

export default RecordVarianceBtn;
