import { ForexDenomination } from "@bbo/features/DenominationLadder/ForexDenomination";
import { ICurrency } from "@bbo/features/DenominationLadder/Icurrency";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
interface ILocationState {
  code: string;
  name: string;
  iSelected?: ICurrency;
}
const ForexDenominationLadder = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [currencyInfo, setCurrencyInfo] = useState<ILocationState>({
    code: "",
    name: "",
    iSelected: {},
  });
  useEffect(() => {
    if (location?.state !== null) {
      setCurrencyInfo({
        code: location?.state?.code,
        name: location?.state?.name,
        iSelected: { ...location.state.iSelected },
      });
    }
    () => {
      navigate("/currencyCountDenominationLadder", {
        replace: true,
      });
    };
  }, [location, navigate]);
  return (
    <ForexDenomination
      code={currencyInfo.code}
      name={currencyInfo.name}
      iSelected={currencyInfo.iSelected}
    />
  );
};

export default ForexDenominationLadder;
