import { useEffect, useState } from "react";
import { IDenomination } from "./ForexDenomination";

interface iProps {
  item?: {
    cash?: string;
    cashType?: string;
    currency?: string;
    quantity?: number;
    value?: number;
    btnDisable?: boolean;
    alertCss?: string;
    QalertCss?: string;
    errorMsg?: string;
    QerrorMsg?: string;
    changeKey?: string;
  };
  index?: number;
  looseBag?: boolean;
  looseCoin?: boolean;
  diabledFlags?: any;
  onChange?: (e: Event, row: IDenomination) => void;
  onBlur?: (row: IDenomination) => void;
  code?: string;
}

const ForexLaddarRow = (props: iProps) => {
  const [data, setData] = useState(props.item);
  const unUseNote = "Unusable Notes";
  const unUseCoin = "Unusable Coins";

  const { index, diabledFlags } = props;
  useEffect(() => {
    setData(props.item);
  }, [props.item]);

  const CashText = (cash, type) => {
    if (type !== "p" && cash !== unUseNote && cash !== unUseCoin && cash !== "Total") {
      return <span className="textCash text-lg font-normal textCashalign">{cash}</span>;
    } else if (type === "p" && cash !== unUseNote && cash !== unUseCoin) {
      return <span className="textCash text-lg font-normal textCashalign">{cash}p</span>;
    } else if (cash === unUseNote || cash === unUseCoin) {
      return <span className="textCash text-lg font-normal textCashalign">{cash}</span>;
    } else if (cash === "Total") {
      return <span className="textCash text-lg font-bold textCashalign">{cash}</span>;
    }
  };
  const handleValue = (event) => {
    if (
      event.which !== 9 &&
      event.which !== 8 &&
      event.which !== 0 &&
      (event.which < 48 || event.which > 57) &&
      event.which !== 190
    ) {
      event.preventDefault();
    }
    if (event.keyCode === 13) {
      const form = event.target.form;
      if (form) {
        const index = Array.prototype.indexOf.call(form, event.target);
        form.elements[index + focus].focus();
      }
      event.preventDefault();
    }
    if (event.which === 190) {
      event.preventDefault();
    }
  };

  const handleOnChange = (e) => {
    const { name, value } = e.target;
    if (
      name === data.changeKey ||
      data.changeKey === undefined ||
      (!Number(data.cash) && !Number(data.currency))
    ) {
      props?.onChange(e, { ...data, cash: props?.item?.cash, currency: props?.item?.currency });
    }
  };

  const handleOnBlur = (e) => {
    const { name, value } = e.target;
    if (props?.onBlur && value !== "0.00" && value !== "" && data?.errorMsg == null) {
      props?.onBlur({ cash: props?.item?.cash, currency: props?.item?.currency });
    }
  };
  return (
    <tr>
      <td className="text-left w-1/5">
        <label className="block text-sm font-medium text-gray-700">
          {CashText(data?.cash, data?.currency)}
          {data?.cash !== unUseNote && data?.cash !== unUseCoin && (
            <span className=" textCashType text-lg font-normal"> {data?.cashType}</span>
          )}
        </label>
      </td>
      <td className="width-10 text-left">
        {data?.cash !== "Total" && (
          <label className="block text-lg font-medium text-gray-700 text-center	">X</label>
        )}
      </td>
      <td className="text-left w-1/5">
        {data?.cash !== "Total" && (
          <input
            key={index}
            data-cy={data.cash + "Q"}
            min="0"
            pattern="[0-9]"
            type="number"
            id="Quantity"
            placeholder="Quantity"
            name="Quantity"
            value={data?.quantity || ""}
            autoComplete="off"
            disabled={!(data.changeKey === undefined || data.changeKey === "Quantity")}
            className={"px-3 py-3 w-full paidTextArea text-lg inputCash " + data?.QalertCss}
            onChange={handleOnChange}
            onKeyDown={handleValue}
            onBlur={handleOnBlur}
            data-testid="forex-ladder-row"
          />
        )}
        {data?.cash !== "Total" && (
          <span className="flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1">
            {data?.QerrorMsg}
          </span>
        )}
      </td>
      <td className="text-center">
        <label className="block text-lg font-medium text-gray-700">=</label>
      </td>
      <td className="text-left w-1/5">
        <input
          key={index}
          data-cy={data?.cash + "V"}
          min="0"
          name={data?.cash !== "Total" ? "value" : "tvalue"}
          id="value"
          placeholder="0"
          autoComplete="off"
          value={data?.value?.toFixed(2)}
          //value={data?.value || "0.00"}
          className={"px-3 py-3 w-full paidTextArea text-lg inputCash " + data?.alertCss}
          onChange={handleOnChange}
          onBlur={handleOnBlur}
          data-testid="forex-ladder-row"
          disabled={
            !(
              data.changeKey === undefined ||
              (data?.cash !== "Total" && data.changeKey === "value") ||
              (data?.cash === "Total" && data.changeKey === "tvalue")
            )
          }
          onKeyDown={handleValue}
        />
        {data.errorMsg && (
          <>
            <div className="col-1 grid mt-2">
              <span className="font-medium tracking-wide text-red-500 text-lg font-bold">
                {data?.errorMsg} <i className="fas fa-exclamation-circle text-red-500"></i>
              </span>
            </div>
          </>
        )}
      </td>
    </tr>
  );
};

export default ForexLaddarRow;
