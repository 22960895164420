/**
 * Ladder row component
 */

import { useState } from "react";
import { LadderFlags, ladderRowCombinedItem } from "../../types";
import { convertPenceToPoundsAsString } from "./utility";
/**
 * Pouch Preparation screen functional component
 * @returns
 */
interface iProps {
  item: ladderRowCombinedItem;
  index?: number;
  looseCoin?: boolean;
  disabledFlags?: LadderFlags;
  onChange?: (params) => void;
  limitExceeds?: boolean;
}

const LadderRowLooseCoins = (props: iProps) => {
  const [data, setData] = useState(props.item);
  const { fullBagID, partBagIndividualValue, symbol, combinedValueInPence } = data;

  const { index, looseCoin } = props;
  const blockInvalidChar = (e) => ["e", "E", "+", "-"].includes(e.key) && e.preventDefault();

  const handleOnChange = (e) => {
    if (!props.limitExceeds) {
      const { name, value } = e.target;

      const quantity = value.replace(/[^0-9]/g, "");
      const newData = { ...data, [name]: quantity };

      if (name === "fullBagQuantity") {
        const oldQuantity = data.fullBagQuantity;
        newData.combinedValueInPence += (quantity - oldQuantity) * Number(data.fullBagValueInPence);
      }

      if (name === "partBagQuantity") {
        const oldQuantity = data.partBagQuantity;
        newData.combinedValueInPence +=
          (quantity - oldQuantity) * Number(data.partBagIndividualValue);
      }

      setData(newData);
      props.onChange(newData);
    }
  };

  return (
    <tr>
      <td className="text-left w-1/5">
        {symbol === "£"
          ? `£${convertPenceToPoundsAsString(partBagIndividualValue)}`
          : `${partBagIndividualValue}p`}
      </td>
      <td className="width-10 text-left">X</td>
      <td className="text-left w-1/5">
        <input
          key={index}
          data-cy={data.partBagIndividualValue + "Q"}
          min="0"
          pattern="[0-9]"
          type="number"
          id={fullBagID}
          onKeyDown={blockInvalidChar}
          name="fullBagQuantity"
          value={data.fullBagQuantity || ""}
          autoComplete="off"
          className={
            "h-9 p-2 commonTableBorder w-full text-right border-4px heading-6 inputCash " +
            data.fullBagQuantity
          }
          onChange={handleOnChange}
        />
      </td>
      <td className="text-left w-1/5">
        <input
          key={index}
          data-cy={data.partBagIndividualValue + "LQ"}
          min="0"
          pattern="[0-9]"
          onKeyDown={blockInvalidChar}
          type="number"
          id={fullBagID}
          name="partBagQuantity"
          value={data.partBagQuantity || ""}
          autoComplete="off"
          className={
            "h-9 p-2 commonTableBorder w-full text-right border-4px heading-6 inputCash " +
            data.alertCss
          }
          onChange={handleOnChange}
        />
      </td>
      <td className="width-10 text-center">=</td>
      <td className="text-left w-1/5">
        {" "}
        <input
          key={index}
          data-cy={data.fullBagID + data.partBagID}
          min="0"
          name="totalValue"
          id="value"
          value={`£${convertPenceToPoundsAsString(combinedValueInPence)}`}
          disabled={true}
          autoComplete="off"
          className={
            "h-9 commonTableBorder heading-6 text-right border-4px inputCash " +
            (looseCoin ? " width-100 " : "") +
            data.alertCss
          }
          onChange={handleOnChange}
        />
      </td>
    </tr>
  );
};

export default LadderRowLooseCoins;
