import { useUpdateDiscrepancy } from "@bbo/api/generator";
import { usePostTransferTransaction } from "@bbo/api/generatorNoFadcode";
import { useAppDispatch, useAppSelector } from "@bbo/lib";
import { discrepancyMovementSettleToCash, updateDiscrepancyStatus } from "@bbo/types/TransferTypes";
import {
  paidInParams,
  paidOutParams,
  TRANSACTION_ITEM_ID,
  TRANSACTION_MODE,
  updateDiscrepancyParams,
} from "@bbo/utils/transferPayload";
import {
  CARD_FAILURE_ITEMID_TC,
  settleTCToCash,
  updateTCParams,
  updateTCParamsForIN,
  updateTCParamsForSP,
} from "../../utils/transferPayload";
import { BMK, CDF, CPA, CPD, DMB, IN, PAID_IN, SETTLED, SP } from "./constant";
import { useDiscrepancyPage } from "./useDiscrepancyPage";

export const useDiscrepancyAccount = () => {
  const { refetchGetDiscrepancyList, refetchTotal } = useDiscrepancyPage();
  const { username, fadCode, smartid } = useAppSelector((root) => root.auth.authUserData);
  const dispatch = useAppDispatch();
  const branchDetails = useAppSelector((root) => root.branchDetails);

  const settleToCashDiscrepancyRequestParams = (data): discrepancyMovementSettleToCash => {
    delete data?.clientReference;
    return {
      discrepencyUpdateDetails: [data],
      userID: smartid,
      userName: username,
      fadCode: fadCode,
      branchName: fadCode,
      branchDetails: branchDetails,
      action: "Update",
    };
  };

  const updateDiscrepancyRequestParams = (data): updateDiscrepancyStatus => {
    delete data?.clientReference;
    return {
      discrepencyUpdateDetails: [data],
      userID: smartid,
      userName: username,
      fadCode: fadCode,
      branchName: fadCode,
      action: "Update",
      branchDetails: branchDetails,
    };
  };

  const {
    mutate: PutDiscrepancyApi,
    data: PutDiscrepancyData,
    isSuccess: DiscrepancyStatus,
  } = useUpdateDiscrepancy({
    mutation: {
      onSuccess: () => {
        setTimeout(() => {
          refetchGetDiscrepancyList();
          refetchTotal();
        }, 3000);
      },
      onError: () => {
        setTimeout(() => {
          refetchGetDiscrepancyList();
          refetchTotal();
        }, 2000);
      },
    },
  });

  const putRequestDataForUpdateDiscrepancy = (data) => {
    return {
      status: data?.status.replace(" ", ""),
      comment: data.comments,
      smartID: smartid,
      userName: username,
    };
  };
  const {
    mutate: postTransferOut,
    isSuccess: isSuccesstransfer,
    isLoading: isLoadingtransfer,
    isError,
  } = usePostTransferTransaction({
    mutation: {
      onSuccess: () => {
        setTimeout(() => {
          refetchGetDiscrepancyList();
          refetchTotal();
        }, 3000);
      },
      onError: () => {
        setTimeout(() => {
          refetchGetDiscrepancyList();
          refetchTotal();
        }, 2000);
      },
    },
  });
  const {
    mutate: settleToCashDiscrepancy,
    isSuccess,
    isLoading,
  } = usePostTransferTransaction({
    mutation: {
      onSuccess: () => {
        setTimeout(() => {
          refetchGetDiscrepancyList();
          refetchTotal();
        }, 3000);
      },
      onError: () => {
        setTimeout(() => {
          refetchGetDiscrepancyList();
          refetchTotal();
        }, 2000);
      },
    },
  });
  const {
    mutate: updateDiscrepancyStatus,
    isSuccess: isSuccessDiscrepancy,
    isLoading: isLoadingDiscrepancy,
    data,
    status,
    isError: updateDiscrepancyError,
  } = usePostTransferTransaction({
    mutation: {
      onSuccess: () => {
        setTimeout(() => {
          refetchGetDiscrepancyList();
          refetchTotal();
        }, 3000);
      },
      onError: (error) => {
        setTimeout(() => {
          refetchGetDiscrepancyList();
          refetchTotal();
        }, 2000);
      },
    },
  });
  const isLoaderStatus = isLoadingDiscrepancy || isLoading || isLoadingtransfer;

  const isDiscrepancyStatus =
    isSuccess || isSuccessDiscrepancy || isSuccesstransfer || DiscrepancyStatus;

  const handleTCSettleToCash = (data) => {
    const transactionMode = TRANSACTION_MODE.TC_SETTLE_TO_CASH;
    let itemID = "";
    let itemIDIn = " ";
    itemID = TRANSACTION_ITEM_ID.CASH; // 1
    itemIDIn = data.itemID; //itemId

    if (data.status === SETTLED) {
      settleToCashDiscrepancy({
        data: settleTCToCash(
          settleToCashDiscrepancyRequestParams(data),
          transactionMode,
          itemID,
          itemIDIn,
        ),
      });
    } else {
      PutDiscrepancyApi({
        discrepancyID: encodeURIComponent(data.discrepencyDataID),
        data: putRequestDataForUpdateDiscrepancy(data),
      });
    }
  };

  const handleTCForSPBranch = (data) => {
    const transactionMode = TRANSACTION_MODE.TC_SETTLE_TO_NOMINEE;
    let itemID = "";
    let itemIDIn = " ";
    switch (data.itemID) {
      case TRANSACTION_ITEM_ID.TC_SETTLE:
        if (data?.TCReference?.length > 12) {
          itemID = TRANSACTION_ITEM_ID.TC_SETTLE; //9574  //TP-BP
          itemIDIn = TRANSACTION_ITEM_ID.TP_BP_BALANCING_SHORTAGE; //50833
        } else {
          itemID = TRANSACTION_ITEM_ID.TC_SETTLE; //9574  //pouch
          itemIDIn = TRANSACTION_ITEM_ID.POUCH_DESPATCH_SHORTAGE; //50818
        }
        break;
      case TRANSACTION_ITEM_ID.TC_SURPLUS_SETTLEMENT:
        if (data?.TCReference?.length > 12) {
          itemID = TRANSACTION_ITEM_ID.TC_SURPLUS_SETTLEMENT; //8570/ /TP-BP
          itemIDIn = TRANSACTION_ITEM_ID.TP_BP_BALANCING_SURPLUS; //50832
        } else {
          itemID = TRANSACTION_ITEM_ID.TC_SURPLUS_SETTLEMENT; //8570 //pouch
          itemIDIn = TRANSACTION_ITEM_ID.MULTIPLE_SETTLED; //50917
        }
        break;
      case TRANSACTION_ITEM_ID.TC_CASH_PA: //9243
        if (data?.discrepancyValue < 0) {
          //variance check needed
          itemID = TRANSACTION_ITEM_ID.POUCH_ACCEPTANCE_SURPLUS; //50811
          itemIDIn = TRANSACTION_ITEM_ID.TC_CASH_PA; //9243
        } else {
          itemID = TRANSACTION_ITEM_ID.POUCH_ACCEPTANCE_SHORTAGE; //50812
          itemIDIn = TRANSACTION_ITEM_ID.TC_CASH_PA; //9243
        }
        break;
      default: //data.itemID
        if (data?.discrepancyValue < 0) {
          itemID = TRANSACTION_ITEM_ID.BANKING_MISS_KEY_GAIN; //50823 BMK surplues
          itemIDIn = data.itemID;
        } else if (CARD_FAILURE_ITEMID_TC.includes(data.itemID)) {
          itemID = TRANSACTION_ITEM_ID.DEBIT_CREDIT_FAILURE_LOSS; //  50826 card failure
          itemIDIn = data.itemID;
        } else {
          itemID = TRANSACTION_ITEM_ID.BANKING_MISS_KEY_LOSS; // 50824 BMK shortage
          itemIDIn = data.itemID;
        }
    }
    if (data.status === SETTLED) {
      updateDiscrepancyStatus({
        data: updateTCParamsForSP(
          updateDiscrepancyRequestParams(data),
          transactionMode,
          itemID,
          itemIDIn,
        ),
      });
    } else {
      PutDiscrepancyApi({
        discrepancyID: encodeURIComponent(data.discrepencyDataID),
        data: putRequestDataForUpdateDiscrepancy(data),
      });
    }
  };
  const hanldeTCForDMB = (data) => {
    const transactionMode = TRANSACTION_MODE.TC_SETTLE_T0_PL; //31
    let itemID = "";
    let itemIDIn = " ";
    switch (data.itemID) {
      case TRANSACTION_ITEM_ID.TC_SETTLE: //9574
        itemID = TRANSACTION_ITEM_ID.SETTLE_TO_PL; //6292
        itemIDIn = TRANSACTION_ITEM_ID.TC_SETTLE; //9574
        break;
      case TRANSACTION_ITEM_ID.TC_SURPLUS_SETTLEMENT: //8570
        itemID = TRANSACTION_ITEM_ID.SETTLE_TO_PL; //6292
        itemIDIn = TRANSACTION_ITEM_ID.TC_SURPLUS_SETTLEMENT; //8570
        break;
      case TRANSACTION_ITEM_ID.TC_CASH_PA: //9243
        itemID = TRANSACTION_ITEM_ID.SETTLE_TO_PL; //6292
        itemIDIn = TRANSACTION_ITEM_ID.TC_CASH_PA; //50815
        break;
      default: //data.itemID
        itemID = TRANSACTION_ITEM_ID.SETTLE_TO_PL;
        itemIDIn = data.itemID;
    }
    if (data.status === SETTLED) {
      updateDiscrepancyStatus({
        data: updateTCParams(
          updateDiscrepancyRequestParams(data),
          transactionMode,
          itemID,
          itemIDIn,
        ),
      });
    } else {
      PutDiscrepancyApi({
        discrepancyID: encodeURIComponent(data.discrepencyDataID),
        data: putRequestDataForUpdateDiscrepancy(data),
      });
    }
  };

  const handleTCSetttleToAccount = (data) => {
    const transactionMode = TRANSACTION_MODE.TC_SETTLE_TO_CASH; //29
    let itemID = "";
    let itemIDIn = "";
    switch (data.itemID) {
      case TRANSACTION_ITEM_ID.TC_SETTLE:
        if (data?.TCReference?.length > 12) {
          itemID = TRANSACTION_ITEM_ID.TC_SETTLE; //9574  //TP-BP
          itemIDIn = TRANSACTION_ITEM_ID.TP_BP_BALANCING_SHORTAGE; //50832
        } else {
          itemID = TRANSACTION_ITEM_ID.TC_SETTLE; //9574  //pouch
          itemIDIn = TRANSACTION_ITEM_ID.POUCH_DESPATCH_SHORTAGE; //50818
        }
        break;
      case TRANSACTION_ITEM_ID.TC_SURPLUS_SETTLEMENT:
        if (data?.TCReference?.length > 12) {
          itemID = TRANSACTION_ITEM_ID.TC_SURPLUS_SETTLEMENT; //8570/ /TP-BP
          itemIDIn = TRANSACTION_ITEM_ID.POUCH_DESPATCH_SHORTAGE; //50833
        } else {
          itemID = TRANSACTION_ITEM_ID.TC_SURPLUS_SETTLEMENT; //8570 //pouch
          itemIDIn = TRANSACTION_ITEM_ID.MULTIPLE_SETTLED; //50817
        }
        break;
      case TRANSACTION_ITEM_ID.TC_CASH_PA: //9243
        if (data.discrepancyValue < 0) {
          //variance check needed
          itemID = TRANSACTION_ITEM_ID.POUCH_ACCEPTANCE_SURPLUS; //6292
          itemIDIn = TRANSACTION_ITEM_ID.TC_CASH_PA; //9243
        } else {
          itemID = TRANSACTION_ITEM_ID.POUCH_ACCEPTANCE_SHORTAGE; //6292
          itemIDIn = TRANSACTION_ITEM_ID.TC_CASH_PA; //9243
        }
        break;
      default: //data.itemID
        if (data.discrepancyValue < 0) {
          itemID = TRANSACTION_ITEM_ID.BANKING_MISS_KEY_GAIN; //BMK surplues
          itemIDIn = data.itemID;
        } else if (CARD_FAILURE_ITEMID_TC.includes(data.itemID)) {
          itemID = TRANSACTION_ITEM_ID.DEBIT_CREDIT_FAILURE_LOSS; //card failure
          itemIDIn = data.itemID;
        } else {
          itemID = TRANSACTION_ITEM_ID.BANKING_MISS_KEY_LOSS; //BMK shortage
          itemIDIn = data.itemID;
        }
    }
    if (data.status === SETTLED) {
      updateDiscrepancyStatus({
        data: updateTCParamsForIN(
          updateDiscrepancyRequestParams(data),
          transactionMode,
          itemID,
          itemIDIn,
        ),
      });
    } else {
      PutDiscrepancyApi({
        discrepancyID: encodeURIComponent(data.discrepencyDataID),
        data: putRequestDataForUpdateDiscrepancy(data),
      });
    }
  };
  const handleTCFlow = (data) => {
    switch (branchDetails.branch_type) {
      case SP:
        handleTCForSPBranch(data);
        break;
      case DMB:
        hanldeTCForDMB(data);
        break;
      case IN:
        handleTCSetttleToAccount(data);
        break;
    }
  };

  const handleUnknownvariance = (data) => {
    let itemID = "";
    let itemIDIn = "1";
    switch (branchDetails.branch_type) {
      case DMB:
        if (data.discrepancyValue > 0) {
          itemID = TRANSACTION_ITEM_ID.TP_BP_BALANCING_SURPLUS; //50832
          itemIDIn = TRANSACTION_ITEM_ID.SETTLE_TO_PL; //6292
        } else {
          itemID = TRANSACTION_ITEM_ID.TP_BP_BALANCING_SHORTAGE; //50833
          itemIDIn = TRANSACTION_ITEM_ID.SETTLE_TO_PL; //6292
        }
        break;
      case IN:
        itemID = TRANSACTION_ITEM_ID.MULTIPLE_SETTLED; // 50917
        itemIDIn = TRANSACTION_ITEM_ID.SETTLE_TO_ACCOUNT; //50918
        break;
      case SP:
        itemID = TRANSACTION_ITEM_ID.MULTIPLE_SETTLED; //50919
        itemIDIn = TRANSACTION_ITEM_ID.SETTLED_TO_NOMINEE; //50917
        break;
    }
    if (data.status === SETTLED) {
      updateDiscrepancyStatus({
        data: updateDiscrepancyParams(updateDiscrepancyRequestParams(data), itemID, itemIDIn),
      });
    } else {
      PutDiscrepancyApi({
        discrepancyID: encodeURIComponent(data.discrepencyDataID),
        data: putRequestDataForUpdateDiscrepancy(data),
      });
    }
  };

  const handleDMB = (data) => {
    let itemID = "";
    const itemIDIn = TRANSACTION_ITEM_ID.SETTLE_TO_PL;

    switch (data.reason) {
      case CPD:
        if (data.discrepancyValue < 0) {
          itemID = TRANSACTION_ITEM_ID.POUCH_DESPATCH_SHORTAGE; //50818
        } else {
          itemID = TRANSACTION_ITEM_ID.POUCH_DESPATCH_SURPLUS; //50817
        }
        break;
      case CPA:
        if (data.discrepancyValue < 0) {
          itemID = TRANSACTION_ITEM_ID.POUCH_ACCEPTANCE_SHORTAGE; //50812
        } else {
          itemID = TRANSACTION_ITEM_ID.POUCH_ACCEPTANCE_SURPLUS; //50811
        }
        break;
      case BMK:
        if (data.discrepancyValue < 0) {
          itemID = TRANSACTION_ITEM_ID.BANKING_MISS_KEY_LOSS; //50824
        } else {
          itemID = TRANSACTION_ITEM_ID.BANKING_MISS_KEY_GAIN; //50823
        }
        break;
      case CDF:
        itemID = TRANSACTION_ITEM_ID.DEBIT_CREDIT_FAILURE_LOSS; //50826
        break;
    }
    if (data.status === SETTLED) {
      updateDiscrepancyStatus({
        data: updateDiscrepancyParams(updateDiscrepancyRequestParams(data), itemID, itemIDIn),
      });
    } else {
      PutDiscrepancyApi({
        discrepancyID: encodeURIComponent(data.discrepencyDataID),
        data: putRequestDataForUpdateDiscrepancy(data),
      });
    }
  };

  const handleKnownVariance = (data) => {
    let itemID = "";
    let itemIDIn = "1";
    switch (branchDetails.branch_type) {
      case DMB:
        handleDMB(data);
        break;
      case SP:
        itemID = TRANSACTION_ITEM_ID.MULTIPLE_SETTLED; //50919
        itemIDIn = TRANSACTION_ITEM_ID.SETTLED_TO_NOMINEE; //50917
        break;
      case IN:
        itemID = TRANSACTION_ITEM_ID.MULTIPLE_SETTLED; // 50917
        itemIDIn = TRANSACTION_ITEM_ID.SETTLE_TO_ACCOUNT; //50918
        break;
    }
    if (branchDetails.branch_type !== DMB && data.status === SETTLED) {
      updateDiscrepancyStatus({
        data: updateDiscrepancyParams(updateDiscrepancyRequestParams(data), itemID, itemIDIn),
      });
    } else if (branchDetails.branch_type !== DMB) {
      PutDiscrepancyApi({
        discrepancyID: encodeURIComponent(data.discrepencyDataID),
        data: putRequestDataForUpdateDiscrepancy(data),
      });
    }
  };

  const handleKnownVarianceForIN = (data) => {
    let itemID = "";
    const itemIDIn = "1";
    switch (data.reason) {
      case CPD:
        if (data.discrepancyValue < 0) {
          itemID = TRANSACTION_ITEM_ID.POUCH_DESPATCH_SHORTAGE; //50818
        } else {
          itemID = TRANSACTION_ITEM_ID.POUCH_DESPATCH_SURPLUS; //50817
        }
        break;
      case CPA:
        if (data.discrepancyValue < 0) {
          itemID = TRANSACTION_ITEM_ID.POUCH_ACCEPTANCE_SHORTAGE; //50812
        } else {
          itemID = TRANSACTION_ITEM_ID.POUCH_ACCEPTANCE_SURPLUS; //50811
        }
        break;
      case BMK:
        if (data.discrepancyValue < 0) {
          itemID = TRANSACTION_ITEM_ID.BANKING_MISS_KEY_LOSS; //50842
        } else {
          itemID = TRANSACTION_ITEM_ID.BANKING_MISS_KEY_GAIN; //50832
        }
        break;
      case CDF:
        itemID = TRANSACTION_ITEM_ID.DEBIT_CREDIT_FAILURE_LOSS; //50826
        break;
    }
    if (data.status === SETTLED) {
      settleToCashDiscrepancy({
        data: updateDiscrepancyParams(updateDiscrepancyRequestParams(data), itemID, itemIDIn),
      });
    } else {
      PutDiscrepancyApi({
        discrepancyID: encodeURIComponent(data.discrepencyDataID),
        data: putRequestDataForUpdateDiscrepancy(data),
      });
    }
  };

  const handleUnknownvarianceIN = (data) => {
    let itemID = "";
    let itemIDIn = "";

    if (data.discrepancyValue > 0) {
      itemID = TRANSACTION_ITEM_ID.TP_BP_BALANCING_SURPLUS; //50832
      itemIDIn = "1";
    } else {
      itemID = TRANSACTION_ITEM_ID.TP_BP_BALANCING_SHORTAGE; //50833
      itemIDIn = "1";
    }
    if (data.status === SETTLED) {
      updateDiscrepancyStatus({
        data: updateDiscrepancyParams(updateDiscrepancyRequestParams(data), itemID, itemIDIn),
      });
    } else {
      PutDiscrepancyApi({
        discrepancyID: encodeURIComponent(data.discrepencyDataID),
        data: putRequestDataForUpdateDiscrepancy(data),
      });
    }
  };

  const saveCashDetailsApi = async (cashData, settlementData) => {
    if (["Transaction Correction"].includes(settlementData.reason)) {
      handleTCSettleToCash(settlementData);
    } else if (["TP", "BP"].includes(settlementData.reason)) {
      handleUnknownvarianceIN(settlementData);
    } else {
      handleKnownVarianceForIN(settlementData);
    }
    await isSuccess;
    cashData.operationType === PAID_IN
      ? postTransferOut({ data: paidInParams(cashData) })
      : postTransferOut({ data: paidOutParams(cashData) });
  };

  const DiscrepancyApi = (data) => {
    if (["Transaction Correction"].includes(data.reason)) {
      handleTCFlow(data);
    } else if (["TP", "BP"].includes(data.reason)) {
      handleUnknownvariance(data);
    } else {
      handleKnownVariance(data);
    }
  };

  return {
    isDiscrepancyStatus,
    DiscrepancyApi,
    saveCashDetailsApi,
    DiscrepancyStatus,
    isLoaderStatus,
    updateDiscrepancyError,
  };
};
