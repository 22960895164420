import { Grid } from "@material-ui/core";

interface ICurrencyData {
  ordercode: string;
  name: string;
  balance?: number;
}

interface IProps {
  count: number;
  items: ICurrencyData[];
  setCountfn: any;
}
export const LoadMore = ({ count, items, setCountfn }: IProps) => {
  if (count !== items?.length && count < items?.length) {
    return (
      <div
        // className="grid-box"
        onClick={() => {
          setCountfn(items.length);
        }}>
        <button className="grid-box"> More</button>
      </div>
    );
  }
  return null;
};
