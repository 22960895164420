import { StockItem, StockItems, SuspendCountStockItems } from "@bbo/api/generator";
import { getValueFromStorage, setValueInStorage } from "@bbo/utils/util";
import { CountedProduct } from "../StockCount/StockCount";

export const convertStockCountArrayToObject = <T extends { barcode?: string }>(
  countedProducts: T[] | null,
): Record<string, T> => {
  if (!countedProducts) {
    return null;
  }

  const countedProductsObj: Record<string, T> = {};
  countedProducts.forEach((item) => {
    countedProductsObj[item.barcode] = item;
  });

  return countedProductsObj;
};

export const convertStockCountObjectToArray = (
  countedProductsObj: Record<string, CountedProduct>,
) => Object.values(countedProductsObj);

export const convertCountedProductToStockItem = (countedProduct: CountedProduct): StockItems => ({
  name: countedProduct.name,
  itemID: countedProduct.itemID,
  barcode: countedProduct.barcode,
  description: countedProduct.description,
  quantity: Number(countedProduct.enteredQuantity),
  value: countedProduct.value,
  ean: countedProduct.ean,
  state: countedProduct.state,
  countGroup: countedProduct.countGroup,
});

export const covertSuspendedStockProducts = (
  countedProduct: CountedProduct,
): SuspendCountStockItems => ({
  name: countedProduct.name,
  itemID: countedProduct.itemID,
  barcode: countedProduct.barcode,
  quantity: Number(countedProduct.enteredQuantity),
  description: countedProduct.description,
  stockCountGroup: countedProduct.countGroup,
});

export const convertCountedProductsToStockItems = (
  countedProducts: CountedProduct[],
): StockItems[] => countedProducts.map((item) => convertCountedProductToStockItem(item));

export const persistStockCountCompleted = (completed: boolean) => {
  setValueInStorage("stockProductListCompleted", completed);
};

export const getStockCountCompleted = () => {
  return getValueFromStorage<boolean | null>("stockProductListCompleted");
};

export const getUpdatedCountedProducts = (
  updatedProduct: CountedProduct,
  countedProducts: CountedProduct[],
) => {
  const productIndex = countedProducts.findIndex((productCopy) => {
    return updatedProduct.itemID === productCopy.itemID;
  });
  const updatedCountedProducts = [...countedProducts];
  if (productIndex === -1) {
    return [...countedProducts, updatedProduct];
  }
  updatedCountedProducts[productIndex] = updatedProduct;
  return updatedCountedProducts;
};

export const calculateVariance = (item: CountedProduct) => {
  const varianceQuantity = Number(item?.enteredQuantity) - item?.quantity;
  const varianceAmount = varianceQuantity * item.value;

  return {
    varianceQuantity,
    varianceAmount,
  };
};
