import { getUUID } from "@bbo/utils/util";
import React, { useContext } from "react";
import { v4 as uuidv4 } from "uuid";
import { DynamoTableRow } from "./DynamoTableRow";
import { Column } from "./dynamoTableTypes";
import { DynamoTableContext } from "./DynamoTableContext";

interface DynamoTableRowsProps<T> {
  columns: Column[];
  data: T[];
  onRowClicked?: (selectedRow: T) => void;
}

export const DynamoTableRows = <T,>({ columns, data, onRowClicked }: DynamoTableRowsProps<T>) => {
  const { selectedIndexes } = useContext(DynamoTableContext);
  return (
    <>
      {data?.map((item, index) => (
        <DynamoTableRow
          key={uuidv4()}
          index={index}
          selected={selectedIndexes?.includes(index)}
          data={item}
          columns={columns}
          onRowClicked={onRowClicked}
        />
      ))}
    </>
  );
};
