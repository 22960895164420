import { useAppDispatch, useAppSelector } from "@bbo/lib/hooks";
import { setSelectedTask } from "@bbo/lib/reduxStore/selectedTask.slice";
import Warning from "../../assets/images/warningMsgtasklist.png";
import "./index.css";

interface MsgData {
  Msg?: string;
}

export const WarningMsgPanel = ({ Msg }: MsgData) => {
  const { warningMsg } = useAppSelector((root) => root.selectedTask);
  const dispatch = useAppDispatch();
  return warningMsg ? (
    <div className="WarningMsgTaskListPage">
      <span>
        <img className="iconBlock text-5xl mr-2" src={Warning} alt="Warning" />
        {Msg}
        <button
          className="ml-8 border-0 text-black opacity-1 float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
          onClick={() => dispatch(setSelectedTask({ warningMsg: false }))}>
          <label className="text-black opacity-1 w-6 text-3xl block outline-none focus:outline-none">
            ×
          </label>
        </button>
      </span>
    </div>
  ) : null;
};
