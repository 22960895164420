import { useEffect, useState } from "react";
import CurrencyInput from "react-currency-input-field";
import { useDebouncedCallback } from "use-debounce";
import { DYNAMO_DEBOUNCE_DURATION } from "../dynamoTableHelpers";

interface MultiInputProps {
  className?: string;
  value: number | string | null;
  placeholder?: string;
  unit: "string" | "number" | "currency" | "date";
  onChange: (newValue: string | number) => void;
  onBlur: () => void;
}

export const MultiInput = ({ value, placeholder, unit, onChange, onBlur }: MultiInputProps) => {
  const [localValue, setLocalValue] = useState(value);

  const debouncedOnChange = useDebouncedCallback((debouncedValue: MultiInputProps["value"]) => {
    onChange(debouncedValue);
  }, DYNAMO_DEBOUNCE_DURATION);

  useEffect(() => {
    debouncedOnChange(localValue);
  }, [debouncedOnChange, localValue]);

  useEffect(() => {
    setLocalValue(value);
  }, [value]);

  if (unit === "currency") {
    return (
      <CurrencyInput
        prefix="£"
        className="filter-input"
        placeholder={placeholder}
        value={localValue || ""}
        // TODO: Use values.float to get formatted value.
        // Or format in onFiltersChange before providing updated filter
        // values to consumer
        onValueChange={(value, __, values) => setLocalValue(value)}
      />
    );
  }

  return (
    <input
      className="filter-input w-full"
      placeholder={placeholder}
      type={unit === "number" ? "number" : "text"}
      value={localValue || ""}
      onChange={(e) => {
        // const regex = new RegExp(pattern);
        // // Early return if pattern is not matched
        // if (!regex.test(e.target.value)) return;

        setLocalValue(e.target.value);
      }}
    />
  );
};
