import CashDrawerList from "../../BranchOverview/CashDrawerList";
import Counters from "../../BranchOverview/CounterList";
import { AccountingLocationGroups } from "@bbo/features/BranchOverview/useBranchOverview";
import { SafeList } from "@bbo/features/BranchOverview/SafeList";

export interface IOverviewProps {
  forexData: AccountingLocationGroups;
}

export const Overview = ({ forexData }: IOverviewProps) => {
  return (
    <div data-testid="overview-testid">
      <Counters items={forexData.counterTerminal} />
      <hr className="border-black border-bottom-none mb-5 w-full" />
      <div className="w-full accounting-card block float-left h-56 overflow-x-auto whitespace-nowrap">
        <SafeList items={forexData.safe} />
      </div>
      <hr className="border-black border-bottom-none mb-5 w-full" />
      <CashDrawerList items={forexData.cashDrawer} />
    </div>
  );
};
