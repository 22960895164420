import { removeNegativeSymbol } from "@bbo/utils/currencyFormatter";
import { getOnlyDateTime } from "@bbo/utils/dateTimeFormatter";
import { ReactNode, useCallback } from "react";

interface ICellProps {
  name?: string | number;
  type?: string;
  headClassName?: string;
  children?: ReactNode;
  colSpan?: number;
}

const Cell = ({ name = "", headClassName, type, children, colSpan }: ICellProps) => {
  const renderCell = useCallback(() => {
    switch (type) {
      case "datetime": {
        const date = getOnlyDateTime(Number(name));
        return <td className={headClassName}>{date}</td>;
      }
      case "currency": {
        let classname = undefined;
        if (Number(name) > 0) classname = "positive";
        else if (Number(name) < 0) classname = "negetive";
        const iniNum = classname === "positive" ? "+" : "-";
        if (Number(name) !== 0) {
          return (
            <td className={`${headClassName} sub-body-cell ${classname}`}>{`${
              classname && iniNum
            }£${removeNegativeSymbol(+name)}`}</td>
          );
        } else {
          return <td className={`${headClassName} sub-body-cell`}>£0.00</td>;
        }
      }

      case "forex": {
        let classname = undefined;
        let amount: string | number;
        let forex: string;
        if (name.toString().includes(",")) {
          const forexData = name.toString().split(",");
          amount = forexData[1];
          forex = forexData[0];
        } else {
          amount = name;
        }
        if (Number(+amount) > 0) classname = "positive";
        else if (Number(+amount) < 0) classname = "negetive";
        const iniNum = classname === "positive" ? "+" : "-";
        if (+amount !== 0) {
          return (
            <td className={`sub-body-cell ${headClassName} ${classname}`}>{`${
              classname && iniNum
            }${forex} ${removeNegativeSymbol(+amount)}`}</td>
          );
        } else {
          return <td className={`${headClassName} sub-body-cell`}>{amount}</td>;
        }
      }

      case "hyperlink":
        return (
          <td className={headClassName}>
            <a href="#" className="hyperlink">
              Details
            </a>
          </td>
        );
      default:
        return (
          <td className={headClassName} colSpan={colSpan}>
            {name || children}
          </td>
        );
    }
  }, [children, headClassName, name, type, colSpan]);
  return renderCell();
};

export default Cell;
