import { memo, useEffect, useRef, useState } from "react";
import { Column } from "./types";

/**
 *Result page row count shown of the table component
 * @param props
 * @returns
 */
interface iProps {
  id?: string;
  name: string;
  column: Column;
  columnIndex: number;
  rows: unknown[];
  activeFilters?: any[];
  onChange?: (params: any) => any;
  TransactionType?: any[];
}

const SearchFilter = (props: iProps) => {
  const [show, setShow] = useState(false);
  const [items, setItems] = useState([]);
  const [displayItems, setDisplayItems] = useState([]);
  const [selectCount, setSelectCount] = useState(0);
  // const [search, setSearch] = useState('');
  const [checkedValues, setCheckedValues] = useState({});
  const [place, setPlace] = useState({
    width: "0px",
  });
  const refDrawer = useRef(null);

  useEffect(() => {
    if (props.activeFilters.length === 0) {
      setCheckedValues({});
      setDisplayItems([...items]);
    }
  }, [props.activeFilters]);

  //Handle effect
  useEffect(() => {
    if (props.rows) {
      const checks = {};
      let mList = [];
      if (props.column.headerName === "Transaction Type") {
        props.TransactionType.map((row) => {
          const mValue = row?.name;
          if (mList.indexOf(mValue) === -1) {
            mList = [...mList, mValue];
            checks[mValue] = false;
          }
        });
      } else {
        props.rows.map((row) => {
          const mValue = row[props.column.field] ? row[props.column.field] : "";
          if (mList.indexOf(mValue) === -1) {
            mList = [...mList, mValue];
            checks[mValue] = false;
          }
        });
      }

      setCheckedValues(checks);
      setItems(mList);
      setDisplayItems([...mList]);
    }
  }, [props.rows]);

  useEffect(() => {
    let selection = [];
    for (const key in checkedValues) {
      if (checkedValues[key]) {
        selection = [...selection, key];
      }
    }
    setSelectCount(selection.length);
  }, [checkedValues]);

  const handleClick = (e) => {
    const newWidth = document.getElementById("searchFilterView");
    setShow((v) => !v);
    setPlace({
      width: newWidth.offsetWidth + "px",
    });
  };

  const handleApply = () => {
    setShow((v) => !v);
    let selection = [];
    for (const key in checkedValues) {
      if (checkedValues[key]) {
        selection = [...selection, key];
      }
    }
    setSelectCount(selection.length);
    props.onChange({
      field: props.name,
      items: selection,
      filter: "searchFilter",
    });
  };

  /**
   * Checkbox event listener
   * @param e EventListener on change input
   */
  const handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const mValue = e.target.value ? e.target.value : "";
    if (mValue && mValue.length > 0) {
      const searchValue = mValue.toLowerCase();
      const mList = items.filter((item) => {
        let compareValue = item || "";
        compareValue = compareValue.toString().toLowerCase();
        return compareValue.indexOf(searchValue) !== -1;
      });
      setDisplayItems(mList);
    } else {
      setDisplayItems([...items]);
    }
  };

  /**
   * Checkbox event listener
   * @param e EventListener on change input
   */
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.name;
    const checked = e.target.checked;
    setCheckedValues((values) => ({
      ...values,
      [name]: checked,
    }));
  };

  /**
   * Checkbox event listener
   * @param e EventListener on change input
   */
  const handleHeaderChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const checks = {};
    displayItems.map((value) => {
      checks[value] = e.target.checked;
    });
    setCheckedValues(checks);
  };

  const ItemHeader = ({ data, label }) => (
    <span className="row-option-checkbox-list">
      <input
        name={data}
        type="checkbox"
        className="filter-option-checkbox"
        checked={selectCount === displayItems.length}
        onChange={handleHeaderChange}
      />
      {label}
    </span>
  );

  const Item = ({ data, label }) => (
    <span className="row-option-checkbox-list">
      <input
        name={data}
        type="checkbox"
        className="filter-option-checkbox"
        checked={checkedValues[data]}
        onChange={handleChange}
      />
      {label}
    </span>
  );

  const ListItems = ({ dataList }) => {
    if (dataList && dataList.length > 0) {
      return dataList.map((value, index) => {
        return <Item key={index} data={value} label={value} />;
      });
    }
    return null;
  };
  useEffect(() => {
    const handleClick = (e) => {
      if (show) {
        if (!refDrawer || (refDrawer && refDrawer.current.contains(e.target))) return;
        setShow((v) => !v);
      }
    };

    document.addEventListener("mousedown", handleClick);
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  });

  return (
    <div>
      <div id="searchFilterView" className="search-filter-view" onClick={handleClick}>
        <span>{`${selectCount > 0 ? `${selectCount} Selected` : "Select"}`}</span>
        <span style={{ paddingLeft: "3px" }}>
          <i className="fa fa-caret-down fa-2x" aria-hidden="true"></i>
        </span>
      </div>
      {show && (
        <div>
          <div
            ref={refDrawer}
            style={place}
            id="sidebar"
            className={
              "filterDropBoxShadow absolute z-40 transform transition-transform duration-200 ease-in-out sidebarBorder translate-x-0 "
            }>
            <div className="filter-inner-wrapper">
              <input
                className={"filter-search-box"}
                placeholder="Search"
                onChange={handleSearchChange}
              />
              <div className="overflow-y-scroll scrollHeight">
                {!props.column?.enableSelectAll && (
                  <ItemHeader data={"Select All"} label={"Select All"} />
                )}
                <ListItems dataList={displayItems} />
              </div>
            </div>
            <button
              style={{ marginLeft: "15px", marginBottom: "15px" }}
              className="brandRedBtn text-white mt-2"
              type="button"
              value="apply"
              data-cy="popupYes"
              onClick={handleApply}>
              Apply
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default memo(SearchFilter);
