import checkMark from "@bbo/assets/images/checkMark.svg";
import { Popup } from "@bbo/components/Popup";

interface IStockCountReviewCompletedPopupProps {
  onConfirm: () => void;
}

export const StockCountReviewCompletedPopup = ({
  onConfirm,
}: IStockCountReviewCompletedPopupProps) => {
  return (
    <Popup
      body={
        <div className="flex flex-col px-28 items-center">
          <img src={checkMark} width="58" className="pb-12 pt-4" />
          <strong className="standard-label pb-6">Stock Count Completed!</strong>
        </div>
      }
      hideCrossBtn
      actions={[
        {
          name: "OK",
          style: "popUp-button red-button",
          handleAction: onConfirm,
        },
      ]}
    />
  );
  // return (
  //   <div className="px-12 flex flex-col items-center">
  //     <img src={checkMark} width="58" className="pb-12 pt-4" />
  //     <strong className="standard-label pb-6">Stock Count Completed!</strong>
  //   </div>
  // );
};
