import makeStyles from "@material-ui/core/styles/makeStyles";
import createStyles from "@material-ui/core/styles/createStyles";
export const useStyles = makeStyles(() =>
  createStyles({
    table: {
      margin: "32px 0px",
    },
    btmNav: {
      background: "inherit",
      margin: "10px 0px",
    },
    subHeader: {
      display: "flex",
      justifyContent: "space-between",
      "font-family": "Nunito Sans !important",
      "font-style": "normal",
      "font-weight": "700",
      "font-size": "24px",
      "line-height": "34px",
      color: "#000000",
    },
    value: {
      color: " #B20101 !important",
    },
    buttonBtmNav: {
      height: "56px",
      width: "auto",
      borderRadius: "4px",
      padding: "0px 56px 0px 56px",
      marginBottom: "29px",
      color: "#293462",
      border: "2px solid #293462",
      fontSize: "18px",
      "font-weight": "700",
    },
    buttonBtmNavactive: {
      height: "56px",
      width: "187px",
      borderRadius: "4px",
      padding: "0px 56px 0px 56px",
      marginBottom: "29px",
      backgroundColor: "#293462",
      color: "#fff",
      border: "2px solid #293462",
      fontSize: "18px",
      "font-weight": "700",
    },
    dialogbox: {
      height: "296px",
      "max-width": "900px",
    },
  }),
);
