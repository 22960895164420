import { ArrowUp } from "@bbo/components/Icons";
import { SortingIcon } from "@bbo/components/Icons/SortingIcon";
import { varianceType } from "../Constants";
import CurrencyCountRow from "./CurrencyCountRow/CurrencyCountRow";
import { useStyles } from "./CurrencyCountTableStyle";
import useCurrencyCountTableHook from "./useCurrencyCountTableHook";
interface ICurrencyCountReviewTableProps {
  heading: varianceType;
  rows: string | any[];
  columns: string[];
}

export const CurrencyCountTable = ({
  heading = varianceType[""],
  rows = [],
  columns = [],
}: ICurrencyCountReviewTableProps) => {
  const classes = useStyles();
  const { tableRows, isAsc, sortAscending, sortDescending } = useCurrencyCountTableHook(rows);

  if (!tableRows) return;
  return (
    <>
      <strong className={classes.heading}>{heading}</strong>
      <table className="breakdownTable table-fixed border-collapse">
        <thead className="adhoc-head-background mb-2">
          <tr>
            {columns.map((col, index) => (
              <th key={index + col}>
                <div className={classes.tableHeading}>
                  <b>{col}</b>
                  {col === "Sterling Value" && (
                    <span
                      onClick={() => {
                        isAsc ? sortAscending(tableRows) : sortDescending(tableRows);
                      }}>
                      <SortingIcon />
                    </span>
                  )}
                </div>
              </th>
            ))}
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-gray-200">
          <>
            {(tableRows as any[])?.map((rowData, index) => {
              return (
                <CurrencyCountRow
                  key={index}
                  item={rowData}
                  varianceType={heading.toString().split(" ")[0]}
                />
              );
            })}
          </>
        </tbody>
      </table>
    </>
  );
};
