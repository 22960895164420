import { PouchSubType } from "@bbo/api/generator";
import { LIMIT_EXCEEDS } from "@bbo/features/PouchLadder/PouchConstants";
import {
  actionsLookup,
  calculateDisplayValuesTotalValueInPence,
  calculateItemValue,
  Col,
  ColKey,
  ColType,
  DenominationLadderDisplayValue,
  denominationLadderForPouchSubTypes,
  denominationLookup,
  formatDisplayCurrency,
  getColumnsForInputRow,
  getDenominationLadderDisplayValues,
  getTotalRowValue,
  getValuesFromDenominationLadderDisplayValues,
  isDisplayValueRowValid,
  penceToPounds,
  PouchDetailsItemsInfo,
  sortRows,
} from "@bbo/utils";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import CurrencyInput from "react-currency-input-field";

const getTdClassName = (col: Col) => {
  switch (col.type) {
    case "text":
      if (col.key === "multiply") return "width-10 text-left";
      if (col.key === "equals") return "width-10 text-center";
      return "text-left w-1/5";
    default:
      return "text-left w-1/5";
  }
};

interface NewPouchLadderProps {
  pouchSubType: PouchSubType;
  denominationLadder: PouchDetailsItemsInfo;
  setDenominationLadder: Dispatch<SetStateAction<PouchDetailsItemsInfo>>;
  handleSuspendCheck: (updatedDenominationLadder: PouchDetailsItemsInfo) => void;
  handleLimitExceeds: () => void;
  handleLeavePouchPreparation: () => void;
  handleSubmitPouch: (updatedDenominationLadder: PouchDetailsItemsInfo) => void;
}

export const NewPouchLadder = ({
  pouchSubType,
  denominationLadder,
  setDenominationLadder,
  handleSuspendCheck,
  handleLimitExceeds,
  handleLeavePouchPreparation,
  handleSubmitPouch,
}: NewPouchLadderProps) => {
  const selectedAction = actionsLookup[pouchSubType];
  const { cols, rows, rowsOrder } = denominationLadderForPouchSubTypes[pouchSubType];
  const [denominationLadderDisplayValues, setDenominationLadderDisplayValues] = useState(
    getDenominationLadderDisplayValues(denominationLadder),
  );
  const anyItemErrors = useMemo(
    () =>
      Object.values(denominationLadderDisplayValues).reduce(
        (acc, curr) => acc || curr.totalInputState === "error",
        false,
      ),
    [denominationLadderDisplayValues],
  );
  const [editingSection, setEditingSection] = useState<"quantities" | "totals" | null>(null);
  const total = calculateDisplayValuesTotalValueInPence(denominationLadderDisplayValues);
  const limitExceeds = total > LIMIT_EXCEEDS * 100;

  useEffect(() => {
    if (!limitExceeds) return;
    handleLimitExceeds();
  }, [handleLimitExceeds, limitExceeds]);

  useEffect(() => {
    // If the "loose coins" column is present don't let the user
    // edit the total values
    if (cols.length === 6) {
      setEditingSection("quantities");
    }
  }, [cols.length]);

  const handleQuantityChange = (rowKey: string, colWithValue: Col, newQuantity: string) => {
    setEditingSection((prev) => (prev ? prev : "quantities"));
    setDenominationLadderDisplayValues((prev) => {
      const newQuantityNumber = Number(newQuantity);
      const newValueNumber = Number(prev[colWithValue.itemID].itemValue);
      const itemValue = calculateItemValue({
        ...prev[colWithValue.itemID],
        itemQuantity: isNaN(newQuantityNumber) ? 0 : newQuantityNumber,
        itemValue: isNaN(newValueNumber) ? 0 : newValueNumber,
      });
      const updatedItem: DenominationLadderDisplayValue = {
        ...prev[colWithValue.itemID],
        itemQuantity: newQuantity,
        itemValue: itemValue.toFixed(2),
        totalInputState: "neutral",
      };
      return {
        ...prev,
        [colWithValue.itemID]: updatedItem,
      };
    });
  };

  const handleTotalChange = (rowKey: string, colWithValue: Col, newValue: string) => {
    setEditingSection((prev) => (prev ? prev : "totals"));
    setDenominationLadderDisplayValues((prev) => {
      // const newQuantityNumber = Number(prev?.[colWithValue.itemID]?.itemQuantity);
      const { denomination, currency } = prev[colWithValue.itemID];
      const newValueNumber = Number(newValue);
      const newQuantity =
        ((isNaN(newValueNumber) ? 0 : newValueNumber) / denomination) *
        (currency === "GBP" ? 1 : 100);

      const updatedItem = {
        ...prev[colWithValue.itemID],
        itemQuantity: `${Number.isInteger(newQuantity) ? newQuantity : 0}`,
        itemValue: newValue,
      };

      const updatedDisplayValues = {
        ...prev,
        [colWithValue.itemID]: updatedItem,
      };

      const isValid = isDisplayValueRowValid(rowKey, rows, updatedDisplayValues);
      if (!newValueNumber) {
        updatedItem.totalInputState = "neutral";
      } else {
        updatedItem.totalInputState = isValid ? "success" : "error";
      }

      return updatedDisplayValues;
    });
  };

  const rowsAndColumnsForInputRows = useMemo(() => {
    const unsorted = Object.keys(rows).map((rowKey) => ({
      rowKey,
      colsWithValue: getColumnsForInputRow(rows[rowKey], cols, denominationLadderDisplayValues),
    }));

    return sortRows(unsorted, rowsOrder);
  }, [cols, denominationLadderDisplayValues, rows, rowsOrder]);

  const getColHeadingClass = (colKey: ColKey, type: ColType) => {
    switch (colKey) {
      case "denomination":
        return "text-left w-1/5 rounded-tl-lg";
      case "multiply":
        return "width-10";
      case "quantity":
        return "text-left w-1/5";
      case "equals":
        return "width-8";
      case "value":
        return "text-center w-1/5 rounded-tr-lg";
    }
  };

  const getTotalRowClass = (colKey: ColKey, type: ColType) => {
    if (colKey === "denomination") return "text-left w-1/5 rounded-bl-lg";

    switch (type) {
      case "input":
        return "width-10 text-right";
      case "total":
        return "text-right w-1/5 rounded-br-lg";
      default:
        break;
    }
    return "width-15 text-center";
  };

  const getTotalColClass = (colWithValue: Col) => {
    const baseClass = "h-9 commonTableBorder heading-6 text-right border-4px inputCash";

    // This is only true when there are more than 1 itemValues rows
    if (!colWithValue.itemID) {
      return `${baseClass} width-100 normalCss`;
    }

    if (editingSection !== "quantities" && colWithValue.itemID) {
      switch (denominationLadderDisplayValues[colWithValue.itemID].totalInputState) {
        case "error":
          return `${baseClass} alertCss`;
        case "success":
          return `${baseClass} successCss`;
        default:
          break;
      }
    }
    return `${baseClass} normalCss`;
  };

  const getCypressID = (rowKey: string, colKey: ColKey) => {
    const { denomination } = denominationLookup[rowKey];

    switch (colKey) {
      case "looseCoins":
      case "value":
        return `${denomination}V`;
      case "bags":
      case "quantity":
        return `${denomination}Q`;
    }

    return undefined;
  };

  const handleSubmit = () => {
    const updatedDenominationLadder = getValuesFromDenominationLadderDisplayValues(
      denominationLadderDisplayValues,
    );
    setDenominationLadder(updatedDenominationLadder);
    handleSubmitPouch(updatedDenominationLadder);
  };

  const handleSuspend = () => {
    const updatedDenominationLadder = getValuesFromDenominationLadderDisplayValues(
      denominationLadderDisplayValues,
    );
    setDenominationLadder(updatedDenominationLadder);
    handleSuspendCheck(updatedDenominationLadder);
  };

  return (
    <>
      <div className="w-full float-left flex justify-between">
        <h2 className="heading-3 text-black font-bold relative bottom-4">{selectedAction.label}</h2>
        {/* {showSuspendButton ? ( */}
        {/* CSS applied disabledBtn and button is disabled as per requirement */}
        <button type="button" disabled className="link-button disabledBtn" onClick={handleSuspend}>
          Suspend this task
        </button>
        {/* ) : null} */}
      </div>
      <div className="rounded-lg breakdownTableBorder ">
        <table className="border-spacing-zero pouchPopup">
          <thead>
            <tr>
              {cols.map(({ label, key, type }) =>
                ["multiply", "equals"].includes(key) ? (
                  <th key={key} className={getColHeadingClass(key, type)}></th>
                ) : (
                  <th key={key} className={getColHeadingClass(key, type)}>
                    <b>{label}</b>
                  </th>
                ),
              )}
            </tr>
          </thead>
          <tbody>
            {rowsAndColumnsForInputRows.map(({ rowKey, colsWithValue }) => (
              <tr key={`row-${rowKey}`}>
                {colsWithValue.map((colWithValue) => (
                  <td
                    key={`row-${rowKey}-col-${colWithValue.key}`}
                    className={getTdClassName(colWithValue)}>
                    {colWithValue.type === "text" ? (
                      <>
                        {colWithValue.key === "denomination"
                          ? formatDisplayCurrency(denominationLookup[rowKey])
                          : colWithValue.label}
                      </>
                    ) : null}
                    {colWithValue.type === "input" ? (
                      <input
                        key={`${rowKey}${colWithValue.key}`}
                        data-cy={getCypressID(rowKey, colWithValue.key)}
                        min="0"
                        pattern="^[0-9\b]+$"
                        type="number"
                        id={colWithValue.key}
                        name={colWithValue.key}
                        value={colWithValue.displayValue}
                        autoComplete="off"
                        className={
                          "h-9 p-2 commonTableBorder w-full text-right border-4px heading-6 inputCash"
                        }
                        disabled={editingSection === "totals"}
                        onKeyDown={(e) => {
                          // Prevent "."" or "-" keys from triggering onChange
                          if (e.key === "." || e.key === "-" || e.key === "e" || e.key === "E") {
                            e.preventDefault();
                          }
                        }}
                        onChange={(e) => handleQuantityChange(rowKey, colWithValue, e.target.value)}
                      />
                    ) : null}
                    {colWithValue.type === "total" && rows[rowKey].length > 1 ? (
                      <CurrencyInput
                        prefix="£"
                        data-cy={getCypressID(rowKey, colWithValue.key)}
                        className={getTotalColClass(colWithValue)}
                        allowNegativeValue={false}
                        value={getTotalRowValue(
                          denominationLadderForPouchSubTypes[pouchSubType].rows[rowKey],
                          denominationLadderDisplayValues,
                        )} // displayValue is in pounds
                        disabled
                        decimalScale={2}
                      />
                    ) : null}
                    {colWithValue.type === "total" && rows[rowKey].length === 1 ? (
                      <CurrencyInput
                        prefix="£"
                        data-cy={getCypressID(rowKey, colWithValue.key)}
                        className={getTotalColClass(colWithValue)}
                        allowNegativeValue={false}
                        value={colWithValue.displayValue} // displayValue is in pounds
                        disabled={editingSection === "quantities"}
                        decimalScale={2}
                        onValueChange={(value) => handleTotalChange(rowKey, colWithValue, value)}
                      />
                    ) : null}
                  </td>
                ))}
              </tr>
            ))}

            <tr>
              {cols.map(({ label, type, key }, index) => (
                <td key={key} className={getTotalRowClass(key, type)}>
                  {key === "denomination" ? "Total" : null}
                  {key === "equals" ? "=" : null}
                  {type === "input"
                    ? Object.values(rowsAndColumnsForInputRows).reduce((acc, curr) => {
                        const currentValue = curr.colsWithValue[index].displayValue;
                        if (isNaN(Number(currentValue))) {
                          return acc;
                        }
                        return acc + Number(currentValue);
                      }, 0)
                    : null}
                  {type === "total" ? <b>£{penceToPounds(total)}</b> : null}
                </td>
              ))}
            </tr>
          </tbody>
        </table>
      </div>
      <div className="flex justify-center pt-2 w-full">
        {handleLeavePouchPreparation ? (
          <button
            className="brandWhiteBtn mr-1 w-5/12 font-bold"
            onClick={handleLeavePouchPreparation}>
            Cancel
          </button>
        ) : null}
        {handleSubmitPouch ? (
          <button
            className="accounting-button ml-1 w-5/12"
            disabled={total <= 0 || limitExceeds || anyItemErrors}
            onClick={handleSubmit}>
            Complete
          </button>
        ) : null}
      </div>
    </>
  );
};
