import { useDescendants } from "@bbo/lib";
import { ReactNode } from "react";
import { Descendants } from "./Descendants";

export const TabTitleList = ({ children }: { children: ReactNode }) => {
  const context = useDescendants();

  return (
    <Descendants value={context}>
      <ul className="tabs" data-cy="tab">
        {children}
      </ul>
    </Descendants>
  );
};
