import { useEffect, useState } from "react";
export default function useCurrencyCountRowHook(item, varianceType) {
  const [data, setData] = useState([]);

  useEffect(() => {
    setData(item);
  }, [data, item]);

  const getStyles = (key, classes) => {
    const color = varianceType === "Positive" ? classes.colorGreen : classes.colorRed;
    switch (key) {
      case "sterlingValue": {
        return `${classes.cellText} ${color} w-1/5`;
      }
      case "currencyVariance": {
        return `${classes.cellText} ${color} w-1/5`;
      }
      case "edits": {
        return `${classes.cellText} ${classes.link} w-1/5`;
      }
      default: {
        return `${classes.cellText} w-1/5`;
      }
    }
  };
  return { data, getStyles };
}
