import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";
import { Auth } from "aws-amplify";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { useGlobalState } from "@bbo/lib/state";
import Logo from "../assets/images/logo.png";

const DEFAULT_REDIRECT = "/";

const Index = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [alertMessage, setAlertMessage] = React.useState("");

  let returnUrl = location.search
    .substring(1)
    .split("&")
    .find((s) => s.startsWith("return="));

  returnUrl = returnUrl ? returnUrl.split("=")[1] : DEFAULT_REDIRECT;

  useEffect(() => {
    onAuthUIStateChange((newAuthState: AuthState) => {
      if (newAuthState === AuthState.SignedIn) {
        navigate(returnUrl, { replace: true });
      }
    });
  }, [navigate, returnUrl]);

  const [inputs, setInputs] = useState({
    username: "",
    password: "",
  });

  const { username, password } = inputs;

  function handleChange(e) {
    const { name, value } = e.target;
    setInputs((inputs) => ({ ...inputs, [name]: value }));
  }

  async function handleSubmit(e) {
    e.preventDefault();
    try {
      await Auth.signIn(username, password);
      navigate("/taskManagement");
    } catch (e) {
      if (username === "" && password === "") {
        setAlertMessage("SmartId and Password cannot be empty");
      } else if (e.message === "Incorrect username or password.") {
        setAlertMessage("Incorrect SmartId or Password.");
      } else if (e.message === "Username cannot be empty") {
        setAlertMessage("SmartId cannot be empty");
      } else if (e.message === "Custom auth lambda trigger is not configured for the user pool.") {
        setAlertMessage("Password cannot be empty");
      } else if (e.message === "Pending sign-in attempt already in progress") {
        console.log("Login inprogress");
      } else {
        setAlertMessage(e.message);
      }
    }
  }

  const loginField = (
    <form name="form" onSubmit={handleSubmit}>
      <div className="formLogin">
        <div className="labelDiv">
          <label className="lableSmart">Smart Id</label>{" "}
        </div>
        <input
          className="loginInput"
          type="text"
          name="username"
          value={username}
          onChange={handleChange}
          data-cy="SmartId"
        />
      </div>
      <div className="formLogin">
        <div className="labelDiv">
          {" "}
          <label className="lableSmart">Password</label>{" "}
        </div>
        <input
          type="password"
          name="password"
          value={password}
          onChange={handleChange}
          className="loginInput"
          data-cy="password"
        />
      </div>
      <div className="formLogin">
        <button className="loginBtn" data-cy="loginBtn">
          {<span className="spinner-border spinner-border-sm mr-1"></span>}
          Login
        </button>{" "}
      </div>
    </form>
  );
  return (
    <>
      <div className="loginContainer">
        <div className="loginTop">
          <div className="Loginlogo">
            <img src={Logo} alt="Post Office" />
          </div>
          <div className="MainHeading" data-cy="loginWelcome">
            Welcome
          </div>
          <div className="SubHeading">Please Log In</div>
          {alertMessage && (
            <div className="alertMsg" data-cy="alertMsg">
              {alertMessage}
            </div>
          )}
        </div>

        {loginField}
      </div>
    </>
  );
};

export default Index;
