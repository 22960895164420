import { BranchCashOverview, useGetCashBranchOverviewDetails } from "@bbo/api/generator";
import { BRANCH_OVERVIEW_TABS } from "@bbo/constants";
import { useMemo, useState } from "react";
import { groupBranchOverviewData } from "./branchOverviewHelpers";

export interface AccountingLocationGroups {
  safe: BranchCashOverview[];
  cashDrawer: BranchCashOverview[];
  counterTerminal: BranchCashOverview[];
}

export const useBranchOverview = () => {
  const [selectedTab, setSelectedTab] = useState(BRANCH_OVERVIEW_TABS[0]);
  const [accountingLocation, setAccountingLocation] = useState(null);
  const { data: branchOverviewData, isLoading } = useGetCashBranchOverviewDetails();

  const handleSelectTab = (params: { name: string }) => {
    setSelectedTab(params.name);
  };

  const accountingLocationGroups: AccountingLocationGroups | null = useMemo(() => {
    if (isLoading) return null;

    return groupBranchOverviewData(branchOverviewData);
  }, [branchOverviewData, isLoading]);

  return {
    selectedTab,
    handleSelectTab,
    accountingLocation,
    setAccountingLocation,
    isLoading,
    accountingLocationGroups,
    branchOverviewData,
  };
};
