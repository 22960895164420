import { TaskData, useBboDeclarationgetTaskList } from "@bbo/api/generator";
import { SCREENS } from "@bbo/constants/screenEnums";
import { useAppDispatch, useGlobalState } from "@bbo/lib";
import { SelectedTask, setSelectedTask } from "@bbo/lib/reduxStore/selectedTask.slice";
import { capitalizeFirstLetter } from "@bbo/utils/capitalizeFirstLetter";
import { SyntheticEvent, useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const useHomeTab = () => {
  const { data, isLoading: isTaskListLoading } = useBboDeclarationgetTaskList();
  const [, setRouteState] = useGlobalState("routeState");
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  useEffect(() => {
    data?.data &&
      data?.data?.find(
        (item) => item?.status === "Pending" && dispatch(setSelectedTask({ warningMsg: true })),
      );
  }, [data, dispatch]);

  const taskList = data?.data || [];

  // TODO: I don't think this will work, need to update how we're navigating to routes
  const handleCashFlowRouteByTask = ({ item }: { item: SelectedTask }) => {
    switch (item.task) {
      case "Cash Balance": {
        dispatch(setSelectedTask(item));
        // TODO: Route state needs review, doesn't seem like a good approach
        setRouteState({ RouteState: "taskManagement" });
        navigate(
          `/cashBalance/${capitalizeFirstLetter(item?.accountingLocationType)}/${
            item?.accountingLocationID
          }`,
          {
            replace: true,
            state: { from: SCREENS.TASK_MANAGEMENT },
          },
        );
        return;
      }
      case "Transfer Out": {
        dispatch(setSelectedTask(item));
        return;
      }
      case "Transfer In": {
        dispatch(setSelectedTask(item));
        return;
      }
      default: {
        // return assertNever(item.task);
      }
    }
  };

  const onCashFlow = (item: TaskData) => (e: SyntheticEvent) => {
    e.preventDefault();
    handleCashFlowRouteByTask({ item: item as SelectedTask });
  };

  return {
    taskList,
    isTaskListLoading,
    onCashFlow,
  };
};
