import { DYNAMO_ITEMS_PER_PAGE } from "@bbo/components/DynamoTable/dynamoTableHelpers";
import { Dispatch, SetStateAction, useEffect, useMemo, useState } from "react";
import { Filter, Filters, FromToFilter } from "../../../components/DynamoTable/dynamoTableTypes";
import { filterSwitch, getGeneratedValues } from "./filterHelpers";
import { getDateRangeForChunk, getDefaultFromDate, getDefaultToDate } from "./paginationHelpers";
import { Options } from "./paginationTypes";
import { useDataFetching } from "./useDataFetching";
import { useDataSelection } from "./useDataSelection";
import { usePaginationControl } from "./usePaginationControl";
import { usePaginationState } from "./usePaginationState";
import { subDays } from "date-fns";

export interface ErrorState {
  isError: boolean;
  failureReason: any;
}
interface UseDynamoPaginationReturn<TData, TError> {
  data: TData; // the current page's data
  error: ErrorState; // the current page's error
  isLoading: boolean; // whether any data is currently being fetched
  hasNext: boolean; // true if there is a next page. Typically this is just passed to the DynamoTable component
  hasPrev: boolean; // true if there is a previous page. Typically this is just passed to the DynamoTable component
  getNext: () => void; // automatically makes the API request to get the next page of data. Typically this is just passed to the DynamoTable component
  getPrev: () => void; // automatically makes the API request to get the previous page of data. Typically this is just passed to the DynamoTable component
  filters: Filters; // the currently applied filters. Typically this is just passed to the DynamoTable component
  setFilters: Dispatch<SetStateAction<Filters>>; // used to set the filters. Typically this is just passed to the DynamoTable component
  setSelectedItemIndexes: Dispatch<SetStateAction<number[]>>; // used to set the selectedItemIndexes. Typically this is just passed to the DynamoTable component
  selectedItemIndexes: number[]; // a convenience for getting selected items from selectedItemIndexes item indexes. Resets on paginatedData change
  selectedItems: unknown[]; // the selected items. Resets on paginatedData change
}

const defaultOptions: Options = {
  dataKey: "data",
  apiFromDateKey: "fromDate",
  apiToDateKey: "toDate",
  dateRangeFilterKey: "timestamp",
};

const itemsPerPage = DYNAMO_ITEMS_PER_PAGE;

export const useDynamoPagination = <T extends (...params: any[]) => any, TData, TError>(
  queryHook: T,
  options?: Options,
  ...queryHookParams: Parameters<T>
): UseDynamoPaginationReturn<TData, TError> => {
  const mergedOptions = {
    ...defaultOptions,
    ...options,
  };

  // Moved these 2 lines inside the hook so that the from/to dates are recalculated each time the screen is navigated to
  const defaultFromDate = useMemo(() => +subDays(+new Date(), 42), []);
  const defaultToDate = useMemo(() => +new Date(), []);

  const transformData = <TData>(allData: TData[], filters: Filters): TData[] => {
    if (allData.length === 0) return allData;

    return allData.map((item) =>
      Object.keys(item).reduce((acc, itemKey) => {
        acc[itemKey];
        acc[itemKey] = filters[itemKey]?.transform
          ? filters[itemKey].transform(item[itemKey])
          : item[itemKey];
        return acc;
      }, {} as typeof item),
    );
  };

  const filterData = <TData>(allData: TData[], filters: Filters): TData[] => {
    if (allData.length === 0) return allData;
    const { timestamp, ...otherFilters } = filters;
    let filteredData = allData;
    for (const key in otherFilters) {
      filteredData = filterSwitch(key, otherFilters[key] as Filter<string>, filteredData);
    }

    return filteredData;
  };

  const { dataKey, dateRangeFilterKey, filters } = mergedOptions;
  const [filterState, setFilterState] = useState<Filters>(filters);

  const {
    allData,
    setAllData,
    currentPage,
    setCurrentPage,
    currentChunk,
    setCurrentChunk,
    needsToFetch,
    setNeedsToFetch,
    selectedItemIndexes,
    setSelectedItemIndexes,
    errorState,
    setError,
    resetAll,
  } = usePaginationState();

  const dateRangeFilter = filterState[dateRangeFilterKey] as FromToFilter;
  const queryHookFromDate = dateRangeFilter?.value?.from;
  const queryHookToDate = dateRangeFilter?.value?.to;

  const unchunkedFromDate: number = queryHookFromDate || defaultFromDate;
  const unchunkedToDate: number = queryHookToDate || defaultToDate;

  const chunkedDate = useMemo(
    () => getDateRangeForChunk(unchunkedFromDate, unchunkedToDate, currentChunk),
    [currentChunk, unchunkedFromDate, unchunkedToDate],
  );

  const transformedAllData = useMemo(
    () => transformData(allData, filterState) || [],
    [allData, filterState],
  );

  const filteredAllData = useMemo(
    () => filterData(transformedAllData, filterState) || [],
    [filterState, transformedAllData],
  );

  const sortedAllData = useMemo(
    () =>
      filteredAllData.sort((a, b) => {
        if (!a?.[mergedOptions.dateRangeFilterKey] || !b?.[mergedOptions.dateRangeFilterKey])
          return 0;
        return b?.[mergedOptions.dateRangeFilterKey] - a?.[mergedOptions.dateRangeFilterKey];
      }),
    [filteredAllData, mergedOptions.dateRangeFilterKey],
  );

  useEffect(() => {
    setFilterState((prev) => getGeneratedValues<unknown>(transformData(allData, prev), prev));
  }, [allData]);

  const { responseData, isInitialLoading, isLoading } = useDataFetching(
    queryHook,
    mergedOptions,
    queryHookParams,
    chunkedDate,
    needsToFetch,
    errorState,
    setError,
    setAllData,
  );

  const hasReachedFinalChunk = chunkedDate.from <= unchunkedFromDate;
  const hasCurrentAndNextPage = sortedAllData.length >= (currentPage + 2) * itemsPerPage;

  useEffect(() => {
    if (isLoading) return;

    const _needsToFetch = !hasCurrentAndNextPage && !hasReachedFinalChunk;
    if (!_needsToFetch) {
      setNeedsToFetch(false);
      return;
    }

    setCurrentChunk((prev) => prev + 1);
    setNeedsToFetch(true);
    // responseData needs to be in dependency array to trigger this useEffect on every fetch
  }, [
    responseData,
    hasReachedFinalChunk,
    hasCurrentAndNextPage,
    setCurrentChunk,
    setNeedsToFetch,
    isLoading,
  ]);

  // Moved this useEffect below the _needsToFetch useEffect so that we force a fetch
  // whenever the from/to date changes
  useEffect(() => {
    resetAll();
  }, [resetAll, queryHookFromDate, queryHookToDate]);

  const { paginatedData, hasNext, hasPrev, getNext, getPrev } = usePaginationControl(
    sortedAllData,
    currentPage,
    setCurrentPage,
    itemsPerPage,
  );

  useEffect(() => {
    if (paginatedData.length === 0) {
      setCurrentPage(0);
    }
  }, [filterState, paginatedData.length, setCurrentPage]);

  // Resets the selected items when paginatedData updates. Reasoning inside
  const { selectedItems } = useDataSelection(
    paginatedData,
    selectedItemIndexes,
    setSelectedItemIndexes,
  );

  return {
    data: { ...responseData, [dataKey]: paginatedData },
    error: errorState,
    isLoading: isInitialLoading && !paginatedData?.length,
    hasNext,
    hasPrev,
    getPrev,
    getNext,
    filters: filterState,
    setFilters: setFilterState,
    selectedItemIndexes,
    setSelectedItemIndexes,
    selectedItems,
  };
};
