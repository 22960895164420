import { useGetForexCountSummary, ForexCurrencyData } from "@bbo/api/generator";
import { useAppDispatch, useAppSelector } from "@bbo/lib";
import { setCurrentGlobalPage } from "@bbo/lib/reduxStore/pagination.slice";
import { getValueFromStorage } from "@bbo/utils/util";
import { useMemo, useState } from "react";

const cols = [
  { field: "userName", name: "User Name", type: "string" },
  { field: "smartID", name: "Smart ID", type: "string" },
  { field: "countType", name: "Count Type", type: "string" },
  { field: "dateTime", name: "Date & Time Range", type: "datetime" },
  { field: "edit", name: "Edited" },
  { field: "currentVariance", name: "Currency Variance", type: "forex" },
  { field: "totalVariance", name: "Variance", type: "currency" },
  { field: "totalVariance", name: "Link", type: "hyperlink" },
];

export const useCurrencyCountGrid = () => {
  const selectedEntityDetails = useAppSelector((root) => root.selectedTask);
  const fadCode = getValueFromStorage<string>("fadcode");
  const entityID = getValueFromStorage<string>("entityID");

  const { data, isLoading } = useGetForexCountSummary(
    selectedEntityDetails.fadcode || fadCode,
    selectedEntityDetails.entityID || entityID,
  );
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useAppDispatch();
  const rows = useMemo(() => data, [data]) as ForexCurrencyData[];
  const onPaginationClick = (page: number) => {
    setCurrentPage(page);
    dispatch(setCurrentGlobalPage({ currentPage: page }));
  };
  return {
    rows,
    cols,
    isLoading,
    currentPage,
    setCurrentGlobalPage,
    onPaginationClick,
  };
};
