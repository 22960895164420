/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  BranchDetailsResponse,
  BboBranchConfigBadRequestResponse,
  BboBranchConfigUnauthorizedResponse,
  BboBranchConfigForbiddenResponse,
  BboBranchConfigFailureResponseResponse,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * @summary Branch Details
 */
export const useGetBranchConfigHook = () => {
  const getBranchConfig = useCustomInstance<BranchDetailsResponse>();

  return () => {
    return getBranchConfig({ url: `/BboBranchConfigService/v1/branch`, method: "get" });
  };
};

export const getGetBranchConfigQueryKey = () => [`/BboBranchConfigService/v1/branch`];

export type GetBranchConfigQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetBranchConfigHook>>
>;
export type GetBranchConfigQueryError = ErrorType<
  | BboBranchConfigBadRequestResponse
  | BboBranchConfigUnauthorizedResponse
  | BboBranchConfigForbiddenResponse
  | BboBranchConfigFailureResponseResponse
>;

export const useGetBranchConfig = <
  TData = AsyncReturnType<ReturnType<typeof useGetBranchConfigHook>>,
  TError = ErrorType<
    | BboBranchConfigBadRequestResponse
    | BboBranchConfigUnauthorizedResponse
    | BboBranchConfigForbiddenResponse
    | BboBranchConfigFailureResponseResponse
  >,
>(options?: {
  query?: UseQueryOptions<
    AsyncReturnType<ReturnType<typeof useGetBranchConfigHook>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetBranchConfigQueryKey();

  const getBranchConfig = useGetBranchConfigHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetBranchConfigHook>>> = () =>
    getBranchConfig();

  const query = useQuery<AsyncReturnType<ReturnType<typeof useGetBranchConfigHook>>, TError, TData>(
    queryKey,
    queryFn,
    queryOptions,
  );

  return {
    queryKey,
    ...query,
  };
};
