import { useEffect } from "react";
import { useGlobalState } from "../state";

export const useIsPOLauncher = ({ query }: { query: URLSearchParams }) => {
  const [authUser, setAuthUser] = useGlobalState("authUser");

  const idToken = query.get("idToken");
  const accessToken = query.get("accessToken");
  const refreshToken = query.get("refreshToken");

  useEffect(() => {
    //set storage here
    idToken &&
      accessToken &&
      refreshToken &&
      setAuthUser({
        ...authUser,
        idToken: idToken,
        accessToken: accessToken,
        refreshToken: refreshToken,
        isPOLauncher: true,
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
};
