import { BpTPHistoryData } from "@bbo/interfaces/HistoryDetail";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface IState {
  CashBalanceHistory: BpTPHistoryData[];
}

const initialState: IState = {
  CashBalanceHistory: [
    {
      ID: "",
      cash: {
        accountingLocations: [],
        totalVariance: 0,
      },
      createdBySmartID: "",
      createdByUserName: "",
      dateTime: 0,
      discrepancyID: "",
      name: "",
      operationType: "",
      varianceAmount: 0,
    },
  ],
};

export const HistoryDetails = createSlice({
  name: "HistoryDetails",
  initialState,
  reducers: {
    setCashBalanceHistoryDetails: (state, action: PayloadAction<IState>) => {
      Object.assign(state, action.payload);
    },

    setCashManagementHistoryDetails: (state, action: PayloadAction<IState>) => {
      Object.assign(state, action.payload);
    },
  },
});

export const { setCashBalanceHistoryDetails, setCashManagementHistoryDetails } =
  HistoryDetails.actions;

export default HistoryDetails.reducer;
