import {
  AccountingLocationType,
  BranchCashOverview,
  BranchCashOverviewResponseResponse,
  CashBalanceStatus,
  CounterID,
  OnBoardingEntityCashDrawers,
} from "@bbo/api/generator";
import { SCREENS } from "@bbo/constants";
import { MaxBranchLimit } from "@bbo/lib/reduxStore/MaxBranchLimit";
import { inputNumberInPence } from "@bbo/utils/currencyFormatter";
import { CurrencyInputOnChangeValues } from "react-currency-input-field/dist/components/CurrencyInputProps";
import { Action, getActionList } from "./BranchOverviewProvider";
import { AccountingLocationGroups } from "./useBranchOverview";

export const branchOverviewCommonStrings = {
  lastVariance: "Last Variance",
  toDateVariance: "To Date Variance",
  lastBalance: "Last Balance Date",
  rows: ["currentVariance", "toDateVariance", "lastBalancedDateTime"],
};

export const branchOverviewForexStrings = {
  lastVariance: "Last Count Variance",
  toDateVariance: "To Date Variance",
  lastBalance: "Last Count Date",
  rows: ["currentVariance", "lastBalancedDateTime", "toDateVariance"],
};

export type Operation =
  | "transfer_out"
  | "transfer_in"
  | "paid_in"
  | "paid_out"
  | "currency_cnt_sum_1"
  | "currency_cnt_1"
  | "cash_balance"
  | null;

export const getCashBalanceSubPath = (accountingLocationType: AccountingLocationType) => {
  switch (accountingLocationType) {
    case "safe":
      return "Safe";
    default:
      return "CashDrawer";
  }
};

export const getOperationUrl: Record<
  Operation,
  (accountingLocationID: string, accountingLocationType?: AccountingLocationType) => string
> = {
  transfer_out: (accountingLocationID: string) => `${SCREENS.TRANSFER_OUT}/${accountingLocationID}`,
  transfer_in: (accountingLocationID: string) => `${SCREENS.TRANSFER_IN}/${accountingLocationID}`,
  paid_in: (accountingLocationID: string) => `${SCREENS.PAID_IN}/${accountingLocationID}`,
  paid_out: (accountingLocationID: string) => `${SCREENS.PAID_OUT}/${accountingLocationID}`,
  currency_cnt_sum_1: () => SCREENS.CURRENCY_COUNT_SUMMARY,
  currency_cnt_1: () => SCREENS.CURRENCY_COUNT,
  cash_balance: (accountingLocationID: string, accountingLocationType: AccountingLocationType) => {
    const subPath = getCashBalanceSubPath(accountingLocationType);
    return `${SCREENS.CASH_BALANCE}/${subPath}/${accountingLocationID}`;
  },
};

export const paidInRestrictBranches = ["DMB", "SP"];

export const checkOperationDisabled = (operation: Operation, branchType: string): boolean =>
  operation === "paid_in" && paidInRestrictBranches.includes(branchType);

// If action isn't in the actionList for this accountingLocationType or the operation is disabled, then throw an error
export const checkIfAccountingLocationActionInvalid = (
  accountingLocation: BranchCashOverview,
  action: Action,
  branchType: string,
) => {
  if (!accountingLocation) return false;
  const actionList = getActionList(accountingLocation);
  return (
    !actionList.find((action) => action.id === action.id) ||
    checkOperationDisabled(action.operation, branchType)
  );
};

export const actionButtonStyle = (
  index: number,
  operation: Operation,
  actionList: Action[],
  branchType: string,
): string => {
  const isLastOddItem = actionList.length % 2 !== 0 && actionList.length === index + 1;
  const isPaidInRestricted = operation === "paid_in" && paidInRestrictBranches.includes(branchType);
  const baseClass = "font-bold";
  const disabledClass = "btn-disable";
  const normalClass = "branchOverviewButton";
  const colSpanClass = isLastOddItem ? "col-span-2" : "";

  return `${isPaidInRestricted ? disabledClass : normalClass} ${baseClass} ${colSpanClass}`.trim();
};

export enum ActionListHeader {
  CashDrawers = "Cash Drawers",
  Counters = "Counters",
  Safe = "Safe",
}

export const getActionListHeader = (
  accountingLocationType: AccountingLocationType,
  counterID: CounterID,
): ActionListHeader | "" => {
  switch (accountingLocationType) {
    case "cashDrawer":
      return counterID ? ActionListHeader.Counters : ActionListHeader.CashDrawers;
    case "safe":
      return ActionListHeader.Safe;
    default:
      return "";
  }
};

export type Status = "continue" | "continue_cash_transfer" | "transfer" | "escape_cash_transfer";

export const getLegacyAccountingLocationType = (accountingLocationType: AccountingLocationType) => {
  switch (accountingLocationType) {
    case "cashDrawer":
      return "cash_drawer";
    case "safe":
      return "safe";
    default:
      return "";
  }
};

export const getAccountingLocationTypeFromLegacy = (legacyAccountingLocationType: string) => {
  switch (legacyAccountingLocationType) {
    case "cash_drawer":
      return "cashDrawer";
    case "safe":
      return "safe";
    default:
      return "";
  }
};

export const getAccountingLocationText = (
  accountingLocationType: AccountingLocationType,
  counterID?: string,
) => {
  // TODO: REMOVE THIS LINE WHEN API ERRORS ARE FIXED
  const _accountingLocationType = `${accountingLocationType?.[0].toLowerCase()}${accountingLocationType?.substring(
    1,
  )}`;
  switch (_accountingLocationType) {
    case "cashDrawer":
      return counterID
        ? ({
            title: "Counter",
            type: "counterTerminal",
            infoTitle: "Terminal",
          } as const)
        : ({
            title: "Cash Drawer",
            type: "cashDrawer",
            infoTitle: "Cash Drawer",
          } as const);
    case "safe":
      return {
        title: "Safe",
        type: "safe",
        infoTitle: "Safe",
      } as const;
    default:
      return {
        title: "Accounting Location",
        type: "",
        infoTitle: "Accounting Location",
      } as const;
  }
};

export const convertToBranchCashOverview = (cashDrawer: OnBoardingEntityCashDrawers) => ({
  accountingLocationID: cashDrawer.entityID,
  accountingLocationName: cashDrawer.entityName,
  accountingLocationType: getAccountingLocationTypeFromLegacy(
    cashDrawer.entity,
  ) as AccountingLocationType,
  counterID: cashDrawer.associatedCounterTerminalID,
  counterName: cashDrawer.associatedCounterTerminalName,
  status: cashDrawer.status as CashBalanceStatus,
  totalValue: cashDrawer.balance,
  timestamp: cashDrawer.onBoardDateTime,
  lastDeclarationTimestamp: 0,
  toDateVariance: 0,
  currentVariance: 0,
});

export const groupBranchOverviewData = (
  branchOverviewData: BranchCashOverviewResponseResponse,
): AccountingLocationGroups | null => {
  if (!branchOverviewData) return null;

  const initial = {
    safe: [],
    cashDrawer: [],
    counterTerminal: [],
  };

  return branchOverviewData.reduce((acc, curr) => {
    const key = getAccountingLocationText(curr.accountingLocationType, curr.counterID).type;
    return { ...acc, [key]: [...acc[key], curr] };
  }, initial) as AccountingLocationGroups;
};

export const transferInOutErrors = {
  // warningFlag
  exceededPermittedSourceLimit: {
    key: "exceededPermittedSourceLimit",
    message: "Warning: Transfer limit has been surpassed",
    className: "warningMsg",
    type: "inputError",
  },
  // invalidLimitFlag - limit in pounds?
  exceededMaxSourceLimit: (limit: number) =>
    ({
      key: "exceededMaxSourceLimit",
      message: "This transaction will not be permitted",
      icon: (
        <i
          className="fas fa-info-circle errorMsg cursor-pointer"
          title={`Transaction above £${limit} will not be permitted.`}
        />
      ),
      className: "errorMsg",
      type: "inputError",
    } as const),
  // warningMaxFlag
  exceededMaxDestinationLimit: {
    key: "exceededMaxDestinationLimit",
    message: "Warning: Exceeding the maximum limit",
    className: "warningMsg",
    type: "inputError",
  },
  // invalidFund
  exceededTotalFunds: {
    key: "exceededTotalFunds",
    message: "This has insufficient funds to transfer",
    className: "errorMsg",
    type: "inputError",
  },
  invalidAccountingLocation: {
    key: "invalidAccountingLocation",
    message: "Invalid accounting location",
    className: "errorMsg",
    type: "modalError",
  },
  invalidOperation: {
    key: "invalidOperation",
    message: "Invalid operation for accounting location",
    className: "errorMsg",
    type: "modalError",
  },
} as const;

export interface TransferInOutError {
  key: keyof typeof transferInOutErrors;
  message: string;
  className: string;
  icon?: JSX.Element;
  type: "inputError" | "modalError";
}

export const hasExceededPermittedSourceLimit = (
  selectedRule: MaxBranchLimit,
  selectedAmount: CurrencyInputOnChangeValues,
) => selectedRule?.permittedLimit < selectedAmount.float;

export const hasExceededMaxDestinationLimit = (
  destinationEntityDataLimits: MaxBranchLimit,
  selectedAmount: CurrencyInputOnChangeValues,
) =>
  destinationEntityDataLimits && destinationEntityDataLimits?.maximumLimit < selectedAmount.float;

export const hasExceededMaxSourceLimit = (
  selectedRule: MaxBranchLimit,
  selectedAmount: CurrencyInputOnChangeValues,
) => selectedRule?.maximumLimit < selectedAmount.float;

export const hasExceededTotalFunds = (
  accountingLocation: number,
  selectedAmount: CurrencyInputOnChangeValues,
) => accountingLocation < inputNumberInPence(selectedAmount.float);

export type TransferInOutModals = "TransferSuccess" | "Print";

export interface TransferInOutTab {
  value: keyof AccountingLocationGroups;
  label: string;
}

export const formatCounterTerminalName = (accountingLocation: BranchCashOverview | undefined) => {
  if (!accountingLocation.counterID) {
    return accountingLocation.accountingLocationName;
  }
  return `${accountingLocation.accountingLocationName} (${accountingLocation.counterName})`;
};
