import { Action } from "@bbo/utils";

interface ActionSelectionProps {
  actions: Action[];
  onClick: (action: Action) => void;
}

export const ActionSelection = ({ actions, onClick }: ActionSelectionProps) => {
  return (
    <>
      <div className="flex items-center justify-center heading pouchPrepBox p-7">
        <h2 className="heading-3 font-bold">
          Select the content type from the options on the right.
        </h2>
      </div>
      <div className="flex items-center justify-center bg-white flex-col pouchPrepBox p-7">
        {actions?.map((action) => (
          <button
            key={action.id}
            className="transfer-out-tiles lg:w-3/5 font-bold heading mb-4"
            onClick={() => onClick(action)}>
            {action.label}
          </button>
        ))}
      </div>
    </>
  );
};
