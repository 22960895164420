import {
  useCashCommodity,
  useCreateBp,
  useCreateTp,
  useGetSystemCashAllAccountingLocations,
} from "@bbo/api/generator";
import { TransferCore, usePostTransferTransaction } from "@bbo/api/generatorNoFadcode";
import { DISCREPANCY_STATUS } from "@bbo/constants";
import { useTitle } from "@bbo/features";
import { dataFormation } from "@bbo/features/TpBp/BpTpHelpers";
import { BpTpProps, PeriodName, PeriodType } from "@bbo/interfaces/BpTp";
import { createBpTpDiscrepancy } from "@bbo/types/TransferTypes";
import { inputInPence } from "@bbo/utils/currencyFormatter";
import {
  createDiscreapancyForUnknownVariance,
  TRANSACTION_ITEM_ID,
} from "@bbo/utils/transferPayload";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useAppSelector } from "./useAppSelector";

type Status = "error" | "loading" | "success";

export const useCurrentBpTp = (periodName: PeriodName, periodType: PeriodType): BpTpProps => {
  const { setTitle } = useTitle();
  const [fromHistory] = useState<boolean>(false);
  const [fromDiscrepancyAccount] = useState<boolean>(false);
  const branchDetails = useAppSelector((root) => root.branchDetails);
  const { data: cashDeclarationsData, status: cashCommodityStatus } = useCashCommodity(periodType);
  const { data: systemCashData, status: systemCashStatus } =
    useGetSystemCashAllAccountingLocations();
  const { username, fadCode, smartid } = useAppSelector((root) => root.auth.authUserData);

  const {
    mutate: postCreateTp,
    isSuccess: isCreateTpSuccess,
    data: createTpResponse,
    error: isCreateTpError,
  } = useCreateTp();

  const {
    mutate: postCreateBp,
    isSuccess: isCreateBpSuccess,
    data: createBpResponse,
    error: isCreateBpError,
  } = useCreateBp();

  const {
    mutate: postBpTpTransaction,
    isSuccess: isBpTpTransactionSuccess,
    error: isBpTpTransactionError,
  } = usePostTransferTransaction();

  useEffect(() => {
    setTitle(periodName);
  });

  const status = useMemo<Status>(
    () =>
      cashCommodityStatus === "error" || systemCashStatus === "error"
        ? "error"
        : cashCommodityStatus === "loading" || systemCashStatus === "loading"
        ? "loading"
        : "success",
    [cashCommodityStatus, systemCashStatus],
  );

  const cashCommodity = useMemo(() => {
    if (status === "success") {
      return dataFormation(cashDeclarationsData, systemCashData);
    }
  }, [cashDeclarationsData, status, systemCashData]);
  const returnItemID = (varianceValue: number): string => {
    if (varianceValue > 0) {
      return TRANSACTION_ITEM_ID.TP_BP_BALANCING_SURPLUS; //50832
    }
    return TRANSACTION_ITEM_ID.TP_BP_BALANCING_SHORTAGE; //50833
  };

  const saveTpDiscrepancyRequestParams = useCallback(
    (varianceValue: number): createBpTpDiscrepancy => {
      return {
        declarationID: createTpResponse?.declarationID ?? createBpResponse?.declarationID,
        comment: "",
        entity: "",
        entityID: "",
        reason: {
          code: periodType,
          id: periodType,
          subReason: {
            filterReason: {},
          },
          title: periodType,
        },
        status: DISCREPANCY_STATUS.AWAITING_BRANCH_ACTION,
        varianceAmount: varianceValue / 100,
        userName: username,
        action: "Create",
        fadcode: fadCode,
        branchName: fadCode,
        userID: smartid,
        branchDetails: branchDetails,
      };
    },
    [
      branchDetails,
      createBpResponse?.declarationID,
      createTpResponse?.declarationID,
      fadCode,
      periodType,
      smartid,
      username,
    ],
  );

  const transactionPostBody: TransferCore = useMemo(() => {
    if (isCreateBpSuccess || isCreateTpSuccess) {
      const varianceValue = inputInPence(String(cashCommodity.varianceValue));
      return createDiscreapancyForUnknownVariance(
        saveTpDiscrepancyRequestParams(varianceValue),
        returnItemID(varianceValue),
      );
    }
  }, [
    isCreateBpSuccess,
    isCreateTpSuccess,
    cashCommodity?.varianceValue,
    saveTpDiscrepancyRequestParams,
  ]);

  return {
    cashCommodity,
    status,
    fromHistory,
    fromDiscrepancyAccount,
    postCreateBp,
    isCreateBpSuccess,
    postCreateTp,
    isCreateTpSuccess,
    createBpResponse,
    createTpResponse,
    transactionPostBody,
    postBpTpTransaction,
    isBpTpTransactionSuccess,
    isCreateTpError,
    isCreateBpError,
    isBpTpTransactionError,
  };
};
