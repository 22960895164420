import { logoutForgerock } from "@bbo/utils";
import { createAsyncThunk, createSlice, PayloadAction, SerializedError } from "@reduxjs/toolkit";
import { Auth } from "aws-amplify";

export type SysRole = {
  role: string;
  fadCode: string;
};

export type AuthSlice = {
  error?: SerializedError | null;
  loading?: boolean;
  isLoggedIn?: boolean;
  authUserData?: {
    username?: string;
    role?: string;
    fadCode?: string;
    sysRole?: SysRole[];
    smartid?: string;
  };
};

const initialState: AuthSlice = {
  error: null,
  loading: true,
  isLoggedIn: false,
  authUserData: {
    username: "",
    role: "",
    fadCode: "",
    sysRole: [],
  },
};

export const logoutFederatedUser = createAsyncThunk("auth/logoutFederatedUser", logoutForgerock);

const federatedSignInAsync = createAsyncThunk("auth/federatedSignInAsync", async () => {
  await Auth.federatedSignIn({
    customProvider: "forgerock",
  });
});

export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    setAuthState: (state, action: PayloadAction<AuthSlice>) => {
      Object.assign(state, action.payload);
    },
  },
  extraReducers: (builder) => {
    //85 - 97
    builder.addCase(federatedSignInAsync.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(federatedSignInAsync.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.isLoggedIn = true;
    });
    builder.addCase(federatedSignInAsync.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
    builder.addCase(logoutFederatedUser.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(logoutFederatedUser.fulfilled, (state) => {
      state.loading = false;
      state.error = null;
      state.isLoggedIn = false;
      state.authUserData = initialState.authUserData;
    });
    builder.addCase(logoutFederatedUser.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error;
    });
  },
});

export { federatedSignInAsync };

export const { setAuthState } = authSlice.actions;

export default authSlice.reducer;
