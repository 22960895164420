import { useEffect } from "react";

import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { ManageCashDrawer } from "@bbo/features/ManageCashDrawer";

const ManageCashDrawers = () => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Manage Cash Drawer");
  }, [setTitle]);

  return (
    <>
      <div className="containerMain">
        <div className="w-full items-start stableheight overflow-x-auto">
          <div className="px-4 py-10 md:p-18 mx-auto w-full">
            <div>
              <ManageCashDrawer />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ManageCashDrawers;
