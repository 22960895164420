import { BranchCashOverview } from "@bbo/api/generator";
import { formatCounterTerminalName } from "@bbo/features/BranchOverview/branchOverviewHelpers";
import { Dispatch, SetStateAction } from "react";

enum CONSTANTS {
  TRANS_BUTTON_ACTIVE = "transferButtonActive",
  COMMON_HEADER = "transferbutton heading",
  EMPTY = "",
}

export interface ListItemProps {
  entityName: string;
  entityID?: string;
  entity?: string;
  sequenceID?: string;
  associatedCounterTerminal?: string;
}

export interface ButtonCounterViewProps {
  otherAccountingLocations: BranchCashOverview[] | undefined;
  selectedOtherAccountingLocation: BranchCashOverview | null;
  setSelectedOtherAccountingLocation: Dispatch<SetStateAction<BranchCashOverview | null>>;
}

// Refactor into its own component
const renderTableRow = (
  otherAccountingLocation: BranchCashOverview,
  selectedOtherAccountingLocation: BranchCashOverview | null,
  setSelectedOtherAccountingLocation: Dispatch<SetStateAction<BranchCashOverview | null>>,
) => {
  const isActive =
    otherAccountingLocation?.accountingLocationID ===
    selectedOtherAccountingLocation?.accountingLocationID;

  const displayText = formatCounterTerminalName(otherAccountingLocation);

  const onClick = () => {
    setSelectedOtherAccountingLocation(otherAccountingLocation);
  };

  return (
    <tr
      key={otherAccountingLocation?.accountingLocationID}
      className={`${CONSTANTS.COMMON_HEADER} ${
        isActive ? CONSTANTS.TRANS_BUTTON_ACTIVE : CONSTANTS.EMPTY
      }`}
      onClick={onClick}>
      <td>{displayText}</td>
    </tr>
  );
};

const ButtonCounterView = ({
  otherAccountingLocations,
  selectedOtherAccountingLocation,
  setSelectedOtherAccountingLocation,
}: ButtonCounterViewProps) => (
  <>
    {otherAccountingLocations?.map((otherAccountingLocation) =>
      renderTableRow(
        otherAccountingLocation,
        selectedOtherAccountingLocation,
        setSelectedOtherAccountingLocation,
      ),
    )}
  </>
);

export default ButtonCounterView;
