//ADD,REMOVE,REPRINT, and all other flows will be handled here..

import { usePostTransferTransaction } from "@bbo/api/generatorNoFadcode/endpoints/transfer-ap-i";
import { DISCREPANCY_STATUS } from "@bbo/constants";
import { useGlobalState } from "@bbo/lib/state";
import { inputInPence } from "@bbo/utils/currencyFormatter";
import Warning from "../../assets/images/warningMsg.png";
import { useAppSelector } from "../../lib";
import { createPouchDiscrepancy } from "../../types/TransferTypes";
import { TRANSACTION_ITEM_ID, createPouchDiscrepancyParams } from "../../utils/transferPayload";
import "./index.css";

/**
 * Alert/Popup view with
 * @param props
 * @returns
 */

interface iProps {
  associatedEntityID?: string;
  associatedEntityName?: string;
  associatedEntityType?: string;
  entityName?: string;
  itemDetails?: any;
  onSuccess?: () => void;
  onCancel?: () => void;
  CommitFlag?: boolean;
  RequiredData?: any;
  valueOfVariance?: string;
}

const PopupConfirm = (props: iProps) => {
  const [KnownVarianceAPI] = useGlobalState("KnownVarianceAPI");
  const [valueOfVariance] = useGlobalState("valueOfVariance");
  const { username, fadCode, smartid } = useAppSelector((root) => root.auth.authUserData);
  const branchDetails = useAppSelector((root) => root.branchDetails);

  //requiredData.varianceType
  const isPouchTypevariance = (): boolean => {
    if (
      ["Cash Pouch Despatch", "Cash Pouch Acceptance"].includes(props?.RequiredData?.varianceType)
    ) {
      return true;
    } else return false;
  };
  const savePouchDicrepnacyRequestParams = (): createPouchDiscrepancy => {
    return {
      comment: "",
      entity: KnownVarianceAPI.Success?.entity,
      entityID: KnownVarianceAPI?.Success?.entityID,
      operationType: "knownDiscrepancyRaised",
      pouchNumber: isPouchTypevariance()
        ? valueOfVariance?.pouchNumber
        : valueOfVariance?.sessionID,
      branchReferenceNumber: valueOfVariance?.branchReferenceNumber,
      reason: {
        code: props?.RequiredData?.id,
        id: props?.RequiredData?.id,
        subReason: {
          filterReason: {},
        },
        title: props?.RequiredData?.varianceType,
      },
      status: DISCREPANCY_STATUS.HELP_REQUESTED,
      varianceAmount: inputInPence(props.valueOfVariance),
      userName: username,
      action: "Create",
      fadcode: fadCode,
      branchName: fadCode,
      userID: smartid,
      branchDetails: branchDetails,
    };
  };
  const { mutate: savePouchDiscrepancy, isSuccess } = usePostTransferTransaction();

  const returnItemID = (varianceType: string): string => {
    switch (varianceType) {
      case "Cash Pouch Despatch":
        if (inputInPence(props.valueOfVariance) > 0) {
          return TRANSACTION_ITEM_ID.POUCH_DESPATCH_SURPLUS; // 50817
        } else return TRANSACTION_ITEM_ID.POUCH_DESPATCH_SHORTAGE; // 50818
        break;
      case "Cash Pouch Acceptance":
        if (inputInPence(props.valueOfVariance) > 0) {
          return TRANSACTION_ITEM_ID.POUCH_ACCEPTANCE_SURPLUS; //  50811
        } else return TRANSACTION_ITEM_ID.POUCH_ACCEPTANCE_SHORTAGE; // 50812
        break;
      case "Banking Miss Key":
        if (inputInPence(props.valueOfVariance) > 0) {
          return TRANSACTION_ITEM_ID.BANKING_MISS_KEY_GAIN; //50824
        } else return TRANSACTION_ITEM_ID.BANKING_MISS_KEY_LOSS; //50823
        break;
      case "Debit and Credit Card Failure":
        return TRANSACTION_ITEM_ID.DEBIT_CREDIT_FAILURE_LOSS; //  50826
        break;
    }
  };
  const onSuccess = async () => {
    const itemID = returnItemID(props?.RequiredData?.varianceType);
    await savePouchDiscrepancy({
      data: createPouchDiscrepancyParams(savePouchDicrepnacyRequestParams(), itemID),
    });
    if (props.onSuccess) {
      props.onSuccess();
    }
  };

  const onCancel = () => {
    if (props.onCancel) {
      props.onCancel();
    }
  };

  return (
    props.CommitFlag && (
      <>
        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
          <div className="relative  popupBoxSize">
            <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4	popupBoxSize">
              <div className="relative mx-auto ">
                <div className=" w-full">
                  <img className="imageSize mt-4" src={Warning} alt="Warning" />
                </div>
              </div>
              <div className="flex p-4  items-center justify-center">
                <div>
                  <h2 className="text-blueGray-500 leading-relaxed font-bold text-color text-center mainheading mt-4">
                    Warning
                  </h2>
                </div>
              </div>
              <div className="flex ml-8 mb-4 items-center justify-between">
                <div className="subheadingPopup">
                  By completing this Known Variance Record Entry:
                  <ul className="popupLabel mx-6">
                    <li>
                      The variance of {valueOfVariance?.Variance} will be moved to the Branch
                      Discrepancy Account.
                    </li>
                    <li>This will be recorded as “{props?.RequiredData?.varianceType}”</li>
                  </ul>
                </div>
              </div>
              {/*footer*/}
              <div className="flex items-center justify-between mx-6 p-4  rounded-b">
                <button
                  className="inline-flex hover justify-center text-black font-bold p-2 rounded resetBtn mr-2 completeBtnPopup"
                  type="button"
                  value="ForceLogOff"
                  onClick={onCancel}
                  data-cy="ForceLogOff">
                  Cancel
                </button>

                <button
                  className="accounting-button ml-4 completePopupBtn"
                  type="button"
                  value="RePrint"
                  onClick={onSuccess}
                  data-cy="RePrint">
                  Complete Known Variance
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
      </>
    )
  );
};

export default PopupConfirm;
