/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  SystemCashListResponseResponse,
  BboCashManagementBadRequestResponse,
  UnauthorisedResponse,
  BboCashManagementForbiddenResponse,
  NotFoundResponse,
  FailureResponseResponse,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Returns list of system cash details of all accounting locations
 * @summary System cash value of all accounting locations
 */
export const useGetSystemCashAllAccountingLocationsHook = () => {
  const getSystemCashAllAccountingLocations = useCustomInstance<SystemCashListResponseResponse>();

  return () => {
    return getSystemCashAllAccountingLocations({
      url: `/BboCashManagementService/v1/system-cash/accounting-location`,
      method: "get",
    });
  };
};

export const getGetSystemCashAllAccountingLocationsQueryKey = () => [
  `/BboCashManagementService/v1/system-cash/accounting-location`,
];

export type GetSystemCashAllAccountingLocationsQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetSystemCashAllAccountingLocationsHook>>
>;
export type GetSystemCashAllAccountingLocationsQueryError = ErrorType<
  | BboCashManagementBadRequestResponse
  | UnauthorisedResponse
  | BboCashManagementForbiddenResponse
  | NotFoundResponse
  | FailureResponseResponse
>;

export const useGetSystemCashAllAccountingLocations = <
  TData = AsyncReturnType<ReturnType<typeof useGetSystemCashAllAccountingLocationsHook>>,
  TError = ErrorType<
    | BboCashManagementBadRequestResponse
    | UnauthorisedResponse
    | BboCashManagementForbiddenResponse
    | NotFoundResponse
    | FailureResponseResponse
  >,
>(options?: {
  query?: UseQueryOptions<
    AsyncReturnType<ReturnType<typeof useGetSystemCashAllAccountingLocationsHook>>,
    TError,
    TData
  >;
}): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetSystemCashAllAccountingLocationsQueryKey();

  const getSystemCashAllAccountingLocations = useGetSystemCashAllAccountingLocationsHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetSystemCashAllAccountingLocationsHook>>
  > = () => getSystemCashAllAccountingLocations();

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetSystemCashAllAccountingLocationsHook>>,
    TError,
    TData
  >(queryKey, queryFn, queryOptions);

  return {
    queryKey,
    ...query,
  };
};
