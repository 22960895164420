//ADD,REMOVE,REPRINT, and all other flows will be handled here..

import { accountingLocationTypeToText } from "@bbo/utils/accountingLocationHelper";
import { amountSignedPound, currencyStyle } from "@bbo/utils/currencyFormatter";
import { AccountingLocationModalConfig } from "./manageCashDrawerTypes";

/**
 * Alert/Popup view with
 * @param props
 * @returns
 */

export const AccountingLocationPopUp = ({
  accountingLocation,
  primaryButton,
  secondaryButton,
}: AccountingLocationModalConfig) => {
  const { accountingLocationName, accountingLocationType, totalValue, counterID, counterName } =
    accountingLocation;
  const accountingLocationTypeText = accountingLocationTypeToText(accountingLocationType);

  return (
    <>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-auto my-6 mx-auto max-w-xl popupmodel">
          <div className="border-0 shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none p-4	">
            <div className="flex p-4  items-center justify-start">
              <div>
                <h2 className="text-blueGray-500 text-lg leading-relaxed heading-5 font-bold text-color">
                  {counterName
                    ? `${counterName}/${accountingLocationName}`
                    : `Safe/${accountingLocationName}`}
                </h2>
              </div>
            </div>
            <div className="flex pl-4 items-center justify-between">
              <div>Balance</div>
              <div className={currencyStyle(totalValue)}>
                <b>{amountSignedPound(totalValue)}</b>
              </div>
            </div>
            {/*footer*/}
            <div className="flex items-center justify-between p-4  rounded-b">
              {counterID ? (
                <button
                  disabled
                  className="col-span-2 btn-disable branchOverviewButton font-bold heading mr-2"
                  type="button"
                  value="ForceLogOff"
                  onClick={secondaryButton.onClick}
                  data-cy="ForceLogOff">
                  {secondaryButton.label}
                </button>
              ) : null}
              <button
                className="col-span-2  branchOverviewButton font-bold heading"
                type="button"
                value="RePrint"
                onClick={primaryButton.onClick}
                data-cy="RePrint">
                {primaryButton.label}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="opacity-50 fixed inset-0 z-40 bg-black"></div>
    </>
  );
};
