import { Auth } from "aws-amplify";
import axios from "axios";

export const logoutForgerock = async () => {
  try {
    const user = await Auth.currentAuthenticatedUser();

    const idToken = await user?.signInUserSession?.idToken?.payload["custom:preferredMode"];
    const logoutConfig = {
      Method: "get",
      url: process.env.REACT_APP_FORGEROCK_LOGOUT,
      headers: {
        "Content-Type": "application/json",
        idToken,
        redirectURI: "https://www.postoffice.co.uk",
        "x-api-key": process.env.REACT_APP_FORGEROCK_API_KEY,
      },
    };
    await axios(logoutConfig);
    await Auth.signOut({ global: false });
    localStorage.clear();
    sessionStorage.clear();
  } catch (error) {
    console.log(error);
    await Auth.signOut({ global: false });
    localStorage.clear();
    sessionStorage.clear();
  }
};
