import { memo } from "react";
import "./index.css";

export interface Item {
  trackingId: string;
  carrierId: string;
  serviceName: string;
  status: string;
}

interface iProps {
  addItem: (item: Item) => void;
  itemSelected: Item[];
  despatchItemList: Item[];
}

const DespatchItemsSelection = (props: iProps) => {
  return (
    <div>
      <div className="flex mb-6">
        <div className="flex infoLayout">
          <div className="px-4 py-2">
            <div className="font-bold">Retrieve and check despatch items.</div>
            <div className="text-sm">De-select any items not ready for despatch</div>
          </div>
        </div>
      </div>
      <div className="h-80 overflow-y-auto">
        <div className="flex">
          <div className="leftText w-1/4">Carrier</div>
          <div className="leftText w-1/4">Barcode</div>
          <div className="leftText w-1/4">Service Name</div>
          <div className="leftText w-1/4">{props.itemSelected.length} Items ready for despatch</div>
        </div>
        <div className="overflow-y-auto">
          {props.despatchItemList.map((item, index) => {
            return (
              <div key={index} className="flex hover justify-start tabRow">
                <div className="w-1/4 flex items-center tableFont">
                  <div>{item.carrierId === "PF" ? "Parcelforce" : "Royal Mail"}</div>
                </div>
                <div className="w-1/4 flex items-center tableFont">
                  <div>{item.trackingId}</div>
                </div>
                <div className="w-1/4 flex items-center tableFont">
                  <div>{item.serviceName}</div>
                </div>
                <div className="w-1/4 flex items-center justify-center tableFont">
                  <input
                    data-testid="add-item"
                    type="checkbox"
                    checked={props.itemSelected.some(
                      (element) => element.trackingId === item.trackingId,
                    )}
                    onChange={() => props.addItem(item)}
                    className="inputRadioChecbox"
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default memo(DespatchItemsSelection);
