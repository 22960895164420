export const ConfirmationPopUpBody = () => {
  return (
    <div>
      <div className="leading-relaxed text-color px-12 amountHighLevel text-center">
        This product has been previously added within this count
        <br />
        Do you wish to adjust previously input quantity
      </div>
    </div>
  );
};
