import { Popup } from "@bbo/components/Popup";

interface IStockCountReviewWarningPopupProps {
  onConfirm: () => void;
}

export const StockCountReviewWarningPopup = ({ onConfirm }: IStockCountReviewWarningPopupProps) => {
  return (
    <Popup
      body={
        <div className="leading-relaxed mt-10 text-color px-12 amountHighLevel text-center">
          Unable to complete action. Please try again later.
        </div>
      }
      hideCrossBtn
      actions={[
        {
          name: "Ok",
          handleAction: onConfirm,
          style: "popUp-button red-button mt-8 flex-initial ml-4",
        },
      ]}
    />
  );
};
