import { createContext } from "react";

interface DynamoTableContextProps {
  maxSelections: number;
  allItemsSelected: boolean;
  selectedIndexes: number[];
  showCheckboxes: boolean;
  onSelectAll: () => void;
  onSelectChange: (index: number) => void;
  shouldDisableRowCheckbox: (item: unknown, index: number) => boolean;
}

export const DynamoTableContext = createContext<DynamoTableContextProps | null>(null);
