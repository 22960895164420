/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  useMutation,
  UseQueryOptions,
  UseMutationOptions,
  QueryFunction,
  MutationFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  CashTransferList,
  CashManagementApiResponse,
  GetListParams,
  CashMovementData,
  GetCashInformationParams,
  CashDrawerData,
  CashTransferData,
  CashManagementData,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get list Cash informatin.
 * @summary This endpoint is used to get Cash information based on branch_id
 */
export const useGetListHook = () => {
  const getList = useCustomInstance<CashTransferList>();

  return (branchId: string, operationType: string, params?: GetListParams) => {
    return getList({
      url: `/cash_management/cash-details/${branchId}/${operationType}`,
      method: "get",
      params,
    });
  };
};

export const getGetListQueryKey = (
  branchId: string,
  operationType: string,
  params?: GetListParams,
) => [`/cash_management/cash-details/${branchId}/${operationType}`, ...(params ? [params] : [])];

export type GetListQueryResult = NonNullable<AsyncReturnType<ReturnType<typeof useGetListHook>>>;
export type GetListQueryError = ErrorType<CashManagementApiResponse | void>;

export const useGetList = <
  TData = AsyncReturnType<ReturnType<typeof useGetListHook>>,
  TError = ErrorType<CashManagementApiResponse | void>,
>(
  branchId: string,
  operationType: string,
  params?: GetListParams,
  options?: {
    query?: UseQueryOptions<AsyncReturnType<ReturnType<typeof useGetListHook>>, TError, TData>;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetListQueryKey(branchId, operationType, params);

  const getList = useGetListHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useGetListHook>>> = () =>
    getList(branchId, operationType, params);

  const query = useQuery<AsyncReturnType<ReturnType<typeof useGetListHook>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!(branchId && operationType), ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};

/**
 * Get list Cash information.
 * @summary This endpoint is used to get Cash transaction based on entity_type
 */
export const useGetCashInformationHook = () => {
  const getCashInformation = useCustomInstance<CashMovementData>();

  return (branchId: string, params?: GetCashInformationParams) => {
    return getCashInformation({
      url: `/cash_management/cash-movement/${branchId}`,
      method: "get",
      params,
    });
  };
};

export const getGetCashInformationQueryKey = (
  branchId: string,
  params?: GetCashInformationParams,
) => [`/cash_management/cash-movement/${branchId}`, ...(params ? [params] : [])];

export type GetCashInformationQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetCashInformationHook>>
>;
export type GetCashInformationQueryError = ErrorType<CashManagementApiResponse | void>;

export const useGetCashInformation = <
  TData = AsyncReturnType<ReturnType<typeof useGetCashInformationHook>>,
  TError = ErrorType<CashManagementApiResponse | void>,
>(
  branchId: string,
  params?: GetCashInformationParams,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetCashInformationHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetCashInformationQueryKey(branchId, params);

  const getCashInformation = useGetCashInformationHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetCashInformationHook>>
  > = () => getCashInformation(branchId, params);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetCashInformationHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!branchId, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};

/**
 * transfer Cash from one location to another.
 * @summary This endpoint is used to transfer cash from one Cash information to another
 */
export const usePostCashTransferHook = () => {
  const postCashTransfer = useCustomInstance<CashDrawerData>();

  return (cashTransferData: CashTransferData) => {
    return postCashTransfer({
      url: `/cash_management/cashtransfer/transfer`,
      method: "post",
      headers: { "Content-Type": "application/json" },
      data: cashTransferData,
    });
  };
};

export type PostCashTransferMutationResult = NonNullable<
  AsyncReturnType<ReturnType<typeof usePostCashTransferHook>>
>;
export type PostCashTransferMutationBody = CashTransferData;
export type PostCashTransferMutationError = ErrorType<CashManagementApiResponse | void>;

export const usePostCashTransfer = <
  TError = ErrorType<CashManagementApiResponse | void>,
  TContext = unknown,
>(options?: {
  mutation?: UseMutationOptions<
    AsyncReturnType<ReturnType<typeof usePostCashTransferHook>>,
    TError,
    { data: CashTransferData },
    TContext
  >;
}) => {
  const { mutation: mutationOptions } = options || {};

  const postCashTransfer = usePostCashTransferHook();

  const mutationFn: MutationFunction<
    AsyncReturnType<ReturnType<typeof usePostCashTransferHook>>,
    { data: CashTransferData }
  > = (props) => {
    const { data } = props || {};

    return postCashTransfer(data);
  };

  return useMutation<
    AsyncReturnType<typeof postCashTransfer>,
    TError,
    { data: CashTransferData },
    TContext
  >(mutationFn, mutationOptions);
};
/**
 * Get list Cash information.
 * @summary This endpoint is used to get Cash information based on branch_id
 */
export const useGetCashTransactionDetailsHook = () => {
  const getCashTransactionDetails = useCustomInstance<CashManagementData>();

  return (branchId: string) => {
    return getCashTransactionDetails({
      url: `/cash_management/cash-details/${branchId}`,
      method: "get",
    });
  };
};

export const getGetCashTransactionDetailsQueryKey = (branchId: string) => [
  `/cash_management/cash-details/${branchId}`,
];

export type GetCashTransactionDetailsQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetCashTransactionDetailsHook>>
>;
export type GetCashTransactionDetailsQueryError = ErrorType<CashManagementApiResponse | void>;

export const useGetCashTransactionDetails = <
  TData = AsyncReturnType<ReturnType<typeof useGetCashTransactionDetailsHook>>,
  TError = ErrorType<CashManagementApiResponse | void>,
>(
  branchId: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetCashTransactionDetailsHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getGetCashTransactionDetailsQueryKey(branchId);

  const getCashTransactionDetails = useGetCashTransactionDetailsHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetCashTransactionDetailsHook>>
  > = () => getCashTransactionDetails(branchId);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetCashTransactionDetailsHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!branchId, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};
