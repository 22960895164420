import { useGlobalState } from "@bbo/lib/state";
import { useEffect, useState } from "react";
import "./index.css";
import PopupConfirm from "./PopupConfirm";
import RecordVarianceBtn from "./RecordVarianceBtn";
import { RequiredDetails } from "./RequiredDetails";
import { SystemGeneratedDetails } from "./SystemGeneratedDetails";
import { VarianceType } from "./VarianceType";

export const RecordKnownVariance = () => {
  const [isDisabled, setisDisabled] = useState<boolean>(false);
  const [Requiredflag, setRequiredflag] = useState<boolean>(false);
  const [CommitFlag, setCommitFlag] = useState<boolean>(false);

  const [resetCount, setResetCount] = useState<number>(0);
  const [requiredDetails, setRequiredDetails] = useGlobalState("requiredDetails");
  const [RecordVarianceDataList] = useGlobalState("RecordVarianceData");
  const [, setShowBtn] = useGlobalState("requiredDetailsShow");

  const [VarianceSelect, setVarinaceSelect] = useState<any>();
  const [RequiredData, setRequiredData] = useState<any>();
  const [valueSelect, setvalueSelect] = useState<any>();
  const [Show, setShow] = useState<boolean>(false);
  const [handleChangefunc, sethandleChangefunc] = useState<string>("");

  const [valueOfVariance, setValueOfVariance] = useState("");
  const onCancel = () => {
    setRequiredflag(false);
    setisDisabled(false);
    setShow(false);
    setvalueSelect("");
    sethandleChangefunc("");
  };

  const handleSelectValue = (params: { value: string }) => {
    onCancel();
    setShowBtn({ requiredDetailsShow: true });

    setisDisabled(true);
    if (VarianceSelect) {
      const newValue = VarianceSelect;
      for (let i = 0; i < VarianceSelect?.length; i++) {
        if (newValue[i].value) {
          newValue[i].value = "";
          newValue[i].error = false;
          newValue[i].errorMsg = "";
        }
      }
      setRequiredDetails([...newValue]);
      setResetCount(resetCount + 1);
    }

    if (params !== null) {
      setRequiredflag(true);
      setvalueSelect(undefined);
      const SelectData =
        Array.isArray(RecordVarianceDataList) &&
        RecordVarianceDataList.filter((item) => {
          if (item.id === params.value) {
            return item.requiredDetails;
          }
        });

      setVarinaceSelect(SelectData[0].requiredDetails);

      setRequiredData({
        id: params.value,
        varianceType: SelectData[0].varianceType,
      });
    }
  };

  const onCommit = (params: any) => {
    if (params && params.valueOfVariance) {
      setValueOfVariance(params.valueOfVariance);
    }
    setCommitFlag(!CommitFlag);
    sethandleChangefunc("");
  };

  const onShow = () => {
    setShow(true);
  };

  const onPopup = () => {
    VarianceSelect.map((item) => {
      item.value = "";
      item.error = false;
      item.errorMsg = "";
    });
    setvalueSelect("");
    setRequiredflag(false);
    setShow(false);
    setCommitFlag(!CommitFlag);
    setisDisabled(false);
  };

  useEffect(() => {
    return () => {
      setShow(false);
    };
  }, []);
  const handleChange = (label) => {
    sethandleChangefunc(label);
  };

  return (
    <>
      <div className="grid grid-cols-3 gap-4">
        <div className="ml-4">
          <VarianceType onSelect={handleSelectValue} value={valueSelect} isDisabled={isDisabled} />
        </div>
        <div>
          <RequiredDetails
            RequiredDisplay={Requiredflag}
            VarianceSelect={VarianceSelect}
            RequiredData={RequiredData}
            onCancel={onCancel}
            onShow={onShow}
            onCommit={onCommit}
            resetCount={resetCount}
            handleChangefunc={handleChangefunc}
          />
        </div>
        <div>
          <SystemGeneratedDetails Show={Show} />
        </div>

        <PopupConfirm
          CommitFlag={CommitFlag}
          onSuccess={onPopup}
          RequiredData={RequiredData}
          valueOfVariance={valueOfVariance}
          onCancel={() => setCommitFlag(!CommitFlag)}
        />
      </div>
      {Requiredflag && <RecordVarianceBtn handleChange={handleChange} />}
    </>
  );
};
