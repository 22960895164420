import { BP_TP_PERIOD_NAMES, BP_TP_PERIOD_TYPES } from "@bbo/constants";
import CurrentBpTp from "@bbo/features/TpBp/CurrentBpTp";

const CurrentTp = () => {
  const periodName = BP_TP_PERIOD_NAMES.TRADING_PERIOD;
  const periodType = BP_TP_PERIOD_TYPES.TP;
  return (
    <>
      <CurrentBpTp periodName={periodName} periodType={periodType} />
    </>
  );
};

export default CurrentTp;
