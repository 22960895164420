import { useGetPouchList } from "@bbo/api/generator";
import Accordion from "@bbo/components/Accordion";
import { ReceiptPrint } from "@bbo/components/PrintView";
import { DENOMINATION_LADDER } from "@bbo/features/AdhocBalance/ladderConstant";
import { useTransactionApi } from "@bbo/lib/hooks/useTransactionApi";
import { useGlobalState } from "@bbo/lib/state";
import { amountInPound, amountPound } from "@bbo/utils/currencyFormatter";
import { getOnlyDateTime } from "@bbo/utils/dateTimeFormatter";
import { useEffect, useState } from "react";
import { CashBalanceDenominationRow } from "./CashBalanceDenominationRow";
import "./index.css";
ReceiptPrint;

export interface CashDenominationRow {
  itemDenomination: string;
  itemQuantity: number;
  itemValue: number;
  itemId: string;
}

export default function CashBalanceDetailPopup(props) {
  const unUseNote = "Unusable Notes";
  const unUseCoin = "Unusable Coins";
  const [isPreparedActive, setIsPreparedActive] = useGlobalState("isPreparedActive");
  const [isSuspenedActive, setIsSuspenedActive] = useGlobalState("isSuspenedActive");
  const [isActivitiesActive, setIsActivitiesActive] = useGlobalState("isActivitiesActive");

  const [denominationLadder, setDenominationLadder] = useState<CashDenominationRow[]>([]);

  useEffect(() => {
    const copy = [...DENOMINATION_LADDER];
    const exampleArr = [];
    const keys = props?.cashItems ? Object.keys(props?.cashItems) : [];

    copy.forEach((itemId) => {
      if (keys.includes(itemId.toString())) {
        exampleArr.push({
          itemDenomination: props.cashItems[itemId].itemDenomination,
          itemQuantity: props.cashItems[itemId].itemQuantity,
          itemValue: props.cashItems[itemId].itemValue,
          itemId,
        });
      }
    });

    setDenominationLadder(exampleArr);
  }, [props?.cashItems]);

  const { data: pouchData } = useGetPouchList({
    fromDate: props.data?.lastDeclarationTimestamp,
    toDate: props.data?.timestamp,
  });

  const { status: isRecordLoaded, cashMovementHistoryData: cashMovementHistory } =
    useTransactionApi({
      fromDate: props.data?.lastDeclarationTimestamp,
      toDate: props.data?.timestamp,
    });

  const preparedPouches = (
    <table className="dynamictable w-full border-collapse mb-5">
      <thead>
        <tr className="h-14 text-white">
          <th className="text-left text-lg font-bold ">Pouch Type</th>
          <th className="text-left text-lg font-bold ">Pouch ID</th>
          <th className="text-left text-lg font-bold ">Value</th>
        </tr>
      </thead>
      <tbody>
        {pouchData?.data
          ?.filter((el) => el.status === "prepared")
          .map((item, index) => (
            <tr key={index}>
              <td>{item.pouchType}</td>
              <td>{item.pouchID}</td>
              <td>£{amountInPound(item.totalValue)}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );

  const suspendPouches = (
    <table className="dynamictable w-full border-collapse mb-5">
      <thead>
        <tr className="h-14 text-white">
          <th className="text-left text-lg font-bold">Pouch Type</th>
          <th className="text-left text-lg font-bold">Pouch ID</th>
          <th className="text-left text-lg font-bold">Value</th>
        </tr>
      </thead>
      <tbody>
        {pouchData?.data
          ?.filter((el) => el.status === "suspended")
          .map((item, index) => (
            <tr key={index}>
              <td>{item.pouchType}</td>
              <td>{item.pouchID}</td>
              <td>£{amountInPound(item.totalValue)}</td>
            </tr>
          ))}
      </tbody>
    </table>
  );
  const transferActivities = (
    <table className="dynamictable w-full border-collapse mb-5">
      <thead>
        <tr className="h-14 text-white">
          <th className="text-left text-lg font-bold">User Name</th>
          <th className="text-left text-lg font-bold">Activity</th>
          <th className="text-left text-lg font-bold">Value</th>
        </tr>
      </thead>
      <tbody>
        {cashMovementHistory?.map((item, index) => {
          if (!item.operationType.includes("Pouch")) {
            return (
              <tr key={index}>
                <td>{item.userName}</td>
                <td>{item.operationType}</td>
                <td>£{amountInPound(item.transferAmount)}</td>
              </tr>
            );
          }
        })}
      </tbody>
    </table>
  );

  const PreparedActivetoggle = () => {
    if (isPreparedActive.isPreparedActive === false) {
      setIsPreparedActive({
        isPreparedActive: true,
      });
    } else if (isPreparedActive.isPreparedActive === true) {
      setIsPreparedActive({
        isPreparedActive: true,
      });
    }
  };
  return (
    <>
      <div className="flex items-center justify-center rounded-b cashBalancePopup">
        {props.showPopup && (
          <table className="w-full BalanceTable mb-5">
            <tbody className="bg-white divide-y divide-gray-200">
              <tr>
                <td colSpan={2}>
                  <b className="text-color text-base">Accounting Location</b>
                </td>
                <td colSpan={3} className="text-color text-base">
                  {props.data.accountingLocationName}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <b className="text-color text-base">User Name</b>
                </td>
                <td colSpan={3} className="text-color text-base">
                  {props.data.updatedBy.userName}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <b className="text-color text-base">Smart ID</b>
                </td>
                <td colSpan={3} className="text-color text-base">
                  {props.data.updatedBy.smartID}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <b className="text-color text-base">Date & Time</b>
                </td>
                <td colSpan={3} className="text-color text-base">
                  {getOnlyDateTime(props.data.timestamp)}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <b className="text-color text-base">Variance</b>
                </td>
                <td colSpan={3} className="text-color text-base">
                  {amountPound(props.data.currentVariance)}
                </td>
              </tr>
              <tr>
                <td colSpan={2}>
                  <b className="text-color text-base">Status </b>
                </td>
                <td colSpan={3} className="text-color text-base">
                  {props.data.status}
                </td>
              </tr>
              <tr className="DenominationHead">
                <td>
                  <b>Denomination</b>
                </td>
                <td></td>
                <td>
                  <b>Quantity</b>
                </td>
                <td></td>
                <td>
                  <b>Value</b>
                </td>
              </tr>
              {denominationLadder?.map((item, index) => {
                return <CashBalanceDenominationRow item={item} key={index} index={index} />;
              })}
            </tbody>
          </table>
        )}
      </div>
      <Accordion
        title="Prepared Pouches in Safe"
        content={preparedPouches}
        feature={"adhocBalance"}
        onToggle={() => {
          PreparedActivetoggle();
        }}
      />
      <Accordion
        title="Suspend Pouches in Safe"
        content={suspendPouches}
        feature={"adhocBalance"}
        onToggle={() => {
          setIsSuspenedActive({
            isSuspenedActive: !isSuspenedActive,
          });
        }}
      />
      <Accordion
        title="Activities between Balances"
        content={transferActivities}
        feature={"adhocBalance"}
        onToggle={() => {
          setIsActivitiesActive({
            isActivitiesActive: !isActivitiesActive,
          });
        }}
      />
    </>
  );
}
