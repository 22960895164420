import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import TransferOutPage from "@bbo/features/TransferOut/TransferOutPage";
import { useEffect } from "react";
import "./Index.css";

const TransferOut = () => {
  const { setTitle, setIsBackButtonActive } = useTitle();

  useEffect(() => {
    setTitle("Cash Transfer Out");
  }, [setTitle, setIsBackButtonActive]);

  return <TransferOutPage />;
};

export default TransferOut;
