import { TransferInPage } from "@bbo/features/TransferIn";
import "./Index.css";
import { useTitle } from "@bbo/features";
import { useEffect } from "react";

export const TransferIn = () => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Cash Transfer In");
  }, [setTitle]);

  return <TransferInPage />;
};

export default TransferIn;
