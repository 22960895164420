import { TableHead, TableRow } from "@material-ui/core";
import Cell from "./Cell";
import { Column } from "./types";

interface ITableHeaderProps {
  cols: Column[];
}

const TableHeader = ({ cols }: ITableHeaderProps) => {
  return (
    <TableHead>
      <TableRow>
        {cols.map(({ field, name }, i) => (
          <Cell key={i} name={name} headClassName="header-cell"></Cell>
        ))}
      </TableRow>
    </TableHead>
  );
};

export default TableHeader;
