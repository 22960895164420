import { BRANCH_OVERVIEW_TABS, featureFlags, VARIANCE_SUMMARY } from "@bbo/constants";
import { useFeatureFlag } from "@bbo/lib/hooks/useFeatureFlag";
import { amountPound } from "@bbo/utils/currencyFormatter";
import { CashManagementBranchOverviewContext } from "./BranchOverviewProvider";
import { useContext } from "react";

const HeaderTabs = () => {
  const { handleSelectTab, selectedTab } = useContext(CashManagementBranchOverviewContext);
  const [branchVarianceFeatureFlag] = useFeatureFlag(featureFlags.shouldShowBranchVariance);
  const handleTabClick = (value) => {
    handleSelectTab({ name: value });
  };

  return (
    <div className="horizontal-tab-list">
      <ul>
        {BRANCH_OVERVIEW_TABS.map((value) => (
          <li
            key={value}
            className={`tab-listview${selectedTab === value ? " active" : ""}`}
            onClick={() => handleTabClick(value)}>
            {value}
          </li>
        ))}
      </ul>
      <ul>
        {branchVarianceFeatureFlag && (
          <li
            className={`tab-listview${selectedTab === VARIANCE_SUMMARY ? " active" : ""}`}
            onClick={() => handleTabClick(VARIANCE_SUMMARY)}>
            {VARIANCE_SUMMARY} ({amountPound(0)})
          </li>
        )}
      </ul>
    </div>
  );
};

export default HeaderTabs;
