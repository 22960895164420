/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  CashCommodityResponseResponse,
  BboDeclarationBadRequestResponse,
  UnauthorizedResponse,
  ForbiddenResponse,
  BboDeclarationFailureResponseResponse,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Get cash commodity with latest declarations, total variance, and progress value. This api will integrate to view cash commodity on Balance Period and Trading Period
 * @summary Get cash commodity with latest declarations, total variance, and progress value.
 */
export const useCashCommodityHook = () => {
  const cashCommodity = useCustomInstance<CashCommodityResponseResponse>();

  return (declarationType: "BP" | "TP") => {
    return cashCommodity({
      url: `/bm-declaration-service/v1/commodity/cash/${declarationType}`,
      method: "get",
    });
  };
};

export const getCashCommodityQueryKey = (declarationType: "BP" | "TP") => [
  `/bm-declaration-service/v1/commodity/cash/${declarationType}`,
];

export type CashCommodityQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useCashCommodityHook>>
>;
export type CashCommodityQueryError = ErrorType<
  | BboDeclarationBadRequestResponse
  | UnauthorizedResponse
  | ForbiddenResponse
  | BboDeclarationFailureResponseResponse
>;

export const useCashCommodity = <
  TData = AsyncReturnType<ReturnType<typeof useCashCommodityHook>>,
  TError = ErrorType<
    | BboDeclarationBadRequestResponse
    | UnauthorizedResponse
    | ForbiddenResponse
    | BboDeclarationFailureResponseResponse
  >,
>(
  declarationType: "BP" | "TP",
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useCashCommodityHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey = queryOptions?.queryKey ?? getCashCommodityQueryKey(declarationType);

  const cashCommodity = useCashCommodityHook();

  const queryFn: QueryFunction<AsyncReturnType<ReturnType<typeof useCashCommodityHook>>> = () =>
    cashCommodity(declarationType);

  const query = useQuery<AsyncReturnType<ReturnType<typeof useCashCommodityHook>>, TError, TData>(
    queryKey,
    queryFn,
    { enabled: !!declarationType, ...queryOptions },
  );

  return {
    queryKey,
    ...query,
  };
};
