import { hoursToMilliseconds, subDays, subMilliseconds } from "date-fns";

export const getDefaultFromDate = () => +subDays(+new Date(), 42);
export const getDefaultToDate = () => +new Date();

export const dateChunkSize = hoursToMilliseconds(24);

// First chunk is { to: toDate, from: toDate - dateChunkSize }
// Second chunk is { to: toDate - dateChunkSize, from: toDate - (dateChunkSize * 2) }
// And so on, until the fromDate is hit
export const getDateRangeForChunk = (fromDate: number, toDate: number, chunk: number) => {
  const chunkedToDate = +subMilliseconds(toDate, dateChunkSize * chunk);
  const chunkedFromDate = Math.max(+subMilliseconds(toDate, dateChunkSize * (chunk + 1)), fromDate);

  return {
    to: chunkedToDate,
    from: chunkedFromDate,
  };
};
