// Table.tsx
import { TableContext } from "@bbo/context";
import { useTableContext } from "@bbo/lib";
import { ReactNode } from "react";

interface TableProps {
  children: ReactNode;
  onComplete: () => void;
  onCancel: () => void;
}

export const CustomTable = ({ children, onComplete, onCancel }: TableProps) => {
  // handle complete, cancel, onChange (from Table.Data), etc. here

  return (
    <TableContext.Provider value={{ onComplete, onCancel }}>
      <table>{children}</table>
    </TableContext.Provider>
  );
};

interface HeaderProps {
  header?: string[];
}

const Header = ({ header }: HeaderProps) => {
  return (
    <tr>
      {header.map((item, index) => (
        <th key={index}>
          <b>{item}</b>
        </th>
      ))}
    </tr>
  );
};

interface DataProps<T> {
  tableData: T;
}

const Data = <T extends ReactNode>({ tableData }: DataProps<T>) => {
  return <tbody>{tableData}</tbody>;
};

const Buttons = () => {
  const { onComplete, onCancel } = useTableContext();

  return (
    <div>
      <button onClick={onComplete}>Complete</button>
      <button onClick={onCancel}>Cancel</button>
    </div>
  );
};

CustomTable.Header = Header;
CustomTable.Data = Data;
CustomTable.Buttons = Buttons;
