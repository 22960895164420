import { ForexAccountsContainer } from "@bbo/features/ForexBranchOverview/ForexAccountsContainer";
import { useEffect } from "react";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";

export function ForexOverview() {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Forex Management");
  }, [setTitle]);

  return (
    <>
      <div className="containerMain">
        <div className="w-full items-start transferOut-height overflow-x-auto">
          <div className="px-4 py-10 md:p-4 mx-auto w-full">
            <ForexAccountsContainer />
          </div>
        </div>
      </div>
    </>
  );
}
export default ForexOverview;
