import ButtonCounterView from "@bbo/screens/ButtonCounterView";
import { Dispatch, SetStateAction } from "react";
import Select from "react-select";
import { AccountingLocationGroups } from "../BranchOverview/useBranchOverview";
import {
  TransferInOutTab,
  formatCounterTerminalName,
} from "../BranchOverview/branchOverviewHelpers";
import { BranchCashOverview, BranchCashOverviewResponseResponse } from "@bbo/api/generator";

interface TransferAccountingLocationListProps {
  title: string;
  tabs: TransferInOutTab[];
  activeTab: keyof AccountingLocationGroups;
  setActiveTab: Dispatch<SetStateAction<keyof AccountingLocationGroups>>;
  otherAccountingLocationsGrouped: AccountingLocationGroups;
  selectedOtherAccountingLocation: BranchCashOverview | null;
  setSelectedOtherAccountingLocation: Dispatch<SetStateAction<BranchCashOverview | null>>;
  branchOverviewData: BranchCashOverviewResponseResponse;
  className?: string;
  listClassName?: string;
}

export const TransferAccountingLocationList = ({
  title,
  tabs,
  activeTab,
  setActiveTab,
  otherAccountingLocationsGrouped,
  selectedOtherAccountingLocation,
  setSelectedOtherAccountingLocation,
  branchOverviewData,
  className,
  listClassName,
}: TransferAccountingLocationListProps) => {
  return (
    <div className={`pt-6 ${className}`.trim()}>
      <h2 className="heading-3 font-bold mb-5 showDesktop">{title}</h2>
      <div
        className={`showDesktop transferOut-center-side overflow-y-auto ${listClassName}`.trim()}>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 pb-4 pr-4">
          {tabs?.map(({ label, value }) => (
            <button
              key={value}
              className={`${
                value === activeTab ? "transferIntabs" : "text-lg text-color font-semibold"
              }`}
              onClick={() => setActiveTab(value)}>
              {label}
            </button>
          ))}
        </div>
        <table className="w-full table-border">
          <tbody>
            <ButtonCounterView
              otherAccountingLocations={otherAccountingLocationsGrouped?.[activeTab]}
              selectedOtherAccountingLocation={selectedOtherAccountingLocation}
              setSelectedOtherAccountingLocation={setSelectedOtherAccountingLocation}
            />
          </tbody>
        </table>
      </div>
      <div className="grid grid-cols-1 lg:grid-cols-3 lg:hidden mb-5 mt-5 showMobile">
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-2 pb-4 pr-4">
          {tabs?.map(({ label, value }) => (
            <button
              key={value}
              className={`${
                value === activeTab ? "transferIntabs" : "text-lg text-color font-semibold"
              }`}
              onClick={() => setActiveTab(value)}>
              {label}
            </button>
          ))}
        </div>
        <div className="pl-4">
          <h2 className="heading-2 font-bold mb-2">{title}</h2>
        </div>
        <Select
          value={
            selectedOtherAccountingLocation
              ? {
                  value: selectedOtherAccountingLocation.accountingLocationID,
                  label: formatCounterTerminalName(selectedOtherAccountingLocation),
                }
              : null
          }
          options={otherAccountingLocationsGrouped?.[activeTab]?.map((otherAccountingLocation) => ({
            value: otherAccountingLocation.accountingLocationID,
            label: formatCounterTerminalName(otherAccountingLocation),
          }))}
          onChange={(newValue) => {
            const newItem = branchOverviewData?.find(
              (item) => item.accountingLocationID === newValue.value,
            );
            if (!newItem) return;
            setSelectedOtherAccountingLocation(newItem);
          }}
          closeMenuOnSelect={true}
        />
      </div>
    </div>
  );
};
