import { useRef, useState } from "react";
import { useDescendantContext } from "./useDescendantContext";
import { useLayout } from "./useLayout";

const randomId = () => Math.random().toString(36).substring(2, 11);

/**
 * Return index of the current item within its parent's list
 * @param {unknown} props - Props that will be exposed to the parent list
 */
export const useDescendant = (props: unknown) => {
  const [index, setIndex] = useState(-1);
  const descendantId = useRef<string>(null);
  const context = useDescendantContext();

  if (!descendantId.current) descendantId.current = randomId();

  useLayout(() => {
    setIndex(context?.get(descendantId.current, props));
  });

  return { index, context };
};
