import AlertDialog from "@bbo/components/Dialog";
import Pagination from "@bbo/components/table/pagination";
import { CurrencyCountFooter } from "@bbo/features/CurrencyCountReview/CurrencyCountFooter";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { BottomNavigation, Box } from "@material-ui/core";
import { CurrencyCountTable } from "..";
import CommitPopup from "../ConfirmPopup/CommitPopup";
import ConfirmPopup from "../ConfirmPopup/ConfirmPopup";
import { cols, currentPageSize, rows, totalSterlingValue, varianceType } from "../Constants";
import CurrencyCountReviewSubHeader from "../CurrencyCountReviewSubHeader/CurrencyCountReviewSubHeader";
import "./CurrencyCountReviewScreen.css";
import { useStyles } from "./CurrencyCountReviewScreenStyles";
import useCurrencyCountReviewScreenHook from "./useCurrencyCountReviewScreenHook";

interface currencyCountReviewData {
  currencyName: string;
  systemQuantity: string;
  enteredQuantity: string;
  currencyVariance: string;
  sterlingValue: string;
  adjustments: string;
  varianceType: string;
}
interface IType {
  Negative: string | any[];
  Positive: string | any[];
  Zero: string | any[];
}
export const CurrencyCountScreen = () => {
  const {
    tableData,
    currentPage,
    getTotalCountOfRows,
    onPaginationClick,
    pagetitle: pageSubTitle,
    currencyInfo,
    handlePopupClose,
    handleConfirmPopupClose,
    handleCancelPopupClose,
    handleClickOpen,
    open,
    confirmOpen,
  } = useCurrencyCountReviewScreenHook();
  const classes = useStyles();
  const { Positive, Negative } = tableData;
  let { Zero } = tableData;
  const { uncountedCurrencies } = useAppSelector((root) => root.countedCurrencies);

  const currencyCountReviewData = uncountedCurrencies.map((curr) => {
    return curr.currencyCountReviewData;
  });
  Zero = [...Zero, ...currencyCountReviewData];
  return (
    <>
      <div className={`w-full container-margin flex-1 ${classes.table}`}>
        <CurrencyCountReviewSubHeader
          PageSubTitle={pageSubTitle}
          totalSterlingValue={totalSterlingValue}
          countType={currencyInfo}
        />

        {(tableData as IType)?.Negative.length !== 0 && (
          <CurrencyCountTable
            heading={varianceType["Negative Variance"]}
            rows={Negative}
            columns={cols}></CurrencyCountTable>
        )}
        {(tableData as IType)?.Positive.length !== 0 && (
          <CurrencyCountTable
            heading={varianceType["Positive Variance"]}
            rows={Positive}
            columns={cols}></CurrencyCountTable>
        )}
        {(tableData as IType)?.Zero.length !== 0 && (
          <CurrencyCountTable
            heading={varianceType["Zero Variance"]}
            rows={Zero}
            columns={cols}></CurrencyCountTable>
        )}
      </div>
      <div>
        <AlertDialog
          className={classes.dialogbox}
          isVisible={open}
          handleClose={handlePopupClose}
          modalContent={<CommitPopup />}
          buttons={[
            {
              title: "Cancel",
              onPress: handleCancelPopupClose,
              btnStyle: "cancelBtnStyle",
            },
            {
              title: "Commit",
              onPress: handlePopupClose,
              btnStyle: "commitBtnStyle",
            },
          ]}
        />
      </div>
      <div>
        <AlertDialog
          className={classes.dialogbox}
          isVisible={confirmOpen}
          handleClose={handleConfirmPopupClose}
          modalContent={<ConfirmPopup />}
          buttons={[{ title: "OK", onPress: handleConfirmPopupClose, btnStyle: "okBtnStyle" }]}
        />
      </div>
      <Box>
        <BottomNavigation className={classes.btmNav}>
          <Pagination
            className="pagination-bar flex justify-center"
            onPageChange={onPaginationClick}
            totalCount={getTotalCountOfRows(rows)}
            currentPage={currentPage as number}
            pageSize={currentPageSize}></Pagination>
        </BottomNavigation>
      </Box>
      <div>
        <CurrencyCountFooter
          handleCancelCount={null}
          handleCommitCount={handleClickOpen}
          handleResetCount={null}
          handleSuspendCount={null}
        />
      </div>
    </>
  );
};
