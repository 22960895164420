import {
  DropdownValue,
  Filter,
  Filters,
  MultiDropdownFilter,
  SingleDropdownFilter,
} from "@bbo/components/DynamoTable/dynamoTableTypes";

export const getNestedData = (str: string, data: any) => {
  if (data[str] !== undefined) return data[str];
  const params = str.split(".");
  if (params.length === 1 || data[params[0]] === undefined) {
    return null;
  }
  return data[params[0]][params[1]] ? data[params[0]][params[1]] : null;
};

export const compareWithSensitivity = (
  string1: string,
  string2: string,
  caseSensitive: boolean | undefined,
) => {
  if (caseSensitive) {
    return string1 === string2;
  }

  return `${string1}`.toLowerCase() === `${string2}`.toLowerCase();
};

export const includesWithSensitivity = (
  stringArray: string[],
  string: string,
  caseSensitive: boolean | undefined,
) => stringArray.some((arrayValue) => compareWithSensitivity(arrayValue, string, caseSensitive));

export const doesMultiItemIncludeValue = (multiItem: DropdownValue, value: string) => {
  if (compareWithSensitivity(multiItem.value, value, multiItem.caseSensitive)) return true;

  if (!multiItem.additionalValues) return false;
  return includesWithSensitivity(multiItem.additionalValues, value, multiItem.caseSensitive);
};

export const filterSwitch = <T>(key: string, filterConfig: Filter<string>, filteredData: T[]) => {
  switch (filterConfig.variant) {
    case "freeText":
      if (filterConfig.value === null || filterConfig.value.toString().length === 0)
        return filteredData;
      if (typeof filterConfig.value === "number") {
        return filteredData.filter((data) => getNestedData(key, data) === filterConfig.value);
      }
      return filteredData.filter((data) =>
        getNestedData(key, data)
          .toLowerCase()
          .includes(filterConfig.value.toString().toLowerCase()),
      );
    case "singleDropdown": {
      const selected = filterConfig.values.find((value: DropdownValue) => value.selected);
      if (!selected) return filteredData;
      return filteredData.filter((data) =>
        doesMultiItemIncludeValue(selected, getNestedData(key, data)),
      );
    }
    case "multiDropdown": {
      const selectedValues = filterConfig.values.filter((value: DropdownValue) => value.selected);
      if (selectedValues.length === 0) return filteredData;

      return filteredData.filter((data) => {
        const nestedData = getNestedData(key, data);
        return selectedValues.some((value) => doesMultiItemIncludeValue(value, nestedData));
      });
    }
    case "fromTo": {
      if (!filterConfig.value || (!filterConfig.value.from && !filterConfig.value.to))
        return filteredData;
      const result = filteredData.filter((data) => {
        let value = getNestedData(key, data);
        const to = parseInt(filterConfig.value.to);
        const from = parseInt(filterConfig.value.from);

        const correctedFilterConfigValue =
          filterConfig.unit === "date"
            ? {
                from:
                  `${filterConfig.value.from}`.length === 13
                    ? Math.floor(Number(filterConfig.value.from) / 1000)
                    : filterConfig.value.from,
                to:
                  `${filterConfig.value.to}`.length === 13
                    ? Math.floor(Number(filterConfig.value.to) / 1000)
                    : filterConfig.value.to,
              }
            : filterConfig.value;

        if (filterConfig.unit === "currency") {
          value = value / 100;
        }
        if (!correctedFilterConfigValue.from) return value <= correctedFilterConfigValue.to;
        if (!correctedFilterConfigValue.to) return value >= correctedFilterConfigValue.from;
        return value >= correctedFilterConfigValue.from && value <= correctedFilterConfigValue.to;
      });
      return result;
    }

    default:
      return filteredData;
  }
};

export const getGeneratedValues = <TData>(allData, filters: Filters): Filters => {
  if (!allData) return filters;
  const result = { ...filters };
  const dropDowns: (
    | (SingleDropdownFilter & { key: string })
    | (MultiDropdownFilter & { key: string })
  )[] = [];
  for (const key in filters) {
    if (
      ["multiDropdown", "singleDropdown"].includes(filters[key].variant) &&
      (filters[key] as SingleDropdownFilter | MultiDropdownFilter).generateValues
    ) {
      dropDowns.push({ ...(filters[key] as SingleDropdownFilter | MultiDropdownFilter), key });
    }
  }
  dropDowns.forEach((dropDown) => {
    const filterValues =
      (filters[dropDown.key] as SingleDropdownFilter | MultiDropdownFilter).values || [];
    const values = filterValues || [];

    const valueString = values.length ? values.map((value) => value.value) : [];
    allData.forEach((data) => {
      if (!valueString.includes(getNestedData(dropDown.key, data))) {
        valueString.push(getNestedData(dropDown.key, data));
        values.push({
          value: getNestedData(dropDown.key, data),
          label: getNestedData(dropDown.key, data),
          selected: false,
        });
      }
    });

    const sortedValues = filters[dropDown.key].filterValuesSort
      ? values.sort(filters[dropDown.key].filterValuesSort)
      : values;

    (result[dropDown.key] as SingleDropdownFilter | MultiDropdownFilter).values = sortedValues;
  });
  return result;
};

export const clearFilters = <TFilters>(filters: TFilters): TFilters => {
  const clearedFilters = {} as TFilters;
  Object.keys(filters).forEach((key) => {
    switch (filters[key].variant) {
      case "freeText":
        clearedFilters[key] = { ...filters[key], value: "" };
        break;
      case "singleDropdown":
        clearedFilters[key] = {
          ...filters[key],
          values: filters[key].values.map((value) => {
            return { ...value, selected: false };
          }),
        };

        break;
      case "multiDropdown":
        clearedFilters[key] = {
          ...filters[key],
          values: filters[key].values.map((value) => {
            return { ...value, selected: false };
          }),
        };

        break;
      case "fromTo":
        clearedFilters[key] = { ...filters[key], value: { to: null, from: null } };
        break;

      default:
        clearedFilters[key] = { ...filters[key] };
        break;
    }
  });

  return clearedFilters;
};
