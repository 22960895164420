import { createContext, SetStateAction, SyntheticEvent } from "react";
import { MapRefProps } from "./descendantContext";

export interface TabContextProps {
  getId: (index: number, id?: string) => string;
  handleClick: (index: SetStateAction<number>) => (event: SyntheticEvent) => void;
  handleKeyboard: (index: number, context: { map: MapRefProps }) => (event: SyntheticEvent) => void;
  activeTabIndex: number;
}

export const TabContext = createContext<TabContextProps>(null);
