/* tslint:disable */
/* eslint-disable */
/**
 * branch_management_back_office
 * PO Branch Management
 *
 * The version of the OpenAPI document: 0.1.0
 * Contact: po.contact@postoffice.co.uk
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import globalAxios, { AxiosInstance, AxiosPromise, AxiosRequestConfig } from "axios";
import { Configuration } from "./configuration";
// Some imports not used depending on template conditions
// @ts-ignore
import {
  assertParamExists,
  createRequestFunction,
  DUMMY_BASE_URL,
  setSearchParams,
  toPathString,
} from "./common";
// @ts-ignore
import { getValueFromStorage } from "@bbo/utils/util";
import { BaseAPI, BASE_PATH, RequestArgs } from "./base";

const headerParams = () => ({
  "Content-Type": "application/json",
  Accept: "application/json",
  fadcode: getValueFromStorage("fadcode"),
});

/**
 * action list of accouting location
 * @export
 * @interface ActionList
 */
export interface ActionList {
  /**
   *
   * @type {string}
   * @memberof ActionList
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ActionList
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof ActionList
   */
  url?: string;
}
/**
 *
 * @export
 * @interface ApiResponse
 */
export interface ApiResponse {
  /**
   *
   * @type {string}
   * @memberof ApiResponse
   */
  message: string;
}
/**
 * association data
 * @export
 * @interface AssociationData
 */
export interface AssociationData {
  /**
   *
   * @type {string}
   * @memberof AssociationData
   */
  entity_type?: string;
  /**
   *
   * @type {string}
   * @memberof AssociationData
   */
  entityID?: string;
  /**
   *
   * @type {string}
   * @memberof AssociationData
   */
  branch_id?: string;
  /**
   *
   * @type {string}
   * @memberof AssociationData
   */
  operation_type?: string;
  /**
   *
   * @type {string}
   * @memberof AssociationData
   */
  associated_entity_type?: string;
  /**
   *
   * @type {string}
   * @memberof AssociationData
   */
  associated_entityID?: string;
  /**
   *
   * @type {string}
   * @memberof AssociationData
   */
  created_by?: string;
}
/**
 *
 * @export
 * @interface AtmDetails
 */
export interface AtmDetails {
  /**
   *
   * @type {number}
   * @memberof AtmDetails
   */
  last_variance: number;
  /**
   *
   * @type {number}
   * @memberof AtmDetails
   */
  to_date_variance: number;
  /**
   *
   * @type {number}
   * @memberof AtmDetails
   */
  last_balanced_date_time: number;
  /**
   *
   * @type {string}
   * @memberof AtmDetails
   */
  branch_id: string;
  /**
   *
   * @type {string}
   * @memberof AtmDetails
   */
  entityID: string;
  /**
   *
   * @type {string}
   * @memberof AtmDetails
   */
  entity: string;
  /**
   *
   * @type {number}
   * @memberof AtmDetails
   */
  last_balance: number;
  /**
   *
   * @type {string}
   * @memberof AtmDetails
   */
  entity_name: string;
  /**
   *
   * @type {number}
   * @memberof AtmDetails
   */
  transfer_amt?: number;
  /**
   *
   * @type {number}
   * @memberof AtmDetails
   */
  transfer_amt_date?: number;
  /**
   *
   * @type {string}
   * @memberof AtmDetails
   */
  smart_id?: string;
  /**
   *
   * @type {string}
   * @memberof AtmDetails
   */
  user_name?: string;
  /**
   *
   * @type {Array<ActionList>}
   * @memberof AtmDetails
   */
  action_list?: Array<ActionList>;
}
/**
 *
 * @export
 * @interface BranchBalancing
 */
export interface BranchBalancing {
  /**
   *
   * @type {string}
   * @memberof BranchBalancing
   */
  createdBySmartID: string;
  /**
   *
   * @type {string}
   * @memberof BranchBalancing
   */
  createdByUserName: string;
  /**
   *
   * @type {number}
   * @memberof BranchBalancing
   */
  dateTime: number;
  /**
   *
   * @type {string}
   * @memberof BranchBalancing
   */
  discrepancyID: string;
  /**
   *
   * @type {number}
   * @memberof BranchBalancing
   */
  varianceAmount: number;
  /**
   *
   * @type {string}
   * @memberof BranchBalancing
   */
  name: string;
  /**
   *
   * @type {string}
   * @memberof BranchBalancing
   */
  ID: string;
  /**
   *
   * @type {CashCommodity}
   * @memberof BranchBalancing
   */
  cash: CashCommodity;
}
/**
 * branch data
 * @export
 * @interface BranchDetails
 */
export interface BranchDetails {
  /**
   *
   * @type {string}
   * @memberof BranchDetails
   */
  branch_address?: string;
  /**
   *
   * @type {string}
   * @memberof BranchDetails
   */
  branch_name?: string;
  /**
   *
   * @type {string}
   * @memberof BranchDetails
   */
  branch_type?: string;
  /**
   *
   * @type {string}
   * @memberof BranchDetails
   */
  fadcode?: string;
}
/**
 * busniess logics
 * @export
 * @interface BusinessLogicDetails
 */
export interface BusinessLogicDetails {
  /**
   *
   * @type {string}
   * @memberof BusinessLogicDetails
   */
  branch_id: string;
  /**
   *
   * @type {string}
   * @memberof BusinessLogicDetails
   */
  entity: string;
  /**
   *
   * @type {string}
   * @memberof BusinessLogicDetails
   */
  entityID: string;
  /**
   *
   * @type {string}
   * @memberof BusinessLogicDetails
   */
  rule_type: string;
  /**
   *
   * @type {string}
   * @memberof BusinessLogicDetails
   */
  location_type: string;
  /**
   *
   * @type {number}
   * @memberof BusinessLogicDetails
   */
  permitted_limit: number;
  /**
   *
   * @type {number}
   * @memberof BusinessLogicDetails
   */
  maximum_limit: number;
}
/**
 *
 * @export
 * @interface CashCommodity
 */
export interface CashCommodity {
  /**
   *
   * @type {number}
   * @memberof CashCommodity
   */
  totalVariance: number;
  /**
   *
   * @type {Array<Entity>}
   * @memberof CashCommodity
   */
  accountingLocations: Array<Entity>;
}
/**
 * Cash Drawer details
 * @export
 * @interface CashDrawerData
 */
export interface CashDrawerData {
  /**
   *
   * @type {string}
   * @memberof CashDrawerData
   */
  associatedCounterTerminal: string;
  /**
   *
   * @type {string}
   * @memberof CashDrawerData
   */
  associatedCounterTerminal_id: string;
  /**
   *
   * @type {string}
   * @memberof CashDrawerData
   */
  smart_id: string;
  /**
   *
   * @type {string}
   * @memberof CashDrawerData
   */
  user_name: string;
  /**
   *
   * @type {string}
   * @memberof CashDrawerData
   */
  entity: string;
  /**
   *
   * @type {string}
   * @memberof CashDrawerData
   */
  entityID: string;
  /**
   *
   * @type {string}
   * @memberof CashDrawerData
   */
  branch_id: string;
  /**
   *
   * @type {string}
   * @memberof CashDrawerData
   */
  entity_name: string;
  /**
   *
   * @type {number}
   * @memberof CashDrawerData
   */
  sequence_no: number;
  /**
   *
   * @type {string}
   * @memberof CashDrawerData
   */
  operation_type: string;
  /**
   *
   * @type {string}
   * @memberof CashDrawerData
   */
  flow_type: string;
  /**
   *
   * @type {string}
   * @memberof CashDrawerData
   */
  entity_type: string;
  /**
   *
   * @type {ItemDetails}
   * @memberof CashDrawerData
   */
  item_details: ItemDetails;
  /**
   *
   * @type {Array<ActionList>}
   * @memberof CashDrawerData
   */
  action_list: Array<ActionList>;
}
/**
 * Cash Management details
 * @export
 * @interface CashManagementData
 */
export interface CashManagementData {
  /**
   *
   * @type {Array<CounterTerminalData>}
   * @memberof CashManagementData
   */
  counter_terminal: Array<CounterTerminalData>;
  /**
   *
   * @type {SafeDetails}
   * @memberof CashManagementData
   */
  safe: SafeDetails;
  /**
   *
   * @type {Array<CashDrawerData>}
   * @memberof CashManagementData
   */
  cash_drawer: Array<CashDrawerData>;
  /**
   *
   * @type {AtmDetails}
   * @memberof CashManagementData
   */
  atm: AtmDetails;
  /**
   *
   * @type {Array<string>}
   * @memberof CashManagementData
   */
  accounting_location: Array<string>;
}
/**
 * cash transfer data
 * @export
 * @interface CashTransferData
 */
export interface CashTransferData {
  /**
   *
   * @type {string}
   * @memberof CashTransferData
   */
  source_entity_type?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferData
   */
  source_entityID?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferData
   */
  destination_entity_type?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferData
   */
  destination_entityID?: string;
  /**
   *
   * @type {number}
   * @memberof CashTransferData
   */
  transfer_amount?: number;
  /**
   *
   * @type {string}
   * @memberof CashTransferData
   */
  branch_id?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferData
   */
  smart_id?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferData
   */
  user_name?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferData
   */
  operation_type?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferData
   */
  reason_code?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferData
   */
  comments?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferData
   */
  transfer_at?: string;
}
/**
 * cash transfer history data
 * @export
 * @interface CashTransferHistoryData
 */
export interface CashTransferHistoryData {
  /**
   *
   * @type {number}
   * @memberof CashTransferHistoryData
   */
  date_time?: number;
  /**
   *
   * @type {string}
   * @memberof CashTransferHistoryData
   */
  source_entity_type?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferHistoryData
   */
  source_entityID?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferHistoryData
   */
  source_entity_name?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferHistoryData
   */
  destination_entity_type?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferHistoryData
   */
  destination_entityID?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferHistoryData
   */
  destination_entity_name?: string;
  /**
   *
   * @type {number}
   * @memberof CashTransferHistoryData
   */
  transfer_amount?: number;
  /**
   *
   * @type {string}
   * @memberof CashTransferHistoryData
   */
  branch_id?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferHistoryData
   */
  smart_id?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferHistoryData
   */
  user_name?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferHistoryData
   */
  operation_type?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferHistoryData
   */
  transaction_id?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferHistoryData
   */
  transaction_type?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferHistoryData
   */
  transfer_at?: string;
  /**
   *
   * @type {string}
   * @memberof CashTransferHistoryData
   */
  entity_type?: string;
}
/**
 * cash transfer options list
 * @export
 * @interface CashTransferList
 */
export interface CashTransferList {
  /**
   *
   * @type {Array<CashDrawerData>}
   * @memberof CashTransferList
   */
  associated_cash_drawer: Array<CashDrawerData>;
  /**
   *
   * @type {Array<CashDrawerData>}
   * @memberof CashTransferList
   */
  unassociated_cash_drawer: Array<CashDrawerData>;
}
/**
 * Counter Terminal details
 * @export
 * @interface CounterTerminalData
 */
export interface CounterTerminalData {
  /**
   *
   * @type {string}
   * @memberof CounterTerminalData
   */
  associated_entityID: string;
  /**
   *
   * @type {string}
   * @memberof CounterTerminalData
   */
  associated_entity_type: string;
  /**
   *
   * @type {string}
   * @memberof CounterTerminalData
   */
  associated_entity_name: string;
  /**
   *
   * @type {string}
   * @memberof CounterTerminalData
   */
  entityID: string;
  /**
   *
   * @type {string}
   * @memberof CounterTerminalData
   */
  entity?: string;
  /**
   *
   * @type {string}
   * @memberof CounterTerminalData
   */
  branch_id: string;
  /**
   *
   * @type {string}
   * @memberof CounterTerminalData
   */
  entity_name: string;
  /**
   *
   * @type {number}
   * @memberof CounterTerminalData
   */
  last_variance: number;
  /**
   *
   * @type {number}
   * @memberof CounterTerminalData
   */
  due_variance: number;
  /**
   *
   * @type {number}
   * @memberof CounterTerminalData
   */
  last_balanced_date_time: number;
  /**
   *
   * @type {number}
   * @memberof CounterTerminalData
   */
  last_balance: number;
  /**
   *
   * @type {string}
   * @memberof CounterTerminalData
   */
  created_by: string;
  /**
   *
   * @type {ItemDetails}
   * @memberof CounterTerminalData
   */
  item_details: ItemDetails;
  /**
   *
   * @type {Array<ActionList>}
   * @memberof CounterTerminalData
   */
  action_list: Array<ActionList>;
}
/**
 * Despatchable Items
 * @export
 * @interface DespatchableData
 */
export interface DespatchableData {
  /**
   *
   * @type {string}
   * @memberof DespatchableData
   */
  branchId?: string;
  /**
   *
   * @type {string}
   * @memberof DespatchableData
   */
  counterId?: string;
  /**
   *
   * @type {string}
   * @memberof DespatchableData
   */
  clerkId?: string;
  /**
   *
   * @type {Array<ParcelsData>}
   * @memberof DespatchableData
   */
  parcels: Array<ParcelsData>;
}
/**
 * Despatchable Items
 * @export
 * @interface DespatchableItemsData
 */
export interface DespatchableItemsData {
  /**
   *
   * @type {Array<ParcelsData>}
   * @memberof DespatchableItemsData
   */
  parcels: Array<ParcelsData>;
}
/**
 *
 * @export
 * @interface DiscrepencyData
 */
export interface DiscrepencyData {
  /**
   *
   * @type {CashDrawerData}
   * @memberof DiscrepencyData
   */
  entity?: CashDrawerData;
  /**
   *
   * @type {ReasonCode}
   * @memberof DiscrepencyData
   */
  reason?: ReasonCode;
  /**
   *
   * @type {string}
   * @memberof DiscrepencyData
   */
  comments?: string;
  /**
   *
   * @type {number}
   * @memberof DiscrepencyData
   */
  discrepency_date_time?: number;
  /**
   *
   * @type {string}
   * @memberof DiscrepencyData
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof DiscrepencyData
   */
  discrepency_data_ID?: string;
}
/**
 *
 * @export
 * @interface DiscrepencyDetails
 */
export interface DiscrepencyDetails {
  /**
   *
   * @type {number}
   * @memberof DiscrepencyDetails
   */
  variance_balance?: number;
  /**
   *
   * @type {number}
   * @memberof DiscrepencyDetails
   */
  date_time?: number;
  /**
   *
   * @type {string}
   * @memberof DiscrepencyDetails
   */
  status?: string;
  /**
   *
   * @type {CashDrawerData}
   * @memberof DiscrepencyDetails
   */
  entity?: CashDrawerData;
  /**
   *
   * @type {ReasonCode}
   * @memberof DiscrepencyDetails
   */
  reason?: ReasonCode;
  /**
   *
   * @type {string}
   * @memberof DiscrepencyDetails
   */
  comments?: string;
}
/**
 *
 * @export
 * @interface DiscrepencyHistory
 */
export interface DiscrepencyHistory {
  /**
   *
   * @type {Array<DiscrepencyDetails>}
   * @memberof DiscrepencyHistory
   */
  waiting_branch_action?: Array<DiscrepencyDetails>;
  /**
   *
   * @type {Array<DiscrepencyDetails>}
   * @memberof DiscrepencyHistory
   */
  central_support?: Array<DiscrepencyDetails>;
  /**
   *
   * @type {Array<DiscrepencyDetails>}
   * @memberof DiscrepencyHistory
   */
  settled?: Array<DiscrepencyDetails>;
  /**
   *
   * @type {Array<DiscrepencyDetails>}
   * @memberof DiscrepencyHistory
   */
  history?: Array<DiscrepencyDetails>;
  /**
   *
   * @type {number}
   * @memberof DiscrepencyHistory
   */
  waited_variance_balance?: number;
  /**
   *
   * @type {number}
   * @memberof DiscrepencyHistory
   */
  central_support_variance_balance?: number;
  /**
   *
   * @type {number}
   * @memberof DiscrepencyHistory
   */
  variance_balance?: number;
}
/**
 *
 * @export
 * @interface Entity
 */
export interface Entity {
  /**
   *
   * @type {string}
   * @memberof Entity
   */
  entityName: string;
  /**
   *
   * @type {string}
   * @memberof Entity
   */
  entityID: string;
  /**
   *
   * @type {string}
   * @memberof Entity
   */
  entity: string;
  /**
   *
   * @type {number}
   * @memberof Entity
   */
  varianceAmount: number;
  /**
   *
   * @type {number}
   * @memberof Entity
   */
  dateTime: number;
}
/**
 * entity data
 * @export
 * @interface EntityData
 */
export interface EntityData {
  /**
   *
   * @type {ItemDetails}
   * @memberof EntityData
   */
  item_details: ItemDetails;
  /**
   *
   * @type {string}
   * @memberof EntityData
   */
  smart_id: string;
  /**
   *
   * @type {string}
   * @memberof EntityData
   */
  user_name: string;
  /**
   *
   * @type {string}
   * @memberof EntityData
   */
  entity: string;
  /**
   *
   * @type {string}
   * @memberof EntityData
   */
  entityID: string;
  /**
   *
   * @type {string}
   * @memberof EntityData
   */
  operation_type: string;
  /**
   *
   * @type {string}
   * @memberof EntityData
   */
  entity_type: string;
  /**
   *
   * @type {string}
   * @memberof EntityData
   */
  branch_entity_type_id_timestamp?: string;
  /**
   *
   * @type {string}
   * @memberof EntityData
   */
  entity_name?: string;
  /**
   *
   * @type {string}
   * @memberof EntityData
   */
  branch_id: string;
  /**
   *
   * @type {string}
   * @memberof EntityData
   */
  flow_type: string;
  /**
   *
   * @type {number}
   * @memberof EntityData
   */
  sequence_no: number;
}
/**
 * Entity Data with Pouch Status
 * @export
 * @interface EntityDataPouchStatus
 */
export interface EntityDataPouchStatus {
  /**
   *
   * @type {boolean}
   * @memberof EntityDataPouchStatus
   */
  is_suspended_pouch: boolean;
  /**
   *
   * @type {string}
   * @memberof EntityDataPouchStatus
   */
  suspended_by: string;
  /**
   *
   * @type {Array<EntityData>}
   * @memberof EntityDataPouchStatus
   */
  entity_data: Array<EntityData>;
}
/**
 * reason-code
 * @export
 * @interface FilteredReason
 */
export interface FilteredReason {
  /**
   *
   * @type {string}
   * @memberof FilteredReason
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof FilteredReason
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof FilteredReason
   */
  code?: string;
}
/**
 *
 * @export
 * @interface InternalServerError
 */
export interface InternalServerError {
  /**
   *
   * @type {string}
   * @memberof InternalServerError
   */
  message?: string;
}
/**
 * International Driving Permit data
 * @export
 * @interface InternationalDrivingPermit
 */
export interface InternationalDrivingPermit {
  /**
   *
   * @type {Array<Items>}
   * @memberof InternationalDrivingPermit
   */
  items?: Array<Items>;
  /**
   *
   * @type {number}
   * @memberof InternationalDrivingPermit
   */
  numberOfItems?: number;
}
/**
 * Item level details
 * @export
 * @interface ItemDetails
 */
export interface ItemDetails {
  /**
   * last balanced unixtimestamp for the entity
   * @type {number}
   * @memberof ItemDetails
   */
  last_balanced_date_time: number;
  /**
   *
   * @type {number}
   * @memberof ItemDetails
   */
  current_variance: number;
  /**
   *
   * @type {number}
   * @memberof ItemDetails
   */
  to_date_variance: number;
  /**
   *
   * @type {number}
   * @memberof ItemDetails
   */
  last_balance: number;
  /**
   *
   * @type {number}
   * @memberof ItemDetails
   */
  transfer_amt: number;
  /**
   *
   * @type {number}
   * @memberof ItemDetails
   */
  transfer_amt_date: number;
}
/**
 *
 * @export
 * @interface Items
 */
export interface Items {
  /**
   *
   * @type {string}
   * @memberof Items
   */
  item1?: string;
}
/**
 * Last Despatch Status
 * @export
 * @interface LastStatus
 */
export interface LastStatus {
  /**
   *
   * @type {boolean}
   * @memberof LastStatus
   */
  afterLastDispatch: boolean;
}
/**
 *
 * @export
 * @interface LogsDetails
 */
export interface LogsDetails {
  /**
   *
   * @type {string}
   * @memberof LogsDetails
   */
  fadcode?: string;
  /**
   *
   * @type {number}
   * @memberof LogsDetails
   */
  date_time?: number;
  /**
   *
   * @type {string}
   * @memberof LogsDetails
   */
  username?: string;
  /**
   *
   * @type {string}
   * @memberof LogsDetails
   */
  smart_id?: string;
  /**
   *
   * @type {string}
   * @memberof LogsDetails
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof LogsDetails
   */
  status_id?: string;
  /**
   *
   * @type {EntityData}
   * @memberof LogsDetails
   */
  entity?: EntityData;
}
/**
 * Menu
 * @export
 * @interface Menu
 */
export interface Menu {
  /**
   *
   * @type {string}
   * @memberof Menu
   */
  id: string;
  /**
   *
   * @type {string}
   * @memberof Menu
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof Menu
   */
  icon: string;
  /**
   *
   * @type {string}
   * @memberof Menu
   */
  link: string;
  /**
   *
   * @type {Array<SubMenu>}
   * @memberof Menu
   */
  sub_menu: Array<SubMenu>;
}
/**
 *
 * @export
 * @interface ModelError
 */
export interface ModelError {
  /**
   *
   * @type {string}
   * @memberof ModelError
   */
  message: string;
}
/**
 *
 * @export
 * @interface OnBoardingEntity
 */
export interface OnBoardingEntity {
  /**
   *
   * @type {string}
   * @memberof OnBoardingEntity
   */
  branch_id?: string;
  /**
   *
   * @type {string}
   * @memberof OnBoardingEntity
   */
  branch_name?: string;
  /**
   *
   * @type {string}
   * @memberof OnBoardingEntity
   */
  operation_type?: string;
  /**
   *
   * @type {string}
   * @memberof OnBoardingEntity
   */
  entity?: string;
  /**
   *
   * @type {string}
   * @memberof OnBoardingEntity
   */
  entityID?: string;
  /**
   *
   * @type {string}
   * @memberof OnBoardingEntity
   */
  entity_type?: string;
  /**
   *
   * @type {string}
   * @memberof OnBoardingEntity
   */
  entity_name?: string;
  /**
   *
   * @type {number}
   * @memberof OnBoardingEntity
   */
  on_board_date_time?: number;
  /**
   *
   * @type {number}
   * @memberof OnBoardingEntity
   */
  on_deactivate_date_time?: number;
  /**
   *
   * @type {number}
   * @memberof OnBoardingEntity
   */
  sequence_no?: number;
  /**
   *
   * @type {string}
   * @memberof OnBoardingEntity
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof OnBoardingEntity
   */
  associatedCounterTerminal?: string;
  /**
   *
   * @type {string}
   * @memberof OnBoardingEntity
   */
  associatedCounterTerminal_id?: string;
  /**
   *
   * @type {string}
   * @memberof OnBoardingEntity
   */
  associatedCounterTerminal_name?: string;
  /**
   *
   * @type {number}
   * @memberof OnBoardingEntity
   */
  balance?: number;
  /**
   *
   * @type {string}
   * @memberof OnBoardingEntity
   */
  on_boarded_by_username?: string;
  /**
   *
   * @type {string}
   * @memberof OnBoardingEntity
   */
  on_boarded_by_smart_id?: string;
  /**
   *
   * @type {string}
   * @memberof OnBoardingEntity
   */
  on_deactivate_by_username?: string;
  /**
   *
   * @type {string}
   * @memberof OnBoardingEntity
   */
  on_deactivated_by_smart_id?: string;
}
/**
 * Parcel Data
 * @export
 * @interface ParcelsData
 */
export interface ParcelsData {
  /**
   *
   * @type {string}
   * @memberof ParcelsData
   */
  trackingId: string;
  /**
   *
   * @type {string}
   * @memberof ParcelsData
   */
  carrierId: string;
  /**
   *
   * @type {string}
   * @memberof ParcelsData
   */
  serviceName: string;
  /**
   *
   * @type {boolean}
   * @memberof ParcelsData
   */
  afterLastDespatch?: boolean;
  /**
   *
   * @type {number}
   * @memberof ParcelsData
   */
  acceptanceDate?: number;
  /**
   *
   * @type {string}
   * @memberof ParcelsData
   */
  addressLine1?: string;
  /**
   *
   * @type {string}
   * @memberof ParcelsData
   */
  country?: string;
  /**
   *
   * @type {number}
   * @memberof ParcelsData
   */
  daysBeforeExpiry?: number;
  /**
   *
   * @type {string}
   * @memberof ParcelsData
   */
  postcode?: string;
  /**
   *
   * @type {boolean}
   * @memberof ParcelsData
   */
  preventCustomerPickup?: boolean;
  /**
   *
   * @type {string}
   * @memberof ParcelsData
   */
  status?: string;
}
/**
 * pouch data
 * @export
 * @interface PouchApiResponse
 */
export interface PouchApiResponse {
  /**
   *
   * @type {boolean}
   * @memberof PouchApiResponse
   */
  is_suspended_pouch: boolean;
  /**
   *
   * @type {string}
   * @memberof PouchApiResponse
   */
  suspended_by: string;
  /**
   *
   * @type {Array<PouchDataDTO>}
   * @memberof PouchApiResponse
   */
  pouch_details: Array<PouchDataDTO>;
}
/**
 *
 * @export
 * @interface PouchDataDTO
 */
export interface PouchDataDTO {
  /**
   *
   * @type {string}
   * @memberof PouchDataDTO
   */
  pouch_type_name?: string;
  /**
   *
   * @type {string}
   * @memberof PouchDataDTO
   */
  operation_type?: string;
  /**
   *
   * @type {string}
   * @memberof PouchDataDTO
   */
  assigned_branch_id?: string;
  /**
   *
   * @type {string}
   * @memberof PouchDataDTO
   */
  assigned_branch_name?: string;
  /**
   *
   * @type {string}
   * @memberof PouchDataDTO
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof PouchDataDTO
   */
  total_pouch_value?: number;
  /**
   *
   * @type {string}
   * @memberof PouchDataDTO
   */
  pouch_bag_type?: string;
  /**
   *
   * @type {boolean}
   * @memberof PouchDataDTO
   */
  is_branch_close?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PouchDataDTO
   */
  is_coin_pouch?: boolean;
  /**
   *
   * @type {number}
   * @memberof PouchDataDTO
   */
  date?: number;
  /**
   *
   * @type {number}
   * @memberof PouchDataDTO
   */
  date_of_preparation?: number;
  /**
   *
   * @type {string}
   * @memberof PouchDataDTO
   */
  preparation_txn_id?: string;
  /**
   *
   * @type {string}
   * @memberof PouchDataDTO
   */
  pouch_barcode?: string;
  /**
   *
   * @type {string}
   * @memberof PouchDataDTO
   */
  prepared_smart_id?: string;
  /**
   *
   * @type {string}
   * @memberof PouchDataDTO
   */
  prepared_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof PouchDataDTO
   */
  pouch_id?: string;
  /**
   *
   * @type {string}
   * @memberof PouchDataDTO
   */
  pouch_type?: string;
  /**
   *
   * @type {string}
   * @memberof PouchDataDTO
   */
  prepared_branch_id?: string;
  /**
   *
   * @type {string}
   * @memberof PouchDataDTO
   */
  prepared_branch_name?: string;
  /**
   *
   * @type {number}
   * @memberof PouchDataDTO
   */
  suspend_count?: number;
  /**
   *
   * @type {Array<PouchValue>}
   * @memberof PouchDataDTO
   */
  denomination_ladder?: Array<PouchValue>;
  /**
   *
   * @type {Array<ActionList>}
   * @memberof PouchDataDTO
   */
  function_list?: Array<ActionList>;
  /**
   *
   * @type {Array<PouchEditHistory>}
   * @memberof PouchDataDTO
   */
  pouch_edit_details?: Array<PouchEditHistory>;
}
/**
 *
 * @export
 * @interface PouchEditHistory
 */
export interface PouchEditHistory {
  /**
   *
   * @type {string}
   * @memberof PouchEditHistory
   */
  pouch_type_name?: string;
  /**
   *
   * @type {string}
   * @memberof PouchEditHistory
   */
  assigned_branch_id?: string;
  /**
   *
   * @type {string}
   * @memberof PouchEditHistory
   */
  assigned_branch_name?: string;
  /**
   *
   * @type {string}
   * @memberof PouchEditHistory
   */
  status?: string;
  /**
   *
   * @type {number}
   * @memberof PouchEditHistory
   */
  total_pouch_value?: number;
  /**
   *
   * @type {string}
   * @memberof PouchEditHistory
   */
  pouch_bag_type?: string;
  /**
   *
   * @type {boolean}
   * @memberof PouchEditHistory
   */
  is_branch_close?: boolean;
  /**
   *
   * @type {boolean}
   * @memberof PouchEditHistory
   */
  is_coin_pouch?: boolean;
  /**
   *
   * @type {number}
   * @memberof PouchEditHistory
   */
  date?: number;
  /**
   *
   * @type {number}
   * @memberof PouchEditHistory
   */
  date_of_preparation?: number;
  /**
   *
   * @type {string}
   * @memberof PouchEditHistory
   */
  preparation_txn_id?: string;
  /**
   *
   * @type {string}
   * @memberof PouchEditHistory
   */
  pouch_barcode?: string;
  /**
   *
   * @type {string}
   * @memberof PouchEditHistory
   */
  prepared_smart_id?: string;
  /**
   *
   * @type {string}
   * @memberof PouchEditHistory
   */
  prepared_user_id?: string;
  /**
   *
   * @type {string}
   * @memberof PouchEditHistory
   */
  pouch_id?: string;
  /**
   *
   * @type {string}
   * @memberof PouchEditHistory
   */
  pouch_type?: string;
  /**
   *
   * @type {string}
   * @memberof PouchEditHistory
   */
  prepared_branch_id?: string;
  /**
   *
   * @type {string}
   * @memberof PouchEditHistory
   */
  prepared_branch_name?: string;
  /**
   *
   * @type {number}
   * @memberof PouchEditHistory
   */
  suspend_count?: number;
  /**
   *
   * @type {Array<PouchValue>}
   * @memberof PouchEditHistory
   */
  denomination_ladder?: Array<PouchValue>;
}
/**
 *
 * @export
 * @interface PouchValue
 */
export interface PouchValue {
  /**
   *
   * @type {string}
   * @memberof PouchValue
   */
  denomination: string;
  /**
   *
   * @type {string}
   * @memberof PouchValue
   */
  bag: string;
  /**
   *
   * @type {string}
   * @memberof PouchValue
   */
  losse_coin: string;
  /**
   *
   * @type {number}
   * @memberof PouchValue
   */
  value: number;
  /**
   *
   * @type {number}
   * @memberof PouchValue
   */
  index: number;
}
/**
 * Stock Categories data
 * @export
 * @interface ProductDetails
 */
export interface ProductDetails {
  /**
   *
   * @type {Array<StockItem>}
   * @memberof ProductDetails
   */
  stockItems?: Array<StockItem>;
  /**
   *
   * @type {string}
   * @memberof ProductDetails
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof ProductDetails
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof ProductDetails
   */
  totalValue?: number;
  /**
   *
   * @type {number}
   * @memberof ProductDetails
   */
  value?: number;
  /**
   *
   * @type {number}
   * @memberof ProductDetails
   */
  productName?: number;
  /**
   *
   * @type {TransactionDetail}
   * @memberof ProductDetails
   */
  updatedBy?: TransactionDetail;
}
/**
 *
 * @export
 * @interface Products
 */
export interface Products {
  /**
   *
   * @type {string}
   * @memberof Products
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof Products
   */
  packSize?: string;
  /**
   *
   * @type {string}
   * @memberof Products
   */
  barcode?: string;
  /**
   *
   * @type {string}
   * @memberof Products
   */
  itemCode?: string;
  /**
   *
   * @type {string}
   * @memberof Products
   */
  eanCode?: string;
}
/**
 * reason-code
 * @export
 * @interface ReasonCode
 */
export interface ReasonCode {
  /**
   *
   * @type {string}
   * @memberof ReasonCode
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof ReasonCode
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof ReasonCode
   */
  code?: string;
  /**
   *
   * @type {SubReasonCode}
   * @memberof ReasonCode
   */
  sub_reason?: SubReasonCode;
}
/**
 * Safe items
 * @export
 * @interface SafeDetails
 */
export interface SafeDetails {
  /**
   * last balanced unixtimestamp for the entity
   * @type {number}
   * @memberof SafeDetails
   */
  last_balanced_date_time: number;
  /**
   *
   * @type {number}
   * @memberof SafeDetails
   */
  last_variance: number;
  /**
   *
   * @type {string}
   * @memberof SafeDetails
   */
  entity_name: string;
  /**
   *
   * @type {number}
   * @memberof SafeDetails
   */
  to_date_variance: number;
  /**
   *
   * @type {number}
   * @memberof SafeDetails
   */
  last_balance: number;
  /**
   *
   * @type {string}
   * @memberof SafeDetails
   */
  branch_id: string;
  /**
   *
   * @type {string}
   * @memberof SafeDetails
   */
  entityID: string;
  /**
   *
   * @type {string}
   * @memberof SafeDetails
   */
  entity: string;
  /**
   *
   * @type {Array<CashDrawerData>}
   * @memberof SafeDetails
   */
  cash_drawer: Array<CashDrawerData>;
  /**
   *
   * @type {number}
   * @memberof SafeDetails
   */
  transfer_amt: number;
  /**
   *
   * @type {number}
   * @memberof SafeDetails
   */
  transfer_amt_date: number;
  /**
   *
   * @type {Array<ActionList>}
   * @memberof SafeDetails
   */
  action_list?: Array<ActionList>;
}
/**
 * Stock Categories data
 * @export
 * @interface StockCategories
 */
export interface StockCategories {
  /**
   *
   * @type {InternationalDrivingPermit}
   * @memberof StockCategories
   */
  items?: InternationalDrivingPermit;
}
/**
 * Stock Categories data
 * @export
 * @interface StockCount
 */
export interface StockCount {
  /**
   *
   * @type {Array<StockCountList>}
   * @memberof StockCount
   */
  stockCount?: Array<StockCountList>;
}
/**
 *
 * @export
 * @interface StockCountList
 */
export interface StockCountList {
  /**
   *
   * @type {string}
   * @memberof StockCountList
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof StockCountList
   */
  name?: string;
  /**
   *
   * @type {boolean}
   * @memberof StockCountList
   */
  inProgress?: boolean;
  /**
   *
   * @type {Array<Products>}
   * @memberof StockCountList
   */
  products?: Array<Products>;
}
/**
 *
 * @export
 * @interface StockItem
 */
export interface StockItem {
  /**
   *
   * @type {number}
   * @memberof StockItem
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof StockItem
   */
  totalValue?: number;
  /**
   *
   * @type {number}
   * @memberof StockItem
   */
  value?: number;
  /**
   *
   * @type {string}
   * @memberof StockItem
   */
  locationAccount?: string;
  /**
   *
   * @type {string}
   * @memberof StockItem
   */
  locationAccountName?: string;
  /**
   *
   * @type {string}
   * @memberof StockItem
   */
  locationAccountID?: string;
}
/**
 *
 * @export
 * @interface StockItemAtAL
 */
export interface StockItemAtAL {
  /**
   *
   * @type {string}
   * @memberof StockItemAtAL
   */
  name?: string;
  /**
   *
   * @type {string}
   * @memberof StockItemAtAL
   */
  itemID?: string;
  /**
   *
   * @type {string}
   * @memberof StockItemAtAL
   */
  barcode?: string;
  /**
   *
   * @type {string}
   * @memberof StockItemAtAL
   */
  description?: string;
  /**
   *
   * @type {number}
   * @memberof StockItemAtAL
   */
  quantity?: number;
  /**
   *
   * @type {number}
   * @memberof StockItemAtAL
   */
  value?: number;
  /**
   *
   * @type {string}
   * @memberof StockItemAtAL
   */
  ean?: string;
  /**
   *
   * @type {string}
   * @memberof StockItemAtAL
   */
  state?: string;
  /**
   *
   * @type {string}
   * @memberof StockItemAtAL
   */
  countGroup?: string;
}
/**
 * sub-menu
 * @export
 * @interface SubMenu
 */
export interface SubMenu {
  /**
   *
   * @type {string}
   * @memberof SubMenu
   */
  sub_menu_id: string;
  /**
   *
   * @type {string}
   * @memberof SubMenu
   */
  title: string;
  /**
   *
   * @type {string}
   * @memberof SubMenu
   */
  link: string;
}
/**
 * reason-code
 * @export
 * @interface SubReasonCode
 */
export interface SubReasonCode {
  /**
   *
   * @type {string}
   * @memberof SubReasonCode
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof SubReasonCode
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof SubReasonCode
   */
  code?: string;
  /**
   *
   * @type {FilteredReason}
   * @memberof SubReasonCode
   */
  filter_reason?: FilteredReason;
}
/**
 * Task details
 * @export
 * @interface TaskData
 */
export interface TaskData {
  /**
   *
   * @type {string}
   * @memberof TaskData
   */
  task: string;
  /**
   *
   * @type {string}
   * @memberof TaskData
   */
  level: string;
  /**
   *
   * @type {string}
   * @memberof TaskData
   */
  trigger: string;
  /**
   *
   * @type {number}
   * @memberof TaskData
   */
  run_date_time: number;
  /**
   *
   * @type {number}
   * @memberof TaskData
   */
  completion_date_time: number;
  /**
   *
   * @type {string}
   * @memberof TaskData
   */
  branch_id: string;
  /**
   *
   * @type {string}
   * @memberof TaskData
   */
  user: string;
  /**
   *
   * @type {string}
   * @memberof TaskData
   */
  status: string;
  /**
   *
   * @type {string}
   * @memberof TaskData
   */
  entityID: string;
  /**
   *
   * @type {string}
   * @memberof TaskData
   */
  entity: string;
  /**
   *
   * @type {string}
   * @memberof TaskData
   */
  role: string;
  /**
   *
   * @type {string}
   * @memberof TaskData
   */
  task_id: string;
  /**
   *
   * @type {string}
   * @memberof TaskData
   */
  task_type: string;
  /**
   *
   * @type {string}
   * @memberof TaskData
   */
  entity_name: string;
}
/**
 *
 * @export
 * @interface TransactionDetail
 */
export interface TransactionDetail {
  /**
   *
   * @type {number}
   * @memberof TransactionDetail
   */
  transactionTime?: number;
  /**
   *
   * @type {string}
   * @memberof TransactionDetail
   */
  userID?: string;
  /**
   *
   * @type {string}
   * @memberof TransactionDetail
   */
  transactionID?: string;
}
/**
 *
 * @export
 * @interface VarianceAccountingDto
 */
export interface VarianceAccountingDto {
  /**
   *
   * @type {string}
   * @memberof VarianceAccountingDto
   */
  entityID?: string;
  /**
   *
   * @type {string}
   * @memberof VarianceAccountingDto
   */
  entity?: string;
  /**
   *
   * @type {string}
   * @memberof VarianceAccountingDto
   */
  branch_id?: string;
  /**
   *
   * @type {string}
   * @memberof VarianceAccountingDto
   */
  branch_name?: string;
  /**
   *
   * @type {ReasonCode}
   * @memberof VarianceAccountingDto
   */
  reason?: ReasonCode;
  /**
   *
   * @type {string}
   * @memberof VarianceAccountingDto
   */
  comments?: string;
}
/**
 *
 * @export
 * @interface VarianceDetails
 */
export interface VarianceDetails {
  /**
   *
   * @type {string}
   * @memberof VarianceDetails
   */
  entity_name?: string;
  /**
   *
   * @type {number}
   * @memberof VarianceDetails
   */
  to_date_variance?: number;
  /**
   *
   * @type {string}
   * @memberof VarianceDetails
   */
  entityID?: string;
  /**
   *
   * @type {string}
   * @memberof VarianceDetails
   */
  entity?: string;
  /**
   *
   * @type {string}
   * @memberof VarianceDetails
   */
  branch_id?: string;
  /**
   *
   * @type {string}
   * @memberof VarianceDetails
   */
  branch_name?: string;
  /**
   *
   * @type {string}
   * @memberof VarianceDetails
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof VarianceDetails
   */
  user_id?: string;
  /**
   *
   * @type {string}
   * @memberof VarianceDetails
   */
  smart_id?: string;
  /**
   *
   * @type {number}
   * @memberof VarianceDetails
   */
  last_balanced_date_time?: number;
  /**
   *
   * @type {ReasonCode}
   * @memberof VarianceDetails
   */
  reason?: ReasonCode;
  /**
   *
   * @type {string}
   * @memberof VarianceDetails
   */
  comment?: string;
}
/**
 *
 * @export
 * @interface VarianceFormFeilds
 */
export interface VarianceFormFeilds {
  /**
   *
   * @type {string}
   * @memberof VarianceFormFeilds
   */
  title?: string;
  /**
   *
   * @type {string}
   * @memberof VarianceFormFeilds
   */
  id?: string;
  /**
   *
   * @type {string}
   * @memberof VarianceFormFeilds
   */
  value?: string;
}
/**
 * variance report of branch
 * @export
 * @interface VarianceReport
 */
export interface VarianceReport {
  /**
   *
   * @type {string}
   * @memberof VarianceReport
   */
  label?: string;
  /**
   *
   * @type {string}
   * @memberof VarianceReport
   */
  value?: string;
}
/**
 * variance report fields of branch  variance
 * @export
 * @interface VarianceReportFields
 */
export interface VarianceReportFields {
  /**
   *
   * @type {string}
   * @memberof VarianceReportFields
   */
  variance_type?: string;
  /**
   *
   * @type {string}
   * @memberof VarianceReportFields
   */
  id?: string;
  /**
   *
   * @type {Array<VarianceFormFeilds>}
   * @memberof VarianceReportFields
   */
  required_details?: Array<VarianceFormFeilds>;
}
/**
 * variance report
 * @export
 * @interface VarianceReportResponse
 */
export interface VarianceReportResponse {
  /**
   *
   * @type {Array<VarianceReport>}
   * @memberof VarianceReportResponse
   */
  system_details?: Array<VarianceReport>;
  /**
   *
   * @type {string}
   * @memberof VarianceReportResponse
   */
  entityID?: string;
  /**
   *
   * @type {string}
   * @memberof VarianceReportResponse
   */
  entity?: string;
}

/**
 * AdhocBalancingAPIApi - axios parameter creator
 * @export
 */
export const AdhocBalancingAPIApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get entity_data.
     * @summary This endpoint is used to get entity data based on entity_type
     * @param {string} entityType
     * @param {string} [toDate]
     * @param {string} [fromDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityData: async (
      entityType: string,
      toDate?: string,
      fromDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'entityType' is not null or undefined
      assertParamExists("getEntityData", "entityType", entityType);
      const localVarPath = `/cash_management/adhoc-balances/{entity_type}`.replace(
        `{${"entity_type"}}`,
        encodeURIComponent(String(entityType)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      if (toDate !== undefined) {
        localVarQueryParameter["to_date"] = toDate;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["from_date"] = fromDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get entity_data.
     * @summary This endpoint is used to create and item post balancing updates
     * @param {string} branchId
     * @param {string} entity
     * @param {string} entityID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityDataByBranchId: async (
      branchId: string,
      entity: string,
      entityID: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'branchId' is not null or undefined
      assertParamExists("getEntityDataByBranchId", "branchId", branchId);
      // verify required parameter 'entity' is not null or undefined
      assertParamExists("getEntityDataByBranchId", "entity", entity);
      // verify required parameter 'entityID' is not null or undefined
      assertParamExists("getEntityDataByBranchId", "entityID", entityID);
      const localVarPath = `/cash_management/adhoc-balances/{branch_id}/{entity}/{entityID}`
        .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
        .replace(`{${"entity"}}`, encodeURIComponent(String(entity)))
        .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to item entry
     * @summary This endpoint is used to create and item post balancing updates
     * @param {string} entityUid
     * @param {EntityData} [entityData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postEntityData: async (
      entityUid: string,
      entityData?: EntityData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'entityUid' is not null or undefined
      assertParamExists("postEntityData", "entityUid", entityUid);
      const localVarPath = `/cash_management/adhoc-balances/entity/{entity_uid}`.replace(
        `{${"entity_uid"}}`,
        encodeURIComponent(String(entityUid)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = entityData;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * AdhocBalancingAPIApi - functional programming interface
 * @export
 */
export const AdhocBalancingAPIApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = AdhocBalancingAPIApiAxiosParamCreator(configuration);
  return {
    /**
     * Get entity_data.
     * @summary This endpoint is used to get entity data based on entity_type
     * @param {string} entityType
     * @param {string} [toDate]
     * @param {string} [fromDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEntityData(
      entityType: string,
      toDate?: string,
      fromDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EntityData>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityData(
        entityType,
        toDate,
        fromDate,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get entity_data.
     * @summary This endpoint is used to create and item post balancing updates
     * @param {string} branchId
     * @param {string} entity
     * @param {string} entityID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getEntityDataByBranchId(
      branchId: string,
      entity: string,
      entityID: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<EntityDataPouchStatus>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getEntityDataByBranchId(
        branchId,
        entity,
        entityID,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint is used to item entry
     * @summary This endpoint is used to create and item post balancing updates
     * @param {string} entityUid
     * @param {EntityData} [entityData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postEntityData(
      entityUid: string,
      entityData?: EntityData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postEntityData(
        entityUid,
        entityData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * AdhocBalancingAPIApi - factory interface
 * @export
 */
export const AdhocBalancingAPIApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = AdhocBalancingAPIApiFp(configuration);
  return {
    /**
     * Get entity_data.
     * @summary This endpoint is used to get entity data based on entity_type
     * @param {string} entityType
     * @param {string} [toDate]
     * @param {string} [fromDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityData(
      entityType: string,
      toDate?: string,
      fromDate?: string,
      options?: any,
    ): AxiosPromise<Array<EntityData>> {
      return localVarFp
        .getEntityData(entityType, toDate, fromDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get entity_data.
     * @summary This endpoint is used to create and item post balancing updates
     * @param {string} branchId
     * @param {string} entity
     * @param {string} entityID
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getEntityDataByBranchId(
      branchId: string,
      entity: string,
      entityID: string,
      options?: any,
    ): AxiosPromise<EntityDataPouchStatus> {
      return localVarFp
        .getEntityDataByBranchId(branchId, entity, entityID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to item entry
     * @summary This endpoint is used to create and item post balancing updates
     * @param {string} entityUid
     * @param {EntityData} [entityData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postEntityData(entityUid: string, entityData?: EntityData, options?: any): AxiosPromise<void> {
      return localVarFp
        .postEntityData(entityUid, entityData, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * AdhocBalancingAPIApi - object-oriented interface
 * @export
 * @class AdhocBalancingAPIApi
 * @extends {BaseAPI}
 */
export class AdhocBalancingAPIApi extends BaseAPI {
  /**
   * Get entity_data.
   * @summary This endpoint is used to get entity data based on entity_type
   * @param {string} entityType
   * @param {string} [toDate]
   * @param {string} [fromDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdhocBalancingAPIApi
   */
  public getEntityData(
    entityType: string,
    toDate?: string,
    fromDate?: string,
    options?: AxiosRequestConfig,
  ) {
    return AdhocBalancingAPIApiFp(this.configuration)
      .getEntityData(entityType, toDate, fromDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get entity_data.
   * @summary This endpoint is used to create and item post balancing updates
   * @param {string} branchId
   * @param {string} entity
   * @param {string} entityID
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdhocBalancingAPIApi
   */
  public getEntityDataByBranchId(
    branchId: string,
    entity: string,
    entityID: string,
    options?: AxiosRequestConfig,
  ) {
    return AdhocBalancingAPIApiFp(this.configuration)
      .getEntityDataByBranchId(branchId, entity, entityID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to item entry
   * @summary This endpoint is used to create and item post balancing updates
   * @param {string} entityUid
   * @param {EntityData} [entityData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof AdhocBalancingAPIApi
   */
  public postEntityData(entityUid: string, entityData?: EntityData, options?: AxiosRequestConfig) {
    return AdhocBalancingAPIApiFp(this.configuration)
      .postEntityData(entityUid, entityData, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BranchBalancingDetailsApi - axios parameter creator
 * @export
 */
export const BranchBalancingDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of BP/TP.
     * @summary get BP/TP list
     * @param {string} fadcode
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBPTPList: async (
      fadcode: string,
      fromDate?: number,
      toDate?: number,
      balancingId?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fadcode' is not null or undefined
      assertParamExists("getBPTPList", "fadcode", fadcode);
      const localVarPath = `/cash_management/branch-balance/{fadcode}`.replace(
        `{${"fadcode"}}`,
        encodeURIComponent(String(fadcode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }
      if (balancingId !== undefined) {
        localVarQueryParameter["balancingId"] = balancingId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BranchBalancingDetailsApi - functional programming interface
 * @export
 */
export const BranchBalancingDetailsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BranchBalancingDetailsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of BP/TP.
     * @summary get BP/TP list
     * @param {string} fadcode
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBPTPList(
      fadcode: string,
      fromDate?: number,
      toDate?: number,
      balancingId?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BranchBalancing>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBPTPList(
        fadcode,
        fromDate,
        toDate,
        balancingId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BranchBalancingDetailsApi - factory interface
 * @export
 */
export const BranchBalancingDetailsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BranchBalancingDetailsApiFp(configuration);
  return {
    /**
     * Get list of BP/TP.
     * @summary get BP/TP list
     * @param {string} fadcode
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBPTPList(
      fadcode: string,
      fromDate?: number,
      toDate?: number,
      options?: any,
    ): AxiosPromise<Array<BranchBalancing>> {
      return localVarFp
        .getBPTPList(fadcode, fromDate, toDate, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BranchBalancingDetailsApi - object-oriented interface
 * @export
 * @class BranchBalancingDetailsApi
 * @extends {BaseAPI}
 */
export class BranchBalancingDetailsApi extends BaseAPI {
  /**
   * Get list of BP/TP.
   * @summary get BP/TP list
   * @param {string} fadcode
   * @param {number} [fromDate]
   * @param {number} [toDate]
   * @param {string} balancingId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BranchBalancingDetailsApi
   */
  public getBPTPList(
    fadcode: string,
    fromDate?: number,
    toDate?: number,
    balancingId?: string,
    options?: AxiosRequestConfig,
  ) {
    return BranchBalancingDetailsApiFp(this.configuration)
      .getBPTPList(fadcode, fromDate, toDate, balancingId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BranchLogsApi - axios parameter creator
 * @export
 */
export const BranchLogsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * get logs details
     * @summary this endpoints is used to get logs
     * @param {string} fadcode
     * @param {string} entity
     * @param {string} entityID
     * @param {string} status
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogsDetails: async (
      fadcode: string,
      entity: string,
      entityID: string,
      status: string,
      fromDate?: number,
      toDate?: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fadcode' is not null or undefined
      assertParamExists("getLogsDetails", "fadcode", fadcode);
      // verify required parameter 'entity' is not null or undefined
      assertParamExists("getLogsDetails", "entity", entity);
      // verify required parameter 'entityID' is not null or undefined
      assertParamExists("getLogsDetails", "entityID", entityID);
      // verify required parameter 'status' is not null or undefined
      assertParamExists("getLogsDetails", "status", status);
      const localVarPath = `/adhoc_balances/log/{fadcode}/{entity}/{entityID}/{status}`
        .replace(`{${"fadcode"}}`, encodeURIComponent(String(fadcode)))
        .replace(`{${"entity"}}`, encodeURIComponent(String(entity)))
        .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
        .replace(`{${"status"}}`, encodeURIComponent(String(status)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      if (fromDate !== undefined) {
        localVarQueryParameter["fromDate"] = fromDate;
      }

      if (toDate !== undefined) {
        localVarQueryParameter["toDate"] = toDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary this endpoints is used to get logs
     * @param {string} fadcode
     * @param {LogsDetails} [logsDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLogsDetails: async (
      fadcode: string,
      logsDetails?: LogsDetails,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fadcode' is not null or undefined
      assertParamExists("postLogsDetails", "fadcode", fadcode);
      const localVarPath = `/adhoc_balances/log/{fadcode}`.replace(
        `{${"fadcode"}}`,
        encodeURIComponent(String(fadcode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = logsDetails;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BranchLogsApi - functional programming interface
 * @export
 */
export const BranchLogsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BranchLogsApiAxiosParamCreator(configuration);
  return {
    /**
     * get logs details
     * @summary this endpoints is used to get logs
     * @param {string} fadcode
     * @param {string} entity
     * @param {string} entityID
     * @param {string} status
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getLogsDetails(
      fadcode: string,
      entity: string,
      entityID: string,
      status: string,
      fromDate?: number,
      toDate?: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<LogsDetails>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getLogsDetails(
        fadcode,
        entity,
        entityID,
        status,
        fromDate,
        toDate,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary this endpoints is used to get logs
     * @param {string} fadcode
     * @param {LogsDetails} [logsDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postLogsDetails(
      fadcode: string,
      logsDetails?: LogsDetails,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postLogsDetails(
        fadcode,
        logsDetails,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BranchLogsApi - factory interface
 * @export
 */
export const BranchLogsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BranchLogsApiFp(configuration);
  return {
    /**
     * get logs details
     * @summary this endpoints is used to get logs
     * @param {string} fadcode
     * @param {string} entity
     * @param {string} entityID
     * @param {string} status
     * @param {number} [fromDate]
     * @param {number} [toDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getLogsDetails(
      fadcode: string,
      entity: string,
      entityID: string,
      status: string,
      fromDate?: number,
      toDate?: number,
      options?: any,
    ): AxiosPromise<Array<LogsDetails>> {
      return localVarFp
        .getLogsDetails(fadcode, entity, entityID, status, fromDate, toDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary this endpoints is used to get logs
     * @param {string} fadcode
     * @param {LogsDetails} [logsDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postLogsDetails(fadcode: string, logsDetails?: LogsDetails, options?: any): AxiosPromise<void> {
      return localVarFp
        .postLogsDetails(fadcode, logsDetails, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BranchLogsApi - object-oriented interface
 * @export
 * @class BranchLogsApi
 * @extends {BaseAPI}
 */
export class BranchLogsApi extends BaseAPI {
  /**
   * get logs details
   * @summary this endpoints is used to get logs
   * @param {string} fadcode
   * @param {string} entity
   * @param {string} entityID
   * @param {string} status
   * @param {number} [fromDate]
   * @param {number} [toDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BranchLogsApi
   */
  public getLogsDetails(
    fadcode: string,
    entity: string,
    entityID: string,
    status: string,
    fromDate?: number,
    toDate?: number,
    options?: AxiosRequestConfig,
  ) {
    return BranchLogsApiFp(this.configuration)
      .getLogsDetails(fadcode, entity, entityID, status, fromDate, toDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary this endpoints is used to get logs
   * @param {string} fadcode
   * @param {LogsDetails} [logsDetails]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BranchLogsApi
   */
  public postLogsDetails(fadcode: string, logsDetails?: LogsDetails, options?: AxiosRequestConfig) {
    return BranchLogsApiFp(this.configuration)
      .postLogsDetails(fadcode, logsDetails, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BranchManagementAPIApi - axios parameter creator
 * @export
 */
export const BranchManagementAPIApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * This endpoint is used to item entry
     * @summary Used for the On Boarding the Cash Drawers.
     * @param {string} entityType
     * @param {OnBoardingEntity} [onBoardingEntity]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOnboardEntity: async (
      entityType: string,
      onBoardingEntity?: OnBoardingEntity,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'entityType' is not null or undefined
      assertParamExists("postOnboardEntity", "entityType", entityType);
      const localVarPath = `/adhoc_balances/{entityType}`.replace(
        `{${"entityType"}}`,
        encodeURIComponent(String(entityType)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = onBoardingEntity;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to remove specific entity
     * @summary removing the cash drawer from the branch.
     * @param {string} entityType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeEntity: async (
      entityType: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'entityType' is not null or undefined
      assertParamExists("removeEntity", "entityType", entityType);
      const localVarPath = `/adhoc_balances/{entityType}`.replace(
        `{${"entityType"}}`,
        encodeURIComponent(String(entityType)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "DELETE", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BranchManagementAPIApi - functional programming interface
 * @export
 */
export const BranchManagementAPIApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BranchManagementAPIApiAxiosParamCreator(configuration);
  return {
    /**
     * This endpoint is used to item entry
     * @summary Used for the On Boarding the Cash Drawers.
     * @param {string} entityType
     * @param {OnBoardingEntity} [onBoardingEntity]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postOnboardEntity(
      entityType: string,
      onBoardingEntity?: OnBoardingEntity,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postOnboardEntity(
        entityType,
        onBoardingEntity,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint is used to remove specific entity
     * @summary removing the cash drawer from the branch.
     * @param {string} entityType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async removeEntity(
      entityType: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.removeEntity(entityType, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BranchManagementAPIApi - factory interface
 * @export
 */
export const BranchManagementAPIApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BranchManagementAPIApiFp(configuration);
  return {
    /**
     * This endpoint is used to item entry
     * @summary Used for the On Boarding the Cash Drawers.
     * @param {string} entityType
     * @param {OnBoardingEntity} [onBoardingEntity]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postOnboardEntity(
      entityType: string,
      onBoardingEntity?: OnBoardingEntity,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postOnboardEntity(entityType, onBoardingEntity, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to remove specific entity
     * @summary removing the cash drawer from the branch.
     * @param {string} entityType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    removeEntity(entityType: string, options?: any): AxiosPromise<void> {
      return localVarFp
        .removeEntity(entityType, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BranchManagementAPIApi - object-oriented interface
 * @export
 * @class BranchManagementAPIApi
 * @extends {BaseAPI}
 */
export class BranchManagementAPIApi extends BaseAPI {
  /**
   * This endpoint is used to item entry
   * @summary Used for the On Boarding the Cash Drawers.
   * @param {string} entityType
   * @param {OnBoardingEntity} [onBoardingEntity]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BranchManagementAPIApi
   */
  public postOnboardEntity(
    entityType: string,
    onBoardingEntity?: OnBoardingEntity,
    options?: AxiosRequestConfig,
  ) {
    return BranchManagementAPIApiFp(this.configuration)
      .postOnboardEntity(entityType, onBoardingEntity, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to remove specific entity
   * @summary removing the cash drawer from the branch.
   * @param {string} entityType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BranchManagementAPIApi
   */
  public removeEntity(entityType: string, options?: AxiosRequestConfig) {
    return BranchManagementAPIApiFp(this.configuration)
      .removeEntity(entityType, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * BusinessRulesApi - axios parameter creator
 * @export
 */
export const BusinessRulesApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get entity_data.
     * @summary This endpoint is used to create and item post balancing updates
     * @param {string} branchId
     * @param {string} [ruleType]
     * @param {string} [location]
     * @param {string} [entityID]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessRulesByBranchId: async (
      branchId: string,
      ruleType?: string,
      location?: string,
      entityID?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'branchId' is not null or undefined
      assertParamExists("getBusinessRulesByBranchId", "branchId", branchId);
      const localVarPath = `/branchmanagement/businessrules/getrules/{branch_id}`.replace(
        `{${"branch_id"}}`,
        encodeURIComponent(String(branchId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      if (ruleType !== undefined) {
        localVarQueryParameter["rule_type"] = ruleType;
      }

      if (location !== undefined) {
        localVarQueryParameter["location"] = location;
      }

      if (entityID !== undefined) {
        localVarQueryParameter["entityID"] = entityID;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * BusinessRulesApi - functional programming interface
 * @export
 */
export const BusinessRulesApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = BusinessRulesApiAxiosParamCreator(configuration);
  return {
    /**
     * Get entity_data.
     * @summary This endpoint is used to create and item post balancing updates
     * @param {string} branchId
     * @param {string} [ruleType]
     * @param {string} [location]
     * @param {string} [entityID]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBusinessRulesByBranchId(
      branchId: string,
      ruleType?: string,
      location?: string,
      entityID?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<BusinessLogicDetails>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBusinessRulesByBranchId(
        branchId,
        ruleType,
        location,
        entityID,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * BusinessRulesApi - factory interface
 * @export
 */
export const BusinessRulesApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = BusinessRulesApiFp(configuration);
  return {
    /**
     * Get entity_data.
     * @summary This endpoint is used to create and item post balancing updates
     * @param {string} branchId
     * @param {string} [ruleType]
     * @param {string} [location]
     * @param {string} [entityID]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBusinessRulesByBranchId(
      branchId: string,
      ruleType?: string,
      location?: string,
      entityID?: string,
      options?: any,
    ): AxiosPromise<Array<BusinessLogicDetails>> {
      return localVarFp
        .getBusinessRulesByBranchId(branchId, ruleType, location, entityID, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * BusinessRulesApi - object-oriented interface
 * @export
 * @class BusinessRulesApi
 * @extends {BaseAPI}
 */
export class BusinessRulesApi extends BaseAPI {
  /**
   * Get entity_data.
   * @summary This endpoint is used to create and item post balancing updates
   * @param {string} branchId
   * @param {string} [ruleType]
   * @param {string} [location]
   * @param {string} [entityID]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof BusinessRulesApi
   */
  public getBusinessRulesByBranchId(
    branchId: string,
    ruleType?: string,
    location?: string,
    entityID?: string,
    options?: AxiosRequestConfig,
  ) {
    return BusinessRulesApiFp(this.configuration)
      .getBusinessRulesByBranchId(branchId, ruleType, location, entityID, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * CashManagementApiApi - axios parameter creator
 * @export
 */
export const CashManagementApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list Cash information.
     * @summary This endpoint is used to get Cash transaction based on entity_type
     * @param {string} branchId
     * @param {string} [toDate]
     * @param {string} [fromDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCashInformation: async (
      branchId: string,
      toDate?: string,
      fromDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'branchId' is not null or undefined
      assertParamExists("getCashInformation", "branchId", branchId);
      const localVarPath = `/cash_management/cash-movement/{branch_id}`.replace(
        `{${"branch_id"}}`,
        encodeURIComponent(String(branchId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      if (toDate !== undefined) {
        localVarQueryParameter["to_date"] = toDate;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["from_date"] = fromDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list Cash information.
     * @summary This endpoint is used to get Cash information based on branch_id
     * @param {string} branchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCashTransactionDetails: async (
      branchId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'branchId' is not null or undefined
      assertParamExists("getCashTransactionDetails", "branchId", branchId);
      const localVarPath = `/cash_management/cash-details/{branch_id}`.replace(
        `{${"branch_id"}}`,
        encodeURIComponent(String(branchId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list Cash informatin.
     * @summary This endpoint is used to get Cash information based on branch_id
     * @param {string} branchId
     * @param {string} operationType
     * @param {string} [entityID]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList: async (
      branchId: string,
      operationType: string,
      entityID?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'branchId' is not null or undefined
      assertParamExists("getList", "branchId", branchId);
      // verify required parameter 'operationType' is not null or undefined
      assertParamExists("getList", "operationType", operationType);
      const localVarPath = `/cash_management/cash-details/{branch_id}/{operation_type}`
        .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
        .replace(`{${"operation_type"}}`, encodeURIComponent(String(operationType)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      if (entityID !== undefined) {
        localVarQueryParameter["entityID"] = entityID;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * transfer Cash from one location to another.
     * @summary This endpoint is used to transfer cash from one Cash information to another
     * @param {CashTransferData} [cashTransferData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postCashTransfer: async (
      cashTransferData?: CashTransferData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cash_management/cashtransfer/transfer`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = cashTransferData;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * CashManagementApiApi - functional programming interface
 * @export
 */
export const CashManagementApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = CashManagementApiApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list Cash information.
     * @summary This endpoint is used to get Cash transaction based on entity_type
     * @param {string} branchId
     * @param {string} [toDate]
     * @param {string} [fromDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCashInformation(
      branchId: string,
      toDate?: string,
      fromDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CashTransferHistoryData>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCashInformation(
        branchId,
        toDate,
        fromDate,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list Cash information.
     * @summary This endpoint is used to get Cash information based on branch_id
     * @param {string} branchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getCashTransactionDetails(
      branchId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CashManagementData>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getCashTransactionDetails(
        branchId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list Cash informatin.
     * @summary This endpoint is used to get Cash information based on branch_id
     * @param {string} branchId
     * @param {string} operationType
     * @param {string} [entityID]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getList(
      branchId: string,
      operationType: string,
      entityID?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CashTransferList>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getList(
        branchId,
        operationType,
        entityID,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * transfer Cash from one location to another.
     * @summary This endpoint is used to transfer cash from one Cash information to another
     * @param {CashTransferData} [cashTransferData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postCashTransfer(
      cashTransferData?: CashTransferData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<CashDrawerData>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postCashTransfer(
        cashTransferData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * CashManagementApiApi - factory interface
 * @export
 */
export const CashManagementApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = CashManagementApiApiFp(configuration);
  return {
    /**
     * Get list Cash information.
     * @summary This endpoint is used to get Cash transaction based on entity_type
     * @param {string} branchId
     * @param {string} [toDate]
     * @param {string} [fromDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCashInformation(
      branchId: string,
      toDate?: string,
      fromDate?: string,
      options?: any,
    ): AxiosPromise<Array<CashTransferHistoryData>> {
      return localVarFp
        .getCashInformation(branchId, toDate, fromDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list Cash information.
     * @summary This endpoint is used to get Cash information based on branch_id
     * @param {string} branchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getCashTransactionDetails(branchId: string, options?: any): AxiosPromise<CashManagementData> {
      return localVarFp
        .getCashTransactionDetails(branchId, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list Cash informatin.
     * @summary This endpoint is used to get Cash information based on branch_id
     * @param {string} branchId
     * @param {string} operationType
     * @param {string} [entityID]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getList(
      branchId: string,
      operationType: string,
      entityID?: string,
      options?: any,
    ): AxiosPromise<CashTransferList> {
      return localVarFp
        .getList(branchId, operationType, entityID, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * transfer Cash from one location to another.
     * @summary This endpoint is used to transfer cash from one Cash information to another
     * @param {CashTransferData} [cashTransferData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postCashTransfer(
      cashTransferData?: CashTransferData,
      options?: any,
    ): AxiosPromise<CashDrawerData> {
      return localVarFp
        .postCashTransfer(cashTransferData, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * CashManagementApiApi - object-oriented interface
 * @export
 * @class CashManagementApiApi
 * @extends {BaseAPI}
 */
export class CashManagementApiApi extends BaseAPI {
  /**
   * Get list Cash information.
   * @summary This endpoint is used to get Cash transaction based on entity_type
   * @param {string} branchId
   * @param {string} [toDate]
   * @param {string} [fromDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CashManagementApiApi
   */
  public getCashInformation(
    branchId: string,
    toDate?: string,
    fromDate?: string,
    options?: AxiosRequestConfig,
  ) {
    return CashManagementApiApiFp(this.configuration)
      .getCashInformation(branchId, toDate, fromDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list Cash information.
   * @summary This endpoint is used to get Cash information based on branch_id
   * @param {string} branchId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CashManagementApiApi
   */
  public getCashTransactionDetails(branchId: string, options?: AxiosRequestConfig) {
    return CashManagementApiApiFp(this.configuration)
      .getCashTransactionDetails(branchId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list Cash informatin.
   * @summary This endpoint is used to get Cash information based on branch_id
   * @param {string} branchId
   * @param {string} operationType
   * @param {string} [entityID]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CashManagementApiApi
   */
  public getList(
    branchId: string,
    operationType: string,
    entityID?: string,
    options?: AxiosRequestConfig,
  ) {
    return CashManagementApiApiFp(this.configuration)
      .getList(branchId, operationType, entityID, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * transfer Cash from one location to another.
   * @summary This endpoint is used to transfer cash from one Cash information to another
   * @param {CashTransferData} [cashTransferData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof CashManagementApiApi
   */
  public postCashTransfer(cashTransferData?: CashTransferData, options?: AxiosRequestConfig) {
    return CashManagementApiApiFp(this.configuration)
      .postCashTransfer(cashTransferData, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DespatchApiApi - axios parameter creator
 * @export
 */
export const DespatchApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * This endpoint is used to update last despatch status
     * @summary This endpoint is used to update the last despatch status
     * @param {string} carrierId
     * @param {string} branchId
     * @param {string} clerkId
     * @param {string} counterId
     * @param {LastStatus} [lastStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    despatchLastDespatchPut: async (
      carrierId: string,
      branchId: string,
      clerkId: string,
      counterId: string,
      lastStatus?: LastStatus,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'carrierId' is not null or undefined
      assertParamExists("despatchLastDespatchPut", "carrierId", carrierId);
      // verify required parameter 'branchId' is not null or undefined
      assertParamExists("despatchLastDespatchPut", "branchId", branchId);
      // verify required parameter 'clerkId' is not null or undefined
      assertParamExists("despatchLastDespatchPut", "clerkId", clerkId);
      // verify required parameter 'counterId' is not null or undefined
      assertParamExists("despatchLastDespatchPut", "counterId", counterId);
      const localVarPath = `/despatch/last-despatch`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      if (carrierId !== undefined) {
        localVarQueryParameter["carrierId"] = carrierId;
      }

      if (branchId !== undefined) {
        localVarQueryParameter["branchId"] = branchId;
      }

      if (clerkId !== undefined) {
        localVarQueryParameter["clerkId"] = clerkId;
      }

      if (counterId !== undefined) {
        localVarQueryParameter["counterId"] = counterId;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = lastStatus;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to update despatch status
     * @summary This endpoint is used to update the despatch status
     * @param {string} carrierId
     * @param {string} branchId
     * @param {string} clerkId
     * @param {string} counterId
     * @param {DespatchableData} [despatchableData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    despatchParcelsPut: async (
      carrierId: string,
      branchId: string,
      clerkId: string,
      counterId: string,
      despatchableData?: DespatchableData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'carrierId' is not null or undefined
      assertParamExists("despatchParcelsPut", "carrierId", carrierId);
      // verify required parameter 'branchId' is not null or undefined
      assertParamExists("despatchParcelsPut", "branchId", branchId);
      // verify required parameter 'clerkId' is not null or undefined
      assertParamExists("despatchParcelsPut", "clerkId", clerkId);
      // verify required parameter 'counterId' is not null or undefined
      assertParamExists("despatchParcelsPut", "counterId", counterId);
      const localVarPath = `/despatch/parcels`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      if (carrierId !== undefined) {
        localVarQueryParameter["carrierId"] = carrierId;
      }

      if (branchId !== undefined) {
        localVarQueryParameter["branchId"] = branchId;
      }

      if (clerkId !== undefined) {
        localVarQueryParameter["clerkId"] = clerkId;
      }

      if (counterId !== undefined) {
        localVarQueryParameter["counterId"] = counterId;
      }

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = despatchableData;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list Cash information.
     * @summary This endpoint is used to get Despatchable items based on branchId or counterId
     * @param {string} carrierId
     * @param {string} branchId
     * @param {string} clerkId
     * @param {string} counterId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDespatchItems: async (
      carrierId: string,
      branchId: string,
      clerkId: string,
      counterId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'carrierId' is not null or undefined
      assertParamExists("getDespatchItems", "carrierId", carrierId);
      // verify required parameter 'branchId' is not null or undefined
      assertParamExists("getDespatchItems", "branchId", branchId);
      // verify required parameter 'clerkId' is not null or undefined
      assertParamExists("getDespatchItems", "clerkId", clerkId);
      // verify required parameter 'counterId' is not null or undefined
      assertParamExists("getDespatchItems", "counterId", counterId);
      const localVarPath = `/despatch/parcels`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      if (carrierId !== undefined) {
        localVarQueryParameter["carrierId"] = carrierId;
      }

      if (branchId !== undefined) {
        localVarQueryParameter["branchId"] = branchId;
      }

      if (clerkId !== undefined) {
        localVarQueryParameter["clerkId"] = clerkId;
      }

      if (counterId !== undefined) {
        localVarQueryParameter["counterId"] = counterId;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DespatchApiApi - functional programming interface
 * @export
 */
export const DespatchApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DespatchApiApiAxiosParamCreator(configuration);
  return {
    /**
     * This endpoint is used to update last despatch status
     * @summary This endpoint is used to update the last despatch status
     * @param {string} carrierId
     * @param {string} branchId
     * @param {string} clerkId
     * @param {string} counterId
     * @param {LastStatus} [lastStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async despatchLastDespatchPut(
      carrierId: string,
      branchId: string,
      clerkId: string,
      counterId: string,
      lastStatus?: LastStatus,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.despatchLastDespatchPut(
        carrierId,
        branchId,
        clerkId,
        counterId,
        lastStatus,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint is used to update despatch status
     * @summary This endpoint is used to update the despatch status
     * @param {string} carrierId
     * @param {string} branchId
     * @param {string} clerkId
     * @param {string} counterId
     * @param {DespatchableData} [despatchableData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async despatchParcelsPut(
      carrierId: string,
      branchId: string,
      clerkId: string,
      counterId: string,
      despatchableData?: DespatchableData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.despatchParcelsPut(
        carrierId,
        branchId,
        clerkId,
        counterId,
        despatchableData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list Cash information.
     * @summary This endpoint is used to get Despatchable items based on branchId or counterId
     * @param {string} carrierId
     * @param {string} branchId
     * @param {string} clerkId
     * @param {string} counterId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDespatchItems(
      carrierId: string,
      branchId: string,
      clerkId: string,
      counterId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DespatchableItemsData>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDespatchItems(
        carrierId,
        branchId,
        clerkId,
        counterId,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DespatchApiApi - factory interface
 * @export
 */
export const DespatchApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DespatchApiApiFp(configuration);
  return {
    /**
     * This endpoint is used to update last despatch status
     * @summary This endpoint is used to update the last despatch status
     * @param {string} carrierId
     * @param {string} branchId
     * @param {string} clerkId
     * @param {string} counterId
     * @param {LastStatus} [lastStatus]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    despatchLastDespatchPut(
      carrierId: string,
      branchId: string,
      clerkId: string,
      counterId: string,
      lastStatus?: LastStatus,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .despatchLastDespatchPut(carrierId, branchId, clerkId, counterId, lastStatus, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to update despatch status
     * @summary This endpoint is used to update the despatch status
     * @param {string} carrierId
     * @param {string} branchId
     * @param {string} clerkId
     * @param {string} counterId
     * @param {DespatchableData} [despatchableData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    despatchParcelsPut(
      carrierId: string,
      branchId: string,
      clerkId: string,
      counterId: string,
      despatchableData?: DespatchableData,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .despatchParcelsPut(carrierId, branchId, clerkId, counterId, despatchableData, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list Cash information.
     * @summary This endpoint is used to get Despatchable items based on branchId or counterId
     * @param {string} carrierId
     * @param {string} branchId
     * @param {string} clerkId
     * @param {string} counterId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDespatchItems(
      carrierId: string,
      branchId: string,
      clerkId: string,
      counterId: string,
      options?: any,
    ): AxiosPromise<DespatchableItemsData> {
      return localVarFp
        .getDespatchItems(carrierId, branchId, clerkId, counterId, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DespatchApiApi - object-oriented interface
 * @export
 * @class DespatchApiApi
 * @extends {BaseAPI}
 */
export class DespatchApiApi extends BaseAPI {
  /**
   * This endpoint is used to update last despatch status
   * @summary This endpoint is used to update the last despatch status
   * @param {string} carrierId
   * @param {string} branchId
   * @param {string} clerkId
   * @param {string} counterId
   * @param {LastStatus} [lastStatus]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DespatchApiApi
   */
  public despatchLastDespatchPut(
    carrierId: string,
    branchId: string,
    clerkId: string,
    counterId: string,
    lastStatus?: LastStatus,
    options?: AxiosRequestConfig,
  ) {
    return DespatchApiApiFp(this.configuration)
      .despatchLastDespatchPut(carrierId, branchId, clerkId, counterId, lastStatus, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to update despatch status
   * @summary This endpoint is used to update the despatch status
   * @param {string} carrierId
   * @param {string} branchId
   * @param {string} clerkId
   * @param {string} counterId
   * @param {DespatchableData} [despatchableData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DespatchApiApi
   */
  public despatchParcelsPut(
    carrierId: string,
    branchId: string,
    clerkId: string,
    counterId: string,
    despatchableData?: DespatchableData,
    options?: AxiosRequestConfig,
  ) {
    return DespatchApiApiFp(this.configuration)
      .despatchParcelsPut(carrierId, branchId, clerkId, counterId, despatchableData, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list Cash information.
   * @summary This endpoint is used to get Despatchable items based on branchId or counterId
   * @param {string} carrierId
   * @param {string} branchId
   * @param {string} clerkId
   * @param {string} counterId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DespatchApiApi
   */
  public getDespatchItems(
    carrierId: string,
    branchId: string,
    clerkId: string,
    counterId: string,
    options?: AxiosRequestConfig,
  ) {
    return DespatchApiApiFp(this.configuration)
      .getDespatchItems(carrierId, branchId, clerkId, counterId, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DiscrepancyAPIApi - axios parameter creator
 * @export
 */
export const DiscrepancyAPIApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary  This endpoint is used to get discrepency movement of entire branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDiscrepancyData: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cash_management/discrepency_movement`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used for movement of variance to discrepancy
     * @summary This endpoint is used to movement of variance to discrepancy account
     * @param {Array<VarianceAccountingDto>} [varianceAccountingDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDiscrepancyData: async (
      varianceAccountingDto?: Array<VarianceAccountingDto>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cash_management/discrepency_movement`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = varianceAccountingDto;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary  This endpoint is used to udpate discrepency movement of entire branch
     * @param {Array<DiscrepencyData>} [discrepencyData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putDiscrepancyData: async (
      discrepencyData?: Array<DiscrepencyData>,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cash_management/discrepency_movement`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = discrepencyData;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DiscrepancyAPIApi - functional programming interface
 * @export
 */
export const DiscrepancyAPIApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DiscrepancyAPIApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary  This endpoint is used to get discrepency movement of entire branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getDiscrepancyData(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<DiscrepencyHistory>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getDiscrepancyData(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint is used for movement of variance to discrepancy
     * @summary This endpoint is used to movement of variance to discrepancy account
     * @param {Array<VarianceAccountingDto>} [varianceAccountingDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postDiscrepancyData(
      varianceAccountingDto?: Array<VarianceAccountingDto>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postDiscrepancyData(
        varianceAccountingDto,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary  This endpoint is used to udpate discrepency movement of entire branch
     * @param {Array<DiscrepencyData>} [discrepencyData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async putDiscrepancyData(
      discrepencyData?: Array<DiscrepencyData>,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.putDiscrepancyData(
        discrepencyData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DiscrepancyAPIApi - factory interface
 * @export
 */
export const DiscrepancyAPIApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DiscrepancyAPIApiFp(configuration);
  return {
    /**
     *
     * @summary  This endpoint is used to get discrepency movement of entire branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getDiscrepancyData(options?: any): AxiosPromise<DiscrepencyHistory> {
      return localVarFp.getDiscrepancyData(options).then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used for movement of variance to discrepancy
     * @summary This endpoint is used to movement of variance to discrepancy account
     * @param {Array<VarianceAccountingDto>} [varianceAccountingDto]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postDiscrepancyData(
      varianceAccountingDto?: Array<VarianceAccountingDto>,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .postDiscrepancyData(varianceAccountingDto, options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary  This endpoint is used to udpate discrepency movement of entire branch
     * @param {Array<DiscrepencyData>} [discrepencyData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    putDiscrepancyData(
      discrepencyData?: Array<DiscrepencyData>,
      options?: any,
    ): AxiosPromise<void> {
      return localVarFp
        .putDiscrepancyData(discrepencyData, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DiscrepancyAPIApi - object-oriented interface
 * @export
 * @class DiscrepancyAPIApi
 * @extends {BaseAPI}
 */
export class DiscrepancyAPIApi extends BaseAPI {
  /**
   *
   * @summary  This endpoint is used to get discrepency movement of entire branch
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiscrepancyAPIApi
   */
  public getDiscrepancyData(options?: AxiosRequestConfig) {
    return DiscrepancyAPIApiFp(this.configuration)
      .getDiscrepancyData(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used for movement of variance to discrepancy
   * @summary This endpoint is used to movement of variance to discrepancy account
   * @param {Array<VarianceAccountingDto>} [varianceAccountingDto]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiscrepancyAPIApi
   */
  public postDiscrepancyData(
    varianceAccountingDto?: Array<VarianceAccountingDto>,
    options?: AxiosRequestConfig,
  ) {
    return DiscrepancyAPIApiFp(this.configuration)
      .postDiscrepancyData(varianceAccountingDto, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary  This endpoint is used to udpate discrepency movement of entire branch
   * @param {Array<DiscrepencyData>} [discrepencyData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DiscrepancyAPIApi
   */
  public putDiscrepancyData(
    discrepencyData?: Array<DiscrepencyData>,
    options?: AxiosRequestConfig,
  ) {
    return DiscrepancyAPIApiFp(this.configuration)
      .putDiscrepancyData(discrepencyData, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * DissociateAPIApi - axios parameter creator
 * @export
 */
export const DissociateAPIApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * This endpoint is used to item entry
     * @summary This endpoint is used to dissociate two entity which is associated
     * @param {AssociationData} [associationData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postEntityType: async (
      associationData?: AssociationData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cash-drawer-association/v1/dissociation/forced`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};
      localVarHeaderParameter["Content-Type"] = "application/json";

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = associationData;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * DissociateAPIApi - functional programming interface
 * @export
 */
export const DissociateAPIApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = DissociateAPIApiAxiosParamCreator(configuration);
  return {
    /**
     * This endpoint is used to item entry
     * @summary This endpoint is used to dissociate two entity which is associated
     * @param {AssociationData} [associationData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async postEntityType(
      associationData?: AssociationData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.postEntityType(
        associationData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * DissociateAPIApi - factory interface
 * @export
 */
export const DissociateAPIApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = DissociateAPIApiFp(configuration);
  return {
    /**
     * This endpoint is used to item entry
     * @summary This endpoint is used to dissociate two entity which is associated
     * @param {AssociationData} [associationData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    postEntityType(associationData?: AssociationData, options?: any): AxiosPromise<void> {
      return localVarFp
        .postEntityType(associationData, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * DissociateAPIApi - object-oriented interface
 * @export
 * @class DissociateAPIApi
 * @extends {BaseAPI}
 */
export class DissociateAPIApi extends BaseAPI {
  /**
   * This endpoint is used to item entry
   * @summary This endpoint is used to dissociate two entity which is associated
   * @param {AssociationData} [associationData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof DissociateAPIApi
   */
  public postEntityType(associationData?: AssociationData, options?: AxiosRequestConfig) {
    return DissociateAPIApiFp(this.configuration)
      .postEntityType(associationData, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GetAdhocBalanceHistoryBPTPApi - axios parameter creator
 * @export
 */
export const GetAdhocBalanceHistoryBPTPApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get list of BP/TP.
     * @summary get Adhoc BalanceHistory
     * @param {string} fadcode
     * @param {string} entity
     * @param {string} entityID
     * @param {number} timestamp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adhocBalanceHistory: async (
      fadcode: string,
      entity: string,
      entityID: string,
      timestamp: number,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fadcode' is not null or undefined
      assertParamExists("adhocBalanceHistory", "fadcode", fadcode);
      // verify required parameter 'entity' is not null or undefined
      assertParamExists("adhocBalanceHistory", "entity", entity);
      // verify required parameter 'entityID' is not null or undefined
      assertParamExists("adhocBalanceHistory", "entityID", entityID);
      // verify required parameter 'timestamp' is not null or undefined
      assertParamExists("adhocBalanceHistory", "timestamp", timestamp);
      const localVarPath =
        `/cash_management/adhoc-balance-history/{fadcode}/{entity}/{entityID}/{timestamp}`
          .replace(`{${"fadcode"}}`, encodeURIComponent(String(fadcode)))
          .replace(`{${"entity"}}`, encodeURIComponent(String(entity)))
          .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
          .replace(`{${"timestamp"}}`, encodeURIComponent(String(timestamp)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetAdhocBalanceHistoryBPTPApi - functional programming interface
 * @export
 */
export const GetAdhocBalanceHistoryBPTPApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GetAdhocBalanceHistoryBPTPApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of BP/TP.
     * @summary get Adhoc BalanceHistory
     * @param {string} fadcode
     * @param {string} entity
     * @param {string} entityID
     * @param {number} timestamp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async adhocBalanceHistory(
      fadcode: string,
      entity: string,
      entityID: string,
      timestamp: number,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CashDrawerData>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.adhocBalanceHistory(
        fadcode,
        entity,
        entityID,
        timestamp,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GetAdhocBalanceHistoryBPTPApi - factory interface
 * @export
 */
export const GetAdhocBalanceHistoryBPTPApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GetAdhocBalanceHistoryBPTPApiFp(configuration);
  return {
    /**
     * Get list of BP/TP.
     * @summary get Adhoc BalanceHistory
     * @param {string} fadcode
     * @param {string} entity
     * @param {string} entityID
     * @param {number} timestamp
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    adhocBalanceHistory(
      fadcode: string,
      entity: string,
      entityID: string,
      timestamp: number,
      options?: any,
    ): AxiosPromise<Array<CashDrawerData>> {
      return localVarFp
        .adhocBalanceHistory(fadcode, entity, entityID, timestamp, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GetAdhocBalanceHistoryBPTPApi - object-oriented interface
 * @export
 * @class GetAdhocBalanceHistoryBPTPApi
 * @extends {BaseAPI}
 */
export class GetAdhocBalanceHistoryBPTPApi extends BaseAPI {
  /**
   * Get list of BP/TP.
   * @summary get Adhoc BalanceHistory
   * @param {string} fadcode
   * @param {string} entity
   * @param {string} entityID
   * @param {number} timestamp
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GetAdhocBalanceHistoryBPTPApi
   */
  public adhocBalanceHistory(
    fadcode: string,
    entity: string,
    entityID: string,
    timestamp: number,
    options?: AxiosRequestConfig,
  ) {
    return GetAdhocBalanceHistoryBPTPApiFp(this.configuration)
      .adhocBalanceHistory(fadcode, entity, entityID, timestamp, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GetBranchDetailsApi - axios parameter creator
 * @export
 */
export const GetBranchDetailsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get branch details.
     * @summary This endpoint is used to get the branch details
     * @param {string} fadcode Value of fadcode i.e. 2314010
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBranchData: async (
      fadcode: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fadcode' is not null or undefined
      assertParamExists("getBranchData", "fadcode", fadcode);
      const localVarPath = `/iam-management/branchDetails/{fadcode}`.replace(
        `{${"fadcode"}}`,
        encodeURIComponent(String(fadcode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetBranchDetailsApi - functional programming interface
 * @export
 */
export const GetBranchDetailsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GetBranchDetailsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get branch details.
     * @summary This endpoint is used to get the branch details
     * @param {string} fadcode Value of fadcode i.e. 2314010
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getBranchData(
      fadcode: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<BranchDetails>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getBranchData(fadcode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GetBranchDetailsApi - factory interface
 * @export
 */
export const GetBranchDetailsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GetBranchDetailsApiFp(configuration);
  return {
    /**
     * Get branch details.
     * @summary This endpoint is used to get the branch details
     * @param {string} fadcode Value of fadcode i.e. 2314010
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getBranchData(fadcode: string, options?: any): AxiosPromise<BranchDetails> {
      return localVarFp.getBranchData(fadcode, options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * GetBranchDetailsApi - object-oriented interface
 * @export
 * @class GetBranchDetailsApi
 * @extends {BaseAPI}
 */
export class GetBranchDetailsApi extends BaseAPI {
  /**
   * Get branch details.
   * @summary This endpoint is used to get the branch details
   * @param {string} fadcode Value of fadcode i.e. 2314010
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GetBranchDetailsApi
   */
  public getBranchData(fadcode: string, options?: AxiosRequestConfig) {
    return GetBranchDetailsApiFp(this.configuration)
      .getBranchData(fadcode, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GetProductDetailsAtAccountingLocationApi - axios parameter creator
 * @export
 */
export const GetProductDetailsAtAccountingLocationApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get product details based at accounting location based on count group
     * @summary this endpoint is used to get the product details at particular accounting location based on count group
     * @param {string} entityID id of accounting location e.x. cash_drawer
     * @param {string} fadcode value of branch id
     * @param {string} countGroup count group value e.x. Top Sellers
     * @param {string} [searchedProduct] product name or barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductDetailsAtAlCountGroup: async (
      entityID: string,
      fadcode: string,
      countGroup: string,
      searchedProduct?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'entityID' is not null or undefined
      assertParamExists("getProductDetailsAtAlCountGroup", "entityID", entityID);
      // verify required parameter 'fadcode' is not null or undefined
      assertParamExists("getProductDetailsAtAlCountGroup", "fadcode", fadcode);
      // verify required parameter 'countGroup' is not null or undefined
      assertParamExists("getProductDetailsAtAlCountGroup", "countGroup", countGroup);
      const localVarPath = `/stock-management/productDetailsAtAL/{entityID}/{fadcode}/{countGroup}`
        .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
        .replace(`{${"fadcode"}}`, encodeURIComponent(String(fadcode)))
        .replace(`{${"countGroup"}}`, encodeURIComponent(String(countGroup)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      const localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (searchedProduct !== undefined) {
        localVarQueryParameter["searchedProduct"] = searchedProduct;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...headerParams(),
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarRequestOptions.headers.Authorization = "Bearer " + accessToken;
      }

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetProductDetailsAtAccountingLocationApi - functional programming interface
 * @export
 */
export const GetProductDetailsAtAccountingLocationApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator =
    GetProductDetailsAtAccountingLocationApiAxiosParamCreator(configuration);
  return {
    /**
     * Get product details based at accounting location based on count group
     * @summary this endpoint is used to get the product details at particular accounting location based on count group
     * @param {string} entityID id of accounting location e.x. cash_drawer
     * @param {string} fadcode value of branch id
     * @param {string} countGroup count group value e.x. Top Sellers
     * @param {string} [searchedProduct] product name or barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getProductDetailsAtAlCountGroup(
      entityID: string,
      fadcode: string,
      countGroup: string,
      searchedProduct?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockItemAtAL>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getProductDetailsAtAlCountGroup(
        entityID,
        fadcode,
        countGroup,
        searchedProduct,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GetProductDetailsAtAccountingLocationApi - factory interface
 * @export
 */
export const GetProductDetailsAtAccountingLocationApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GetProductDetailsAtAccountingLocationApiFp(configuration);
  return {
    /**
     * Get product details based at accounting location based on count group
     * @summary this endpoint is used to get the product details at particular accounting location based on count group
     * @param {string} entityID id of accounting location e.x. cash_drawer
     * @param {string} fadcode value of branch id
     * @param {string} countGroup count group value e.x. Top Sellers
     * @param {string} [searchedProduct] product name or barcode
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getProductDetailsAtAlCountGroup(
      entityID: string,
      fadcode: string,
      countGroup: string,
      searchedProduct?: string,
      options?: any,
    ): AxiosPromise<StockItemAtAL> {
      return localVarFp
        .getProductDetailsAtAlCountGroup(entityID, fadcode, countGroup, searchedProduct, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GetProductDetailsAtAccountingLocationApi - object-oriented interface
 * @export
 * @class GetProductDetailsAtAccountingLocationApi
 * @extends {BaseAPI}
 */
export class GetProductDetailsAtAccountingLocationApi extends BaseAPI {
  /**
   * Get product details based at accounting location based on count group
   * @summary this endpoint is used to get the product details at particular accounting location based on count group
   * @param {string} entityID id of accounting location e.x. cash_drawer
   * @param {string} fadcode value of branch id
   * @param {string} countGroup count group value e.x. Top Sellers
   * @param {string} [searchedProduct] product name or barcode
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GetProductDetailsAtAccountingLocationApi
   */
  public getProductDetailsAtAlCountGroup(
    entityID: string,
    fadcode: string,
    countGroup: string,
    searchedProduct?: string,
    options?: AxiosRequestConfig,
  ) {
    return GetProductDetailsAtAccountingLocationApiFp(this.configuration)
      .getProductDetailsAtAlCountGroup(entityID, fadcode, countGroup, searchedProduct, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GetStockCategoriesByBranchApi - axios parameter creator
 * @export
 */
export const GetStockCategoriesByBranchApiAxiosParamCreator = function (
  configuration?: Configuration,
) {
  return {
    /**
     * Get stock category for a branch
     * @summary This endpoint will get the stock categories list on a branch
     * @param {string} fadcode value of branch id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStockCategoryList: async (
      fadcode: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fadcode' is not null or undefined
      assertParamExists("getStockCategoryList", "fadcode", fadcode);
      const localVarPath = `/stock-management/categories/{fadcode}`.replace(
        `{${"fadcode"}}`,
        encodeURIComponent(String(fadcode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get stock category for a branch
     * @summary This endpoint will get the sproduct details on a branch
     * @param {string} fadcode value of branch id
     * @param {string} searchedProduct value of serach product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStockProductDetails: async (
      fadcode: string,
      searchedProduct: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fadcode' is not null or undefined
      assertParamExists("getStockProductDetails", "fadcode", fadcode);
      // verify required parameter 'searchedProduct' is not null or undefined
      assertParamExists("getStockProductDetails", "searchedProduct", searchedProduct);
      const localVarPath = `/stock-management/categories/productDetails/{fadcode}`.replace(
        `{${"fadcode"}}`,
        encodeURIComponent(String(fadcode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      if (searchedProduct !== undefined) {
        localVarQueryParameter["searchedProduct"] = searchedProduct;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetStockCategoriesByBranchApi - functional programming interface
 * @export
 */
export const GetStockCategoriesByBranchApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GetStockCategoriesByBranchApiAxiosParamCreator(configuration);
  return {
    /**
     * Get stock category for a branch
     * @summary This endpoint will get the stock categories list on a branch
     * @param {string} fadcode value of branch id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStockCategoryList(
      fadcode: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockCategories>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStockCategoryList(
        fadcode,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get stock category for a branch
     * @summary This endpoint will get the sproduct details on a branch
     * @param {string} fadcode value of branch id
     * @param {string} searchedProduct value of serach product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStockProductDetails(
      fadcode: string,
      searchedProduct: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ProductDetails>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStockProductDetails(
        fadcode,
        searchedProduct,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GetStockCategoriesByBranchApi - factory interface
 * @export
 */
export const GetStockCategoriesByBranchApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GetStockCategoriesByBranchApiFp(configuration);
  return {
    /**
     * Get stock category for a branch
     * @summary This endpoint will get the stock categories list on a branch
     * @param {string} fadcode value of branch id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStockCategoryList(fadcode: string, options?: any): AxiosPromise<StockCategories> {
      return localVarFp
        .getStockCategoryList(fadcode, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get stock category for a branch
     * @summary This endpoint will get the sproduct details on a branch
     * @param {string} fadcode value of branch id
     * @param {string} searchedProduct value of serach product
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStockProductDetails(
      fadcode: string,
      searchedProduct: string,
      options?: any,
    ): AxiosPromise<ProductDetails> {
      return localVarFp
        .getStockProductDetails(fadcode, searchedProduct, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GetStockCategoriesByBranchApi - object-oriented interface
 * @export
 * @class GetStockCategoriesByBranchApi
 * @extends {BaseAPI}
 */
export class GetStockCategoriesByBranchApi extends BaseAPI {
  /**
   * Get stock category for a branch
   * @summary This endpoint will get the stock categories list on a branch
   * @param {string} fadcode value of branch id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GetStockCategoriesByBranchApi
   */
  public getStockCategoryList(fadcode: string, options?: AxiosRequestConfig) {
    return GetStockCategoriesByBranchApiFp(this.configuration)
      .getStockCategoryList(fadcode, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get stock category for a branch
   * @summary This endpoint will get the sproduct details on a branch
   * @param {string} fadcode value of branch id
   * @param {string} searchedProduct value of serach product
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GetStockCategoriesByBranchApi
   */
  public getStockProductDetails(
    fadcode: string,
    searchedProduct: string,
    options?: AxiosRequestConfig,
  ) {
    return GetStockCategoriesByBranchApiFp(this.configuration)
      .getStockProductDetails(fadcode, searchedProduct, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * GetStockCountTypeItemsApi - axios parameter creator
 * @export
 */
export const GetStockCountTypeItemsApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get stock count for a branch
     * @summary This endpoint will get the stock counts list on a branch
     * @param {string} fadcode value of branch id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStockCountList: async (
      fadcode: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'fadcode' is not null or undefined
      assertParamExists("getStockCountList", "fadcode", fadcode);
      const localVarPath = `/stock-management/stockcount/{fadcode}`.replace(
        `{${"fadcode"}}`,
        encodeURIComponent(String(fadcode)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * GetStockCountTypeItemsApi - functional programming interface
 * @export
 */
export const GetStockCountTypeItemsApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = GetStockCountTypeItemsApiAxiosParamCreator(configuration);
  return {
    /**
     * Get stock count for a branch
     * @summary This endpoint will get the stock counts list on a branch
     * @param {string} fadcode value of branch id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getStockCountList(
      fadcode: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<StockCount>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getStockCountList(fadcode, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * GetStockCountTypeItemsApi - factory interface
 * @export
 */
export const GetStockCountTypeItemsApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = GetStockCountTypeItemsApiFp(configuration);
  return {
    /**
     * Get stock count for a branch
     * @summary This endpoint will get the stock counts list on a branch
     * @param {string} fadcode value of branch id
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getStockCountList(fadcode: string, options?: any): AxiosPromise<StockCount> {
      return localVarFp
        .getStockCountList(fadcode, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * GetStockCountTypeItemsApi - object-oriented interface
 * @export
 * @class GetStockCountTypeItemsApi
 * @extends {BaseAPI}
 */
export class GetStockCountTypeItemsApi extends BaseAPI {
  /**
   * Get stock count for a branch
   * @summary This endpoint will get the stock counts list on a branch
   * @param {string} fadcode value of branch id
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof GetStockCountTypeItemsApi
   */
  public getStockCountList(fadcode: string, options?: AxiosRequestConfig) {
    return GetStockCountTypeItemsApiFp(this.configuration)
      .getStockCountList(fadcode, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * MenuApi - axios parameter creator
 * @export
 */
export const MenuApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of Menu.
     * @summary This endpoint is used to get all the Menu items with sub menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMenuList: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/menu-management/menu`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;

      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};
      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list of reason code of paid-in/paid-out.
     * @summary This endpoint is used to get all the reason code list
     * @param {string} operationType
     * @param {string} branchType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReasonCodeList: async (
      operationType: string,
      branchType: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'operationType' is not null or undefined
      assertParamExists("getReasonCodeList", "operationType", operationType);

      // verify required parameter 'taskType' is not null or undefined
      assertParamExists("getReasonCodeList", "branchType", branchType);
      const localVarPath = `/menu-management/reason-code/{operation-type}/{branch-type}`
        .replace(`{${"operation-type"}}`, encodeURIComponent(String(operationType)))
        .replace(`{${"branch-type"}}`, encodeURIComponent(String(branchType)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * MenuApi - functional programming interface
 * @export
 */
export const MenuApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = MenuApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of Menu.
     * @summary This endpoint is used to get all the Menu items with sub menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getMenuList(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Menu>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getMenuList(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list of reason code of paid-in/paid-out.
     * @summary This endpoint is used to get all the reason code list
     * @param {string} operationType
     * @param {string} branchType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getReasonCodeList(
      operationType: string,
      branchType: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ReasonCode>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getReasonCodeList(
        operationType,
        branchType,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * MenuApi - factory interface
 * @export
 */
export const MenuApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = MenuApiFp(configuration);
  return {
    /**
     * Get list of Menu.
     * @summary This endpoint is used to get all the Menu items with sub menu
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getMenuList(options?: any): AxiosPromise<Array<Menu>> {
      return localVarFp.getMenuList(options).then((request) => request(axios, basePath));
    },
    /**
     * Get list of reason code of paid-in/paid-out.
     * @summary This endpoint is used to get all the reason code list
     * @param {string} operationType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getReasonCodeList(operationType: string, options?: any): AxiosPromise<Array<ReasonCode>> {
      return localVarFp
        .getReasonCodeList(operationType, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * MenuApi - object-oriented interface
 * @export
 * @class MenuApi
 * @extends {BaseAPI}
 */
export class MenuApi extends BaseAPI {
  /**
   * Get list of Menu.
   * @summary This endpoint is used to get all the Menu items with sub menu
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenuApi
   */
  public getMenuList(options?: AxiosRequestConfig) {
    return MenuApiFp(this.configuration)
      .getMenuList(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list of reason code of paid-in/paid-out.
   * @summary This endpoint is used to get all the reason code list
   * @param {string} operationType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof MenuApi
   */
  public getReasonCodeList(
    operationType: string,
    branchType: string,
    options?: AxiosRequestConfig,
  ) {
    return MenuApiFp(this.configuration)
      .getReasonCodeList(operationType, branchType, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * PouchApiApi - axios parameter creator
 * @export
 */
export const PouchApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get Pouch-preperation.
     * @summary This endpoint is used to validate Pouch
     * @param {string} branchId
     * @param {string} pouchId
     * @param {string} pouchType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPouchInformation: async (
      branchId: string,
      pouchId: string,
      pouchType: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'branchId' is not null or undefined
      assertParamExists("getPouchInformation", "branchId", branchId);
      // verify required parameter 'pouchId' is not null or undefined
      assertParamExists("getPouchInformation", "pouchId", pouchId);
      // verify required parameter 'pouchType' is not null or undefined
      assertParamExists("getPouchInformation", "pouchType", pouchType);
      const localVarPath = `/cash_management/pouch-preparation/{branch_id}/{pouch_type}/{pouch_id}`
        .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
        .replace(`{${"pouch_id"}}`, encodeURIComponent(String(pouchId)))
        .replace(`{${"pouch_type"}}`, encodeURIComponent(String(pouchType)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get Pouch information
     * @summary This endpoint is used to getting Pouches references operation
     * @param {string} branchId
     * @param {string} [toDate]
     * @param {string} [fromDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPouchesInformation: async (
      branchId: string,
      toDate?: string,
      fromDate?: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'branchId' is not null or undefined
      assertParamExists("getPouchesInformation", "branchId", branchId);
      const localVarPath = `/cash_management/pouch-movement/{branch_id}`.replace(
        `{${"branch_id"}}`,
        encodeURIComponent(String(branchId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      if (toDate !== undefined) {
        localVarQueryParameter["to_date"] = toDate;
      }

      if (fromDate !== undefined) {
        localVarQueryParameter["from_date"] = fromDate;
      }

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * update Pouch-preperation.
     * @summary This endpoint is used to update pouch-information
     * @param {PouchDataDTO} [pouchDataDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePouchDetails: async (
      pouchDataDTO?: PouchDataDTO,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cash_management/pouch`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "POST", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = pouchDataDTO;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * PouchApiApi - functional programming interface
 * @export
 */
export const PouchApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = PouchApiApiAxiosParamCreator(configuration);
  return {
    /**
     * Get Pouch-preperation.
     * @summary This endpoint is used to validate Pouch
     * @param {string} branchId
     * @param {string} pouchId
     * @param {string} pouchType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPouchInformation(
      branchId: string,
      pouchId: string,
      pouchType: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PouchApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPouchInformation(
        branchId,
        pouchId,
        pouchType,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get Pouch information
     * @summary This endpoint is used to getting Pouches references operation
     * @param {string} branchId
     * @param {string} [toDate]
     * @param {string} [fromDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getPouchesInformation(
      branchId: string,
      toDate?: string,
      fromDate?: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PouchDataDTO>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getPouchesInformation(
        branchId,
        toDate,
        fromDate,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * update Pouch-preperation.
     * @summary This endpoint is used to update pouch-information
     * @param {PouchDataDTO} [pouchDataDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async updatePouchDetails(
      pouchDataDTO?: PouchDataDTO,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ApiResponse>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.updatePouchDetails(
        pouchDataDTO,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * PouchApiApi - factory interface
 * @export
 */
export const PouchApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = PouchApiApiFp(configuration);
  return {
    /**
     * Get Pouch-preperation.
     * @summary This endpoint is used to validate Pouch
     * @param {string} branchId
     * @param {string} pouchId
     * @param {string} pouchType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPouchInformation(
      branchId: string,
      pouchId: string,
      pouchType: string,
      options?: any,
    ): AxiosPromise<PouchApiResponse> {
      return localVarFp
        .getPouchInformation(branchId, pouchId, pouchType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get Pouch information
     * @summary This endpoint is used to getting Pouches references operation
     * @param {string} branchId
     * @param {string} [toDate]
     * @param {string} [fromDate]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getPouchesInformation(
      branchId: string,
      toDate?: string,
      fromDate?: string,
      options?: any,
    ): AxiosPromise<Array<PouchDataDTO>> {
      return localVarFp
        .getPouchesInformation(branchId, toDate, fromDate, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * update Pouch-preperation.
     * @summary This endpoint is used to update pouch-information
     * @param {PouchDataDTO} [pouchDataDTO]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    updatePouchDetails(pouchDataDTO?: PouchDataDTO, options?: any): AxiosPromise<ApiResponse> {
      return localVarFp
        .updatePouchDetails(pouchDataDTO, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * PouchApiApi - object-oriented interface
 * @export
 * @class PouchApiApi
 * @extends {BaseAPI}
 */
export class PouchApiApi extends BaseAPI {
  /**
   * Get Pouch-preperation.
   * @summary This endpoint is used to validate Pouch
   * @param {string} branchId
   * @param {string} pouchId
   * @param {string} pouchType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PouchApiApi
   */
  public getPouchInformation(
    branchId: string,
    pouchId: string,
    pouchType: string,
    options?: AxiosRequestConfig,
  ) {
    return PouchApiApiFp(this.configuration)
      .getPouchInformation(branchId, pouchId, pouchType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get Pouch information
   * @summary This endpoint is used to getting Pouches references operation
   * @param {string} branchId
   * @param {string} [toDate]
   * @param {string} [fromDate]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PouchApiApi
   */
  public getPouchesInformation(
    branchId: string,
    toDate?: string,
    fromDate?: string,
    options?: AxiosRequestConfig,
  ) {
    return PouchApiApiFp(this.configuration)
      .getPouchesInformation(branchId, toDate, fromDate, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * update Pouch-preperation.
   * @summary This endpoint is used to update pouch-information
   * @param {PouchDataDTO} [pouchDataDTO]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof PouchApiApi
   */
  public updatePouchDetails(pouchDataDTO?: PouchDataDTO, options?: AxiosRequestConfig) {
    return PouchApiApiFp(this.configuration)
      .updatePouchDetails(pouchDataDTO, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * TaskManagementApiApi - axios parameter creator
 * @export
 */
export const TaskManagementApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     * Get list of Tasks of particular Entity crossponding Task_type.
     * @summary This endpoint is used to get task List data based on branch_id and entityID
     * @param {string} branchId
     * @param {string} entityID
     * @param {string} taskType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParticularTask: async (
      branchId: string,
      entityID: string,
      taskType: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'branchId' is not null or undefined
      assertParamExists("getParticularTask", "branchId", branchId);
      // verify required parameter 'entityID' is not null or undefined
      assertParamExists("getParticularTask", "entityID", entityID);
      // verify required parameter 'taskType' is not null or undefined
      assertParamExists("getParticularTask", "taskType", taskType);
      const localVarPath = `/task-management/task/{branch_id}/{task_type}/{entityID}`
        .replace(`{${"branch_id"}}`, encodeURIComponent(String(branchId)))
        .replace(`{${"entityID"}}`, encodeURIComponent(String(entityID)))
        .replace(`{${"task_type"}}`, encodeURIComponent(String(taskType)));
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get list  of  Tasks.
     * @summary This endpoint is used to get task List data based on branch_id
     * @param {string} branchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskList: async (
      branchId: string,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      // verify required parameter 'branchId' is not null or undefined
      assertParamExists("getTaskList", "branchId", branchId);
      const localVarPath = `/task-management/task/{branch_id}`.replace(
        `{${"branch_id"}}`,
        encodeURIComponent(String(branchId)),
      );
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * This endpoint is used to task list
     * @summary This endpoint is used to update the task status
     * @param {TaskData} [taskData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskManagementTaskPut: async (
      taskData?: TaskData,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/task-management/task`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = taskData;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * TaskManagementApiApi - functional programming interface
 * @export
 */
export const TaskManagementApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = TaskManagementApiApiAxiosParamCreator(configuration);
  return {
    /**
     * Get list of Tasks of particular Entity crossponding Task_type.
     * @summary This endpoint is used to get task List data based on branch_id and entityID
     * @param {string} branchId
     * @param {string} entityID
     * @param {string} taskType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getParticularTask(
      branchId: string,
      entityID: string,
      taskType: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskData>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getParticularTask(
        branchId,
        entityID,
        taskType,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get list  of  Tasks.
     * @summary This endpoint is used to get task List data based on branch_id
     * @param {string} branchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getTaskList(
      branchId: string,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<TaskData>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getTaskList(branchId, options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * This endpoint is used to task list
     * @summary This endpoint is used to update the task status
     * @param {TaskData} [taskData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async taskManagementTaskPut(
      taskData?: TaskData,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.taskManagementTaskPut(
        taskData,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * TaskManagementApiApi - factory interface
 * @export
 */
export const TaskManagementApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = TaskManagementApiApiFp(configuration);
  return {
    /**
     * Get list of Tasks of particular Entity crossponding Task_type.
     * @summary This endpoint is used to get task List data based on branch_id and entityID
     * @param {string} branchId
     * @param {string} entityID
     * @param {string} taskType
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getParticularTask(
      branchId: string,
      entityID: string,
      taskType: string,
      options?: any,
    ): AxiosPromise<Array<TaskData>> {
      return localVarFp
        .getParticularTask(branchId, entityID, taskType, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get list  of  Tasks.
     * @summary This endpoint is used to get task List data based on branch_id
     * @param {string} branchId
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getTaskList(branchId: string, options?: any): AxiosPromise<Array<TaskData>> {
      return localVarFp.getTaskList(branchId, options).then((request) => request(axios, basePath));
    },
    /**
     * This endpoint is used to task list
     * @summary This endpoint is used to update the task status
     * @param {TaskData} [taskData]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    taskManagementTaskPut(taskData?: TaskData, options?: any): AxiosPromise<void> {
      return localVarFp
        .taskManagementTaskPut(taskData, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * TaskManagementApiApi - object-oriented interface
 * @export
 * @class TaskManagementApiApi
 * @extends {BaseAPI}
 */
export class TaskManagementApiApi extends BaseAPI {
  /**
   * Get list of Tasks of particular Entity crossponding Task_type.
   * @summary This endpoint is used to get task List data based on branch_id and entityID
   * @param {string} branchId
   * @param {string} entityID
   * @param {string} taskType
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskManagementApiApi
   */
  public getParticularTask(
    branchId: string,
    entityID: string,
    taskType: string,
    options?: AxiosRequestConfig,
  ) {
    return TaskManagementApiApiFp(this.configuration)
      .getParticularTask(branchId, entityID, taskType, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get list  of  Tasks.
   * @summary This endpoint is used to get task List data based on branch_id
   * @param {string} branchId
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskManagementApiApi
   */
  public getTaskList(branchId: string, options?: AxiosRequestConfig) {
    return TaskManagementApiApiFp(this.configuration)
      .getTaskList(branchId, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * This endpoint is used to task list
   * @summary This endpoint is used to update the task status
   * @param {TaskData} [taskData]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof TaskManagementApiApi
   */
  public taskManagementTaskPut(taskData?: TaskData, options?: AxiosRequestConfig) {
    return TaskManagementApiApiFp(this.configuration)
      .taskManagementTaskPut(taskData, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * VarianceReportFormApiApi - axios parameter creator
 * @export
 */
export const VarianceReportFormApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary This endpoint is used to update Variance report of branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    branchmanagementVarianceGet: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/branchmanagement/variance`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     *
     * @summary This endpoint is used to get Variance report of branch
     * @param {VarianceReportFields} [varianceReportFields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cashManagementVarianceReportPut: async (
      varianceReportFields?: VarianceReportFields,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cash_management/variance_report`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = varianceReportFields;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * VarianceReportFormApiApi - functional programming interface
 * @export
 */
export const VarianceReportFormApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VarianceReportFormApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary This endpoint is used to update Variance report of branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async branchmanagementVarianceGet(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VarianceReport>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.branchmanagementVarianceGet(
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     *
     * @summary This endpoint is used to get Variance report of branch
     * @param {VarianceReportFields} [varianceReportFields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cashManagementVarianceReportPut(
      varianceReportFields?: VarianceReportFields,
      options?: AxiosRequestConfig,
    ): Promise<
      (axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VarianceReportResponse>>
    > {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cashManagementVarianceReportPut(
        varianceReportFields,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * VarianceReportFormApiApi - factory interface
 * @export
 */
export const VarianceReportFormApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = VarianceReportFormApiApiFp(configuration);
  return {
    /**
     *
     * @summary This endpoint is used to update Variance report of branch
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    branchmanagementVarianceGet(options?: any): AxiosPromise<Array<VarianceReport>> {
      return localVarFp
        .branchmanagementVarianceGet(options)
        .then((request) => request(axios, basePath));
    },
    /**
     *
     * @summary This endpoint is used to get Variance report of branch
     * @param {VarianceReportFields} [varianceReportFields]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cashManagementVarianceReportPut(
      varianceReportFields?: VarianceReportFields,
      options?: any,
    ): AxiosPromise<Array<VarianceReportResponse>> {
      return localVarFp
        .cashManagementVarianceReportPut(varianceReportFields, options)
        .then((request) => request(axios, basePath));
    },
  };
};

/**
 * VarianceReportFormApiApi - object-oriented interface
 * @export
 * @class VarianceReportFormApiApi
 * @extends {BaseAPI}
 */
export class VarianceReportFormApiApi extends BaseAPI {
  /**
   *
   * @summary This endpoint is used to update Variance report of branch
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VarianceReportFormApiApi
   */
  public branchmanagementVarianceGet(options?: AxiosRequestConfig) {
    return VarianceReportFormApiApiFp(this.configuration)
      .branchmanagementVarianceGet(options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   *
   * @summary This endpoint is used to get Variance report of branch
   * @param {VarianceReportFields} [varianceReportFields]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VarianceReportFormApiApi
   */
  public cashManagementVarianceReportPut(
    varianceReportFields?: VarianceReportFields,
    options?: AxiosRequestConfig,
  ) {
    return VarianceReportFormApiApiFp(this.configuration)
      .cashManagementVarianceReportPut(varianceReportFields, options)
      .then((request) => request(this.axios, this.basePath));
  }
}

/**
 * VarianceSummaryApiApi - axios parameter creator
 * @export
 */
export const VarianceSummaryApiApiAxiosParamCreator = function (configuration?: Configuration) {
  return {
    /**
     *
     * @summary This endpoint is used to update Variance summary of accounting location
     * @param {VarianceDetails} [varianceDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cashManagementVarianceSummaryPut: async (
      varianceDetails?: VarianceDetails,
      options: AxiosRequestConfig = {},
    ): Promise<RequestArgs> => {
      const localVarPath = `/cash_management/variance_summary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "PUT", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };
      localVarRequestOptions.data = varianceDetails;

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
    /**
     * Get Variance information
     * @summary This endpoint is used to getting Variance summary of accounting locations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVarianceSummary: async (options: AxiosRequestConfig = {}): Promise<RequestArgs> => {
      const localVarPath = `/cash_management/variance_summary`;
      // use dummy base URL string because the URL constructor only accepts absolute URLs.
      const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
      let baseOptions;
      if (configuration) {
        baseOptions = configuration.baseOptions;
      }

      const localVarRequestOptions = { method: "GET", ...baseOptions, ...options };
      let localVarHeaderParameter = {} as any;
      const localVarQueryParameter = {} as any;
      if (configuration && configuration.accessToken) {
        const accessToken =
          typeof configuration.accessToken === "function"
            ? await configuration.accessToken()
            : await configuration.accessToken;
        localVarHeaderParameter = {
          ...headerParams(),
          Authorization: "Bearer " + accessToken,
        };
      }
      localVarRequestOptions.data = {};

      setSearchParams(localVarUrlObj, localVarQueryParameter);
      let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
      localVarRequestOptions.headers = {
        ...localVarHeaderParameter,
        ...headersFromBaseOptions,
        ...options.headers,
      };

      return {
        url: toPathString(localVarUrlObj),
        options: localVarRequestOptions,
      };
    },
  };
};

/**
 * VarianceSummaryApiApi - functional programming interface
 * @export
 */
export const VarianceSummaryApiApiFp = function (configuration?: Configuration) {
  const localVarAxiosParamCreator = VarianceSummaryApiApiAxiosParamCreator(configuration);
  return {
    /**
     *
     * @summary This endpoint is used to update Variance summary of accounting location
     * @param {VarianceDetails} [varianceDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async cashManagementVarianceSummaryPut(
      varianceDetails?: VarianceDetails,
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VarianceDetails>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.cashManagementVarianceSummaryPut(
        varianceDetails,
        options,
      );
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
    /**
     * Get Variance information
     * @summary This endpoint is used to getting Variance summary of accounting locations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    async getVarianceSummary(
      options?: AxiosRequestConfig,
    ): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<VarianceDetails>>> {
      const localVarAxiosArgs = await localVarAxiosParamCreator.getVarianceSummary(options);
      return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
    },
  };
};

/**
 * VarianceSummaryApiApi - factory interface
 * @export
 */
export const VarianceSummaryApiApiFactory = function (
  configuration?: Configuration,
  basePath?: string,
  axios?: AxiosInstance,
) {
  const localVarFp = VarianceSummaryApiApiFp(configuration);
  return {
    /**
     *
     * @summary This endpoint is used to update Variance summary of accounting location
     * @param {VarianceDetails} [varianceDetails]
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    cashManagementVarianceSummaryPut(
      varianceDetails?: VarianceDetails,
      options?: any,
    ): AxiosPromise<Array<VarianceDetails>> {
      return localVarFp
        .cashManagementVarianceSummaryPut(varianceDetails, options)
        .then((request) => request(axios, basePath));
    },
    /**
     * Get Variance information
     * @summary This endpoint is used to getting Variance summary of accounting locations
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     */
    getVarianceSummary(options?: any): AxiosPromise<Array<VarianceDetails>> {
      return localVarFp.getVarianceSummary(options).then((request) => request(axios, basePath));
    },
  };
};

/**
 * VarianceSummaryApiApi - object-oriented interface
 * @export
 * @class VarianceSummaryApiApi
 * @extends {BaseAPI}
 */
export class VarianceSummaryApiApi extends BaseAPI {
  /**
   *
   * @summary This endpoint is used to update Variance summary of accounting location
   * @param {VarianceDetails} [varianceDetails]
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VarianceSummaryApiApi
   */
  public cashManagementVarianceSummaryPut(
    varianceDetails?: VarianceDetails,
    options?: AxiosRequestConfig,
  ) {
    return VarianceSummaryApiApiFp(this.configuration)
      .cashManagementVarianceSummaryPut(varianceDetails, options)
      .then((request) => request(this.axios, this.basePath));
  }

  /**
   * Get Variance information
   * @summary This endpoint is used to getting Variance summary of accounting locations
   * @param {*} [options] Override http request option.
   * @throws {RequiredError}
   * @memberof VarianceSummaryApiApi
   */
  public getVarianceSummary(options?: AxiosRequestConfig) {
    return VarianceSummaryApiApiFp(this.configuration)
      .getVarianceSummary(options)
      .then((request) => request(this.axios, this.basePath));
  }
}
