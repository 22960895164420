import useStockApi from "@bbo/lib/hooks/useStockApi";
import { useGlobalState } from "@bbo/lib/state";
import { getValueFromStorage } from "@bbo/utils/util";
import { useEffect, useState } from "react";
import CategoryList from "./CategoryList";
import "./index.css";

export const Categories = ({ handleSelect, productName }) => {
  const [Categories] = useGlobalState("categories");
  const [GlobalUSerName] = useGlobalState("userName");
  const [, setCategories] = useGlobalState("categories");
  const [fadcode, setFadcode] = useState<string>("");

  useEffect(() => {
    setFadcode(getValueFromStorage("fadcode"));
  }, []);
  const [{ data: categories }, categoriesApi] = useStockApi("", fadcode, "", "GET");
  useEffect(() => {
    categoriesApi("getStockCategoryList");
    setCategories(categories);
  }, [categories, categoriesApi, setCategories]);

  return (
    <>
      <div className="nav-heading">Categories</div>
      <div className="categoryList h-96 bg-white overflow-scroll overflow-x-hidden">
        {Categories &&
          Categories.categories.length &&
          Categories?.categories.map((data, i) => {
            if (data) {
              for (const title in data) {
                return (
                  <CategoryList
                    title={title}
                    key={title}
                    content={data[title]}
                    handleSelect={handleSelect}
                    productName={productName}
                  />
                );
              }
            }
          })}
      </div>
    </>
  );
};
