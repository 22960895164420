import { useEffect, useMemo, useRef, useState } from "react";
import { DropdownValue } from "../dynamoTableTypes";
import { MultiDropdownItem } from "./MultiDropdownItem";

interface MultiDropdownProps {
  placeholder?: string;
  values: DropdownValue[] | undefined;
  onChange: (newValues: DropdownValue[]) => void;
}

const selectAllValue: DropdownValue = {
  label: "Select All",
  value: "",
  selected: false,
};

export const MultiDropdown = ({ placeholder, values, onChange }: MultiDropdownProps) => {
  const [show, setShow] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [width, setWidth] = useState("0px");
  const refDrawer = useRef<HTMLDivElement | null>(null);

  const selectedValues = useMemo(() => values && values.filter((item) => item.selected), [values]);
  const selectCount = selectedValues?.length;

  const [pendingValues, setPendingValues] = useState<DropdownValue[] | undefined>(values);
  const pendingSelectedValues = useMemo(
    () => pendingValues && pendingValues.filter((item) => item.selected),
    [pendingValues],
  );
  const pendingSelectCount = pendingSelectedValues?.length;
  const allPendingItemsSelected = pendingSelectCount === values?.length;

  // Whenever values change, local pending values should also update
  // (e.g. when resetting filters)
  useEffect(() => {
    setPendingValues(values);
  }, [values]);

  const handleSearchChange = (searchQuery: string) => {
    setSearchTerm(searchQuery);
    setPendingValues(
      pendingValues.filter((item) => item.label.toLowerCase().includes(searchQuery.toLowerCase())),
    );
  };
  const handleClick = () => {
    const newWidth = document.getElementById("searchFilterView");
    setShow((prev) => !prev);
    setWidth(newWidth.offsetWidth + "px");
  };

  const handleApply = () => {
    onChange(pendingValues);
    setShow(false);
  };

  useEffect(() => {
    const handleWindowClick = (e) => {
      if (!show) return;
      if (refDrawer.current.contains(e.target)) return;
      setShow((prev) => !prev);
    };

    document.addEventListener("mousedown", handleWindowClick);

    return () => {
      document.removeEventListener("mousedown", handleWindowClick);
    };
  }, [show]);

  return (
    <div>
      <div id="searchFilterView" className="search-filter-view" onClick={handleClick}>
        <span>{`${selectCount > 0 ? `${selectCount} Selected` : "Select"}`}</span>
        <span style={{ paddingLeft: "3px" }}>
          <i className="fa fa-caret-down fa-2x" aria-hidden="true"></i>
        </span>
      </div>
      {show ? (
        <div>
          <div
            ref={refDrawer}
            style={{ width }}
            id="sidebar"
            className={
              "filterDropBoxShadow absolute z-40 transform transition-transform duration-200 ease-in-out sidebarBorder translate-x-0 "
            }>
            <div className="filter-inner-wrapper">
              <input
                className={"filter-search-box"}
                placeholder="Search"
                value={searchTerm}
                onChange={(e) => handleSearchChange(e.target.value)}
              />
              <div className="overflow-y-scroll scrollHeight">
                <MultiDropdownItem
                  value={selectAllValue.value}
                  label={selectAllValue.label}
                  selected={allPendingItemsSelected}
                  onChange={() =>
                    setPendingValues((prev) =>
                      prev?.map((item) => ({ ...item, selected: !allPendingItemsSelected })),
                    )
                  }
                />
                {pendingValues?.map((item) => (
                  <MultiDropdownItem
                    key={item.value}
                    label={item.label}
                    value={item.value}
                    selected={item.selected}
                    onChange={(e) =>
                      setPendingValues((prev) =>
                        prev.map((item) =>
                          item.value === e.target.value ||
                          item.additionalValues?.includes(e.target.value)
                            ? { ...item, selected: !item.selected }
                            : item,
                        ),
                      )
                    }
                  />
                ))}
              </div>
            </div>
            <button
              style={{ marginLeft: "15px", marginBottom: "15px" }}
              className="brandRedBtn text-white mt-2"
              type="button"
              value="apply"
              data-cy="popupYes"
              onClick={handleApply}>
              Apply
            </button>
          </div>
        </div>
      ) : null}
    </div>
  );
};
