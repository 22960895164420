import React from "react";

type Props = {
  tabLabels: string[];
  varianceAmount?: number;
  selectedTab: string;
  onSelectTab: (params: { tabName: string }) => void;
  loadNextData: () => void;
  loadPreviousData: () => void;
  totalPages: number;
  currentPage: number;
};

const HeaderTabs = ({
  tabLabels,
  selectedTab,
  onSelectTab,
  loadNextData,
  loadPreviousData,
  totalPages,
  currentPage,
}: Props) => {
  const handleTabClick = (tabName: string) => {
    onSelectTab({ tabName });
  };
  const [isEndOfLeftScrollInDetailView, setIsEndOfLeftScrollInDetailView] =
    React.useState<boolean>(false);
  const [isEndOfRightScrollInDetailView, setIsEndOfRightScrollInDetailView] =
    React.useState<boolean>(true);

  return (
    <div className="horizontal-tab-list">
      {currentPage > 1 && (
        <i className="fas fa-chevron-left left text-xl" onClick={loadPreviousData} />
      )}
      <div className="horizontal-tab-list-inner">
        <ul>
          {tabLabels?.map((tabName) => (
            <li
              key={tabName}
              className={`tab-listview-trading-period ${selectedTab === tabName ? "active" : ""}`}
              onClick={() => handleTabClick(tabName)}>
              {tabName}
            </li>
          ))}
        </ul>
      </div>
      {currentPage !== totalPages && (
        <i className="fas fa-chevron-right right text-xl" onClick={loadNextData} />
      )}
    </div>
  );
};

export default HeaderTabs;
