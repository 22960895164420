import PopupConfirm from "@bbo/components/Alert";
import { useNavigate } from "react-router-dom";
import { useAppSelector } from "@bbo/lib/hooks/useAppSelector";
import { CurrencyCountList } from "@bbo/api/generator";

interface props {
  fnOpenDenominationLadder: (code: string, name: string) => void;
  selected: CurrencyCountList;
}

export const UncountedCurrencyPopup = ({ fnOpenDenominationLadder, selected }: props) => {
  const navigate = useNavigate();
  const { uncountedCurrencies } = useAppSelector((root) => root.countedCurrencies);

  const handleOnCancel = () => {
    fnOpenDenominationLadder(
      uncountedCurrencies[0].currencyOrderCode.ordercode,
      uncountedCurrencies[0].currencyCountReviewData.currencyName,
    );
  };

  const onSuccess = () => {
    navigate("/currencyCountReview", {
      state: { iSelected: selected },
    });
  };

  const currency = uncountedCurrencies.length === 1 ? "currency" : "currencies";

  const currencyList =
    uncountedCurrencies.length === 1
      ? uncountedCurrencies[0].currencyCountReviewData.currencyName
      : uncountedCurrencies
          .slice(0, 10)
          .map((currency) => " " + currency.currencyCountReviewData.currencyName);

  const has = uncountedCurrencies.length === 1 ? "has" : "have";

  const contentForTenCurrenciesOrFewer = `The following ${currency} - ${currencyList} with an expected on hand figure ${has} not been declared, are you sure you want to continue?`;

  const contentForGreaterThanTenCurrencies = `You have not entered any count detail for ${uncountedCurrencies.length} foreign currencies that have an on-hand figure. Are you sure you want to continue?`;

  return (
    <PopupConfirm
      header="Missed Currency"
      content={
        uncountedCurrencies.length > 10
          ? contentForGreaterThanTenCurrencies
          : contentForTenCurrenciesOrFewer
      }
      labelSuccess="Continue"
      labelCancel="Recount"
      tickicon={false}
      onSuccess={onSuccess}
      onCancel={handleOnCancel}
    />
  );
};
