import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import TransactionLogPage from "@bbo/features/TransactionLog/TransactionLogPage";
import { useEffect } from "react";
import "./Index.css";

const TransactionLog = () => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Transaction Log");
  }, [setTitle]);

  return (
    <>
      <TransactionLogPage />
    </>
  );
};

export default TransactionLog;
