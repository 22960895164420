/**
 * Generated by orval v6.7.1 🍺
 * Do not edit manually.
 * forex-management-api
 * PO Branch Management

PO IAM

Cash management apis

These apis helps to manage branch at infra level

PO Branch Management

REST APIs for Branch Back Office Menu. Returns all menu list and submenu lists for a user's role associated to the Fadcode (Branch). Various user roles defined - Postmaster, Branch Manager, Supervisor, Assistant and Junior Assistant.

Stock Management

Task management apis

Cash Drawer Association API

Accounting Location API defining how to get and modify Accounting Locations
 * OpenAPI spec version: 0.1.0
 */
import {
  useQuery,
  UseQueryOptions,
  QueryFunction,
  UseQueryResult,
  QueryKey,
} from "@tanstack/react-query";
import type {
  SystemCashResponseResponse,
  BboCashManagementBadRequestResponse,
  UnauthorisedResponse,
  BboCashManagementForbiddenResponse,
  NotFoundResponse,
  FailureResponseResponse,
} from "../model";
import { useCustomInstance, ErrorType } from "../mutator/useCustomInstance";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type AsyncReturnType<T extends (...args: any) => Promise<any>> = T extends (
  ...args: any
) => Promise<infer R>
  ? R
  : any;

/**
 * Returns list of system cash details of an accounting location
 * @summary System cash value of an accounting location
 */
export const useGetSystemCashAnAccountingLocationHook = () => {
  const getSystemCashAnAccountingLocation = useCustomInstance<SystemCashResponseResponse>();

  return (accountingLocationID: string) => {
    return getSystemCashAnAccountingLocation({
      url: `/BboCashManagementService/v1/system-cash/accounting-location/${accountingLocationID}`,
      method: "get",
    });
  };
};

export const getGetSystemCashAnAccountingLocationQueryKey = (accountingLocationID: string) => [
  `/BboCashManagementService/v1/system-cash/accounting-location/${accountingLocationID}`,
];

export type GetSystemCashAnAccountingLocationQueryResult = NonNullable<
  AsyncReturnType<ReturnType<typeof useGetSystemCashAnAccountingLocationHook>>
>;
export type GetSystemCashAnAccountingLocationQueryError = ErrorType<
  | BboCashManagementBadRequestResponse
  | UnauthorisedResponse
  | BboCashManagementForbiddenResponse
  | NotFoundResponse
  | FailureResponseResponse
>;

export const useGetSystemCashAnAccountingLocation = <
  TData = AsyncReturnType<ReturnType<typeof useGetSystemCashAnAccountingLocationHook>>,
  TError = ErrorType<
    | BboCashManagementBadRequestResponse
    | UnauthorisedResponse
    | BboCashManagementForbiddenResponse
    | NotFoundResponse
    | FailureResponseResponse
  >,
>(
  accountingLocationID: string,
  options?: {
    query?: UseQueryOptions<
      AsyncReturnType<ReturnType<typeof useGetSystemCashAnAccountingLocationHook>>,
      TError,
      TData
    >;
  },
): UseQueryResult<TData, TError> & { queryKey: QueryKey } => {
  const { query: queryOptions } = options || {};

  const queryKey =
    queryOptions?.queryKey ?? getGetSystemCashAnAccountingLocationQueryKey(accountingLocationID);

  const getSystemCashAnAccountingLocation = useGetSystemCashAnAccountingLocationHook();

  const queryFn: QueryFunction<
    AsyncReturnType<ReturnType<typeof useGetSystemCashAnAccountingLocationHook>>
  > = () => getSystemCashAnAccountingLocation(accountingLocationID);

  const query = useQuery<
    AsyncReturnType<ReturnType<typeof useGetSystemCashAnAccountingLocationHook>>,
    TError,
    TData
  >(queryKey, queryFn, { enabled: !!accountingLocationID, ...queryOptions });

  return {
    queryKey,
    ...query,
  };
};
