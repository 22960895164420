import { BP_TP_PERIOD_NAMES, BP_TP_PERIOD_TYPES } from "@bbo/constants";
import HistoricalBpTp from "@bbo/features/TpBp/HistoricalBpTp";

const HistoricalBp = () => {
  const periodName = BP_TP_PERIOD_NAMES.BALANCE_PERIOD;
  const periodType = BP_TP_PERIOD_TYPES.BP;

  return (
    <>
      <HistoricalBpTp periodName={periodName} periodType={periodType} />
    </>
  );
};
export default HistoricalBp;
