export const ALL = "All";
export const AWAITING_BRANCH_ACTION = "Awaiting Branch Action";
export const AWAITING_CENTRAL_SUPPORT = "Awaiting Central Support";
export const SETTLED = "Settled";
export const HISTORY = "History";
export const SUMMARY = "Summary";
export const IN_BRANCH_TYPE = "IN";
export const BACK_OFFICE = "Back Office";
export const PAID_OUT = "paid_out";
export const PAID_IN = "paid_in";
export const DISCREPANCY_SETTLEMENT = "DS";
export const COMMENT = "ABC";
export const SETTLE_CASH = "Settle to Cash";
export const DMB = "DMB";
export const IN = "IN";
export const SP = "SP";
export const CPD = "csc_pd_cc";
export const CPA = "csc_pa_cc";
export const BMK = "bank_mke";
export const CDF = "bank_dcpf";

export const SUMMARY_OVERVIEW_TABS = [
  ALL,
  AWAITING_BRANCH_ACTION,
  AWAITING_CENTRAL_SUPPORT,
  SETTLED,
];
export const DISCREPENCY_OVERVIEW_TABS = [
  { value: SUMMARY, valueLength: 0 },
  { value: HISTORY, valueLength: 0 },
];

export const ERROR_MESSAGE =
  "This Discrepancy record has been already settled. Please refresh the Page .";
