import TableView from "@bbo/components/table";
import { STOCK_REVIEW_COLUMN } from "@bbo/components/table/tableConstants";
import { TableCellCallbackParams } from "@bbo/components/table/types";
import { useMemo } from "react";
import { CountedProduct } from "../StockCount/StockCount";

interface IStockCountReviewTableProps {
  heading: string;
  rows: CountedProduct[];
  onCellChange: (row: TableCellCallbackParams<CountedProduct>) => void;
  isStockCountCompleted: boolean;
}

export const StockCountReviewTable = ({
  heading,
  rows,
  onCellChange,
  isStockCountCompleted,
}: IStockCountReviewTableProps) => {
  const columns = useMemo(() => {
    if (!isStockCountCompleted) {
      return STOCK_REVIEW_COLUMN;
    }
    const copyColumns = STOCK_REVIEW_COLUMN.map((item) => ({ ...item }));
    const columnEnterQuantityIndex = copyColumns.findIndex(
      (col) => col.field === "enteredQuantity",
    );
    copyColumns[columnEnterQuantityIndex].inputBox = false;
    return copyColumns;
  }, [isStockCountCompleted]);

  return (
    <>
      <strong className="col-span-6 standard-label stockReviewFont">{heading}</strong>
      <div className="lg:w-full relative my-5">
        <TableView
          columns={columns}
          rows={rows}
          isDiscrpencyTable={true}
          isStockReview={true}
          onCellChange={onCellChange}
          min="0"
        />
      </div>
    </>
  );
};
