import Loader from "@bbo/components/Loader";
import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { IEntityData, StockCount } from "@bbo/features/StockCount";
import { useAppSelector } from "@bbo/lib";
import { useGlobalState } from "@bbo/lib/state";
import { getValueFromStorage, setValueInStorage } from "@bbo/utils/util";
import { useEffect, useState } from "react";

const StockCountScreen = () => {
  const [GlobalUSerName] = useGlobalState("userName");
  const [loader, setloader] = useState<boolean>(false);
  const selectedTask = useAppSelector((root) => root.selectedTask);
  const entityData = getValueFromStorage<IEntityData>("selectedALName");

  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle(`Stock Count | ${entityData?.["entityName"]}`);
  }, [setTitle, entityData]);

  useEffect(() => {
    if (entityData && entityData?.entityID) {
      setloader(false);
    } else {
      setloader(true);
    }
    if (selectedTask?.entityID) {
      setValueInStorage("selectedALName", selectedTask);
    }
  }, [GlobalUSerName, selectedTask]);

  return <>{loader ? <Loader /> : <StockCount entityData={entityData} />}</>;
};

export default StockCountScreen;
