import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { PouchPreparationScanFeature } from "@bbo/features/PouchPreparationScan";
import { useEffect } from "react";

export const PouchPreparationScan = () => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Pouch Preparation");
  }, [setTitle]);

  return <PouchPreparationScanFeature />;
};
