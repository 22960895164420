import { useTitle } from "@bbo/features/AuthRoutes/AuthRoutes";
import { DiscrepancyAccount } from "@bbo/features/DiscrepancyAccount";
import { useEffect } from "react";

const DiscrepancyAccountPage = () => {
  const { setTitle } = useTitle();

  useEffect(() => {
    setTitle("Branch Discrepancy Account");
  }, [setTitle]);

  return (
    <>
      <div className="containerMain">
        <div className="w-full items-start h-full overflow-x-auto px-4">
          <div className="w-full">
            <div>
              <DiscrepancyAccount />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default DiscrepancyAccountPage;
