/**
 *Result page row count shown of the table component
 * @param props
 * @returns
 */
interface iProps {
  totalCount: number;
  currentPage: number;
  pageSize: number;
  currentPageSize: number;
  isDiscrepancyTable?: boolean;
}

const ResultShown = (props: iProps) => {
  const showCount = (props.currentPage - 1) * props.pageSize + (props.currentPageSize ?? 0);

  return (
    <div className={`${props.isDiscrepancyTable ? "float-right" : "w-full text-right mb-5"}`}>
      <span>
        <span className="ui-label-light">Showing </span>
        <strong>{showCount}</strong>
        <span className="ui-label-light"> of </span>
        <strong>{props.totalCount}</strong>
        <span className="ui-label-light"> results</span>
      </span>
    </div>
  );
};

export default ResultShown;
