import TableView from "@bbo/components/table";
import { USER_ACTIVITIES_TABLE_COLUMN } from "@bbo/components/table/tableConstants";
import "./index.css";

interface iProps {
  UserData: any[];
  handleCellClickedCallback: any;
}

const UserActivitiesTable = (props: iProps) => {
  return (
    <>
      <>
        <div className="w-full transferOut-height">
          <div className=" lg:w-full h-full relative dynamictable userActivitiesTableHeight">
            {props.UserData.length !== 0 && (
              <TableView
                columns={USER_ACTIVITIES_TABLE_COLUMN}
                rows={props.UserData}
                paginationSize={props.UserData.length}
                onCellClicked={props.handleCellClickedCallback}
              />
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default UserActivitiesTable;
