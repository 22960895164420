import { PopupType } from "@bbo/components/ErrorPopup";
import { LIMIT_EXCEEDS } from "../PouchLadder/PouchConstants";

export interface PouchPreparationModalConfig {
  key: keyof typeof pouchPreparationModalConfigs;
  modalType: PopupType;
  heading?: string;
  message?: string;
  secondaryButtonText?: string;
  onSecondaryButtonClick?: () => void;
  primaryButtonText?: string;
  onPrimaryButtonClick?: () => void;
}

export type PouchPreparationModalConfigKeys =
  | "invalidBarcode"
  | "resumeSuspendedPouch"
  | "resumeSuspendedPouchByOther"
  | "cancelPouch"
  | "cancelPouchSuccess"
  | "cancelPouchFailed"
  | "suspendPouch"
  | "suspendPouchFailed"
  | "suspendPouchLimit"
  | "pouchPreparationSuccess"
  | "pouchPreparationFailed"
  | "printSuccess"
  | "reprintReceipt"
  | "reprintSuccess"
  | "pouchDespatch"
  | "suspendedPouchByOther"
  | "limitExceeds"
  | "leavePouchPreparation"
  | "cancelledPouch"
  | "despatchedPouch"
  | "acceptedPouch"
  | "expectedPouch";

export const pouchPreparationModalConfigs: Record<
  PouchPreparationModalConfigKeys,
  PouchPreparationModalConfig
> = {
  invalidBarcode: {
    key: "invalidBarcode",
    modalType: "cross",
    heading: "Pouch Barcode Failed to Scan",
    message: "Cancel or Scan Pouch again.",
    primaryButtonText: "Close",
  },
  resumeSuspendedPouch: {
    key: "resumeSuspendedPouch",
    modalType: "alert",
    heading: "There is a suspended pouch preparation, you can resume that preparation to proceed.",
    primaryButtonText: "Resume",
    secondaryButtonText: "Cancel",
  },
  resumeSuspendedPouchByOther: {
    key: "resumeSuspendedPouchByOther",
    modalType: "alert",
    heading:
      "There is a suspended pouch prepared by {username}, you cannot suspend same type of pouch more than once.",
    primaryButtonText: "Close",
  },
  cancelPouch: {
    key: "cancelPouch",
    modalType: "alert",
    heading: "Do you really want to cancel this pouch?",
    primaryButtonText: "Yes",
    secondaryButtonText: "No",
  },
  cancelPouchSuccess: {
    key: "cancelPouchSuccess",
    modalType: "success",
    heading: "Successfully cancelled pouch",
    primaryButtonText: "Close",
  },
  cancelPouchFailed: {
    key: "cancelPouchFailed",
    modalType: "error",
    heading: "Something went wrong when cancelling your pouch",
    primaryButtonText: "Close",
  },
  suspendPouch: {
    key: "suspendPouch",
    modalType: "alert",
    heading:
      "Do you really want to suspend this task? Note - suspension rules cease at the end of the day (7pm) after which the Pouch Preparation will be cancelled.",
    primaryButtonText: "Save",
    secondaryButtonText: "Cancel",
  },
  suspendPouchFailed: {
    key: "suspendPouchFailed",
    modalType: "alert",
    heading:
      "Unable to suspend pouch. There may be a suspended pouch prepared by somebody else, you cannot suspend same type of pouch more than once.",
    primaryButtonText: "Close",
  },
  suspendPouchLimit: {
    key: "suspendPouchLimit",
    modalType: "alert",
    heading: "You can not suspend a pouch more than two times",
    primaryButtonText: "Close",
  },
  pouchPreparationSuccess: {
    key: "pouchPreparationSuccess",
    modalType: "success",
    heading: "Pouch Preparation is Successful",
    primaryButtonText: "Print Receipt",
    secondaryButtonText: "Cancel",
  },
  pouchPreparationFailed: {
    key: "pouchPreparationFailed",
    modalType: "error",
    heading: "Something went wrong when submitting your pouch preparation",
    primaryButtonText: "Close",
  },
  printSuccess: {
    key: "printSuccess",
    modalType: "print",
    heading: "Print Successful",
    primaryButtonText: "Confirm Successful Print",
    secondaryButtonText: "Cancel",
  },
  reprintReceipt: {
    key: "reprintReceipt",
    modalType: "print",
    heading: "Do you want to reprint the receipt?",
    primaryButtonText: "Yes",
    secondaryButtonText: "No",
  },
  reprintSuccess: {
    key: "reprintSuccess",
    modalType: "print",
    heading: "Reprint Successful",
    primaryButtonText: "OK",
  },
  pouchDespatch: {
    key: "pouchDespatch",
    modalType: "alert",
    heading: "Pouch Dispatch is only available via the Counter Terminal App",
    primaryButtonText: "Close",
  },
  suspendedPouchByOther: {
    key: "suspendedPouchByOther",
    modalType: "alert",
    heading: "This pouch is prepared by {username}, you are not authorised to edit or cancel it.",
    primaryButtonText: "Close",
  },
  limitExceeds: {
    key: "limitExceeds",
    modalType: "alert",
    heading: `Pouch amount above £${LIMIT_EXCEEDS}.00 will not be permitted.`,
    primaryButtonText: "Close",
  },
  leavePouchPreparation: {
    key: "leavePouchPreparation",
    modalType: "alert",
    heading: "Do you really want to cancel this task?",
    primaryButtonText: "Yes",
    secondaryButtonText: "No",
  },
  cancelledPouch: {
    key: "cancelledPouch",
    modalType: "alert",
    heading: "This pouch has already been cancelled. Scan a different barcode to continue",
    primaryButtonText: "Close",
  },
  despatchedPouch: {
    key: "despatchedPouch",
    modalType: "alert",
    heading: "This pouch has already been despatched. Scan a different barcode to continue",
    primaryButtonText: "Close",
  },
  acceptedPouch: {
    key: "acceptedPouch",
    modalType: "alert",
    heading: "This pouch has already been accepted. Scan a different barcode to continue",
    primaryButtonText: "Close",
  },
  expectedPouch: {
    key: "expectedPouch",
    modalType: "alert",
    heading: "This pouch is expected for delivery. Scan a different barcode to continue",
    primaryButtonText: "Close",
  },
};
