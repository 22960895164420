import { useStyles } from "./CurrencyCountReviewScreen/CurrencyCountReviewScreenStyles";

interface ICurrencyCountFooterProps {
  handleCancelCount: () => void;
  handleResetCount: () => void;
  handleSuspendCount: () => void;
  handleCommitCount: () => void;
}

export const CurrencyCountFooter = ({
  handleCancelCount,
  handleCommitCount,
  handleResetCount,
  handleSuspendCount,
}: ICurrencyCountFooterProps) => {
  const classes = useStyles();
  return (
    <div className="flex w-full justify-center top-border-light p-4 bg-white sticky-panel space-x-6 mb-8">
      <button
        // className="action-button button-white"
        className={classes.buttonBtmNav}
        onClick={handleSuspendCount}>
        Suspend
      </button>
      <button
        className={classes.buttonBtmNav}
        // className="action-button button-white"
        onClick={handleCancelCount}>
        Cancel Count
      </button>
      <button
        className={classes.buttonBtmNav}
        // className="action-button button-white"
        onClick={handleResetCount}>
        Reset
      </button>
      <button
        className={classes.buttonBtmNavactive}
        // className="action-button button-primaryblue"
        onClick={handleCommitCount}>
        Commit
      </button>
    </div>
  );
};
